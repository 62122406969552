import React from 'react';
import { AlertColor } from '@mui/material/Alert';

interface ToastConfig {
  content?: string;
  severity?: AlertColor;
  duration?: number;
}

export interface ToastContent extends ToastConfig {
  publish: (msg: string, severity?: AlertColor, duration?: number) => void;
  clear: () => void;
}

export const ToastContext = React.createContext<ToastContent>({
  publish: () => {},
  clear: () => {},
});

function ToastProvider({ children }: { children: React.ReactNode }) {
  let [data, setData] = React.useState<ToastConfig>({ content: '' });

  const publish = (content: string, severity: AlertColor = 'info', duration: number = 5000) => {
    setData({ content, severity, duration });
  };

  const clear = () => {
    setData({ content: '' });
  };

  let value = { ...data, publish, clear };
  return <ToastContext.Provider value={value}> {children} </ToastContext.Provider>;
}

function useToast() {
  return React.useContext(ToastContext);
}

export { ToastProvider, useToast };
