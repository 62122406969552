import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUpdateAccountMessagingMutation } from '../../app/services/account';
import theme from '../../app/theme';
import { useToast } from '../../app/toast';
import RoundedContainer from '../../common/components/RoundedContainer';
import SubmitButton from '../../common/components/SubmitButton';
import {
  CommunicationSubTypes,
  Recipients,
  generateValidationSchema,
  populateCommunicationSubTypes,
  populateCommunicationTypes,
  populateInstructionMessage,
} from '../../common/utils/accountMessaging';
import { ReportType } from '../../common/utils/assessmentType';
import checkNullData from '../../common/utils/checkNullData';
import {
  AccountMessagingConfigs,
  CommunicationSubType,
  DefaultAccountMessaging,
} from '../../types/AccountMessagingType';
import { ClinicAccountItems } from '../../types/ClinicAccountType';
import { ResponseError } from '../../types/ResponseError';
import MessageSettingDynamicForms from './MessageSettingDynamicForms';

const getAccountOptionLabel = (option: ClinicAccountItems) => `${option.name} (${option.id})`;

const filterAccountOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: ClinicAccountItems) => getAccountOptionLabel(option),
});

const defaultMessageTexts: CommunicationSubType = {
  customWelcomeEmailText: '',
  trackerWelcomeEmailText: '',
  landingPageClinicianText: '',
  landingPageEmailText: '',
  landingPagePatientText: '',
  landingPageResumeText: '',
  landingPagePatientResumeText: '',
  completePageClinicianText: '',
  completePagePatientText: '',
  landingPageClinicianHeaderText: '',
  landingPageEmailHeaderText: '',
  landingPagePatientHeaderText: '',
  landingPageResumeHeaderText: '',
  landingPagePatientResumeHeaderText: '',
  landingPageMonitorText: '',
  completePageMonitorText: '',
  landingPageMonitorHeaderText: '',
  interviewWelcomeEmailSubjectText: '',
  monitorWelcomeEmailSubjectText: '',
  interviewSMSText: '',
  monitorSMSText: '',
};

const defaultConfigValues: AccountMessagingConfigs = {
  clinicAccountId: '',
  assessmentType: ReportType.Interview,
  recipient: Recipients.PATIENT,
  communicationType: '',
};

const getDefaultValues = (newDefaultValues?: DefaultAccountMessaging): DefaultAccountMessaging => {
  return {
    ...defaultConfigValues,
    ...defaultMessageTexts,
    ...newDefaultValues,
  };
};

interface AccountSettingsProps {
  currentAccountId: string;
  setClinicAccountId: Dispatch<SetStateAction<string>>;
  clinicAccounts?: ClinicAccountItems[];
  accountSettings?: CommunicationSubType;
  disabledClinicAccount?: boolean;
  refetchAccountSettings: () => void;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setCanRunEvent: Dispatch<SetStateAction<boolean>>;
  canRunEvent: boolean;
}

export default function AccountMessagingForm({
  currentAccountId,
  clinicAccounts = [],
  accountSettings,
  setClinicAccountId,
  disabledClinicAccount,
  refetchAccountSettings,
  setOpenPopup,
  setCanRunEvent,
  canRunEvent,
}: AccountSettingsProps) {
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const toast = useToast();
  const defaultValues = getDefaultValues({ clinicAccountId: currentAccountId });

  const [communicationConditions, setConditions] = useState<{
    assessmentType?: string;
    recipient?: string;
    clinicAccountId?: string;
    communicationType?: string;
  }>({
    assessmentType: defaultValues?.assessmentType,
    recipient: defaultValues?.recipient,
    clinicAccountId: defaultValues?.clinicAccountId,
    communicationType: defaultValues?.communicationType,
  });
  const [communicationTypes, setCommunicationTypes] = useState<string[]>([]);
  const [communicationSubTypes, setCommunicationSubTypes] = useState<string[]>([]);
  const [disabledRecipient, setDisabledRecipient] = useState<boolean>(false);
  const [pageErrors, setPageErrors] = useState<string[]>([]);

  const [updateAccountSettings] = useUpdateAccountMessagingMutation();

  const recipientNames = t('accountMessaging.recipientNames', { returnObjects: true });
  const assessmentNames = t('accountMessaging.assessmentNames', { returnObjects: true });
  const communicationTypeNames = t('accountMessaging.communicationTypeNames', {
    returnObjects: true,
  });

  const validationSchema = yup
    .object({
      clinicAccountId: yup.string().required(t('accountMessaging.error.emptyText')),
      assessmentType: yup.string().required(t('accountMessaging.error.emptyAssessmentType')),
      recipient: yup.string().required(t('accountMessaging.error.emptyRecipient')),
      communicationType: yup.string().required(t('accountMessaging.error.emptyCommunicationType')),
      ...generateValidationSchema(communicationSubTypes, yup, t),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    resetField,
  } = useForm<DefaultAccountMessaging>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const resetCommunicationSubTypes = () => {
    communicationSubTypes.forEach((item: string) => {
      resetField(item as keyof DefaultAccountMessaging);
    });
  };

  const checkingUnSaveMessage = (): boolean => {
    let messageUnChecked = false;
    for (const item of communicationSubTypes) {
      if (
        JSON.stringify(getValues(item as keyof CommunicationSubType)?.trim() || '') !==
        JSON.stringify(accountSettings?.[item]?.trim() || '')
      ) {
        messageUnChecked = true;
        setCanRunEvent(false);
        setOpenPopup(messageUnChecked);
        break;
      }
    }

    return messageUnChecked;
  };

  const handleAccountChange = (e: SyntheticEvent<Element, Event>, values: any) => {
    const selectedValue = values?.id || '';
    if (selectedValue) {
      checkingUnSaveMessage();
      setConditions({
        communicationType: '',
        clinicAccountId: selectedValue,
        recipient: Recipients.PATIENT,
        assessmentType: ReportType.Interview,
      });
      setDisabledRecipient(false);
    }
  };

  const handleRecipientChange = (e: any) => {
    let selectedValue = e?.target?.value;
    if (selectedValue) {
      checkingUnSaveMessage();
      setConditions({
        ...communicationConditions,
        communicationType: '',
        recipient: selectedValue,
      });
    }
  };

  const handleAssessmentTypeChange = (e: any) => {
    const selectedValue = e?.target?.value;
    let conditions = {};
    let isDisabled = false;
    if (selectedValue) {
      checkingUnSaveMessage();
      if (selectedValue === ReportType.Interview) {
        conditions = {
          ...communicationConditions,
          communicationType: '',
          assessmentType: selectedValue,
        };
      }

      if (selectedValue === ReportType.Monitor) {
        conditions = {
          ...communicationConditions,
          communicationType: '',
          assessmentType: selectedValue,
          recipient: Recipients.PATIENT,
        };
        isDisabled = true;
      }

      setDisabledRecipient(isDisabled);
      setConditions(conditions);
    }
  };

  const handleCommunicationTypeChange = (e: any) => {
    const selectedValue = e?.target?.value;
    if (selectedValue) {
      checkingUnSaveMessage();
      setConditions({
        ...communicationConditions,
        communicationType: selectedValue,
      });
    }
  };

  useEffect(() => {
    if (accountSettings) {
      Object.values(CommunicationSubTypes).forEach((field: string) => {
        setValue(
          field as keyof CommunicationSubType,
          checkNullData(accountSettings[field as keyof CommunicationSubType])
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSettings, communicationSubTypes]);

  useEffect(() => {
    if (currentAccountId) {
      setValue('clinicAccountId', currentAccountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountId]);

  useEffect(() => {
    if (canRunEvent) {
      const values = populateCommunicationTypes(communicationConditions);
      let defaultValue = values[0];

      for (const key in communicationConditions) {
        const condition = communicationConditions?.[key];
        if (key === 'communicationType') {
          defaultValue = condition || defaultValue;
          setValue('communicationType', defaultValue);
        } else if (key === 'clinicAccountId') {
          setValue('clinicAccountId', condition || currentAccountId);
          setClinicAccountId(condition || currentAccountId);
        } else {
          setValue(key as keyof DefaultAccountMessaging, condition);
        }
      }

      const populateSubTypes = populateCommunicationSubTypes(defaultValue);
      resetCommunicationSubTypes();
      setCommunicationTypes(values);
      setCommunicationSubTypes(populateSubTypes);
      setPageErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationConditions, canRunEvent]);

  const onSubmit = async (data: DefaultAccountMessaging) => {
    const submitData = Object.entries(data).filter(
      ([key, _]: [string, string]) =>
        key === 'clinicAccountId' || communicationSubTypes.includes(key)
    );

    try {
      const res = await updateAccountSettings(Object.fromEntries(submitData)).unwrap();
      if (res) {
        toast.publish(t('accountMessaging.savedSuccess'), 'success');
        setPageErrors([]);
        setConditions({
          communicationType: getValues('communicationType'),
          clinicAccountId: getValues('clinicAccountId'),
          recipient: getValues('recipient'),
          assessmentType: getValues('assessmentType'),
        });
        setCanRunEvent(true);
        refetchAccountSettings();
      }
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <Box
      data-testid="account-messaging-form"
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      <RoundedContainer sx={{ py: 3 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: matches ? '1fr' : 'repeat(2, 1fr)',
            gridGap: '10px',
          }}
        >
          <Controller
            name="clinicAccountId"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <Autocomplete
                {...props}
                disabled={disabledClinicAccount}
                fullWidth
                data-testid="clinicAccountId"
                options={clinicAccounts || []}
                getOptionLabel={getAccountOptionLabel}
                filterOptions={filterAccountOptions}
                value={value ? clinicAccounts?.find((item) => item.id === value) || null : null}
                onChange={handleAccountChange}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.id} value={option.id}>
                      {getAccountOptionLabel(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.clinicAccountId}
                    helperText={errors.clinicAccountId?.message}
                    margin="normal"
                    fullWidth
                    label={t('exportData.structured.form.clinicAccount')}
                  />
                )}
              />
            )}
          />
          <Controller
            name="assessmentType"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                select
                fullWidth
                data-testid="assessmentType"
                aria-labelledby="accountMessaging.form.assessmentType"
                label={t('accountMessaging.form.assessmentType')}
                error={!!errors.assessmentType}
                helperText={errors.assessmentType?.message}
                variant="outlined"
                onChange={handleAssessmentTypeChange}
              >
                {Object.values(ReportType).map((type: string) => (
                  <MenuItem key={type} value={type}>
                    {assessmentNames[type]}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="recipient"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={disabledRecipient}
                margin="normal"
                select
                aria-selected
                fullWidth
                data-testid="recipient"
                label={t('accountMessaging.form.recipient')}
                error={!!errors.recipient}
                helperText={errors.recipient?.message}
                variant="outlined"
                onChange={handleRecipientChange}
              >
                {Object.values(Recipients).map((recipient: string) => (
                  <MenuItem key={recipient} value={recipient} role="option">
                    {recipientNames[recipient]}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="communicationType"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                select
                fullWidth
                data-testid="communicationType"
                label={t('accountMessaging.form.communicationType')}
                error={!!errors.communicationType}
                helperText={errors.communicationType?.message}
                variant="outlined"
                onChange={handleCommunicationTypeChange}
              >
                {communicationTypes.map((item: string) => (
                  <MenuItem key={item} value={item}>
                    {communicationTypeNames[item]}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Box>
      </RoundedContainer>

      <RoundedContainer>
        <Box
          sx={{
            my: 2,
          }}
        >
          <Typography
            fontWeight={500}
            color={theme.xPalette.lightGrey}
            dangerouslySetInnerHTML={{
              __html: populateInstructionMessage(t, getValues('communicationType')),
            }}
          />
        </Box>

        <Box>
          <MessageSettingDynamicForms
            communicationSubTypes={communicationSubTypes}
            control={control}
            errors={errors}
          />
        </Box>

        <SubmitButton
          content={t('accountMessaging.save')}
          matches={matches}
          pageErrors={pageErrors}
        />
      </RoundedContainer>
    </Box>
  );
}
