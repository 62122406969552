import { useState } from 'react';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

interface TagListProps {
  label: string;
  placeholder?: string;
  tags: string[];
  setTags: (value: string[]) => void;
  validationFn?: (value: string[]) => boolean;
  error?: any;
  setError?: () => void;
  clearError?: () => void;
  sx?: any;
  disabled?: boolean;
}

export default function TagList({
  label,
  placeholder,
  tags,
  error,
  setError,
  clearError,
  setTags,
  validationFn,
  sx,
  disabled,
}: TagListProps) {

  const setUniqueTags = (newValue: string[]) => {
    const uniqueValue = newValue.filter((item, pos) => {
      return newValue.indexOf(item) === pos;
    })
    setTags(uniqueValue);
  }

  const onChange = (e: React.SyntheticEvent, value: any, reason?: AutocompleteChangeReason) => {
    if (reason === 'createOption' && validationFn && setError && clearError) {
      const newValue = previousInputValue ? previousInputValue.split(/[\s,]+/).map((currentValue) => currentValue.trim()) : [];
      if (!validationFn(newValue)) {
        setError();
        setInputValue(previousInputValue);
      } else {
        clearError();
        const valueWithoutLast = value.filter((currentValue: string) => currentValue !== previousInputValue);
        setUniqueTags([...valueWithoutLast, ...newValue]);
        setInputValue('');
      }
    } else {
      setUniqueTags(value);
    }
  };

  const [inputValue, setInputValue] = useState('');
  const [previousInputValue, setPreviousInputValue] = useState(inputValue);
  const onInputChange = (e: React.SyntheticEvent, value: string) => {
    if (value) {
      setPreviousInputValue(value);
      if (validationFn && validationFn([value])) {
        clearError && clearError();
      }
    } else if (clearError) {
      clearError();
    }
    setInputValue(value);
  };

  const onInputBlur =  (event: any) => {
    if (inputValue) {
      onChange({} as any, [...tags, inputValue], 'createOption');
    }
  }

  return (
    <Autocomplete
      sx={sx}
      value={tags ? tags : []}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      multiple
      options={[]}
      freeSolo
      renderTags={(value: unknown[], getTagProps) => {
        return value.map((option: unknown, index: number) => (
          <Chip variant="outlined" label={String(option)} {...getTagProps({ index })} />
        ))
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onBlur={onInputBlur}
          sx={{marginTop: 2, marginBottom: 1}}
          fullWidth
          error={!!error}
          helperText={error?.message}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}
