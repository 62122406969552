import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import useCustomQuestionInfo from './useCustomQuestionInfo';

const borderStyle = `1px solid ${theme.xPalette.black}`;
const borderBottomStyle = `1px solid ${theme.xPalette.shadeGrey}`;

export default function YbocsCheckOtherAnswer({
  questionAnswer: yBocsQuestionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const { answerValue } = useCustomQuestionInfo({
    initialState: yBocsQuestionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <Box
      key={yBocsQuestionAnswer.questionId}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        pageBreakInside: 'avoid',
        alignItems: 'center',
        columnGap: 1,
        borderTop: borderBottomStyle,
        borderBottom: borderBottomStyle,
        borderRight: borderStyle,
        borderLeft: borderStyle,
        height: '100%',
      }}
    >
      <Box sx={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', px: 1, py: 2 }}>
        <Typography fontWeight={'bold'} fontSize={'16px'}>
          {yBocsQuestionAnswer.title}
        </Typography>
      </Box>

      <Divider sx={{ backgroundColor: theme.xPalette.black }} orientation="vertical" flexItem />

      <Box
        sx={{
          flex: 3,
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 1,
          py: 2,
        }}
      >
        <Typography
          fontWeight="bold"
          fontSize={'16px'}
          dangerouslySetInnerHTML={{
            __html: answerValue || '',
          }}
        ></Typography>
      </Box>
    </Box>
  );
}
