import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { convertCopyRight } from '../../../common/utils/reportUtils';
import { AssessmentReport } from '../../../types/ReportType';
import ReportCoverPage from '../reportModules/ReportCoverPage';
import ReportModulePage from '../reportModules/ReportModulePage';
import ReportNextSteps from '../reportModules/ReportNextSteps';
import ReportPageBreak from '../reportModules/ReportPageBreak';
import ReportSummaryPage from '../reportModules/ReportSummaryPage';
import ReportTitlePage from '../reportModules/ReportTitlePage';

export default function MINIReportPage({
  logoUrl,
  moduleQuestions,
  assessmentResult: interviewResult,
  patient,
  interviewer,
  assessment: interview,
  nextSteps,
  questionNotes,
}: AssessmentReport) {
  return (
    <>
      <ReportCoverPage
        logo={logoUrl}
        result={interviewResult}
        patient={patient}
        interviewer={interviewer}
        interview={interview}
        isInterview
      />

      {interview && (
        <>
          <ReportPageBreak />
          <ReportTitlePage assessment={interview} />
        </>
      )}

      {interview?.displaySummaryPage && (
        <>
          <ReportPageBreak />
          <ReportSummaryPage
            assessmentResult={interviewResult}
            patient={patient}
            interview={interview}
            interviewer={interviewer}
            moduleQuestions={moduleQuestions}
          />
        </>
      )}

      {nextSteps && nextSteps.length > 0 && (
        <>
          <ReportPageBreak />
          <ReportNextSteps nextSteps={nextSteps} />
        </>
      )}

      <RoundedContainer sx={{ my: 2, py: 2 }}>
        {moduleQuestions?.map((module: any) => (
          <Box
            sx={{
              pageBreakBefore: 'always',
            }}
          >
            <ReportModulePage
              key={module?.id}
              moduleTitle={module?.title}
              moduleLetter={module?.letter}
              questionAnswers={module?.questionAnswers}
              questionScores={module?.questionScores}
              moduleType={interview?.reportType}
              questionNotes={questionNotes}
            />
          </Box>
        ))}
      </RoundedContainer>

      <Typography
        my={2}
        variant="body1"
        textAlign="left"
        dangerouslySetInnerHTML={{
          __html: convertCopyRight(interview?.copyright),
        }}
      ></Typography>
    </>
  );
}
