import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useAppSelector } from '../../app/hooks';
import { PageBreadcrumb } from '../../types/PageBreadcrumb';
import { BaseProps } from '../../types/PageProps';
import Breadcrumb from '../components/Breadcrumb';
import CompletedFollowupModal from '../components/CompletedFollowupModal';
import { GlobalLoading } from '../components/GlobalLoading';
import Header from '../components/Header';
import SessionExpirationModal from '../components/SessionExpirationModal';
import { selectShowGlobalLoading } from '../slices/globalSlice';
import { pageHeightStyle } from '../utils/commonStyles';
import BaseLayout from './Base';

interface Props extends BaseProps {
  breadcrumbs?: PageBreadcrumb[];
  showBreadcrumb?: boolean;
  showHeader?: boolean;
  accountLogo?: string;
  sessionExpiration?: boolean;
}

export default function DashboardLayout({
  children,
  breadcrumbs,
  accountLogo,
  showBreadcrumb = true,
  showCopyright = true,
  showHeader = true,
  background = 'inherit',
  sessionExpiration = true,
}: Props) {
  const [appBarRef, setAppBarRef] = useState<React.RefObject<HTMLDivElement>>();
  const [appBarHeight, setAppBarHeight] = useState<number>(0);
  const showGlobalLoading = useAppSelector(selectShowGlobalLoading);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      if (appBarRef?.current) {
        setAppBarHeight(appBarRef.current.clientHeight || appBarRef.current.offsetHeight);
      }
    };
    window.addEventListener('resize', handleWindowSizeChange);

    // set initial value
    handleWindowSizeChange();

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [appBarRef]);

  const onAppBarRefSet = (ref: React.RefObject<HTMLDivElement>) => {
    setAppBarRef(ref);
  };

  return (
    <BaseLayout showCopyright={showCopyright} background={background}>
      {showHeader && <Header setAppBarRef={onAppBarRefSet} accountLogo={accountLogo} />}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          ...pageHeightStyle(theme),
        })}
      >
        {showBreadcrumb && (
          <Container maxWidth="xl" sx={{ my: 2 }}>
            <Breadcrumb data={breadcrumbs} />
          </Container>
        )}
        {showGlobalLoading && <GlobalLoading appBarHeight={appBarHeight} />}
        {children}
      </Box>
      {sessionExpiration && <SessionExpirationModal />}
      <CompletedFollowupModal />
    </BaseLayout>
  );
}
