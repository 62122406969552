import { ClinicalFollowUpAccountSettingsDetailsResponse } from '../../types/ClinicalFollowUps';
import { NextStepType } from './assessmentType';

export const hasInterviewType = (nextStepData: ClinicalFollowUpAccountSettingsDetailsResponse) =>
  nextStepData.items.some((item) => item.type === NextStepType.Interview);

export const hasMonitorType = (nextStepData: ClinicalFollowUpAccountSettingsDetailsResponse) =>
  nextStepData.items.some((item) => item.type === NextStepType.Monitor);

export const isSame = (initialValue: any, submitValue: any) =>
  initialValue.length === submitValue.length &&
  initialValue.every(
    (item: any, index: number) =>
      Object.keys(item).length === Object.keys(submitValue[index]).length &&
      Object.keys(item).every((property) => item[property] === submitValue[index][property])
  );
