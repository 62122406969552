import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetPatientNotesQuery } from '../../../app/services/patient';
import PageHeader from '../../../common/components/PageHeader';
import PatientInfoLayout from '../../../common/components/PatientInfoLayout';
import RoundedContainer from '../../../common/components/RoundedContainer';
import DashboardLayout from '../../../common/layouts/Dashboard';
import { formatServerDateAndTime } from '../../../common/utils/dateTimeUtils';
import { ReplaceType, editBreadcrumbs } from '../../../common/utils/editBreadcrums';
import { getPageTitle } from '../../../common/utils/pageUtils';
import { PageProps } from '../../../types/PageProps';
import { PatientNote } from '../../../types/PatientRequest';
import DataListNote from '../DataListNote';
import PatientNoteForm from './PatientNoteForm';

interface DataList {
  author: string;
  dateAdded: string;
  title: string;
  body: string;
}

export default function PatientNotesPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { patientId } = useParams();

  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }];

  const {
    data: patientNotesData,
    isLoading,
    refetch,
  } = useGetPatientNotesQuery(
    patientId
      ? {
          patientId,
          isWriteLog: true,
        }
      : skipToken
  );

  return (
    <DashboardLayout breadcrumbs={editBreadcrumbs(breadcrumbs, ...replacePath)}>
      <Helmet>
        <title>{getPageTitle(t('patient.patientDashboard.form.patientNote'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={!isDesktop}>
        <PageHeader
          sx={{ mb: 2, mx: isDesktop ? 0 : 2 }}
          headerText={t('patient.patientDashboard.form.patientNote')}
          backUrl={`/dashboard/patient/${patientId}`}
        />
        <PatientInfoLayout patientId={patientId}>
          <RoundedContainer sx={{ mt: 2 }}>
            <PatientNoteForm refetchData={refetch} />
          </RoundedContainer>

          <DataListNote
            data={
              patientNotesData?.patientNotes?.length
                ? patientNotesData.patientNotes.map(
                    (item: PatientNote) =>
                      ({
                        author: item.author,
                        dateAdded: formatServerDateAndTime(item.dateAdded),
                        title: item.title,
                        body: item.body,
                      } as DataList)
                  )
                : undefined
            }
            isLoading={isLoading}
          />
        </PatientInfoLayout>
      </Container>
    </DashboardLayout>
  );
}
