import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError, SkipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetAccountMessagesQuery,
  useGetClinicAccountsQuery,
} from '../../../app/services/account';
import { CommunicationSubType } from '../../../types/AccountMessagingType';
import { ClinicAccountItems } from '../../../types/ClinicAccountType';

export const useRequestForAccountMessagingPage = (
  clinicAccountId: string,
  skipToken: SkipToken
): {
  clinicAccounts?: ClinicAccountItems[];
  accountSettings?: CommunicationSubType;
  error: FetchBaseQueryError | SerializedError | undefined;
  isLoading: boolean;
  refetchAccountSettings: () => void;
} => {
  const {
    data: accountData,
    isLoading: loadClinicAccounts,
    error: accountError,
  } = useGetClinicAccountsQuery();

  const {
    data: accountSettings,
    isLoading: loadAccountSettings,
    error: settingError,
    refetch: refetchAccountSettings,
  } = useGetAccountMessagesQuery(clinicAccountId ? { clinicAccountId } : skipToken);

  return {
    clinicAccounts: accountData?.clinicAccounts,
    accountSettings,
    error: settingError || accountError,
    isLoading: loadClinicAccounts || loadAccountSettings,
    refetchAccountSettings,
  };
};
