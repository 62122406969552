import { CustomPatient, PatientInfo, PatientListItem } from '../../types/PatientRequest';
import { RescorePatient } from '../../types/RescoreInterviewJob';

export const getPatientName = (
  patient: PatientInfo | PatientListItem | RescorePatient | CustomPatient
) => {
  const patientId = (patient as PatientListItem).id || (patient as PatientInfo).patientId;
  if (patient.lastName && patient.firstName) {
    return `${patient.lastName}, ${patient.firstName} (${patientId})`;
  }
  if (patient.lastName || patient.firstName || patient.externalId) {
    return `${patient.lastName || patient.firstName || patient.externalId} (${patientId})`;
  }
  return patientId;
};

export const getPatientFullName = (patient: RescorePatient) => {
  if (patient.firstName && patient.lastName) {
    return `${patient.lastName}, ${patient.firstName}`;
  }
  if (patient.firstName || patient.lastName) return `${patient.lastName || patient.firstName}`;
  return '';
};
