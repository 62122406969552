import { Theme } from '@emotion/react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSignatureCanvas, {
  ReactSignatureCanvasProps,
  default as SignatureCanvas,
} from 'react-signature-canvas';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import theme from '../../app/theme';

interface ElectronicSignatureProps extends Partial<ReactSignatureCanvasProps> {
  clear: () => void;
  headerText?: string;
  footerText?: string;
  matches?: boolean;
  showTopClearButton?: boolean;
  showDivider?: boolean;
  sx?: Theme;
}

const StyleBox = styled(Box)(({ theme, sx }) => ({
  sx,
  maxWidth: 500,
  backgroundColor: theme.xPalette.white,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  padding: '8px',
  '& canvas': {
    borderRadius: '4px',
    display: 'block',
    height: 200,
  },
  '& .MuiBox-root': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .MuiDivider-root': {
    marginTop: '16px',
  },
  '& .MuiLink-root': {
    textDecoration: 'unset',
    textAlign: 'right',
  },
}));

const ElectronicSignature = forwardRef<ReactSignatureCanvas, ElectronicSignatureProps>(
  (
    {
      headerText,
      footerText,
      showTopClearButton = false,
      showDivider = true,
      sx: sxProps,
      clear,
      ...signatureProps
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <StyleBox sx={{ ...sxProps }}>
        <Box>
          <Typography
            component="p"
            dangerouslySetInnerHTML={{
              __html: `${headerText || ''}`,
            }}
          ></Typography>
          {showTopClearButton && (
            <Link component="button" type="reset" variant="body1" onClick={clear}>
              {t('eSignature.form.clear')}
            </Link>
          )}
        </Box>
        <SignatureCanvas
          ref={ref}
          minWidth={1.5}
          maxWidth={1.5}
          penColor="black"
          backgroundColor={theme.xPalette.white}
          {...signatureProps}
        />
        {showDivider && <Divider />}
        <Box>
          <Typography
            component="p"
            dangerouslySetInnerHTML={{
              __html: `${footerText || ''}`,
            }}
          ></Typography>
          {!showTopClearButton && (
            <Link component="button" type="reset" variant="body1" onClick={clear}>
              {t('eSignature.form.clear')}
            </Link>
          )}
        </Box>
      </StyleBox>
    );
  }
);

export default ElectronicSignature;
