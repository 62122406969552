import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ProfileIconSvg } from '../../../assets/icons/profile-circle-icon.svg';

export default function ProfileIcon() {
  return (
    <SvgIcon aria-label="patient-icon" fontSize="large" fill="white" viewBox='0 -3 24 24'>
      <ProfileIconSvg />
    </SvgIcon>
  );
}
