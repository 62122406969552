export enum MfaUserType {
  User,
  Patient,
}

export const MfaInterviewType = {
  Interview: 1,
  Monitor: 2,
  TreatmentSignature: 3,
  AutomaticFollowUpSignature: 4,
};

export enum MfaGetCodeMode {
  GetCode,
  Resend,
}

export const MfaType = {
  Email: 1,
  Phone: 2,
};
