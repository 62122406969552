export enum AssessmentType {
  Interview,
  Monitor,
}

export const ReportType = {
  Interview: 'interview',
  Monitor: 'monitor',
};

export const MasterReportType = {
  All: 'all',
  Interview: 'interview',
  Monitor: 'monitor',
};

export enum AssessmentBuilder {
  INTERVIEW = 'interview',
  MONITOR = 'monitor',
}

export const NextStepType = {
  Interview: 1,
  Monitor: 2,
  External: 3,
};

export const ImportType = {
  Interview: 'Interview',
  Monitor: 'Monitor',
};
