import dayjs from 'dayjs';
import { GoalItem, GoalProgress, GoalStatus } from '../../types/treatment-plan/GoalType';
import { ObjectiveStatus } from '../../types/treatment-plan/ObjectiveType';

export interface OptionItem {
  name: string;
  code: string;
}

export const goalProgressListWithKey = {
  [GoalProgress.NotStarted]: 'Not Started',
  [GoalProgress.Minimal]: 'Minimal',
  [GoalProgress.Moderate]: 'Moderate',
  [GoalProgress.Good]: 'Good',
  [GoalProgress.Excellent]: 'Excellent',
};

export const goalProgressList: OptionItem[] = Object.entries(goalProgressListWithKey).map(
  ([code, name]) => ({
    code,
    name,
  })
);

export const goalStatusListWithKey = {
  [GoalStatus.NotStarted]: 'Not Started',
  [GoalStatus.Active]: 'Active',
  [GoalStatus.Completed]: 'Completed',
  [GoalStatus.Inactive]: 'Inactive',
  [GoalStatus.Deferred]: 'Deferred',
};

export const goalStatusList: OptionItem[] = Object.entries(goalStatusListWithKey).map(
  ([code, name]) => ({
    code,
    name,
  })
);

export const objectiveStatusListWithKey = {
  [ObjectiveStatus.NotStarted]: 'Not Started',
  [ObjectiveStatus.Active]: 'Active',
  [ObjectiveStatus.Completed]: 'Completed',
};

export const objectiveStatusList: OptionItem[] = Object.entries(objectiveStatusListWithKey).map(
  ([code, name]) => ({
    code,
    name,
  })
);

export const isBefore = (dayBefore?: Date, dayAfter?: Date) => {
  if (!dayAfter || !dayBefore) {
    return false;
  }
  const dateBeforeFormat = new Date(new Date(dayBefore).toISOString().split('T')[0]);
  const dateAfterFormat = new Date(new Date(dayAfter).toISOString().split('T')[0]);
  return dateBeforeFormat.getTime() < dateAfterFormat.getTime();
};

export const truncate = (value: string, maxLength: number) => {
  if (value.length <= maxLength) {
    return value;
  }
  return value.slice(0, maxLength) + '...';
};

export const NeedFormatDateFields = [
  'startDate',
  'projectedCompletionDate',
  'completionDate',
  'nextReviewDate',
];

export const convertToClientTimezone = (date: string | Date): string => {
  if (!date) {
    return '';
  }

  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) {
    return '';
  }

  // Get the client's timezone
  const clientTimezone = dayjs.tz.guess();

  // Convert the date to the client's timezone
  const convertedDate = parsedDate.tz(clientTimezone);

  return convertedDate.toString();
};

export const checkAllGoalCompleted = (goals: GoalItem[]) => {
  return goals.every(
    (goal: GoalItem) =>
      goal.status === GoalStatus.Completed ||
      goal.status === GoalStatus.Inactive ||
      goal.status === GoalStatus.Deferred
  );
};
