import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useAddObjectiveMutation,
  useEditObjectiveMutation,
} from '../../../app/services/treatment-plan/objective';
import { useToast } from '../../../app/toast';
import CommonDatePicker from '../../../common/components/CommonDatePicker';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { objectiveStatusList } from '../../../common/utils/treatmentPlans';
import { ResponseError } from '../../../types/ResponseError';
import { ObjectiveItem, ObjectiveStatus } from '../../../types/treatment-plan/ObjectiveType';

interface ObjectiveFormProps {
  parentGoalId: number;
  objectiveData?: ObjectiveItem;
  onFormSubmitCallback: (objectiveData: ObjectiveItem, result: boolean, info?: any) => void;
}

export default function ObjectiveForm({
  parentGoalId,
  objectiveData,
  onFormSubmitCallback,
}: ObjectiveFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const toast = useToast();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [addObjective] = useAddObjectiveMutation();
  const [editObjective] = useEditObjectiveMutation();

  const defaultFormValues = {
    objective: '',
    status: '',
    statusDate: null,
    dateDue: null,
  };

  const validationSchema = yup
    .object({
      objective: yup.string().required(t('treatmentPlans.objectiveForm.error.blankName')),
      status: yup.string().required(t('treatmentPlans.objectiveForm.error.blankStatus')),
      statusDate: yup
        .date()
        .nullable()
        .transform((current: any, origin: any) => (origin === '' ? null : current))
        .when('status', {
          is: (status: ObjectiveStatus) => status && status !== ObjectiveStatus.NotStarted,
          then: yup
            .date()
            .nullable()
            .transform((current: any, origin: any) => (origin === '' ? null : current))
            .required(t('treatmentPlans.objectiveForm.error.blankStatusDate')),
        }),
      dateDue: yup
        .date()
        .required(t('treatmentPlans.objectiveForm.error.blankDateDue'))
        .typeError(t('treatmentPlans.objectiveForm.error.blankDateDue')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<ObjectiveItem>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  // initial data load
  useEffect(() => {
    if (objectiveData?.id) {
      if (!isDataLoaded) {
        setIsDataLoaded(true);
        Object.keys(objectiveData).forEach((attr) => {
          if (attr !== 'id') {
            setValue(attr as any, objectiveData[attr] ?? null);
          }
        });
      }
    }
  }, [objectiveData, isDataLoaded, setValue]);

  const onSubmit = async (objectiveFormData: ObjectiveItem) => {
    setDisableSubmit(true);
    try {
      let submitData = objectiveFormData;
      if (objectiveData?.id) {
        submitData = { ...objectiveFormData, id: objectiveData.id };
        await editObjective(submitData).unwrap();
      } else if (parentGoalId) {
        submitData = { ...objectiveFormData, treatmentGoalId: parentGoalId };
        const addingRes = await addObjective(submitData).unwrap();
        if (addingRes?.data) {
          submitData = addingRes?.data;
        }
      }

      onFormSubmitCallback(submitData, true);

      setPageErrors([]);

      toast.publish(
        (objectiveData?.id && t('treatmentPlans.objectiveForm.updateSuccess')) ||
          t('treatmentPlans.objectiveForm.addSuccess'),
        'success'
      );
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
      onFormSubmitCallback(objectiveFormData, false, error);
    }
    setDisableSubmit(false);
  };

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  const handleStatusChange = (e: any, values: any) => {
    const selectedValue = values ? values.code : '';
    setValue('status', selectedValue);
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        px: 0,
        pt: 0,
      }}
    >
      <RoundedContainer sx={{ paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Controller
            name="objective"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.objectiveForm.name.title')}
                variant="outlined"
                error={!!errors.objective}
                helperText={errors.objective?.message}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CommonDatePicker
              name="dateDue"
              control={control}
              error={!!errors.dateDue}
              helperText={errors.dateDue?.message}
              label={t('treatmentPlans.objectiveForm.dueDate.title')}
              matches={isMobile}
              textFieldSx={{
                width: isMobile ? '100%' : '30%',
              }}
            />
          </LocalizationProvider>

          <Controller
            name="status"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <Autocomplete
                {...props}
                sx={{ width: isMobile ? '100%' : '30%' }}
                data-testid="mui-component-select-objective-status"
                options={objectiveStatusList}
                getOptionLabel={(option: any) => (option.name ? option.name : '')}
                onChange={handleStatusChange}
                value={value ? objectiveStatusList.find((status) => status.code === value) : null}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.code} value={option.code}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.status}
                    helperText={errors.status?.message}
                    margin="normal"
                    fullWidth
                    label={t('treatmentPlans.objectiveForm.status.title')}
                  />
                )}
              />
            )}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CommonDatePicker
              name="statusDate"
              control={control}
              error={!!errors.statusDate}
              helperText={errors.statusDate?.message}
              label={t('treatmentPlans.objectiveForm.statusDate.title')}
              matches={isMobile}
              textFieldSx={{
                width: isMobile ? '100%' : '30%',
              }}
            />
          </LocalizationProvider>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            pt: 3,
          }}
        >
          {!isMobile && <Divider sx={{ width: '100%' }} />}

          {pageErrors?.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            ))}
          <Button
            onClick={submitForm}
            disabled={disableSubmit}
            fullWidth
            variant="contained"
            sx={{
              width: isMobile ? '100%' : '30%',
              my: 2,
              py: 2,
              float: 'right',
            }}
          >
            {objectiveData?.id
              ? t('treatmentPlans.objectiveForm.update')
              : t('treatmentPlans.objectiveForm.add')}
          </Button>
        </Box>
      </RoundedContainer>
    </Box>
  );
}
