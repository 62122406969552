import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useGetIdentityProvidersActiveQuery } from '../../app/services/identityProvider';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import SubmitButton from '../../common/components/SubmitButton';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { EditAccountActionType } from '../../common/utils/callActionType';
import checkNullData from '../../common/utils/checkNullData';
import { DefaultRemovedDays } from '../../common/utils/commonUtils';
import { sortedCountryCodes, USCountryCode } from '../../common/utils/countries';
import { populateLanguage } from '../../common/utils/languages';
import LinkedList from '../../common/utils/singlyLinkList';
import { getStateErrorKey, usStates } from '../../common/utils/usStates';
import { PhoneNumberRequired, validatePhoneNumber } from '../../common/utils/validationUtils';
import {
  AccountFormInfo,
  AccountResponse,
  InterviewTypeItem,
  MonitorTypeItem,
} from '../../types/AccountType';
import { PopupContent } from '../../types/PopupType';
import { ResponseError } from '../../types/ResponseError';

interface AccountFormProps {
  accountId?: string;
  data?: AccountResponse;
  onFormSubmit: (
    data: AccountFormInfo,
    interviewTypesData?: any,
    monitorTypesData?: any,
    accountId?: string,
    successCallback?: () => void
  ) => Promise<void>;
}

const defaultFormValues: AccountFormInfo = {
  accountId: '',
  accountName: '',
  accountType: '',
  exportSetting: 'native',
  automateEmail: true,
  automateIntakePacket: false,
  mfaEnabled: false,
  followUpEnabled: false,
  followUpReportEnabled: false,

  siteName: '',
  siteLocation: '',
  siteCountry: '',
  siteAddressLine1: '',
  siteAddressLine2: '',
  siteCity: '',
  siteState: '',
  siteZipCode: '',
  siteTimezone: '',

  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPhone: '',
  userCellPhone: '',
  mfaType: '2',

  whiteLabel: false,
  tokenForAdminOnly: false,
  externalLink: '',

  interviewTypes: [],
  monitorTypes: [],

  ehrEnabled: false,
  taraEnabled: false,
  organizationId: '',
  ehrType: '',
  sendPdfEhr: false,
  sendPatientReportEhr: false,
  smsEnabled: false,
  removeDays: 0,
};

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '49%',
    },
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    width: '45px',
    height: '45px',
  },
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 30,
  },
}));

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  '& .MuiOutlinedInput-root': {
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyleBox = styled(Box)(({ theme }) => ({
  width: '49%',
  marginTop: '16px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyleErrorForm = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const StyleFormItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  height: 'auto',
  paddingBottom: '16px',
  marginTop: '50px',
}));

const StyleFormTag = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '16px',
  right: 0,
  backgroundColor: theme.xPalette.white,
  borderRadius: '16px 16px 0 0',
  '&.MuiBox-root .MuiTypography-root': {
    fontWeight: 400,
    margin: '16px 16px',
    color: theme.xPalette.lightGrey,
  },
}));

const StyleRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}));

const StyleMultipleAutocomplete = styled(Autocomplete)(() => ({
  '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    width: '100%',
  },
}));

const noState = 'NO STATE';

const handleInterviewOrMonitorTypesFilter = (values: any[]) => {
  return values
    .filter(
      (
        value: InterviewTypeItem | MonitorTypeItem,
        index: number,
        arrCurr: InterviewTypeItem[] | MonitorTypeItem[]
      ) => {
        return (
          arrCurr.findIndex((x: InterviewTypeItem | MonitorTypeItem) => x.id === value.id) ===
          arrCurr.map((x: InterviewTypeItem | MonitorTypeItem) => x.id).lastIndexOf(value.id)
        );
      }
    )
    .map((value, index) => ({ ...value, ...{ order: ++index } }));
};

export const ehrTypes = [
  { id: 'athena', name: 'Athena' },
  { id: 'epic', name: 'Epic' },
  { id: 'cerner', name: 'Cerner' },
  { id: 'meditech', name: 'Meditech' },
  { id: 'allscripts', name: 'Allscripts' },
  { id: 'eclinicalworks', name: 'eClinicalWorks' },
  { id: 'lightningStep', name: 'Lightning Step' },
];

export default function AccountForm({ accountId, data, onFormSubmit }: AccountFormProps) {
  const dispatch = useAppDispatch();
  const contentPopup = useAppSelector(selectContentPopup);
  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [isAutomateEmail, setIsAutomateEmail] = useState<boolean>(true);
  const [isAutomateIntakePacket, setIsAutomateIntakePacket] = useState<boolean>(false);
  const [isMfaEnabled, setIsMfaEnabled] = useState<boolean>(false);
  const [isSmsEnabled, setIsSmsEnabled] = useState<boolean>(false);
  const [isFollowupEnabled, setIsFollowupEnabled] = useState<boolean>(false);
  const [isFollowUpReportEnabled, setIsFollowUpReportEnabled] = useState<boolean>(false);
  const [isWhiteLabelEnabled, setIsWhiteLabelEnabled] = useState<boolean>(false);
  const [isTokenForAdmin, setIsTokenForAdmin] = useState<boolean>(false);
  const [ehrEnabled, setEhrEnabled] = useState<boolean>(false);
  const [taraEnabled, setTaraEnabled] = useState<boolean>(false);
  const [sendPdfEhr, setSendPdfEhr] = useState<boolean>(false);
  const [sendPatientReportEhr, setSendPatientReportEhr] = useState<boolean>(false);
  const [isUSA, setIsUSA] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [stateLabel, setStateLabel] = useState<string>(t('clinicSite.manageSites.siteForm.state'));
  const [isStateDisabled, setStateDisabled] = useState<boolean>(false);
  const [timezones, setTimezones] = useState<string[]>([]);

  const [interviewTypeData, setInterviewTypeData] = useState<InterviewTypeItem[]>([]);
  const [multiCheckInterviewType, setMultiCheckInterviewType] = useState<InterviewTypeItem[]>([]);

  const [monitorTypeData, setMonitorTypeData] = useState<MonitorTypeItem[]>([]);
  const [multiCheckMonitorType, setMultiCheckMonitorType] = useState<MonitorTypeItem[]>([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" color="secondary" />;

  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [identityProviderId, setIdentityProviderId] = useState<number | undefined>();
  const [editAccountAction, setEditAccountAction] = useState<string>('');
  const [hasConfigIntakePacketOnSite, setHasConfigIntakePacketOnSite] = useState<boolean>(false);
  const [callAction, setCallAction] = useState<string>('');
  const [interviewTypesData, setInterviewTypesData] = useState<any>([]);
  const [monitorTypesData, setMonitorTypesData] = useState<any>([]);
  const [siteStateData, setSiteStateData] = useState<string>('');

  const SortableListItemInterview = SortableElement(
    ({
      option,
      getTagProps,
      index,
    }: {
      key: number;
      getTagProps: any;
      index: number;
      option: InterviewTypeItem;
    }) => {
      return (
        <Chip
          deleteIcon={
            <HighlightOffIcon
              onMouseDown={(event: any) => {
                const deletedInterviewTypesData: any = [];
                const linkList = LinkedList.fromArray([...interviewTypesData]);
                linkList.removeAt(option.order - 1);

                let count = 0;
                linkList.forEach((current: any, position: any) => {
                  const cloneItem = { ...current.getValue() };
                  cloneItem.order = ++count;
                  deletedInterviewTypesData.push(cloneItem);
                });
                setMultiCheckInterviewType(deletedInterviewTypesData);
                setInterviewTypesData(deletedInterviewTypesData);
                setValue('interviewTypes', deletedInterviewTypesData);
              }}
            />
          }
          {...getTagProps({ index })}
          label={getOptionLabel(option)}
        />
      );
    }
  );

  const SortableListItemMonitor = SortableElement(
    ({
      option,
      getTagProps,
      index,
    }: {
      key: number;
      getTagProps: any;
      index: number;
      option: MonitorTypeItem;
    }) => {
      return (
        <Chip
          deleteIcon={
            <HighlightOffIcon
              onMouseDown={(event: any) => {
                const deletedMonitorTypesData: any = [];
                const linkList = LinkedList.fromArray([...monitorTypesData]);
                linkList.removeAt(option.order - 1);

                let count = 0;
                linkList.forEach((current: any, position: any) => {
                  const cloneItem = { ...current.getValue() };
                  cloneItem.order = ++count;
                  deletedMonitorTypesData.push(cloneItem);
                });

                setMultiCheckMonitorType(deletedMonitorTypesData);
                setMonitorTypesData(deletedMonitorTypesData);
                setValue('monitorTypes', deletedMonitorTypesData);
              }}
            />
          }
          {...getTagProps({ index })}
          label={getOptionLabel(option)}
        />
      );
    }
  );

  const SortableListInterview = SortableContainer(
    ({ items, getTagProps }: { getTagProps: any; items: InterviewTypeItem[] }) => {
      return (
        <ul className="list-group-interviews">
          {items.map((item, index) => {
            return (
              <SortableListItemInterview
                getTagProps={getTagProps}
                key={index}
                index={item.order}
                option={item}
              />
            );
          })}
        </ul>
      );
    }
  );

  const SortableListMonitorType = SortableContainer(
    ({ items, getTagProps }: { getTagProps: any; items: MonitorTypeItem[] }) => {
      return (
        <ul className="list-group-monitors">
          {items.map((item, index) => {
            return (
              <SortableListItemMonitor
                getTagProps={getTagProps}
                key={index}
                index={item.order}
                option={item}
              />
            );
          })}
        </ul>
      );
    }
  );

  const onSortInterviewTypesEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    const linkList = LinkedList.fromArray([...interviewTypesData]);
    const oldItem = linkList.find((current: any) => current.getValue()?.order === oldIndex);

    linkList.removeAt(oldItem?.getValue()?.order - 1);
    linkList.insertAt(newIndex - 1, oldItem);

    const data: any = [];
    let count = 0;
    linkList.forEach((current: any, position: any) => {
      const cloneItem = { ...current.getValue() };
      cloneItem.order = ++count;
      data.push(cloneItem);
    });
    const formatedData = data.sort((a: any, b: any) =>
      a['order'] < b['order'] ? -1 : a['order'] > b['order'] ? 1 : 0
    );

    setInterviewTypesData(formatedData);
    setValue('interviewTypes', formatedData);
  };

  const onSortMonitorTypesEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    const linkList = LinkedList.fromArray([...monitorTypesData]);
    const oldItem = linkList.find((current: any) => current.getValue()?.order === oldIndex);

    linkList.removeAt(oldItem?.getValue()?.order - 1);
    linkList.insertAt(newIndex - 1, oldItem);

    const data: any = [];
    let count = 0;
    linkList.forEach((current: any, position: any) => {
      const cloneItem = { ...current.getValue() };
      cloneItem.order = ++count;
      data.push(cloneItem);
    });
    const formatedData = data.sort((a: MonitorTypeItem, b: MonitorTypeItem) =>
      a['order'] < b['order'] ? -1 : a['order'] > b['order'] ? 1 : 0
    );

    setMonitorTypesData(formatedData);
    setValue('monitorTypes', formatedData);
  };

  const identityProviderContentPopup = {
    title: t('account.manageAccounts.identityProviderPopup.title'),
    description: t('account.manageAccounts.identityProviderPopup.description'),
    btnOk: t('account.manageAccounts.identityProviderPopup.btnOk'),
    btnClose: t('account.manageAccounts.identityProviderPopup.btnClose'),
  } as PopupContent;
  const mfaContentPopup = {
    title: t('account.manageAccounts.mfaPopup.title'),
    description: t('account.manageAccounts.mfaPopup.description'),
    btnOk: t('account.manageAccounts.mfaPopup.btnOk'),
    btnClose: t('account.manageAccounts.mfaPopup.btnClose'),
  } as PopupContent;

  const intakePacketContentPopup = {
    title: t('account.manageAccounts.intakePacketPopup.title'),
    description: t('account.manageAccounts.intakePacketPopup.description'),
    btnOk: t('account.manageAccounts.intakePacketPopup.btnOk'),
    btnClose: t('account.manageAccounts.intakePacketPopup.btnClose'),
  } as PopupContent;

  const showIdentityProviderPopup = async (identityProviderId?: number) => {
    dispatch(setContentPopup({ content: identityProviderContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setIdentityProviderId(identityProviderId);
    setEditAccountAction(EditAccountActionType.SetIdentityProviderId);
  };
  const showMfaPopup = async () => {
    dispatch(setContentPopup({ content: mfaContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setEditAccountAction(EditAccountActionType.EnableMfa);
  };

  const showIntakePacketPopup = async () => {
    dispatch(setContentPopup({ content: intakePacketContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setCallAction(EditAccountActionType.DisableIntakePacket);
  };

  const onChooseActionLogin = async (successCallback?: () => void) => {
    if (identityProviderId && editAccountAction === EditAccountActionType.SetIdentityProviderId) {
      setValue('identityProviderId', identityProviderId);
      setIsMfaEnabled(false);
      setValue('mfaEnabled', false);
    }
    if (editAccountAction === EditAccountActionType.EnableMfa) {
      setIsMfaEnabled(true);
      setValue('mfaEnabled', true);
      setValue('identityProviderId', undefined);
    }
    if (successCallback) {
      successCallback();
    }
  };

  const onProcessIntakePacket = async (successCallback?: () => void) => {
    setIsAutomateIntakePacket(false);
    setValue('automateIntakePacket', false);
    if (successCallback) {
      successCallback();
    }
  };

  const { data: identityProviderActiveData } = useGetIdentityProvidersActiveQuery();
  const identityProviders =
    identityProviderActiveData &&
    identityProviderActiveData.identityProviders &&
    identityProviderActiveData.identityProviders.length > 0
      ? identityProviderActiveData.identityProviders
      : [];

  const validationSchema = yup
    .object()
    .shape(
      {
        accountName: yup
          .string()
          .trim()
          .required(t('account.manageAccounts.error.blankAccountName')),
        accountType: yup.string().required(t('account.manageAccounts.error.blankAccountType')),
        exportSetting: yup.string().required(t('account.manageAccounts.error.blankExportSetting')),
        automateEmail: yup.boolean(),
        mfaEnabled: yup.boolean(),
        followUpEnabled: yup.boolean(),
        followUpReportEnabled: yup.boolean(),

        siteName: accountId
          ? yup.string()
          : yup.string().required(t('clinicSite.manageSites.error.blankName')),
        siteLocation: accountId
          ? yup.string().trim()
          : yup.string().trim().required(t('clinicSite.manageSites.error.blankLocation')),
        siteCountry: accountId
          ? yup.string()
          : yup.string().required(t('clinicSite.manageSites.error.blankCountry')),
        siteAddressLine1: accountId
          ? yup.string().trim()
          : yup.string().trim().required(t('clinicSite.manageSites.error.blankAddressLine1')),
        siteAddressLine2: yup.string(),
        siteCity: accountId
          ? yup.string().trim()
          : yup.string().trim().required(t('clinicSite.manageSites.error.blankCity')),
        siteState: accountId
          ? yup.string()
          : yup
              .string()
              .typeError(`${t('clinicSite.manageSites.error.blankState')}`)
              .test('site-state', '', function (state) {
                if (!state && state !== noState) {
                  return this.createError({ message: t(getStateErrorKey(stateLabel)) });
                }
                return true;
              }),
        siteZipCode: accountId
          ? yup.string()
          : yup.string().required(t('clinicSite.manageSites.error.blankZipCode')),
        siteTimezone: accountId
          ? yup.string()
          : yup.string().required(t('clinicSite.manageSites.error.blankTimeZone')),

        userFirstName: accountId
          ? yup.string().trim()
          : yup.string().trim().required(t('user.manageUsers.error.blankFirstName')),
        userLastName: accountId
          ? yup.string().trim()
          : yup.string().trim().required(t('user.manageUsers.error.blankLastName')),
        userEmail: accountId
          ? yup.string().trim()
          : yup
              .string()
              .trim()
              .email(t('user.manageUsers.error.invalidEmail'))
              .required(t('user.manageUsers.error.blankEmail')),
        userPhone: accountId
          ? yup.string().trim()
          : yup.string().when('userCellPhone', {
              is: '',
              then: yup
                .string()
                .trim()
                .required(t('account.manageAccounts.error.blankPhoneNumber'))
                .test(
                  'validate-phone-number',
                  t('user.manageUsers.error.phoneNumberTooShort', {
                    minLength: PhoneNumberRequired.MinLengthPhoneNumber,
                  }),
                  function (userPhone) {
                    return validatePhoneNumber(userPhone);
                  }
                ),
            }),
        userCellPhone: accountId
          ? yup.string().trim()
          : yup.string().when('userPhone', {
              is: '',
              then: yup
                .string()
                .trim()
                .required(t('account.manageAccounts.error.blankPhoneNumber'))
                .test(
                  'validate-cell-phone',
                  t('user.manageUsers.error.cellPhoneTooShort', {
                    minLength: PhoneNumberRequired.MinLengthPhoneNumber,
                  }),
                  function (userCellPhone) {
                    return validatePhoneNumber(userCellPhone);
                  }
                ),
            }),
        mfaType: yup.string(),
        whiteLabel: yup.boolean(),
        externalLink: yup.string(),
        identityProviderId: yup.mixed(),
        tokenForAdminOnly: yup.boolean(),
        interviewTypes: yup.mixed(),
        monitorTypes: yup.mixed(),
        organizationId: ehrEnabled
          ? yup.string().required(t('account.manageAccounts.error.blankOrganizationId'))
          : yup.string(),
        ehrType: ehrEnabled
          ? yup.string().required(t('account.manageAccounts.error.blankEHRType'))
          : yup.string(),
        removeDays: yup
          .mixed()
          .nullable()
          .test(
            'validate-removeDays',
            t('account.manageAccounts.error.invalidRemoveDays', {
              maxRemoveDays: DefaultRemovedDays,
            }),
            function (removeDays) {
              if (removeDays && isNaN(removeDays)) {
                return false;
              }

              if (!removeDays) {
                return true;
              }

              if (removeDays < 0 || removeDays > DefaultRemovedDays) {
                return false;
              }

              return true;
            }
          ),
      },
      [['userPhone', 'userCellPhone']]
    )
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    getValues,
  } = useForm<AccountFormInfo>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  // bind controls based on selected country code
  useEffect(() => {
    if (countryCode) {
      setIsUSA(countryCode === USCountryCode);
      const { adminDistrict, timezone } = sortedCountryCodes[countryCode];
      setTimezones(Array.isArray(timezone) ? timezone : [timezone]);
      setStateLabel(adminDistrict);
      const isNoState = adminDistrict === noState;
      setStateDisabled(isNoState);
      if (isNoState) {
        setValue('siteState', noState);
        setSiteStateData(noState);
      }
      setValue('siteCountry', countryCode);
      setCountryCode(countryCode);
    }
  }, [countryCode, setValue, isUSA]);

  const handleSetInterviewTypeData = useCallback((data: AccountResponse) => {
    setInterviewTypeData(data.availableInterviewTypes);
  }, []);

  const handleSetAccountInterviewType = useCallback(
    (data: AccountResponse) => {
      setMultiCheckInterviewType(data.accountInterviewTypes);
      setInterviewTypesData(data.accountInterviewTypes);
      setValue('interviewTypes', data.accountInterviewTypes);
    },
    [setValue]
  );

  const handleSetMonitorTypeData = useCallback((data: AccountResponse) => {
    setMonitorTypeData(data.availableMonitorTypes);
  }, []);

  const handleSetAccountMonitorType = useCallback(
    (data: AccountResponse) => {
      setMultiCheckMonitorType(data.accountMonitorTypes);
      setMonitorTypesData(data.accountMonitorTypes);
      setValue('monitorTypes', data.accountMonitorTypes);
    },
    [setValue]
  );
  const handleSetIdentityProviderId = useCallback(
    (id: number) => {
      setValue('identityProviderId', id);
    },
    [setValue]
  );

  useEffect(() => {
    let mounted = true;
    if (!!accountId && data) {
      const bindEditControls = () => {
        if (mounted) {
          Object.keys(defaultFormValues).forEach((field: any) => {
            if (field === 'removeDays') {
              setValue(field, data[field as keyof AccountResponse] || 0);
            } else {
              setValue(field, checkNullData(data[field as keyof AccountResponse]));
            }
          });

          setIsAutomateEmail(data.automateEmail);
          setIsAutomateIntakePacket(data.automateIntakePacket);
          setIsFollowupEnabled(data.followUpEnabled);
          setIsFollowUpReportEnabled(data.followUpReportEnabled);
          setIsMfaEnabled(data.mfaEnabled);
          setIsWhiteLabelEnabled(data.whiteLabel);
          setIsTokenForAdmin(data.tokenForAdminOnly);
          setApiKey(data.apiKey);
          setHasConfigIntakePacketOnSite(data.hasConfigIntakePacketOnSite);
          setEhrEnabled(data.ehrEnabled);
          setIsSmsEnabled(data.smsEnabled);
          setTaraEnabled(data.taraEnabled);
          setSendPdfEhr(data.sendPdfEhr);
          setSendPatientReportEhr(data.sendPatientReportEhr);

          handleSetInterviewTypeData(data);
          handleSetAccountInterviewType(data);

          handleSetMonitorTypeData(data);
          handleSetAccountMonitorType(data);
          if (data.identityProviderId) {
            handleSetIdentityProviderId(data.identityProviderId);
          }
        }
      };

      bindEditControls();
      return () => {
        mounted = false;
      };
    }
  }, [
    accountId,
    data,
    handleSetAccountInterviewType,
    handleSetAccountMonitorType,
    handleSetIdentityProviderId,
    handleSetInterviewTypeData,
    handleSetMonitorTypeData,
    setValue,
  ]);

  const filterCountryOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });

  const filterStateOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });

  const filterOptionsTimezone = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option,
  });

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getOptionLabel(option),
  });

  const getOptionLabel = (option: any) => `${option.name} (${populateLanguage(option.language)})`;

  const handleCountryChange = (e: any, values: any) => {
    const selectedValue = values ? values.code : '';

    setValue('siteState', '');
    setSiteStateData('');

    setValue('siteTimezone', '');
    setCountryCode(selectedValue);
    setValue('siteCountry', selectedValue);

    if (!selectedValue) {
      setStateDisabled(true);
    }
  };

  const handleStateChange = (e: any, values: any) => {
    const selectedState = values ? values.code : '';
    setValue('siteState', selectedState);
    setSiteStateData(selectedState);

    if (!selectedState) {
      setValue('siteTimezone', '');
    }
  };

  const handleTimezoneChange = (e: any, values: any) => {
    const selectedState = values || '';
    setValue('siteTimezone', selectedState);
  };

  const handleInterviewTypesChanged = (e: any, values: any) => {
    const finalInterviewTypes = handleInterviewOrMonitorTypesFilter(values);

    setMultiCheckInterviewType(finalInterviewTypes);
    setInterviewTypesData(finalInterviewTypes);
    setValue('interviewTypes', finalInterviewTypes);
  };

  const handleMonitorTypesChanged = (e: any, values: any) => {
    const finalMonitorTypes = handleInterviewOrMonitorTypesFilter(values);

    setMultiCheckMonitorType(finalMonitorTypes);
    setMonitorTypesData(finalMonitorTypes);
    setValue('monitorTypes', finalMonitorTypes);
  };

  const handleIdentityProviderChange = (e: any, values: any) => {
    if (values) {
      if (isMfaEnabled) {
        showIdentityProviderPopup(values.id);
      } else {
        setValue('identityProviderId', values.id);
      }
    } else {
      setValue('identityProviderId', undefined);
    }
  };

  const handleMfaEnabledChange = (event: any) => {
    event.stopPropagation();
    const isChecked = event.target.checked;
    if (getValues('identityProviderId')) {
      showMfaPopup();
    } else {
      setIsMfaEnabled(isChecked);
      setValue('mfaEnabled', isChecked);
    }
  };
  const handleSmsEnabledChange = (event: any) => {
    event.stopPropagation();
    const isChecked = event.target.checked;
    setIsSmsEnabled(isChecked);
    setValue('smsEnabled', isChecked);
  };

  const handleAutomaticIntakePacketChange = (event: any) => {
    event.stopPropagation();
    const isChecked = event.target.checked;
    if (isAutomateIntakePacket && hasConfigIntakePacketOnSite) {
      showIntakePacketPopup();
    } else {
      setIsAutomateIntakePacket(isChecked);
      setValue('automateIntakePacket', isChecked);
    }
  };

  const handleEHRTypeChange = (event: any) => {
    const value = event.target.value;
    setValue('ehrType', value);
  };

  const filterIdentityProviderOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });

  const onSubmit = async (data: AccountFormInfo) => {
    try {
      if (!ehrEnabled && data) {
        delete data.organizationId;
        delete data.ehrType;
        delete data.sendPdfEhr;
        delete data.sendPatientReportEhr;
      }
      await onFormSubmit(data, interviewTypesData, monitorTypesData, accountId, () => {
        if (!accountId) {
          reset();
          setIsAutomateEmail(true);
          setIsAutomateIntakePacket(false);
          setIsFollowupEnabled(false);
          setIsFollowUpReportEnabled(false);
          setIsMfaEnabled(false);
          setIdentityProviderId(undefined);
          setEhrEnabled(false);
          setTaraEnabled(false);
        }
        setPageErrors([]);
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <Box
      sx={{
        px: matches ? 0 : 3,
        pt: 3,
      }}
    >
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
        }}
      >
        <RoundedContainer sx={{ position: 'relative' }}>
          <StyleFormTag>
            <Typography fontWeight="bold">
              {t('account.manageAccounts.formTabs.account')}
            </Typography>
          </StyleFormTag>
          <StyleFormItem>
            <Controller
              name="accountName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.accountName}
                  helperText={errors.accountName?.message}
                  margin="normal"
                  fullWidth
                  label={t('account.manageAccounts.accountForm.accountName')}
                  variant="outlined"
                />
              )}
            />
            {accountId && (
              <TextField
                disabled
                margin="normal"
                fullWidth
                value={apiKey}
                label={t('account.manageAccounts.accountForm.apiKey')}
                variant="outlined"
              />
            )}
            {accountId && (
              <Controller
                name="identityProviderId"
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <Autocomplete
                    {...props}
                    fullWidth
                    data-testid="mui-component-select-identity-provider-id"
                    options={identityProviders || []}
                    getOptionLabel={(option: any) => (option.name ? option.name : '')}
                    onChange={handleIdentityProviderChange}
                    sx={{ width: '100%' }}
                    value={
                      value ? identityProviders.find((item: any) => item?.id === value) || [] : null
                    }
                    filterOptions={filterIdentityProviderOptions}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.id} value={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.identityProviderId}
                        helperText={errors.identityProviderId?.message}
                        margin="normal"
                        fullWidth
                        label={t('account.manageAccounts.accountForm.ssoClientConfig.title')}
                      />
                    )}
                  />
                )}
              />
            )}

            {accountId && (
              <Controller
                name="interviewTypes"
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <StyleMultipleAutocomplete
                    fullWidth
                    {...props}
                    multiple
                    disableCloseOnSelect
                    options={interviewTypeData}
                    getOptionLabel={getOptionLabel}
                    renderTags={(tagValue, getTagProps) => {
                      return (
                        <SortableListInterview
                          axis={'xy'}
                          getTagProps={getTagProps}
                          items={interviewTypesData}
                          onSortEnd={onSortInterviewTypesEnd}
                          distance={1}
                        />
                      );
                    }}
                    value={value || multiCheckInterviewType}
                    onChange={handleInterviewTypesChanged}
                    filterOptions={filterOptions}
                    disableClearable={true}
                    renderOption={(props, option: any, { selected }) => {
                      return (
                        <li {...props} key={option.id} value={option.id}>
                          <Checkbox
                            key={option.id}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={
                              selected ||
                              multiCheckInterviewType.some(
                                (interviewType) => interviewType.id === option.id
                              )
                            }
                          />
                          {getOptionLabel(option)}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        label={t('account.manageAccounts.accountForm.interviewType')}
                        placeholder={t('account.manageAccounts.accountForm.searchInterview')}
                        onKeyDown={(event: any) => {
                          if (event.key === 'Backspace') {
                            event.stopPropagation();
                          }
                        }}
                      />
                    )}
                  />
                )}
              />
            )}
            {accountId && (
              <Controller
                name="monitorTypes"
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <StyleMultipleAutocomplete
                    fullWidth
                    {...props}
                    multiple
                    disableCloseOnSelect
                    options={monitorTypeData}
                    getOptionLabel={getOptionLabel}
                    value={value || multiCheckMonitorType}
                    onChange={handleMonitorTypesChanged}
                    filterOptions={filterOptions}
                    disableClearable={true}
                    renderTags={(tagValue, getTagProps) => {
                      return (
                        <SortableListMonitorType
                          axis={'xy'}
                          getTagProps={getTagProps}
                          items={monitorTypesData}
                          onSortEnd={onSortMonitorTypesEnd}
                          distance={1}
                        />
                      );
                    }}
                    renderOption={(props, option: any, { selected }) => {
                      return (
                        <li {...props} key={option.id} value={option.id}>
                          <Checkbox
                            key={option.id}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={
                              selected ||
                              multiCheckMonitorType.some(
                                (monitorType) => monitorType.id === option.id
                              )
                            }
                          />
                          {getOptionLabel(option)}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        label={t('account.manageAccounts.accountForm.monitorType')}
                        placeholder={t('account.manageAccounts.accountForm.searchMonitor')}
                        onKeyDown={(event: any) => {
                          if (event.key === 'Backspace') {
                            event.stopPropagation();
                          }
                        }}
                      />
                    )}
                  />
                )}
              />
            )}
            <StyleBox>
              <Typography fontWeight="bold" variant="body1">
                {t('account.manageAccounts.accountForm.accountType.title')}
              </Typography>
              <Controller
                name="accountType"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row={matches}>
                    <FormControlLabel
                      value="commercial"
                      labelPlacement="end"
                      control={<StyleRadio />}
                      label={`${t('account.manageAccounts.accountForm.accountType.commercial')}`}
                    />
                    <FormControlLabel
                      value="research"
                      labelPlacement="end"
                      control={<StyleRadio />}
                      label={`${t('account.manageAccounts.accountForm.accountType.research')}`}
                    />
                  </RadioGroup>
                )}
              />
              {!!errors.accountType && (
                <StyleErrorForm>
                  {errors.accountType && (errors.accountType as any).message}
                </StyleErrorForm>
              )}
            </StyleBox>
            <StyleBox>
              <Typography fontWeight="bold" variant="body1">
                {t('account.manageAccounts.accountForm.exportSetting.title')}
              </Typography>
              <Controller
                name="exportSetting"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row={matches}>
                    <FormControlLabel
                      value="native"
                      labelPlacement="end"
                      control={<StyleRadio />}
                      label={`${t('account.manageAccounts.accountForm.exportSetting.native')}`}
                    />
                    <FormControlLabel
                      value="english"
                      labelPlacement="end"
                      control={<StyleRadio />}
                      label={`${t('account.manageAccounts.accountForm.exportSetting.english')}`}
                    />
                  </RadioGroup>
                )}
              />
              {!!errors.exportSetting && (
                <StyleErrorForm>
                  {errors.exportSetting && (errors.exportSetting as any).message}
                </StyleErrorForm>
              )}
            </StyleBox>
            <StyleBox>
              <Typography fontWeight="bold" variant="body1">
                {t('account.manageAccounts.accountForm.accountSetting')}
              </Typography>
              <Controller
                name="automateEmail"
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={isAutomateEmail}
                      value={isAutomateEmail}
                      onChange={(event: any) => {
                        setIsAutomateEmail(event.target.checked);
                      }}
                      label={`${t('account.manageAccounts.accountForm.automateEmail')}`}
                    />
                  </FormGroup>
                )}
              />

              <Controller
                name="mfaEnabled"
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={isMfaEnabled}
                      value={isMfaEnabled}
                      onChange={handleMfaEnabledChange}
                      label={`${t('account.manageAccounts.accountForm.mfaEnabled')}`}
                    />
                  </FormGroup>
                )}
              />

              <Controller
                name="automateIntakePacket"
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={isAutomateIntakePacket}
                      value={isAutomateIntakePacket}
                      onChange={handleAutomaticIntakePacketChange}
                      label={`${t('account.manageAccounts.accountForm.automateIntakePacket')}`}
                    />
                  </FormGroup>
                )}
              />

              <Controller
                name="smsEnabled"
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={isSmsEnabled}
                      value={isSmsEnabled}
                      onChange={handleSmsEnabledChange}
                      label={`${t('account.manageAccounts.accountForm.smsEnabled')}`}
                    />
                  </FormGroup>
                )}
              />

              <Box sx={{ mt: 2 }}>
                <Typography fontWeight="bold" variant="body1">
                  {t('account.manageAccounts.accountForm.removeDays')}
                </Typography>
                {/* <Box
                  maxWidth="xl"
                  sx={{
                    backgroundColor: theme.xPalette.background,
                    padding: 1,
                    display: 'flex',
                    width: 1,
                  }}
                > */}
                <Alert severity="info" sx={{ p: '0px 8px' }}>
                  <Typography component="span" variant="body2" fontWeight="bold">
                    {t('account.manageAccounts.accountForm.pleaseNote')}
                  </Typography>
                  <br />
                  <Typography component="span" variant="subtitle2" fontWeight={500}>
                    {t('account.manageAccounts.accountForm.removeDaysNotice')}
                  </Typography>
                  <br />
                  <Typography component="span" variant="subtitle2" fontWeight={500}>
                    {t('account.manageAccounts.accountForm.removeDefault')}
                  </Typography>
                </Alert>
                {/* </Box> */}
                <Controller
                  name="removeDays"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: '64%' }}
                      error={!!errors.removeDays}
                      helperText={errors.removeDays?.message}
                      margin="normal"
                      label={t('account.manageAccounts.accountForm.days')}
                      variant="outlined"
                    />
                  )}
                />
              </Box>
            </StyleBox>

            <StyleBox>
              <Typography fontWeight="bold" variant="body1">
                {t('account.manageAccounts.accountForm.clinicalFollowupSettings.title')}
              </Typography>
              <Controller
                name="followUpEnabled"
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={isFollowupEnabled}
                      value={isFollowupEnabled}
                      onChange={(event: any) => {
                        const isChecked = event.target.checked;
                        setIsFollowupEnabled(isChecked);
                        if (!isChecked) {
                          setIsFollowUpReportEnabled(isChecked);
                          setValue('followUpReportEnabled', isChecked);
                        }
                      }}
                      label={`${t(
                        'account.manageAccounts.accountForm.clinicalFollowupSettings.followUpEnabled'
                      )}`}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                name="followUpReportEnabled"
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={isFollowUpReportEnabled}
                      value={isFollowUpReportEnabled}
                      onChange={(event: any) => {
                        setIsFollowUpReportEnabled(event.target.checked);
                      }}
                      disabled={!isFollowupEnabled}
                      label={`${t(
                        'account.manageAccounts.accountForm.clinicalFollowupSettings.followUpReportEnabled'
                      )}`}
                    />
                  </FormGroup>
                )}
              />
            </StyleBox>
            {accountId && (
              <StyleBox>
                <Typography fontWeight="bold" variant="body1">
                  {t('account.manageAccounts.accountForm.whiteLabel.title')}
                </Typography>
                <Controller
                  name="whiteLabel"
                  control={control}
                  render={({ field }) => (
                    <FormGroup {...field}>
                      <FormControlLabel
                        control={<StyledCheckbox />}
                        checked={isWhiteLabelEnabled}
                        value={isWhiteLabelEnabled}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;
                          setIsWhiteLabelEnabled(isChecked);
                        }}
                        label={`${t('account.manageAccounts.accountForm.whiteLabel.enabled')}`}
                      />
                    </FormGroup>
                  )}
                />
              </StyleBox>
            )}
            {accountId && (
              <StyleBox>
                <Typography fontWeight="bold" variant="body1">
                  {t('account.manageAccounts.accountForm.tokenForAdminOnly.title')}
                </Typography>
                <Controller
                  name="tokenForAdminOnly"
                  control={control}
                  render={({ field }) => (
                    <FormGroup {...field}>
                      <FormControlLabel
                        control={<StyledCheckbox />}
                        checked={isTokenForAdmin}
                        value={isTokenForAdmin}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;
                          setIsTokenForAdmin(isChecked);
                        }}
                        label={`${t(
                          'account.manageAccounts.accountForm.tokenForAdminOnly.enabled'
                        )}`}
                      />
                    </FormGroup>
                  )}
                />
              </StyleBox>
            )}
            {accountId && (
              <StyleBox>
                <Typography fontWeight="bold" variant="body1">
                  {t('account.manageAccounts.accountForm.externalLinks.title')}
                </Typography>
                <Controller
                  name="externalLink"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      row={matches}
                      sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <FormControlLabel
                        value="externalClinicianRated"
                        labelPlacement="end"
                        control={<StyleRadio />}
                        label={`${t(
                          'account.manageAccounts.accountForm.externalLinks.clinicianRated'
                        )}`}
                      />
                      <FormControlLabel
                        value="externalPatientRated"
                        labelPlacement="end"
                        control={<StyleRadio />}
                        label={`${t(
                          'account.manageAccounts.accountForm.externalLinks.patientRate'
                        )}`}
                      />
                      <FormControlLabel
                        value="no_permission"
                        labelPlacement="end"
                        control={<StyleRadio />}
                        label={`${t(
                          'account.manageAccounts.accountForm.externalLinks.noPermission'
                        )}`}
                      />
                    </RadioGroup>
                  )}
                />
                {!!errors.accountType && (
                  <StyleErrorForm>
                    {errors.accountType && (errors.accountType as any).message}
                  </StyleErrorForm>
                )}
              </StyleBox>
            )}

            <StyleBox>
              <Typography fontWeight="bold" variant="body1">
                {t('account.manageAccounts.accountForm.ehrSetting.title')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Controller
                  name="ehrEnabled"
                  control={control}
                  render={({ field }) => (
                    <FormGroup {...field}>
                      <FormControlLabel
                        control={<StyledCheckbox />}
                        checked={ehrEnabled}
                        value={ehrEnabled}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;
                          setEhrEnabled(isChecked);
                        }}
                        label={`${t('account.manageAccounts.accountForm.ehrSetting.enableEHR')}`}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="sendPdfEhr"
                  control={control}
                  render={({ field }) => (
                    <FormGroup {...field}>
                      <FormControlLabel
                        control={<StyledCheckbox />}
                        checked={sendPdfEhr}
                        value={sendPdfEhr}
                        disabled={!ehrEnabled}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;
                          setSendPdfEhr(isChecked);
                        }}
                        label={`${t('account.manageAccounts.accountForm.ehrSetting.sendPdfEhr')}`}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="sendPatientReportEhr"
                  control={control}
                  render={({ field }) => (
                    <FormGroup {...field}>
                      <FormControlLabel
                        control={<StyledCheckbox />}
                        checked={sendPatientReportEhr}
                        value={sendPatientReportEhr}
                        disabled={!ehrEnabled}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;
                          setSendPatientReportEhr(isChecked);
                        }}
                        label={`${t(
                          'account.manageAccounts.accountForm.ehrSetting.sendPatientReportToEhr'
                        )}`}
                      />
                    </FormGroup>
                  )}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: matches ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Controller
                  name="organizationId"
                  control={control}
                  render={({ field }) => (
                    <StyleTextField
                      {...field}
                      disabled={!ehrEnabled}
                      error={!!errors.organizationId}
                      helperText={errors.organizationId?.message}
                      margin="normal"
                      fullWidth
                      label={t('account.manageAccounts.accountForm.ehrSetting.organizationId')}
                      variant="outlined"
                    />
                  )}
                />
                <Controller
                  name="ehrType"
                  control={control}
                  render={({ field }) => (
                    <StyleTextField
                      {...field}
                      disabled={!ehrEnabled}
                      margin="normal"
                      select
                      fullWidth
                      label={t('account.manageAccounts.accountForm.ehrSetting.ehrType')}
                      error={!!errors.ehrType}
                      helperText={errors.ehrType?.message}
                      variant="outlined"
                      onChange={handleEHRTypeChange}
                    >
                      {ehrTypes?.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </StyleTextField>
                  )}
                />
              </Box>
            </StyleBox>
            <StyleBox>
              <Typography fontWeight="bold" variant="body1">
                {t('account.manageAccounts.accountForm.taraSetting.title')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: matches ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Controller
                  name="taraEnabled"
                  control={control}
                  render={({ field }) => (
                    <FormGroup {...field}>
                      <FormControlLabel
                        control={<StyledCheckbox />}
                        checked={taraEnabled}
                        value={taraEnabled}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;
                          setTaraEnabled(isChecked);
                        }}
                        label={`${t('account.manageAccounts.accountForm.taraSetting.enableTARA')}`}
                      />
                    </FormGroup>
                  )}
                />
              </Box>
            </StyleBox>
          </StyleFormItem>
          {accountId && (
            <SubmitButton
              content={
                accountId
                  ? t('account.manageAccounts.accountForm.update')
                  : t('account.manageAccounts.accountForm.create')
              }
              matches={matches}
              pageErrors={pageErrors}
            />
          )}
        </RoundedContainer>

        {!accountId && (
          <RoundedContainer sx={{ position: 'relative' }}>
            <StyleFormTag>
              <Typography fontWeight="bold">{t('account.manageAccounts.formTabs.site')}</Typography>
            </StyleFormTag>
            <StyleFormItem>
              <Controller
                name="siteName"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.siteName}
                    helperText={errors.siteName?.message}
                    margin="normal"
                    fullWidth
                    label={t('clinicSite.manageSites.siteForm.name')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="siteLocation"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.siteLocation}
                    helperText={errors.siteLocation?.message}
                    margin="normal"
                    fullWidth
                    label={t('clinicSite.manageSites.siteForm.location')}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="siteCountry"
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <StyleAutocomplete
                    {...props}
                    data-testid="mui-component-select-country"
                    options={Object.values(sortedCountryCodes)}
                    getOptionLabel={(option: any) => (option.name ? option.name : '')}
                    onChange={handleCountryChange}
                    value={value ? sortedCountryCodes[value] : null}
                    filterOptions={filterCountryOptions}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.code} value={option.code}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.siteCountry}
                        helperText={errors.siteCountry?.message}
                        margin="normal"
                        fullWidth
                        label={t('clinicSite.manageSites.siteForm.country')}
                      />
                    )}
                  />
                )}
              />

              <Controller
                name="siteAddressLine1"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.siteAddressLine1}
                    helperText={errors.siteAddressLine1?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.accountForm.clinicSite.siteAddressLine1')}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="siteAddressLine2"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.siteAddressLine2}
                    helperText={errors.siteAddressLine2?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.accountForm.clinicSite.siteAddressLine2')}
                    variant="outlined"
                  />
                )}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: matches ? 'column' : 'row',
                  justifyContent: 'space-between',
                  width: matches ? '100%' : '49%',
                }}
              >
                <Controller
                  name="siteCity"
                  control={control}
                  render={({ field }) => (
                    <StyleTextField
                      {...field}
                      error={!!errors.siteCity}
                      helperText={errors.siteCity?.message}
                      margin="normal"
                      fullWidth
                      label={t('clinicSite.manageSites.siteForm.city')}
                      variant="outlined"
                    />
                  )}
                />
                <Controller
                  name="siteState"
                  control={control}
                  render={({ fieldState, formState, field, ...props }) =>
                    isUSA ? (
                      <StyleAutocomplete
                        {...props}
                        data-testid="mui-component-select-state"
                        options={usStates}
                        getOptionLabel={(option: any) => (option.name ? option.name : '')}
                        onChange={handleStateChange}
                        value={
                          field.value ? usStates.find((state) => state.code === field.value) : null
                        }
                        filterOptions={filterStateOptions}
                        disabled={isStateDisabled || !countryCode}
                        renderOption={(props, option: any) => {
                          return (
                            <li {...props} key={option.code} value={option.code}>
                              {option.name}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.siteState}
                            helperText={errors.siteState?.message}
                            margin="normal"
                            fullWidth
                            label={stateLabel}
                          />
                        )}
                      />
                    ) : (
                      <StyleTextField
                        {...field}
                        error={!!errors.siteState}
                        helperText={errors.siteState?.message}
                        margin="normal"
                        fullWidth
                        disabled={isStateDisabled || !countryCode}
                        label={stateLabel}
                        variant="outlined"
                      />
                    )
                  }
                />
              </Box>
              <Controller
                name="siteZipCode"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.siteZipCode}
                    helperText={errors.siteZipCode?.message}
                    margin="normal"
                    fullWidth
                    label={t('clinicSite.manageSites.siteForm.zipCode')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="siteTimezone"
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <StyleAutocomplete
                    {...props}
                    data-testid="mui-component-select-timezone"
                    options={timezones || []}
                    getOptionLabel={(option: any) => option || null}
                    onChange={handleTimezoneChange}
                    disabled={isUSA && !siteStateData}
                    value={value ? timezones?.find((timezone) => timezone === value) || null : null}
                    filterOptions={filterOptionsTimezone}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option} value={option}>
                          {option}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.siteTimezone}
                        helperText={errors.siteTimezone?.message}
                        margin="normal"
                        fullWidth
                        disabled={isUSA && !siteStateData}
                        label={t('clinicSite.manageSites.siteForm.timezone')}
                      />
                    )}
                  />
                )}
              />
            </StyleFormItem>
          </RoundedContainer>
        )}

        {!accountId && (
          <RoundedContainer sx={{ position: 'relative' }}>
            <StyleFormTag>
              <Typography fontWeight="bold">{t('account.manageAccounts.formTabs.user')}</Typography>
            </StyleFormTag>
            <StyleFormItem>
              <Controller
                name="userFirstName"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.userFirstName}
                    helperText={errors.userFirstName?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.manageUsers.userForm.firstName')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="userLastName"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.userLastName}
                    helperText={errors.userLastName?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.manageUsers.userForm.lastName')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="userEmail"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.userEmail}
                    helperText={errors.userEmail?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.manageUsers.userForm.email')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="userPhone"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.userPhone}
                    helperText={errors.userPhone?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.manageUsers.userForm.phone')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="userCellPhone"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.userCellPhone}
                    helperText={errors.userCellPhone?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.manageUsers.userForm.cellPhone')}
                    variant="outlined"
                  />
                )}
              />

              {isMfaEnabled && (
                <StyleBox>
                  <Typography fontWeight="bold" variant="body1">
                    {t('account.manageAccounts.accountForm.mfaType.title')}
                  </Typography>
                  <Controller
                    name="mfaType"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} row={matches}>
                        <FormControlLabel
                          value="2"
                          labelPlacement="end"
                          control={<StyleRadio />}
                          label={`${t('account.manageAccounts.accountForm.mfaType.cellPhone')}`}
                        />
                        <FormControlLabel
                          value="1"
                          labelPlacement="end"
                          control={<StyleRadio />}
                          label={`${t('account.manageAccounts.accountForm.mfaType.email')}`}
                        />
                      </RadioGroup>
                    )}
                  />
                  {!!errors.mfaType && (
                    <StyleErrorForm>
                      {errors.mfaType && (errors.mfaType as any).message}
                    </StyleErrorForm>
                  )}
                </StyleBox>
              )}
            </StyleFormItem>
            <SubmitButton
              content={
                accountId
                  ? t('account.manageAccounts.accountForm.update')
                  : t('account.manageAccounts.accountForm.create')
              }
              matches={matches}
              pageErrors={pageErrors}
            />
          </RoundedContainer>
        )}
      </Box>
      <PopupModal
        isOpen={isOpenPopup}
        contentPopup={contentPopup}
        onClick={
          callAction === EditAccountActionType.DisableIntakePacket
            ? onProcessIntakePacket
            : onChooseActionLogin
        }
      />
    </Box>
  );
}
