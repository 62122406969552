import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomPagination from '../../../common/components/CustomPagination';
import DataList from '../../../common/components/DataList';
import { DefaultPageSize } from '../../../common/utils/commonUtils';
import { ImportPatientLineError } from '../../../types/ResponseError';

interface TableProps {
  errorLineItems?: ImportPatientLineError[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: 'rgb(254, 239, 238)',
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

export default function LineErrorTable({ errorLineItems }: TableProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const tableRef = useRef<React.RefObject<HTMLDivElement>>(null);
  const hasErrorItems = errorLineItems && errorLineItems.length > 0;
  const pageSize = DefaultPageSize;

  const { currentPage, currentData, handlePageChange, totalPage, resetCurrentPage } =
    useCustomPagination(errorLineItems || [], pageSize);

  const tableHeaders: string[] = Object.values(
    t('patient.importPatient.errorTableHeader', {
      returnObjects: true,
    })
  );

  useEffect(() => {
    if (errorLineItems) {
      resetCurrentPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorLineItems]);

  const renderDesktopData = (data: ImportPatientLineError[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <StyledTableCell key={`${header} ${index}`}>{header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: ImportPatientLineError) => {
              const [[line, description]] = Object.entries(item);
              return (
                <TableRow key={line}>
                  <StyledTableCell sx={{ wordBreak: 'break-all', minWidth: '300px' }}>
                    {line}
                  </StyledTableCell>
                  <StyledTableCell sx={{ wordBreak: 'break-word', minWidth: '160px' }}>
                    {description.join(', ')}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: ImportPatientLineError[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: ImportPatientLineError) => {
          const [[line, description]] = Object.entries(item);
          const listItems = [
            [
              <>{t('user.manageUsers.importUserTableHeader.errorDescription')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{description.join(', ')}</Box>,
            ],
          ];
          return {
            leftHeader: (
              <Typography variant="body1" fontWeight="bold" fontSize={20}>
                {line}
              </Typography>
            ),
            rightHeader: <p></p>,
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  return (
    <Box ref={tableRef}>
      {hasErrorItems && matches ? renderDesktopData(currentData) : renderMobileData(currentData)}
      <Box
        sx={{
          display: hasErrorItems ? 'flex' : 'none',
          justifyContent: 'center',
          mt: 3,
        }}
      >
        <CustomPagination
          changePage={(e, value) => handlePageChange(value)}
          currentPage={currentPage}
          pages={totalPage}
          pageRef={tableRef}
        />
      </Box>
    </Box>
  );
}

const useCustomPagination = (data: any[], pageSize: number) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalPage = Math.ceil(data.length / pageSize);

  const handlePageChange = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const resetCurrentPage = () => {
    setCurrentPage(1);
  };

  const currentData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return {
    currentData,
    currentPage,
    totalPage,
    handlePageChange,
    resetCurrentPage,
  };
};
