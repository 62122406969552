import { rest } from 'msw';
import { SearchPatientResponse, SearchPatientResult } from '../types/DashboardRequest';

const mocks = [
  rest.get('/v1/account/patients', (req, res, ctx) => {
    const mockPatients = [
      {
        id: 1,
        firstName: 'FName 1',
        lastName: 'LName 1',
      },
      {
        id: 2,
        firstName: 'FName 2',
        lastName: 'LName 2',
      },
    ];
    return res(ctx.json([mockPatients]));
  }),

  rest.get('/v1/account/patient-stats', (req, res, ctx) => {
    return res(
      ctx.json({
        totalPatients: 52,
        suicidalPatients: 5,
      })
    );
  }),

  rest.get('/v1/account/search-patient', (req, res, ctx) => {
    const mockPatients: SearchPatientResult[] = [
      {
        patientId: '1',
        name: 'name 1',
        firstName: 'FName 1',
        lastName: 'LName 1',
      } as SearchPatientResult,
      {
        patientId: '2',
        name: 'name 2',
        firstName: 'FName 2',
        lastName: 'LName 2',
      } as SearchPatientResult,
    ];

    const mockPatientsResponse: SearchPatientResponse = {
      patients: mockPatients,
      hasMore: true,
    };
    return res(ctx.json(mockPatientsResponse));
  }),

  rest.get('/v1/account/patient/check-has-clinical-follow-ups', (req, res, ctx) => {
    return res(
      ctx.json({
        hasFollowUps: true,
      })
    );
  }),

  rest.get('/v1/account/patient/get-clinical-follow-ups', (req, res, ctx) => {
    return res(
      ctx.json({
        patientId: '767697683080',
        nextSteps: [
          {
            id: 1,
            text: 'We recommend the patient take the Anxiety Disorder Interview - account Settings',
            followUpText: 'Would you like to start it now?  - account Settings',
            type: 1,
            action: 52,
            interviewTestIds: [3458, 3443, 3808, 3814, 3817, 3825, 4263, 4265],
          },
          {
            id: 2,
            text: 'monitor  - account Settings',
            followUpText: 'monitor  - account Settings',
            type: 2,
            action: 2,
            interviewTestIds: [3458, 3443, 3808, 3814, 3817, 3825, 4263, 4265],
          },
          {
            id: 3,
            text: 'external',
            followUpText: 'external 1',
            type: 3,
            action: 'http://lap.com',
            interviewTestIds: [3, 4],
          },
        ],
        metScoringLineItems: [
          'Next Step: Major Depressive Disorder',
          'Next Step: MINI Scoring Section',
          'STS Line Item',
        ],
      })
    );
  }),

  rest.get('/v1/user/users-list', (req, res, ctx) => {
    const page = req.url.searchParams.get('page');
    const pageSize = req.url.searchParams.get('pageSize');
    const status = req.url.searchParams.get('status');
    const search = req.url.searchParams.get('search');

    console.log(
      'page: ',
      page,
      ' ',
      'pageSize: ',
      pageSize,
      ' ',
      'status: ',
      status,
      ' ',
      'search: ',
      search
    );

    if ((!status || status === 'active') && !search) {
      return res(
        ctx.json({
          userList: [
            {
              userId: 1,
              name: 'This is active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
            {
              userId: 2,
              name: 'This is active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
            {
              userId: 3,
              name: 'This is active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
            {
              userId: 4,
              name: 'This is active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
            {
              userId: 5,
              name: 'This is active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
            {
              userId: 6,
              name: 'This is active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
            {
              userId: 7,
              name: 'This is active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
            {
              userId: 8,
              name: 'This is active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
            {
              userId: 9,
              name: 'This is active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
            {
              userId: 10,
              name: '011f29a329_kms_116, a75e1800bf_kms_116',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'active',
            },
          ],
          totalItems: 30,
        })
      );
    }

    if (status === 'deactivated' && !search) {
      return res(
        ctx.json({
          userList: [
            {
              userId: 1,
              name: 'This is deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
            {
              userId: 2,
              name: 'This is deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
            {
              userId: 3,
              name: 'This is deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
            {
              userId: 4,
              name: 'This is deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
            {
              userId: 5,
              name: 'This is deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
            {
              userId: 6,
              name: 'This is deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
            {
              userId: 7,
              name: 'This is deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
            {
              userId: 8,
              name: 'This is deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
            {
              userId: 9,
              name: 'This is deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
            {
              userId: 10,
              name: '011f29a329_kms_116, a75e1800bf_kms_116',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
          ],
          totalItems: 20,
        })
      );
    }

    if (status === 'active' && !!search) {
      return res(
        ctx.json({
          userList: [
            {
              userId: 1,
              name: 'This is searching active user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
          ],
          totalItems: 20,
        })
      );
    }

    if (status === 'deactivated' && !!search) {
      return res(
        ctx.json({
          userList: [
            {
              userId: 1,
              name: 'This is searching deactivated user',
              email: 'test@gmail.com',
              account: 'Medical University of South Carolina (4088711343)',
              site: 'Department of Psychiatry and Behavioral Sciences',
              userRole: 'Account Admin',
              dateCreated: '11/22/2019',
              status: 'deactivated',
            },
          ],
          totalItems: 20,
        })
      );
    }
  }),

  rest.get('/v1/user/user-info', (req, res, ctx) => {
    const userId = req.url.searchParams.get('userId');

    if (userId) {
      return res(
        ctx.json({
          userId,
          userRole: 5,
          clinicSiteId: [287],
          clinicAccountId: '2461581107',
          firstName: 'Nhat',
          lastName: 'Cao',
          email: 'test@gmail.com',
          phone: '0352956126',
          cellPhone: '0352956126',
          allowTrialInterview: true,
          disableSystemLogin: true,
        })
      );
    }
  }),

  rest.get('/v1/account/patient/diagnoses', (req, res, ctx) => {
    const patientId = req.url.searchParams.get('patientId');

    if (patientId) {
      return res(
        ctx.json({
          diagnoses: [
            {
              id: 1,
              name: 'Major Depressive Disorder',
            },
            {
              id: 2,
              name: 'Bipolar Disorder',
            },
            {
              id: 3,
              name: 'Manic Disorder',
            },
          ],
        })
      );
    }
  }),

  rest.get('/v1/account/patient/specifiers', (req, res, ctx) => {
    const patientId = req.url.searchParams.get('patientId');

    if (patientId) {
      return res(
        ctx.json({
          specifiers: [
            {
              id: 1,
              name: 'with Depersonalization',
            },
            {
              id: 2,
              name: 'with M.I.N.I',
            },
            {
              id: 3,
              name: 'Manic Disorder',
            },
          ],
        })
      );
    }
  }),

  rest.get('/v1/account/patient/patient-diagnoses', (req, res, ctx) => {
    const patientId = req.url.searchParams.get('patientId');

    if (patientId) {
      return res(
        ctx.json({
          diagnosisItems: [
            {
              id: 1,
              diagnosisName: 'Major Depressive Disorder',
              specifierName: 'with Depersonalization',
              createAt: '10/02/2022 | 05:36 PM',
              patientName: 'Libby Dodson',
            },
            {
              id: 2,
              diagnosisName: 'Bipolar Disorder',
              specifierName: 'with M.I.N.I',
              createAt: '10/02/2022 | 05:36 PM',
              patientName: 'Libby Dodson',
            },
          ],
        })
      );
    }
  }),

  rest.get('/v1/account/patient/patient-diagnosis-info', (req, res, ctx) => {
    const patientId = req.url.searchParams.get('patientId');
    const diagnosisId = req.url.searchParams.get('diagnosisId');

    if (patientId && diagnosisId) {
      return res(
        ctx.json({
          patientId: '1',
          diagnosis: 1,
          specifier: 1,
          diagnosisName: 'Major Depressive Disorder',
          specifierName: 'with Depersonalization',
          otherDiagnosis: '',
        })
      );
    }
  }),
];

export default process.env.REACT_APP_ACCOUNT_MOCK_DISABLED === 'true' ? [] : mocks;
