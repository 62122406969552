import { ChangeEvent, useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../app/hooks';
import { toTitleCase } from '../../../common/utils/stringUtils';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectResetQuestion } from '../interviewSlice';

const StyledTypography = styled(Typography)(() => ({
  marginBottom: '8px',
}));

export const StyledFormLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: 0,
  width: '25%',
  [theme.breakpoints.down('lg')]: {
    width: '30%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledFormGroup = styled(FormGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  marginLeft: '8px',
}));

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.xPalette.completed.color,
  },
}));

export default function MultiOptionsQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  optionChangeCallback,
  sequenceOrder,
}: InterviewQuestionProps) {
  const [multiOptions, setMultiOptions] = useState<string[]>([]);
  const debounce = useRef<boolean>(true);
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);

  const renderItems = () => {
    const { possibleAnswers, displayAnswers } = question;
    const items = [];
    for (let i = 0; i < possibleAnswers.length; i++) {
      const value = possibleAnswers[i];
      const text = toTitleCase(
        displayAnswers?.length === possibleAnswers?.length
          ? `${displayAnswers?.[i] || value}`.trim()
          : value.trim()
      );
      items.push(
        <StyledFormLabel
          checked={multiOptions.includes(value)}
          key={value}
          value={value}
          control={<StyledCheckBox />}
          label={text}
        />
      );
    }
    return items;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const checkExistValue = multiOptions.includes(value);
    if (checkExistValue) {
      setMultiOptions([...multiOptions.filter((item) => item !== value)]);
    } else {
      setMultiOptions([...multiOptions, value]);
    }
    if (optionChangeCallback) {
      optionChangeCallback();
    }
  };

  useEffect(() => {
    if (field?.value && typeof field?.value === 'string') {
      if (debounce.current) {
        debounce.current = false;
        const savedValue = field?.value.split('|');
        setMultiOptions(savedValue);
      }
    }
  }, [field, field?.value]);

  useEffect(() => {
    setValue(field?.name!, multiOptions.join('|'));
    if (optionChangeCallback && multiOptions.length) {
      optionChangeCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiOptions, setValue, field?.name]);

  useEffect(() => {
    if (isResetQuestion) {
      setMultiOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetQuestion]);

  return (
    <Box sx={{ my: 2 }}>
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <StyledTypography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></StyledTypography>
      <FormControl sx={{ width: '100%' }} onChange={handleChange} error={error} variant="standard">
        <StyledFormGroup {...field} className={`sequence-order-${sequenceOrder}`}>
          {renderItems()}
        </StyledFormGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
