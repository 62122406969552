export enum TreatmentSignatureStatus {
  NotSigned = 'notSigned',
  Signed = 'signed',
  Refused = 'refused',
}

export enum TreatmentSignatureProviderType {
  Provider = 'provider',
  Patient = 'patient',
  Guardian = 'guardian',
}

export enum TreatmentSignatureType {
  Participate = 'participate',
  Refuse = 'refuse',
  Email = 'email',
}

export interface TreatmentSignatureItem {
  signatureProviderType: TreatmentSignatureProviderType;
  signatureType: TreatmentSignatureType | '';
  signature?: string;
}

export interface TreatmentSignatureListRequest {
  treatmentPlanId: number;
}

export interface TreatmentSignatureListResponse {
  items: TreatmentSignatureItem[];
}

export interface DeleteTreatmentSignatureParam {
  treatmentSignatureId: number;
}

export interface getTreatmentSignatureDetailRequest {
  treatmentSignatureId: number;
}

export interface getTreatmentSignatureDetailResponse {
  data: TreatmentSignatureItem;
}

export interface TreatmentSignatureAddRequest extends TreatmentSignatureItem {
  treatmentPlanId: number;
}

export interface TreatmentSignatureEditRequest extends TreatmentSignatureItem {
  treatmentSignatureId: number;
}

export interface TreatmentSignatureEditResponse {
  data: TreatmentSignatureItem;
}
