import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import ReportNotes from '../ReportNotes';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function MultiSelectListAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
  notes,
}: QuestionAnswerProps) {
  const { t } = useTranslation();
  const { questionNameDisplay, promptDisplay, answerValue } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <>
      <Box
        key={questionAnswer.questionId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          my: 3,
          width: '100%',
          pageBreakInside: 'avoid',
        }}
      >
        <Box sx={{ width: '15%', display: 'flex', columnGap: 1 }}>
          <Typography component={'span'} fontWeight={500}></Typography>
          <Typography component={'span'} fontWeight={500}></Typography>
        </Box>
        <Box sx={{ width: '80%' }}>
          {promptDisplay && (
            <Typography
              fontWeight="bold"
              dangerouslySetInnerHTML={{
                __html: questionNameDisplay,
              }}
            ></Typography>
          )}
          <Typography fontWeight={'bold'}>
            {t('assessmentReport.labels.listOfCheckedItems')}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {answerValue &&
              Array.isArray(answerValue) &&
              questionAnswer?.possibleAnswers &&
              questionAnswer?.possibleAnswers
                .filter((answer: string) => answerValue.includes(answer))
                .map((answer: string, index: any) => (
                  <Box
                    key={index}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      bgcolor:
                        index % 2 === 0 ? theme.xPalette.noteLightGrey : theme.xPalette.white,
                    }}
                  >
                    <Typography component={'span'} fontWeight={500}>{answer}</Typography>
                  </Box>
                ))}
          </Box>
          {notes?.length && (
            <ReportNotes reportNotes={notes} questionId={questionAnswer.questionId} />
          )}
        </Box>
        <>
          <Typography
            sx={{ width: '10%', textAlign: 'right' }}
            component={'span'}
            fontWeight={'bold'}
          ></Typography>
        </>
      </Box>
    </>
  );
}
