import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { rootApi } from '../../app/services';
import { useCheckHasClinicalFollowUpsQuery } from '../../app/services/account';
import {
  useGetInterviewTypesQuery,
  useGetLatestInterviewsQuery,
  useSendInterviewEmailOrTextTheQuestionsMutation,
} from '../../app/services/interview';
import {
  useGetLatestMonitorsQuery,
  useGetMonitorTypesDataQuery,
  useSendMonitorEmailOrTextTheQuestionsMutation,
} from '../../app/services/monitor';
import {
  useGetPatientDiagnosesQuery,
  useGetPatientQuery,
  useCheckShowIntakePacketPopupQuery,
} from '../../app/services/patient';
import { useToast } from '../../app/toast';
import { ReactComponent as PatientNoteSvgIcon } from '../../assets/icons/incomplete-interviews.svg';
import { ReactComponent as TreatmentPlanSvgIcon } from '../../assets/icons/incomplete-monitors.svg';
import { ReactComponent as ClinicalFollowUpSvgIcon } from '../../assets/icons/total-monitors.svg';
import ChartIcon from '../../assets/images/chart.png';
import CustomPagination from '../../common/components/CustomPagination';
import Loading from '../../common/components/Loading';
import LocalPopupModal from '../../common/components/LocalPopupModal';
import PageHeader from '../../common/components/PageHeader';
import PatientInfoLayout from '../../common/components/PatientInfoLayout';
import DashboardLayout from '../../common/layouts/Dashboard';
import { AssessmentStatus } from '../../common/utils/assessmentStatus';
import { AssessmentType } from '../../common/utils/assessmentType';
import { DefaultPageSize } from '../../common/utils/commonUtils';
import { InterviewMode } from '../../common/utils/interviewsMode';
import { PageSource } from '../../common/utils/pageInfo';
import { getPageTitle } from '../../common/utils/pageUtils';
import { isQuickSightReportEnabled } from '../../common/utils/permissionUtils';
import IntakePacketPopupBody from '../../features/patient/IntakePacketPopupBody';
import { ContentPopup } from '../../types/CommonType';
import { PageProps } from '../../types/PageProps';
import { LatestAssessmentItem } from '../../types/PatientRequest';
import {
  IntakePopupType,
  CheckShowPopupIntakePacketResponse,
  MeasurementPacketType,
} from '../../types/PatientType';
import { PopupContent } from '../../types/PopupType';
import ErrorPage from '../error/ErrorPage';
import { setInterviewMode, setTrialInterview } from '../interview/interviewSlice';
import { setMonitorMode, setMonitorTypesData } from '../monitor/monitorSlide';
import DashboardTable from './DashboardTable';
import PatientInterviewMenu from './PatientInterviewMenu';
import { setInterviewTypesData } from './patientSlice';
import {
  fetchMissingAssessmentBatches,
  getPagedAssessmentList,
  loadAssessmentCachedData,
  mapInterviewItem,
  mapMonitorItem,
  saveAssessmentCachedData,
  savePatientDashboardPageBehaviorData,
} from './patientUtils';

interface IconTextProps {
  icon?: JSX.Element | undefined;
  text: string | undefined;
  total?: number | undefined;
  color?: string | undefined;
  backgroundColor: string | undefined;
  marginTop?: string | 'auto';
  disabled?: boolean;
  toggleNavigation?: () => void;
}

export default function PatientDashboard({ breadcrumbs }: PageProps) {
  const dispatch = useAppDispatch();
  const { patientId } = useParams();
  const pageSize = DefaultPageSize;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const toast = useToast();
  const defaultContentPopup: PopupContent = {
    title: t('patient.patientDashboard.form.intakePacketPopup.titleIntakePacket').toUpperCase(),
    btnOk: t('patient.patientDashboard.form.intakePacketPopup.btnOk').toUpperCase(),
  } as PopupContent;

  const ref = useRef<HTMLDivElement>(null);
  const tableRef = useRef<React.RefObject<HTMLDivElement>>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedAssessments, setDisplayedAssessments] = useState<LatestAssessmentItem[]>([]);
  const [isPageLoading, setPageLoading] = useState<boolean>(false);
  const [isShowPopupIntakePacket, setIsShowPopupIntakePacket] = useState<boolean>(false);
  const [contentPopupData, setContentPopupData] = useState<ContentPopup>(defaultContentPopup);
  const [isPatientHasEmailOrPhoneNumber, setIsPatientHasEmailOrPhoneNumber] =
    useState<boolean>(true);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(true);

  const [checkShowPopupIntakePacketResponse, setCheckShowPopupIntakePacketResponse] = useState<
    CheckShowPopupIntakePacketResponse | undefined
  >(undefined);

  const navBackUrl = `/dashboard/patient/${patientId}`;
  const showPatientReport = isQuickSightReportEnabled;

  const [sendInterviewEmailOrTextTheQuestions] = useSendInterviewEmailOrTextTheQuestionsMutation();

  const [sendMonitorEmailOrTextTheQuestions] = useSendMonitorEmailOrTextTheQuestionsMutation();

  const { data: diagnosisData } = useGetPatientDiagnosesQuery(
    patientId
      ? {
          patientId,
          checkDiagnoses: true,
        }
      : skipToken
  );

  const { data: infoData, error } = useGetPatientQuery(
    patientId
      ? {
          patientId,
          isWriteLog: true,
        }
      : skipToken
  );

  const { data: checkShowIntakePacketPopupResponseData } = useCheckShowIntakePacketPopupQuery({
    patientId: patientId!,
  });

  const handleEmailOrTextTheQuestions = async (
    type: AssessmentType,
    interviewResultId?: number,
    trackerResultId?: number
  ) => {
    try {
      if (type === AssessmentType.Interview) {
        await sendInterviewEmailOrTextTheQuestions({
          interviewResultId: interviewResultId!,
        }).unwrap();
      } else {
        await sendMonitorEmailOrTextTheQuestions({
          trackerResultId: trackerResultId!,
        }).unwrap();
      }
      setIsShowPopupIntakePacket(false);
      toast.publish(
        t('patient.patientDashboard.form.intakePacketPopup.sendEmailOrTextTheQuestionsSuccess'),
        'success'
      );
    } catch {
      toast.publish(
        t('patient.patientDashboard.form.intakePacketPopup.sendEmailOrTextTheQuestionsError'),
        'error'
      );
    }
  };

  const restartOrResumeAssessment = async (
    type: AssessmentType,
    interviewResultId?: number,
    trackerResultId?: number,
    trial?: boolean,
    statusData?: string
  ) => {
    setIsShowPopupIntakePacket(false);
    if (statusData === AssessmentStatus.InProgress) {
      if (type === AssessmentType.Interview) {
        dispatch(setInterviewMode({ interviewMode: InterviewMode.Patient }));
        navigate(`/dashboard/patient/${patientId}/${interviewResultId}/resume-interview`, {
          state: { resumeInterview: true },
        });
        dispatch(setTrialInterview({ trialInterview: trial || false }));
      } else {
        dispatch(setMonitorMode({ monitorMode: InterviewMode.Patient }));
        navigate(`/dashboard/patient/${patientId}/${trackerResultId}/resume-monitor`, {
          state: { resumeMonitor: true },
        });
      }
    } else {
      if (type === AssessmentType.Interview) {
        dispatch(setInterviewMode({ interviewMode: InterviewMode.Patient }));
        navigate(`/dashboard/patient/${patientId}/${interviewResultId}/start-interview`, {
          state: { resumeInterview: false },
        });
      } else {
        dispatch(setMonitorMode({ monitorMode: InterviewMode.Patient }));
        navigate(`/dashboard/patient/${patientId}/${trackerResultId}/start-monitor`, {
          state: { resumeInterview: false },
        });
      }
    }
  };

  const onIntakePacketPopupSubmit = async (currentOption?: IntakePopupType) => {
    const isValidShowPopupData =
      checkShowPopupIntakePacketResponse &&
      checkShowPopupIntakePacketResponse.type &&
      checkShowPopupIntakePacketResponse.statusData &&
      (checkShowPopupIntakePacketResponse.interviewResultId ||
        checkShowPopupIntakePacketResponse.trackerResultId);
    const assessmentType = checkShowPopupIntakePacketResponse?.interviewResultId
      ? AssessmentType.Interview
      : AssessmentType.Monitor;
    switch (currentOption) {
      case IntakePopupType.EmailAndTextTheQuestionsToThePatient:
        if (isValidShowPopupData) {
          await handleEmailOrTextTheQuestions(
            assessmentType,
            checkShowPopupIntakePacketResponse?.interviewResultId,
            checkShowPopupIntakePacketResponse?.trackerResultId
          );
        }
        break;
      case IntakePopupType.PatientWillFillOutTheQuestions:
        if (isValidShowPopupData) {
          await restartOrResumeAssessment(
            assessmentType,
            checkShowPopupIntakePacketResponse?.interviewResultId,
            checkShowPopupIntakePacketResponse?.trackerResultId,
            checkShowPopupIntakePacketResponse?.trial,
            checkShowPopupIntakePacketResponse?.statusData
          );
        }
        break;
      case IntakePopupType.DoNotSendTheIntakePacket:
        setIsShowPopupIntakePacket(false);
        break;
      default:
        break;
    }
  };

  const { data: clinicalFollowUp, refetch: refetchCheckFollowUp } =
    useCheckHasClinicalFollowUpsQuery(
      patientId
        ? {
            patientId,
          }
        : skipToken,
      { refetchOnMountOrArgChange: true }
    );

  const batchSize = 50;
  const batchPageIndex = Math.ceil((currentPage * pageSize) / batchSize);
  const {
    data: interviewResult,
    isLoading: isInterviewLoading,
    isFetching: isInterviewFetching,
    refetch: refetchLatestInterviewsQuery,
  } = useGetLatestInterviewsQuery(
    patientId ? { patientId, page: batchPageIndex, pageSize: batchSize } : skipToken
  );
  const {
    data: monitorResult,
    isLoading: isMonitorLoading,
    isFetching: isMonitorFetching,
    refetch: refetchLatestMonitorsQuery,
  } = useGetLatestMonitorsQuery(
    patientId ? { patientId, page: batchPageIndex, pageSize: batchSize } : skipToken
  );
  const isFetchingAssessments = isInterviewLoading || isMonitorLoading || isPageLoading;

  const totalPages = Math.ceil(
    ((interviewResult ? interviewResult.totalItems : 0) +
      (monitorResult ? monitorResult.totalItems : 0)) /
      pageSize
  );
  const hasAssessmentData = displayedAssessments.length > 0;
  const showPagingInfo = totalPages > 1;

  const { data: interviewTypes } = useGetInterviewTypesQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const { data: monitorTypesData } = useGetMonitorTypesDataQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const handlePageChange = (e: any, page: any) => {
    setPageLoading(true);
    setCurrentPage(page);
  };

  const handleRefetchData = () => {
    refetchLatestInterviewsQuery();
    refetchLatestMonitorsQuery();
  };

  const onClickEditPatient = () => {
    navigate(`/dashboard/edit-patient/${patientId}?src=${PageSource.PatientDashboard}`);
  };

  const onClickEditFeedback = () => {
    navigate(`/dashboard/patient/${patientId}/diagnosis`);
  };

  const onClosePopupIntakePacket = () => {
    setIsShowPopupIntakePacket(false);
  };

  useEffect(() => {
    if (patientId) {
      setTimeout(() => {
        setIsShowPopup(savePatientDashboardPageBehaviorData(patientId));
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (infoData?.email || infoData?.cellPhoneNumber) {
      setIsPatientHasEmailOrPhoneNumber(true);
    } else {
      setIsPatientHasEmailOrPhoneNumber(false);
    }
  }, [infoData]);

  useEffect(() => {
    if (
      checkShowIntakePacketPopupResponseData?.interviewResultId ||
      checkShowIntakePacketPopupResponseData?.trackerResultId
    ) {
      setIsShowPopupIntakePacket(true);
      setCheckShowPopupIntakePacketResponse(checkShowIntakePacketPopupResponseData);
      setContentPopupData({
        title:
          checkShowIntakePacketPopupResponseData.type === MeasurementPacketType.MeasurementPacket
            ? t(
                'patient.patientDashboard.form.intakePacketPopup.titleMeasurementPacket'
              ).toUpperCase()
            : t('patient.patientDashboard.form.intakePacketPopup.titleIntakePacket').toUpperCase(),
        btnOk: t('patient.patientDashboard.form.intakePacketPopup.btnOk').toUpperCase(),
      } as PopupContent);
    }
  }, [checkShowIntakePacketPopupResponseData, t]);

  useEffect(() => {
    if (interviewTypes) {
      dispatch(setInterviewTypesData({ interviewTypesData: interviewTypes }));
    }
    if (monitorTypesData) {
      dispatch(setMonitorTypesData({ monitorTypesData: monitorTypesData.trackerTypes }));
    }
  }, [interviewTypes, dispatch, monitorTypesData]);

  useEffect(() => {
    const bindData = async () => {
      if (interviewResult && monitorResult && patientId) {
        setPageLoading(true);
        // TODO: handle refresh token scenario for manual queries
        // Retrieve cache data
        let { assessments: cachedAssessments, metadata: cachedMetadata } =
          loadAssessmentCachedData(patientId);

        let aggregateAssessments: LatestAssessmentItem[] = [];
        if (!cachedMetadata[batchPageIndex] && !isInterviewFetching && !isMonitorFetching) {
          // Check and fetch missing data in batches
          const { missingDataList, cachedMetadata: updatedCachedMetadata } =
            await fetchMissingAssessmentBatches({
              patientId,
              batchPageIndex,
              batchSize,
              cachedMetadata,
              dispatch,
            });

          aggregateAssessments = [
            ...cachedAssessments,
            ...missingDataList,
            ...interviewResult.items.map((item: any) => mapInterviewItem(item)),
            ...monitorResult.items.map((item: any) => mapMonitorItem(item)),
          ].sort((a, b) => b.createdTs - a.createdTs);

          saveAssessmentCachedData(
            patientId,
            aggregateAssessments,
            updatedCachedMetadata,
            batchPageIndex
          );
        } else {
          aggregateAssessments = cachedAssessments;
        }

        // Do paging and fetch assessment details
        const nextDisplayedAssessments = await getPagedAssessmentList({
          patientId,
          currentPage,
          pageSize,
          aggregateAssessments,
          dispatch,
        });

        // bind data for initial load or when data in next page is ready
        if (
          !hasAssessmentData ||
          nextDisplayedAssessments.length ||
          (hasAssessmentData && nextDisplayedAssessments.length === 0)
        ) {
          setDisplayedAssessments(nextDisplayedAssessments);
          setPageLoading(false);
        }
        refetchCheckFollowUp();
      }
    };
    bindData();
  }, [
    interviewResult,
    monitorResult,
    patientId,
    currentPage,
    pageSize,
    batchPageIndex,
    isInterviewFetching,
    isMonitorFetching,
    hasAssessmentData,
    dispatch,
    setPageLoading,
    refetchCheckFollowUp,
  ]);

  useEffect(() => {
    return () => {
      dispatch(rootApi.util.resetApiState());
    };
  }, [dispatch]);

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  const LinkBox = ({ text, backgroundColor, disabled, toggleNavigation, icon }: IconTextProps) => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: matches ? '100%' : showPatientReport ? '23.5%' : '32%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <Button
            type="button"
            fullWidth
            variant="contained"
            disableRipple={disabled}
            disabled={disabled}
            sx={{
              borderRadius: '8px',
              color: '#000',
              minHeight: '75px',
              py: 2,
              textTransform: 'none',
              backgroundColor: { backgroundColor },
              '&:hover': {
                backgroundColor: { backgroundColor },
              },
              cursor: disabled ? 'default' : 'pointer',
            }}
            onClick={toggleNavigation}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                marginLeft: '11px',
                marginRight: '11px',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  width: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    minWidth: '42px!important',
                    height: '42px!important',
                    marginRight: '16px',
                    borderRadius: '50%',
                    background: '#8fde584f',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {icon}
                </Box>
                <Typography>{text}</Typography>
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('patient.patientDashboard.title'))}</title>
      </Helmet>
      <>
        <Container ref={ref} maxWidth="xl" disableGutters={true}>
          {isShowPopup && infoData && checkShowPopupIntakePacketResponse && (
            <LocalPopupModal
              isOpen={isShowPopupIntakePacket}
              contentPopup={contentPopupData}
              onSubmit={onIntakePacketPopupSubmit}
              onClose={onClosePopupIntakePacket}
              hasEmailOrPhoneNumber={isPatientHasEmailOrPhoneNumber}
              bodyComponent={IntakePacketPopupBody}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: 2,
              px: matches ? 2 : 3,
            }}
          >
            <PageHeader headerText={t('patient.patientDashboard.dashboard')} backUrl="/dashboard" />
          </Box>
          <PatientInfoLayout
            patientId={patientId}
            showDiagnosis
            diagnosisData={diagnosisData?.patientDiagnoses}
            onClickEditPatient={onClickEditPatient}
            onClickEditFeedback={onClickEditFeedback}
          >
            <Container
              maxWidth="xl"
              sx={{
                backgroundColor: theme.xPalette.background,
                borderRadius: '15px',
                display: 'flex',
                marginTop: '20px',
                flexDirection: matches ? 'column' : 'row',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                py: 2.75,
                gap: matches ? 2 : 0,
              }}
            >
              {showPatientReport && (
                <LinkBox
                  text={t('patientReport.title')}
                  color={theme.palette.primary.main}
                  backgroundColor={theme.xPalette.white}
                  toggleNavigation={() => {
                    navigate(`/dashboard/patient/${patientId}/patient-report`, {
                      state: { navBackUrl },
                    });
                  }}
                  disabled={!hasAssessmentData}
                  icon={<img width="22px" height="22px" src={ChartIcon} alt="chart icon" />}
                />
              )}
              <LinkBox
                text={t('patient.patientDashboard.clinicalFollowup')}
                color={theme.palette.primary.main}
                backgroundColor={theme.xPalette.white}
                toggleNavigation={() => {
                  if (clinicalFollowUp?.hasFollowUps) {
                    navigate(`/dashboard/patient/${patientId}/follow-up`);
                  }
                }}
                disabled={!clinicalFollowUp?.hasFollowUps}
                icon={<ClinicalFollowUpSvgIcon />}
              />
              <LinkBox
                text={t('patient.patientDashboard.treatmentPlans')}
                color={theme.palette.primary.main}
                backgroundColor={theme.xPalette.white}
                toggleNavigation={() => {
                  navigate(`/dashboard/patient/${patientId}/treatment-plan`);
                }}
                icon={<TreatmentPlanSvgIcon />}
              />
              <LinkBox
                text={t('patient.patientDashboard.form.patientNote')}
                color={theme.palette.primary.main}
                backgroundColor={theme.xPalette.white}
                toggleNavigation={() => {
                  navigate(`/dashboard/patient/${patientId}/notes`, { state: { patientId } });
                }}
                icon={<PatientNoteSvgIcon />}
              />
            </Container>
          </PatientInfoLayout>
        </Container>

        <Container
          sx={{
            mt: 2,
          }}
          maxWidth="xl"
          disableGutters={true}
        >
          <Box sx={{ backgroundColor: theme.xPalette.background, p: 2, borderRadius: 4 }}>
            <PatientInterviewMenu />
          </Box>

          <Box ref={tableRef} sx={{ backgroundColor: theme.xPalette.white, borderRadius: 4 }}>
            {isFetchingAssessments ? (
              <Box sx={{ mt: 2, py: 2, px: 3 }}>
                <Loading />
              </Box>
            ) : (
              <>
                {hasAssessmentData ? (
                  <>
                    <DashboardTable
                      displayedAssessments={displayedAssessments}
                      patientInfo={infoData}
                      isAddPopupModel={true}
                      refetchData={handleRefetchData}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        py: showPagingInfo ? 4 : 2,
                      }}
                    >
                      {showPagingInfo && (
                        <CustomPagination
                          changePage={(e, value) => handlePageChange(e, value)}
                          currentPage={currentPage}
                          pages={totalPages}
                          pageRef={tableRef}
                        />
                      )}
                    </Box>
                  </>
                ) : (
                  <Box sx={{ mt: 2, py: 2, px: 3 }}>
                    <Typography component="h4">{t('patient.error.blankInterview')}</Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Container>
      </>
    </DashboardLayout>
  );
}
