import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { PageStatus, selectPageStatus } from '../../common/slices/globalSlice';
import { BreadcrumbType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import ManagementChartReportComponent from './ManagementChartReportComponent';

export default function ManagementChartReportPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const targetRef = useRef<HTMLDivElement>(null);
  const pageStatus = useAppSelector(selectPageStatus);
  const isIdle = pageStatus === PageStatus.Idle;

  const newBreadcrumb: BreadcrumbType = {
    text: t('dashboard.title'),
    link: `/dashboard`,
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('managementReport.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={false}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: matches ? 'column' : 'row',
            py: 2,
            rowGap: 3,
          }}
        >
          <PageHeader headerText={t('managementReport.title')} backUrl={newBreadcrumb.link} />
        </Box>
      </Container>
      <RoundedContainer sx={{ background: 'rgb(247, 248, 249)' }}>
        {isIdle && (
          <Typography
            sx={{
              position: 'absolute',
              top: 190,
              right: 45,
              padding: '10px',
              fontSize: '14px !important',
              fontWeight: 500,
            }}
          >
            {t('managementReport.instruction')}
          </Typography>
        )}
        <Box ref={targetRef}>
          <ManagementChartReportComponent />
        </Box>
      </RoundedContainer>
    </DashboardLayout>
  );
}
