import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ScoringLineItemNextStep } from '../../../types/ReportType';

interface ReportNextStepProps {
  nextSteps?: ScoringLineItemNextStep[];
}

export default function ReportNextSteps({ nextSteps }: ReportNextStepProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="body1" fontWeight="bold">
        {t('assessmentReport.screenerReport.nextStep')}
      </Typography>
      <Box component="ul">
        {nextSteps?.map((nextStep: any) => (
          <Typography component="li" key={nextStep.id} fontSize={'16px'}>
            {nextStep.reportText}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
