import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { buildMonitorConductUrl } from '../../app/routes';
import { useCheckExpireTokenMutation } from '../../app/services/interview';
import {
  useGetPatientMonitorSettingsQuery,
  useMonitorCheckExpireTokenMutation,
} from '../../app/services/monitor';
import Loading from '../../common/components/Loading';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { landingTextStyle } from '../../common/utils/commonStyles';
import { InterviewSettingsViewName as MonitorSettingsViewName } from '../../common/utils/interviewSettings';
import { MfaInterviewType, MfaType } from '../../common/utils/mfa';
import { MonitorModeName } from '../../common/utils/monitorsMode';
import { ResponseError } from '../../types/ResponseError';
import MfaPage from '../auth/MfaPage';
import { clearCredentials } from '../auth/authSlice';
import { setMonitorMode, setPatientMonitorData } from './monitorSlide';

enum MonitorStatus {
  InProgress = 'inProgress',
}

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '16px 0',
  borderRadius: '8px',
  width: '20%',
  minWidth: 200,
  '&.MuiButton-text': {
    backgroundColor: theme.xPalette.white,
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default function PatientStartMonitorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const monitorInstructions = t('monitor.MonitorInstructionsPage.body.ul', {
    returnObjects: true,
  });
  const dispatch = useAppDispatch();
  const { token, tokenHash } = useParams();
  const [{ monitorStatus, monitorResultId, accessToken }, setMonitorData] = useState<{
    monitorStatus?: string;
    monitorResultId?: number;
    accessToken?: string;
  }>({});
  const [pageErrors, setPageErrors] = useState<any[]>([]);
  const resumeMonitor = monitorStatus === MonitorStatus.InProgress;
  const [isShowMfa, setMfaData] = useState<boolean>(false);
  const [mfaMonitorData, setMfaMonitorData] = useState<any>({});
  const [searchParams] = useSearchParams();
  const isFromInterview = searchParams.get('isFromInterview');
  const checkTokenExpireInterview = useCheckExpireTokenMutation();
  const checkTokenExpireMonitor = useMonitorCheckExpireTokenMutation();
  const checkTokenExpire = isFromInterview
    ? checkTokenExpireInterview[0]
    : checkTokenExpireMonitor[0];
  const { data: accountSettings, isLoading } = useGetPatientMonitorSettingsQuery(
    accessToken
      ? {
          accessToken,
          monitorType: MonitorModeName.Schedule,
          viewName: resumeMonitor ? MonitorSettingsViewName.Resume : MonitorSettingsViewName.Start,
        }
      : skipToken
  );
  const hasError = pageErrors.length > 0;

  useEffect(() => {
    const handleCheckTokenExpire = async () => {
      dispatch(clearCredentials());
      try {
        if (token && tokenHash) {
          const res = await checkTokenExpire({ token, tokenHash }).unwrap();

          let redirectUrl = '';
          if (res?.nextUrl) {
            redirectUrl = res.nextUrl;
          } else if (!res.monitorTestId) {
            redirectUrl = `/interview/${token}/${tokenHash}?isFromMonitor=true`;
          }

          if (redirectUrl) {
            navigate(redirectUrl);
          }

          if (res) {
            if (res.isShowMfa) {
              setMfaData(true);
            }

            setMfaMonitorData({
              isShowMfa: res.isShowMfa!,
              mfaType: MfaType.Phone,
              mfaVerified: res.mfaVerified!,
              testId: res?.monitorTestId!,
              type: MfaInterviewType.Monitor,
              patientId: res.patientId,
              accessToken: res?.patientMonitorToken!,
              phoneNumber: res.phoneNumber!,
              lockoutExpiration: res.lockoutExpiration!,
            });

            setMonitorData({
              monitorResultId: res?.monitorTestId!,
              monitorStatus: res?.monitorStatus!,
              accessToken: res?.patientMonitorToken!,
            });

            dispatch(
              setPatientMonitorData({
                data: {
                  monitorTestId: res?.monitorTestId!,
                  lastActivityTs: Date.now(),
                  accessToken: res?.patientMonitorToken!,
                },
              })
            );
          }
        }
      } catch (e) {
        const {
          data: { error },
        } = e as ResponseError;
        setPageErrors(error);
      }
    };
    handleCheckTokenExpire();
  }, [checkTokenExpire, dispatch, navigate, token, tokenHash]);

  const onMfaPageEventChange = () => {
    setMfaData(false);
  };

  const renderStaticLandingText = () => {
    return (
      <>
        <Typography variant="body1">{t('monitor.MonitorInstructionsPage.body.p1')}</Typography>
        <Typography py={2} variant="body1">
          {t('monitor.MonitorInstructionsPage.body.p2')}
        </Typography>
        <List sx={{ ml: 3 }}>
          {Object.keys(monitorInstructions).map((item: any) => (
            <ListItem key={item} disablePadding>
              <ListItemText primary={monitorInstructions[item]} />
            </ListItem>
          ))}
        </List>
        <Typography py={2} variant="body1">
          {t('monitor.MonitorInstructionsPage.body.p3_new')}
        </Typography>
        <Typography py={2} variant="body1">
          {t('monitor.MonitorInstructionsPage.body.p4')}
        </Typography>
      </>
    );
  };

  const renderLandingHeader = () => {
    if (!isLoading) {
      if (accountSettings?.landingHeader) {
        return (
          <Typography
            variant="h1"
            dangerouslySetInnerHTML={{
              __html: accountSettings.landingHeader,
            }}
          ></Typography>
        );
      }
      return resumeMonitor ? (
        <Typography variant="h1">{t('monitor.MonitorInstructionsPage.resume-monitor')}</Typography>
      ) : (
        <Typography variant="h1">{t('monitor.MonitorInstructionsPage.title')}</Typography>
      );
    }
  };

  const renderLandingText = () => {
    if (!isLoading) {
      if (accountSettings?.landingText) {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: accountSettings.landingText,
            }}
          ></Typography>
        );
      }
      return resumeMonitor ? (
        <Typography variant="body1">
          {t('monitor.MonitorInstructionsPage.body.p3_existing')}
        </Typography>
      ) : (
        renderStaticLandingText()
      );
    }
  };

  return (
    <DashboardLayout showBreadcrumb={false} sessionExpiration={false}>
      {isShowMfa ? (
        <MfaPage onMfaPageEventChange={onMfaPageEventChange} mfaPatientData={mfaMonitorData} />
      ) : !(monitorResultId || hasError) ? (
        <Loading />
      ) : (
        <RoundedContainer sx={{ mt: 2 }}>
          <Box sx={{ py: 3 }}>
            {hasError ? (
              <Typography variant="h1">{pageErrors[0].title}</Typography>
            ) : (
              renderLandingHeader()
            )}
          </Box>
          <Box sx={{ mb: 1, ...landingTextStyle() }}>
            {hasError ? (
              <Typography variant="body1">{pageErrors[0].body}</Typography>
            ) : (
              renderLandingText()
            )}
          </Box>
          <Box
            sx={{
              py: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: matches ? 'column' : 'row-reverse',
            }}
          >
            {!hasError && (
              <StyledButton
                type="button"
                variant="contained"
                onClick={() => {
                  dispatch(clearCredentials());
                  dispatch(setMonitorMode({ monitorMode: MonitorModeName.Schedule }));
                  const monitorConductUrl = buildMonitorConductUrl(MonitorModeName.Schedule, {
                    monitorResultId: monitorResultId! + '',
                  });
                  navigate(monitorConductUrl);
                }}
              >
                {resumeMonitor
                  ? t('monitor.MonitorInstructionsPage.submit_existing')
                  : t('monitor.MonitorInstructionsPage.submit_new')}
              </StyledButton>
            )}
          </Box>
        </RoundedContainer>
      )}
    </DashboardLayout>
  );
}
