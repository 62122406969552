import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import {
  Components,
  Theme,
  TypographyVariant,
  TypographyVariants,
  alpha,
  createTheme,
} from '@mui/material/styles';
import loginBottomImage from '../assets/images/login-background.png';

type colorType = {
  bg: string;
  color: string;
};

type ExtendedPallete = {
  disabled: string;
  disabledText: string;
  brown: string;
  background: string;
  bodyBackground: string;
  black: string;
  lightGreyBackground?: string;
  lightGrey: string;
  darkGrey: string;
  shadeGrey: string;
  tableAltLine: string;
  white: string;
  noteLightGrey: string;
  notStarted: colorType;
  inProgress: colorType;
  completed: colorType;
  logoText: string;
  sliver: string;
  warning: string;
  menuBlack: string;
};
// Augment them module to add custom props
declare module '@mui/material/styles' {
  interface Theme {
    xPalette: ExtendedPallete;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    xPalette: ExtendedPallete;
  }
}

// Map href (MUI) -> to (react-router)
const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const loginLayoutClass = 'login-layout';
const globalBorderRadius = '8px';
export const customMdBreakpoints = 769;
// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 564,
      md: 768,
      lg: 1020,
      xl: 1200,
    },
  },
  // TODO: add more typography & color
  palette: {
    primary: {
      main: '#388AC8',
    },
    secondary: {
      main: '#76C440',
    },
    error: {
      main: '#F44336',
    },
  },
  xPalette: {
    disabled: alpha('#000000', 0.08),
    disabledText: alpha('#000000', 0.3),
    brown: '#A65F37',
    lightGrey: alpha('#000000', 0.5),
    noteLightGrey: alpha('#000000', 0.04),
    background: '#E5F5FD',
    bodyBackground: '#F7F8F9',
    black: '#000000',
    white: '#FFFFFF',
    darkGrey: '#F2F2F2',
    shadeGrey: '#727272',
    sliver: '#C0C0C0',
    tableAltLine: alpha('#000000', 0.02),
    warning: '#f44336',
    menuBlack: '#000000de',
    notStarted: {
      bg: alpha('#000000', 0.08),
      color: alpha('#000000', 0.3),
    },
    inProgress: {
      bg: '#FBF5ED',
      color: '#ED880F',
    },
    completed: {
      bg: '#EAF6E2',
      color: '#76C440',
    },
    logoText: '#425B76',
  },
  typography: {
    fontFamily: '"Mulish", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '24px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '22px',
    },
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '14px',
    },
    subtitle1: {
      fontSize: '14px',
    },
    subtitle2: {
      fontSize: '13px',
    },
  },
});

// Must export here, should not override breakpoints
const reportTheme = createTheme(
  { ...theme },
  {
    xPalette: {
      lightGreyBackground: alpha('#000000', 0.15),
      bodyBackground: theme.xPalette.white,
    },
  }
);

const overrideDesktopTypographyStyles: (
  typography: TypographyVariants,
  options: { variant: TypographyVariant; overriddenOptions: any }[]
) => any = (typography, options) => {
  options.forEach((opt) => {
    typography[opt.variant] = {
      ...typography[opt.variant],
      [theme.breakpoints.up('md')]: {
        ...opt.overriddenOptions,
      },
    };
  });
};

// Override font styles for desktop
overrideDesktopTypographyStyles(theme.typography, [
  {
    variant: 'h1',
    overriddenOptions: {
      fontSize: '28px',
    },
  },
  {
    variant: 'h2',
    overriddenOptions: {
      fontSize: '26px',
    },
  },
  {
    variant: 'body1',
    overriddenOptions: {
      fontSize: '18px',
    },
  },
  {
    variant: 'body2',
    overriddenOptions: {
      fontSize: '16px',
    },
  },
  {
    variant: 'subtitle1',
    overriddenOptions: {
      fontSize: '16px',
    },
  },
  {
    variant: 'subtitle2',
    overriddenOptions: {
      fontSize: '15px',
    },
  },
]);

const overriddenComponents = (customTheme: Theme): Components => {
  return {
    // make links work with react-router
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          textDecoration: 'none',

          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: globalBorderRadius,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: globalBorderRadius,
        },
        filledSuccess: {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        // TODO: adjust for screens larger than 1200px
        root: {
          [theme.breakpoints.up('xs')]: {
            bottom: '48px',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: customTheme.xPalette.bodyBackground,

          [`&.${loginLayoutClass}>#root>.MuiContainer-root`]: {
            backgroundImage: `url(${loginBottomImage})`,
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
            backgroundColor: customTheme.xPalette.white,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          [theme.breakpoints.up('md')]: {
            fontSize: '16px',
          },
        },
      },
    },
  };
};

reportTheme.components = overriddenComponents(reportTheme);
theme.components = overriddenComponents(theme);

export { reportTheme };

export default theme;
