import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { IntakePopupType, IntakePacketPopupBodyProp } from '../../types/PatientType';

export default function IntakePacketPopupBody({
  setChoseOption,
  hasEmailOrPhoneNumber,
}: IntakePacketPopupBodyProp) {
  const [currentOption, setCurrentOption] = useState<IntakePopupType>(
    hasEmailOrPhoneNumber
      ? IntakePopupType.EmailAndTextTheQuestionsToThePatient
      : IntakePopupType.PatientWillFillOutTheQuestions
  );
  const { t } = useTranslation();

  const emailAndTextTheQuestionsToThePatient = t(
    'patient.patientDashboard.form.intakePacketPopup.emailAndTextTheQuestionsToThePatient'
  );
  const patientWillFillOutTheQuestions = t(
    'patient.patientDashboard.form.intakePacketPopup.patientWillFillOutTheQuestions'
  );
  const doNotSendTheIntakePacket = t(
    'patient.patientDashboard.form.intakePacketPopup.doNotSendTheIntakePacket'
  );

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedData = event.target.value as IntakePopupType;
    setCurrentOption(selectedData);
    setChoseOption(selectedData);
  };

  return (
    <FormControl>
      <Typography variant="body1" fontWeight="bold">
        {t('patient.patientDashboard.form.intakePacketPopup.description')}
      </Typography>
      <Box m={2} />
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={currentOption}
        name="radio-buttons-group"
        onChange={handleRadioChange}
      >
        {hasEmailOrPhoneNumber && (
          <FormControlLabel
            value={IntakePopupType.EmailAndTextTheQuestionsToThePatient}
            control={<Radio />}
            label={emailAndTextTheQuestionsToThePatient}
          />
        )}
        <FormControlLabel
          value={IntakePopupType.PatientWillFillOutTheQuestions}
          control={<Radio />}
          label={patientWillFillOutTheQuestions}
        />
        <FormControlLabel
          value={IntakePopupType.DoNotSendTheIntakePacket}
          control={<Radio />}
          label={doNotSendTheIntakePacket}
        />
      </RadioGroup>
    </FormControl>
  );
}
