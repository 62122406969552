import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { LoginUserProfile, UserResponse } from '../../types/UserTypes';
import { formatDate } from '../utils/dateTimeUtils';
import ProfileIcon from './icons/ProfileIcon';

interface Props {
  data?: LoginUserProfile;
  editUserData?: UserResponse;
}

export default function UserInfoCard({ data, editUserData }: Props) {
  const { t } = useTranslation();
  const { clinicAccountName, clinicSiteName } = data || {};
  const { clinicAccountName: editUserAccountName, clinicAccount, dateCreated } = editUserData || {};
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <ProfileIcon />
        <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
          {(clinicAccountName || editUserAccountName) && (
            <Typography variant="body1">
              {t('user.editProfile.form.accountName')}
              <Typography component="span" sx={{ fontWeight: 600, color: theme.xPalette.black }}>
                {clinicAccountName || `${editUserAccountName} (${clinicAccount})` || ''}
              </Typography>
            </Typography>
          )}
          {clinicSiteName && (
            <Typography variant="body1">
              {t('user.editProfile.form.siteName')}
              <Typography component="span" sx={{ fontWeight: 600, color: theme.xPalette.black }}>
                {clinicSiteName?.join(', ')}
              </Typography>
            </Typography>
          )}
          {dateCreated && (
            <Typography variant="body1">
              {t('user.manageUsers.userForm.creationDate')}
              <Typography component="span" sx={{ fontWeight: 600, color: theme.xPalette.black }}>
                {formatDate(dateCreated)}
              </Typography>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
