import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { PageBreadcrumb } from '../../types/PageBreadcrumb';

interface BreadcrumbProps {
  data?: PageBreadcrumb[];
}

function BreadcrumbItem({ text, link }: PageBreadcrumb) {
  if (link) {
    return (
      <Link underline="hover" color="inherit" href={link} variant="subtitle1">
        {text}
      </Link>
    );
  }
  return (
    <Typography color="primary" variant="subtitle1">
      {text}
    </Typography>
  );
}

export default function Breadcrumb({ data }: BreadcrumbProps) {
  const { t } = useTranslation();
  const defaultBreadcrumbs: PageBreadcrumb[] = [{ text: t('site.home') }];
  let renderedItems = data?.length ? data : defaultBreadcrumbs;
  // if Home page then remove the "Dashboard" from breadcrumb
  if (renderedItems?.length === 2 && renderedItems[1].text === t('dashboard.title')) {
    renderedItems.pop();
    renderedItems[0].link = undefined;
  }

  let itemCount = 0;
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {renderedItems.map((item: PageBreadcrumb) => (
        <BreadcrumbItem key={itemCount++} {...item} />
      ))}
    </Breadcrumbs>
  );
}
