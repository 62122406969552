import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import validator from 'validator';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SxProps } from '@mui/system';
import {
  useGetClinicalFollowUpsQuery,
  useUpdateFollowUpStatusMutation,
} from '../../app/services/account';
import { useGetPatientQuery } from '../../app/services/patient';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { editBreadcrumbs, ReplaceType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { ResponseError } from '../../types/ResponseError';
import ErrorPage from '../error/ErrorPage';

const NextStepType = {
  Interview: 1,
  Monitor: 2,
  External: 3,
};

const FormAction: { [key: string]: string } = {
  Yes: 'completed',
  No: 'no',
  Executed: 'alreadyExecuted',
  DoNotAsk: 'doNotAskAgain',
};

function NextStepMenus({ onChange, sx }: { onChange: (value: string) => void; sx?: SxProps }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selectedValue: string) => {
    handleClose();
    onChange(selectedValue);
  };

  return (
    <Box sx={{ ...sx }}>
      <Button
        id="next-step-button"
        aria-controls={open ? 'next-step-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t('clinicalFollowup.action.select')}
      </Button>
      <Menu
        id="next-step-menu"
        MenuListProps={{
          'aria-labelledby': 'next-step-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {Object.keys(FormAction).map((key: string) => {
          const actionKey = FormAction[key];
          return (
            <MenuItem key={key} onClick={() => handleSelect(actionKey)}>
              {t(`clinicalFollowup.action.${actionKey}`)}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default function ClinicalFollowUpPage({ breadcrumbs }: PageProps) {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [updateFollowUp] = useUpdateFollowUpStatusMutation();

  let newBreadcumbs = breadcrumbs;
  // remove duplicated breadcumb
  if (breadcrumbs?.length === 4) {
    newBreadcumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);
    newBreadcumbs[newBreadcumbs.length - 1].link = '';
  }
  const updatedParams: ReplaceType = { param: ':patientId', value: patientId! };
  const patientDashboardUrl = `/dashboard/patient/${patientId}`;

  const [metItemNames, setMetItemNames] = useState<string[]>([]);
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [nextSteps, setNextSteps] = useState<
    {
      followUpText: string;
      text: string;
      type: number;
      action: any;
      language: string;
      id: number;
    }[]
  >([]);

  const { data: patientInfo, error: patientLoadError } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const {
    data: clinicalFollowup,
    isLoading,
    refetch,
  } = useGetClinicalFollowUpsQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  useEffect(() => {
    if (clinicalFollowup) {
      setMetItemNames(clinicalFollowup.metScoringLineItems);
      setNextSteps(clinicalFollowup.nextSteps);
    }
  }, [clinicalFollowup]);

  const handleChange = async (
    value: string,
    {
      type,
      action,
      language,
      nextStepId,
    }: { type: number; action: string; language: string; nextStepId: number }
  ) => {
    const followUpRequest = {
      patientId: patientId!,
      id: nextStepId,
      status: '',
    };

    let isUpdatedFollowUp = false;
    switch (value) {
      case FormAction.Yes:
        switch (type) {
          case NextStepType.Interview:
            navigate(`${patientDashboardUrl}?interview=${action}&language=${language}`);
            break;
          case NextStepType.Monitor:
            navigate(`${patientDashboardUrl}?monitor=${action}&language=${language}`);
            break;
          case NextStepType.External:
            followUpRequest.status = FormAction.Yes;
            isUpdatedFollowUp = true;
            window.open(
              validator.isURL(action, {
                protocols: ['http', 'https', 'ftp'],
                require_protocol: true,
                require_valid_protocol: true,
              })
                ? action
                : `https://${action}`,
              '_blank'
            );
            break;
          default:
            break;
        }
        break;
      case FormAction.No:
        if (nextSteps.length === 1) {
          navigate(patientDashboardUrl);
        }
        break;
      case FormAction.Executed:
        followUpRequest.status = FormAction.Executed;
        isUpdatedFollowUp = true;
        break;
      case FormAction.DoNotAsk:
        followUpRequest.status = FormAction.DoNotAsk;
        isUpdatedFollowUp = true;
        break;
      default:
        break;
    }

    if (isUpdatedFollowUp) {
      try {
        await updateFollowUp(followUpRequest).unwrap();
        if (nextSteps.length !== 1) {
          refetch();
        } else {
          navigate(patientDashboardUrl);
        }
      } catch (e) {
        const {
          data: { error },
        } = e as ResponseError;
        setPageErrors(error);
      }
    }
  };

  if (patientLoadError) {
    return <ErrorPage statusCode={(patientLoadError as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={editBreadcrumbs(newBreadcumbs, updatedParams)}>
      <Helmet>
        <title>{getPageTitle(t('clinicalFollowup.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            px: matches ? 2 : 3,
          }}
        >
          <PageHeader headerText={t('clinicalFollowup.title')} backUrl={patientDashboardUrl} />
        </Box>
        <RoundedContainer sx={{ py: 2 }}>
          <PatientInfoCard data={patientInfo!} />
        </RoundedContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {nextSteps?.length !== 0 && (
              <RoundedContainer sx={{ mt: 2, py: 2 }}>
                <Typography fontWeight={600}>
                  {t('clinicalFollowup.metCriteriaFor', { metItems: metItemNames?.join(', ') })}
                </Typography>
                <Typography>{t('clinicalFollowup.stepsCouldBeTaken')}</Typography>
              </RoundedContainer>
            )}

            {nextSteps?.length
              ? nextSteps.map((step, index) => (
                  <RoundedContainer
                    sx={{
                      mt: 2,
                      py: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                    }}
                    key={`${step.type}-${step.action}-${index}`}
                  >
                    <Box>
                      <Typography>{step.text}</Typography>
                      <Typography fontWeight={600}>{step.followUpText}</Typography>
                    </Box>
                    <NextStepMenus
                      sx={{
                        mt: 1,
                        [theme.breakpoints.up('md')]: {
                          flexShrink: 0,
                          ml: 1,
                          mt: 0,
                        },
                      }}
                      onChange={(value: string) =>
                        handleChange(value, {
                          type: step.type,
                          action: step.action,
                          nextStepId: step.id,
                          language: step.language,
                        })
                      }
                    />
                  </RoundedContainer>
                ))
              : ''}
          </>
        )}
        {pageErrors &&
          pageErrors.length > 0 &&
          pageErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ))}
      </Container>
    </DashboardLayout>
  );
}
