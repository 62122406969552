import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { clearCredentials, rotateToken } from '../../features/auth/authSlice';
import { RootState } from '../store';

// URL builders
export const buildAuthUrl = (url: string) => `${process.env.REACT_APP_AUTH_BASE_URL || ''}${url}`;
export const buildAccountUrl = (url: string) =>
  `${process.env.REACT_APP_ACCOUNT_BASE_URL || ''}${url}`;
export const buildInterviewUrl = (url: string) =>
  `${process.env.REACT_APP_INTERVIEW_BASE_URL || ''}${url}`;
export const buildMonitorUrl = (url: string) =>
  `${process.env.REACT_APP_MONITOR_BASE_URL || ''}${url}`;

// RTK caching
export const TagTypes = {
  User: 'user',
  Patient: 'patient',
  LatestInterviews: 'latest-interviews',
  LatestInterviewDetails: 'latest-interview-details',
  LatestMonitors: 'latest-monitors',
  LatestMonitorDetails: 'latest-monitor-details',
  InterviewSchedule: 'interview-schedule',
  MonitorSchedule: 'monitor-schedule',
  StopSchedule: 'stop-schedule',
  ClinicalFollowUps: 'clinical-follow-up',
  SuicideAlert: 'suicide-alert',
  InterviewTypes: 'interview-types',
  Site: 'site',
  AuditLog: 'audit-log',
  Reminder: 'patient-reminders',

};
export const TagIds = {
  List: 'LIST',
};
export const QueryCacheDuration = {
  InterviewSettings: 300, // long caching for setting data
  MonitorSettings: 300,
  Short: 15,
  Normal: 60, // default value of RTK Query,
  NoCache: 0,
};

export const downloadFile = async (
  downloadUrl: string,
  data: any,
  accessToken: string,
  refreshToken: string
) => {
  const downloadPdf = async (accessToken: string) => {
    const { data: pdfData } = await axios.post(downloadUrl, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    });
    return pdfData;
  };

  const renewToken = async (accessToken: string) => {
    const { data } = await axios.post(buildAuthUrl('/v1/auth/refresh-token'), {
      accessToken,
      refreshToken,
    });
    return data.accessToken;
  };

  let pdfData = undefined;
  let newAccessToken = undefined;
  try {
    pdfData = await downloadPdf(accessToken);
  } catch (error: any) {
    const { statusCode, message } = JSON.parse(new TextDecoder().decode(error.response.data));
    if (statusCode === HttpStatusCode.Unauthorized && message === 'TokenExpiredError') {
      try {
        newAccessToken = await renewToken(accessToken);
        pdfData = await downloadPdf(newAccessToken);
      } catch (tokenRefreshError) {
        console.error('Failed to refresh token. ', tokenRefreshError);
      }
    }
  }
  return { pdfData, newAccessToken };
};

// RTK queries
const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token && !headers.get('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === HttpStatusCode.Unauthorized) {
    // refresh token when it expired
    if ((result.error.data! as any).message === 'TokenExpiredError') {
      const rootState = api.getState() as RootState;
      const refreshResult = await baseQuery(
        {
          url: buildAuthUrl('/v1/auth/refresh-token'),
          method: 'POST',
          body: {
            accessToken: rootState.auth.token,
            refreshToken: rootState.auth.refreshToken,
          },
        },
        api,
        extraOptions
      );

      if (refreshResult.data) {
        api.dispatch(rotateToken({ accessToken: (refreshResult.data as any).accessToken }));

        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(clearCredentials());
      }
    } else {
      api.dispatch(clearCredentials());
    }
  }
  return result;
};

export const rootApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: Object.values(TagTypes),
  endpoints: () => ({}),
});
