import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { useToast } from '../../app/toast';
import Button from '@mui/material/Button';
import { interviewApis } from '../../app/services/interview';
import { ResponseError } from '../../types/ResponseError';
import { CacheManagement, CacheManagementResponse } from '../../types/CacheManagementType';
import { commonButtonStyle } from '../../common/utils/commonStyles';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearAssessmentCachedData } from '../patient/patientUtils';
import { clearCredentials, selectUser } from '../auth/authSlice';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../error/ErrorPage';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { canResetCache } from '../../common/utils/permissionUtils';

const defaultFormValues: CacheManagement = {
  key: '',
  cacheData: '',
};

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',
  },
}));

const StyledCacheData = styled(TextField)(({ theme }) => ({
  margin: '16px 0',
  backgroundColor: theme.xPalette.noteLightGrey,
  borderRadius: '12px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&:hover fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.xPalette.white,
    },
  },
  'p.MuiFormHelperText-root': {
    backgroundColor: theme.xPalette.white,
    margin: 0,
  },
}));

export default function CacheManagementPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const toast = useToast();

  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const canResetCacheData = canResetCache(currentUser.roleId);

  const validationSchema = yup
    .object({
      key: yup.string().required(t('cacheManagement.error.blankKey')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<CacheManagement>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  if (!canResetCacheData) {
    return <ErrorPage statusCode={HttpStatusCode.Forbidden} />;
  }

  const handleGetCacheByKey = async (data: CacheManagement) => {
    try {
      const cacheDispatcher = dispatch(interviewApis.getCacheByKey.initiate({ key: data.key }, { forceRefetch: true }));
      cacheDispatcher.then((result) => {
        if (typeof result !== 'undefined') {
          if (result.data?.data) {
            const cacheData = result.data?.data || '';
            setValue('cacheData', JSON.stringify(cacheData));
            setIsDataLoaded(true);
            setPageErrors([]);
          } else {
            setPageErrors([t('cacheManagement.error.noDataFound')]);
            setIsDataLoaded(false);
          }
        }});
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const handleResetCacheByKey = (data: CacheManagement) => {
    try {
      const cacheDispatcher = dispatch(interviewApis.resetCacheByKey.initiate({ key: data.key }));
      cacheDispatcher.then((result) => {
        if (typeof result !== 'undefined') {
          const cacheManagementResponse = result as CacheManagementResponse;
          setIsDataLoaded(false);
          if (!cacheManagementResponse?.error) {
            setValue('cacheData', '');
            setPageErrors([]);
            toast.publish(t('cacheManagement.form.resetCacheSuccess'),'success');
          } else {
            setPageErrors([cacheManagementResponse.error?.data?.error]);
          }
        }});
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const ResetCache = () => {
    try {
      const cacheDispatcher = dispatch(interviewApis.resetCache.initiate());
      cacheDispatcher.then((result) => {
        if (typeof result !== 'undefined') {
          const cacheManagementResponse = result as CacheManagementResponse;
          if (!cacheManagementResponse?.error) {
            reset();
            clearAssessmentCachedData();
            dispatch(clearCredentials());
            toast.publish(t('cacheManagement.form.resetCacheSuccess'),'success');
            navigate('/login', { replace: true });
          } else {
            setPageErrors([cacheManagementResponse.error?.data?.error]);
            setIsDataLoaded(false);
          }
        }});
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('cacheManagement.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <PageHeader
            sx={{ mt: 1, mb: 2 }}
            headerText={t('cacheManagement.title')}
            backUrl={'/dashboard'}
          />
        </Container>
          <RoundedContainer
            sx={{
              py: 1,
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box component="form" noValidate>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <Controller
                  name="key"
                  control={control}
                  render={({ field }) => (
                    <StyleTextField
                      {...field}
                      error={!!errors.key}
                      helperText={errors.key?.message}
                      margin="normal"
                      fullWidth
                      label={t('cacheManagement.form.key')}
                      variant="outlined"
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  float: 'right',
                }}
              >
                {!matches && <Divider sx={{ width: '100%' }} />}
                {pageErrors.length > 0 &&
                  pageErrors.map((error) => (
                    <Alert key={error} severity="error" sx={{ mt: 2 }}>
                      {error}
                    </Alert>
                  ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: 1,
                  flexDirection: matches ? 'column' : 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  onClick={handleSubmit(handleGetCacheByKey)}
                  variant="contained"
                  sx={{
                    ...commonButtonStyle(matches),
                    width: '30%',
                  }}
                >
                  {t('cacheManagement.form.getCacheByKey')}
                </Button>
                <Button
                  onClick={handleSubmit(handleResetCacheByKey)}
                  variant="contained"
                  sx={{
                    ...commonButtonStyle(matches),
                    width: '30%',
                  }}
                >
                  {t('cacheManagement.form.resetCacheByKey')}
                </Button>
                <Button
                  onClick={ResetCache}
                  variant="contained"
                  sx={{
                    ...commonButtonStyle(matches),
                    width: '30%',
                  }}
                >
                  {t('cacheManagement.form.resetCache')}
                </Button>
              </Box>
              { isDataLoaded && (<Box
                sx={{
                  width: '100%',
                  float: 'right',
                }}
                >
                  <Controller
                    name="cacheData"
                    control={control}
                    render={({ field }) => (
                      <StyledCacheData
                        {...field}
                        error={!!errors.cacheData}
                        helperText={errors.cacheData?.message}
                        fullWidth
                        multiline={true}
                        rows={15}
                        label={t('cacheManagement.form.cacheData')}
                        variant="outlined"
                      />
                    )}
                  />
              </Box>)}
            </Box>
          </RoundedContainer>
      </Container>
    </DashboardLayout>
  );
}
