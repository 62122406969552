import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { DashboardContentOptions, FrameOptions } from 'amazon-quicksight-embedding-sdk/dist/types';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useGetManagementReportInfoForDownloadQuery,
  useGetManagementReportInfoQuery,
} from '../../app/services/patient';
import { PageStatus, selectPageStatus, setPageStatus } from '../../common/slices/globalSlice';
import { logger } from '../../common/utils/logger';
import { Role } from '../../common/utils/loginRole';
import { AuthUserType } from '../../types/AuthTypes';
import { selectUser } from '../auth/authSlice';

export default function ManagementChartReportComponent() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser) as AuthUserType;
  const embeddingContainerRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const [isDashboardEmbedded, setIsDashboardEmbedded] = useState(false);
  const [reportLoaded, setReportLoaded] = useState(false);
  const [reportFrameHeight, setReportFrameHeight] = useState(0);
  const [reportFrameWidth, setReportFrameWidth] = useState(0);
  const pageStatus = useAppSelector(selectPageStatus);
  const token = searchParams?.get('token') || null;
  const reportNameActive = searchParams?.get('reportNameActive') || null;
  const reportIdActive = searchParams?.get('reportIdActive') || undefined;
  const { data: reportInfo, isLoading: isReportInfoLoading } = useGetManagementReportInfoQuery(
    !token ? { reportNameActive, reportIdActive } : skipToken
  );

  const { data: reportInfoForDownload } = useGetManagementReportInfoForDownloadQuery(
    token && reportNameActive && reportIdActive
      ? { token, reportNameActive, reportIdActive }
      : skipToken
  );

  const reportInfoData = token ? reportInfoForDownload : reportInfo;

  const embedUrlData = reportInfoData?.embedUrl;

  useEffect(() => {
    if (isReportInfoLoading && pageStatus !== PageStatus.Loading) {
      dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
    }
  });

  const loadCallback = useCallback(async () => {
    setReportLoaded(true);
    dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
  }, [dispatch]);

  const embedDashboard = useCallback(
    async (url: string, apiKey: string, accountName: string) => {
      if (!url || !apiKey) return;
      dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
      const containerDiv = embeddingContainerRef.current as HTMLElement;

      const frameOptions: FrameOptions = {
        url,
        container: containerDiv,
        height: 'AutoFit',
        width: '100%',
      };

      const contentOptions: DashboardContentOptions = {
        parameters: [
          {
            Name: 'AccountID',
            Values: [apiKey],
          },
        ],
        toolbarOptions: {
          export: true,
          undoRedo: false,
          reset: true,
        },
        sheetOptions: {
          initialSheetId: reportIdActive ? reportIdActive : undefined,
          singleSheet: false,
          emitSizeChangedEventOnSheetChange: true,
        },
        attributionOptions: {
          overlayContent: false,
        },
        onMessage: async (messageEvent) => {
          switch (messageEvent.eventName) {
            case 'ERROR_OCCURRED': {
              logger.info(
                'Error occurred while rendering the experience. Error code:',
                messageEvent.message.errorCode
              );
              loadCallback();
              break;
            }
            case 'SIZE_CHANGED': {
              logger.info('Size changed. New dimensions:', messageEvent.message);
              const iframe = document.querySelector(
                '#embeddingContainer .quicksight-embedding-iframe'
              ) as HTMLIFrameElement;
              if (iframe && messageEvent.message) {
                iframe.height = messageEvent.message.height || 0;
                setReportFrameHeight(messageEvent.message.height || 0);
                setReportFrameWidth(messageEvent.message.width || 0);
              }
              break;
            }
          }
        },
      };

      if (accountName) {
        contentOptions?.parameters?.push({
          Name: 'AccountName',
          Values: [accountName],
        });
      }

      const embeddingContext = await createEmbeddingContext();
      await embeddingContext.embedDashboard(frameOptions, contentOptions);

      // This will force stopping the loading after 15 seconds
      setTimeout(() => {
        setReportLoaded(true);
        dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
      }, 10000);
    },
    [dispatch, loadCallback, reportIdActive]
  );

  const showManagementReport = useCallback(async () => {
    if (embedUrlData?.embedData?.EmbedUrl) {
      embedDashboard(
        embedUrlData?.embedData?.EmbedUrl || '',
        embedUrlData.encryptedKey || '',
        currentUser.roleId === Role.SuperAdmin ? '' : reportInfoForDownload?.accountName || '' // don't need for super admin
      );
    }
  }, [embedDashboard, embedUrlData, currentUser.roleId, reportInfoForDownload?.accountName]);

  useEffect(() => {
    if (!isDashboardEmbedded && embedUrlData) {
      setIsDashboardEmbedded(true);
      showManagementReport();
    }
  }, [embedUrlData, isDashboardEmbedded, showManagementReport]);

  return (
    <>
      {reportLoaded && pageStatus === PageStatus.Idle && (
        <div className="report-loaded" style={{ display: 'none' }}></div>
      )}
      <div className="report-frame-height" style={{ display: 'none' }}>
        {reportFrameHeight}
      </div>
      <div className="report-frame-width" style={{ display: 'none' }}>
        {reportFrameWidth}
      </div>
      <Box
        ref={embeddingContainerRef}
        id="embeddingContainer"
        maxWidth="xl"
        sx={{ paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}
      ></Box>
    </>
  );
}
