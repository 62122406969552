import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import { useCheckConfigSendReportQuery } from '../../app/services/account';
import { useGetPatientDiagnosesQuery } from '../../app/services/patient';
import { useSendReportToEHRMutation } from '../../app/services/treatment-plan/treatmentPlanReport';
import { useToast } from '../../app/toast';
import CommonDatePicker from '../../common/components/CommonDatePicker';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import {
  PageStatus,
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
  setPageStatus,
} from '../../common/slices/globalSlice';
import { checkAllGoalCompleted, isBefore } from '../../common/utils/treatmentPlans';
import { PopupContent } from '../../types/PopupType';
import { ResponseError } from '../../types/ResponseError';
import { GoalItem } from '../../types/treatment-plan/GoalType';
import {
  TreatmentPlanInfo,
  TreatmentPlanResponse,
  TreatmentPlanStatus,
} from '../../types/treatment-plan/TreatmentPlanType';
import SummaryError from '../error/SummaryError';
import TreatmentPlanAuditLogs from './TreatmentPlanAuditLogs';
import TreatmentPlanDiagnosisList from './diagnosis/TreatmentPlanDiagnosisList';
import GoalList from './goal/GoalList';
import SignatureList from './signature/SignatureList';

const StyledAddTreatmentBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: '18px',
  width: '24%',
  height: '57px',
  ml: 1,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '56px',
    ml: 0,
  },
}));

interface TreatmentPlanFormProps {
  patientId?: string;
  treatmentPlanId?: number;
  treatmentPlanData?: TreatmentPlanResponse;
  onFormSubmit: (
    data: TreatmentPlanInfo,
    treatmentPlanId?: number,
    successCallback?: () => void
  ) => Promise<void>;
}
const date = new Date();

const defaultFormValues: TreatmentPlanInfo = {
  patientId: '',
  symptoms: '',
  name: '',
  problemPatientWords: '',
  problemPatientWordsIfNeeded: '',
  startDate: date,
  projectedCompletionDate: null,
  completionDate: null,
  nextReviewDate: null,
  patientStrengths: '',
  patientAbilities: '',
  patientNeedsLimitations: '',
  patientPreferences: '',
  clinicalIssues: '',
  medicalIssues: '',
  psychiatricIssues: '',
  completionCriteria: '',
};

export default function TreatmentPlan({
  patientId,
  treatmentPlanId,
  treatmentPlanData,
  onFormSubmit,
}: TreatmentPlanFormProps) {
  const toast = useToast();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [treatmentGoalDisplay, setTreatmentGoalDisplay] = useState<GoalItem[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [treatmentPlanInitialData, setTreatmentPlanInitialData] =
    useState<TreatmentPlanInfo>(defaultFormValues);
  const [actionTreatmentGoalId, setActionTreatmentGoalId] = useState<number | undefined>(undefined);
  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);

  const unsavedDataContentPopup = {
    title: t('treatmentPlans.treatmentPlanForm.unsavedPopup.title'),
    description: t('treatmentPlans.treatmentPlanForm.unsavedPopup.description'),
    btnOk: t('treatmentPlans.treatmentPlanForm.unsavedPopup.btnOk'),
    btnClose: t('treatmentPlans.treatmentPlanForm.unsavedPopup.btnClose'),
  } as PopupContent;

  const { data: patientDiagnosesData } = useGetPatientDiagnosesQuery(
    patientId && !treatmentPlanId ? { patientId } : skipToken
  );

  const { data: accountConfig } = useCheckConfigSendReportQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const [sendTreatmentPlanReportToEHR] = useSendReportToEHRMutation();

  const navBackUrl = `/dashboard/patient/${patientId}/treatment-plan/edit/${treatmentPlanId}`;

  const validationSchema = yup
    .object()
    .shape({
      symptoms: yup.string(),
      name: yup.string().required(t('treatmentPlans.treatmentPlanForm.error.blankName')),
      problemPatientWords: yup.string(),
      problemPatientWordsIfNeeded: yup.string(),
      startDate: yup
        .date()
        .required(t('treatmentPlans.treatmentPlanForm.error.blankStartDate'))
        .typeError(t('treatmentPlans.treatmentPlanForm.error.blankStartDate'))
        .test(
          'start-date',
          t('treatmentPlans.treatmentPlanForm.error.invalidStartDate'),
          function (startDate) {
            const projectedCompletionDate: Date = getValues('projectedCompletionDate') || undefined;
            const completionDate: Date = getValues('completionDate') || undefined;
            const nextReviewDate: Date = getValues('nextReviewDate') || undefined;
            if (startDate) {
              return !(
                isBefore(projectedCompletionDate, startDate) ||
                isBefore(completionDate, startDate) ||
                isBefore(nextReviewDate, startDate)
              );
            }
            return true;
          }
        ),
      projectedCompletionDate: yup
        .date()
        .nullable()
        .default(null)
        .test(
          'projected-completion-date',
          t('treatmentPlans.treatmentPlanForm.error.invalidProjectedCompletionDate'),
          function (projectedCompletionDate) {
            const startDate: Date = getValues('startDate') || undefined;
            if (projectedCompletionDate) {
              return !isBefore(projectedCompletionDate, startDate);
            }
            return true;
          }
        ),
      completionDate: yup
        .date()
        .nullable()
        .default(null)
        .test(
          'completion-date',
          t('treatmentPlans.treatmentPlanForm.error.invalidCompletionDate'),
          function (completionDate) {
            const startDate: Date = getValues('startDate') || undefined;
            const nextReviewDate: Date = getValues('nextReviewDate') || undefined;
            if (completionDate) {
              return !(
                isBefore(completionDate, startDate) || isBefore(completionDate, nextReviewDate)
              );
            }
            return true;
          }
        ),
      nextReviewDate: yup
        .date()
        .nullable()
        .default(null)
        .test(
          'next-review-date',
          t('treatmentPlans.treatmentPlanForm.error.invalidReviewDate'),
          function (nextReviewDate) {
            const startDate: Date = getValues('startDate') || undefined;
            const completionDate: Date = getValues('completionDate') || undefined;
            if (nextReviewDate) {
              return !(
                isBefore(nextReviewDate, startDate) || isBefore(completionDate, nextReviewDate)
              );
            }
            return true;
          }
        ),
      patientStrengths: yup.string(),
      patientAbilities: yup.string(),
      patientNeedsLimitations: yup.string(),
      patientPreferences: yup.string(),
      clinicalIssues: yup.string(),
      medicalIssues: yup.string(),
      psychiatricIssues: yup.string(),
      completionCriteria: yup.string(),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<TreatmentPlanInfo>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (treatmentPlanData?.treatmentGoals) {
      setTreatmentGoalDisplay(treatmentPlanData?.treatmentGoals);
    }
  }, [treatmentPlanData]);

  useEffect(() => {
    if (patientId) {
      setValue('patientId', patientId);
    }
  }, [patientId, setValue]);

  // initial data load
  useEffect(() => {
    if (treatmentPlanId && treatmentPlanData !== undefined) {
      if (!isDataLoaded) {
        setIsDataLoaded(true);
        const bindEditControls = async () => {
          Object.keys(defaultFormValues).forEach((field: any) => {
            let value = treatmentPlanData[field as keyof TreatmentPlanResponse];
            setValue(field, value ?? null);
          });
        };
        bindEditControls();
        const initialData = getValues();
        setTreatmentPlanInitialData(initialData);
      }
    }
  }, [getValues, isDataLoaded, setValue, treatmentPlanData, treatmentPlanId]);

  const showUnSavedDataPopup = () => {
    dispatch(setContentPopup({ content: unsavedDataContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
  };

  const confirmUnSaved = async () => {
    if (!actionTreatmentGoalId) {
      navigate(`/dashboard/patient/${patientId}/treatment-plan/edit/${treatmentPlanId}/goal/add`, {
        state: { navBackUrl },
      });
    } else {
      navigate(
        `/dashboard/patient/${patientId}/treatment-plan/edit/${treatmentPlanId}/goal/edit/${actionTreatmentGoalId}`
      );
    }
  };

  const onSubmit = async (data: TreatmentPlanInfo) => {
    try {
      await onFormSubmit(data, treatmentPlanId, () => {
        const values = getValues();
        setTreatmentPlanInitialData(values);
        setPageErrors([]);
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const addOrEditGoalAction = (treatmentGoalId?: number) => {
    const dataSubmit = getValues();
    if (JSON.stringify(treatmentPlanInitialData) !== JSON.stringify(dataSubmit)) {
      showUnSavedDataPopup();
      if (treatmentGoalId) {
        setActionTreatmentGoalId(treatmentGoalId);
      }
    } else {
      if (!treatmentGoalId) {
        navigate(
          `/dashboard/patient/${patientId}/treatment-plan/edit/${treatmentPlanId}/goal/add`,
          {
            state: { navBackUrl },
          }
        );
      } else {
        navigate(
          `/dashboard/patient/${patientId}/treatment-plan/edit/${treatmentPlanId}/goal/edit/${treatmentGoalId}`
        );
      }
    }
  };

  const viewReport = async (treatmentPlanId: number) => {
    window.open(`/dashboard/treatment-plan-report/${patientId}/${treatmentPlanId}`, '_blank');
  };

  const handleSendPatientReportToEHR = async (treatmentPlanId: number) => {
    if (treatmentPlanId) {
      try {
        const res = await sendTreatmentPlanReportToEHR({ treatmentPlanId }).unwrap();
        if (res && res.message) {
          toast.publish(res.message, 'success');
        } else {
          toast.publish(t('patientReport.error.cannotSendReportToEHR'), 'error');
        }
        dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
      } catch (e) {
        dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
        toast.publish(t('patientReport.error.cannotSendReportToEHR'), 'error');
      }
    }
  };

  return (
    <Box
      sx={{
        px: 3,
        pt: 3,
      }}
    >
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <RoundedContainer sx={{ marginBottom: 2, paddingY: 1 }}>
          <TreatmentPlanDiagnosisList
            parentPlanId={treatmentPlanId ? treatmentPlanId : 0}
            diagnosisRows={
              treatmentPlanId
                ? treatmentPlanData?.treatmentDiagnoses || []
                : patientDiagnosesData?.patientDiagnoses || []
            }
            treatmentPlanCompleted={
              treatmentPlanData?.status === TreatmentPlanStatus.CompletedNotSigned ||
              treatmentPlanData?.status === TreatmentPlanStatus.CompletedSigned
            }
          />
        </RoundedContainer>

        <RoundedContainer sx={{ marginBottom: 2, paddingY: 2 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '18px!important', marginBottom: 1 }}>
            {t('treatmentPlans.treatmentPlanForm.patientSymptoms')}
          </Typography>
          <Controller
            name="symptoms"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.symptoms}
                helperText={errors.symptoms?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.patientsSymptoms')}
                variant="outlined"
                multiline
                minRows={7}
              />
            )}
          />
        </RoundedContainer>

        <RoundedContainer sx={{ marginBottom: 2, paddingY: 2 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '18px!important', marginBottom: 1 }}>
            {t('treatmentPlans.treatmentPlanForm.patientAssessment')}
          </Typography>
          <Controller
            name="patientStrengths"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.patientStrengths}
                helperText={errors.patientStrengths?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.patientStrengths')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
          <Controller
            name="patientAbilities"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.patientAbilities}
                helperText={errors.patientAbilities?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.patientAbilities')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
          <Controller
            name="patientNeedsLimitations"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.patientNeedsLimitations}
                helperText={errors.patientNeedsLimitations?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.patientNeedLimitations')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
          <Controller
            name="patientPreferences"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.patientPreferences}
                helperText={errors.patientPreferences?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.patientPreferences')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
        </RoundedContainer>

        <RoundedContainer sx={{ marginBottom: 2, paddingY: 2 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '18px!important', marginBottom: 1 }}>
            {t('treatmentPlans.treatmentPlanForm.treatmentPlanDefinition')}
          </Typography>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.name}
                helperText={errors.name?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.treatmentPlanNameOrSubject')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="problemPatientWords"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.problemPatientWords}
                helperText={errors.problemPatientWords?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.problemInPatientWords')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
          <Controller
            name="problemPatientWordsIfNeeded"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.problemPatientWordsIfNeeded}
                helperText={errors.problemPatientWordsIfNeeded?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.problemInPatientWordsIfNeeded')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: matches ? '1fr' : 'repeat(4, 1fr)',
              gridGap: '8px',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CommonDatePicker
                name="startDate"
                control={control}
                error={!!errors.startDate}
                helperText={errors.startDate?.message}
                label={t('treatmentPlans.treatmentPlanForm.treatmentPlanStartDate')}
                matches={matches}
              />

              <CommonDatePicker
                name="projectedCompletionDate"
                control={control}
                error={!!errors.projectedCompletionDate}
                helperText={errors.projectedCompletionDate?.message}
                label={t('treatmentPlans.treatmentPlanForm.projectedCompletionDate')}
                matches={matches}
              />

              <CommonDatePicker
                name="completionDate"
                control={control}
                error={!!errors.completionDate}
                helperText={errors.completionDate?.message}
                label={t('treatmentPlans.treatmentPlanForm.completionDate')}
                matches={matches}
                disableFuture
                disabled={!treatmentPlanId || !checkAllGoalCompleted(treatmentGoalDisplay)}
              />

              <CommonDatePicker
                name="nextReviewDate"
                control={control}
                error={!!errors.nextReviewDate}
                helperText={errors.nextReviewDate?.message}
                label={t('treatmentPlans.treatmentPlanForm.nextReviewDate')}
                matches={matches}
              />
            </LocalizationProvider>
          </Box>
        </RoundedContainer>

        {treatmentPlanId && (
          <RoundedContainer sx={{ marginBottom: 2, paddingY: 2 }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: matches ? 'center' : 'normal',
                paddingTop: 1,
                flexWrap: 'wrap',
                paddingBottom: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '18px!important',
                  marginBottom: 1,
                }}
              >
                {t('treatmentPlans.treatmentPlanForm.treatmentPlanGoalsObjectivesStrategies')}
              </Typography>
              <StyledAddTreatmentBox
                type="button"
                fullWidth
                variant="contained"
                disabled={
                  treatmentPlanData?.status === TreatmentPlanStatus.CompletedNotSigned ||
                  treatmentPlanData?.status === TreatmentPlanStatus.CompletedSigned
                }
                onClick={() => addOrEditGoalAction()}
              >
                {t('treatmentPlans.goalList.add')}
              </StyledAddTreatmentBox>
            </Box>
            <GoalList
              goalRows={treatmentGoalDisplay}
              addOrEditGoal={addOrEditGoalAction}
              treatmentPlanCompleted={
                treatmentPlanData?.status === TreatmentPlanStatus.CompletedNotSigned ||
                treatmentPlanData?.status === TreatmentPlanStatus.CompletedSigned
              }
            />
          </RoundedContainer>
        )}

        <RoundedContainer sx={{ marginBottom: 2, paddingY: 2 }}>
          <Typography sx={{ fontSize: '18px!important', fontWeight: 'bold', marginBottom: 1 }}>
            {t('treatmentPlans.treatmentPlanForm.issuesRequiringAttentionPost')}
          </Typography>
          <Controller
            name="clinicalIssues"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.clinicalIssues}
                helperText={errors.clinicalIssues?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.clinicalIssues')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
          <Controller
            name="medicalIssues"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.medicalIssues}
                helperText={errors.medicalIssues?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.medicalIssues')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
          <Controller
            name="psychiatricIssues"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.psychiatricIssues}
                helperText={errors.psychiatricIssues?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.psychiatricIssues')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
          <Controller
            name="completionCriteria"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.completionCriteria}
                helperText={errors.completionCriteria?.message}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.treatmentPlanForm.completionCriteria')}
                variant="outlined"
                multiline
                minRows={3}
              />
            )}
          />
        </RoundedContainer>
        {treatmentPlanId && (
          <RoundedContainer sx={{ marginBottom: 2, paddingY: 2 }}>
            <SignatureList
              parentPlanId={treatmentPlanId ? treatmentPlanId : 0}
              treatmentPlanCompletedSigned={false}
              signatureData={treatmentPlanData?.treatmentSignatures || []}
            />
          </RoundedContainer>
        )}

        <RoundedContainer sx={{ marginBottom: 2, paddingY: 2 }}>
          <TreatmentPlanAuditLogs treatmentPlanId={treatmentPlanId} />
        </RoundedContainer>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            columnGap: 2,
            pb: 3,
            px: matches ? 2 : 1,
            pt: 1,
          }}
        >
          {!matches && <Divider sx={{ width: '100%' }} />}

          {pageErrors.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            ))}
          {Object.keys(errors).length > 0 && (
            <SummaryError
              errors={errors}
              message={t('treatmentPlans.treatmentPlanForm.error.summary')}
            ></SummaryError>
          )}
          {treatmentPlanId && (
            <>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  viewReport(treatmentPlanId);
                }}
                sx={{
                  minWidth: '24%',
                  width: matches ? '100%' : 'fit-content',
                  my: 2,
                  py: 2,
                  float: 'right',
                }}
              >
                {t('treatmentPlans.treatmentPlanForm.buttonExportReport')}
              </Button>
              {accountConfig?.ehrEnabled && accountConfig?.sendPatientReportEhr && (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    handleSendPatientReportToEHR(treatmentPlanId);
                  }}
                  sx={{
                    minWidth: '24%',
                    width: matches ? '100%' : 'fit-content',
                    my: 2,
                    py: 2,
                    float: 'right',
                  }}
                >
                  {t('treatmentPlans.treatmentPlanForm.buttonSentToEHR')}
                </Button>
              )}
            </>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              minWidth: '24%',
              width: matches ? '100%' : 'fit-content',
              my: 2,
              py: 2,
              float: 'right',
            }}
          >
            {treatmentPlanId
              ? t('treatmentPlans.treatmentPlanForm.buttonEdit')
              : t('treatmentPlans.treatmentPlanForm.buttonAdd')}
          </Button>
        </Box>
      </Box>
      <PopupModal isOpen={isOpenPopup} contentPopup={contentPopup} onClick={confirmUnSaved} />
    </Box>
  );
}
