import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from '../../../app/hooks';
import { useDeleteStrategyMutation } from '../../../app/services/treatment-plan/strategy';
import { useToast } from '../../../app/toast';
import LocalPopupModal from '../../../common/components/LocalPopupModal';
import RoundedContainer from '../../../common/components/RoundedContainer';
import DeactivatePatientIcon from '../../../common/components/icons/DeactivatePatientIcon';
import EditUserIcon from '../../../common/components/icons/EditInterviewIcon';
import { sortItemsOnList } from '../../../common/utils/commonUtils';
import { SortDirs } from '../../../common/utils/sortInfo';
import { truncate } from '../../../common/utils/treatmentPlans';
import { PopupContent } from '../../../types/PopupType';
import { ObjectiveItem } from '../../../types/treatment-plan/ObjectiveType';
import { StrategyItem } from '../../../types/treatment-plan/StrategyType';
import ContextMenu from '../../patient/ContextMenu';
import { setTreatmentPlanReloadOption } from '../treatmentPlanSlice';
import StrategyForm from './StrategyForm';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledSortIconBox = styled(Box)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.primary.main,
  verticalAlign: 'middle',
}));

interface StrategyListProps {
  strategyRows: StrategyItem[];
  objectiveData: ObjectiveItem;
  isNoIndent?: boolean;
  objectiveCompleted?: boolean;
}

export default function StrategyList({
  strategyRows,
  objectiveData,
  isNoIndent,
  objectiveCompleted,
}: StrategyListProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const [displayedItems, setDisplayedItems] = useState<StrategyItem[]>([]);

  const defaultSortOptions = {
    sortField: 'description',
    sortDir: SortDirs.Asc,
  };

  const [sortOptions, setSortOptions] =
    useState<{ sortField: string; sortDir: SortDirs }>(defaultSortOptions);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Delete Popup
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState<boolean>(false);
  const [deletingId, setDeletingId] = useState<number>(0);

  // Edit Popup
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const [editingData, setEditingData] = useState<StrategyItem | null>(null);

  const sortHeader = ['description', 'providedBy'];

  const tableHeaders: string[][] = Object.entries(
    t('treatmentPlans.strategyList.tableHeader', {
      returnObjects: true,
    })
  );

  const [deleteStrategy] = useDeleteStrategyMutation();

  useEffect(() => {
    if (strategyRows) {
      const sortedItems = [...strategyRows];
      sortItemsOnList(sortedItems, sortOptions);
      setDisplayedItems(sortedItems);
    }
  }, [strategyRows, sortOptions]);

  const deleteContentPopup = {
    title: t('treatmentPlans.strategyList.delete.title'),
    description: `${t('treatmentPlans.strategyList.delete.description')}`,
    btnOk: t('treatmentPlans.strategyList.delete.btnOk'),
    btnClose: t('treatmentPlans.strategyList.delete.btnClose'),
    toastMessage: t('treatmentPlans.strategyList.delete.toastMessage'),
  } as PopupContent;

  const showDeletePopup = async (strategyId: number) => {
    setIsOpenDeletePopup(true);
    setDeletingId(strategyId);
    handleClose();
  };

  const closeDeletePopup = async () => {
    setIsOpenDeletePopup(false);
  };

  const editContentPopup = {
    title: t('treatmentPlans.strategyForm.edit'),
  } as PopupContent;

  const showEditPopup = async (strategyData: StrategyItem) => {
    setIsOpenEditPopup(true);
    setEditingData(strategyData);
    handleClose();
  };

  const closeEditPopup = async () => {
    setIsOpenEditPopup(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHeaderClicked = (header?: string) => {
    if (header && displayedItems?.length) {
      const newSortOptions = {
        sortField: header,
        sortDir: sortOptions.sortDir === SortDirs.Asc ? SortDirs.Desc : SortDirs.Asc,
      };

      setSortOptions(newSortOptions);
    }
  };

  const handleDeleteStrategy = async (successCallback?: () => void) => {
    try {
      await deleteStrategy({ id: deletingId }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('treatmentPlans.strategyList.delete.success'), 'success');
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
    } catch (e) {
      toast.publish(t('treatmentPlans.strategyList.delete.fail'), 'error');
    }
    closeDeletePopup();
  };

  const onFormSubmitCallback = async (strategyData: StrategyItem, result: boolean) => {
    if (result) {
      setIsOpenEditPopup(false);
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
    }
  };

  const renderStrategyContextMenu = (item: StrategyItem) => {
    const menuItems = [
      {
        label: t('treatmentPlans.strategyList.menuContext.edit'),
        icon: <EditUserIcon />,
        onClick: () => showEditPopup(item),
      },
      {
        isDisplayed: !objectiveCompleted,
        label: t('treatmentPlans.strategyList.menuContext.delete'),
        icon: <DeactivatePatientIcon />,
        onClick: () => showDeletePopup(item.id),
      },
    ];

    return (
      <ContextMenu
        id={`strategy-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    );
  };

  const columnWidths = isNoIndent
    ? {
        icon: '66px',
        name: '34%',
        providedBy: '34%',
        frequency: '17%',
        action: '10%',
      }
    : {
        icon: '66px',
        name: '38%',
        providedBy: '28%',
        frequency: '15%',
        action: '8%',
      };

  const renderStrategyRow = (rowData: StrategyItem) => {
    return (
      <TableRow key={rowData.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell
          sx={{
            width: columnWidths.icon,
            minWidth: columnWidths.icon,
            maxWidth: columnWidths.icon,
          }}
        />
        <StyledTableCell sx={{ width: columnWidths.name }}>
          <Link
            sx={{ cursor: 'pointer' }}
            component="div"
            variant="body2"
            onClick={() => {
              showEditPopup(rowData);
            }}
          >
            {truncate(rowData.description, 180)}
          </Link>
        </StyledTableCell>
        <StyledTableCell sx={{ width: columnWidths.providedBy }}>
          {rowData.providedByData?.name}
        </StyledTableCell>
        <StyledTableCell sx={{ width: columnWidths.frequency }}>
          {truncate(rowData.frequency, 180)}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{
            width: columnWidths.action,
            '& .MuiButtonBase-root': {
              marginLeft: '10px',
            },
          }}
        >
          {renderStrategyContextMenu(rowData)}
        </StyledTableCell>
      </TableRow>
    );
  };

  const renderDesktopData = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            {tableHeaders.map(([headerKey, headerLabel], index) => {
              const isSortable = sortHeader.includes(headerKey);
              const hasSort = sortOptions.sortField === headerKey;
              const sortArrowIcon =
                sortOptions.sortDir === SortDirs.Asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
              const sortIcon = hasSort ? (
                <StyledSortIconBox>{sortArrowIcon}</StyledSortIconBox>
              ) : (
                <></>
              );
              return (
                <StyledTableCell
                  sx={isSortable ? { cursor: 'pointer' } : {}}
                  className={isNoIndent ? 'no-indent' : 'indent'}
                  key={`${headerKey} ${index}`}
                  onClick={isSortable ? () => onHeaderClicked(headerKey) : undefined}
                >
                  {headerLabel} {sortIcon}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{displayedItems.map((rowData) => renderStrategyRow(rowData))}</TableBody>
      </Table>
    );
  };

  return (
    <>
      <Box>
        {displayedItems.length === 0 ? (
          <RoundedContainer sx={{ mt: 2, p: 2 }}>
            <Typography sx={{ fontSize: '16px !important', textAlign: 'center' }}>
              {t('treatmentPlans.strategyList.error.notFoundOnList')}
            </Typography>
          </RoundedContainer>
        ) : (
          <Box sx={{ mt: 2 }}>{renderDesktopData()}</Box>
        )}
      </Box>

      {/* Delete Popup */}
      <LocalPopupModal
        isOpen={isOpenDeletePopup}
        contentPopup={deleteContentPopup}
        onSuccess={handleDeleteStrategy}
        onClose={closeDeletePopup}
      />

      {/* Edit Popup */}
      <LocalPopupModal
        isOpen={isOpenEditPopup}
        contentPopup={editContentPopup}
        onClose={closeEditPopup}
        maxWidth={'md'}
        fullWidth={true}
      >
        {editingData && (
          <StrategyForm
            parentObjectiveId={objectiveData.id}
            strategyData={editingData}
            onFormSubmitCallback={onFormSubmitCallback}
          />
        )}
      </LocalPopupModal>
    </>
  );
}
