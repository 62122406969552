import { interviewApis } from '../../app/services/interview';
import { monitorApis } from '../../app/services/monitor';
import { AppDispatch } from '../../app/store';
import { AssessmentType } from '../../common/utils/assessmentType';
import { formatServerDate } from '../../common/utils/dateTimeUtils';
import { RouteInfo } from '../../types/PageBreadcrumb';
import {
  LatestAssessmentItem,
  LatestInterviewsItem,
  LatestMonitorsItem,
} from '../../types/PatientRequest';

const PATIENT_METADATA = 'patientMetadata';
const PATIENT_ASSESSMENTS = 'patientAssessments';
const ROUTE_HISTORY = 'routeHistory';

export const mapInterviewItem = (item: LatestInterviewsItem) => ({
  ...item,
  type: AssessmentType.Interview,
});
export const mapMonitorItem = (item: LatestMonitorsItem) => ({
  ...item,
  type: AssessmentType.Monitor,
});

export const loadAssessmentCachedData = (patientId: string) => {
  let metadata: any;
  let assessments: LatestAssessmentItem[];
  try {
    assessments = JSON.parse(localStorage.getItem(PATIENT_ASSESSMENTS) || '[]');
    metadata = JSON.parse(localStorage.getItem(PATIENT_METADATA) || '{}');
  } catch {
    assessments = [];
    metadata = {};
  }

  // Clear cached data if patientId is changed
  if ((metadata.patientId || '').length > 0 && metadata.patientId !== patientId) {
    assessments = [];
    metadata = {};
  }

  return {
    assessments,
    metadata,
  };
};

export const saveRouteHistoryData = (routeInfo: RouteInfo, data?: Record<string, any>): void => {
  const { routeHistory = [] }: { routeHistory: RouteInfo[]; params: Record<string, any> } =
    JSON.parse(localStorage.getItem(ROUTE_HISTORY) || '{}');

  if (routeHistory?.length > 1) {
    routeHistory.unshift(routeInfo);
    routeHistory.pop();
  } else {
    routeHistory.unshift(routeInfo);
  }

  localStorage.setItem(
    ROUTE_HISTORY,
    JSON.stringify({
      routeHistory,
      params: data,
    })
  );
};

export const savePatientDashboardPageBehaviorData = (patientId: string): boolean => {
  const { routeHistory = [], params }: { routeHistory: RouteInfo[]; params: Record<string, any> } =
    JSON.parse(localStorage.getItem(ROUTE_HISTORY) || '{}');

  const notShowPopupFromPaths = [
    '/loading',
    '/dashboard/patient/:patientId',
    '/dashboard/patient/:patientId/treatment-plan',
    '/dashboard/patient/:patientId/treatment-plan/add',
    '/dashboard/patient/:patientId/treatment-plan/edit/:treatmentPlanId',
    '/dashboard/patient/:patientId/follow-up',
    '/dashboard/patient/:patientId/diagnosis',
    '/dashboard/patient/:patientId/notes',
    '/dashboard/patient/:patientId/patient-report',
    '/dashboard/patient/:patientId/edit-schedule/:assessmentType/:assessmentTypeId',
    '/dashboard/edit-patient/:patientId',
    '/dashboard/patient/:patientId/:interviewResultId/resume-interview',
    '/dashboard/patient/:patientId/:monitorResultId/resume-monitor',
    '/dashboard/patient/:patientId/:interviewResultId/start-interview',
    '/dashboard/patient/:patientId/:monitorResultId/start-monitor',
    '/dashboard/current-interview/interview-complete/:patientId/:interviewResultId',
    '/dashboard/current-monitor/monitor-complete/:patientId/:monitorResultId',
    '/dashboard/current-monitor/screenerPage/:patientId/:monitorResultId',
    '/dashboard/patient/:patientId/:interviewResultId/edit-interview',
    '/dashboard/current-interview/:reportType/:patientId/:interviewResultId',
    '/dashboard/current-interview/:reportType/:patientId/:interviewResultId/edit-interview',
    '/dashboard/patient/:patientId/report-history/:interviewResultId',
    '/dashboard/patient/:patientId/treatment-plan/edit/:treatmentPlanId/goal/add',
    '/dashboard/patient/:patientId/treatment-plan/edit/:treatmentPlanId/goal/edit/:treatmentPlanGoalId',
    '/dashboard/treatment-plan-report/:patientId/:treatmentPlanId',
  ];

  return !(
    patientId === params?.patientId &&
    ((notShowPopupFromPaths.includes(routeHistory?.[1]?.basePath) &&
      routeHistory?.[1]?.path.includes(patientId)) ||
      notShowPopupFromPaths.includes(routeHistory?.[1]?.path))
  );
};

export const saveAssessmentCachedData = (
  patientId: string,
  assessments: LatestAssessmentItem[],
  metadata: any,
  batchPageIndex: number
) => {
  localStorage.setItem(PATIENT_ASSESSMENTS, JSON.stringify(assessments));
  localStorage.setItem(
    PATIENT_METADATA,
    JSON.stringify({ ...metadata, [batchPageIndex]: true, patientId })
  );
};

export const clearAssessmentCachedData = () => {
  localStorage.removeItem(PATIENT_ASSESSMENTS);
  localStorage.removeItem(PATIENT_METADATA);
};

export const fetchMissingAssessmentBatches = async ({
  patientId,
  batchPageIndex,
  batchSize,
  cachedMetadata: inputMetadata,
  dispatch,
}: {
  patientId: string;
  batchPageIndex: number;
  batchSize: number;
  cachedMetadata: any;
  dispatch: AppDispatch;
}) => {
  let cachedMetadata = { ...inputMetadata };
  let missingDataList: LatestAssessmentItem[] = [];
  const missingBatchFetchingPromise: Promise<any>[] = [];
  const missingBatchIds: any = {};
  for (let index = 1; index < batchPageIndex; index++) {
    if (!cachedMetadata[index]) {
      missingBatchIds[index] = true;

      missingBatchFetchingPromise.push(
        dispatch(
          interviewApis.getLatestInterviews.initiate({
            patientId,
            page: index,
            pageSize: batchSize,
          })
        )
      );

      missingBatchFetchingPromise.push(
        dispatch(
          monitorApis.getLatestMonitors.initiate({
            patientId,
            page: index,
            pageSize: batchSize,
          })
        )
      );
    }
  }

  if (missingBatchFetchingPromise.length) {
    let promiseIndex = 0;
    (await Promise.all(missingBatchFetchingPromise)).forEach((response) => {
      if (response?.data) {
        let result: LatestAssessmentItem[] = [];
        if (promiseIndex % 2 === 0) {
          result = response.data.items.map((item: LatestInterviewsItem) => mapInterviewItem(item));
        } else {
          result = response.data.items.map((item: LatestMonitorsItem) => mapMonitorItem(item));
        }
        missingDataList = missingDataList.concat(result);
        promiseIndex++;
      }
    });
    cachedMetadata = { ...cachedMetadata, ...missingBatchIds };
  }

  return {
    cachedMetadata,
    missingDataList,
  };
};

export const getPagedAssessmentList = async ({
  patientId,
  currentPage,
  pageSize,
  aggregateAssessments,
  dispatch,
}: {
  patientId: string;
  currentPage: number;
  pageSize: number;
  aggregateAssessments: LatestAssessmentItem[];
  dispatch: AppDispatch;
}) => {
  const pagedList = aggregateAssessments.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  );

  const fetchedInterviewIds = pagedList.filter(
    (item: LatestAssessmentItem) => item.type === AssessmentType.Interview
  );
  const interviewPromise = fetchedInterviewIds.length
    ? dispatch(
        interviewApis.getLatestInterviewsByIds.initiate({
          patientId,
          page: 1,
          pageSize: 1,
          interviewResultIds: fetchedInterviewIds.map((item) => item.id),
        })
      )
    : new Promise((resolve) => resolve(undefined));

  const fetchedMonitorIds = pagedList.filter(
    (item: LatestAssessmentItem) => item.type === AssessmentType.Monitor
  );
  const monitorPromise = fetchedMonitorIds.length
    ? dispatch(
        monitorApis.getLatestMonitorsByIds.initiate({
          patientId,
          page: 1,
          pageSize: 1,
          monitorResultIds: fetchedMonitorIds.map((item) => item.id),
        })
      )
    : new Promise((resolve) => resolve(undefined));

  const results = await Promise.all([interviewPromise, monitorPromise]);
  const detailedInterviewResponse = results[0];
  const detailedMonitorResponse = results[1];

  const aggregateList = [
    ...((detailedInterviewResponse as any)?.data?.items?.map((item: LatestInterviewsItem) =>
      mapInterviewItem(item)
    ) || []),
    ...((detailedMonitorResponse as any)?.data?.items?.map((item: LatestMonitorsItem) =>
      mapMonitorItem(item)
    ) || []),
  ].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  return aggregateList;
};

export const getMeasumentStatus = (status: string): string => {
  const statusNames: any = {
    notStarted: 'Not Started',
    inProgress: 'In Progress',
    completed: 'Completed',
    pendingScoring: 'Pending Scoring',
  };

  return statusNames[status] || '';
};

export const getAssessmentStatus = (row: { status: string; completedAt: Date }) => {
  const { status, completedAt } = row;
  const statusText = getMeasumentStatus(status);
  if (statusText !== 'Completed') {
    return statusText;
  }

  return formatServerDate(completedAt) || 'N/A';
};
