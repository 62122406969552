import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import './globalLoadingStyles.css';
import Typography from '@mui/material/Typography';

interface GlobalLoadingProps {
  appBarHeight?: number;
  loadingText?: string;
}

function GlobalLoadingIcon() {
  return (
    // note: need a wrapper 'absolute' div to avoid flashing issue on Safari
    <Box sx={{ position: 'absolute' }}>
      <Box sx={{ position: 'fixed', top: '20vh' }}>
        <Box className="spinner">
          <Box className="dot1"></Box>
          <Box className="dot2"></Box>
        </Box>
      </Box>
    </Box>
  );
}

export function GlobalLoading({ appBarHeight, loadingText }: GlobalLoadingProps) {
  return (
    <Backdrop
      sx={(theme) => ({
        backgroundColor: alpha(theme.xPalette.white, 0.9),
        position: 'absolute',
        top: appBarHeight || 0,
        zIndex: theme.zIndex.drawer + 1,
      })}
      open={true}
    >
      {loadingText && <Typography sx={{ fontSize: '22px!important', marginLeft: 5}}>{loadingText}</Typography>}
      <GlobalLoadingIcon />
    </Backdrop>
  );
}
