import { forwardRef } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useGetPatientQuery } from '../../app/services/patient';
import { BaseProps } from '../../types/PageProps';
import { PatientInfoCardProps } from '../../types/PatientType';
import PatientInfoCard from './PatientInfoCard';
import RoundedContainer from './RoundedContainer';

interface Props extends BaseProps, PatientInfoCardProps {
  sx?: SxProps<Theme>;
  patientId?: string;
}

const PatientInfoLayout = forwardRef<HTMLDivElement, Props>(
  ({ children, sx: styles, patientId, ...patientInfoProps }, ref) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const { data } = useGetPatientQuery(
      patientId
        ? {
            patientId,
          }
        : skipToken
    );

    return (
      <>
        <RoundedContainer
          ref={ref}
          sx={{
            backgroundColor: theme.xPalette.white,
            display: 'flex',
            py: 2,
            px: matches ? 2 : 3,
            borderRadius: 4,
            ...styles,
          }}
        >
          <PatientInfoCard {...patientInfoProps} data={data} />
        </RoundedContainer>
        {children}
      </>
    );
  }
);
export default PatientInfoLayout;
