import { buildAccountUrl, QueryCacheDuration, rootApi } from '..';
import { DeleteParam } from '../../../types/CommonType';
import {
  GoalAddRequest,
  GoalAddResponse,
  GoalEditRequest,
  GoalEditResponse,
  GoalItem,
  GoalListReponse,
  GoalListRequest,
} from '../../../types/treatment-plan/GoalType';

const api = rootApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getGoalList: builder.query<GoalListReponse, GoalListRequest>({
      query: (params) => ({
        url: buildAccountUrl('/v1/account/treatment-goal'),
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    deleteGoal: builder.mutation<void, DeleteParam>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-goal/${params.id}`),
        method: 'DELETE',
      }),
    }),

    getGoalData: builder.query<{ data: GoalItem }, { id: number }>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-goal/${params.id}?isIncludeObjectives=true`),
        method: 'GET',
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    addGoal: builder.mutation<GoalAddResponse, GoalAddRequest>({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-goal`),
        method: 'POST',
        body,
      }),
    }),

    editGoal: builder.mutation<GoalEditResponse, GoalEditRequest>({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-goal/${body.id}`),
        method: 'PUT',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetGoalListQuery,
  useLazyGetGoalListQuery,
  useDeleteGoalMutation,
  useGetGoalDataQuery,
  useAddGoalMutation,
  useEditGoalMutation,
} = api;
