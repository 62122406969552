import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Container from '@mui/material/Container';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useGetPatientQuery } from '../../app/services/patient';
import {
  useAddTreatmentPlanMutation,
  useEditTreatmentPlanMutation,
  useGetTreatmentPlanByIdQuery,
} from '../../app/services/treatment-plan/treatmentPlan';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { ReplaceType, editBreadcrumbs } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import ErrorPage from '../error/ErrorPage';
import { setHiddenEmailOption } from '../patient/patientSlice';
import AddOrEditTreatmentPlanForm from './TreatmentPlanForm';
import {
  selectTreatmentPlanReloadOption,
  setTreatmentPlanPatientId,
  setTreatmentPlanReloadOption,
} from './treatmentPlanSlice';

export default function AddOrEditTreatmentPlanPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { patientId, treatmentPlanId } = params;
  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }];
  const reloadGoal = useAppSelector(selectTreatmentPlanReloadOption);

  const [addTreatmentPlan] = useAddTreatmentPlanMutation();
  const [editTreatmentPlan] = useEditTreatmentPlanMutation();
  const {
    data: treatmentPlanData,
    error,
    refetch,
  } = useGetTreatmentPlanByIdQuery(
    treatmentPlanId
      ? {
          treatmentPlanId: parseInt(treatmentPlanId),
          isIncludeGoals: true,
          isIncludeDiagnose: true,
          isIncludeSignatures: true,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  if (patientId) {
    dispatch(setTreatmentPlanPatientId({ patientId }));
  }

  const { data: patientInfo } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const onFormSubmit = async (
    data: any,
    treatmentPlanId?: number,
    successCallback?: () => void
  ) => {
    if (treatmentPlanId) {
      await editTreatmentPlan({ ...data, treatmentPlanId }).unwrap();
    } else {
      const addedTreatmentPlan = await addTreatmentPlan(data).unwrap();
      if (addedTreatmentPlan?.data?.id) {
        navigate(
          `/dashboard/patient/${patientId}/treatment-plan/edit/${addedTreatmentPlan.data.id}`
        );
      }
    }

    if (successCallback) {
      successCallback();
    }

    dispatch(setTreatmentPlanReloadOption({ reload: true }));
    toast.publish(
      (treatmentPlanId && t('treatmentPlans.treatmentPlanForm.updateTreatmentPlanSuccess')) ||
        t('treatmentPlans.treatmentPlanForm.addTreatmentPlanSuccess'),
      'success'
    );
  };

  useEffect(() => {
    if (treatmentPlanData?.data) {
      dispatch(
        setHiddenEmailOption({
          patientHasEmail: treatmentPlanData?.data.patientHasEmail,
          patientSMSOptIn: treatmentPlanData?.data.patientSMSOptIn,
        })
      );
    }
  }, [dispatch, treatmentPlanData?.data, treatmentPlanData?.data?.patientHasEmail, treatmentPlanData?.data?.patientSMSOptIn]);

  useEffect(() => {
    if (reloadGoal?.reload) {
      refetch();
      dispatch(setTreatmentPlanReloadOption({ reload: false }));
    }
  }, [reloadGoal, refetch, dispatch]);

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }
  return (
    <DashboardLayout breadcrumbs={editBreadcrumbs(breadcrumbs, ...replacePath)}>
      <Helmet>
        <title>
          {treatmentPlanId
            ? getPageTitle(t('treatmentPlans.treatmentPlanForm.titleEdit'))
            : getPageTitle(t('treatmentPlans.treatmentPlanForm.titleAdd'))}
        </title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={
            treatmentPlanId
              ? t('treatmentPlans.treatmentPlanForm.titleEdit')
              : t('treatmentPlans.treatmentPlanForm.titleAdd')
          }
          backUrl={`/dashboard/patient/${patientId}/treatment-plan`}
        />
        <RoundedContainer sx={{ py: 2 }}>
          <PatientInfoCard data={patientInfo!} />
        </RoundedContainer>
      </Container>

      <AddOrEditTreatmentPlanForm
        patientId={patientId}
        treatmentPlanId={treatmentPlanId ? parseInt(treatmentPlanId) : undefined}
        treatmentPlanData={treatmentPlanData?.data}
        onFormSubmit={onFormSubmit}
      />
    </DashboardLayout>
  );
}
