import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as ArrowDownIconSvg } from '../../../assets/icons/arrow-down.svg';

export default function ArrowDownIcon() {
  const theme = useTheme();
  return (
    <SvgIcon
      component={ArrowDownIconSvg}
      sx={{ '& path': { fill: 'white', stroke: theme.xPalette.shadeGrey } }}
    />
  );
}
