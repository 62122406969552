import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import theme from '../../app/theme';
import { SubmitButtonProps } from '../../types/CommonType';

export default function SubmitButton({
  content,
  matches,
  pageErrors,
  disabled,
}: SubmitButtonProps) {
  return (
    <>
      {!matches && <Divider sx={{ py: 1, width: '100%' }} />}
      {pageErrors &&
        pageErrors?.length > 0 &&
        pageErrors.map((error) => (
          <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
            {error}
          </Alert>
        ))}
      <Box
        sx={{
          width: '100%',
          pb: 3,
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button
          type="submit"
          data-testid="submit-button"
          role="button"
          fullWidth
          variant="contained"
          disabled={disabled}
          sx={{
            width: matches ? '100%' : '24%',
            my: 2,
            py: 2,
            [theme.breakpoints.down('md')]: {
              my: 2,
            },
          }}
        >
          {content}
        </Button>
      </Box>
    </>
  );
}
