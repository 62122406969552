import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Autocomplete from '@mui/lab/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useAddOrEditPatientDiagnosisMutation,
  useDeletePatientDiagnosisMutation,
  useGetDiagnosesQuery,
  useGetPatientDiagnosesQuery,
  useGetPatientDiagnosisInfoQuery,
} from '../../app/services/patient';
import { useToast } from '../../app/toast';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import SubmitButton from '../../common/components/SubmitButton';
import DeleteReportIcon from '../../common/components/icons/DeleteReportIcon';
import EditUserIcon from '../../common/components/icons/EditInterviewIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { formatServerDateAndTime } from '../../common/utils/dateTimeUtils';
import { addBreadcrumb, editBreadcrumbs, ReplaceType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { StyledClinicianNote } from '../../features/interview/interviewUtils';
import { PageProps } from '../../types/PageProps';
import { CommonItem, PatientDiagnosisInfo } from '../../types/PatientRequest';
import { PopupContent } from '../../types/PopupType';
import { ResponseError } from '../../types/ResponseError';
import ContextMenu from './ContextMenu';

const StyleDivider = styled(Divider)(() => ({
  width: '100%',
}));

interface FormType {
  diagnosis: string;
  timeFrame: string;
  otherDiagnosis: string;
}

const defaultFormValues: FormType = {
  diagnosis: '',
  timeFrame: '',
  otherDiagnosis: '',
};

export default function ProviderFeedbackPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { patientId } = useParams();
  const { state } = useLocation();
  const { fromPage } = !!state && state;
  const dispatch = useAppDispatch();
  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);

  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [disabledOtherForm, setDisabledOtherForm] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [patientIdAction, setPatientIdAction] = useState<string>('');
  const [diagnosisIdAction, setDiagnosisIdAction] = useState<number>(0);
  const [diagnoses, setDiagnosesData] = useState<CommonItem[]>([]);
  const [timeFramesData, setTimeFramesData] = useState<string[]>([]);

  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }];
  const diagnosisId = searchParams.get('diagnosisId');
  const formRef = useRef<HTMLDivElement>(null);

  const [addOrEditPatientDiagnosis] = useAddOrEditPatientDiagnosisMutation();
  const [deletePatientDiagnosis] = useDeletePatientDiagnosisMutation();

  const deletePatientDiagnosisContentPopup = {
    title: t('patient.providerFeedback.deletePatientDiagnosisContent.title'),
    description: t('patient.providerFeedback.deletePatientDiagnosisContent.description'),
    btnOk: t('patient.providerFeedback.deletePatientDiagnosisContent.btnOk'),
    btnClose: t('patient.providerFeedback.deletePatientDiagnosisContent.btnClose'),
    toastMessage: t('patient.providerFeedback.deletePatientDiagnosisContent.toastMessage'),
  } as PopupContent;

  const { data: diagnosesData, isLoading: isLoadingDiagnoses } = useGetDiagnosesQuery(
    !!patientId ? { patientId } : skipToken
  );

  const {
    data: patientData,
    isLoading: isLoadingPatientDiagnoses,
    refetch: refetchPatientDiagnosis,
  } = useGetPatientDiagnosesQuery(!!patientId ? { patientId } : skipToken);

  const { data: diagnosesInfoData, isLoading: isLoadingDiagnosesInfoData } =
    useGetPatientDiagnosisInfoQuery(
      !!patientId && !!diagnosisId
        ? { patientId, patientDiagnosisId: parseInt(diagnosisId) }
        : skipToken
    );

  const validationSchema = yup
    .object({
      diagnosis: yup.string().required(t('patient.error.blankDiagnosis')),
      timeFrame: yup.string().when('diagnosis', {
        is: (diagnosis: string) => diagnosis === '0' || timeFramesData.length === 0,
        then: yup.string().trim(),
        otherwise: yup.string().trim().required(t('patient.error.blankTimeFrame')),
      }),
      otherDiagnosis: yup.string().when('diagnosis', {
        is: '0',
        then: yup.string().trim().required(t('patient.error.blankOtherDiagnosis')),
        otherwise: yup.string().trim(),
      }),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    resetField,
    clearErrors,
  } = useForm<FormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const showDeletePatientDiagnosisPopup = async (patientId: string, diagnosisId: number) => {
    dispatch(setContentPopup({ content: deletePatientDiagnosisContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setPatientIdAction(patientId);
    setDiagnosisIdAction(diagnosisId);
    handleClose();
  };

  useEffect(() => {
    if (diagnosesData) {
      const newDiagnosesData = [...diagnosesData.diagnoses];
      newDiagnosesData.push({
        id: 0,
        name: t('patient.providerFeedback.form.otherDiagnosis'),
        availableTimeframes: diagnosesData.otherDiagnosesTimeFrames,
      });
      setDiagnosesData(newDiagnosesData);
    }
  }, [clearErrors, diagnosesData, t]);

  useEffect(() => {
    if (diagnosesInfoData) {
      const bindEditControls = () => {
        if (diagnosesInfoData.diagnosisId) {
          setValue('diagnosis', diagnosesInfoData.diagnosisId.toString());
        } else {
          setValue('diagnosis', '0');
          setDisabledOtherForm(false);
        }

        if (diagnosesInfoData.timeFrame) {
          setValue('timeFrame', diagnosesInfoData.timeFrame);
          setTimeFramesData(diagnosesInfoData.availableTimeframes);
        }
        setValue('otherDiagnosis', diagnosesInfoData.otherDiagnosis);
      };

      bindEditControls();
    }

    (formRef.current as HTMLDivElement).scrollIntoView({ behavior: 'smooth' });
  }, [diagnosesInfoData, setValue]);

  const filterDiagnosisOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getDiagnosisOptionLabel(option),
  });

  const getDiagnosisOptionLabel = (option: any) => option.name;

  const handleDiagnosisChange = (e: any, values: CommonItem) => {
    if (!values) {
      reset();
    } else {
      const value = values?.id;
      const availableTimeFrames = values?.availableTimeframes;
      setDisabledOtherForm(true);
      if (value === 0) {
        setDisabledOtherForm(false);
      }

      setTimeFramesData(availableTimeFrames);
      resetField('otherDiagnosis');
      resetField('timeFrame');
      setValue('diagnosis', value.toString());
    }
  };

  const handleEdit = (data: PatientDiagnosisInfo) => {
    navigate(`/dashboard/patient/${patientId}/diagnosis?diagnosisId=${data.patientDiagnosisId}`);
    setAnchorEl(null);
    clearErrors(['diagnosis', 'timeFrame', 'otherDiagnosis']);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderContextMenu = (data: PatientDiagnosisInfo) => {
    const menuItems = [
      {
        isDisplayed: true,
        label: t('patient.providerFeedback.form.edit'),
        icon: <EditUserIcon />,
        onClick: () => handleEdit(data),
      },
      {
        isDisplayed: true,
        label: t('patient.providerFeedback.form.delete'),
        icon: <DeleteReportIcon />,
        onClick: () => showDeletePatientDiagnosisPopup(data.patientId, data.patientDiagnosisId),
      },
    ];

    return (
      <ContextMenu
        id={`${data.patientDiagnosisId}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    );
  };

  const renderDesktopData = (data: PatientDiagnosisInfo[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableBody>
            {data?.map((item: PatientDiagnosisInfo) => (
              <TableRow key={item.patientDiagnosisId}>
                <TableCell align="center">{item.patientName}</TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: 600, '&.MuiTableCell-root': { fontSize: '18px' } }}
                >{`${item.diagnosisName ? item.diagnosisName : item.otherDiagnosis} ${
                  !!item.timeFrame ? ' - ' : ''
                } ${item.timeFrame}`}</TableCell>
                <TableCell align="center">{formatServerDateAndTime(item.createdAt)}</TableCell>
                <TableCell align="center">{renderContextMenu(item)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: PatientDiagnosisInfo[]) => {
    return (
      <>
        {data.map((item: PatientDiagnosisInfo) => (
          <RoundedContainer key={item.patientDiagnosisId} sx={{ mt: 1, py: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
              <Typography>{formatServerDateAndTime(item.createdAt)}</Typography>
              <Box>{renderContextMenu(item)}</Box>
            </Box>
            <StyleDivider />
            <Box sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>{`${
                item.diagnosisName ? item.diagnosisName : item.otherDiagnosis
              } ${!!item.timeFrame ? ' - ' : ''} ${item.timeFrame}`}</Typography>
            </Box>
            <StyleDivider />
            <Box sx={{ py: 1 }}>
              <Typography>{item.patientName}</Typography>
            </Box>
          </RoundedContainer>
        ))}
      </>
    );
  };

  const handleDeletePatientDiagnosis = async (successCallback?: () => void) => {
    try {
      if (patientIdAction && diagnosisIdAction) {
        await deletePatientDiagnosis({
          patientId: patientIdAction,
          patientDiagnosisId: diagnosisIdAction,
        }).unwrap();
      }
      if (successCallback) {
        successCallback();
      }
      toast.publish(
        t('patient.providerFeedback.deletePatientDiagnosisContent.toastMessage'),
        'success'
      );
      navigate(`/dashboard/patient/${patientId}/diagnosis`);
      reset();
      setPageErrors([]);
      setTimeFramesData([]);
      setDisabledOtherForm(true);
      refetchPatientDiagnosis();
    } catch (e) {
      toast.publish(t('patient.error.cannotDeletePatientDiagnosis'), 'error');
    }
  };

  const onSubmit = async (data: FormType) => {
    const { diagnosis, timeFrame, otherDiagnosis } = data;
    const reqData = {
      patientId: patientId!,
      patientDiagnosisId: diagnosisId ? parseInt(diagnosisId) : null,
      diagnosis: parseInt(diagnosis) !== 0 ? parseInt(diagnosis) : null,
      timeFrame: timeFrame || null,
      otherDiagnosis,
    };
    try {
      const res = await addOrEditPatientDiagnosis(reqData).unwrap();
      if (res) {
        toast.publish(
          diagnosisId
            ? t('patient.providerFeedback.updateSuccess')
            : t('patient.providerFeedback.addSuccess'),
          'success',
          3000
        );
        if (diagnosisId) {
          navigate(`/dashboard/patient/${patientId}/diagnosis`);
        }

        reset();
        setPageErrors([]);
        setTimeFramesData([]);
        setDisabledOtherForm(true);
        refetchPatientDiagnosis();
      }
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const isLoading = isLoadingDiagnoses || isLoadingPatientDiagnoses || isLoadingDiagnosesInfoData;

  return (
    <Box ref={formRef}>
      <DashboardLayout
        breadcrumbs={
          !fromPage
            ? editBreadcrumbs(breadcrumbs, ...replacePath)
            : addBreadcrumb(breadcrumbs!, fromPage)
        }
      >
        <Helmet>
          <title>{getPageTitle(t('patient.providerFeedback.title'))}</title>
        </Helmet>
        <Container maxWidth="xl">
          <PageHeader
            sx={{ mt: 1, mb: 2 }}
            headerText={t('patient.providerFeedback.title')}
            backUrl={`/dashboard/patient/${patientId}`}
          />
        </Container>
        <Container maxWidth="xl" disableGutters={true}>
          <RoundedContainer sx={{ pt: 1, mt: 2 }}>
            {isLoading ? (
              <Loading />
            ) : (
              <Box sx={{ mt: 2 }} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    py: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Controller
                    name="diagnosis"
                    control={control}
                    render={({ fieldState, formState, field: { value }, ...props }) => (
                      <Autocomplete
                        {...props}
                        options={diagnoses}
                        getOptionLabel={getDiagnosisOptionLabel}
                        onChange={handleDiagnosisChange}
                        value={
                          value
                            ? diagnoses?.find((item) => item.id === parseInt(value)) || null
                            : null
                        }
                        filterOptions={filterDiagnosisOptions}
                        renderOption={(
                          props: React.HTMLAttributes<HTMLLIElement>,
                          option: CommonItem
                        ) => {
                          return (
                            <li {...props} key={option.id} value={option.id}>
                              {getDiagnosisOptionLabel(option)}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.diagnosis}
                            helperText={errors.diagnosis?.message}
                            margin="normal"
                            fullWidth
                            label={t('patient.providerFeedback.form.diagnosis')}
                          />
                        )}
                      />
                    )}
                  />

                  <Controller
                    name="timeFrame"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        select
                        fullWidth
                        label={t('patient.providerFeedback.form.timeFrames')}
                        error={!!errors.timeFrame}
                        helperText={errors.timeFrame?.message}
                        variant="outlined"
                        disabled={timeFramesData.length === 0}
                      >
                        {timeFramesData.map((timeFrame: string) => (
                          <MenuItem key={timeFrame} value={timeFrame}>
                            {timeFrame}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />

                  <Controller
                    name="otherDiagnosis"
                    control={control}
                    render={({ field }) => (
                      <StyledClinicianNote
                        {...field}
                        sx={{
                          backgroundColor: theme.xPalette.white,
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: theme.xPalette.noteLightGrey,
                            borderRadius: '8px',
                            pb: 4,
                          },
                        }}
                        disabled={disabledOtherForm}
                        multiline
                        maxRows={4}
                        fullWidth
                        label={t('patient.providerFeedback.form.otherDiagnosis')}
                        error={!!errors.otherDiagnosis}
                        helperText={errors.otherDiagnosis?.message}
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
                <SubmitButton
                  content={
                    diagnosisId
                      ? t('patient.providerFeedback.form.save')
                      : t('patient.providerFeedback.form.add')
                  }
                  matches={!isDesktop}
                  pageErrors={pageErrors}
                />
              </Box>
            )}
          </RoundedContainer>

          <RoundedContainer sx={{ py: 3, mt: 2, backgroundColor: theme.xPalette.background }}>
            {isDesktop ? (
              <RoundedContainer>
                {renderDesktopData(patientData?.patientDiagnoses || [])}
              </RoundedContainer>
            ) : (
              renderMobileData(patientData?.patientDiagnoses || [])
            )}
          </RoundedContainer>
          <PopupModal
            isOpen={isOpenPopup}
            contentPopup={contentPopup}
            onClick={handleDeletePatientDiagnosis}
          />
        </Container>
      </DashboardLayout>
    </Box>
  );
}
