import { useState } from 'react';
import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { MonitorSubQuestionProps } from '../../../types/MonitorTypes';
import {
  StyledBox,
  StyledButton, // StyledNumberField,
} from '../../interview/questions/NumberQuestion';

export const StyledNumberField = styled(TextField)(({ theme }) => ({
  width: '30%',
  '& .MuiOutlinedInput-root': {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '& fieldset': {
      borderRadius: 0,
      borderColor: theme.xPalette.lightGrey,
    },
    '&:hover fieldset': {
      borderColor: theme.xPalette.lightGrey,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.xPalette.lightGrey,
    },

    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const checkType = (input: any) => (typeof input === 'string' ? parseInt(input) : input);

export default function NumberQuestion({
  onChangeCallBack,
  subQuestionId,
  questionText,
  isRequired,
  error,
  helperText,
}: MonitorSubQuestionProps) {
  const [inputNumber, setInputNumber] = useState<number>(0);

  const setNumberValue = (value: number) => {
    const NumberOnlyRegex = /^[0-9\b]+$/;

    if (!value || NumberOnlyRegex.test(value.toString())) {
    const finalValue = typeof value === 'number' ? value : parseInt(value);
      if (onChangeCallBack) {
        onChangeCallBack(finalValue > 0 ? [finalValue] : [], subQuestionId);
      }
      setInputNumber(value);
    }
  };

  const onValueChanged = (event: any) => {
    const valueNumber = event.target.value;
    setNumberValue(valueNumber);
  };

  const onIncreaseValue = () => {
    const value = (checkType(inputNumber) || 0) + 1;
    setNumberValue(value);
  };

  const onDecreaseValue = () => {
    const value = (checkType(inputNumber) || 0) - 1;
    setNumberValue(value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl sx={{ width: '100%' }} error={error} variant="standard">
        {questionText && (
          <Typography
            mt={1}
            dangerouslySetInnerHTML={{
              __html: questionText,
            }}
          ></Typography>
        )}
        <StyledBox>
          <StyledButton
            type="button"
            variant="outlined"
            startIcon={<MinusIcon fontSize="medium" />}
            onClick={onDecreaseValue}
          ></StyledButton>
          <StyledNumberField
            value={inputNumber}
            variant="outlined"
            onChange={onValueChanged}
            onBlur={onValueChanged}
          />
          <StyledButton
            type="button"
            variant="outlined"
            startIcon={<PlusIcon fontSize="medium" />}
            onClick={onIncreaseValue}
          ></StyledButton>
        </StyledBox>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
