interface TemplateData {
  template: string;
}

export default function InterviewResultTemplate(templateData: TemplateData) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: templateData.template.replace(/(<? *script)/gi, 'illegalscript'),
      }}
    ></div>
  );
}
