import { rest } from 'msw';

const mocks = [
  rest.get('/v1/monitor/stats', (req, res, ctx) => {
    return res(
      ctx.json({
        totalMonitors: 104,
        incompleteMonitors: 12,
      })
    );
  }),
  rest.get('/v1/monitor/monitor-types?patientId', (req, res, ctx) => {
    const patientId = req.url.searchParams.get('patientId');
    console.log(patientId);
    return res(
      ctx.json({
        trackerTypes: [
          {
            id: 7,
            name: 'ADULT-H. POSTTRAUMATIC STRESS DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[8]',
          },
          {
            id: 20,
            name: 'KID-B. SUICIDALITY (for ages 9 through 12)',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[27,28,29,30]',
          },
          {
            id: 24,
            name: 'KID-E. AGORAPHOBIA',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[38]',
          },
          {
            id: 32,
            name: 'KID-M. TIC DISORDERS / TOURETTE’S DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[48]',
          },
          {
            id: 34,
            name: 'KID-O. CONDUCT DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[50]',
          },
          {
            id: 4,
            name: 'ADULT-E. AGORAPHOBIA',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[5]',
          },
          {
            id: 8,
            name: 'ADULT-I. ALCOHOL USE DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[9]',
          },
          {
            id: 10,
            name: 'ADULT-K. PSYCHOTIC DISORDERS',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[11]',
          },
          {
            id: 15,
            name: 'ADULT-P. ANTISOCIAL PERSONALITY DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[16]',
          },
          {
            id: 23,
            name: 'KID-D. PANIC DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[37]',
          },
          {
            id: 30,
            name: 'KID-K. ALCOHOL USE DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[46]',
          },
          {
            id: 31,
            name: 'KID-L. SUBSTANCE USE DISORDER (NON-ALCOHOL)',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[47]',
          },
          {
            id: 2,
            name: 'ADULT-C. MANIC AND HYPOMANIC EPISODE',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[2,3]',
          },
          {
            id: 6,
            name: 'ADULT-G. OBSESSIVE-COMPULSIVE DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[7]',
          },
          {
            id: 16,
            name: 'ADULT-Q. ADULT ATTENTION – DEFICIT / HYPERACTIVITY DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[17]',
          },
          {
            id: 22,
            name: 'KID-C. MANIC AND HYPOMANIC EPISODE',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[35,36]',
          },
          {
            id: 1,
            name: 'ADULT-A. MAJOR DEPRESSIVE EPISODE',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[1]',
          },
          {
            id: 19,
            name: 'KID-B. SUICIDALITY (for ages 6 through 8)',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[23,24,25,26]',
          },
          {
            id: 21,
            name: 'KID-B. SUICIDALITY (for ages 13 through 17)',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[31,32,33,34]',
          },
          {
            id: 25,
            name: 'KID-F. SEPARATION ANXIETY DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[39,40]',
          },
          {
            id: 35,
            name: 'KID-P. OPPOSITIONAL DEFIANT DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[51]',
          },
          {
            id: 5,
            name: 'ADULT-D. PANIC DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[6]',
          },
          {
            id: 14,
            name: 'ADULT-N. GENERALIZED ANXIETY DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[15]',
          },
          {
            id: 37,
            name: 'KID-R. ANOREXIA NERVOSA',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[53]',
          },
          {
            id: 3,
            name: 'ADULT-F. SOCIAL ANXIETY DISORDER (Social Phobia)',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[4]',
          },
          {
            id: 9,
            name: 'ADULT-J. SUBSTANCE USE DISORDER (NON-ALCOHOL)',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[10]',
          },
          {
            id: 11,
            name: 'ADULT-L. ANOREXIA NERVOSA',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[12]',
          },
          {
            id: 29,
            name: 'KID-J. POSTTRAUMATIC STRESS DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[45]',
          },
          {
            id: 39,
            name: 'KID-T. BINGE - EATING DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[55]',
          },
          {
            id: 12,
            name: 'ADULT-M. BULIMIA NERVOSA',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[13]',
          },
          {
            id: 26,
            name: 'KID-G. SOCIAL ANXIETY DISORDER (Social Phobia)',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[41]',
          },
          {
            id: 40,
            name: 'KID-V. ADJUSTMENT DISORDERS',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[56]',
          },
          {
            id: 13,
            name: 'ADULT-MB. BINGE EATING DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[14]',
          },
          {
            id: 27,
            name: 'KID-H. SPECIFIC PHOBIA',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[42,43]',
          },
          {
            id: 33,
            name: 'KID-N. ATTENTION – DEFICIT / HYPERACTIVITY DISORDER (KID)',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[49]',
          },
          {
            id: 17,
            name: 'ADULT-B. SUICIDALITY',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[18,19,20,21]',
          },
          {
            id: 18,
            name: 'KID-A. MAJOR DEPRESSIVE EPISODE',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[22]',
          },
          {
            id: 28,
            name: 'KID-I. OBSESSIVE-COMPULSIVE DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[44]',
          },
          {
            id: 36,
            name: 'KID-Q. PSYCHOTIC DISORDERS',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[52]',
          },
          {
            id: 41,
            name: 'KID-U. GENERALIZED ANXIETY DISORDER',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[57]',
          },
          {
            id: 38,
            name: 'KID-S. BULIMIA NERVOSA',
            language: 'uni',
            languages: 'uni',
            status: 'active',
            modules: '[54]',
          },
        ],
      })
    );
  }),
  rest.post<any>('/v1/monitor/request-monitor', (req, res, ctx) => {
    const { monitorTestId } = req.body;

    // Module B
    if (monitorTestId === 1) {
      return res(
        ctx.json({
          monitorId: 17,
          monitorMode: 'C',
          monitorTestId: 5656,
          nViewPatientId: '160438894136',
          extPatientId: 'ALL',
          language: 'en',
          monitorStatus: 'inProgress',
          monitor: {
            modules: [
              {
                clientDisplayCondition: [],
                sequenceOrders: [
                  {
                    sequenceOrder: 1,
                    itemType: 'question',
                    questionId: 184,
                    question: {
                      possibleAnswers: [''],
                      id: 184,
                      title: '1.',
                      subTitle: '1.',
                      questionText:
                        'did you have any accident?<br />(this includes taking too much of your medication accidentally).',
                      clinicianHelpText: null,
                      questionAnswerType: 'binary',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [],
                    },
                  },
                ],
                id: 18,
                prompt: 'Since the last evaluation or monitor,',
              },
              {
                clientDisplayCondition: [
                  {
                    dependOnModuleId: 18,
                    questionId: 184,
                    questionAnswerValue: 'YES',
                    questionAnswerComparisonType: 'equal',
                  },
                ],
                sequenceOrders: [
                  {
                    sequenceOrder: 1,
                    itemType: 'question',
                    questionId: 185,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 185,
                      title: '1a.',
                      subTitle: '1a.',
                      questionText:
                        'how seriously did you plan or intend to hurt yourself in any accident, either by not avoiding a risk or by causing the accident on purpose?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [],
                    },
                  },
                ],
                id: 19,
                prompt: '',
              },
              {
                clientDisplayCondition: [
                  {
                    dependOnModuleId: 19,
                    questionId: 185,
                    questionAnswerValue: '0',
                    questionAnswerComparisonType: 'greaterthan',
                  },
                ],
                sequenceOrders: [
                  {
                    sequenceOrder: 1,
                    itemType: 'question',
                    questionId: 186,
                    question: {
                      possibleAnswers: [''],
                      id: 186,
                      title: '1b.',
                      subTitle: '1b.',
                      questionText: 'Did you intend to die as a result of any accident?',
                      clinicianHelpText: null,
                      questionAnswerType: 'binary',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [],
                    },
                  },
                ],
                id: 20,
                prompt: '',
              },
              {
                clientDisplayCondition: [],
                sequenceOrders: [
                  {
                    sequenceOrder: 1,
                    itemType: 'question',
                    questionId: 187,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 187,
                      title: '2.',
                      subTitle: '2.',
                      questionText:
                        'think (even momentarily) that you would be better off dead, need to be dead or wish you were dead?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 1,
                          title: 'B4.2',
                          subTitle: 'B4.2',
                          questionText: 'How many times?',
                          clinicianHelpText: '',
                          questionAnswerType: 'number',
                          isClinicianOnly: false,
                          isPatientOnly: false,
                          isRequired: true,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: [''],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 2,
                    itemType: 'question',
                    questionId: 188,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 188,
                      title: '3.',
                      subTitle: '3.',
                      questionText:
                        'think (even momentarily) about harming or hurting or injuring yourself<br />- with at least some intent or awareness that you might die as a result<br />- or think about suicide (killing yourself)?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 2,
                          title: 'B4.3',
                          subTitle: 'B4.3',
                          questionText: 'How many times?',
                          clinicianHelpText: '',
                          questionAnswerType: 'number',
                          isClinicianOnly: false,
                          isPatientOnly: false,
                          isRequired: true,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: [''],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 3,
                    itemType: 'question',
                    questionId: 189,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 189,
                      title: '4.',
                      subTitle: '4.',
                      questionText:
                        'have a voice or voices telling you to kill yourself or have dreams with any suicidal content?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 3,
                          title: 'B4.4',
                          subTitle: 'B4.4',
                          questionText: 'mark either or both:',
                          clinicianHelpText: '',
                          questionAnswerType: 'multiOptions',
                          isClinicianOnly: false,
                          isPatientOnly: false,
                          isRequired: true,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: ['a voice or voices, a dream'],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 4,
                    itemType: 'question',
                    questionId: 190,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 190,
                      title: '5.',
                      subTitle: '5.',
                      questionText: 'have any suicide method in mind (i.e. how)?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 4,
                          title: 'B4.5',
                          subTitle: 'B4.5',
                          questionText: 'Ask for details:',
                          clinicianHelpText: '',
                          questionAnswerType: 'text',
                          isClinicianOnly: true,
                          isPatientOnly: false,
                          isRequired: false,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: [''],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 5,
                    itemType: 'question',
                    questionId: 191,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 191,
                      title: '6.',
                      subTitle: '6.',
                      questionText: 'have any suicide means in mind (i.e. with what)?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 5,
                          title: 'B4.6',
                          subTitle: 'B4.6',
                          questionText: 'Ask for details:',
                          clinicianHelpText: '',
                          questionAnswerType: 'text',
                          isClinicianOnly: true,
                          isPatientOnly: false,
                          isRequired: false,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: [''],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 6,
                    itemType: 'question',
                    questionId: 192,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 192,
                      title: '7.',
                      subTitle: '7.',
                      questionText:
                        'have any place in mind to attempt suicide (i.e. where)?<b> *</b>',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 6,
                          title: 'B4.7',
                          subTitle: 'B4.7',
                          questionText: 'Ask for details:',
                          clinicianHelpText: '',
                          questionAnswerType: 'text',
                          isClinicianOnly: true,
                          isPatientOnly: false,
                          isRequired: false,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: [''],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 7,
                    itemType: 'question',
                    questionId: 193,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 193,
                      title: '8.',
                      subTitle: '8.',
                      questionText:
                        'have any date/timeframe in mind to attempt suicide (i.e. when)?<b> *</b>',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 7,
                          title: 'B4.8',
                          subTitle: 'B4.8',
                          questionText: 'Ask for details:',
                          clinicianHelpText: '',
                          questionAnswerType: 'text',
                          isClinicianOnly: true,
                          isPatientOnly: false,
                          isRequired: false,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: [''],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 8,
                    itemType: 'question',
                    questionId: 194,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 194,
                      title: '9.',
                      subTitle: '9.',
                      questionText:
                        'think about any task you would like to complete before trying to kill yourself (e.g. writing a suicide note)?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [],
                    },
                  },
                  {
                    sequenceOrder: 9,
                    itemType: 'question',
                    questionId: 195,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 195,
                      title: '10.',
                      subTitle: '10.',
                      questionText: 'intend to act on thoughts of killing yourself?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 8,
                          title: 'B4.10',
                          subTitle: 'B4.10',
                          questionText: 'mark either or both:',
                          clinicianHelpText: '',
                          questionAnswerType: 'multiOptions',
                          isClinicianOnly: false,
                          isPatientOnly: false,
                          isRequired: true,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: ['at the time, at some time in the future'],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 10,
                    itemType: 'question',
                    questionId: 196,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 196,
                      title: '11.',
                      subTitle: '11.',
                      questionText: 'intend to die as a result of a suicidal act?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 9,
                          title: 'B4.11',
                          subTitle: 'B4.11',
                          questionText: 'mark either or both:',
                          clinicianHelpText: '',
                          questionAnswerType: 'multiOptions',
                          isClinicianOnly: false,
                          isPatientOnly: false,
                          isRequired: true,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: ['at the time, at some time in the future'],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 11,
                    itemType: 'question',
                    questionId: 197,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 197,
                      title: '12.',
                      subTitle: '12.',
                      questionText:
                        'feel the need or impulse to kill yourself or to plan to kill yourself sooner rather than later?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 10,
                          title: 'B4.12.1',
                          subTitle: 'B4.12.1',
                          questionText: 'mark either or both:',
                          clinicianHelpText: '',
                          questionAnswerType: 'multiOptions',
                          isClinicianOnly: false,
                          isPatientOnly: false,
                          isRequired: true,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: ['to kill yourself, to plan to kill yourself'],
                        },
                        {
                          id: 11,
                          title: 'B4.12.2',
                          subTitle: 'B4.12.2',
                          questionText: 'mark either or both:',
                          clinicianHelpText: '',
                          questionAnswerType: 'multiOptions',
                          isClinicianOnly: false,
                          isPatientOnly: false,
                          isRequired: true,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: ['provoked, largely unprovoked'],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 12,
                    itemType: 'question',
                    questionId: 198,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 198,
                      title: '13.',
                      subTitle: '13.',
                      questionText:
                        'take active steps to prepare for a suicide attempt in which you expected or intended to die (include anything done or purposely not done that put you closer to making a suicide attempt)?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [],
                    },
                  },
                  {
                    sequenceOrder: 13,
                    itemType: 'question',
                    questionId: 199,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 199,
                      title: '14.',
                      subTitle: '14.',
                      questionText:
                        'injure yourself on purpose without intending to kill yourself?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 12,
                          title: 'B4.14',
                          subTitle: 'B4.14',
                          questionText: 'How many times?',
                          clinicianHelpText: '',
                          questionAnswerType: 'number',
                          isClinicianOnly: false,
                          isPatientOnly: false,
                          isRequired: true,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: [''],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 14,
                    itemType: 'question',
                    questionId: 200,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 200,
                      title: '15.',
                      subTitle: '15.',
                      questionText: 'attempt suicide (try to kill yourself)?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [
                        {
                          id: 13,
                          title: 'B4.15',
                          subTitle: 'B4.15',
                          questionText: 'How many times?',
                          clinicianHelpText: '',
                          questionAnswerType: 'number',
                          isClinicianOnly: false,
                          isPatientOnly: false,
                          isRequired: true,
                          shouldFillWithTimeframe: false,
                          possibleAnswers: [''],
                        },
                      ],
                    },
                  },
                  {
                    sequenceOrder: 20,
                    itemType: 'question',
                    questionId: 205,
                    question: {
                      possibleAnswers: [''],
                      id: 205,
                      title: '*',
                      subTitle: '*',
                      questionText:
                        '<b>* Note: Items 7 & 8 above (“plan for suicide”) means not going beyond ideas or talking about a plan for suicide. If actual behaviors occurred, the event should not be coded on item 7 or 8, but as “preparatory behavior” (item 13). Both events can occur separately over the same timeframe.</b>',
                      clinicianHelpText: null,
                      questionAnswerType: 'infoNoAnswer',
                      isClinicianOnly: true,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [],
                    },
                  },
                ],
                id: 21,
                prompt: 'Since the last evaluation or monitor, how seriously did you:',
              },
            ],
            name: 'ADULT-B. SUICIDALITY',
          },
        })
      );
    }

    // KID-H. SPECIFIC PHOBIA
    if (monitorTestId === 2) {
      return res(
        ctx.json({
          monitorId: 27,
          monitorMode: 'C',
          monitorTestId: 5776,
          nViewPatientId: '160438894136',
          extPatientId: 'ALL',
          language: 'en',
          monitorStatus: 'inProgress',
          monitor: {
            modules: [
              {
                clientDisplayCondition: [],
                sequenceOrders: [
                  {
                    sequenceOrder: 1,
                    itemType: 'question',
                    questionId: 343,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 343,
                      title: '1.',
                      subTitle: '1.',
                      questionText:
                        'feel really afraid about something (like snakes or bugs, dogs or other animals, high places, flying, storms, the dark, or seeing blood or needles)?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [],
                    },
                  },
                  {
                    sequenceOrder: 2,
                    itemType: 'question',
                    questionId: 344,
                    question: {
                      possibleAnswers: [''],
                      id: 344,
                      title: '1a.',
                      subTitle: '1a.',
                      questionText: 'list any specific phobia(s):',
                      clinicianHelpText:
                        'CLINICIAN: MAKE SURE THIS PHOBIA IS NOT BETTER EXPLAINED BY A FEAR, ANXIETY OR AVOIDANCE ASSOCIATED WITH PANIC DISORDER, AGORAPHOBIA, SEPARATION ANXIETY, OCD, PTSD, OR SOCIAL ANXIETY DISORDER.',
                      questionAnswerType: 'text',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [],
                    },
                  },
                ],
                id: 42,
                prompt: 'Since your last evaluation or monitor, how much did you:',
              },
              {
                clientDisplayCondition: [],
                sequenceOrders: [
                  {
                    sequenceOrder: 1,
                    itemType: 'question',
                    questionId: 345,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 345,
                      title: '2.',
                      subTitle: '2.',
                      questionText: 'feel afraid immediately when you were near or around %s?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: 344,
                      subQuestions: [],
                    },
                  },
                  {
                    sequenceOrder: 2,
                    itemType: 'question',
                    questionId: 346,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 346,
                      title: '3.',
                      subTitle: '3.',
                      questionText: 'feel more afraid of %s than other kids your age?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: 344,
                      subQuestions: [],
                    },
                  },
                  {
                    sequenceOrder: 3,
                    itemType: 'question',
                    questionId: 347,
                    question: {
                      possibleAnswers: ['0', '1', '2', '3', '4'],
                      possibleAnswersText: [
                        'Not at all',
                        'A little',
                        'Moderately',
                        'Very',
                        'Extremely',
                      ],
                      id: 347,
                      title: '4.',
                      subTitle: '4.',
                      questionText:
                        'have problems at home, at school, or with friends because of this fear?',
                      clinicianHelpText: null,
                      questionAnswerType: 'questionnaire',
                      isClinicianOnly: false,
                      isPatientOnly: false,
                      isRequired: true,
                      shouldFillWithTimeframe: false,
                      relatedQuestionIdForAnswerValues: null,
                      subQuestions: [],
                    },
                  },
                ],
                id: 43,
                prompt: 'Since your last evaluation or monitor, how much did you:',
              },
            ],
            name: 'KID-H. SPECIFIC PHOBIA',
          },
        })
      );
    }

    return res(ctx.status(404, 'Not found'));
  }),
];

export default process.env.REACT_APP_MONITOR_MOCK_DISABLED === 'true' ? [] : mocks;
