import { forwardRef } from 'react';
import Container from '@mui/material/Container';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { BaseProps } from '../../types/PageProps';
import { roundedBoxStyle } from '../utils/commonStyles';

interface Props extends BaseProps {
  sx?: SxProps<Theme>;
}

const RoundedContainer = forwardRef<HTMLDivElement, Props>(({ children, sx: styles }, ref) => {
  return (
    <Container ref={ref} maxWidth="xl" sx={(theme) => ({ ...roundedBoxStyle(theme), ...styles })}>
      {children}
    </Container>
  );
});
export default RoundedContainer;
