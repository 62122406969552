import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useActivateOrDeactivateUserMutation,
  useGetUserListQuery,
} from '../../app/services/account';
import { useResetUserPasswordMutation } from '../../app/services/auth';
import { useToast } from '../../app/toast';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import DeactivatePatientIcon from '../../common/components/icons/DeactivatePatientIcon';
import EditUserIcon from '../../common/components/icons/EditInterviewIcon';
import ReactivatePatientIcon from '../../common/components/icons/ReactivatePatientIcon';
import ResetPasswordIcon from '../../common/components/icons/ResetPasswordIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { CallActionType } from '../../common/utils/callActionType';
import { Role } from '../../common/utils/loginRole';
import { getPageTitle } from '../../common/utils/pageUtils';
import { canEditUser } from '../../common/utils/permissionUtils';
import { SortDirs, SortFields } from '../../common/utils/sortInfo';
import { UserStatus, UserTabStatus } from '../../common/utils/userListUtils';
import { PageProps } from '../../types/PageProps';
import { PopupContent } from '../../types/PopupType';
import { UserListItem } from '../../types/UserTypes';
import { selectUser } from '../auth/authSlice';
import ContextMenu from '../patient/ContextMenu';
import PatientSortMenu from '../patient/PatientSortMenu';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledTab = styled(Tab)(() => ({
  width: '50%',
}));

const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.xPalette.white,
  flexBasis: '100%',
  minWidth: '240px',
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'unset',
    width: '74%',
    minWidth: 'unset',
  },
}));

const StyledAddUserBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: 0,
  width: '40%',
  minWidth: '140px',
  ml: 1,
  [theme.breakpoints.down('sm')]: {
    width: '96%',
    height: '56px',
    ml: 0,
  },
}));

const StyledAddWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 0px',
  width: '100%',
  padding: '0px 8px',
  columnGap: '8px',
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    width: 'auto',
    flexBasis: '40%',
    maxWidth: '1200px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',
  },
}));

const defaultPage = 1;

export default function UserListPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const toast = useToast();

  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [searchParams] = useSearchParams();
  const [displayUserData, setDisplayUserData] = useState<UserListItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [navBackUrl, setNavBackUrl] = useState<string>('/dashboard/users');
  const [userIdAction, setUserIdAction] = useState<number>(1);
  const [callAction, setCallAction] = useState<string>('');

  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);
  const currentUser = useAppSelector(selectUser);

  const tableHeaders: string[] = Object.values(
    t('user.manageUsers.tableHeader', {
      returnObjects: true,
    })
  );

  const deactivateUserContentPopup = {
    title: t('user.manageUsers.deactivateUser.title'),
    description: t('user.manageUsers.deactivateUser.description'),
    btnOk: t('user.manageUsers.deactivateUser.btnOk'),
    btnClose: t('user.manageUsers.deactivateUser.btnClose'),
    toastMessage: t('user.manageUsers.deactivateUser.toastMessage'),
  } as PopupContent;

  const reactivateUserContentPopup = {
    title: t('user.manageUsers.reactivateUser.title'),
    description: t('user.manageUsers.reactivateUser.description'),
    btnOk: t('user.manageUsers.reactivateUser.btnOk'),
    btnClose: t('user.manageUsers.reactivateUser.btnClose'),
    toastMessage: t('user.manageUsers.reactivateUser.toastMessage'),
  } as PopupContent;

  const resetUserPasswordPopup = {
    title: t('user.manageUsers.resetUserPassword.title'),
    description: t('user.manageUsers.resetUserPassword.description'),
    btnOk: t('user.manageUsers.resetUserPassword.btnOk'),
    btnClose: t('user.manageUsers.resetUserPassword.btnClose'),
    toastMessage: t('user.manageUsers.resetUserPassword.toastMessage'),
  } as PopupContent;

  const [activateOrDeactivateUser] = useActivateOrDeactivateUserMutation();
  const [resetUserPassword] = useResetUserPasswordMutation();

  const searchTextboxRef = useRef<HTMLInputElement>(null);

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
    search: searchParams.get('search') || '',
    status: searchParams.get('status') || UserStatus.ACTIVE,
    sortField: searchParams.get('sort') || SortFields.Name,
    sortDir: searchParams.get('dir') || SortDirs.Asc,
  };

  const currentPage = searchOptions.page;
  const tableRef = useRef<HTMLDivElement>(null);

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUserList,
  } = useGetUserListQuery({
    search: searchOptions.search,
    page: currentPage,
    pageSize: pageSize,
    status: searchOptions.status,
    sort: `${searchOptions.sortField} ${searchOptions.sortDir}`,
  });

  const StyledSortIconBox = styled(Box)(({ theme }) => ({
    display: 'inline',
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
  }));

  const showDeactivateUserPopup = async (userId: number) => {
    dispatch(setContentPopup({ content: deactivateUserContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setUserIdAction(userId);
    handleClose();
    setCallAction(CallActionType.Deactivate);
  };

  const showReactivateUserPopup = async (userId: number) => {
    dispatch(setContentPopup({ content: reactivateUserContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setUserIdAction(userId);
    handleClose();
    setCallAction(CallActionType.Reactivate);
  };

  const showResetUserPasswordPopup = async (userId: number) => {
    dispatch(setContentPopup({ content: resetUserPasswordPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setUserIdAction(userId);
    handleClose();
    setCallAction(CallActionType.ResetUserPassword);
  };

  useEffect(() => {
    if (searchOptions.status === UserStatus.DEACTIVATED) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [searchOptions.status]);

  useEffect(() => {
    if (userData) {
      setDisplayUserData(userData.users);
      setTotalPages(Math.ceil(userData.totalItems / pageSize));
    }
  }, [dispatch, pageSize, userData]);

  const updateUrl = (
    page: number,
    tabIndex: number,
    search: string | undefined,
    sort: string,
    dir: string
  ) => {
    const updatedUrl = `/dashboard/users?page=${page}&search=${encodeURIComponent(
      search || ''
    )}&status=${UserTabStatus[tabIndex]}&sort=${sort}&dir=${dir}`;
    navigate(updatedUrl);
    setNavBackUrl(updatedUrl);
  };

  const handlePageChange = (value: number) => {
    updateUrl(
      value,
      tabIndex,
      searchOptions.search,
      searchOptions.sortField,
      searchOptions.sortDir
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
    updateUrl(
      defaultPage,
      newIndex,
      searchTextboxRef.current?.value,
      searchOptions.sortField,
      searchOptions.sortDir
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    const searchInput = searchTextboxRef.current?.value || '';
    updateUrl(defaultPage, tabIndex, searchInput, searchOptions.sortField, searchOptions.sortDir);
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleMenuSorting = (sortField: string, sortDir: string) => {
    updateUrl(defaultPage, tabIndex, searchOptions.search, sortField, sortDir);
  };

  const onHeaderClicked = (header?: string) => {
    if (header) {
      const sortHeader = header === 'site(s)' ? header.slice(0, 4) : header;
      updateUrl(
        defaultPage,
        tabIndex,
        searchOptions.search,
        sortHeader,
        sortHeader !== searchOptions.sortField || searchOptions.sortDir === SortDirs.Desc
          ? SortDirs.Asc
          : SortDirs.Desc
      );
    }
  };

  const handleActivateUser = async (successCallback?: () => void) => {
    try {
      await activateOrDeactivateUser({
        editUserId: userIdAction,
        status: UserStatus.ACTIVE,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('user.manageUsers.reactivateUser.success'), 'success');
      refetchUserList();
      updateUrl(
        defaultPage,
        tabIndex,
        searchOptions.search,
        searchOptions.sortField,
        searchOptions.sortDir
      );
    } catch (e) {
      toast.publish(t('user.manageUsers.reactivateUser.fail'), 'error');
    }
  };

  const handleDeactivateUser = async (successCallback?: () => void) => {
    try {
      await activateOrDeactivateUser({
        editUserId: userIdAction,
        status: UserStatus.DEACTIVATED,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('user.manageUsers.deactivateUser.success'), 'success');
      refetchUserList();
      updateUrl(
        defaultPage,
        tabIndex,
        searchOptions.search,
        searchOptions.sortField,
        searchOptions.sortDir
      );
    } catch (e) {
      toast.publish(t('user.manageUsers.deactivateUser.fail'), 'error');
    }
  };

  const handleResetUserPassword = async (successCallback?: () => void) => {
    try {
      await resetUserPassword({ userId: userIdAction }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('user.manageUsers.resetUserPassword.success'), 'success');
      refetchUserList();
    } catch (e) {
      toast.publish(t('user.manageUsers.resetUserPassword.toastMessageFail'), 'error');
    }
  };

  const renderContextMenu = (item: UserListItem) => {
    const commonCondition = item.status !== UserStatus.DEACTIVATED;
    const menuItems = [
      {
        isDisplayed: commonCondition && canEditUser(item.userRoleId, currentUser.roleId),
        label: t('user.manageUsers.menuContext.editUser'),
        icon: <EditUserIcon />,
        onClick: () => {
          navigate(`/dashboard/users/edit-user/${item.userId}`, { state: { navBackUrl } });
        },
      },
      {
        isDisplayed: commonCondition && canEditUser(item.userRoleId, currentUser.roleId),
        label: t('user.manageUsers.menuContext.resetPassword'),
        icon: <ResetPasswordIcon />,
        onClick: () => showResetUserPasswordPopup(item.userId),
      },
      {
        isDisplayed: canEditUser(item.userRoleId, currentUser.roleId),
        label: commonCondition
          ? t('user.manageUsers.menuContext.deactivateUser')
          : t('user.manageUsers.menuContext.activateUser'),
        icon: commonCondition ? <DeactivatePatientIcon /> : <ReactivatePatientIcon />,
        onClick: () =>
          commonCondition
            ? showDeactivateUserPopup(item.userId)
            : showReactivateUserPopup(item.userId),
      },
    ];

    const checkShowContextMenu = menuItems.some((item) => item.isDisplayed);
    return checkShowContextMenu ? (
      <ContextMenu
        id={`user-${item.userId}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    ) : (
      <></>
    );
  };

  const renderDesktopData = (data: UserListItem[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders
                .filter((header) => {
                  if (currentUser.roleId !== Role.SuperAdmin) {
                    if (header === t('user.manageUsers.tableHeader.account')) {
                      return false;
                    }
                  }
                  return true;
                })
                .map((header, index) => {
                  const eventHeaders = ['account', 'name', 'site(s)'];
                  const checkSortEvent =
                    searchOptions.sortDir === SortDirs.Asc &&
                    (searchOptions.sortField === header.toLowerCase() ||
                      searchOptions.sortField === header.slice(0, 4).toLowerCase());

                  const sortIcon = eventHeaders.includes(header.toLowerCase()) ? (
                    <StyledSortIconBox>
                      {checkSortEvent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </StyledSortIconBox>
                  ) : (
                    <></>
                  );
                  return (
                    <StyledTableCell
                      key={`${header} ${index}`}
                      onClick={
                        eventHeaders.includes(header.toLowerCase())
                          ? () => onHeaderClicked(header.toLowerCase())
                          : undefined
                      }
                    >
                      {header} {sortIcon}
                    </StyledTableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: UserListItem) => {
              const commonCondition = item.status !== UserStatus.DEACTIVATED;
              return (
                <TableRow key={item.userId}>
                  <StyledTableCell sx={{ maxWidth: '500px', minWidth: '150px' }}>
                    {commonCondition && canEditUser(item.userRoleId, currentUser.roleId) ? (
                      <Link
                        sx={{ fontSize: '1em', textAlign: 'left' }}
                        component="button"
                        onClick={() => {
                          navigate(`/dashboard/users/edit-user/${item.userId}`, {
                            state: { navBackUrl },
                          });
                        }}
                      >
                        {`${item.firstName} ${item.lastName}`}
                      </Link>
                    ) : (
                      `${item.firstName} ${item.lastName}`
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ wordBreak: 'break-all', minWidth: '300px' }}>
                    {item.email}
                  </StyledTableCell>
                  {currentUser.roleId === Role.SuperAdmin && (
                    <StyledTableCell sx={{ minWidth: '190px' }}>{item.account}</StyledTableCell>
                  )}
                  <StyledTableCell sx={{ wordBreak: 'break-word', minWidth: '160px' }}>
                    {item.availableSites && item.availableSites?.length > 0
                      ? item.availableSites?.join(', ')
                      : item.site}
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>{item.userRole}</StyledTableCell>
                  <StyledTableCell align="center">{renderContextMenu(item)}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: UserListItem[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: UserListItem) => {
          const commonCondition = item.status !== UserStatus.DEACTIVATED;
          const accountItem = [
            <>{t('user.manageUsers.tableHeader.account')}</>,
            <Box sx={{ wordBreak: 'break-word' }}>{item.account}</Box>,
          ];
          const listItems = [
            [
              <>{t('user.manageUsers.tableHeader.email')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.email}</Box>,
            ],
            [
              <>{t('user.manageUsers.tableHeader.site')}</>,
              <>
                {item.availableSites && item.availableSites?.length > 0
                  ? item.availableSites?.join(', ')
                  : item.site}
              </>,
            ],
            [<>{t('user.manageUsers.tableHeader.userRole')}</>, <>{item.userRole}</>],
          ];

          if (currentUser.roleId === Role.SuperAdmin) {
            listItems.splice(1, 0, accountItem);
          }
          return {
            leftHeader:
              commonCondition && canEditUser(item.userRoleId, currentUser.roleId) ? (
                <Link
                  sx={{ fontSize: '20px', textAlign: 'left', fontWeight: '600' }}
                  component="button"
                  onClick={() => {
                    navigate(`/dashboard/users/edit-user/${item.userId}`, {
                      state: { navBackUrl },
                    });
                  }}
                >
                  {`${item.firstName} ${item.lastName}`}
                </Link>
              ) : (
                <Typography variant="body1" fontWeight="bold" fontSize={20}>
                  {`${item.firstName} ${item.lastName}`}
                </Typography>
              ),
            rightHeader: renderContextMenu(item),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  const isLoading = isLoadingUser;

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('user.manageUsers.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={!isDesktop}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 0 : 2 }}
            headerText={t('user.manageUsers.title')}
            backUrl={'/dashboard'}
          />

          <StyledAddWrapper>
            <StyledSearchBox
              name="user-search-field"
              inputRef={searchTextboxRef}
              onKeyUp={handleSearchKeyUp}
              defaultValue={searchOptions.search}
              placeholder={t('user.manageUsers.searchUser')}
              endAdornment={
                <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              }
            />
            {!isDesktop && (
              <PatientSortMenu
                sx={{
                  width: '20%',
                  flexShrink: 0,
                  borderRadius: '8px',
                  minWidth: 'unset',
                }}
                sortField={searchOptions.sortField}
                sortDir={searchOptions.sortDir}
                onSorted={handleMenuSorting}
                isUser={true}
              />
            )}
            <StyledAddUserBox
              type="button"
              fullWidth
              variant="contained"
              onClick={() => {
                navigate('/dashboard/users/add-user', { state: { navBackUrl } });
              }}
            >
              {t('user.manageUsers.addUser')}
            </StyledAddUserBox>
            <StyledAddUserBox
              type="button"
              fullWidth
              variant="contained"
              onClick={() => {
                navigate('/dashboard/users/import-user', { state: { navBackUrl } });
              }}
            >
              {t('user.manageUsers.importUser')}
            </StyledAddUserBox>
          </StyledAddWrapper>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={tableRef}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, mx: 2 }}>
              <StyledTabs value={tabIndex} onChange={handleTabChange} aria-label="users-list-tabs">
                <StyledTab
                  label={t('user.manageUsers.tableTabs.activeTab')}
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
                <StyledTab
                  label={t('user.manageUsers.tableTabs.deactivatedTab')}
                  id="tab-2"
                  aria-controls="tabpanel-2"
                />
              </StyledTabs>
            </Box>
            {displayUserData.length === 0 ? (
              <RoundedContainer sx={{ mt: 2, p: 2 }}>
                <Typography>{t('user.manageUsers.error.usersNotFound')}</Typography>
              </RoundedContainer>
            ) : (
              <>
                {isDesktop ? renderDesktopData(displayUserData) : renderMobileData(displayUserData)}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 4,
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    background: theme.xPalette.white,
                    mt: 0,
                    [theme.breakpoints.down('sm')]: {
                      mt: 1,
                      py: 2,
                    },
                  }}
                >
                  <CustomPagination
                    changePage={(e, value) => handlePageChange(value)}
                    currentPage={searchOptions.page}
                    pages={totalPages}
                    pageRef={tableRef}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={
            callAction === CallActionType.Deactivate
              ? handleDeactivateUser
              : callAction === CallActionType.Reactivate
              ? handleActivateUser
              : handleResetUserPassword
          }
        />
      </Container>
    </DashboardLayout>
  );
}
