import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import { useDownloadTreatmentPlanReportQuery } from '../../../app/services/treatment-plan/treatmentPlanReport';
import { GlobalLoading } from '../../../common/components/GlobalLoading';
import ErrorPage from '../../error/ErrorPage';

export default function ViewTreatmentPlanReport() {
  const { t } = useTranslation();
  const { treatmentPlanId } = useParams();
  const [iframeUrl, setIframeUrl] = useState<string>('');

  const { data, isLoading, error } = useDownloadTreatmentPlanReportQuery(
    treatmentPlanId
      ? {
          treatmentPlanId: Number(treatmentPlanId),
        }
      : skipToken
  );

  useEffect(() => {
    if (data) {
      setIframeUrl(data);
    }

    return () => {
      window.URL.revokeObjectURL(iframeUrl);
    };
  }, [data, iframeUrl]);

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <Box>
      {isLoading ? (
        <GlobalLoading
          appBarHeight={0}
          loadingText={t('treatmentPlans.report.generatingTreatmentPlanReport')}
        />
      ) : (
        <iframe
          title={t('treatmentPlans.report.title')}
          src={iframeUrl}
          style={{ height: '100vh', width: '100%' }}
        ></iframe>
      )}
    </Box>
  );
}
