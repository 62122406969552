import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { convertCopyRight, parseLegal } from '../../../common/utils/reportUtils';
import { AssessmentReport, ModuleQuestions } from '../../../types/ReportType';
import { InterviewReportType } from '../../interview/interviewUtils';
import ReportCoverPage from '../reportModules/ReportCoverPage';
import ReportModulePage from '../reportModules/ReportModulePage';
import ReportPageBreak from '../reportModules/ReportPageBreak';

export default function YbocsCybocsReportPage({
  logoUrl,
  moduleQuestions,
  assessmentResult: interviewResult,
  patient,
  interviewer,
  assessment: interview,
  questionNotes,
}: AssessmentReport) {
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          pageBreakInside: 'avoid',
          rowGap: 4,
        }}
      >
        <ReportCoverPage
          logo={logoUrl}
          result={interviewResult}
          patient={patient}
          interviewer={interviewer}
          interview={interview}
          isInterview
        />

        {interview?.legal && (
          <Typography
            fontSize={'12px'}
            fontWeight={500}
            dangerouslySetInnerHTML={{
              __html: parseLegal(interview.legal),
            }}
          ></Typography>
        )}
      </Box>

      <ReportPageBreak />
      <Typography variant="body1" textAlign="left">
        {interview?.reportType === InterviewReportType.YBOCS
          ? t('assessmentReport.labels.phenomenaInstructionYBOCS')
          : t('assessmentReport.labels.phenomenaInstruction')}
      </Typography>

      <RoundedContainer sx={{ my: 2, py: 2 }}>
        <Typography variant="h2" fontWeight={600} textAlign={'center'}>
          {t('assessmentReport.reportModule.testResults')}
        </Typography>
        {moduleQuestions?.map((module: ModuleQuestions) => (
          <ReportModulePage
            key={module?.id}
            moduleTitle={module?.title}
            moduleLetter={module?.letter}
            questionAnswers={module?.questionAnswers}
            questionScores={module?.questionScores}
            moduleType={interview?.reportType}
            subModuleType={interview?.subReportType}
            questionNotes={questionNotes}
          />
        ))}
      </RoundedContainer>

      <Typography
        my={2}
        variant="body1"
        textAlign="left"
        dangerouslySetInnerHTML={{
          __html: convertCopyRight(interview?.copyright),
        }}
      ></Typography>
    </>
  );
}
