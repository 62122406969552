import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Copyright from '../../common/components/Copyright';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import ErrorPage from '../error/ErrorPage';
import ManagementChartReportComponent from './ManagementChartReportComponent';

export default function ManagementChartReportForDownloadPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams?.get('token') || null;
  const reportNameActive = searchParams?.get('reportNameActive') || '';

  if (!token) {
    return <ErrorPage statusCode={HttpStatusCode.NotFound} />;
  }
  return (
    <>
      <Box sx={{ height: 'calc(100vh - 60px)' }}>
        <Typography
          sx={{ paddingTop: 2, paddingBottom: 2, textAlign: 'center', fontSize: '24px !important' }}
        >
          {`${reportNameActive?.charAt(0).toUpperCase() + reportNameActive?.slice(1)} ${t(
            'managementReport.viewReport'
          )} - ${dayjs(new Date()).format('MMM DD, YYYY')}`}
        </Typography>
        <ManagementChartReportComponent />
      </Box>
      <Box>
        <Copyright sx={{ fontSize: '14px!important' }} />
      </Box>
    </>
  );
}
