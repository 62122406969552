import { useEffect, useState } from 'react';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  formatDateTimeByPattern,
  validDatePattern,
  validDateTimePattern,
} from '../../../common/utils/dateTimeUtils';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectResetQuestion, setResetQuestion } from '../interviewSlice';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '8px',

  '&:last-of-type': {
    color: theme.xPalette.lightGrey,
  },
}));

export const StyledBox = styled(Box)(() => ({
  width: '100%',
  margin: '16px 0',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
}));

interface DateTimeComponentProps extends Partial<InterviewQuestionProps> {
  pattern?: string;
  dateValue: string | null;
  onChange: (value: any) => void;
}

const matchDateTimeComponentByPattern = ({
  pattern,
  field,
  error,
  onChange,
  dateValue,
}: DateTimeComponentProps): JSX.Element => {
  const value = dateValue && formatDateTimeByPattern({ dateValue, pattern });
  if (pattern && validDatePattern.includes(pattern)) {
    return (
      <DatePicker
        {...field!}
        value={dateValue}
        inputFormat={pattern}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              marginTop: '0.5rem',
              width: '100%',
            }}
            error={error}
            margin="normal"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
          />
        )}
      />
    );
  }

  if (pattern && validDateTimePattern.includes(pattern)) {
    return (
      <DateTimePicker
        {...field!}
        value={value}
        inputFormat={pattern}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              marginTop: '0.5rem',
              width: '100%',
            }}
            error={error}
            margin="normal"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
          />
        )}
      />
    );
  }

  return (
    <DatePicker
      {...field!}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            marginTop: '0.5rem',
            width: '100%',
          }}
          error={error}
          margin="normal"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            readOnly: true,
          }}
        />
      )}
    />
  );
};

export default function DateQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
}: InterviewQuestionProps) {
  const dispatch = useAppDispatch();
  const [dateValue, setDateValue] = useState<string | null>(null);
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);

  const handleDateTimeChange = (value: any) => {
    if (value) {
      const formattedDateTime = formatDateTimeByPattern({
        dateValue: value,
        pattern: question?.textPattern,
      });
      setDateValue(formattedDateTime);
      setValue(field?.name!, value);
    }
  };

  useEffect(() => {
    if (isResetQuestion) {
      setDateValue(null);
      setValue(field?.name!, '');
      dispatch(setResetQuestion({ reset: false }));
    } else if (field?.value) {
      const formattedDateTime = formatDateTimeByPattern({
        dateValue: field?.value,
        pattern: question?.textPattern,
      });
      setDateValue(formattedDateTime);
      setValue(field?.name!, formattedDateTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetQuestion, dateValue, field?.value, setValue]);

  return (
    <Box sx={{ my: 2 }}>
      <FormControl error={error} variant="standard">
        {question.questionPrompt && (
          <StyledTitle
            dangerouslySetInnerHTML={{
              __html: question.questionPrompt,
            }}
          ></StyledTitle>
        )}
        <StyledTitle
          dangerouslySetInnerHTML={{
            __html: question.questionText!,
          }}
        ></StyledTitle>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {matchDateTimeComponentByPattern({
            onChange: handleDateTimeChange,
            pattern: question?.textPattern,
            field,
            error,
            dateValue,
          })}
        </LocalizationProvider>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
