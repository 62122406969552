import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import UsageReportIcon from './icons/UsageReportIcon';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReportType } from '../utils/assessmentType';

interface UsageReportInfoCardProps {
  clinicAccountId: string;
  clinicAccountName: string;
  reportType: string;
  fromDate: string;
  toDate: string;
  total: number;
  totalCompleted: number;
  onDownLoadReport: () => Promise<void>;
};

export default function UsageReportInfoCard({ clinicAccountId, clinicAccountName, reportType, fromDate, toDate, total, totalCompleted, onDownLoadReport }: UsageReportInfoCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <UsageReportIcon />
        <Box sx={{
          ml: 1,
        }}>
          <>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {t('report.usageReport.infoCard.account')}{' '}
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {clinicAccountName ? clinicAccountName + ' (' + clinicAccountId + ')' : ''}
              </Typography>
            </Typography>
            <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
              <Typography component="span" variant="body1" sx={(theme) => ({ color: theme.xPalette.shadeGrey })}>{t(
                'report.usageReport.infoCard.time'
              )}</Typography>
              {' '}<Typography component="span" variant="body1" sx={(theme) => ({ color: theme.xPalette.black })}>{`${fromDate} - ${toDate}`}</Typography>
            </Box>
            <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
              <Typography component="span" variant="body1" sx={(theme) => ({ color: theme.xPalette.shadeGrey })}>{t(
                `report.usageReport.infoCard.${reportType === ReportType.Interview ? 'totalInterviews' : 'totalMonitors'}`
              )}</Typography>
              {' '}<Typography component="span" variant="body1" sx={(theme) => ({ color: theme.xPalette.black })}>{total}</Typography>
            </Box>
            <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
              <Typography component="span" variant="body1" sx={(theme) => ({ color: theme.xPalette.shadeGrey })}>{t(
                `report.usageReport.infoCard.${reportType === ReportType.Interview ? 'interviewsCompleted' : 'monitorsCompleted'}`
              )}</Typography>
              {' '}<Typography component="span" variant="body1" sx={(theme) => ({ color: theme.xPalette.black })}>{totalCompleted}</Typography>
            </Box>
          </>
        </Box>
      </Box>
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{
          width: matches ? '100%' : '30%',
          my: 2,
          py: 2,
          float: 'right',
        }}
        onClick={async () => {
          await onDownLoadReport();
        }}
      >
        {t('report.usageReport.infoCard.downloadReport')}
      </Button>
    </Box>
  );
}
