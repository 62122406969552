export const Role = {
    User: 100,
    Admin: 101,
    SuperAdmin: 1,
    ClinicAdmin: 2,
    SiteAdmin: 3,
    Interviewer: 4,
    MultiSiteAdmin: 5,
    SingleUserProvider: 6,
    MultiUserProvider: 7,
    JobScheduler: 201
};
