import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useGetMfaCodeMutation,
  useResendMfaCodeMutation,
  useVerifyMfaCodeMutation,
} from '../../app/services/auth';
import { useToast } from '../../app/toast';
import SessionExpirationModal from '../../common/components/SessionExpirationModal';
import SubDashboardLayout from '../../common/layouts/SubDashboard';
import checkNullData from '../../common/utils/checkNullData';
import { obfuscateEmail, obfuscatePhoneNo } from '../../common/utils/commonUtils';
import { MfaGetCodeMode, MfaType, MfaUserType } from '../../common/utils/mfa';
import { getPageTitle } from '../../common/utils/pageUtils';
import { saveSessionCookie } from '../../common/utils/storageUtils';
import {
  PhoneNumberRequired,
  RegexValidation,
  validatePhoneNumber,
} from '../../common/utils/validationUtils';
import { MfaVerificationRequest } from '../../types/AuthRequests';
import { OptVerificationResponseError, ResponseError } from '../../types/ResponseError';
import {
  clearCredentials,
  selectMfa,
  selectTokenInfo,
  selectUser,
  setCredentials,
  setGetCode,
  setMfaUserLockedOut,
  setRemainingTime,
} from './authSlice';

const defaultFormValues: MfaVerificationRequest = {
  phoneNumber: '',
  otpCode: '',
};

export default function MfaPage({ onMfaPageEventChange, mfaPatientData }: any) {
  const toast = useToast();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [patientPhoneNumber, setPatientPhoneNumber] = useState<string>('');
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const mfaData = useAppSelector(selectMfa);
  const currentUser = useAppSelector(selectUser);
  const tokenInfo = useAppSelector(selectTokenInfo);
  const [getMfaCode] = useGetMfaCodeMutation();
  const [resendMfaCode] = useResendMfaCodeMutation();
  const [verifyMfaCode] = useVerifyMfaCodeMutation();
  const patientId = mfaPatientData?.patientId || null;
  const patientHasVerified =
    mfaPatientData?.patientId &&
    mfaPatientData?.isShowMfa &&
    mfaPatientData?.mfaVerified &&
    !!mfaPatientData?.phoneNumber;
  const validationSchema = yup
    .object({
      phoneNumber:
        patientHasVerified || !patientId
          ? yup.string().test(
              'validate-mfa-phone',
              t('mfa.error.phoneNumberTooShort', {
                minLength: PhoneNumberRequired.MinLengthPhoneNumber,
              }),
              function (phoneNumber) {
                return validatePhoneNumber(phoneNumber);
              }
            )
          : yup
              .string()
              .required(t('mfa.error.blankPhoneNumber'))
              .test(
                'validate-mfa-phone',
                t('mfa.error.phoneNumberTooShort', {
                  minLength: PhoneNumberRequired.MinLengthPhoneNumber,
                }),
                function (phoneNumber) {
                  return validatePhoneNumber(phoneNumber);
                }
              ),
      otpCode: yup
        .string()
        .required(t('mfa.error.blankOtpCode'))
        .matches(RegexValidation.NumberOnly, t('mfa.error.invalidOtpCode')),
    })
    .required();
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<MfaVerificationRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  // initial data load
  useEffect(() => {
    if (
      (mfaData && Object.keys(mfaData).length > 0) ||
      (mfaPatientData && Object.keys(mfaPatientData).length > 0)
    ) {
      if (!isDataLoaded) {
        setIsDataLoaded(true);
        setValue('phoneNumber', checkNullData(mfaData.phoneNumber || mfaPatientData?.phoneNumber));
      }
    }
  }, [isDataLoaded, setValue, mfaData, mfaPatientData]);

  useEffect(() => {
    const timer = setInterval(
      () => dispatch(setRemainingTime({ remainingTime: mfaData.remainingTime - 1 })),
      1000
    );
    if (mfaData.remainingTime < 0) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [mfaData.remainingTime, dispatch]);

  const isPhone = (mfaData: any) => {
    return mfaData.mfaType === MfaType.Phone;
  };

  const shouldDisplayUnverifiedInfo = (mfaData: any) => {
    return isPhone(mfaData) && !mfaData.mfaVerified && !mfaData.isTokenLimitExceeded;
  };

  const getCode = useCallback(
    async (mfaGetCodeMode: MfaGetCodeMode) => {
      try {
        let param = {
          token: patientId
            ? mfaData.accessToken || mfaPatientData?.accessToken
            : tokenInfo.accessToken,
          phoneNumber: patientHasVerified
            ? mfaData.phoneNumber || mfaPatientData?.phoneNumber
            : getValues('phoneNumber'),
        };

        const extraParam = patientId
          ? {
              ...param,
              patientId,
              type: mfaPatientData?.type,
              testId: mfaPatientData?.testId,
            }
          : {
              ...param,
              userType: MfaUserType.User,
              userId: currentUser?.id,
            };
        const data =
          mfaGetCodeMode === MfaGetCodeMode.GetCode
            ? await getMfaCode(extraParam).unwrap()
            : await resendMfaCode(extraParam).unwrap();

        if (data) {
          setPageErrors([]);
          dispatch(setGetCode());
          dispatch(setRemainingTime({ remainingTime: data.remainingTime || -1 }));
          if (mfaGetCodeMode === MfaGetCodeMode.Resend) {
            toast.publish(t('mfa.forms.resentOtpCode'), 'success');
          }
        }

        if (data && patientId) {
          setPatientPhoneNumber(data.phoneNumber!);
        }
      } catch (e) {
        const responseError = e as ResponseError;
        if (responseError.data?.error) {
          setPageErrors(
            typeof responseError.data.error === 'string'
              ? [responseError.data.error]
              : responseError.data.error
          );
        } else {
          setPageErrors([t('error.unexpectedError')]);
        }
      }
    },
    [
      currentUser?.id,
      dispatch,
      getMfaCode,
      getValues,
      mfaData.accessToken,
      mfaData.phoneNumber,
      mfaPatientData?.accessToken,
      mfaPatientData?.phoneNumber,
      mfaPatientData?.testId,
      mfaPatientData?.type,
      patientHasVerified,
      patientId,
      resendMfaCode,
      t,
      toast,
      tokenInfo.accessToken,
    ]
  );

  useEffect(() => {
    if (patientHasVerified) {
      getCode(MfaGetCodeMode.GetCode);
    }
  }, [getCode, patientHasVerified]);

  const onSubmit = async (data: MfaVerificationRequest) => {
    try {
      const param = {
        token: patientId
          ? mfaData.accessToken || mfaPatientData?.accessToken
          : tokenInfo.accessToken,
        phoneNumber: data.phoneNumber,
        otpCode: data.otpCode,
      };
      const extraParam = patientId
        ? {
            ...param,
            patientId,
            type: mfaPatientData?.type,
            testId: mfaPatientData?.testId,
          }
        : {
            ...param,
            userType: MfaUserType.User,
            userId: currentUser?.id,
          };
      const { user, accessToken, refreshToken } = await verifyMfaCode(extraParam).unwrap();
      setPageErrors([]);

      if (patientId && onMfaPageEventChange) {
        onMfaPageEventChange();
      }

      if (!patientId) {
        dispatch(setCredentials({ user, accessToken, refreshToken, isShowMfa: false }));
        saveSessionCookie();
        navigate('/dashboard', { replace: true });
      }
    } catch (e) {
      const responseError = e as OptVerificationResponseError;
      if (responseError.data?.error) {
        if (responseError.data?.mfaDataFinal?.userLockedOut) {
          dispatch(setMfaUserLockedOut());
        }
        setPageErrors(
          typeof responseError.data.error === 'string'
            ? [responseError.data.error]
            : responseError.data.error
        );
      } else {
        setPageErrors([t('error.unexpectedError')]);
      }
    }
  };

  const signOut = () => {
    dispatch(clearCredentials());
    navigate('/login');
  };

  return (
    <SubDashboardLayout isShowFooter={!!!patientId}>
      <Helmet>
        <title>{getPageTitle(t('mfa.title'))} </title>
      </Helmet>

      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h1">{t('mfa.title')}</Typography>
          {mfaData.userLockedOut ||
          new Date().getTime() < new Date(mfaPatientData?.lockoutExpiration).getTime() ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                color: theme.palette.primary.main,
              }}
            >
              <Box>
                {pageErrors.length > 0 ? (
                  pageErrors.map((error) => (
                    <Alert key={error} severity="error" sx={{ mt: 2, textAlign: 'left' }}>
                      {error || t('login.error.patientLockedOut')}
                    </Alert>
                  ))
                ) : (
                  <Alert
                    key={Object.keys(t('login.error.patientLockedOut'))[0]}
                    severity="error"
                    sx={{ mt: 2, textAlign: 'left' }}
                  >
                    {t('login.error.patientLockedOut')}
                  </Alert>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: theme.palette.primary.main,
                  mt: 1,
                }}
              >
                <ArrowBack></ArrowBack>
                <Link sx={{ fontSize: 16 }} component="button" onClick={() => signOut()}>
                  {t('mfa.forms.goBack')}
                </Link>
              </Box>
            </Box>
          ) : (
            <Box component="form" sx={{ mt: 1, width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
              {shouldDisplayUnverifiedInfo(!patientId ? mfaData : mfaPatientData) ? (
                <>
                  {patientId && (
                    <>
                      <Typography variant="subtitle2" sx={{ mt: 2 }}>
                        {t('mfa.forms.unverifiedMessagePatient1')}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ mt: 2 }}>
                        {t('mfa.forms.unverifiedMessagePatient2')}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ mt: 2 }}>
                        {t('mfa.forms.unverifiedMessagePatient3')}
                      </Typography>
                    </>
                  )}
                  {!patientId && (
                    <>
                      <Typography variant="subtitle2" sx={{ mt: 2 }}>
                        {t('mfa.forms.unverifiedMessage1')}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ mt: 2 }}>
                        {t('mfa.forms.unverifiedMessage2')}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ mt: 2 }}>
                        {t('mfa.forms.unverifiedMessage3')}
                      </Typography>
                    </>
                  )}
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber?.message}
                        margin="normal"
                        fullWidth
                        disabled={mfaData.isOtpSent}
                        label={''}
                        variant="outlined"
                      />
                    )}
                  />
                  {!mfaData.mfaVerified && !mfaData.isOtpSent && !mfaPatientData?.mfaVerified && (
                    <Box textAlign="center" sx={{ mt: 1 }}>
                      {pageErrors.length > 0 &&
                        pageErrors.map((error) => (
                          <Alert key={error} severity="error" sx={{ mt: 2, textAlign: 'left' }}>
                            {error}
                          </Alert>
                        ))}
                      <Button
                        size="large"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3 }}
                        onClick={() => {
                          getCode(MfaGetCodeMode.GetCode);
                        }}
                      >
                        {t('mfa.forms.getCode')}
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <></>
              )}

              {(!isPhone(!patientId ? mfaData : mfaPatientData) ||
                mfaData.mfaVerified ||
                mfaData.isOtpSent ||
                mfaPatientData?.mfaVerified) && (
                <>
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    {isPhone(!patientId ? mfaData : mfaPatientData)
                      ? t('mfa.forms.verifyPhoneMessage', {
                          phoneNumber: obfuscatePhoneNo(mfaData.phoneNumber || patientPhoneNumber),
                        })
                      : t('mfa.forms.verifyEmailMessage', { email: obfuscateEmail(mfaData.email) })}
                  </Typography>
                  <Controller
                    name="otpCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.otpCode}
                        helperText={errors.otpCode?.message}
                        margin="normal"
                        fullWidth
                        label={''}
                        variant="outlined"
                      />
                    )}
                  />
                  <Box textAlign="center">
                    {mfaData.remainingTime > 0 && (
                      <Typography variant="subtitle2">
                        {t('mfa.forms.resendMessage', { seconds: mfaData.remainingTime })}
                      </Typography>
                    )}
                    {mfaData.remainingTime <= 0 && (
                      <Link href="#" onClick={() => getCode(MfaGetCodeMode.Resend)}>
                        <Typography variant="subtitle2">{t('mfa.forms.resendCode')}</Typography>
                      </Link>
                    )}
                  </Box>
                  <Box textAlign="center" sx={{ mt: 1 }}>
                    {pageErrors.length > 0 &&
                      pageErrors.map((error) => (
                        <Alert key={error} severity="error" sx={{ mt: 2, textAlign: 'left' }}>
                          {error}
                        </Alert>
                      ))}
                    <Button type="submit" size="large" fullWidth variant="contained" sx={{ mt: 3 }}>
                      {t('mfa.forms.verify')}
                    </Button>
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: theme.palette.primary.main,
                  mt: 2,
                }}
              >
                <ArrowBack></ArrowBack>
                <Link sx={{ fontSize: 16 }} component="button" onClick={() => signOut()}>
                  {t('mfa.forms.goBack')}
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
      {!patientId && <SessionExpirationModal />}
    </SubDashboardLayout>
  );
}
