import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCreatePatientNoteMutation } from '../../../app/services/patient';
import { useToast } from '../../../app/toast';
import { PatientNoteRequest } from '../../../types/PatientRequest';
import { ResponseError } from '../../../types/ResponseError';
import { ControlledTextField } from '../../auth/ControlledTextField';

interface PatientNoteProps {
  refetchData: () => void;
}

const StyledPatientNote = styled(TextField)(({ theme }) => ({
  margin: '16px 0',
  backgroundColor: theme.xPalette.noteLightGrey,
  borderRadius: '12px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&:hover fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.xPalette.white,
    },
  },
  'p.MuiFormHelperText-root': {
    backgroundColor: theme.xPalette.white,
    margin: 0,
  },
}));

export default function PatientNoteForm({ refetchData }: PatientNoteProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { patientId } = useParams();
  const toast = useToast();
  const textInput = useRef<HTMLInputElement>(null);
  const [createPatientNote] = useCreatePatientNoteMutation();

  // Form setup
  const validationSchema = yup
    .object({
      title: yup.string().required(t('patientNote.blankTitle')),
      body: yup.string().required(t('patientNote.blankBody')),
    })
    .required();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
    reset,
  } = useForm<PatientNoteRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      body: '',
    },
  });

  const onSubmit = async (data: PatientNoteRequest) => {
    try {
      data.patientId = patientId!;
      await createPatientNote(data).unwrap();
      toast.publish(t('patientNote.success'), 'success');
      if (textInput && textInput.current) {
        textInput.current.value = '';
      }
      resetField('title');
      resetField('body');
      setValue('title', '');
      setValue('body', '');
      reset();
      refetchData();
    } catch (e) {
      const responseError = e as ResponseError;
      if (responseError.data?.error) {
        toast.publish(t(responseError.data?.error), 'error');
      }
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        py: 4,
        px: 1,
        display: 'flex',
        flexDirection: 'column',
        columnGap: 2,
      }}
    >
      <Box
        sx={{
          columnGap: 1,
          width: '100%',
        }}
      >
        <ControlledTextField
          name="title"
          control={control}
          fieldError={errors.title}
          label={t('patientNote.title')}
          isDefaultStyle={true}
          isSetVariantFilled={false}
          maxLengthNumber={140}
        />
        <Controller
          name="body"
          control={control}
          render={({ field }) => (
            <StyledPatientNote
              {...field}
              error={!!errors.body}
              helperText={errors.body?.message}
              fullWidth
              multiline={true}
              rows={5}
              label={t('patientNote.body')}
              variant="outlined"
            />
          )}
        />
      </Box>
      <Typography variant="subtitle2" sx={{ mt: 2, color: theme.xPalette.shadeGrey }}>
        {t('patientNote.instructions')}
      </Typography>
      <Box sx={{ mt: 2 }}>
        {!matches && <Divider sx={{ my: 3 }} />}
        <Button
          type="submit"
          variant="contained"
          sx={{
            my: 2,
            py: 2,
            borderRadius: 2,
            width: matches ? '100%' : '20%',
            float: 'right',
          }}
        >
          {t('patientNote.add')}
        </Button>
      </Box>
    </Box>
  );
}
