import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useImportInterviewOrMonitorMutation } from '../../app/services/interview';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import SubmitButton from '../../common/components/SubmitButton';
import DashboardLayout from '../../common/layouts/Dashboard';
import { ImportType } from '../../common/utils/assessmentType';
import { getPageTitle } from '../../common/utils/pageUtils';
import { maximumImportFileUpload } from '../../common/utils/validationUtils';
import { ImportBuilder } from '../../types/ImportBuilderType';
import { PageProps } from '../../types/PageProps';
import { ResponseError } from '../../types/ResponseError';

const defaultFormValues: ImportBuilder = {
  receiveEmail: 'No',
  email: '',
  importType: ImportType.Interview,
  fileData: '',
  fileName: '',
};

const StyleTextField = styled(TextField)(() => ({
  [`&.MuiFormControl-root`]: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

const ReceiveEmailsType = {
  No: 'No',
  Yes: 'Yes',
};

const ReceiveEmails: { [key: string]: string } = {
  No: 'No',
  Yes: 'Yes',
};

const ImportTypes: { [key: string]: string } = {
  Interview: ImportType.Interview,
  // Monitor: ImportType.Monitor,
};

export default function ImportInterviewsPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [sendEmail, setSendEmail] = useState<string>('No');
  const [selectedImportType, setSelectedImportType] = useState<string>(ImportType.Interview);
  const [disabledEmail, setDisabledEmail] = useState<boolean>(true);
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const inputImportInterview = useRef<HTMLInputElement>(null);
  const [importFile, setImportFile] = useState<any>(null);
  const [importFileName, setImportFileName] = useState<string>('');
  const [dataFile, setDataFile] = useState<string>('');
  const toast = useToast();

  const validationSchema = yup
    .object({
      receiveEmail: yup.string(),
      email: yup.string().when('receiveEmail', {
        is: (receiveEmail: string) => {
          return receiveEmail === ReceiveEmailsType.Yes;
        },
        then: yup
          .string()
          .required(t('interviews.importInterviews.error.blankEmail'))
          .email(t('interviews.importInterviews.error.invalidEmail')),
      }),
      importType: yup.string(),
      fileName: yup.string().required(t('interviews.importInterviews.error.blankFile')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<ImportBuilder>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const [importInterviewOrMonitor] = useImportInterviewOrMonitorMutation();

  const onSubmit = async (data: ImportBuilder) => {
    setPageErrors([]);
    let pageErrors: string[] = [];

    if (importFile && importFile.size / (1024 * 1024) > maximumImportFileUpload) {
      pageErrors.push(
        t('interviews.importInterviews.error.importFileLargeThanMaximum', {
          maximumFileUpload: maximumImportFileUpload,
        })
      );
    }
    if (!dataFile) {
      pageErrors.push(t('interviews.importInterviews.error.blankImportFile'));
    }
    if (pageErrors.length) {
      setPageErrors(pageErrors);
      return;
    }
    const submitData = {
      receiveEmail: data.receiveEmail,
      email: data.receiveEmail === ReceiveEmailsType.Yes ? data.email : null,
      importType: data.importType,
      fileName: data.fileName,
      fileData: dataFile,
    } as ImportBuilder;

    try {
      await importInterviewOrMonitor(submitData)
        .unwrap()
        .finally(() => {
          setPageErrors([]);
          if (data.importType === ImportType.Interview) {
            toast.publish(t('interviews.importInterviews.importInterviewSuccess'), 'success');
          }
          if (data.importType === ImportType.Monitor) {
            toast.publish(t('interviews.importInterviews.importMonitorSuccess'), 'success');
          }
        });
    } catch (e) {
      const {
        data: { error, message },
      } = e as ResponseError;
      setPageErrors(error ? error : message ? [message] : []);
    }
  };

  const handleImport = () => {
    inputImportInterview?.current?.click();
  };

  useEffect(() => {
    setPageErrors([]);
    if (importFile) {
      const read = new FileReader();
      read.readAsBinaryString(importFile);
      setImportFileName(importFile.name);
      setValue('fileName', importFile.name);
      if (importFile.type && !importFile.type.includes('json')) {
        setPageErrors([t('interviews.importInterviews.error.invalidFileFormat')]);
        return;
      }
      read.onloadend = () => {
        setDataFile(decodeURIComponent(escape(read.result as string)));
      };
    }
  }, [importFile, t, setValue]);

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('interviews.importInterviews.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <PageHeader
            sx={{ mt: 1, mb: 2 }}
            headerText={t('interviews.importInterviews.title')}
            backUrl={'/dashboard'}
          />
        </Container>
        <RoundedContainer
          sx={{
            p: 3,
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: matches ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
                gridGap: '16px',
              }}
            >
              <Controller
                name="receiveEmail"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    margin="normal"
                    select
                    fullWidth
                    label={t('interviews.importInterviews.form.receiveEmail')}
                    error={!!errors.receiveEmail}
                    helperText={errors.receiveEmail?.message}
                    variant="outlined"
                    value={sendEmail}
                    onChange={(e: any) => {
                      const value = e.target.value;
                      setSendEmail(value);
                      setValue('receiveEmail', value);
                      setPageErrors([]);
                      if (value === ReceiveEmailsType.Yes) {
                        setDisabledEmail(false);
                        setValue('email', '');
                      }
                      if (value === 'No') {
                        setDisabledEmail(true);
                        setValue('email', '');
                      }
                    }}
                  >
                    {Object.keys(ReceiveEmails).map((type: string) => (
                      <MenuItem key={type} value={type}>
                        {ReceiveEmails[type]}
                      </MenuItem>
                    ))}
                  </StyleTextField>
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    margin="normal"
                    fullWidth
                    label={t('interviews.importInterviews.form.email')}
                    variant="outlined"
                    disabled={disabledEmail}
                  />
                )}
              />
              <Controller
                name="importType"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    margin="normal"
                    select
                    fullWidth
                    label={t('interviews.importInterviews.form.importType')}
                    error={!!errors.importType}
                    helperText={errors.importType?.message}
                    variant="outlined"
                    value={selectedImportType}
                    onChange={(e: any) => {
                      const value = e.target.value;
                      setSelectedImportType(value);
                      setValue('importType', value);
                      setImportFileName('');
                      setValue('fileData', '');
                      setValue('fileName', '');
                      setDataFile('');
                      setPageErrors([]);
                    }}
                  >
                    {Object.keys(ImportTypes).map((type: string) => (
                      <MenuItem key={type} value={type}>
                        {ImportTypes[type]}
                      </MenuItem>
                    ))}
                  </StyleTextField>
                )}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: matches ? 'column' : 'row',
                  columnGap: 1,
                  rowGap: 2,
                }}
              >
                <Controller
                  name="fileName"
                  control={control}
                  render={({ field }) => (
                    <StyleTextField
                      {...field}
                      disabled={true}
                      error={!!errors.fileName}
                      helperText={errors.fileName?.message}
                      margin="normal"
                      fullWidth
                      label={t('interviews.importInterviews.form.fileName')}
                      variant="outlined"
                      value={importFileName}
                    />
                  )}
                />
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{
                    width: matches ? '100%' : '50%',
                  }}
                >
                  <input
                    type="file"
                    id="file"
                    ref={inputImportInterview}
                    accept=".json"
                    onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                      const element = event.target as HTMLInputElement;
                      element.value = '';
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event?.target?.files) {
                        setImportFile(event.target.files[0]);
                      }
                    }}
                    style={{ display: 'none' }}
                  />
                  <Box>
                    <Controller
                      name="fileData"
                      control={control}
                      render={() => (
                        <Button
                          type="button"
                          onClick={handleImport}
                          fullWidth
                          variant="contained"
                          sx={{
                            width: '100%',
                            height: '57px',
                            float: 'right',
                          }}
                        >
                          {t('user.manageUsers.importUserForm.selectFile')}
                        </Button>
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {dataFile && (
              <SubmitButton
                content={t('interviews.importInterviews.import')}
                matches={matches}
                pageErrors={pageErrors}
              />
            )}
          </Box>
        </RoundedContainer>
      </Container>
    </DashboardLayout>
  );
}
