import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { default as SignatureCanvas } from 'react-signature-canvas';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../../app/hooks';
import { useGetPatientQuery } from '../../../app/services/patient';
import { useAddTreatmentSignatureMutation } from '../../../app/services/treatment-plan/treatmentSignature';
import theme from '../../../app/theme';
import { useToast } from '../../../app/toast';
import ElectronicSignature from '../../../common/components/ElectronicSignature';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { capitalizeFirstLetter, toTitleCase } from '../../../common/utils/stringUtils';
import { ResponseError } from '../../../types/ResponseError';
import {
  TreatmentSignatureItem,
  TreatmentSignatureProviderType,
  TreatmentSignatureType,
} from '../../../types/treatment-plan/TreatmentSignature';
import { selectUser } from '../../auth/authSlice';
import { selectPatientHasEmail, selectPatientSMSOptIn } from '../../patient/patientSlice';

const StyleRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}));

const StyleErrorForm = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  paddingLeft: '8px',
}));

interface TreatmentSignatureFormProps {
  parentPlanId: number;
  onFormSubmitCallback: (result: boolean, info?: any) => void;
}

export default function TreatmentSignatureForm({
  parentPlanId,
  onFormSubmitCallback,
}: TreatmentSignatureFormProps) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const currentUser = useAppSelector(selectUser);
  const patientHasEmail = useAppSelector(selectPatientHasEmail);
  const patientSMSOptIn = useAppSelector(selectPatientSMSOptIn);
  const sigRef = useRef<SignatureCanvas>(null);
  const params = useParams();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [signatureProviderType, setSignatureProviderType] =
    useState<TreatmentSignatureProviderType>(TreatmentSignatureProviderType.Provider);
  const [signatureType, setSignatureType] = useState<TreatmentSignatureType | ''>('');
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const toast = useToast();

  const { patientId } = params;
  const { data: patientInfo } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const [addSignature] = useAddTreatmentSignatureMutation();

  const { firstName: firstNameUser, lastName: lastNameUser } = currentUser || {};
  const { firstName: firstNamePatient, lastName: lastNamePatient } = patientInfo || {};

  const signatureProviderTypes = [
    {
      id: 'provider',
      name: 'Provider',
      displayName: (firstNameUser || lastNameUser) && `${firstNameUser} ${lastNameUser}`,
    },
    {
      id: 'patient',
      name: 'Patient',
      displayName: `${
        (firstNamePatient || lastNamePatient) && `${firstNamePatient} ${lastNamePatient}`
      }`,
    },
  ];

  const defaultFormValues: TreatmentSignatureItem = {
    signatureProviderType: TreatmentSignatureProviderType.Provider,
    signatureType: '',
    signature: '',
  };

  const signatureTypes = Object.keys(
    t('treatmentPlans.signatureForm.signatureTypes', {
      returnObjects: true,
    })
  );

  const validationSchema = yup
    .object({
      signatureProviderType: yup
        .string()
        .required(t('treatmentPlans.signatureForm.errors.blankSignatureProviderType')),
      signatureType: yup.string().when('signatureProviderType', {
        is: (signatureProviderType: TreatmentSignatureProviderType) =>
          signatureProviderType === TreatmentSignatureProviderType.Patient,
        then: yup.string().required(t('treatmentPlans.signatureForm.errors.blankSignatureType')),
      }),
      signature: yup.string().when(['signatureProviderType', 'signatureType'], {
        is: (
          signatureProviderType: TreatmentSignatureProviderType,
          signatureType: TreatmentSignatureType
        ) =>
          (signatureType === TreatmentSignatureType.Participate &&
            signatureProviderType === TreatmentSignatureProviderType.Patient) ||
          signatureProviderType === TreatmentSignatureProviderType.Provider,
        then: yup.string().required(t('treatmentPlans.signatureForm.errors.blankSignature')),
      }),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
  } = useForm<TreatmentSignatureItem>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const getSignatureName = () => {
    const prefix = getValues('signatureProviderType');
    const name = signatureProviderTypes.find(
      (type) => type.id === getValues('signatureProviderType')
    )?.displayName;
    return `${toTitleCase(prefix)}: ${name}`;
  };

  const getSignatureProviderOptionLabel = (option: any) => (option?.name ? option.name : '');

  const filterSignatureProviderOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });

  const handleSignatureProviderTypeChange = (e: any, values: any) => {
    const value = values?.id;
    clearSignature();
    setSignatureProviderType(value);
    if (value === TreatmentSignatureProviderType.Patient) {
      setValue('signatureType', TreatmentSignatureType.Participate);
      setSignatureType(TreatmentSignatureType.Participate);
    } else {
      setValue('signatureType', '');
    }
    setValue('signatureProviderType', value);
  };

  const handleSignatureTypeChange = (e: any) => {
    setSignatureType(e?.target.defaultValue);
    setValue('signatureType', e?.target.defaultValue);
  };

  const clearSignature = () => {
    sigRef?.current?.clear();
    setValue('signature', '');
  };

  const handleSignatureEnd = () => {
    setValue('signature', sigRef?.current?.toDataURL() || '');
    if (sigRef?.current?.toDataURL() && sigRef?.current?.toDataURL() !== '') {
      clearErrors('signature');
    }
  };

  const onSubmit = async (signatureInfoFormData: TreatmentSignatureItem) => {
    setDisableSubmit(true);
    try {
      const { signatureProviderType, signatureType, signature } = signatureInfoFormData;
      const submitData = {
        treatmentPlanId: parentPlanId,
        signatureProviderType,
        signatureType,
        signature,
      };
      await addSignature(submitData).unwrap();

      onFormSubmitCallback(true);

      setPageErrors([]);
      if (
        signatureProviderType === TreatmentSignatureProviderType.Patient &&
        signatureType === TreatmentSignatureType.Email
      ) {
        toast.publish(
          t('treatmentPlans.signatureForm.sentToEmail', {
            type:
              patientHasEmail && patientSMSOptIn
                ? `${t('treatmentPlans.signatureForm.typeSent.emailAndText')}`
                : `${
                    patientHasEmail
                      ? `${t('treatmentPlans.signatureForm.typeSent.email')}`
                      : `${t('treatmentPlans.signatureForm.typeSent.text')}`
                  }`,
          }),
          'success'
        );
      } else {
        toast.publish(t('treatmentPlans.signatureForm.addSuccess'), 'success');
      }
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
      onFormSubmitCallback(false, error);
    }
    setDisableSubmit(false);
  };

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  // !patientHasEmail && !patientSMSOptIn
  const renderSignatureTypes = (value: string) => {
    return signatureTypes
      .filter(
        (signatureType) =>
          !(signatureType === TreatmentSignatureType.Email && !patientHasEmail && !patientSMSOptIn)
      )
      .map((signatureType) => {
        let signatureTypeParameters = {};
        switch (signatureType) {
          case TreatmentSignatureType.Participate: {
            signatureTypeParameters = {
              object: 'Patient: ',
            };
            break;
          }
          case TreatmentSignatureType.Email: {
            signatureTypeParameters = {
              type:
                patientHasEmail && patientSMSOptIn
                  ? capitalizeFirstLetter(t('treatmentPlans.signatureForm.typeSent.emailAndText'))
                  : `${
                      patientHasEmail
                        ? capitalizeFirstLetter(t('treatmentPlans.signatureForm.typeSent.email'))
                        : capitalizeFirstLetter(t('treatmentPlans.signatureForm.typeSent.text'))
                    }`,
            };
          }
        }

        return (
          <FormControlLabel
            key={signatureType}
            value={signatureType}
            labelPlacement="end"
            control={
              <StyleRadio onChange={handleSignatureTypeChange} checked={value === signatureType} />
            }
            label={`${t(
              `treatmentPlans.signatureForm.signatureTypes.${signatureType}`,
              signatureTypeParameters
            )}`}
          />
        );
      });
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        px: 0,
        pt: 0,
      }}
    >
      <RoundedContainer sx={{ paddingTop: 1 }}>
        <Box
          sx={{
            py: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Controller
            name="signatureProviderType"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <Autocomplete
                {...props}
                options={signatureProviderTypes}
                getOptionLabel={getSignatureProviderOptionLabel}
                onChange={handleSignatureProviderTypeChange}
                value={value ? signatureProviderTypes.find((item) => item?.id === value) : ''}
                filterOptions={filterSignatureProviderOptions}
                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
                  return (
                    <li {...props} key={option.id} value={option.id}>
                      {getSignatureProviderOptionLabel(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.signatureProviderType}
                    helperText={errors.signatureProviderType?.message}
                    margin="normal"
                    fullWidth
                    label={t('treatmentPlans.signatureForm.signatureProviderType')}
                  />
                )}
              />
            )}
          />

          {signatureProviderType === TreatmentSignatureProviderType.Patient && (
            <>
              <Controller
                name="signatureType"
                control={control}
                render={({ field }) => (
                  <RadioGroup sx={{ marginTop: 2 }} {...field}>
                    {renderSignatureTypes(field.value)}
                  </RadioGroup>
                )}
              />
              {!!errors.signatureType && (
                <StyleErrorForm>
                  {errors.signatureType && (errors.signatureType as any).message}
                </StyleErrorForm>
              )}
            </>
          )}

          {((signatureType === TreatmentSignatureType.Participate &&
            signatureProviderType === TreatmentSignatureProviderType.Patient) ||
            signatureProviderType === TreatmentSignatureProviderType.Provider) && (
            <Box>
              <ElectronicSignature
                headerText="Signature:"
                showTopClearButton
                showDivider={false}
                footerText={getSignatureName()}
                ref={sigRef}
                clear={clearSignature}
                onEnd={handleSignatureEnd}
                backgroundColor={theme.xPalette.noteLightGrey}
                sx={{
                  minWidth: '100%',
                  '& .MuiBox-root:first-child .MuiTypography-root:first-child': {
                    fontWeight: 600,
                  },
                }}
              />
              {!!errors.signature && (
                <StyleErrorForm>
                  {errors.signature && (errors.signature as any).message}
                </StyleErrorForm>
              )}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            pt: 3,
          }}
        >
          {!isMobile && <Divider sx={{ width: '100%' }} />}

          {pageErrors?.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            ))}
          <Button
            onClick={submitForm}
            disabled={disableSubmit}
            fullWidth
            variant="contained"
            sx={{
              width: isMobile ? '100%' : '35%',
              my: 2,
              py: 2,
              float: 'right',
            }}
          >
            {t('treatmentPlans.signatureForm.submit')}
          </Button>
        </Box>
      </RoundedContainer>
    </Box>
  );
}
