import { StrategyItem } from './StrategyType';

export enum ObjectiveStatus {
  NotStarted = 'notStarted',
  Active = 'active',
  Completed = 'completed',
}

export interface ObjectiveItem {
  id: number;
  treatmentGoalId: number;
  objective: string;
  status: string;
  statusDate: Date | any;
  dateDue: Date | any;
  strategies?: StrategyItem[];
}

export interface ObjectiveListRequest {
  treatmentGoalId: number;
}

export interface ObjectiveListReponse {
  data: ObjectiveItem[];
  totalItems: number;
}

export interface ObjectiveAddRequest extends ObjectiveItem {}

export interface ObjectiveAddResponse {
  data: ObjectiveItem;
}

export interface ObjectiveEditRequest extends ObjectiveItem {}

export interface ObjectiveEditResponse {
  data: ObjectiveItem;
}
