import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { DashboardContentOptions, FrameOptions } from 'amazon-quicksight-embedding-sdk/dist/types';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useGetReportInfoForDownloadQuery,
  useGetReportInfoQuery,
} from '../../app/services/patient';
import { PageStatus, selectPageStatus, setPageStatus } from '../../common/slices/globalSlice';
import { getPatientName } from '../../common/utils/patientUtils';
import {
  DiagnosisItemsResponse,
  FirstLastInterviewsResponse,
  FirstLastMonitorsResponse,
  LatestInterviewsItem,
  PatientInfo,
} from '../../types/PatientRequest';
import { getMeasumentStatus } from '../patient/patientUtils';

export default function PatientChartReportComponent() {
  const dispatch = useAppDispatch();
  const { patientId } = useParams();
  const embeddingContainerRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const [isDashboardEmbedded, setIsDashboardEmbedded] = useState(false);
  const [reportLoaded, setReportLoaded] = useState(false);
  const [reportFrameHeight, setReportFrameHeight] = useState(0);
  const [reportFrameWidth, setReportFrameWidth] = useState(0);
  const pageStatus = useAppSelector(selectPageStatus);
  const token = searchParams?.get('token') || null;

  const { data: reportInfo, isLoading: isReportInfoLoading } = useGetReportInfoQuery(
    !token && patientId ? { patientId } : skipToken
  );

  const { data: reportInfoForDownload } = useGetReportInfoForDownloadQuery(
    token && patientId ? { patientId, token } : skipToken
  );

  const reportInfoData = token ? reportInfoForDownload : reportInfo;
  const diagnosisData = reportInfoData?.patientDiagnoses as DiagnosisItemsResponse;
  const patientData = reportInfoData?.patientDetails as PatientInfo;
  const firstAndLastInterviewsData =
    reportInfoData?.firstAndLastInterviews as FirstLastInterviewsResponse;
  const firstAndLastTrackersData =
    reportInfoData?.firstAndLastTrackers as FirstLastMonitorsResponse;
  const embedUrlData = reportInfoData?.embedUrl;

  useEffect(() => {
    if (isReportInfoLoading && pageStatus !== PageStatus.Loading) {
      dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
    }
  });

  const loadCallback = useCallback(() => {
    setReportLoaded(true);
    dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
  }, [dispatch]);

  const embedDashboard = useCallback(
    async (url: string, apiKey: string) => {
      if (!url || !apiKey) return;
      dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
      const containerDiv = embeddingContainerRef.current as HTMLElement;

      let diagnosisNames = '';
      if (diagnosisData?.patientDiagnoses?.length) {
        diagnosisNames = diagnosisData?.patientDiagnoses
          .map((item) =>
            `${item.diagnosisName ? item.diagnosisName : item.otherDiagnosis} ${
              !!item.timeFrame ? '-' : ''
            } ${item.timeFrame}`.trim()
          )
          .join(', ');
      }

      let firstMeasurement = firstAndLastInterviewsData?.first;
      let lastMeasurement = firstAndLastInterviewsData?.last;

      if (
        firstAndLastTrackersData?.first &&
        (!firstMeasurement || firstAndLastTrackersData.first.createdAt < firstMeasurement.createdAt)
      ) {
        firstMeasurement = firstAndLastTrackersData.first as LatestInterviewsItem;
      }
      if (
        firstAndLastTrackersData?.last &&
        (!lastMeasurement || firstAndLastTrackersData.last.createdAt > lastMeasurement.createdAt)
      ) {
        lastMeasurement = firstAndLastTrackersData.last as LatestInterviewsItem;
      }

      if (!firstMeasurement || !lastMeasurement) {
        console.error('There are no measurements');
        return;
      }

      const frameOptions: FrameOptions = {
        url,
        container: containerDiv,
        height: 'AutoFit',
        width: '100%',
      };

      const contentOptions: DashboardContentOptions = {
        parameters: [
          {
            Name: 'AccountID',
            Values: [apiKey],
          },
          {
            Name: 'PatientID',
            Values: [patientId || ''],
          },
          {
            Name: 'PatientEmail',
            Values: [patientData?.email || 'No email'],
          },
          {
            Name: 'PatientName',
            Values: [patientData ? getPatientName(patientData) : ''],
          },
          {
            Name: 'Diagnosis',
            Values: [diagnosisNames || 'No diagnosis'],
          },
          {
            Name: 'FirstMeasured',
            Values: [dayjs(firstMeasurement.createdAt).format('YYYY-MM-DD')],
          },
          {
            Name: 'LastMeasured',
            Values: [dayjs(lastMeasurement.createdAt).format('YYYY-MM-DD')],
          },
          {
            Name: 'FirstStatus',
            Values: [getMeasumentStatus(firstMeasurement.status || '')],
          },
          {
            Name: 'LastStatus',
            Values: [getMeasumentStatus(lastMeasurement.status || '')],
          },
        ],
        toolbarOptions: {
          export: false,
          undoRedo: false,
          reset: false,
        },
        attributionOptions: {
          overlayContent: false,
        },
        onMessage: async (messageEvent) => {
          switch (messageEvent.eventName) {
            case 'CONTENT_LOADED': {
              console.log(
                'All visuals are loaded. The title of the document:',
                messageEvent.message.title
              );
              loadCallback();
              break;
            }
            case 'ERROR_OCCURRED': {
              console.log(
                'Error occurred while rendering the experience. Error code:',
                messageEvent.message.errorCode
              );
              loadCallback();
              break;
            }
            case 'SIZE_CHANGED': {
              console.log('Size changed. New dimensions:', messageEvent.message);
              const iframe = document.querySelector(
                '#embeddingContainer .quicksight-embedding-iframe'
              ) as HTMLIFrameElement;
              if (iframe && messageEvent.message) {
                iframe.height = messageEvent.message.height || 0;
                setReportFrameHeight(messageEvent.message.height || 0);
                setReportFrameWidth(messageEvent.message.width || 0);
              }
              break;
            }
          }
        },
      };

      const embeddingContext = await createEmbeddingContext();
      await embeddingContext.embedDashboard(frameOptions, contentOptions);

      // This will force stopping the loading after 15 seconds
      setTimeout(() => {
        setReportLoaded(true);
        dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
      }, 15000);
    },
    [
      diagnosisData?.patientDiagnoses,
      dispatch,
      firstAndLastInterviewsData?.first,
      firstAndLastInterviewsData?.last,
      firstAndLastTrackersData?.first,
      firstAndLastTrackersData?.last,
      loadCallback,
      patientData,
      patientId,
    ]
  );

  const showPatientReport = useCallback(async () => {
    if (embedUrlData?.embedData?.EmbedUrl) {
      embedDashboard(embedUrlData.embedData.EmbedUrl || '', embedUrlData.encryptedKey || '');
    }
  }, [embedDashboard, embedUrlData]);

  useEffect(() => {
    if (
      !isDashboardEmbedded &&
      diagnosisData &&
      patientData &&
      firstAndLastInterviewsData &&
      firstAndLastTrackersData &&
      embedUrlData
    ) {
      setIsDashboardEmbedded(true);
      showPatientReport();
    }
  }, [
    diagnosisData,
    embedUrlData,
    firstAndLastInterviewsData,
    firstAndLastTrackersData,
    isDashboardEmbedded,
    patientData,
    showPatientReport,
  ]);

  return (
    <>
      {reportLoaded && pageStatus === PageStatus.Idle && (
        <div className="report-loaded" style={{ display: 'none' }}></div>
      )}
      <div className="report-frame-height" style={{ display: 'none' }}>{reportFrameHeight}</div>
      <div className="report-frame-width" style={{ display: 'none' }}>{reportFrameWidth}</div>
      <Box
        ref={embeddingContainerRef}
        id="embeddingContainer"
        sx={{ paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}
      ></Box>
    </>
  );
}
