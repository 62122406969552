import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import {
  IDLE_CHECK_EVENTS,
  PATIENT_SESSION_EXPIRE_MILLISECONDS,
} from '../../../common/utils/securityConfig';

interface Props {
  isClinician: boolean;
  assessmentUrl: string;
}

export default function usePatientSessionExpiration({ isClinician, assessmentUrl }: Props): void {
  const navigate = useNavigate();

  const handleOnIdle = (_: Event) => {
    if (assessmentUrl) {
      navigate('/assessment/expire', { state: { assessmentUrl } });
    }
  };

  useIdleTimer({
    timeout: PATIENT_SESSION_EXPIRE_MILLISECONDS,
    onIdle: handleOnIdle,
    debounce: 500,
    events: IDLE_CHECK_EVENTS,
    startOnMount: !isClinician,
  });
}
