import { JSXElementConstructor } from 'react';
import { t } from 'i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../app/hooks';
import { InterviewMode } from '../../common/utils/interviewsMode';
import { QuestionAnswerType } from '../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../types/InterviewQuestionTypes';
import { selectInterviewMode } from './interviewSlice';
import BinaryQuestion from './questions/BinaryQuestion';
import DateQuestion from './questions/DateQuestion';
import ElectronicSignatureQuestion from './questions/ElectronicSignatureQuestion';
import FormularQuestion from './questions/FormularQuestion';
import GridQuestion from './questions/GridQuestion';
import HeightQuestion from './questions/HeightQuestion';
import InfoNoAnswer from './questions/InfoNoAnswer';
import MultiOptionsQuestion from './questions/MultiOptionsQuestion';
import MultiSelectQuestion from './questions/MultiSelectQuestion';
import NumberQuestion from './questions/NumberQuestion';
import OptionsQuestion from './questions/OptionsQuestion';
import SdsScaleQuestion from './questions/SdsScaleQuestion';
import StsEventQuestion from './questions/StsEventQuestion';
import StsScaleQuestion from './questions/StsScaleQuestion';
import TableQuestion from './questions/TableQuestion';
import TextQuestion from './questions/TextQuestion';
import TimeDayMonthQuestion from './questions/TimeDayMonthQuestion';
import TimeQuestion from './questions/TimeQuestion';
import WeightQuestion from './questions/WeightQuestion';
import YbocsCheckListQuestion from './questions/YbocsCheckListQuestion';
import YbocsCheckOtherQuestion from './questions/YbocsCheckOtherQuestion';
import YbocsScaleQuestion from './questions/YbocsScaleQuestion';

// TODO: add more items to the map when implementing it
const componentMap: { [type: string]: JSXElementConstructor<InterviewQuestionProps> } = {
  [QuestionAnswerType.Binary]: BinaryQuestion,
  [QuestionAnswerType.Height]: HeightQuestion,
  [QuestionAnswerType.Weight]: WeightQuestion,
  [QuestionAnswerType.Text]: TextQuestion,
  [QuestionAnswerType.Options]: OptionsQuestion,
  [QuestionAnswerType.MultiSelectList]: MultiSelectQuestion,
  [QuestionAnswerType.TimeDayMonth]: TimeDayMonthQuestion,
  [QuestionAnswerType.Time]: TimeQuestion,
  [QuestionAnswerType.Number]: NumberQuestion,
  [QuestionAnswerType.MultiOptions]: MultiOptionsQuestion,
  [QuestionAnswerType.YbocsCheckList]: YbocsCheckListQuestion,
  [QuestionAnswerType.YbocsCheckOther]: YbocsCheckOtherQuestion,
  [QuestionAnswerType.YbocsScale]: YbocsScaleQuestion,
  [QuestionAnswerType.SdsScale]: SdsScaleQuestion,
  [QuestionAnswerType.StsScale]: StsScaleQuestion,
  [QuestionAnswerType.Grid]: GridQuestion,
  [QuestionAnswerType.Table]: TableQuestion,
  [QuestionAnswerType.StsEvent]: StsEventQuestion,
  [QuestionAnswerType.InfoNoAnswer]: InfoNoAnswer,
  [QuestionAnswerType.Formula]: FormularQuestion,
  [QuestionAnswerType.ElectronicSignature]: ElectronicSignatureQuestion,
  [QuestionAnswerType.Date]: DateQuestion,
};

export default function InterviewQuestion({ question, ...rest }: InterviewQuestionProps) {
  const interviewMode = useAppSelector(selectInterviewMode);
  const isPatientInterview = interviewMode === InterviewMode.Patient;
  const showHelpText =
    !isPatientInterview && !question.isPatientOnly && !!question.clinicianHelpText;
  const showWarningText = !isPatientInterview && question.isClinicianOnly;

  const QuestionComponent = componentMap[question.questionAnswerType];
  if (!QuestionComponent || !question) {
    return (
      <Typography color="red">
        {/* // TODO: Remove warning message once done implenentation */}
        *** Type {question.questionAnswerType} is not implement yet!
      </Typography>
    );
  }

  return question.questionAnswerType === QuestionAnswerType.YbocsCheckList ||
    question.questionAnswerType === QuestionAnswerType.YbocsCheckOther ||
    question.questionAnswerType === QuestionAnswerType.YbocsScale ? (
    <QuestionComponent question={question} {...rest}></QuestionComponent>
  ) : (
    <Box>
      {showWarningText && (
        <Alert severity="warning">
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('interview.form.clinicianOnlyWarning'),
            }}
          ></Typography>
        </Alert>
      )}
      <QuestionComponent question={question} {...rest}></QuestionComponent>

      {/* TODO: double check UI */}
      {question.questionHelpText && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography
            dangerouslySetInnerHTML={{
              __html: question.questionHelpText,
            }}
          ></Typography>
        </>
      )}

      {showHelpText && (
        <Alert severity="info">
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('interview.form.clinicianHelpText'),
            }}
          ></Typography>
          <Typography
            sx={{ wordBreak: 'break-word' }}
            dangerouslySetInnerHTML={{
              __html: question.clinicianHelpText!,
            }}
          ></Typography>
        </Alert>
      )}
    </Box>
  );
}
