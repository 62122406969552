import { QueryCacheDuration, buildAccountUrl, rootApi } from '..';
import {
  DeleteTreatmentPlanRequest,
  GetTreatmentDetailRequest,
  GetTreatmentDetailResponse,
  GetTreatmentPlanListRequest,
  GetTreatmentPlanListResponse,
  TreatmentPlanInfo,
  editTreatmentPlanRequest,
} from '../../../types/treatment-plan/TreatmentPlanType';

const api = rootApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getTreatmentPlanList: builder.query<GetTreatmentPlanListResponse, GetTreatmentPlanListRequest>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-plan/list/${params.patientId}`),
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),
    deleteTreatmentPlan: builder.mutation<void, DeleteTreatmentPlanRequest>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-plan/${params.id}`),
        method: 'DELETE',
      }),
    }),
    getTreatmentPlanById: builder.query<GetTreatmentDetailResponse, GetTreatmentDetailRequest>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-plan/${params.treatmentPlanId}`),
        method: 'GET',
        params: {
          isIncludeGoals: params.isIncludeGoals,
          isIncludeDiagnose: params.isIncludeDiagnose,
          isIncludeSignatures: params.isIncludeSignatures,
        },
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
    }),

    addTreatmentPlan: builder.mutation<GetTreatmentDetailResponse, TreatmentPlanInfo>({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-plan`),
        method: 'Post',
        body,
      }),
    }),
    editTreatmentPlan: builder.mutation<void, editTreatmentPlanRequest>({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-plan/${body.treatmentPlanId}`),
        method: 'put',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTreatmentPlanListQuery,
  useDeleteTreatmentPlanMutation,
  useGetTreatmentPlanByIdQuery,
  useAddTreatmentPlanMutation,
  useEditTreatmentPlanMutation,
} = api;
