import { buildAccountUrl, QueryCacheDuration, rootApi, TagTypes } from '.';
import {
  IdentityProviderActiveListResponse,
  IdentityProviderAddRequest,
  IdentityProviderAddResponse,
  IdentityProviderDetailRequest,
  IdentityProviderDetailResponse,
  IdentityProviderEditRequest,
  IdentityProviderEditResponse,
  IdentityProviderListRequest,
  IdentityProviderListResponse,
  LoginMethodsResponse,
} from '../../types/SsoType';

const api = rootApi
  .enhanceEndpoints({
    addTagTypes: [TagTypes.Site],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getIdentityProviders: builder.query<
        IdentityProviderListResponse,
        IdentityProviderListRequest
      >({
        query: (request) => ({
          url: buildAccountUrl(`/v1/account/sso/get-identity-providers`),
          method: 'GET',
          params: { ...request },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      getIdentityProvidersActive: builder.query<IdentityProviderActiveListResponse, void>({
        query: () => ({
          url: buildAccountUrl(`/v1/account/sso/get-identity-providers-active`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      getLoginMethods: builder.query<LoginMethodsResponse, void>({
        query: () => ({
          url: buildAccountUrl(`/v1/account/sso/get-sso-login-methods`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getIdentityProviderDetail: builder.query<
        IdentityProviderDetailResponse,
        IdentityProviderDetailRequest
      >({
        query: (request) => ({
          url: buildAccountUrl(`/v1/account/sso/get-identity-provider-detail`),
          method: 'GET',
          params: { ...request },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      addIdentityProvider: builder.mutation<
        IdentityProviderAddResponse,
        IdentityProviderAddRequest
      >({
        query: (body) => ({
          url: buildAccountUrl(`/v1/account/sso/create-identity-provider`),
          method: 'POST',
          body,
        }),
      }),
      editIdentityProvider: builder.mutation<
        IdentityProviderEditResponse,
        IdentityProviderEditRequest
      >({
        query: (body) => ({
          url: buildAccountUrl(`/v1/account/sso/edit-identity-provider`),
          method: 'POST',
          body,
        }),
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetLoginMethodsQuery,
  useGetIdentityProviderDetailQuery,
  useGetIdentityProvidersQuery,
  useGetIdentityProvidersActiveQuery,
  useAddIdentityProviderMutation,
  useEditIdentityProviderMutation,
} = api;
