import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useDeleteInterviewOrMonitorMutation } from '../../app/services/interview';
import { useToast } from '../../app/toast';
import PopupModal from '../../common/components/PopupModal';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { AssessmentStatus } from '../../common/utils/assessmentStatus';
import { AssessmentType } from '../../common/utils/assessmentType';
import { statusBoxStyle } from '../../common/utils/commonStyles';
import { PatientStatus } from '../../common/utils/patientStatus';
import { LatestAssessmentItem, PatientInfo } from '../../types/PatientRequest';
import DashboardTableMenu from './DashboardTableMenu';
import { clearAssessmentCachedData, getAssessmentStatus } from './patientUtils';

interface TableProps {
  displayedAssessments: LatestAssessmentItem[];
  patientInfo?: PatientInfo;
  isAddPopupModel?: boolean;
  refetchData: () => void;
}

const StyledTableCell = styled(TableCell)(({ theme, ...props }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    padding: '8px 24px',
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: '14px 24px',
    fontWeight: theme.typography.h4.fontWeight,
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    wordBreak: 'break-word',
    color: props.color,
  },
}));

export default function DashboardTable({
  displayedAssessments,
  patientInfo,
  isAddPopupModel,
  refetchData,
}: TableProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const { patientId } = useParams();
  const [patientIdAction, setPatientIdAction] = useState<string>('');
  const [testIdAction, setTestIdAction] = useState<number>(0);
  const [assessmentTypeAction, setAssessmentTypeAction] = useState<AssessmentType>(
    AssessmentType.Interview
  );
  const toast = useToast();

  const [deleteReport] = useDeleteInterviewOrMonitorMutation();

  interface PopupContent {
    title: string;
    description: string;
    btnOk: string;
    btnClose: string;
    toastMessage: string;
    hidePatient?: boolean;
  }

  const deleteInterviewContentPopup = {
    title: t('patient.deleteInterview.title'),
    description: t('patient.deleteInterview.description'),
    btnOk: t('patient.deleteInterview.btnOk'),
    btnClose: t('patient.deleteInterview.btnClose'),
    toastMessage: t('patient.deleteInterview.toastMessage'),
  } as PopupContent;

  const deleteMonitorContentPopup = {
    title: t('patient.deleteMonitor.title'),
    description: t('patient.deleteMonitor.description'),
    btnOk: t('patient.deleteMonitor.btnOk'),
    btnClose: t('patient.deleteMonitor.btnClose'),
    toastMessage: t('patient.deleteMonitor.toastMessage'),
  } as PopupContent;

  const contentPopup = useAppSelector(selectContentPopup);
  const isOpenPopup = useAppSelector(selectIsOpenPopup);

  const showDeleteReportPopup = async (patientId: string, type: AssessmentType, testId: number) => {
    dispatch(
      setContentPopup({
        content:
          type === AssessmentType.Interview
            ? deleteInterviewContentPopup
            : deleteMonitorContentPopup,
      })
    );
    dispatch(setIsOpenPopup({ value: true }));
    setPatientIdAction(patientId);
    setTestIdAction(testId);
    setAssessmentTypeAction(type);
  };

  const onDeleteReport = async (successCallback?: () => void) => {
    try {
      if (patientIdAction && testIdAction) {
        if (assessmentTypeAction === AssessmentType.Interview) {
          await deleteReport({
            patientId: patientIdAction,
            interviewResultId: testIdAction,
            type: assessmentTypeAction,
          }).unwrap();
        }
        if (assessmentTypeAction === AssessmentType.Monitor) {
          await deleteReport({
            patientId: patientIdAction,
            monitorResultId: testIdAction,
            type: assessmentTypeAction,
          }).unwrap();
        }
      }
      if (successCallback) {
        successCallback();
      }
      toast.publish(
        t(
          assessmentTypeAction === AssessmentType.Interview
            ? 'patient.deleteInterview.toastMessage'
            : 'patient.deleteMonitor.toastMessage'
        ),
        'success'
      );
      clearAssessmentCachedData();
      refetchData();
    } catch (e) {
      toast.publish(
        t(
          assessmentTypeAction === AssessmentType.Interview
            ? 'patient.deleteInterview.toastMessageFail'
            : 'patient.deleteMonitor.toastMessageFail'
        ),
        'error'
      );
    }
  };

  const changeMeasurementName = (assessmentItem: LatestAssessmentItem) => {
    const { status, name, trial } = assessmentItem;
    if (status !== AssessmentStatus.InProgress) {
      return name;
    } else {
      return <Box sx={{ textAlign: 'left', color: trial ? 'red' : '' }}>{name}</Box>;
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <TableContainer
        data-testid="dashboard-table"
        elevation={0}
        component={Paper}
        sx={{ borderRadius: '16px 16px 0 0' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {t('patient.patientDashboard.dashboardTable.measurement')}
              </StyledTableCell>
              <StyledTableCell>
                {t('patient.patientDashboard.dashboardTable.complete')}
              </StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedAssessments?.map((assessment) => (
              <TableRow key={assessment.id}>
                <StyledTableCell
                  color={assessment.trial ? 'red' : ''}
                  sx={{
                    maxWidth: 100,
                    [theme.breakpoints.up('sm')]: {
                      maxWidth: 'unset',
                    },
                  }}
                >
                  {changeMeasurementName(assessment)}
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    [theme.breakpoints.up('sm')]: {
                      width: '30%',
                    },
                  }}
                >
                  <Box sx={{ ...statusBoxStyle(matches, assessment.status) }}>
                    {getAssessmentStatus({
                      status: assessment.status,
                      completedAt: assessment.completedAt,
                    })}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{
                    [theme.breakpoints.up('sm')]: {
                      width: '5%',
                    },
                  }}
                >
                  {assessment.status === AssessmentStatus.Completed ||
                  (patientInfo?.patientStatus === PatientStatus.Active &&
                    assessment.status === AssessmentStatus.NotStarted) ||
                  (patientInfo?.patientStatus === PatientStatus.Active &&
                    assessment.status === AssessmentStatus.InProgress) ||
                  (patientInfo?.patientStatus === PatientStatus.Active &&
                    assessment.reducedTypeId > 0 &&
                    assessment.hasInterval === true) ? (
                    <DashboardTableMenu
                      patientInfo={patientInfo}
                      assessmentItem={assessment}
                      patientId={patientId!}
                      assessmentId={assessment.id}
                      status={assessment.status}
                      type={assessment.type}
                      patientStatus={patientInfo?.patientStatus}
                      reducedTypeId={assessment.reducedTypeId}
                      hasInterval={assessment.hasInterval}
                      completedAt={assessment?.completedAt}
                      editExpireTimeStart={assessment.editExpireTimeStart}
                      callShowPopup={() =>
                        showDeleteReportPopup(patientId!, assessment?.type, assessment?.id)
                      }
                      refetchData={refetchData}
                      isSignature={assessment?.isSignature}
                    />
                  ) : undefined}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isAddPopupModel && (
          <PopupModal
            isOpen={isOpenPopup}
            contentPopup={contentPopup}
            onClick={onDeleteReport}
          />
        )}
      </TableContainer>
    </Box>
  );
}
