import { buildAccountUrl, QueryCacheDuration, rootApi, TagIds, TagTypes } from '.';
import { PatientStatus } from '../../common/utils/patientStatus';
import {
  CommunicationSubType,
  UpdateAccountMessagingRequest,
} from '../../types/AccountMessagingType';
import {
  AccountListRequest,
  AccountListResponse,
  AccountRequest,
  AccountResponse,
  AddAccountRequest,
  BrandSettingResponse,
  CheckAccountConfigResponse,
  CommonAccountResponse,
  DeactivateOrActivateAccountRequest,
  EditAccountRequest,
  ManagementReportEmbedUrlRequest,
  ManagementReportEmbedUrlResponse,
} from '../../types/AccountType';
import { ApiKeyResponse } from '../../types/ApiKeyType';
import { AuditLogDownloadResponse, AuditLogRequest, AuditLogResponse } from '../../types/AuditLog';
import { ClinicAccountResponse, SiteByUserResponse } from '../../types/ClinicAccountType';
import { ClinicSiteResponse } from '../../types/ClinicSiteType';
import {
  ClinicalFollowUpAccountSettingsDetailsRequest,
  ClinicalFollowUpAccountSettingsDetailsResponse,
  ClinicalFollowUpAccountSettingsListRequest,
  ClinicalFollowUpAccountSettingsListResponse,
  NextStepActionsRequest,
  NextStepActionsResponse,
  SignedClinicalFollowUpAccountResponse,
  UpdateClinicalFollowUpRequest,
} from '../../types/ClinicalFollowUps';
import {
  PatientStatsRequest,
  PatientStatsResponse,
  SearchPatientRequest,
  SearchPatientResponse,
} from '../../types/DashboardRequest';
import { ExportTypeValues } from '../../types/ExportTypes';
import {
  CheckHasClinicalFollowUpsResponse,
  ClinicalFollowUpsRequest,
  ClinicalFollowUpsResponse,
  PatientListResponse,
  PatientListRequest,
  UpdateFollowUpStatusRequest,
  ProviderResponse,
  GetPatientResponse,
  TransferAssessmentRequest,
} from '../../types/PatientRequest';
import {
  MarkAlertAsSeenRequest,
  MarkAlertAsSeenResponse,
  SuicideAlertListRequest,
  SuicideAlertListResponse,
} from '../../types/SuicideAlertType';
import { SupportUrlRequest, SupportUrlResponse } from '../../types/SupportTypes';
import {
  ProcessUnlockUsersRequest,
  ProcessUnlockUsersResponse,
  UnlockIPsResponse,
  UnlockUsersResponse,
} from '../../types/UnlockUserType';
import { SearchUserRequest, SearchUserResponse } from '../../types/UserRequest';
import {
  DeactivateOrActivateRequest,
  ImportUserRequest,
  LoginUserProfileRequest,
  LoginUserProfileResponse,
  UpdateLoginUserProfileRequest,
  UserListRequest,
  UserListResponse,
  UserRequest,
  UserResponse,
  UserRolesResponse,
} from '../../types/UserTypes';
import {
  FollowUpProviderSignatureRequest,
  FollowUpProviderSignatureResponse,
  FollowUpSignatureAddRequest,
  FollowUpSignedSignatureProviderListRequest,
  FollowUpSignedSignatureProviderListResponse,
} from '../../types/clinical-follow-up/ClinicalFollowUpSignature';

const api = rootApi
  .enhanceEndpoints({
    addTagTypes: [
      TagTypes.Patient,
      TagTypes.User,
      TagTypes.ClinicalFollowUps,
      TagTypes.SuicideAlert,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPatientStats: builder.query<PatientStatsResponse, PatientStatsRequest>({
        query: () => ({
          url: buildAccountUrl(`/v1/account/patient-stats`),
          method: 'GET',
        }),
        keepUnusedDataFor: 0,
      }),

      listPatients: builder.query<PatientListResponse, PatientListRequest>({
        query: (params) => ({
          url: buildAccountUrl(`/v1/account/patients`),
          method: 'GET',
          params,
        }),
        providesTags: (result) =>
          result?.patients
            ? [
                ...result.patients.map(({ id }) => ({
                  type: TagTypes.Patient,
                  id: id + '',
                })),
                {
                  type: TagTypes.Patient,
                  id: TagIds.List,
                },
              ]
            : [],
      }),

      getLoginUserProfile: builder.query<LoginUserProfileResponse, LoginUserProfileRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/user/user-profile'),
          method: 'GET',
          params,
        }),
        providesTags: (_result, _error, arg) => [{ type: TagTypes.User, id: arg.userId }],
      }),

      updateLoginUserProfile: builder.mutation<
        LoginUserProfileResponse,
        UpdateLoginUserProfileRequest
      >({
        query: (updateInfo) => ({
          url: buildAccountUrl('/v1/user/edit-user-profile'),
          method: 'POST',
          body: updateInfo,
        }),
        invalidatesTags: (_result, error, arg) =>
          error ? [] : [{ type: TagTypes.User, id: arg.userId }],
      }),

      unlockUsers: builder.mutation<ProcessUnlockUsersResponse, ProcessUnlockUsersRequest>({
        query: (param) => ({
          url: buildAccountUrl('/v1/user/process-unlock-users'),
          method: 'POST',
          body: param,
        }),
      }),

      getSupportUrl: builder.query<SupportUrlResponse, SupportUrlRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/support/get-url'),
          method: 'GET',
          params,
        }),
      }),

      getApiKey: builder.query<ApiKeyResponse, void>({
        query: () => ({
          url: buildAccountUrl('/v1/support/get-api-key'),
          method: 'GET',
        }),
      }),

      checkHasClinicalFollowUps: builder.query<
        CheckHasClinicalFollowUpsResponse,
        ClinicalFollowUpsRequest
      >({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/patient/check-has-clinical-follow-ups'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getClinicalFollowUps: builder.query<ClinicalFollowUpsResponse, ClinicalFollowUpsRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/patient/get-clinical-follow-ups'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      updateFollowUpStatus: builder.mutation<any, UpdateFollowUpStatusRequest>({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/patient/update-follow-up-status'),
          method: 'POST',
          body,
        }),
      }),

      getSuicideAlertList: builder.query<SuicideAlertListResponse, SuicideAlertListRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/suicide-alert-list'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getUnlockUsers: builder.query<UnlockUsersResponse, void>({
        query: () => ({
          url: buildAccountUrl('/v1/user/unlock-users'),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getUnlockIPAddresses: builder.query<UnlockIPsResponse, void>({
        query: () => ({
          url: buildAccountUrl('/v1/user/unlock-ips'),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      markAlertAsSeen: builder.mutation<MarkAlertAsSeenResponse, MarkAlertAsSeenRequest>({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/mark-alert-as-seen'),
          method: 'PATCH',
          body,
        }),
      }),

      getUserList: builder.query<UserListResponse, UserListRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/user/users'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getClinicAccounts: builder.query<ClinicAccountResponse, void>({
        query: () => ({
          url: buildAccountUrl(`/v1/account/get-accounts`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getSitesByUsers: builder.query<SiteByUserResponse, void>({
        query: () => ({
          url: buildAccountUrl(`/v1/user/get-sites-by-user`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getClinicSites: builder.query<ClinicSiteResponse, void>({
        query: () => ({
          url: buildAccountUrl(`/v1/account/site/get-sites`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getUserRoles: builder.query<UserRolesResponse, void>({
        query: () => ({
          url: buildAccountUrl(`/v1/account/get-user-roles`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      activateOrDeactivateUser: builder.mutation<any, DeactivateOrActivateRequest>({
        query: (body) => ({
          url: buildAccountUrl(`/v1/user/activate-or-deactivate-user`),
          method: 'PATCH',
          body,
        }),
      }),

      getUserInfo: builder.query<UserResponse, { userId: number }>({
        query: (params) => ({
          url: buildAccountUrl(`/v1/user/edit-user-info`),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      addUser: builder.mutation<any, UserRequest>({
        query: (body) => ({
          url: buildAccountUrl(`/v1/user/add-user`),
          method: 'POST',
          body,
        }),
      }),

      editUser: builder.mutation<any, UserRequest>({
        query: (body) => ({
          url: buildAccountUrl(`/v1/user/edit-user`),
          method: 'POST',
          body,
        }),
      }),

      getAccountList: builder.query<AccountListResponse, AccountListRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/accounts'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      activateOrDeactivateAccount: builder.mutation<any, DeactivateOrActivateAccountRequest>({
        query: (body) => ({
          url: buildAccountUrl(`/v1/account/activate-or-deactivate-account`),
          method: 'PATCH',
          body,
        }),
      }),

      getAccountInfo: builder.query<AccountResponse, AccountRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/edit-account-info'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getManagementReportEmbedUrl: builder.query<
        ManagementReportEmbedUrlResponse,
        ManagementReportEmbedUrlRequest
      >({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/get-management-report-embed-url'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getPatientReportEmbedUrl: builder.query<
        ManagementReportEmbedUrlResponse,
        ManagementReportEmbedUrlRequest
      >({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/get-patient-report-embed-url'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      editAccount: builder.mutation<any, EditAccountRequest>({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/edit-account'),
          method: 'POST',
          body,
        }),
      }),

      addAccount: builder.mutation<any, AddAccountRequest>({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/add-account'),
          method: 'POST',
          body,
        }),
      }),

      importUser: builder.mutation<any, ImportUserRequest>({
        query: (body) => ({
          url: buildAccountUrl('/v1/user/import-users'),
          method: 'POST',
          body,
        }),
      }),

      getBrandSetting: builder.query<BrandSettingResponse, AccountRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/brand-setting'),
          method: 'GET',
          params,
        }),
      }),

      updateBrandSetting: builder.mutation<CommonAccountResponse, FormData>({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/update-brand-setting'),
          method: 'POST',
          body,
        }),
      }),

      getAccountLogo: builder.query<{ accountLogo: string }, void>({
        query: () => ({
          url: buildAccountUrl('/v1/account/logo'),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      rawDataDownload: builder.query<any, ExportTypeValues>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/export-raw-data'),
          method: 'GET',
          params,
          responseHandler: async (response: Response) => {
            return {
              csvDataStream: await response.text(),
            };
          },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      structuredDataDownload: builder.query<any, any>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/export-structured-data'),
          method: 'GET',
          params,
          responseHandler: async (response: Response) => {
            return {
              csvDataStream: await response.text(),
            };
          },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      importPatients: builder.mutation<any, FormData>({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/patient/import-patients'),
          method: 'POST',
          body,
        }),
      }),

      checkConfigSendReport: builder.query<CheckAccountConfigResponse, { patientId: string }>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/check-config-send-report'),
          method: 'GET',
          params,
        }),
      }),

      getAccountMessages: builder.query<CommunicationSubType, { clinicAccountId: string }>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/get-account-settings'),
          method: 'GET',
          params,
        }),
      }),

      updateAccountMessaging: builder.mutation<CommunicationSubType, UpdateAccountMessagingRequest>(
        {
          query: (body) => ({
            url: buildAccountUrl('/v1/account/update-account-settings'),
            method: 'POST',
            body,
          }),
        }
      ),

      getClinicalFollowUpSettingsList: builder.query<
        ClinicalFollowUpAccountSettingsListResponse,
        ClinicalFollowUpAccountSettingsListRequest
      >({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/get-clinical-follow-up-settings-list'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getClinicalFollowUpSettings: builder.query<
        ClinicalFollowUpAccountSettingsDetailsResponse,
        ClinicalFollowUpAccountSettingsDetailsRequest
      >({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/get-clinical-follow-up-account-settings'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getSignedClinicalFollowUpAccountStatus: builder.query<
        SignedClinicalFollowUpAccountResponse,
        ClinicalFollowUpAccountSettingsListRequest
      >({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/get-signed-clinical-followup-account-status'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getNextStepActions: builder.query<NextStepActionsResponse, NextStepActionsRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/get-next-step-actions'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      updateClinicalFollowUpAccountSettings: builder.mutation<void, UpdateClinicalFollowUpRequest>({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/update-clinical-follow-up-account-settings'),
          method: 'POST',
          body: body,
        }),
      }),

      addClinicalFollowUpSignature: builder.mutation<void, FollowUpSignatureAddRequest>({
        query: (body) => ({
          url: buildAccountUrl(`/v1/account/clinical-followup-signature`),
          method: 'POST',
          body,
        }),
      }),

      getProviderSignature: builder.query<
        FollowUpProviderSignatureResponse,
        FollowUpProviderSignatureRequest
      >({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/clinical-followup-signature/get-by-provider'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getSignedSignatureProviderList: builder.query<
        FollowUpSignedSignatureProviderListResponse,
        FollowUpSignedSignatureProviderListRequest
      >({
        query: (params) => ({
          url: buildAccountUrl(
            '/v1/account/clinical-followup-signature/get-signed-signature-provider-list'
          ),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getFollowUpSignatureByToken: builder.query<any, { token: string; tokenHash: string }>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/clinical-followup-signature/get-by-token'),
          method: 'GET',
          params,
        }),
      }),

      getSignatureFollowupPdfFileToken: builder.query<any, any>({
        query: (params) => ({
          url: buildAccountUrl(
            '/v1/account/clinical-followup-signature/get-signature-followup-pdf-file-token'
          ),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      updateFollowUpSignatureByToken: builder.mutation<
        any,
        { token: string; tokenHash: string; signature?: string }
      >({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/clinical-followup-signature/update-by-token'),
          method: 'POST',
          body,
        }),
      }),

      checkPatientEmailSignature: builder.query<any, { token: string; tokenHash: string }>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/treatment-signature/get-by-token'),
          method: 'GET',
          params,
        }),
      }),

      updateSignatureByToken: builder.mutation<
        any,
        { token: string; tokenHash: string; signatureType: string; signature?: string }
      >({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/treatment-signature/update-by-token'),
          method: 'POST',
          body,
        }),
      }),

      getAccountProviders: builder.query<ProviderResponse, { accountId: string }>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/get-account-providers'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getPatients: builder.query<
        GetPatientResponse,
        { clinicAccountId?: string; clinicSiteId?: number }
      >({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/patient/patients'),
          method: 'GET',
          params,
        }),
      }),

      transferSingleAssessment: builder.mutation<{ message?: string }, TransferAssessmentRequest>({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/patient/transfer-assessment'),
          method: 'POST',
          body,
        }),
      }),
    }),
  });

export const searchPatientsEndpoint = 'searchPatients';
export const patientSearchApi = rootApi
  .enhanceEndpoints({ addTagTypes: [TagTypes.Patient] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // use brackets notation to support referencing in globalSlice
      [searchPatientsEndpoint]: builder.query<SearchPatientResponse, SearchPatientRequest>({
        query: (searchPatientRequest) => {
          let url = `/v1/account/search-patient/?status=${PatientStatus.Active}&search=${searchPatientRequest.searchTerm}`;
          url += `&searchByFullName=${Boolean(searchPatientRequest.searchByFullName)}`;
          url += `&searchByExternalId=${Boolean(
            searchPatientRequest.searchByExternalId === false ? false : true
          )}`;
          if (searchPatientRequest.clinicSiteId) {
            url += `&clinicSiteId=${searchPatientRequest.clinicSiteId}`;
          }
          if (searchPatientRequest.clinicAccountId) {
            url += `&clinicAccountId=${searchPatientRequest.clinicAccountId}`;
          }
          if (searchPatientRequest.pageSize) {
            url += `&pageSize=${searchPatientRequest.pageSize}`;
          }
          return {
            url: buildAccountUrl(url),
            method: 'GET',
          };
        },
        providesTags: [{ type: TagTypes.Patient, id: TagIds.List }],
      }),
    }),
    overrideExisting: true,
  });

export const searchUsersEndpoint = 'searchUsers';
export const userSearchApi = rootApi
  .enhanceEndpoints({ addTagTypes: [TagTypes.User] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // use brackets notation to support referencing in globalSlice
      [searchUsersEndpoint]: builder.query<SearchUserResponse, SearchUserRequest>({
        query: (searchUserRequest) => {
          let url = `/v1/user/users/?simpleSearch=true&search=${
            searchUserRequest.searchTerm || ''
          }`;
          url += `&searchByFullName=${Boolean(searchUserRequest.searchByFullName)}`;
          url += `&searchByEmail=${Boolean(
            searchUserRequest.searchByEmail === false ? false : true
          )}`;
          if (searchUserRequest.clinicSiteId) {
            url += `&clinicSiteId=${searchUserRequest.clinicSiteId}`;
          }
          if (searchUserRequest.clinicAccountId) {
            url += `&clinicAccountId=${searchUserRequest.clinicAccountId}`;
          }
          if (searchUserRequest.pageSize) {
            url += `&pageSize=${searchUserRequest.pageSize}`;
          }
          return {
            url: buildAccountUrl(url),
            method: 'GET',
          };
        },
        providesTags: [{ type: TagTypes.User, id: TagIds.List }],
      }),
    }),
    overrideExisting: true,
  });

// export const auditLogSearchEndpoint = 'searchAuditLogs';
export const auditLogSearchApi = rootApi
  .enhanceEndpoints({ addTagTypes: [TagTypes.AuditLog] })
  .injectEndpoints({
    endpoints: (builder) => ({
      searchAuditLogs: builder.query<AuditLogResponse, AuditLogRequest>({
        query: (param) => ({
          url: buildAccountUrl('/v1/audit-log/get-list'),
          method: 'GET',
          params: {
            clinicAccountId: param.clinicAccountId,
            clinicSiteId: param.clinicSiteId,
            userId: param.userId,
            patientId: param.patientId,
            eventType: param.eventType,
            fromDate: param.fromDateString,
            toDate: param.toDateString,
            timezoneOffset: param.timezoneOffset || 0,
            page: param.page,
            pageSize: param.pageSize,
          },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      searchAuditLogsForDownload: builder.query<AuditLogDownloadResponse, AuditLogRequest>({
        query: (param) => ({
          url: buildAccountUrl('/v1/audit-log/download-audit-log-csv'),
          method: 'GET',
          params: {
            clinicAccountId: param.clinicAccountId,
            clinicSiteId: param.clinicSiteId,
            userId: param.userId,
            patientId: param.patientId,
            eventType: param.eventType,
            fromDate: param.fromDateString,
            toDate: param.toDateString,
            timezoneOffset: param.timezoneOffset || 0,
          },
          responseHandler: async (response: any) => {
            return {
              csvData: await response.text(),
            };
          },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetPatientStatsQuery,
  useListPatientsQuery,
  useGetLoginUserProfileQuery,
  useUpdateLoginUserProfileMutation,
  useGetSupportUrlQuery,
  useGetApiKeyQuery,
  useGetClinicalFollowUpsQuery,
  useCheckHasClinicalFollowUpsQuery,
  useUpdateFollowUpStatusMutation,
  useGetSuicideAlertListQuery,
  useMarkAlertAsSeenMutation,
  useGetUnlockIPAddressesQuery,
  useGetUnlockUsersQuery,
  useUnlockUsersMutation,
  useGetUserListQuery,
  useGetUserInfoQuery,
  useGetClinicAccountsQuery,
  useGetSitesByUsersQuery,
  useGetClinicSitesQuery,
  useGetUserRolesQuery,
  useActivateOrDeactivateUserMutation,
  useAddUserMutation,
  useEditUserMutation,
  useGetAccountListQuery,
  useActivateOrDeactivateAccountMutation,
  useGetAccountInfoQuery,
  useEditAccountMutation,
  useAddAccountMutation,
  useImportUserMutation,
  useGetBrandSettingQuery,
  useUpdateBrandSettingMutation,
  useGetAccountLogoQuery,
  useLazyRawDataDownloadQuery,
  useLazyStructuredDataDownloadQuery,
  useImportPatientsMutation,
  useCheckConfigSendReportQuery,
  useGetAccountMessagesQuery,
  useUpdateAccountMessagingMutation,
  useGetClinicalFollowUpSettingsListQuery,
  useGetClinicalFollowUpSettingsQuery,
  useLazyGetSignedClinicalFollowUpAccountStatusQuery,
  useGetNextStepActionsQuery,
  useUpdateClinicalFollowUpAccountSettingsMutation,
  useCheckPatientEmailSignatureQuery,
  useUpdateSignatureByTokenMutation,
  useGetAccountProvidersQuery,
  useAddClinicalFollowUpSignatureMutation,
  useLazyGetProviderSignatureQuery,
  useGetFollowUpSignatureByTokenQuery,
  useUpdateFollowUpSignatureByTokenMutation,
  useGetSignatureFollowupPdfFileTokenQuery,
  useGetSignedSignatureProviderListQuery,
  useGetPatientsQuery,
  useTransferSingleAssessmentMutation,
} = api;

export const { endpoints: accountApis } = api;

export const { useSearchAuditLogsQuery } = auditLogSearchApi;
