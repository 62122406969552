export const intervals: string[] = [
  'Once',
  'Daily',
  'Weekly',
  'Biweekly',
  'Monthly',
  'Quarterly',
  'Biannually',
  'Annually',
];
