import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetReminderDetailQuery } from '../../../app/services/site';
import theme from '../../../app/theme';
import Loading from '../../../common/components/Loading';
import PageHeader from '../../../common/components/PageHeader';
import DashboardLayout from '../../../common/layouts/Dashboard';
import { BreadcrumbType, editBreadcrumbs, ReplaceType } from '../../../common/utils/editBreadcrums';
import { getPageTitle } from '../../../common/utils/pageUtils';
import { PageProps } from '../../../types/PageProps';
import ErrorPage from '../../error/ErrorPage';
import ReminderForm from './ReminderForm';

export default function EditReminderPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const params = useParams();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { state } = useLocation();
  const { navBackUrl } = !!state && state;

  const siteId: number | undefined = params.siteId ? parseInt(params.siteId) : undefined;
  const reminderId: number | undefined = params.reminderId
    ? parseInt(params.reminderId)
    : undefined;
  const newBreadcrumb: BreadcrumbType = {
    text: t('clinicSite.manageSites.manageReminders.reminderForm.title'),
    link: navBackUrl || `/dashboard/sites/${siteId}/patient-reminders`,
  };

  const replacePath: ReplaceType[] = [{ param: ':siteId', value: siteId?.toString()! }];

  const {
    data: reminderData,
    isLoading,
    error,
    refetch: refetchReminders,
  } = useGetReminderDetailQuery({
    id: reminderId,
  });

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={editBreadcrumbs(breadcrumbs, ...replacePath)}>
      <Helmet>
        <title>
          {getPageTitle(t('clinicSite.manageSites.manageReminders.reminderForm.title'))}
        </title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            px: matches ? 2 : 3,
          }}
        >
          <PageHeader
            headerText={t('clinicSite.manageSites.manageReminders.reminderForm.title')}
            backUrl={newBreadcrumb.link}
          />
        </Box>
        {isLoading ? (
          <Loading />
        ) : (
          reminderData && (
            <ReminderForm reminderData={reminderData} refetchReminders={refetchReminders} />
          )
        )}
      </Container>
    </DashboardLayout>
  );
}
