import { JSXElementConstructor } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { AssessmentMode } from '../../../../common/utils/assessmentMode';
import { QuestionAnswerType } from '../../../../common/utils/questionAnswerTypes';
import {
  MonitorSubQuestion,
  QuestionAnswerProps,
  SubQuestionAnswerProps,
} from '../../../../types/ReportType';
import { InterviewReportType } from '../../../interview/interviewUtils';
import SubMultiOptionsAnswer from './subQuestionAnswers/SubMultiOptionsAnswer';
import SubTextAnswer from './subQuestionAnswers/SubTextAnswer';
import useCustomQuestionInfo from './useCustomQuestionInfo';

const subComponentMap: { [type: string]: JSXElementConstructor<SubQuestionAnswerProps> } = {
  [QuestionAnswerType.Text]: SubTextAnswer,
  [QuestionAnswerType.Number]: SubTextAnswer,
  [QuestionAnswerType.MultiOptions]: SubMultiOptionsAnswer,
};

export default function QuestionnaireAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const { questionDisplay, promptDisplay, answerValue } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });
  const questionnaireAnswer = answerValue[answerValue.length - 1] || '';

  const renderSubAnswer = (monitorSubQuestion: MonitorSubQuestion) => {
    const SubAnswerComponent =
      monitorSubQuestion?.questionAnswerType &&
      subComponentMap[monitorSubQuestion?.questionAnswerType];

    if (!SubAnswerComponent) {
      return (
        <Typography color="red">
          *** Sub Answer {monitorSubQuestion?.questionAnswerType} is not implement yet!
        </Typography>
      );
    }
    return (
      <SubAnswerComponent monitorSubQuestion={monitorSubQuestion} subQuestionAnswer={answerValue} />
    );
  };

  return (
    <Box sx={{ my: 3 }}>
      <Box
        key={questionAnswer.questionId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
          columnGap: 1,
          my: 1,
          width: '100%',
          pageBreakInside: 'avoid',
        }}
      >
        <Box sx={{ width: '15%' }}>
          <Typography component={'span'} fontWeight={500}>
            {questionAnswer.title || ''}
          </Typography>
          {moduleType !== InterviewReportType.Monitor && (
            <Typography component={'span'} fontWeight={500}>
              {questionAnswer.subTitle || ''}
            </Typography>
          )}
        </Box>
        <Box sx={{ width: '75%' }}>
          {promptDisplay && (
            <Typography
              fontWeight="bold"
              dangerouslySetInnerHTML={{
                __html: promptDisplay,
              }}
            ></Typography>
          )}
          <Typography
            fontWeight={500}
            dangerouslySetInnerHTML={{
              __html: questionDisplay,
            }}
          ></Typography>
          {!!questionAnswer?.clinicianHelpText && assessmentType === AssessmentMode.Clinician && (
            <Typography
              fontWeight={500}
              mt={1}
              color={theme.xPalette.lightGrey}
              dangerouslySetInnerHTML={{
                __html: questionAnswer.clinicianHelpText,
              }}
            ></Typography>
          )}
        </Box>

        <>
          <Box sx={{ width: '15%', textAlign: 'right', py: 1 }}>
            <Typography
              component={'span'}
              sx={{
                textAlign: 'right',
                py: 1,
                px: 2,
                border: questionnaireAnswer ? `1px solid ${theme.xPalette.black}` : 'unset',
                fontWeight: 'bold',
              }}
            >
              {questionnaireAnswer || ''}
            </Typography>
          </Box>
        </>
      </Box>

      {questionAnswer?.trackerSubQuestions &&
        questionAnswer?.trackerSubQuestions.length > 0 &&
        questionAnswer?.trackerSubQuestions.map((trackerSubQuestions: MonitorSubQuestion) => {
          if (
            (assessmentType === AssessmentMode.Clinician &&
              !trackerSubQuestions.isClinicianOnly &&
              trackerSubQuestions.isPatientOnly) ||
            ((assessmentType === AssessmentMode.Patient ||
              assessmentType === AssessmentMode.Schedule) &&
              trackerSubQuestions.isClinicianOnly &&
              !trackerSubQuestions.isPatientOnly)
          ) {
            return null;
          }

          return renderSubAnswer(trackerSubQuestions);
        })}
    </Box>
  );
}
