import { useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import ErrorPage from '../error/ErrorPage';
import PatientChartReportComponent from './PatientChartReportComponent';
import Box from '@mui/material/Box';
import Copyright from '../../common/components/Copyright';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function PatientChartReportForDownloadPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams?.get('token') || null;
  if (!token) {
    return <ErrorPage statusCode={HttpStatusCode.NotFound} />;
  }
  return (
    <>
      <Box sx={{ height: 'calc(100vh - 60px)' }}>
        <Typography sx={{ paddingTop: 2, paddingBottom: 2, textAlign: 'center', fontSize: '24px !important' }}>
          {`${t('patientReport.pdfTitle')} - ${dayjs(new Date()).format('MMM DD, YYYY')}`}
        </Typography>
        <PatientChartReportComponent />
      </Box>
      <Box>
        <Copyright sx={{ fontSize: '14px!important' }} />
      </Box>
    </>
  );
}
