import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Container from '@mui/material/Container';
import { useAppSelector } from '../../app/hooks';
import Loading from '../../common/components/Loading';
import LocalPopupModal from '../../common/components/LocalPopupModal';
import PageHeader from '../../common/components/PageHeader';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getAccountSettingContentPopup } from '../../common/utils/accountMessaging';
import { Role } from '../../common/utils/loginRole';
import { getPageTitle } from '../../common/utils/pageUtils';
import { AuthUserType } from '../../types/AuthTypes';
import { PageProps } from '../../types/PageProps';
import { useRequestForAccountMessagingPage } from '../account/hooks/customHookForAccount';
import { selectUser } from '../auth/authSlice';
import ErrorPage from '../error/ErrorPage';
import AccountSettingsForm from './AccountMessagingForm';

export default function AccountMessagingPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const currentUser = useAppSelector<AuthUserType>(selectUser);
  const [clinicAccountId, setClinicAccountId] = useState<string>(currentUser?.clinicAccountId);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [canRunEvent, setCanRunEvent] = useState<boolean>(true);

  const { clinicAccounts, accountSettings, error, isLoading, refetchAccountSettings } =
    useRequestForAccountMessagingPage(clinicAccountId, skipToken);

  const handleSuccess = (): void => {
    setOpenPopup(false);
    setCanRunEvent(true);
  };

  const handleClose = (): void => {
    setOpenPopup(false);
    setCanRunEvent(false);
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('accountMessaging.title'))}</title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={t('accountMessaging.title')}
          backUrl={`/dashboard`}
        />
      </Container>

      {isLoading ? (
        <Loading />
      ) : (
        <AccountSettingsForm
          currentAccountId={currentUser?.clinicAccountId}
          clinicAccounts={clinicAccounts}
          accountSettings={accountSettings}
          setClinicAccountId={setClinicAccountId}
          disabledClinicAccount={
            currentUser?.roleId === Role.ClinicAdmin ||
            currentUser?.roleId === Role.MultiUserProvider
          }
          refetchAccountSettings={refetchAccountSettings}
          setOpenPopup={setOpenPopup}
          setCanRunEvent={setCanRunEvent}
          canRunEvent={canRunEvent}
        />
      )}

      <LocalPopupModal
        isOpen={openPopup}
        contentPopup={getAccountSettingContentPopup(t)}
        onSuccess={handleSuccess}
        onClose={handleClose}
      />
    </DashboardLayout>
  );
}
