import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { buildRedirectUrl } from '../../app/routes';
import {
  useGetMonitorSettingsQuery,
  useGetPatientMonitorSettingsQuery,
} from '../../app/services/monitor';
import { useGetPatientQuery } from '../../app/services/patient';
import Loading from '../../common/components/Loading';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import DashboardLayout from '../../common/layouts/Dashboard';
import { linkWrapperStyle } from '../../common/utils/commonStyles';
import {
  addBreadcrumb,
  BreadcrumbType,
  editBreadcrumbs,
  ReplaceType,
} from '../../common/utils/editBreadcrums';
import { standardizeRedirectText } from '../../common/utils/editText';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { InterviewSettingsViewName as MonitorSettingsViewName } from '../../common/utils/interviewSettings';
import { InterviewMode as MonitorMode } from '../../common/utils/interviewsMode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import ErrorPage from '../error/ErrorPage';
import { clearPatientInterviewData } from '../interview/interviewSlice';
import { StyledButton } from './ReadyOrResumeMonitor';
import { StyledBox } from './StartMonitorPage';
import {
  selectMonitorMode,
  selectPatientMonitorData,
  clearPatientMonitorData,
} from './monitorSlide';

export default function CompleteMonitorPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId, monitorResultId: inputMonitorResultId } = useParams();
  const monitorResultId = parseInt(inputMonitorResultId!);
  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }]; // TODO: should create common logic?
  const newBreadcrumb: BreadcrumbType = {
    text: 'Patient Dashboard',
    link: `/dashboard/patient/${patientId}`,
  };
  const monitorMode = useAppSelector(selectMonitorMode);
  const dispatch = useAppDispatch();
  const patientMonitorData = useAppSelector(selectPatientMonitorData);
  const isClinicianMonitor = monitorMode === MonitorMode.Clinician;

  const { data: infoData, error } = useGetPatientQuery(
    isClinicianMonitor && patientId ? { patientId } : skipToken
  );

  const { data: patientAccountSettings, isLoading: isSettingsLoading } =
    useGetPatientMonitorSettingsQuery(
      !isClinicianMonitor && patientMonitorData?.accessToken
        ? {
            accessToken: patientMonitorData.accessToken,
            monitorType: MonitorMode.Patient,
            viewName: MonitorSettingsViewName.Complete,
          }
        : skipToken
    );

  const { data: clinicianAccountSettings } = useGetMonitorSettingsQuery(
    isClinicianMonitor
      ? {
          monitorTestId: monitorResultId,
          monitorType: MonitorMode.Clinician,
          viewName: MonitorSettingsViewName.Complete,
        }
      : skipToken
  );

  const accountSettings = patientAccountSettings || clinicianAccountSettings;
  const hasAccountSettings = !!accountSettings?.completeText;
  const savedListResultIds = localStorage.getItem('listResultIds');
  const parsedListResultIds = savedListResultIds ? JSON.parse(savedListResultIds) : [];
  const isValidResultId =
    !parsedListResultIds || parsedListResultIds.includes(monitorResultId?.toString());

  // check if interview result id in URL is valid
  if (!isClinicianMonitor && !isValidResultId) {
    return <ErrorPage statusCode={HttpStatusCode.NotFound} />;
  }

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  const renderStaticCompletePageBody = () => {
    return isClinicianMonitor ? (
      <>
        <Typography variant="subtitle1">{t('monitor.completePage.clinician.body.p1')}</Typography>
        <Typography variant="subtitle1" sx={{ py: 2 }}>
          {t('monitor.completePage.clinician.body.p2')}
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="subtitle1">{t('monitor.completePage.patient.body.p1')}</Typography>
        <Typography variant="subtitle1">
          {t('interview.completePage.patient.closeBtnHelpText')}
        </Typography>
      </>
    );
  };

  const renderCompletePageBody = () => {
    if (hasAccountSettings && !isClinicianMonitor) {
      return (
        <>
          <Typography
            dangerouslySetInnerHTML={{
              __html: `${accountSettings.completeText}`,
            }}
            sx={(theme) => ({ ...linkWrapperStyle(theme) })}
          ></Typography>
        </>
      );
    }
    return renderStaticCompletePageBody();
  };

  return (
    <DashboardLayout
      breadcrumbs={editBreadcrumbs(addBreadcrumb(breadcrumbs!, newBreadcrumb), ...replacePath)}
      showBreadcrumb={isClinicianMonitor}
      sessionExpiration={isClinicianMonitor}
    >
      <Helmet>
        <title>{getPageTitle(t('monitor.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        {isClinicianMonitor && (
          <StyledBox>
            <PatientInfoCard data={infoData!} />
          </StyledBox>
        )}
        <StyledBox>
          <Box sx={{ py: 3 }}>
            <Typography variant="h1">{t('monitor.completePage.title')}</Typography>
          </Box>
          {isSettingsLoading ? (
            <Loading />
          ) : (
            <>
              <Box sx={{ mb: 1 }}>{renderCompletePageBody()}</Box>
              <Box
                sx={{
                  py: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {isClinicianMonitor ? (
                  <StyledButton
                    type="button"
                    variant="contained"
                    onClick={() => {
                      navigate(`/dashboard/patient/${patientId}`);
                      dispatch(clearPatientInterviewData());
                      dispatch(clearPatientMonitorData());
                      localStorage.removeItem('listResultIds');
                    }}
                  >
                    {t('monitor.completePage.done')}
                  </StyledButton>
                ) : (
                  <StyledButton
                    type="button"
                    variant="contained"
                    onClick={() => {
                      navigate(
                        buildRedirectUrl(accountSettings?.redirectUrl || 'https://nview.com')
                      );
                      dispatch(clearPatientInterviewData());
                      dispatch(clearPatientMonitorData());
                      localStorage.removeItem('listResultIds');
                    }}
                  >
                    {accountSettings?.redirectText
                      ? standardizeRedirectText(accountSettings?.redirectText)
                      : t('monitor.completePage.patient.closeBtn')}
                  </StyledButton>
                )}
              </Box>
            </>
          )}
        </StyledBox>
      </Container>
    </DashboardLayout>
  );
}
