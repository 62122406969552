import { JSXElementConstructor } from 'react';
import { t } from 'i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../app/hooks';
import { MonitorModeName } from '../../common/utils/monitorsMode';
import { QuestionAnswerType } from '../../common/utils/questionAnswerTypes';
import { MonitorQuestionProps } from '../../types/MonitorTypes';
import BinaryQuestion from './monitorQuestion/BinaryQuestion';
import InfoNoAnswer from './monitorQuestion/InfoNoAnswer';
import Questionnaire from './monitorQuestion/Questionnaire';
import TextQuestion from './monitorQuestion/TextQuestion';
import { selectMonitorMode } from './monitorSlide';

const componentMap: {
  [type: string]: JSXElementConstructor<MonitorQuestionProps>;
} = {
  [QuestionAnswerType.Binary]: BinaryQuestion,
  [QuestionAnswerType.Questionnaire]: Questionnaire,
  [QuestionAnswerType.InfoNoAnswer]: InfoNoAnswer,
  [QuestionAnswerType.Text]: TextQuestion,
};

export default function MonitorQuestion({ question, ...rest }: MonitorQuestionProps) {
  const monitorMode = useAppSelector(selectMonitorMode);
  const isPatientMonitor = monitorMode === MonitorModeName.Patient;
  const QuestionComponent = componentMap[question.questionAnswerType!];
  const showHelpText = !isPatientMonitor && !question.isPatientOnly && !!question.clinicianHelpText;
  const showWarningText = !isPatientMonitor && question.isClinicianOnly;

  if (!QuestionComponent || !question) {
    return (
      <Typography color="red">
        *** Type {question.questionAnswerType} is not implement yet!
      </Typography>
    );
  }

  return (
    <Box>
      {showWarningText && question.questionAnswerType !== QuestionAnswerType.InfoNoAnswer && (
        <Alert severity="warning">
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('monitor.form.clinicianOnlyWarning'),
            }}
          ></Typography>
        </Alert>
      )}

      <QuestionComponent question={question} {...rest}></QuestionComponent>

      {showHelpText && (
        <Alert severity="info">
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('monitor.form.clinicianHelpText'),
            }}
          ></Typography>
          <Typography
            sx={{ wordBreak: 'break-word' }}
            dangerouslySetInnerHTML={{
              __html: question.clinicianHelpText!,
            }}
          ></Typography>
        </Alert>
      )}
    </Box>
  );
}
