export interface FollowUpSignatureItem {
  providerId: number | null;
  signViaEmail?: boolean;
  signature?: string;
  status?: FollowUpSignatureStatus;
  signatureType?: FollowUpSignatureType;
  clinicAccountId?: string;
  clinicalFollowUpId?: number;
}

export interface FollowUpSignatureAddRequest extends FollowUpSignatureItem {
}

export enum FollowUpSignatureStatus {
  NotSigned = 'notSigned',
  Signed = 'signed',
}

export enum FollowUpSignatureType {
  Participate = 'participate',
  Email = 'email',
}

export interface FollowUpProviderSignatureRequest {
  clinicAccountId: string;
  providerId: number;
}

export interface FollowUpProviderSignatureResponse {
  followUpSignature: FollowUpSignatureItem
}

export interface FollowUpSignedSignatureProviderListRequest {
  clinicAccountId: string;
}

export interface FollowUpSignedSignatureProviderListResponse {
  providerIds: number[];
}
