import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import theme from '../../../../app/theme';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import useCustomQuestionInfo from './useCustomQuestionInfo';

const StyledFormControl = styled(FormControl)(() => ({
  margin: '16px 0px',
  width: '100%',

  '&.MuiFormControl-root .MuiFormGroup-root': {
    flexWrap: 'wrap',
  },
}));

const commonStylesIcon = {
  minHeight: '38px',
  fontSize: '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
};

const StyledCheckIconCustom = styled(Box)(({ theme }) => ({
  ...commonStylesIcon,
  width: '38px',
  zIndex: '0',
  backgroundColor: theme.xPalette.completed.color,
  color: theme.xPalette.white,
  border: `1px solid ${theme.xPalette.completed.color}`,
}));

const StyledIconCustom = styled(Box)(({ theme }) => ({
  ...commonStylesIcon,
  width: '38px',
  zIndex: '0',
  backgroundColor: theme.xPalette.background,
  color: theme.xPalette.shadeGrey,
  border: `1px solid ${theme.xPalette.shadeGrey}`,
}));

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.xPalette.completed.color,
  },
}));

enum PossibleAnswers {
  Na = 'N/A',
  Yes = 'yes',
  No = 'no',
}

export default function SdsScaleAnswer({ questionAnswer, moduleType }: QuestionAnswerProps) {
  const { t } = useTranslation();
  const { questionDisplay } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
  });
  const defaultPossibleAnswersText = t('interview.form.sds', {
    returnObjects: true,
  });

  const renderPossibleAnswersText = (possibleAnswersText: string[]) => {
    if (
      !possibleAnswersText ||
      possibleAnswersText.includes(PossibleAnswers.Yes || PossibleAnswers.No)
    ) {
      possibleAnswersText = Object.values(defaultPossibleAnswersText);
    }

    let customWidth = 10;
    return possibleAnswersText.map((title: string, index: number) => {
      if (index > 2) {
        customWidth -= 10;
      } else {
        customWidth += 10;
      }
      return (
        <Box key={title} sx={{ width: `${customWidth}%`, height: '40px', fontWeight: 'bold' }}>
          {title}
        </Box>
      );
    });
  };

  const renderAnswers = () => {
    let skipAnswer: any = [];
    let selectAnswer: any = [];

    if (questionAnswer) {
      (questionAnswer.possibleAnswers || []).forEach((item) => {
        if (item && item !== PossibleAnswers.Na) {
          selectAnswer.push(
            <>
              {questionAnswer.questionAnswer === item ? (
                <StyledCheckIconCustom sx={{ fontWeight: 'bold' }}>{item}</StyledCheckIconCustom>
              ) : (
                <StyledIconCustom sx={{ fontWeight: 'bold' }}>{item}</StyledIconCustom>
              )}
            </>
          );
        } else {
          skipAnswer.push(
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <StyledCheckBox
                checked={
                  !!questionAnswer.questionAnswer &&
                  questionAnswer.questionAnswer === PossibleAnswers.Na
                }
              />
              <Typography
                dangerouslySetInnerHTML={{
                  __html: questionAnswer?.questionSkipCheckboxText ?? '',
                }}
              ></Typography>
            </Box>
          );
        }
      });
    }

    return {
      skipAnswer,
      selectAnswer,
    };
  };

  return (
    <Box
      key={questionAnswer.questionId}
      sx={{
        border: `1px solid ${theme.xPalette.black}`,
        my: 3,
        pageBreakInside: 'avoid',
      }}
    >
      <Typography
        my={3}
        fontSize={'16px!important'}
        fontWeight={'bold'}
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: questionAnswer.title || '',
        }}
      ></Typography>
      <Typography
        fontSize={'16px!important'}
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: questionDisplay,
        }}
      ></Typography>

      <StyledFormControl variant="standard">
        <RadioGroup row sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                position: 'relative',
                columnGap: '4%',
                textAlign: 'center',
                my: 2,
              }}
            >
              {renderPossibleAnswersText(Object.values(defaultPossibleAnswersText) || [])}
            </Box>
            <Box
              sx={{
                width: '90%',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '34px',
              }}
            >
              <Box
                sx={{
                  width: '9%',
                  height: '40px',
                  borderBottom: '1px solid black',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    left: '14px',
                    bottom: '-3.5px',
                    borderTop: '4px solid transparent',
                    borderBottom: '4px solid transparent',
                    borderRight: `5px solid ${theme.xPalette.shadeGrey}`,
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderRight: 0,
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderRight: 0,
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderTop: 0,
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderLeft: 0,
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderRight: 0,
                  borderLeft: 0,
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: '1px solid black',
                  borderTop: 0,
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: '1px solid black',
                  borderLeft: 0,
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: '1px solid black',
                  borderLeft: 0,
                }}
              ></Box>
              <Box
                sx={{
                  width: '9%',
                  height: '40px',
                  borderBottom: `1px solid ${theme.xPalette.black}`,
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    right: '14px',
                    bottom: '-3.5px',
                    borderTop: '4px solid transparent',
                    borderBottom: '4px solid transparent',
                    borderLeft: `5px solid ${theme.xPalette.shadeGrey}`,
                  },
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                my: 2,
                flexWrap: 'wrap',
              }}
            >
              {renderAnswers().selectAnswer}
            </Box>
          </Box>
          {renderAnswers().skipAnswer}
        </RadioGroup>
      </StyledFormControl>
    </Box>
  );
}
