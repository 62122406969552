import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetDetailStructuredDataCodebookQuery } from '../../app/services/interview';
import theme from '../../app/theme';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getPageTitle } from '../../common/utils/pageUtils';
import { dataValue, valueField } from '../../types/InterviewTypes';
import { PageProps } from '../../types/PageProps';

const typeMapping = {
  binary: 'string',
  options: 'string',
  multiOptions: 'string',
  text: 'string',
  number: 'number',
  time: 'string (time duration in H:MM)',
  height: 'number (height in cm)',
  weight: 'number (weight in kg)',
  sdsScale: 'number',
  stsScale: 'number',
  stsEvent: 'JSON',
  grid: 'JSON',
  table: 'JSON',
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

export default function StructuredDataCodebookDetail({ breadcrumbs }: PageProps) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const { interviewTypeId } = useParams();
  const metaDataTableHeaders: string[] = Object.values(
    t('exportData.structured.codebook.metaDataTableHeader', {
      returnObjects: true,
    })
  );
  const interviewDataTableHeaders: string[] = Object.values(
    t('exportData.structured.codebook.interviewDataTableHeader', {
      returnObjects: true,
    })
  );
  const { data: dataCodebook, isLoading } = useGetDetailStructuredDataCodebookQuery(
    interviewTypeId
      ? {
          interviewTypeId,
        }
      : skipToken
  );

  const renderDesktopMetaData = (data?: valueField[]) => {
    return (
      <TableContainer
        key="table-meta-data"
        component={Paper}
        elevation={0}
        sx={{ borderRadius: '16px 16px 0 0' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {metaDataTableHeaders.map((header, index) => {
                return <StyledTableCell key={`${header} ${index}`}>{header}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item: any) => {
              return (
                <TableRow key={item.name}>
                  <StyledTableCell sx={{ minWidth: '100px' }}>{item.name}</StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>
                    {typeMapping[item.type] || 'string'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>{item.description}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderDetailTable = (value: valueField) => {
    const name = value.name;
    const type = typeMapping[value.type] || 'string';
    const description = value.description;
    const possibleValues = Array.isArray(value.possibleValues)
      ? value.possibleValues?.join(', ')
      : value.possibleValues;
    return (
      <TableRow>
        <StyledTableCell sx={{ minWidth: '100px' }}>{name}</StyledTableCell>
        <StyledTableCell sx={{ minWidth: '100px' }}>{type}</StyledTableCell>
        <StyledTableCell sx={{ minWidth: '100px' }}>
          <Typography
            fontSize="16px!important"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></Typography>
        </StyledTableCell>
        <StyledTableCell sx={{ minWidth: '100px' }}>{possibleValues}</StyledTableCell>
      </TableRow>
    );
  };

  const renderDesktopInterviewData = (data?: dataValue) => {
    return (
      <TableContainer
        key="table-interview-data"
        component={Paper}
        elevation={0}
        sx={{ borderRadius: '16px 16px 0 0' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {interviewDataTableHeaders.map((header, index) => {
                return <StyledTableCell key={`${header} ${index}`}>{header}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>{data?.values.map((value: valueField) => renderDetailTable(value))}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileMetaData = (data?: valueField[]) => {
    if (data) {
      return (
        <DataList
          lineHeight={'auto'}
          data={data.map((item: valueField) => {
            const listItems = [
              [
                <>{t('exportData.structured.codebook.metaDataTableHeader.type')}</>,
                <Box sx={{ wordBreak: 'break-word' }}>{typeMapping[item.type] || 'string'}</Box>,
              ],
              [
                <>{t('exportData.structured.codebook.interviewDataTableHeader.description')}</>,
                <Box sx={{ wordBreak: 'break-word' }}>
                  <Typography
                    fontSize="16px!important"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  ></Typography>
                </Box>,
              ],
            ];
            return {
              leftHeader: (
                <Typography variant="body1" fontWeight="bold" fontSize={20}>
                  {item.name}
                </Typography>
              ),
              rightHeader: <></>,
              items: [...listItems],
            };
          })}
          customStyle={{
            borderRadius: '16px 16px 0 0',
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderMobileInterviewData = (data?: dataValue) => {
    if (data) {
      return (
        <DataList
          lineHeight={'auto'}
          data={data?.values.map((item: valueField) => {
            const listItems = [
              [
                <>{t('exportData.structured.codebook.interviewDataTableHeader.type')}</>,
                <Box sx={{ wordBreak: 'break-word' }}>{typeMapping[item.type] || 'string'}</Box>,
              ],
              [
                <>{t('exportData.structured.codebook.interviewDataTableHeader.description')}</>,
                <Box sx={{ wordBreak: 'break-word' }}>
                  <Typography
                    fontSize={16}
                    dangerouslySetInnerHTML={{
                      __html:item.description,
                    }}
                  ></Typography>
                </Box>,
              ],
              [
                <>{t('exportData.structured.codebook.interviewDataTableHeader.possibleValues')}</>,
                <Box sx={{ wordBreak: 'break-word' }}>
                  {Array.isArray(item.possibleValues)
                    ? item.possibleValues?.join(', ')
                    : item.possibleValues}
                </Box>,
              ],
            ];
            return {
              leftHeader: (
                <Typography variant="body1" fontWeight="bold" fontSize={20}>
                  {item.name}
                </Typography>
              ),
              rightHeader: <></>,
              items: [...listItems],
            };
          })}
          customStyle={{
            borderRadius: '16px 16px 0 0',
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('exportData.structured.codebook.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <PageHeader
            sx={{ mt: 1, mb: 2 }}
            headerText={`${t('exportData.structured.codebook.title')} ${
              dataCodebook?.data[0].name ? `for ${dataCodebook?.data[0].name}` : ''
            }`}
            backUrl={'/dashboard/structured-export/structured-data-codebook'}
          />
        </Container>
        {isLoading ? (
          <Loading />
        ) : (
          <RoundedContainer
            sx={{
              py: 1,
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {typeof interviewTypeId !== 'undefined' &&
              interviewTypeId &&
              dataCodebook &&
              dataCodebook.metadata &&
              dataCodebook.metadata.map((field) => {
                if (field.name === 'Metadata') {
                  return (
                    <Box>
                      <Typography
                        variant="h3"
                        fontSize={24}
                        fontWeight="bold"
                        paddingLeft={isDesktop ? 0 : '24px'}
                        marginBottom="10px"
                        marginTop="20px"
                      >
                        {field.name}
                      </Typography>
                      {isDesktop
                        ? renderDesktopMetaData(field.values)
                        : renderMobileMetaData(field.values)}
                    </Box>
                  );
                } else {
                  return <></>;
                }
              })}
            {typeof interviewTypeId !== 'undefined' &&
              interviewTypeId &&
              dataCodebook &&
              dataCodebook.data &&
              dataCodebook.data.map((interview) => {
                return interview.values.map((module) => (
                  <Box>
                    <Typography
                      variant="h3"
                      fontSize={24}
                      fontWeight="bold"
                      paddingLeft={isDesktop ? 0 : '24px'}
                      marginBottom="10px"
                      marginTop="20px"
                    >
                      {module.name}
                    </Typography>
                    {module.values.map((value: dataValue) =>
                      isDesktop
                        ? renderDesktopInterviewData(value)
                        : renderMobileInterviewData(value)
                    )}
                  </Box>
                ));
              })}
          </RoundedContainer>
        )}
      </Container>
    </DashboardLayout>
  );
}
