interface InterviewModeType {
  [key: string]: {
    modeCode: string;
    emailCode: string;
  };
}

interface ModeNameType {
  [key: string]: string;
}

export const InterviewMode = {
  Patient: 'patient',
  Clinician: 'clinician',
  Email: 'email',
  EmailPatient: 'emailPatient',
};

export const interviewsModeCode: InterviewModeType = {
  [InterviewMode.Clinician]: {
    modeCode: 'C',
    emailCode: 'N',
  },
  [InterviewMode.Patient]: {
    modeCode: 'P',
    emailCode: 'N',
  },
  [InterviewMode.Email]: {
    modeCode: 'P',
    emailCode: 'Y',
  },
};

export const interviewsModeName: ModeNameType = {
  C: InterviewMode.Clinician,
  P: InterviewMode.Patient,
};
