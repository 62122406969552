import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';

export default function DocumentPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { state } = useLocation();
  const { supportVideo } = !!state && state;

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('support.training.videos'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <PageHeader
            sx={{ mb: 2 }}
            headerText={t('support.training.videos')}
            backUrl={'/dashboard/support'}
          />
          <RoundedContainer sx={{ py: 3, mb: 2, backgroundColor: theme.xPalette.background }}>
            {supportVideo && (
              <CardMedia component="video" src={supportVideo} title="title" controls />
            )}
          </RoundedContainer>
        </Container>
      </Container>
    </DashboardLayout>
  );
}
