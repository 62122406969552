import { TFunction } from 'react-i18next';
import { ContentPopup } from '../../types/CommonType';
import { ReportType } from './assessmentType';

export const CommunicationTypes = {
  INTERVIEW_PATIENT_WELCOME_EMAIL: 'interviewPatientWelcomeEmail',
  LANDING_PAGE_INTERVIEW_WHEN_EMAIL_TO_PATIENT: 'landingPageInterviewWhenEmailToPatient',
  LANDING_PAGE_INTERVIEW_PATIENT_REPORT_AT_OFFICE: 'landingPageInterviewPatientReportAtOffice',
  RESUME_PATIENT_INTERVIEW_MESSAGE: 'resumePatientInterviewMessage',
  COMPLETE_PATIENT_INTERVIEW_PAGE_MESSAGE: 'completePatientInterviewPageMessage',

  LANDING_PAGE_INTERVIEW_CLINICIAN_MESSAGE: 'landingPageClinicianMessage',
  RESUME_CLINICIAN_INTERVIEW_MESSAGE: 'resumeClinicianInterviewMessage',
  COMPLETE_CLINICIAN_INTERVIEW_PAGE_MESSAGE: 'completeClinicianInterviewPageMessage',

  MONITOR_PATIENT_WELCOME_EMAIL: 'monitorPatientWelcomeEmail',
  LANDING_PAGE_MONITOR_PATIENT_EMAIL_OR_OFFICE: 'landingPageMonitorPatientEmailOrOffice',
  COMPLETE_PATIENT_MONITOR_PAGE_MESSAGE: 'completePatientMonitorPageMessage',

  LANDING_TEXT_INTERVIEW_SMS_TO_PATIENT: 'landingTextInterviewSMSToPatient',
  LANDING_TEXT_MONITOR_SMS_TO_PATIENT: 'landingTextMonitorSMSToPatient',
};

export const CommunicationSubjectSubTypes = {
  LANDING_PAGE_CLINICIAN_HEADER_TEXT: 'landingPageClinicianHeaderText',
  LANDING_PAGE_EMAIL_HEADER_TEXT: 'landingPageEmailHeaderText',
  LANDING_PAGE_PATIENT_HEADER_TEXT: 'landingPagePatientHeaderText',
  LANDING_PAGE_RESUME_HEADER_TEXT: 'landingPageResumeHeaderText',
  LANDING_PAGE_PATIENT_RESUME_HEADER_TEXT: 'landingPagePatientResumeHeaderText',
  LANDING_PAGE_MONITOR_HEADER_TEXT: 'landingPageMonitorHeaderText',
  INTERVIEW_WELCOME_EMAIL_HEADER_TEXT: 'interviewWelcomeEmailSubjectText',
  MONITOR_WELCOME_EMAIL_HEADER_TEXT: 'monitorWelcomeEmailSubjectText',
};

export const CommunicationTextSubTypes = {
  LANDING_PAGE_CLINICIAN_TEXT: 'landingPageClinicianText',
  LANDING_PAGE_EMAIL_TEXT: 'landingPageEmailText',
  LANDING_PAGE_PATIENT_TEXT: 'landingPagePatientText',
  LANDING_PAGE_RESUME_TEXT: 'landingPageResumeText',
  LANDING_PAGE_PATIENT_RESUME_TEXT: 'landingPagePatientResumeText',
  LANDING_PAGE_MONITOR_TEXT: 'landingPageMonitorText',
  LANDING_PAGE_INTERVIEW_WELCOME_TEXT: 'customWelcomeEmailText',
  LANDING_PAGE_MONITOR_WELCOME_TEXT: 'trackerWelcomeEmailText',
  LANDING_TEXT_INTERVIEW_SMS_TO_PATIENT: 'interviewSMSText',
  LANDING_TEXT_MONITOR_SMS_TO_PATIENT: 'monitorSMSText',
  COMPLETE_PAGE_CLINICIAN_TEXT: 'completePageClinicianText',
  COMPLETE_PAGE_PATIENT_TEXT: 'completePagePatientText',
  COMPLETE_PAGE_MONITOR_TEXT: 'completePageMonitorText',
};

export const CommunicationSubTypes = {
  ...CommunicationSubjectSubTypes,
  ...CommunicationTextSubTypes,
};

export const Recipients = {
  PATIENT: 'patient',
  PROVIDER: 'provider',
};

export const populateCommunicationTypes = ({
  assessmentType,
  recipient,
}: {
  assessmentType?: string;
  recipient?: string;
}): string[] => {
  let items: string[] = [];

  if (!assessmentType || !recipient) {
    return [];
  }

  if (assessmentType === ReportType.Interview && recipient === Recipients.PATIENT) {
    items = [
      CommunicationTypes.INTERVIEW_PATIENT_WELCOME_EMAIL,
      CommunicationTypes.LANDING_PAGE_INTERVIEW_WHEN_EMAIL_TO_PATIENT,
      CommunicationTypes.LANDING_PAGE_INTERVIEW_PATIENT_REPORT_AT_OFFICE,
      CommunicationTypes.LANDING_TEXT_INTERVIEW_SMS_TO_PATIENT,
      CommunicationTypes.RESUME_PATIENT_INTERVIEW_MESSAGE,
      CommunicationTypes.COMPLETE_PATIENT_INTERVIEW_PAGE_MESSAGE,
    ];
  }

  if (assessmentType === ReportType.Interview && recipient === Recipients.PROVIDER) {
    items = [
      CommunicationTypes.LANDING_PAGE_INTERVIEW_CLINICIAN_MESSAGE,
      CommunicationTypes.RESUME_CLINICIAN_INTERVIEW_MESSAGE,
      CommunicationTypes.COMPLETE_CLINICIAN_INTERVIEW_PAGE_MESSAGE,
    ];
  }

  if (assessmentType === ReportType.Monitor && recipient === Recipients.PATIENT) {
    items = [
      CommunicationTypes.MONITOR_PATIENT_WELCOME_EMAIL,
      CommunicationTypes.LANDING_PAGE_MONITOR_PATIENT_EMAIL_OR_OFFICE,
      CommunicationTypes.LANDING_TEXT_MONITOR_SMS_TO_PATIENT,
      CommunicationTypes.COMPLETE_PATIENT_MONITOR_PAGE_MESSAGE,
    ];
  }

  return items;
};

export const populateCommunicationSubTypes = (communicationType: string): string[] => {
  switch (communicationType) {
    case CommunicationTypes.INTERVIEW_PATIENT_WELCOME_EMAIL:
      return [
        CommunicationSubjectSubTypes.INTERVIEW_WELCOME_EMAIL_HEADER_TEXT,
        CommunicationTextSubTypes.LANDING_PAGE_INTERVIEW_WELCOME_TEXT,
      ];
    case CommunicationTypes.LANDING_PAGE_INTERVIEW_WHEN_EMAIL_TO_PATIENT:
      return [
        CommunicationSubjectSubTypes.LANDING_PAGE_EMAIL_HEADER_TEXT,
        CommunicationTextSubTypes.LANDING_PAGE_EMAIL_TEXT,
      ];
    case CommunicationTypes.LANDING_PAGE_INTERVIEW_PATIENT_REPORT_AT_OFFICE:
      return [
        CommunicationSubjectSubTypes.LANDING_PAGE_PATIENT_HEADER_TEXT,
        CommunicationTextSubTypes.LANDING_PAGE_PATIENT_TEXT,
      ];
    case CommunicationTypes.LANDING_PAGE_INTERVIEW_CLINICIAN_MESSAGE:
      return [
        CommunicationSubjectSubTypes.LANDING_PAGE_CLINICIAN_HEADER_TEXT,
        CommunicationTextSubTypes.LANDING_PAGE_CLINICIAN_TEXT,
      ];
    case CommunicationTypes.RESUME_CLINICIAN_INTERVIEW_MESSAGE:
      return [
        CommunicationSubjectSubTypes.LANDING_PAGE_RESUME_HEADER_TEXT,
        CommunicationTextSubTypes.LANDING_PAGE_RESUME_TEXT,
      ];
    case CommunicationTypes.RESUME_PATIENT_INTERVIEW_MESSAGE:
      return [
        CommunicationSubjectSubTypes.LANDING_PAGE_PATIENT_RESUME_HEADER_TEXT,
        CommunicationTextSubTypes.LANDING_PAGE_PATIENT_RESUME_TEXT,
      ];
    case CommunicationTypes.COMPLETE_CLINICIAN_INTERVIEW_PAGE_MESSAGE:
      return [CommunicationTextSubTypes.COMPLETE_PAGE_CLINICIAN_TEXT];
    case CommunicationTypes.MONITOR_PATIENT_WELCOME_EMAIL:
      return [
        CommunicationSubjectSubTypes.MONITOR_WELCOME_EMAIL_HEADER_TEXT,
        CommunicationTextSubTypes.LANDING_PAGE_MONITOR_WELCOME_TEXT,
      ];
    case CommunicationTypes.LANDING_PAGE_MONITOR_PATIENT_EMAIL_OR_OFFICE:
      return [
        CommunicationSubjectSubTypes.LANDING_PAGE_MONITOR_HEADER_TEXT,
        CommunicationTextSubTypes.LANDING_PAGE_MONITOR_TEXT,
      ];
    case CommunicationTypes.COMPLETE_PATIENT_MONITOR_PAGE_MESSAGE:
      return [CommunicationTextSubTypes.COMPLETE_PAGE_MONITOR_TEXT];
    case CommunicationTypes.COMPLETE_PATIENT_INTERVIEW_PAGE_MESSAGE:
      return [CommunicationTextSubTypes.COMPLETE_PAGE_PATIENT_TEXT];
    case CommunicationTypes.LANDING_TEXT_INTERVIEW_SMS_TO_PATIENT:
      return [CommunicationTextSubTypes.LANDING_TEXT_INTERVIEW_SMS_TO_PATIENT];
    case CommunicationTypes.LANDING_TEXT_MONITOR_SMS_TO_PATIENT:
      return [CommunicationTextSubTypes.LANDING_TEXT_MONITOR_SMS_TO_PATIENT];
    default:
      return [];
  }
};

export const populateInstructionMessage = (
  t: TFunction<'translation', undefined>,
  communicationSubType?: string
): string => {
  if (!communicationSubType) {
    return '';
  }

  if ([CommunicationTypes.INTERVIEW_PATIENT_WELCOME_EMAIL].includes(communicationSubType)) {
    return t('accountMessaging.instructionMessage.interviewWelcome');
  }

  if ([CommunicationTypes.MONITOR_PATIENT_WELCOME_EMAIL].includes(communicationSubType)) {
    return t('accountMessaging.instructionMessage.monitorWelcome');
  }

  if (
    [
      CommunicationTypes.LANDING_PAGE_INTERVIEW_PATIENT_REPORT_AT_OFFICE,
      CommunicationTypes.LANDING_PAGE_INTERVIEW_WHEN_EMAIL_TO_PATIENT,
      CommunicationTypes.LANDING_PAGE_INTERVIEW_CLINICIAN_MESSAGE,
    ].includes(communicationSubType)
  ) {
    return t('accountMessaging.instructionMessage.noPlaceHolderLandingPageInterview');
  }

  if (
    [CommunicationTypes.LANDING_PAGE_MONITOR_PATIENT_EMAIL_OR_OFFICE].includes(communicationSubType)
  ) {
    return t('accountMessaging.instructionMessage.noPlaceHolderLandingPageMonitor');
  }

  if (
    [CommunicationTypes.COMPLETE_CLINICIAN_INTERVIEW_PAGE_MESSAGE].includes(communicationSubType)
  ) {
    return t('accountMessaging.instructionMessage.noPlaceHolderCompletePage');
  }

  if (
    [
      CommunicationTypes.RESUME_CLINICIAN_INTERVIEW_MESSAGE,
      CommunicationTypes.RESUME_PATIENT_INTERVIEW_MESSAGE,
    ].includes(communicationSubType)
  ) {
    return t('accountMessaging.instructionMessage.noPlaceHolderResumePage');
  }

  if ([CommunicationTypes.COMPLETE_PATIENT_INTERVIEW_PAGE_MESSAGE].includes(communicationSubType)) {
    return t('accountMessaging.instructionMessage.patientCompleteInterview');
  }

  if ([CommunicationTypes.COMPLETE_PATIENT_MONITOR_PAGE_MESSAGE].includes(communicationSubType)) {
    return t('accountMessaging.instructionMessage.patientCompleteMonitor');
  }

  if (
    [
      CommunicationTypes.LANDING_TEXT_INTERVIEW_SMS_TO_PATIENT,
    ].includes(communicationSubType)
  ) {
    return t('accountMessaging.instructionMessage.interviewPatientSMS');
  }

  if (
    [
      CommunicationTypes.LANDING_TEXT_MONITOR_SMS_TO_PATIENT,
    ].includes(communicationSubType)
  ) {
    return t('accountMessaging.instructionMessage.monitorPatientSMS');
  }

  return '';
};

export const generateValidationSchema = (
  communicationSubTypes: string[],
  yup: any,
  t: TFunction<'translation', undefined>
) => {
  const validationEntities = {};
  communicationSubTypes.forEach((item: string) => {
    validationEntities[item] = yup.string().required(t('accountMessaging.error.requiredField'));
  });

  return validationEntities;
};

export const getAccountSettingContentPopup = (
  t: TFunction<'translation', undefined>
): ContentPopup => {
  return {
    title: t('accountMessaging.form.warningPopup.title'),
    description: t('accountMessaging.form.warningPopup.description'),
    btnOk: t('accountMessaging.form.warningPopup.btnOk'),
    btnClose: t('accountMessaging.form.warningPopup.btnClose'),
  };
};
