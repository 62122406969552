import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { interviewsModeName } from '../../common/utils/interviewsMode';
import { AnswerUnit } from '../../common/utils/questionAnswerTypes';
import { InterviewFormatDto, SequenceItemDto } from '../../types/InterviewQuestionTypes';
import {
  InterviewResponse,
  errorQuestionsType,
  errorAnswerMessagesType,
} from '../../types/InterviewRequest';
import { clearCredentials } from '../auth/authSlice';

export interface PatientInterviewData {
  accessToken: string;
  interviewTestId: number;
  lastActivityTs: number;
}

interface ClinicalFollowUpInfoType {
  patientId: string;
  interviewResultId: number;
}

// TODO: create type for 'any' fields
// TODO: use PascalCase for interface
export interface InterviewStateType {
  interviewMode: string;
  trialInterview: boolean;
  reportType?: string;
  currentPatientId?: string;
  currentInterviewTestId?: number;
  interviewTypeId?: number;
  currentSequenceItems?: SequenceItemDto[];
  interview?: InterviewFormatDto;
  savedAnswers: any;
  answerUnit: AnswerUnit;
  canNavigateBack?: boolean;
  errorAnswerMessages: errorAnswerMessagesType;
  patientInterview?: PatientInterviewData;
  resetQuestion?: boolean;
  previousInterviewTestId?: number;
  gridSelection?: any;
  stsEventRelativeQuestion?: any;
  clinicalFollowUpInfo?: ClinicalFollowUpInfoType;
  timeframe?: string;
  timeframeInDays?: number;
  assessmentUrl?: string;
}

const initialState: InterviewStateType = {
  interviewMode: '',
  trialInterview: false,
  reportType: undefined,
  currentPatientId: undefined,
  currentInterviewTestId: undefined,
  interviewTypeId: undefined,
  currentSequenceItems: [],
  interview: undefined,
  savedAnswers: {},
  answerUnit: 'imperial',
  canNavigateBack: undefined,
  errorAnswerMessages: {},
  patientInterview: undefined,
  resetQuestion: false,
  previousInterviewTestId: undefined,
  gridSelection: {},
  stsEventRelativeQuestion: {},
  clinicalFollowUpInfo: undefined,
  timeframe: '',
  timeframeInDays: 9999,
  assessmentUrl: '',
};

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    setInterviewMode: (
      state,
      {
        payload: { interviewMode, reportType },
      }: PayloadAction<{ interviewMode: string; reportType?: string }>
    ) => {
      state.interviewMode = interviewMode;
      state.reportType = reportType;
    },
    setTrialInterview: (
      state,
      { payload: { trialInterview } }: PayloadAction<{ trialInterview: boolean }>
    ) => {
      state.trialInterview = trialInterview;
    },
    setAnswer: (
      state,
      {
        payload: { questionId, value, answerType },
      }: PayloadAction<{ questionId: number; value: any; answerType: string }>
    ) => {
      state.savedAnswers[questionId] = value;
    },
    setCurrentInterview: (
      state,
      {
        payload: { interviewData: interviewResponse },
      }: PayloadAction<{ interviewData: InterviewResponse }>
    ) => {
      if (interviewResponse) {
        state.currentInterviewTestId = interviewResponse.interviewTestId || 0;
        state.interviewTypeId = interviewResponse.interviewId;
        state.currentPatientId = interviewResponse.nViewPatientId || '';
        state.interview = interviewResponse.interview || {};
        state.interviewMode = interviewsModeName[interviewResponse.interviewMode];
        state.reportType = interviewResponse.interview?.reportType;
        state.canNavigateBack = interviewResponse.canNavigateBack;
        state.errorAnswerMessages = {};
        state.gridSelection = {};
        state.stsEventRelativeQuestion = {};
        state.timeframe = interviewResponse.timeframe;
        state.timeframeInDays = interviewResponse.timeframeInDays;
        state.assessmentUrl = interviewResponse.assessmentUrl || '';
      }
    },
    clearCurrentInterview: (state) => {
      // note: don't clear interviewMode to allow showing correct text in complete page
      state.previousInterviewTestId = state.currentInterviewTestId;
      state.currentInterviewTestId = 0;
      state.interviewTypeId = undefined;
      state.currentPatientId = undefined;
      state.interview = undefined;
      state.reportType = undefined;
      state.canNavigateBack = undefined;
      state.errorAnswerMessages = {};
      state.gridSelection = {};
      state.assessmentUrl = '';
    },
    updateCurrentInterview: (
      state,
      {
        payload: { interview, canNavigateBack },
      }: PayloadAction<{ interview?: InterviewFormatDto; canNavigateBack?: boolean }>
    ) => {
      if (interview) {
        state.interview = interview;
        state.canNavigateBack = canNavigateBack;
      }
    },
    setErrorFromResponse: (
      state,
      { payload: { errorQuestions } }: PayloadAction<{ errorQuestions: errorQuestionsType[] }>
    ) => {
      let errorMessages = {};
      errorQuestions.forEach((error: errorQuestionsType) => {
        errorMessages = { ...errorMessages, [error.questionId]: error.message };
      });
      state.errorAnswerMessages = errorMessages;
    },
    setPatientInterviewData: (
      state,
      { payload: { data } }: PayloadAction<{ data: PatientInterviewData }>
    ) => {
      state.patientInterview = data;
    },
    clearPatientInterviewData: (state) => {
      state.patientInterview = undefined;
    },
    setResetQuestion: (state, { payload: { reset } }: PayloadAction<{ reset: boolean }>) => {
      state.resetQuestion = reset;
    },
    setGridSelection: (state, { payload: { value } }: PayloadAction<{ value: any }>) => {
      state.gridSelection = {
        ...state.gridSelection,
        ...value,
      };
    },
    setStsEventQuestion: (
      state,
      {
        payload: { questionNumber, value },
      }: PayloadAction<{ questionNumber: string; value: number }>
    ) => {
      state.stsEventRelativeQuestion = {
        ...state.stsEventRelativeQuestion,
        [questionNumber]: value,
      };
    },
    setClinicalFollowUps: (
      state,
      {
        payload: { patientId, interviewResultId },
      }: PayloadAction<{ patientId: string; interviewResultId: number }>
    ) => {
      state.clinicalFollowUpInfo = { patientId, interviewResultId };
    },
    clearClinicalFollowUps: (state) => {
      state.clinicalFollowUpInfo = undefined;
    },
  },

  extraReducers: (builder) => {
    // clear interview data when log out
    builder.addCase(clearCredentials, (state) => ({
      ...state,
      ...initialState,
      // retains below state for patient interview flow
      interviewMode: state.interviewMode,
      reportType: state.reportType,
      patientInterview: state.patientInterview,
    }));
  },
});

export const {
  setInterviewMode,
  setCurrentInterview,
  clearCurrentInterview,
  setAnswer,
  setTrialInterview,
  updateCurrentInterview,
  setErrorFromResponse,
  setPatientInterviewData,
  clearPatientInterviewData,
  setResetQuestion,
  setGridSelection,
  setStsEventQuestion,
  setClinicalFollowUps,
  clearClinicalFollowUps,
} = interviewSlice.actions;

export const selectInterview = (state: RootState) => state.interview.interview;
export const selectInterviewTypeId = (state: RootState) => state.interview.interviewTypeId;
export const selectInterviewMode = (state: RootState) => state.interview.interviewMode;
export const selectReportType = (state: RootState) => state.interview.reportType;
export const selectAnswerUnit = () => initialState.answerUnit;
export const selectTrialInterview = (state: RootState) => state.interview.trialInterview;
export const selectCanNavigateBack = (state: RootState) => state.interview.canNavigateBack;
export const selectErrorAnswerQuestions = (state: RootState) => state.interview.errorAnswerMessages;
export const selectPatientInterviewData = (state: RootState) => state.interview.patientInterview;
export const selectResetQuestion = (state: RootState) => state.interview.resetQuestion;
export const selectPreviousInterviewTestId = (state: RootState) =>
  state.interview.previousInterviewTestId;
export const selectGridSelection = (state: RootState) => state.interview.gridSelection;
export const selectStsEventRelativeQuestion = (state: RootState) =>
  state.interview.stsEventRelativeQuestion;
export const selectClinicalFollowUpInfo = (state: RootState): ClinicalFollowUpInfoType =>
  state.interview.clinicalFollowUpInfo;
export const selectTimeframeInDays = (state: RootState): number => state.interview.timeframeInDays;
export const selectAssessmentUrl = (state: RootState) => state.interview.assessmentUrl;

export default interviewSlice.reducer;
