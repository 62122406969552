import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import {
  CommunicationSubjectSubTypes,
  CommunicationTextSubTypes,
} from '../../common/utils/accountMessaging';
import { DefaultAccountMessaging } from '../../types/AccountMessagingType';

interface Props {
  communicationSubTypes: string[];
  control: Control<DefaultAccountMessaging, object>;
  errors: FieldErrors<DefaultAccountMessaging>;
}

export default function MessageSettingDynamicForms({
  communicationSubTypes,
  control,
  errors,
}: Props) {
  const { t } = useTranslation();

  const renderMessageSubject = () => {
    const messageSubjectName: any = communicationSubTypes.find((type: string) =>
      Object.values(CommunicationSubjectSubTypes).includes(type)
    );

    if (messageSubjectName) {
      const label = [
        CommunicationSubjectSubTypes.MONITOR_WELCOME_EMAIL_HEADER_TEXT,
        CommunicationSubjectSubTypes.INTERVIEW_WELCOME_EMAIL_HEADER_TEXT,
      ].includes(messageSubjectName)
        ? t('accountMessaging.form.subject')
        : t('accountMessaging.form.header');

      return (
        <Controller
          name={messageSubjectName}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              data-testid={messageSubjectName}
              label={label}
              error={!!errors[messageSubjectName]}
              helperText={errors[messageSubjectName]?.message}
              variant="outlined"
            />
          )}
        />
      );
    }

    return <></>;
  };

  const renderMessageText = () => {
    const messageTextName: any = communicationSubTypes.find((type: string) =>
      Object.values(CommunicationTextSubTypes).includes(type)
    );

    if (messageTextName) {
      return (
        <Controller
          name={messageTextName}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              data-testid={messageTextName}
              label={t('accountMessaging.form.message')}
              error={!!errors[messageTextName]}
              helperText={errors[messageTextName]?.message}
              variant="outlined"
              multiline
              minRows={10}
            />
          )}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      {renderMessageSubject()}
      {renderMessageText()}
    </>
  );
}
