import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function InfoNoAnswerNoUI({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const { questionDisplay } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <Box
      key={questionAnswer.questionId}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 1,
        my: 3,
        width: '100%',
        pageBreakInside: 'avoid',
      }}
    >
      <Typography
        color={
          questionAnswer?.questionText && questionAnswer.questionText.includes('%r') ? 'red' : ''
        }
        fontWeight={'bold'}
        dangerouslySetInnerHTML={{
          __html: questionDisplay,
        }}
      ></Typography>
    </Box>
  );
}
