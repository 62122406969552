import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  useGetClinicalFollowUpSettingsListQuery,
  useGetClinicalFollowUpSettingsQuery,
  useGetNextStepActionsQuery,
  useLazyGetSignedClinicalFollowUpAccountStatusQuery,
} from '../../../app/services/account';
import Loading from '../../../common/components/Loading';
import LocalPopupModal from '../../../common/components/LocalPopupModal';
import PopupModal from '../../../common/components/PopupModal';
import RoundedContainer from '../../../common/components/RoundedContainer';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../../common/slices/globalSlice';
import { NextStepType } from '../../../common/utils/assessmentType';
import checkNullData from '../../../common/utils/checkNullData';
import { isSame } from '../../../common/utils/clinicalFollowUpUtil';
import { Role } from '../../../common/utils/loginRole';
import { ClinicAccountItems } from '../../../types/ClinicAccountType';
import {
  ClinicalFollowUpNextStep,
  ClinicalFollowUpAccountSettingsItem,
  ClinicalFollowUpInfo,
  InterviewTypeResponse,
  MonitorTypeResponse,
  ClinicalFollowUpNextStepInfo,
} from '../../../types/ClinicalFollowUps';
import { PopupContent } from '../../../types/PopupType';
import { selectUser } from '../../auth/authSlice';
import ClinicalFollowUpsNextStepsForm from './ClinicalFollowUpsNextStepsForm';
import ClinicalFollowUpsSignatureForm from './ClinicalFollowUpsSignatureForm';

const defaultFormValues: ClinicalFollowUpInfo = {
  clinicAccountId: '',
  clinicalFollowUpId: 0,
  clinicalFollowUpNextStep: [],
};

export const commonButtonStyle = (matches: boolean): { [key: string]: number | string } => ({
  my: 2,
  p: 2,
  borderRadius: 2,
  minWidth: matches ? '100%' : '20%',
  whiteSpace: 'nowrap',
});

interface ClinicalFollowUpsProps {
  accountId?: string;
  clinicAccounts: ClinicAccountItems[];
  onFormSubmit: (data: any, successCallback?: () => void) => Promise<void>;
}

const StyleFormItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  height: 'auto',
  paddingBottom: '16px',
  marginTop: '10px',
}));

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default function ClinicalFollowUpsForm({
  accountId,
  onFormSubmit,
  clinicAccounts,
}: ClinicalFollowUpsProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const currentUser = useAppSelector(selectUser);

  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [clinicAccountId, setClinicAccountId] = useState<string | null>(null);
  const [clinicAccountIdTemp, setClinicAccountIdTemp] = useState<string | null>(null);
  const [followUpId, setFollowUpId] = useState<number | null>(null);
  const [followUpIdTemp, setFollowUpIdTemp] = useState<number | null>(null);
  const [initialNextStepData, setInitialNextStepData] = useState<ClinicalFollowUpNextStepInfo[]>(
    []
  );
  const [accountInterviews, setAccountInterviews] = useState<InterviewTypeResponse[]>([]);
  const [accountMonitors, setAccountMonitors] = useState<MonitorTypeResponse[]>([]);
  const [sortedFollowUpData, setSortedFollowUpData] = useState<
    ClinicalFollowUpAccountSettingsItem[]
  >([]);
  const [isSignPopup, setIsSignPopup] = useState<boolean>(false);
  const [automaticStates, setAutomaticStates] = useState<any>(null);
  const [hasAutomateFollowUpChecked, setHasAutomateFollowUpChecked] = useState<boolean>(false);
  const [isSigned, setIsSigned] = useState<boolean>(false);

  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);

  const deactivateUserContentPopup = {
    title: t('account.manageAccounts.accountForm.clinicalFollowUps.form.unsavedPopup.title'),
    description: t(
      'account.manageAccounts.accountForm.clinicalFollowUps.form.unsavedPopup.description'
    ),
    btnOk: t('account.manageAccounts.accountForm.clinicalFollowUps.form.unsavedPopup.btnOk'),
    btnClose: t('account.manageAccounts.accountForm.clinicalFollowUps.form.unsavedPopup.btnClose'),
  } as PopupContent;

  const signContentPopup = {
    title: t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.title'),
  } as PopupContent;

  const showSignPopup = async () => {
    setIsSignPopup(true);
  };

  const closeSignPopup = async () => {
    setIsSignPopup(false);
  };

  const onFormSubmitCallback = async (result: boolean, info: any) => {
    if (result) {
      setIsSignPopup(false);
      if (info?.isSigned) {
        setIsSigned(true);
      }
    }
  };

  const {
    data: nextStepActionsData,
    isSuccess,
    isFetching,
  } = useGetNextStepActionsQuery(
    clinicAccountId
      ? {
          accountId: clinicAccountId,
        }
      : skipToken
  );

  const { data: clinicalFollowUpListData } = useGetClinicalFollowUpSettingsListQuery(
    clinicAccountId && isSuccess ? { clinicAccountId } : skipToken
  );

  const { data: nextStepData, refetch: refetchClinicalFollowUps } =
    useGetClinicalFollowUpSettingsQuery(
      clinicAccountId && followUpId && isSuccess
        ? {
            clinicAccountId,
            followUpId,
          }
        : skipToken
    );

  const [triggerGetProviderSignature, { data: signedFollowUpAccountStatusData }] =
    useLazyGetSignedClinicalFollowUpAccountStatusQuery();

  const validationSchema = yup
    .object()
    .shape({
      clinicAccountId: yup
        .string()
        .trim()
        .test(
          'validate-account',
          t('account.manageAccounts.accountForm.clinicalFollowUps.form.error.blankAccount'),
          function (clinicAccountId) {
            return clinicAccountId !== '';
          }
        ),
      clinicalFollowUpId: yup
        .string()
        .required(
          t('account.manageAccounts.accountForm.clinicalFollowUps.form.error.blankClinicalFollowUp')
        ),
      clinicalFollowUpNextStep: yup
        .array()
        .of(
          yup.object({
            interval: yup.string().nullable(),
            startAfterDays: yup
              .string()
              .nullable()
              .trim()
              .test(
                'validate-invalidStartAfterDays',
                t(
                  'account.manageAccounts.accountForm.clinicalFollowUps.form.error.invalidStartAfterDays'
                ),
                function (startAfterDays) {
                  if (
                    this.parent.automateFollowup &&
                    this.parent.interval &&
                    this.parent.interval !== 'Once' &&
                    (Number(startAfterDays) < 0 || Number(startAfterDays) > 365)
                  ) {
                    return false;
                  }
                  return true;
                }
              ),
            maxSendTimes: yup
              .string()
              .nullable()
              .trim()
              .test(
                'validate-invalidMaxSendTimes',
                t(
                  'account.manageAccounts.accountForm.clinicalFollowUps.form.error.invalidMaxSendTimes'
                ),
                function (maxSendTimes) {
                  if (
                    this.parent.automateFollowup &&
                    this.parent.interval &&
                    this.parent.interval !== 'Once' &&
                    (Number(maxSendTimes) < 1 || Number(maxSendTimes) > 99)
                  ) {
                    return false;
                  }
                  return true;
                }
              ),
            reportText: yup.string().when('applyNextStep', {
              is: (applyNextStep: boolean) => applyNextStep,
              then: yup
                .string()
                .required(
                  t(
                    'account.manageAccounts.accountForm.clinicalFollowUps.form.error.blankReportText'
                  )
                ),
            }),
            nextStepText: yup.string().when('applyNextStep', {
              is: (applyNextStep: boolean) => applyNextStep,
              then: yup
                .string()
                .required(
                  t(
                    'account.manageAccounts.accountForm.clinicalFollowUps.form.error.blankNextStepText'
                  )
                ),
            }),
            followUpText: yup.string().when('applyNextStep', {
              is: (applyNextStep: boolean) => applyNextStep,
              then: yup
                .string()
                .required(
                  t(
                    'account.manageAccounts.accountForm.clinicalFollowUps.form.error.blankNextStepText'
                  )
                ),
            }),
            nextStepAction: yup.string().when('applyNextStep', {
              is: (applyNextStep: boolean) => applyNextStep,
              then: yup
                .string()
                .required(
                  t(
                    'account.manageAccounts.accountForm.clinicalFollowUps.form.error.blankNextStepAction'
                  )
                ),
            }),
          })
        )
        .required(),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    resetField,
    clearErrors,
    watch,
  } = useForm<ClinicalFollowUpInfo>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'clinicalFollowUpNextStep',
  });

  useEffect(() => {
    if (clinicAccountId) {
      triggerGetProviderSignature({ clinicAccountId });
    }
  }, [clinicAccountId, triggerGetProviderSignature]);

  useEffect(() => {
    if (
      accountId &&
      clinicAccounts &&
      clinicAccounts.findIndex((item) => item?.id?.toString() === accountId?.toString()) >= 0
    ) {
      setValue('clinicAccountId', accountId);
      setClinicAccountId(accountId);
    } else if (clinicAccounts) {
      setValue('clinicAccountId', clinicAccounts[0].id);
      setClinicAccountId(clinicAccounts[0].id);
    }
  }, [accountId, clinicAccounts, resetField, setValue]);

  useEffect(() => {
    if (nextStepActionsData?.accountInterviewTypes && nextStepActionsData?.accountMonitorTypes) {
      setAccountInterviews(nextStepActionsData?.accountInterviewTypes);
      setAccountMonitors(nextStepActionsData?.accountMonitorTypes);
    }
  }, [nextStepActionsData]);

  useEffect(() => {
    if (clinicalFollowUpListData?.items?.length) {
      const sortFollowUpData = clinicalFollowUpListData.items
        ?.slice()
        .sort((a: ClinicalFollowUpAccountSettingsItem, b: ClinicalFollowUpAccountSettingsItem) =>
          getClinicalFollowUpLabel(a).toLocaleLowerCase() >
          getClinicalFollowUpLabel(b).toLocaleLowerCase()
            ? 1
            : -1
        );
      setValue('clinicalFollowUpId', sortFollowUpData[0].followUpId);
      setSortedFollowUpData(sortFollowUpData);
      setFollowUpId(sortFollowUpData[0].followUpId);
    } else {
      setValue('clinicalFollowUpId', 0);
      setSortedFollowUpData([]);
      setFollowUpId(null);
    }
  }, [clinicalFollowUpListData, setValue]);

  useEffect(() => {
    if (nextStepData && followUpId && !isFetching) {
      setInitialNextStepData([]);
      resetField('clinicalFollowUpNextStep');
      const bindEditControls = () => {
        const nextStepDataDisplay = nextStepData.items.map((item: ClinicalFollowUpNextStep) => {
          let nextStepAction = item.nextStepAction;
          if (
            (item.type === NextStepType.Interview &&
              !accountInterviews.find(
                (interview) => interview?.id?.toString() === item?.nextStepAction
              )) ||
            (item.type === NextStepType.Monitor &&
              !accountMonitors.find((monitor) => monitor?.id?.toString() === item?.nextStepAction))
          ) {
            nextStepAction = '';
          }

          const nextStepItem = {
            clinicalFollowUpAccountId: item.clinicalFollowUpAccountId,
            nextStepId: item.nextStepId,
            applyNextStep: checkNullData(item.applyNextStep),
            automateFollowup: !!item.automateFollowup,
            interval: checkNullData(item.interval),
            startAfterDays: item.startAfterDays || 0,
            maxSendTimes: item.maxSendTimes || 0,
            reportText: checkNullData(item.reportText),
            nextStepText: checkNullData(item.nextStepText),
            followUpText: checkNullData(item.followUpText),
            type: checkNullData(item.type),
            nextStepAction: nextStepAction,
            nextStepName: checkNullData(item.nextStepName),
          };
          return nextStepItem;
        });
        append(nextStepDataDisplay);
        setInitialNextStepData(nextStepDataDisplay);
        setHasAutomateFollowUpChecked(
          !!nextStepDataDisplay.some((nextStep) => !!nextStep?.automateFollowup)
        );
      };
      bindEditControls();
    }
  }, [
    accountInterviews,
    accountMonitors,
    append,
    followUpId,
    isFetching,
    nextStepData,
    resetField,
  ]);

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name?.includes('automateFollowup')) {
        setHasAutomateFollowUpChecked(
          !!value?.clinicalFollowUpNextStep?.some((nextStep) => !!nextStep?.automateFollowup)
        );
      }
    });
  }, [watch]);

  useEffect(() => {
    if (!fields?.length) {
      setAutomaticStates(null);
      return;
    }

    const automateFollowUpDisabled = {};
    const displayedIntervalOptions = {};
    const startAfterDaysDisabled = {};
    const maxSendTimesDisabled = {};

    fields?.forEach((item) => {
      automateFollowUpDisabled[item.nextStepId] = !item.applyNextStep;
      displayedIntervalOptions[item.nextStepId] = item.automateFollowup && !!item.interval;
      startAfterDaysDisabled[item.nextStepId] = !item.interval || item.interval === 'Once';
      maxSendTimesDisabled[item.nextStepId] = !item.interval || item.interval === 'Once';
    });

    setAutomaticStates({
      automateFollowUpDisabled,
      displayedIntervalOptions,
      startAfterDaysDisabled,
      maxSendTimesDisabled,
    });
  }, [fields]);

  const onSubmit = async (data: ClinicalFollowUpInfo) => {
    try {
      await onFormSubmit(data, () => {
        setPageErrors([]);
        refetchClinicalFollowUps();
      });
    } catch (e: any) {
      if (e?.data?.error) {
        setPageErrors(e?.data?.error);
      }
      console.error('Error when submit form', e);
    }
  };

  const showUnSavedSettingPopup = async (accountTemp?: string, clinicFollowUpIdTemp?: number) => {
    dispatch(setContentPopup({ content: deactivateUserContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    if (accountTemp) {
      setClinicAccountIdTemp(accountTemp);
    }
    if (clinicFollowUpIdTemp) {
      setFollowUpIdTemp(clinicFollowUpIdTemp);
    }
  };

  const confirmUnSavedWhenChangeAccount = async (successCallback?: () => void) => {
    if (clinicAccountIdTemp) {
      setValue('clinicAccountId', clinicAccountIdTemp);
      setClinicAccountId(clinicAccountIdTemp);
      resetField('clinicalFollowUpId');
      setFollowUpId(null);
      setClinicAccountIdTemp(null);
    }
    if (successCallback) {
      successCallback();
    }
  };

  const confirmUnSavedWhenChangeFollowUp = async (successCallback?: () => void) => {
    if (followUpIdTemp) {
      setValue('clinicalFollowUpId', followUpIdTemp);
      setFollowUpId(followUpIdTemp);
      setFollowUpIdTemp(null);
    }
    if (successCallback) {
      successCallback();
    }
  };

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    if (!isSame(initialNextStepData, getValues('clinicalFollowUpNextStep'))) {
      showUnSavedSettingPopup(selectedValue);
    } else {
      setValue('clinicAccountId', selectedValue);
      setClinicAccountId(selectedValue);
      setFollowUpId(null);
      resetField('clinicalFollowUpId');
      resetField('clinicalFollowUpNextStep');
      setInitialNextStepData([]);
    }
  };

  const handleClinicalFollowUpChange = (e: any, values: any) => {
    const selectedValue = values ? values.followUpId : '';
    if (!isSame(initialNextStepData, getValues('clinicalFollowUpNextStep'))) {
      showUnSavedSettingPopup(undefined, selectedValue);
    } else {
      setValue('clinicalFollowUpId', selectedValue);
      resetField('clinicalFollowUpNextStep');
      setFollowUpId(selectedValue);
      setInitialNextStepData([]);
    }
  };

  const filterClinicalFollowUpOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getClinicalFollowUpLabel(option),
  });

  const getClinicalFollowUpLabel = (option: any) =>
    option.followUpName && option.interviewName
      ? `${option.interviewName} - ${option.followUpName}`
      : '';

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getAccountOptionLabel(option),
  });

  const getAccountOptionLabel = (option: any) => `${option.name} (${option.id})`;

  const isSuperAdmin = () => currentUser?.roleId === Role.SuperAdmin;

  const hasAutomateFollowUpCheckedAndSigned = () => {
    return (
      (signedFollowUpAccountStatusData?.isSigned || isSigned) &&
      (signedFollowUpAccountStatusData?.isAutomated || hasAutomateFollowUpChecked)
    );
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        px: matches ? 0 : 3,
        pt: !isSuperAdmin() ? 0 : 3,
      }}
    >
      {!isSuperAdmin() && (
        <Box sx={{ width: '100%', marginBottom: 2 }}>
          <Alert
            sx={{ display: 'flex', justifyContent: 'center', borderRadius: 4 }}
            severity="warning"
            icon={false}
          >
            {t('account.manageAccounts.accountForm.clinicalFollowUps.form.accountAdminWarning')}
          </Alert>
        </Box>
      )}
      <RoundedContainer sx={{ position: 'relative', marginBottom: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
          }}
        >
          <StyleFormItem>
            {isSuperAdmin() && (
              <Controller
                name="clinicAccountId"
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <StyleAutocomplete
                    {...props}
                    sx={{ width: '100%' }}
                    data-testid="mui-component-select-account"
                    options={clinicAccounts || []}
                    getOptionLabel={getAccountOptionLabel}
                    onChange={handleAccountChange}
                    value={value ? clinicAccounts?.find((item) => item.id === value) || [] : null}
                    filterOptions={filterAccountOptions}
                    disabled={!clinicAccounts}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.id} value={option.id}>
                          {getAccountOptionLabel(option)}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.clinicAccountId}
                        helperText={errors.clinicAccountId?.message}
                        margin="normal"
                        fullWidth
                        label={t(
                          'account.manageAccounts.accountForm.clinicalFollowUps.form.account'
                        )}
                      />
                    )}
                  />
                )}
              />
            )}

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: matches ? 0 : 3,
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
              }}
            >
              {hasAutomateFollowUpCheckedAndSigned() && (
                <Box
                  sx={{
                    width: matches ? '100%' : '30%',
                  }}
                >
                  <Link
                    to={`/accounts/clinical-follow-ups/${clinicAccountId}/signaturePdfFileName.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      fullWidth
                      sx={{
                        my: 2,
                        py: 2,
                      }}
                      variant="contained"
                    >
                      {t(
                        'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.viewSignedForm'
                      )}
                    </Button>
                  </Link>
                </Box>
              )}
              {!hasAutomateFollowUpCheckedAndSigned() && (
                <Button
                  fullWidth
                  sx={{
                    width: matches ? '100%' : '30%',
                    my: 2,
                    py: 2,
                  }}
                  variant="contained"
                  disabled={true}
                >
                  {t(
                    'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.viewSignedForm'
                  )}
                </Button>
              )}
              <Button
                fullWidth
                variant="contained"
                disabled={
                  !hasAutomateFollowUpChecked && !signedFollowUpAccountStatusData?.isAutomated
                }
                onClick={() => {
                  showSignPopup();
                }}
                sx={{
                  width: matches ? '100%' : '30%',
                  my: 2,
                  py: 2,
                }}
              >
                {t(
                  'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.buttonTitle'
                )}
              </Button>
            </Box>

            <Controller
              name="clinicalFollowUpId"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  sx={{ width: '100%' }}
                  data-testid="mui-component-select-clinical-follow-up"
                  options={sortedFollowUpData}
                  getOptionLabel={getClinicalFollowUpLabel}
                  onChange={handleClinicalFollowUpChange}
                  disabled={!clinicalFollowUpListData || !clinicalFollowUpListData.items}
                  value={
                    value
                      ? clinicalFollowUpListData?.items?.find(
                          (item: ClinicalFollowUpAccountSettingsItem) => item.followUpId === value
                        ) || []
                      : null
                  }
                  filterOptions={filterClinicalFollowUpOptions}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.followUpId} value={option.followUpId}>
                        {getClinicalFollowUpLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.clinicalFollowUpId}
                      helperText={errors.clinicalFollowUpId?.message}
                      margin="normal"
                      fullWidth
                      label={t(
                        'account.manageAccounts.accountForm.clinicalFollowUps.form.clinicalFollowUp'
                      )}
                    />
                  )}
                />
              )}
            />
          </StyleFormItem>
        </Box>
      </RoundedContainer>

      {!sortedFollowUpData?.length ? (
        <RoundedContainer sx={{ mt: 2, p: 2 }}>
          <Typography>
            {t(
              'account.manageAccounts.accountForm.clinicalFollowUps.form.error.accountFollowUpsNotFound'
            )}
          </Typography>
        </RoundedContainer>
      ) : isFetching ? (
        <Box sx={{ my: 2 }}>
          <Loading />
        </Box>
      ) : !fields?.length ? (
        <RoundedContainer sx={{ mt: 2, p: 2 }}>
          <Typography>
            {t(
              'account.manageAccounts.accountForm.clinicalFollowUps.form.error.nextStepActionNotFound'
            )}
          </Typography>
        </RoundedContainer>
      ) : (
        automaticStates && (
          <ClinicalFollowUpsNextStepsForm
            nextStepActionsData={nextStepActionsData}
            fields={fields}
            automaticStates={automaticStates}
            errors={errors}
            control={control}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
          ></ClinicalFollowUpsNextStepsForm>
        )
      )}
      <Box
        sx={{
          width: '100%',
          float: 'right',
          p: '0 1rem',
        }}
      >
        {!matches && <Divider sx={{ py: 1 }} />}
        {pageErrors?.length > 0 &&
          pageErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ))}

        {isSuperAdmin() && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: matches ? 0 : 3,
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={getValues('clinicalFollowUpNextStep').length === 0}
              sx={{
                width: matches ? '100%' : '30%',
                my: 2,
                py: 2,
              }}
            >
              {t('account.manageAccounts.accountForm.clinicalFollowUps.form.submit')}
            </Button>
          </Box>
        )}
      </Box>
      <PopupModal
        isOpen={isOpenPopup}
        contentPopup={contentPopup}
        onClick={
          clinicAccountIdTemp ? confirmUnSavedWhenChangeAccount : confirmUnSavedWhenChangeFollowUp
        }
      />
      {clinicAccountId && followUpId && (
        <LocalPopupModal
          isOpen={isSignPopup}
          contentPopup={signContentPopup}
          onClose={closeSignPopup}
          maxWidth={'md'}
          fullWidth={true}
        >
          <ClinicalFollowUpsSignatureForm
            clinicAccountId={clinicAccountId}
            clinicalFollowUpId={followUpId}
            onFormSubmitCallback={onFormSubmitCallback}
          />
        </LocalPopupModal>
      )}
    </Box>
  );
}
