import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { convertCopyRight, parseLegal } from '../../../common/utils/reportUtils';
import { AssessmentReport } from '../../../types/ReportType';
import ReportCoverPage from '../reportModules/ReportCoverPage';
import ReportModulePage from '../reportModules/ReportModulePage';
import ReportNextSteps from '../reportModules/ReportNextSteps';
import ReportNotes from '../reportModules/ReportNotes';
import ReportPageBreak from '../reportModules/ReportPageBreak';
import ReportSummaryPage from '../reportModules/ReportSummaryPage';

export default function MINIScreenerMonitorReportPage({
  logoUrl,
  moduleQuestions,
  assessmentResult: interviewResult,
  patient,
  interviewer,
  assessment: interview,
  nextSteps,
  questionNotes,
}: AssessmentReport) {
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          pageBreakInside: 'avoid',
          rowGap: 4,
        }}
      >
        <ReportCoverPage
          logo={logoUrl}
          result={interviewResult}
          patient={patient}
          interviewer={interviewer}
          interview={interview}
          isInterview
        />

        {interview?.legal && (
          <Typography
            fontSize={'12px'}
            fontWeight={500}
            dangerouslySetInnerHTML={{
              __html: parseLegal(interview.legal),
            }}
          ></Typography>
        )}
      </Box>
      {!!interviewResult?.reasonForEdit && (
        <ReportSummaryPage
          assessmentResult={interviewResult}
          patient={patient}
          interview={interview}
          interviewer={interviewer}
          moduleQuestions={moduleQuestions}
        />
      )}

      {nextSteps && nextSteps.length > 0 && <ReportNextSteps nextSteps={nextSteps} />}

      <ReportPageBreak />
      <RoundedContainer sx={{ my: 2, py: 2 }}>
        <Typography variant="h2" fontWeight={600} textAlign={'center'}>
          {t('assessmentReport.reportModule.testResults')}
        </Typography>
        {moduleQuestions?.map((module: any) => (
          <ReportModulePage
            key={module?.id}
            moduleTitle={interview?.title}
            questionAnswers={module?.questionAnswers}
            questionScores={module?.questionScores}
            moduleType={interview?.reportType}
            subTitle={interview?.subTitle}
            assessmentName={interview?.name}
          />
        ))}
      </RoundedContainer>

      {Object.values(questionNotes)[0] && (
        <ReportNotes reportNotes={Object.values(questionNotes)[0]} />
      )}

      <Typography
        my={2}
        variant="body1"
        textAlign="left"
        dangerouslySetInnerHTML={{
          __html: convertCopyRight(interview?.copyright),
        }}
      />
    </>
  );
}
