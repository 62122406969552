import { ClinicAccountItems } from '../../types/ClinicAccountType';

export const getAutoIntakePacket = (
  accounts: ClinicAccountItems[] | undefined,
  clinicAccountId: string
) => accounts?.find((account) => account.id === clinicAccountId)?.automateIntakePacket;

export const getAccountInterviewTypes = (
  accounts: ClinicAccountItems[] | undefined,
  clinicAccountId: string
) => {
  let interviewTypes =
    accounts?.find((account) => account.id === clinicAccountId)?.accountInterviewTypes || [];
  return interviewTypes.slice().sort((a, b) => a.order - b.order);
};

export const getEhrEnabled = (
  accounts: ClinicAccountItems[] | undefined,
  clinicAccountId: string
) => accounts?.find((account) => account.id === clinicAccountId)?.ehrEnabled;
