import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../app/hooks';
import { InterviewQuestionProps, StsEventFormType } from '../../../types/InterviewQuestionTypes';
import { selectStsEventRelativeQuestion } from '../interviewSlice';
import StsEventForm from './eventComponents/StsEventForm';

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

const dividerComponent = <Divider sx={{ my: 2 }} />;

export default function StsEventQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
}: InterviewQuestionProps) {
  const [stsEventValue, setEventValue] = useState<any>([]);
  const [numberEventForm, setNumberForm] = useState<number>(0);
  const [formSavedValue, setFormSavedValue] = useState<StsEventFormType[]>([]);
  const valueQuestionRelative = useAppSelector<any>(selectStsEventRelativeQuestion);
  const savedValue = field?.value ? JSON.parse(field?.value) : '';
  const shouldRequestInterviewRef = useRef<boolean>(true);

  const numberOfForm = valueQuestionRelative[question?.name!.slice(0, 2)];

  const callBackData = (data: any) => {
    let temp = [];
    const checkExistValue = stsEventValue.some((item: any) => {
      return item.formKey === data.formKey;
    });

    if (!checkExistValue) {
      temp = [...stsEventValue, data];
    } else {
      temp = stsEventValue.map((item: any) => {
        if (item.formKey === data.formKey) {
          return data;
        }
        return item;
      });
    }
    setEventValue([...temp]);
  };

  useEffect(() => {
    if (savedValue || savedValue.length !== 0) {
      if (shouldRequestInterviewRef.current) {
        shouldRequestInterviewRef.current = false;
        setNumberForm(savedValue.length);
        setFormSavedValue(savedValue);
        const savedValueWithKeys = savedValue.map((value: StsEventFormType, index: number) => ({
          formKey: index + 1,
          ...value,
        }));
        setEventValue([...stsEventValue, ...savedValueWithKeys]);
      }
    }
  }, [savedValue, savedValue.length, stsEventValue]);

  useEffect(() => {
    setNumberForm(numberOfForm);
  }, [numberOfForm]);

  useEffect(() => {
    const submitValue = stsEventValue.map((item: any) => ({
      date: item.date,
      how: item.how,
      scaleValue: item.scaleValue,
      level: item.level,
    }));
    setValue(field?.name!, JSON.stringify(submitValue));
  }, [field, setValue, stsEventValue]);

  const renderQuestion = (numberEventForm: number) => {
    const question = [];
    for (let i = 1; i <= numberEventForm; i++) {
      question.push(
        <Box key={i}>
          <StsEventForm
            formKey={i}
            error={error}
            callBackData={callBackData}
            savedAnswer={formSavedValue[i - 1]}
          />
          {i !== numberEventForm && dividerComponent}
        </Box>
      );
    }
    return question;
  };

  return (
    <Box sx={{ mt: 2 }}>
      {question.name && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: `${question.name}:`,
          }}
        ></StyledTypography>
      )}
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <Typography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></Typography>
      <StyledFormControl error={error} variant="standard">
        {renderQuestion(numberEventForm)}
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </StyledFormControl>
    </Box>
  );
}
