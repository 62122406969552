import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useToast } from '../../app/toast';
import DataList from '../../common/components/DataList';
import DataTable from '../../common/components/DataTable';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { Role } from '../../common/utils/loginRole';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { selectUser } from '../auth/authSlice';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useGetUnlockIPAddressesQuery, useGetUnlockUsersQuery, useUnlockUsersMutation } from '../../app/services/account';

interface UnlockUserInfo {
  userId: number;
  userName: string;
  email: string;
  clinicAccountName: string;
  checked: boolean;
}

interface UnlockIPInfo {
  loginAttemptId: number;
  ip: string;
  checked: boolean;
}

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.xPalette.completed.color,
  },
}));

export default function UnlockUserPage({ breadcrumbs }: PageProps) {
  const contentPopup = useAppSelector(selectContentPopup);
  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const { t } = useTranslation();
  const theme = useTheme();
  const shouldRenderDesktopData = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [disabledButton, setDisabledButton] = useState(true);
  const tableRef = useRef<HTMLDivElement>(null);
  const [unlockUserIds, setUnlockUserIds] = useState<number[]>([]);
  const [unlockIPLoginAttemptIds, setUnlockIPLoginAttemptIds] = useState<number[]>([]);
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const currentUser = useAppSelector(selectUser);

  // Query data
  const { data: unlockUsersData,
    isLoading: isLoadingUnlockUser,
    refetch: refetchUnlockUsers
   } = useGetUnlockUsersQuery();

   const { data: unlockIPsData,
    isLoading: isLoadingUnlockIP,
    refetch: refetchUnlockIPs
   } = useGetUnlockIPAddressesQuery();

  const isLoading = isLoadingUnlockUser || isLoadingUnlockIP;

  const handleRefetchData = () => {
    refetchUnlockUsers();
    refetchUnlockIPs();
  };
  interface PopupContent {
    title: string;
    description: string;
    btnOk: string;
    btnClose: string;
    toastMessage: string;
    hidePatient?: boolean;
  }

  const unlockUserContentPopup = {
    title: t('unlock.unlockUser.title'),
    description: t('unlock.unlockUser.description'),
    btnOk: t('unlock.unlockUser.btnOk'),
    btnClose: t('unlock.unlockUser.btnClose'),
    toastMessage: t('unlock.unlockUser.toastMessage')
  } as PopupContent;

  const showUnlockUserPopup = async () => {
    dispatch(setContentPopup({ content: unlockUserContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
  };

  const [unlockUser] = useUnlockUsersMutation();

  const onUnlockUser = async (successCallback?: () => void) => {
    try {
      await unlockUser({ userIds: unlockUserIds, loginAttemptIds: unlockIPLoginAttemptIds}).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('unlock.unlockUser.toastMessage'), 'success');
      setUnlockUserIds([]);
      setUnlockIPLoginAttemptIds([]);
      setDisabledButton(true);
      handleRefetchData();
    } catch (e) {
      toast.publish(t('unlock.unlockUser.toastMessageFail'), 'error');
    }
  };

  const renderUserDesktopData = (data: UnlockUserInfo[]) => {
    return (
      <DataTable
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
        }}
        headers={
          currentUser.roleId === Role.SuperAdmin ?
          [
            { text: t('unlock.unlockUserInfo.userName'), sx: { width: '30%'}},
            { text: t('unlock.unlockUserInfo.email'), sx: { width: '30%'}},
            { text: t('unlock.unlockUserInfo.clinicAccountName'), sx: { width: '30%'}},
            { text: t('unlock.unlockUserInfo.action'), sx: { width: '10%'}}
          ] :
          [
            { text: t('unlock.unlockUserInfo.userName'), sx: { width: '45%'}},
            { text: t('unlock.unlockUserInfo.email'), sx: { width: '45%'}},
            { text: t('unlock.unlockUserInfo.action'), sx: { width: '10%'}}
          ]
        }
        items={
          data.map((item) => {
            if(currentUser.roleId === Role.SuperAdmin) {
              return [
                { component: <Typography sx={{ textAlign: 'left'}}>
                  {item.userName}
                  </Typography> 
                },
                { component: <Typography sx={{ textAlign: 'left'}}>
                  {item.email}
                  </Typography> 
                },
                { component: <Typography sx={{ textAlign: 'left'}}>
                  {item.clinicAccountName}
                  </Typography> 
                },
                { component: <Typography sx={{ textAlign: 'left'}}>
                  {renderCheckBox(item, undefined)}
                  </Typography> 
                }
              ] 
            } else {
              return [
                { component: <Typography sx={{ textAlign: 'left'}}>
                  {item.userName}
                  </Typography> 
                },
                { component: <Typography sx={{ textAlign: 'left'}}>
                  {item.email}
                  </Typography> 
                },
                { component: <Typography sx={{ textAlign: 'left'}}>
                  {renderCheckBox(item, undefined)}
                  </Typography> 
                }
              ];
            }
          })
        }
      />
    );
  };

  const renderIPDesktopData = (data: UnlockIPInfo[]) => {
    return (
      <DataTable
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
        }}
        headers={
          [
            { text: t('unlock.unlockUserInfo.ip'), sx: { width: '90%'}},
            { text: t('unlock.unlockUserInfo.action'), sx: { width: '10%'}}
          ]
        }
        items={
          data.map((item) => {
            return [
              { component: <Typography sx={{ textAlign: 'left'}}>
                {item.ip}
                </Typography> 
              },
              { component: <Typography sx={{ textAlign: 'left'}}>
                {renderCheckBox(undefined, item)}
                </Typography> 
              }
            ];
          })
        }
      />
    );
  };

  const listItemsFilterPhone = (item: UnlockUserInfo) => {
    return [
      [t('unlock.unlockUserInfo.email'), item.email],
      [t('unlock.unlockUserInfo.clinicAccountName'), item.clinicAccountName]
    ];
  };

  const renderCheckBox = (userInfo?: UnlockUserInfo, ipInfo?: UnlockIPInfo) => {
    return (
       <StyledCheckBox
        key={`row-checkbox-${userInfo ? userInfo.userId.toString() : ipInfo ? ipInfo.loginAttemptId.toString() : ''}`}
        id={userInfo ? userInfo.userId.toString() : ipInfo ? ipInfo.loginAttemptId.toString() : ''}
        name={userInfo ? userInfo.userId.toString() : ipInfo ? ipInfo.loginAttemptId.toString() : ''}
        defaultChecked={false}
        value={userInfo ? userInfo.userId : ipInfo ? ipInfo.loginAttemptId : undefined}
        onChange={userInfo ? handleChangeUnlockUser : ipInfo ? handleChangeUnlockIP : undefined}
        inputProps={{ 'aria-label': 'controlled'}}
      />
    );
  };

  const handleChangeUnlockUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.value;
    const checked = event.target.checked;
    let data = []; 
    if(checked){
      if(unlockUserIds && unlockUserIds.length > 0){
        for(let i = 0; i < unlockUserIds.length; i++){
           data.push(unlockUserIds[i]);
        }
      }
      data.push(parseInt(checkedValue));
      setUnlockUserIds(data);
    } else {
      if(unlockUserIds && unlockUserIds.length > 0){
      for(let i = 0; i < unlockUserIds.length; i++){
         if(unlockUserIds[i] !== parseInt(checkedValue)){
           data.push(unlockUserIds[i]);
         }
        }
      }
      setUnlockUserIds(data);
    }
    if((data && data.length > 0) || (unlockIPLoginAttemptIds && unlockIPLoginAttemptIds.length > 0)){
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleChangeUnlockIP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.value;
    const checked = event.target.checked;
    let data = []; 
    if(checked){
      if(unlockIPLoginAttemptIds && unlockIPLoginAttemptIds.length > 0){
        for(let i = 0; i < unlockIPLoginAttemptIds.length; i++){
           data.push(unlockIPLoginAttemptIds[i]);
        }
      }
      data.push(parseInt(checkedValue));
      setUnlockIPLoginAttemptIds(data);
    } else {
      if(unlockIPLoginAttemptIds && unlockIPLoginAttemptIds.length > 0){
      for(let i = 0; i < unlockIPLoginAttemptIds.length; i++){
         if(unlockIPLoginAttemptIds[i] !== parseInt(checkedValue)){
           data.push(unlockIPLoginAttemptIds[i]);
         }
        }
      }
      setUnlockIPLoginAttemptIds(data);
    }
    if((unlockUserIds && unlockUserIds.length > 0) || (data && data.length > 0)){
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const renderUserPhoneData = (data: UnlockUserInfo[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item) => ({
          leftHeader: (
            <Box sx={{ display: 'flex', fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '150%',
              paddingLeft: '16px'}}>
              {item.userName}
            </Box>
          ),
          rightHeader: renderCheckBox(item, undefined),
          items: listItemsFilterPhone(item)
        }))}
        hideDividerBottum={true}
      />
    );
  };

  const renderIPPhoneData = (data: UnlockIPInfo[]) => {
    let rowIndex = 0;
    return (
      <RoundedContainer
        sx={{ mt: 2, px: 0 }}
      >
        {data.map((row) => (
        <Box key={`row-ip-master-${rowIndex++}`}>  
          <Box
            key={`row-ip-${rowIndex++}`}
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 1,
              height: 'auto',
              [theme.breakpoints.up('sm')]: {
                px: 0,
              },
            })}
          >
            <Box 
              key={`ip-${rowIndex++}`}
              sx={{ display: 'flex', fontFamily: 'Mulish',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '150%',
                paddingLeft: '16px' }}>
              {row.ip}
            </Box>
            {renderCheckBox(undefined, row)}
          </Box>
        {rowIndex < data.length && <Divider key={`divider-ip-${rowIndex++}`}/>}
      </Box>  
      ))}
      </RoundedContainer>
    );
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('unlock.unlockUserInfo.title'))}</title>
      </Helmet>
      <Container maxWidth="xl">
        <Box
          key={'tableRef-user'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            py: 2,
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          ref={tableRef}
        >
          <PageHeader
            sx={{ flexShrink: 0 }}
            headerText={t('unlock.unlockUserInfo.title')}
            backUrl="/dashboard"
          />
        </Box>
        </Container>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Box ref={tableRef}>
              {unlockUsersData && unlockUsersData.unlockUsers && (
                <>
                  <Box sx={{ width: '100%' }}>
                    {unlockUsersData.unlockUsers.length === 0 ? (
                      <RoundedContainer sx={{ mt: 2, p: 2 }}>
                        <Typography>{t('unlock.unlockUserInfo.unlockUserNotFound')}</Typography>
                      </RoundedContainer>
                    ) : (
                      <>
                        {shouldRenderDesktopData
                          ? renderUserDesktopData(unlockUsersData.unlockUsers)
                          : renderUserPhoneData(unlockUsersData.unlockUsers)}
                      </>
                    )}
                    {shouldRenderDesktopData && unlockUsersData.unlockUsers.length > 0 && <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          py: 2,
                          borderBottomLeftRadius: '15px',
                          borderBottomRightRadius: '15px',
                          background: theme.xPalette.white,
                        }}
                      />}
                  </Box>
                </>  
              )}
            </Box>
            <>
              <Box
                key={'tableRef-ip'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    py: 2,
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    },
                  }}
                ref={tableRef}
              >
                <Box
                  key={'titleIp'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '16px'
                  }}
                >
                  <Typography variant="h1">{t('unlock.unlockUserInfo.titleIp')}</Typography>
                </Box>
              </Box>
              <Box ref={tableRef}>
                {unlockIPsData && unlockIPsData.unlockIPs && (
                  <>
                    <Box sx={{ width: '100%' }}>
                      {unlockIPsData.unlockIPs.length === 0 ? (
                        <RoundedContainer sx={{ mt: 2, p: 2 }}>
                          <Typography>{t('unlock.unlockUserInfo.unlockIPNotFound')}</Typography>
                        </RoundedContainer>
                      ) : (
                        <>
                          {shouldRenderDesktopData
                            ? renderIPDesktopData(unlockIPsData.unlockIPs)
                           : renderIPPhoneData(unlockIPsData.unlockIPs)}
                        </>
                      )}
                      {shouldRenderDesktopData && unlockIPsData.unlockIPs.length > 0 && <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          py: 2,
                          borderBottomLeftRadius: '15px',
                          borderBottomRightRadius: '15px',
                          background: theme.xPalette.white,
                        }}
                      />}
                    </Box>
                  </>
                )}
              </Box>
            </>
            <Box
                sx={{
                  width: '100%',
                  float: 'right',
                  pb: 3,
                  paddingLeft: '16px',
                  paddingRight: shouldRenderDesktopData ? '0px':'16px'
                }}
              >
              {((unlockUsersData && unlockUsersData.unlockUsers && unlockUsersData.unlockUsers.length > 0) ||
              (unlockIPsData && unlockIPsData.unlockIPs && unlockIPsData.unlockIPs.length > 0)) && <Button
                type="button"
                disabled={disabledButton}
                fullWidth
                variant="contained"
                sx={{
                  width: matches ? '100%' : '24%',
                  my: 2,
                  py: 2,
                  float: 'right'
                }}
                onClick={() => {showUnlockUserPopup()}}
              >
                {t('unlock.unlockUserInfo.button')}
              </Button>}
            </Box>
          </>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={onUnlockUser}
        />
      
    </DashboardLayout>
  );
}
