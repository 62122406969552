import { buildAccountUrl, QueryCacheDuration, rootApi } from '..';
import { DeleteParam } from '../../../types/CommonType';
import {
  ObjectiveAddRequest,
  ObjectiveAddResponse,
  ObjectiveEditRequest,
  ObjectiveEditResponse,
  ObjectiveItem,
  ObjectiveListReponse,
  ObjectiveListRequest,
} from '../../../types/treatment-plan/ObjectiveType';

const api = rootApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getObjectiveList: builder.query<ObjectiveListReponse, ObjectiveListRequest>({
      query: (params) => ({
        url: buildAccountUrl('/v1/account/treatment-objective'),
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    deleteObjective: builder.mutation<void, DeleteParam>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-objective/${params.id}`),
        method: 'DELETE',
      }),
    }),

    getObjectiveData: builder.query<{ data: ObjectiveItem }, { id: number }>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-objective/${params.id}`),
        method: 'GET',
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    addObjective: builder.mutation<ObjectiveAddResponse, ObjectiveAddRequest>({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-objective`),
        method: 'POST',
        body,
      }),
    }),

    editObjective: builder.mutation<ObjectiveEditResponse, ObjectiveEditRequest>({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-objective/${body.id}`),
        method: 'PUT',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetObjectiveListQuery,
  useLazyGetObjectiveListQuery,
  useDeleteObjectiveMutation,
  useGetObjectiveDataQuery,
  useAddObjectiveMutation,
  useEditObjectiveMutation,
} = api;
