import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function GridAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const { questionDisplay, answerValue } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  const renderGridAnswer = () => {
    const completedRows: string[] = [];
    return (
      <Table>
        {questionDisplay.sections.map((section: any, sectionIdx: any) => (
          <React.Fragment key={sectionIdx}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Factor</TableCell>
                {questionDisplay.options.map((option: any, optionIdx: any) => (
                  <TableCell
                    key={optionIdx}
                    sx={{ width: '20px', padding: '8px', textAlign: 'center' }}
                  >
                    <Typography
                      fontSize={'14px'}
                      fontWeight={500}
                      dangerouslySetInnerHTML={{
                        __html: option,
                      }}
                    ></Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>{section.title}</TableCell>
              </TableRow>
              {section.questions.map((gridQuestion: any) => {
                completedRows.push(gridQuestion.title);
                const selectedOption: any = answerValue[gridQuestion.title] || {};

                return (
                  <TableRow key={gridQuestion.title}>
                    <TableCell>{gridQuestion.title}</TableCell>
                    <TableCell>
                      {gridQuestion.text} {selectedOption.factor || ''}
                    </TableCell>
                    {questionDisplay.options.map((_option: any, optionIdx: any) => {
                      const selected =
                        parseInt(selectedOption.value) === optionIdx ||
                        parseInt(selectedOption) === optionIdx;

                      return (
                        <TableCell key={optionIdx}>
                          <Box
                            sx={{
                              border: `1px solid ${
                                selected ? theme.xPalette.white : theme.xPalette.shadeGrey
                              }`,
                              height: '24px',
                              width: '24px',
                              borderRadius: '4px',
                              backgroundColor: selected
                                ? theme.palette.secondary.main
                                : theme.xPalette.noteLightGrey,
                              marginLeft: '8px',
                            }}
                          >
                            {selected ? (
                              <CheckIcon sx={{ color: 'white', fontSize: '1.4rem' }} />
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </React.Fragment>
        ))}
      </Table>
    );
  };

  return (
    <Box
      key={questionAnswer.questionId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        columnGap: 1,
        width: '100%',
        my: 2,
      }}
    >
      <Box
        sx={{
          width: 'fit-content',
          overflow: 'auto',
          position: 'relative',
          right: '2%',
        }}
      >
        {renderGridAnswer()}
      </Box>
    </Box>
  );
}
