import { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import DataList from '../../common/components/DataList';
import { Role } from '../../common/utils/loginRole';
import { csvToArray } from '../../common/utils/stringUtils';
import {
  validateEmail,
  validatePhoneNumber,
  maximumImportFileUpload,
} from '../../common/utils/validationUtils';
import { ClinicAccountItems } from '../../types/ClinicAccountType';
import { ResponseError } from '../../types/ResponseError';
import {
  ImportUserRequest,
  ImportUser,
  ErrorDataImportUser,
  ImportUserResponse,
} from '../../types/UserTypes';
import { selectUser } from '../auth/authSlice';

interface ImportUserFormProps {
  siteData?: any;
  clinicAccounts?: ClinicAccountItems[];
  onFormSubmit: (data: ImportUserRequest, successCallback?: () => void) => Promise<void>;
}

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  '& .MuiOutlinedInput-root': {
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: 'rgb(254, 239, 238)',
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

export default function ImportForm({
  siteData,
  clinicAccounts,
  onFormSubmit,
}: ImportUserFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const inputImportUser = useRef<HTMLInputElement>(null);
  const [userFile, setUserFile] = useState<any>(null);
  const [dataFile, setDataFile] = useState<string>('');
  const [dataClinicAccount, setDataClinicAccount] = useState<string>('');
  const [isShowErrorAccount, setIsShowErrorAccount] = useState<boolean>(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const currentUser = useAppSelector(selectUser);
  const [errorData, setErrorData] = useState<ErrorDataImportUser[]>([]);
  const [importFileName, setImportFileName] = useState<string>('');

  const tableHeaders: string[] = Object.values(
    t('user.manageUsers.importUserTableHeader', {
      returnObjects: true,
    })
  );

  const { control, handleSubmit, setValue } = useForm<ImportUserResponse>({});

  const handleImportUser = () => {
    if (!dataClinicAccount) {
      setIsShowErrorAccount(true);
    } else {
      inputImportUser?.current?.click();
    }
  };

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getAccountOptionLabel(option),
  });

  const getAccountOptionLabel = (option: any) => `${option.name} (${option.id})`;

  useEffect(() => {
    setPageErrors([]);
    setErrorData([]);
    if (userFile) {
      if (userFile && !dataClinicAccount) {
        setPageErrors([t('user.manageUsers.error.blankClinicAccount')]);
      }
      const read = new FileReader();
      read.readAsBinaryString(userFile);
      setImportFileName(userFile.name);
      if (userFile.type && !userFile.type.includes('csv') && !userFile.type.includes('excel')) {
        setPageErrors([t('user.manageUsers.error.invalidFileFormat')]);
        return;
      }
      read.onloadend = () => {
        setDataFile(read.result as string);
      };
    }
  }, [userFile, t, dataClinicAccount]);

  useEffect(() => {
    if (currentUser.roleId !== Role.SuperAdmin && clinicAccounts) {
      setValue('clinicAccount', clinicAccounts[0].id);
      setDataClinicAccount(clinicAccounts[0].id);
    }
  }, [clinicAccounts, currentUser.roleId, setValue]);

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccount', selectedValue);
    setDataClinicAccount(selectedValue);
    setPageErrors([]);
    setErrorData([]);
    if (!selectedValue) {
      setIsShowErrorAccount(true);
    } else {
      setIsShowErrorAccount(false);
    }
  };

  const extractErrorMessage = (message: string | null, errorMessage: string) =>
    message ? `${message}. ${errorMessage}` : errorMessage;

  const extractDataImportUser = () => {
    const users = csvToArray(dataFile);
    let errorMessages: ErrorDataImportUser[] = [];
    const maxLength = 1024;
    const minLength = 4;
    const maxLengthPhoneNumber = 16;

    if (users && users.length < 2) {
      setPageErrors([t('user.manageUsers.error.fileImportEmpty')]);
      return { errorMessages: [], importUsers: [] };
    }

    const headers = users[0];
    const siteIndex = headers.indexOf('Site');
    const firstNameIndex = headers.indexOf('First Name');
    const lastNameIndex = headers.indexOf('Last Name');
    const emailIndex = headers.indexOf('Email');
    const phoneIndex = headers.indexOf('Phone');
    const cellPhoneIndex = headers.indexOf('Cell Phone');
    const personalIdIndex = headers.indexOf('TARA Personal ID');
    const duplicateEmails = {};
    const duplicatePersonalIdCodes = {};

    const importUsers = users
      .filter((user, index) => index)
      .map((user, index) => {
        let errorDescription = null;
        if (!user[siteIndex]) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.blankSiteName')
          );
        }
        if (user[siteIndex] && user[siteIndex].length > maxLength) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.siteNameTooLong', { maxLength })
          );
        }

        if (user[siteIndex] && siteData && siteData.length) {
          const existSiteNames = [];
          for (let i = 0; i < siteData.length; i++) {
            if (
              siteData[i].siteName &&
              siteData[i].clinicAccountId &&
              dataClinicAccount === siteData[i].clinicAccountId
            ) {
              existSiteNames.push(siteData[i].siteName.toLowerCase());
            }
          }
          if (!existSiteNames.includes(user[siteIndex].toLowerCase())) {
            errorDescription = extractErrorMessage(
              errorDescription,
              t('user.manageUsers.error.siteDoesNotExist')
            );
          }
        }

        if (!user[firstNameIndex]) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.blankFirstName')
          );
        }
        if (user[firstNameIndex] && user[firstNameIndex].length > maxLength) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.firstNameTooLong', { maxLength })
          );
        }

        if (!user[lastNameIndex]) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.blankLastName')
          );
        }
        if (user[lastNameIndex] && user[lastNameIndex].length > maxLength) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.lastNameTooLong', { maxLength })
          );
        }

        if (!user[emailIndex]) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.blankEmail')
          );
        } else if (!validateEmail(user[emailIndex])) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.invalidEmail')
          );
        }

        if (user[emailIndex]) {
          if (duplicateEmails[user[emailIndex]]) {
            errorDescription = extractErrorMessage(
              errorDescription,
              t('user.manageUsers.error.duplicateEmail')
            );
          } else {
            duplicateEmails[user[emailIndex]] = user[emailIndex];
          }
        }

        if (user[personalIdIndex]) {
          if (duplicatePersonalIdCodes[user[personalIdIndex]]) {
            errorDescription = extractErrorMessage(
              errorDescription,
              t('user.manageUsers.error.duplicatePersonalIdCode')
            );
          } else {
            duplicatePersonalIdCodes[user[personalIdIndex]] = user[personalIdIndex];
          }
        }

        if (!user[phoneIndex] && !user[cellPhoneIndex]) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.mustInputEitherPhoneOrCellPhone')
          );
        }
        if (user[phoneIndex] && !validatePhoneNumber(user[phoneIndex])) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.phoneNumberTooShort', { minLength })
          );
        }
        if (user[phoneIndex] && user[phoneIndex].length > maxLengthPhoneNumber) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.phoneTooLong', { maxLength: maxLengthPhoneNumber })
          );
        }

        if (user[cellPhoneIndex] && !validatePhoneNumber(user[cellPhoneIndex])) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.cellPhoneTooShort', { minLength })
          );
        }
        if (user[cellPhoneIndex] && user[cellPhoneIndex].length > maxLengthPhoneNumber) {
          errorDescription = extractErrorMessage(
            errorDescription,
            t('user.manageUsers.error.cellPhoneTooLong', { maxLength: maxLengthPhoneNumber })
          );
        }

        if (errorDescription) {
          errorMessages.push({
            line: index + 1,
            errorDescription,
          });
        }

        const importUser = {
          siteName: user[siteIndex],
          firstName: user[firstNameIndex],
          lastName: user[lastNameIndex],
          email: user[emailIndex],
          phone: user[phoneIndex],
          cellPhone: user[cellPhoneIndex],
          personalIdCode: user[personalIdIndex],
        } as ImportUser;

        return importUser;
      });
    return { errorMessages, importUsers };
  };

  const onSubmit = async (data: ImportUserResponse) => {
    setPageErrors([]);
    setErrorData([]);
    let pageErrors: string[] = [];

    if (userFile && userFile.size / (1024 * 1024) > maximumImportFileUpload) {
      pageErrors.push(
        t('user.manageUsers.error.importFileLargeThanMaximum', {
          maximumFileUpload: maximumImportFileUpload,
        })
      );
    }
    if (!dataFile) {
      pageErrors.push(t('user.manageUsers.error.blankImportFile'));
    }
    if (!data.clinicAccount) {
      pageErrors.push(t('user.manageUsers.error.blankAccount'));
    }

    if (pageErrors.length) {
      setPageErrors(pageErrors);
      return;
    }
    const { errorMessages, importUsers } = extractDataImportUser();
    const dataErrors = errorMessages as ErrorDataImportUser[];

    if (dataErrors.length) {
      setErrorData(dataErrors);
    } else {
      const submitData = {
        clinicAccountId: data.clinicAccount,
        importUsers,
      } as ImportUserRequest;

      try {
        await onFormSubmit(submitData, () => {
          setPageErrors([]);
        });
      } catch (e) {
        const {
          data: { error },
        } = e as ResponseError;
        setPageErrors(error);
      }
    }
  };

  const renderDesktopData = (data: ErrorDataImportUser[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => {
                return <StyledTableCell key={`${header} ${index}`}>{header}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: ErrorDataImportUser, index) => {
              return (
                <TableRow key={index}>
                  <StyledTableCell sx={{ wordBreak: 'break-all', minWidth: '300px' }}>
                    {item.line}
                  </StyledTableCell>
                  <StyledTableCell sx={{ wordBreak: 'break-word', minWidth: '160px' }}>
                    {item.errorDescription}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: ErrorDataImportUser[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: ErrorDataImportUser) => {
          const listItems = [
            [<>{t('user.manageUsers.importUserTableHeader.line')}</>, <>{item.line}</>],
            [
              <>{t('user.manageUsers.importUserTableHeader.errorDescription')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.errorDescription}</Box>,
            ],
          ];
          return {
            leftHeader: (
              <Typography variant="body1" fontWeight="bold" fontSize={20}>
                {`${t('user.manageUsers.importUserTableHeader.line')} ${item.line}`}
              </Typography>
            ),
            rightHeader: <p></p>,
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        px: matches ? 0 : 3,
        pt: 3,
      }}
    >
      <input
        type="file"
        id="file"
        ref={inputImportUser}
        accept=".csv"
        onClick={(event: React.MouseEvent<HTMLInputElement>) => {
          const element = event.target as HTMLInputElement;
          element.value = '';
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event?.target?.files) {
            setUserFile(event.target.files[0]);
          }
        }}
        style={{ display: 'none' }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {currentUser.roleId !== Role.Interviewer && (
          <Controller
            name="clinicAccount"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <StyleAutocomplete
                style={{ width: isDesktop ? '78%' : '100%' }}
                {...props}
                data-testid="mui-component-select-clinic-account"
                options={clinicAccounts || []}
                getOptionLabel={getAccountOptionLabel}
                onChange={handleAccountChange}
                value={value ? clinicAccounts?.find((item) => item.id === value) || null : null}
                filterOptions={filterAccountOptions}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.id} value={option.id}>
                      {getAccountOptionLabel(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    fullWidth
                    label={t('user.manageUsers.userForm.account')}
                    error={isShowErrorAccount}
                    helperText={
                      isShowErrorAccount ? t('user.manageUsers.error.blankClinicAccount') : ''
                    }
                  />
                )}
              />
            )}
          />
        )}

        <Controller
          name="importFile"
          control={control}
          render={() => (
            <Button
              type="button"
              onClick={handleImportUser}
              fullWidth
              variant="contained"
              sx={{
                width: isDesktop ? '20%' : '100%',
                height: isDesktop ? '60px' : '100%',
                my: 2,
                py: 2,
                float: 'right',
              }}
            >
              {t('user.manageUsers.importUserForm.selectFile')}
            </Button>
          )}
        />
        <div style={isDesktop ? { marginLeft: '0%' } : { width: '100%', float: 'right' }}>
          {importFileName && `${t('user.manageUsers.fileName')}: ${importFileName}`}
        </div>
      </Box>
      <Box>
        {pageErrors?.length > 0 &&
          pageErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ))}

        {errorData.length
          ? isDesktop
            ? renderDesktopData(errorData)
            : renderMobileData(errorData)
          : null}

        {!pageErrors.length && !errorData.length && dataFile && dataClinicAccount && (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              width: isDesktop ? '20%' : '100%',
              my: 2,
              py: 2,
              float: 'left',
            }}
          >
            {t('user.manageUsers.importUserForm.import')}
          </Button>
        )}
      </Box>
    </Box>
  );
}
