import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { auditLogSearchApi, useSearchAuditLogsQuery } from '../../app/services/account';
import AuditLogResultInfoCard from '../../common/components/AuditLogResultInfoCard';
import CustomPagination from '../../common/components/CustomPagination';
import DataTable from '../../common/components/DataTable';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { DefaultPageSize } from '../../common/utils/commonUtils';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { saveReportDataToFile } from '../../common/utils/reportUtils';
import { AuditLogItem, AuditLogResponse } from '../../types/AuditLog';
import { PageProps } from '../../types/PageProps';
import ErrorPage from '../error/ErrorPage';

export default function AuditLogResultPage({ breadcrumbs }: PageProps) {
  const { state } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const { audiLogData } = !!state && state;

  const tableRef = useRef<HTMLDivElement>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [displayedAuditLogData, setDisplayedAuditLogData] = useState<AuditLogResponse>();
  const searchOptions = {
    clinicAccountId: searchParams.get('clinicAccountId') || '',
    clinicSiteId: searchParams.get('clinicSiteId') || '',
    userId: searchParams.get('userId') || '',
    patientId: searchParams.get('patientId') || '',
    eventType: searchParams.get('eventType') || '',
    fromDate: searchParams.get('fromDate') || '',
    toDate: searchParams.get('toDate') || '',
    page: searchParams.get('page') || '1',
  };

  const baseParams =
    '?clinicAccountId=:clinicAccountId' +
    '&clinicSiteId=:clinicSiteId' +
    '&userId=:userId' +
    '&patientId=:patientId' +
    '&eventType=:eventType' +
    '&fromDate=:fromDate' +
    '&toDate=:toDate';

  const getNavBackUrl = () => {
    return generatePath(`/dashboard/audit-log/${baseParams}`, searchOptions);
  };
  const newBreadcrumb: BreadcrumbType = {
    text: t('auditLog.title'),
    link: getNavBackUrl() || `/dashboard/audit-log/`,
  };

  const currentPage = parseInt(searchOptions.page);
  // Query data
  const auditLogQuery = {
    clinicAccountId: searchOptions.clinicAccountId,
    clinicSiteId: searchOptions.clinicSiteId,
    userId: searchOptions.userId,
    patientId: searchOptions.patientId,
    eventType: searchOptions.eventType,
    fromDateString: searchOptions.fromDate || undefined,
    toDateString: searchOptions.toDate || undefined,
    timezoneOffset: new Date().getTimezoneOffset(),
    page: currentPage,
    pageSize: DefaultPageSize,
  };
  const {
    data: fetchedAuditLogData,
    error,
    isLoading,
  } = useSearchAuditLogsQuery(!audiLogData ? auditLogQuery : skipToken);

  const showPagingInfo = totalPages > 1;

  useEffect(() => {
    if (!searchOptions.eventType) {
      setPageErrors([t('auditLog.error.noEventType')]);
      setTimeout(() => {
        const url = generatePath(`/dashboard/audit-log`);
        navigate(url, { replace: true });
      }, 3000);
    }
  }, [navigate, searchOptions.eventType, t]);

  useEffect(() => {
    let total = 0;
    if (searchOptions.eventType) {
      if (fetchedAuditLogData) {
        total = fetchedAuditLogData.total;
        setDisplayedAuditLogData(fetchedAuditLogData);
        if (fetchedAuditLogData.auditLogs.length === 0) {
          setPageErrors([t('auditLog.error.noDataFound')]);
        }
      } else if (audiLogData) {
        total = audiLogData.total;
        setDisplayedAuditLogData(audiLogData);
      }
      setTotalPages(Math.ceil(total / DefaultPageSize));
    }
  }, [audiLogData, fetchedAuditLogData, searchOptions.eventType, t]);

  const updateSearchUrl = (updatedData: any) => {
    const params = {
      ...searchOptions,
      ...updatedData,
    };

    const url = generatePath(`/dashboard/audit-log-result/${baseParams}&page=:page`, params);
    navigate(url, { replace: true });
  };

  const onDownLoadReport = async () => {
    const auditLogDownloadDispatcher = dispatch(
      auditLogSearchApi.endpoints.searchAuditLogsForDownload.initiate({
        clinicAccountId: auditLogQuery.clinicAccountId,
        clinicSiteId: auditLogQuery.clinicSiteId,
        userId: auditLogQuery.userId,
        patientId: auditLogQuery.patientId,
        eventType: auditLogQuery.eventType,
        fromDateString: auditLogQuery.fromDateString || undefined,
        toDateString: auditLogQuery.toDateString || undefined,
        timezoneOffset: new Date().getTimezoneOffset(),
      })
    );

    auditLogDownloadDispatcher.then((result) => {
      if (typeof result !== 'undefined') {
        const filenameStartDate = dayjs(auditLogQuery.fromDateString, 'MM/DD/YYYY').format(
          'YYYY-MM-DD'
        );
        const filenameEndDate = dayjs(auditLogQuery.toDateString, 'MM/DD/YYYY').format(
          'YYYY-MM-DD'
        );
        const fileName = `audit_log_${filenameStartDate}_${filenameEndDate}.csv`;
        saveReportDataToFile(result?.data?.csvData, { type: 'text/csv' }, fileName);
      }
    });
  };

  const handlePageChange = (value: any) => {
    const newPage = parseInt(value);
    updateSearchUrl({ page: newPage });
  };

  const getHeader = () => {
    return [
      { text: t('auditLog.items.updatedAt'), sx: { width: '10%' } },
      { text: t('auditLog.items.user'), sx: { width: '10%' } },
      { text: t('auditLog.items.ip'), sx: { width: '10%' } },
      { text: t('auditLog.items.actionType'), sx: { width: '10%' } },
      { text: t('auditLog.items.accountName'), sx: { width: '10%' } },
      { text: t('auditLog.items.siteName'), sx: { width: '10%' } },
      { text: t('auditLog.items.userAffected'), sx: { width: '10%' } },
      { text: t('auditLog.items.patientId'), sx: { width: '10%' } },
      { text: t('auditLog.items.measurement'), sx: { width: '10%' } },
      { text: t('auditLog.items.interviewId'), sx: { width: '10%' } },
    ];
  };

  const getRowData = (item: AuditLogItem) => {
    let affectedUserFullName = '';
    if (!!item.actionToExtID && !item.affectedUserLName && !item.affectedUserFName) {
      affectedUserFullName = item.actionToExtID;
    } else if (item.affectedUserLName || item.affectedUserFName) {
      affectedUserFullName = `${item.affectedUserLName}${
        item.affectedUserLName && item.affectedUserFName ? ', ' : ''
      }${item.affectedUserFName}`;
    } else affectedUserFullName = '';
    return [
      { component: <Typography>{getDateTime(item.updatedAt)}</Typography> },
      { component: <Typography>{`${item.actionByLName}, ${item.actionByFName}`}</Typography> },
      { component: <Typography>{item.ipAddress}</Typography> },
      { component: <Typography>{item.actionName}</Typography> },
      { component: <Typography>{item.accountName}</Typography> },
      { component: <Typography>{item.siteName}</Typography> },
      { component: <Typography>{affectedUserFullName}</Typography> },
      { component: <Typography>{item.patient}</Typography> },
      { component: <Typography>{item.measurement}</Typography> },
      {
        component: (
          <Typography>
            {item.actionRole === 'schedule'
              ? ''
              : item.measurementId ?? item.interviewResult ?? item.trackerResult}
          </Typography>
        ),
      },
    ];
  };

  const getDateTime = (dateTime: string) => {
    const dateTimeArr = dateTime?.split(' ') || []; // original formatted value: 07/11/2022 12:00:33 am
    if (dateTimeArr?.length) {
      const date = dateTimeArr[0];
      dateTimeArr.shift();
      return (
        <>
          {date}
          <br />
          {dateTimeArr.join(' ')}
        </>
      );
    }
    return '';
  };

  const renderDesktopData = (data: AuditLogItem[]) => {
    return (
      <DataTable
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}:first-of-type`]: {
            maxWidth: 'none',
            wordBreak: 'normal',
          },
        }}
        headers={getHeader()}
        items={data.map((item) => getRowData(item))}
      />
    );
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }
  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>{getPageTitle(t('report.usageReport.output'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            py: 2,
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          ref={tableRef}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 3 : 2 }}
            headerText={t('report.usageReport.output')}
            backUrl={getNavBackUrl()}
          />
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Box ref={tableRef}>
              <RoundedContainer sx={{ py: 2, mb: 2 }}>
                <AuditLogResultInfoCard
                  fromDate={searchOptions.fromDate || ''}
                  toDate={searchOptions.toDate || ''}
                  onDownLoadReport={onDownLoadReport}
                  disabled={totalPages === 0}
                />
              </RoundedContainer>

              {displayedAuditLogData && (
                <>
                  <Box sx={{ width: '100%' }}>
                    {displayedAuditLogData?.auditLogs.length > 0 && (
                      <>
                        {renderDesktopData(displayedAuditLogData?.auditLogs)}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            py: showPagingInfo ? 4 : 2,
                            borderBottomLeftRadius: '15px',
                            borderBottomRightRadius: '15px',
                            background: theme.xPalette.white,
                          }}
                        >
                          {showPagingInfo && (
                            <CustomPagination
                              changePage={(e, value) => handlePageChange(value)}
                              currentPage={currentPage}
                              pages={totalPages}
                              pageRef={tableRef}
                            />
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
            {pageErrors &&
              pageErrors.length > 0 &&
              pageErrors.map((error) => (
                <Alert key={error} severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              ))}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
}
