import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/lab/Alert';
import Autocomplete from '@mui/lab/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetBrandSettingQuery, useUpdateBrandSettingMutation } from '../../app/services/account';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import checkNullData from '../../common/utils/checkNullData';
import { commonStyledButton } from '../../common/utils/commonStyles';
import { getPageTitle } from '../../common/utils/pageUtils';
import { BrandSettingResponse } from '../../types/AccountType';
import { PageProps } from '../../types/PageProps';
import { ResponseError } from '../../types/ResponseError';
import ErrorPage from '../error/ErrorPage';

interface BandSettingsType {
  accountId?: string;
  companyName: string;
  callbackUrl: string;
  accessManagementCallbackUrl: string;
  authUserName: string;
  authPassword: string;
  importLocation: string;
  emailAddresses: string[];
  delimiter: string;
  bucketName: string;
}

const defaultValues = {
  companyName: '',
  callbackUrl: '',
  accessManagementCallbackUrl: '',
  authUserName: '',
  authPassword: '',
  importLocation: '',
  emailAddresses: [],
  delimiter: '',
  bucketName: '',
};

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '49%',
    },
  },
}));

const StyleAutocomplete = styled(Autocomplete)(() => ({
  '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    width: '100%',
  },
}));

export default function BrandSettings({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const toast = useToast();
  const { accountId } = useParams();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [showLogoCompany, setShowLogoCompany] = useState<string>('');
  const [uploadImageFile, setUploadImageFile] = useState<File | null>(null);
  const [patientEmailInput, setPatientEmailInput] = useState<string>('');
  const [patientEmailAddresses, setPatientEmailAddresses] = useState<string[]>([]);
  const [updateBrandSetting] = useUpdateBrandSettingMutation();

  const {
    data: brandSettingData,
    error,
    refetch,
  } = useGetBrandSettingQuery(
    accountId
      ? {
          accountId,
        }
      : skipToken
  );

  const validationSchema = yup
    .object({
      companyName: yup.string().trim().required(t('account.manageAccounts.error.blankCompanyName')),
      callbackUrl: yup.string().trim(),
      accessManagementCallbackUrl: yup.string().trim(),
      authUserName: yup.string().trim(),
      authPassword: yup.string().trim(),
      importLocation: yup.string().trim(),
      delimiter: yup.string().trim(),
      bucketName: yup.string().trim(),
      emailAddresses: yup.array(yup.string().email()),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<BandSettingsType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleSetEmail = (value: any) => {
    let uniqueEmail: string[] = [];

    if (Array.isArray(value)) {
      uniqueEmail = Array.from(new Set(value));
    }
    if (!Array.isArray(value) && value) {
      uniqueEmail = Array.from(new Set([...patientEmailAddresses, value]));
    }
    if (!Array.isArray(value) && !value) {
      uniqueEmail = Array.from(new Set([...patientEmailAddresses]));
    }

    setPatientEmailAddresses([...uniqueEmail]);
    setPatientEmailInput('');
    setValue('emailAddresses', uniqueEmail);
  };

  const handleEmailAddressChange = (e: React.SyntheticEvent<EventTarget>, value: any) => {
    handleSetEmail(value);
  };

  const onInputBlur = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    const value = target.value.trim();
    handleSetEmail(value);
  };

  const handleEmailAddressInputChange = (e: React.SyntheticEvent, value: string) => {
    setPatientEmailInput(value);
  };

  const handleFileChange = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    if (target?.files && target?.files?.length > 0) {
      setShowLogoCompany(URL.createObjectURL(target?.files[0]));
      setUploadImageFile(target?.files[0]);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (!!accountId && brandSettingData) {
      const bindEditControls = () => {
        if (mounted) {
          Object.keys(defaultValues).forEach((field: any) => {
            setValue(field, checkNullData(brandSettingData[field as keyof BrandSettingResponse]));
          });

          setPatientEmailAddresses(brandSettingData.emailAddresses);

          if (!!brandSettingData.binaryImage) {
            setShowLogoCompany(`data:image/*;base64,${brandSettingData.binaryImage}`);
          }
        }
      };

      bindEditControls();

      return () => {
        mounted = false;
      };
    }
  }, [accountId, brandSettingData, setValue]);

  const onSubmit = async (data: BandSettingsType) => {
    const formData = new FormData();

    formData.append('accountId', accountId as string);
    Object.keys(data).forEach((setting: string) => {
      if (setting === 'emailAddresses') {
        formData.append(`${setting}`, JSON.stringify(data[setting as keyof BandSettingsType]));
      } else {
        formData.append(`${setting}`, data[setting as keyof BandSettingsType] as string);
      }
    });

    if (uploadImageFile) {
      formData.append('file', uploadImageFile);
    }

    try {
      const res = await updateBrandSetting(formData).unwrap();
      if (res) {
        setPageErrors([]);
        refetch();
        toast.publish(res.message, 'success');
      }
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('account.manageAccounts.brandSettings.title'))}</title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={t('account.manageAccounts.brandSettings.title')}
          backUrl={'/dashboard/accounts'}
        />
      </Container>
      <Container maxWidth="xl" disableGutters={true}>
        <RoundedContainer sx={{ pt: 1, mt: 2 }}>
          <Box sx={{ mt: 2 }} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.companyName}
                    helperText={errors.companyName?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.brandSettings.companyName')}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="callbackUrl"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.callbackUrl}
                    helperText={errors.callbackUrl?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.brandSettings.callBackUrl')}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="authUserName"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.authUserName}
                    helperText={errors.authUserName?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.brandSettings.authUserName')}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="authPassword"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.authPassword}
                    helperText={errors.authPassword?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.brandSettings.authPassword')}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="accessManagementCallbackUrl"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.accessManagementCallbackUrl}
                    helperText={errors.accessManagementCallbackUrl?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.brandSettings.accessManagementCallbackUrl')}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="importLocation"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.importLocation}
                    helperText={errors.importLocation?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.brandSettings.importLocation')}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="emailAddresses"
                control={control}
                render={({ fieldState, formState, field, ...props }) => (
                  <StyleAutocomplete
                    sx={{ width: matches ? '100%' : '49%' }}
                    {...props}
                    multiple
                    freeSolo
                    value={patientEmailAddresses || []}
                    options={[]}
                    inputValue={patientEmailInput}
                    onInputChange={handleEmailAddressInputChange}
                    onChange={handleEmailAddressChange}
                    renderTags={(value: unknown[], getTagProps) => {
                      return value.map((option: unknown, index: number) => (
                        <Chip
                          variant="outlined"
                          label={String(option)}
                          {...getTagProps({ index })}
                        />
                      ));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.emailAddresses}
                        onBlur={onInputBlur}
                        helperText={
                          (!!errors.emailAddresses &&
                            (errors?.emailAddresses as unknown as FieldError)?.message) ||
                          (errors?.emailAddresses &&
                            errors?.emailAddresses?.length > 0 &&
                            t('account.manageAccounts.error.invalidEmail'))
                        }
                        margin="normal"
                        placeholder={t('account.manageAccounts.brandSettings.emailAddresses')}
                        fullWidth
                        label={t('account.manageAccounts.brandSettings.emailAddresses')}
                      />
                    )}
                  />
                )}
              />

              <Controller
                name="delimiter"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.delimiter}
                    helperText={errors.delimiter?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.brandSettings.delimiter')}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="bucketName"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.bucketName}
                    helperText={errors.bucketName?.message}
                    margin="normal"
                    fullWidth
                    label={t('account.manageAccounts.brandSettings.bucketName')}
                    variant="outlined"
                  />
                )}
              />
            </Box>

            <Box sx={{ width: matches ? '100%' : '49%' }}>
              {showLogoCompany && (
                <>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: theme.xPalette.lightGrey,
                      marginTop: '8px',
                      fontSize: '16px',
                    }}
                  >
                    {t('account.manageAccounts.brandSettings.currentCompanyLogo')}
                  </Typography>
                  <ImageList
                    variant="woven"
                    sx={{
                      width: matches ? '100%' : '400px',
                      height: '300px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: `1px solid ${theme.xPalette.disabledText}`,
                      borderRadius: '8px',
                    }}
                  >
                    <ImageListItem
                      sx={{
                        maxWidth: '350px',
                        maxHeight: '250px',
                        '&.MuiImageListItem-root .MuiImageListItem-img': {
                          objectFit: 'contain',
                        },
                      }}
                    >
                      <img
                        src={showLogoCompany}
                        srcSet={showLogoCompany}
                        alt={'company icon'}
                        loading="eager"
                      />
                    </ImageListItem>
                  </ImageList>
                </>
              )}

              <Typography
                sx={{ fontWeight: 400, color: theme.xPalette.lightGrey, margin: '8px 0px' }}
              >
                {t('account.manageAccounts.brandSettings.chooseFileTitle')}
              </Typography>
              <Button variant="contained" component="label">
                {t('account.manageAccounts.brandSettings.chooseFile')}
                <input hidden accept="image/*" type="file" onChange={handleFileChange} />
              </Button>
            </Box>

            {!matches && <Divider sx={{ my: 2 }} />}
            {pageErrors?.length > 0 &&
              pageErrors.map((error) => (
                <Alert key={error} severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              ))}
            <Box
              sx={{
                py: 2,
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: matches ? 'column' : 'row-reverse',
                columnGap: 1,
              }}
            >
              <Button
                sx={(theme) => ({ ...commonStyledButton(theme) })}
                type="submit"
                variant="contained"
              >
                {t('account.manageAccounts.brandSettings.submit')}
              </Button>
            </Box>
          </Box>
        </RoundedContainer>
      </Container>
    </DashboardLayout>
  );
}
