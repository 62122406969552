import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MonitorErrorAnswerMessagesType, SequenceMonitorItemType } from '../../types/MonitorTypes';
import { getQuestionControlName } from '../interview/interviewUtils';
import MonitorQuestion from './MonitorQuestion';

interface MonitorModuleProps {
  sequenceOrders?: SequenceMonitorItemType[];
  moduleName?: string;
  moduleId?: number;
  control: Control;
  errors: any;
  setValue: UseFormSetValue<any>;
  checkRelativeQuestion: any;
  defaultValues: any;
  responseErrors: MonitorErrorAnswerMessagesType;
  isClinicianMonitor: boolean;
  relativeAnswers: any;
}

export default function MonitorModule({
  sequenceOrders,
  moduleName,
  moduleId,
  control,
  errors,
  setValue,
  checkRelativeQuestion,
  defaultValues,
  responseErrors,
  isClinicianMonitor,
  relativeAnswers,
}: MonitorModuleProps) {
  const renderMonitorQuestion = (sequenceOrders: SequenceMonitorItemType[]) => {

    return sequenceOrders
      .filter((questionItem: SequenceMonitorItemType) => {
        const { question } = questionItem;
        if (
          (isClinicianMonitor && question?.isClinicianOnly) ||
          (!isClinicianMonitor && question?.isPatientOnly) ||
          (!question?.isClinicianOnly && !question?.isPatientOnly)
        ) {
          return true;
        }
        return false;
      })
      .map((questionItem: SequenceMonitorItemType) => {
        const controlName = getQuestionControlName(questionItem.questionId!);
        const errorResponse = responseErrors[questionItem.questionId!]
          ? responseErrors[questionItem.questionId!].message
          : '';
        const error = errors[controlName];
        return (
          <Controller
            key={controlName}
            name={controlName}
            control={control}
            defaultValue={defaultValues[controlName] || ''}
            render={({ field }) => (
              <MonitorQuestion
                question={questionItem.question!}
                field={field}
                error={!!error || !!errorResponse}
                helperText={error?.message || errorResponse}
                setValue={setValue}
                moduleId={moduleId}
                questionId={questionItem.questionId}
                checkRelativeQuestion={checkRelativeQuestion}
                subErrorsResponse={
                  responseErrors[questionItem.questionId!] &&
                  responseErrors[questionItem.questionId!].subErrors
                }
                relativeAnswers={
                  relativeAnswers[questionItem.question?.relatedQuestionIdForAnswerValues!] || ''
                }
              />
            )}
          />
        );
      });
  };
  return (
    <Box>
      {moduleName && (
        <Typography
          sx={{ fontWeight: 'bold' }}
          dangerouslySetInnerHTML={{
            __html: moduleName,
          }}
        ></Typography>
      )}
      {sequenceOrders && renderMonitorQuestion(sequenceOrders)}
    </Box>
  );
}
