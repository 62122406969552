import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ShowMoreIconSvg } from '../../../assets/icons/show-more.svg';

export default function ShowMoreIcon() {
  return <SvgIcon component={ShowMoreIconSvg} 
  sx={{ 
    '& path': { fill: 'white' },
    height: '1.5em',
    marginLeft: '5px'
   }} 
   />;
}
