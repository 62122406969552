import { EVENTS } from 'react-idle-timer';

export const ALLOWED_IDLE_MINUTES =
  parseFloat(process.env.REACT_APP_ALLOWED_IDLE_MINUTES || '') || 15;
export const IDLE_CHECK_SECONDS = 60 * (ALLOWED_IDLE_MINUTES - 1); // 1 min before allowed time
export const COUNTDOWN_SECONDS = 60;
export const PATIENT_ASSESSMENT_EXPIRE_TIME_MILLISECONDS = 43200000; // 12 hours
export const PATIENT_SESSION_EXPIRE_MILLISECONDS = ALLOWED_IDLE_MINUTES * 60 * 1000; // 15 minutes

export const IDLE_CHECK_EVENTS: EVENTS[] = [
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
];
