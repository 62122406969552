import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { clearCredentials } from '../auth/authSlice';

export interface TreatmentPlanState {
  patientId: string;
  reloadOption: { reload: boolean };
  addOrEditGoalId: string;
}

const initialState: TreatmentPlanState = {
  patientId: '',
  reloadOption: { reload: false },
  addOrEditGoalId: '',
};

const treatmentPlanSlice = createSlice({
  name: 'treatmentPlan',
  initialState,
  reducers: {
    setTreatmentPlanPatientId: (
      state,
      { payload: { patientId } }: PayloadAction<{ patientId: string }>
    ) => {
      state.patientId = patientId;
    },
    setTreatmentPlanReloadOption: (state, { payload }: PayloadAction<{ reload: boolean }>) => {
      state.reloadOption = payload;
    },
    setAddOrEditTreatmentGoalId: (
      state,
      { payload: { addOrEditGoalId } }: PayloadAction<{ addOrEditGoalId: string }>
    ) => {
      state.addOrEditGoalId = addOrEditGoalId;
    },
  },

  extraReducers: (builder) => {
    // clear patient data when log out
    builder.addCase(clearCredentials, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const {
  setTreatmentPlanPatientId,
  setTreatmentPlanReloadOption,
  setAddOrEditTreatmentGoalId,
} = treatmentPlanSlice.actions;

export const selectTreatmentPlanPatientId = (state: RootState) => state.treatmentPlan.patientId;

export const selectTreatmentPlanReloadOption = (state: RootState) =>
  state.treatmentPlan.reloadOption;

export const selectAddOrEditTreatmentGoalId = (state: RootState) =>
  state.treatmentPlan.addOrEditGoalId;

export default treatmentPlanSlice.reducer;
