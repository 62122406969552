import { useTranslation } from 'react-i18next';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import RoundedContainer from '../../common/components/RoundedContainer';
import { selectUser } from '../../features/auth/authSlice';
import { InterviewReportType } from '../../features/interview/interviewUtils';
import { ModuleDto, SequenceItemDto } from '../../types/InterviewQuestionTypes';
import { PatientDiagnosisInfo } from '../../types/PatientRequest';
import { PatientInfoCardProps } from '../../types/PatientType';
import { Role } from '../utils/loginRole';
import { QuestionAnswerType } from '../utils/questionAnswerTypes';
import ProfileIcon from './icons/ProfileIcon';

const generateProgress = (theme: any, modulesForScoring?: number[], currentModule?: ModuleDto) => {
  const items = [];
  const modules = modulesForScoring || [];
  const totalModules = modules.length;
  if (!totalModules) {
    return <></>;
  }

  const width = Math.floor(100 / (totalModules || 100));
  const currentModuleIndex = modules.indexOf(currentModule?.id!);
  for (let i = 0; i < totalModules; i++) {
    items.push(
      <Divider
        key={`devider_index_${i + 1}`}
        sx={{
          backgroundColor: `${
            i <= currentModuleIndex ? theme.palette.primary.main : theme.xPalette.disabled
          }`,
          height: '5px',
          borderRadius: 1,
          mr: `${i === totalModules - 1} ? 0 : 1`,
          width: `${width - ((width - 1) * 8) / 100}%`,
        }}
      />
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        my: 2,
        width: 1,
      }}
    >
      {items}
    </Box>
  );
};

export default function PatientInfoCard({
  data,
  clinicAccounts,
  patientIdOnly,
  interview,
  showDiagnosis,
  diagnosisData,
  onClickEditPatient,
  onClickEditFeedback,
}: PatientInfoCardProps) {
  const { t } = useTranslation();
  const { firstName, lastName, patientId, externalId, clinicAccountId, clinicSiteName } =
    data || {};
  const currentModule = (interview?.modules && interview.modules[0]) || {};
  const theme = useTheme();
  const currentUser = useAppSelector(selectUser);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const showYbocsModule = [InterviewReportType.YBOCS, InterviewReportType.CYBOCS].includes(
    interview?.reportType!
  );
  const currentSequenceItems = currentModule?.sequenceOrders;
  let moduleName;
  let ybocsInfoNoAnswer: SequenceItemDto | undefined;
  let ybocsCheckList: SequenceItemDto | undefined;
  let clinicianHelpText = '';
  if (showYbocsModule) {
    ybocsInfoNoAnswer = currentSequenceItems?.find(
      (sequence) => sequence.question?.questionAnswerType === QuestionAnswerType.InfoNoAnswer
    );
    ybocsCheckList = currentSequenceItems?.find(
      (sequence) => sequence.question?.questionAnswerType === QuestionAnswerType.YbocsCheckList
    );
    clinicianHelpText =
      (currentSequenceItems?.length && currentSequenceItems[0].question?.clinicianHelpText) || '';
    moduleName =
      currentSequenceItems?.length &&
      currentSequenceItems[0].question?.questionAnswerType ===
        QuestionAnswerType.TargetSymptomListText
        ? currentSequenceItems[0].question?.name
        : currentModule.name;
  }

  const clinicAccount = clinicAccounts?.find((x) => x.id === clinicAccountId);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isDesktop ? 'row' : 'column',
          columnGap: 2,
          rowGap: 1,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <ProfileIcon />
          <Box>
            {patientIdOnly ? (
              <>
                <Typography>
                  {t('patient.patientDashboard.patientInfoCard.patientId')}{' '}
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    {patientId}
                  </Typography>
                </Typography>
                <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
                  <Typography variant="body1">{`${t(
                    'patient.patientDashboard.patientInfoCard.account'
                  )} ${
                    clinicAccount ? clinicAccount.name + ' (' + clinicAccount.id + ')' : ''
                  }`}</Typography>
                </Box>
                {(currentUser?.roleId === Role.SiteAdmin ||
                  currentUser?.roleId === Role.Interviewer ||
                  currentUser?.roleId === Role.SingleUserProvider) && (
                  <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
                    <Typography variant="body1">{`${t(
                      'patient.patientDashboard.patientInfoCard.site'
                    )} ${clinicSiteName || ''}`}</Typography>
                  </Box>
                )}
              </>
            ) : (
              <Typography aria-label="patient-name" variant="body1" sx={{ fontWeight: 600, p: 0 }}>
                {(firstName || lastName) && `${firstName} ${lastName}`}
              </Typography>
            )}

            {!patientIdOnly && (
              <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
                <Typography variant="body1">{`${t(
                  'patient.patientDashboard.patientInfoCard.patientId'
                )} ${patientId}`}</Typography>
                <Typography variant="body1">
                  {externalId &&
                    `${t('patient.patientDashboard.patientInfoCard.externalId')} ${externalId}`}
                </Typography>
              </Box>
            )}
          </Box>

          {onClickEditPatient && (
            <Box
              sx={{
                flexGrow: !isDesktop ? 1 : 0.1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                marginLeft: '48px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '20%',
                }}
              >
                <IconButton
                  aria-label="patient-edit-button"
                  size="medium"
                  onClick={onClickEditPatient}
                >
                  <EditIcon sx={{ color: theme.xPalette.white }} />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>

        {showDiagnosis && (
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                columnGap: 1,
                maxWidth: '400px',
              }}
            >
              <Typography
                fontWeight="bold"
                color={diagnosisData && diagnosisData.length > 0 ? '' : 'orange'}
              >
                {`${t('patient.providerFeedback.title')}: `}
              </Typography>
              <Box>
                {diagnosisData && diagnosisData.length > 0 ? (
                  diagnosisData.map((item: PatientDiagnosisInfo) => (
                    <Typography key={item.patientDiagnosisId} fontWeight={500}>
                      {`${item.diagnosisName ? item.diagnosisName : item.otherDiagnosis} ${
                        !!item.timeFrame ? ' - ' : ''
                      } ${item.timeFrame}`}
                    </Typography>
                  ))
                ) : (
                  <Typography fontWeight="bold" color="orange">
                    {t('patient.providerFeedback.enterDiagnosis')}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                marginLeft: '20px',
                [theme.breakpoints.down('md')]: {
                  marginLeft: '16px',
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '20%',
                }}
              >
                <IconButton
                  aria-label="diagnosis-edit-button"
                  size="medium"
                  onClick={onClickEditFeedback}
                  sx={{ width: '40px', height: '40px' }}
                >
                  <ContentPasteIcon sx={{ color: theme.xPalette.white }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {showYbocsModule && (
        <>
          <Box
            sx={(theme) => ({
              mt: 3,
              [theme.breakpoints.up('md')]: {
                mt: 4,
              },
            })}
          >
            <Typography variant="h1">{interview?.name}</Typography>
            {generateProgress(theme, interview?.modulesForScoring, currentModule)}
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              {moduleName}
            </Typography>
          </Box>
          {ybocsCheckList && (
            <RoundedContainer
              sx={{
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                backgroundColor: theme.xPalette.darkGrey,
                minHeight: isDesktop ? '55px' : '35px',
                mt: 2,
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={(theme) => ({ color: theme.xPalette.inProgress.color })}>
                  <Typography component="span" variant="subtitle2">
                    NOTE
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    sx={(theme) => ({
                      fontWeight: 600,
                      ml: 1,
                      [theme.breakpoints.up('md')]: {
                        ml: 2,
                      },
                    })}
                  >
                    Current:
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    sx={(theme) => ({
                      ml: 0.5,
                      [theme.breakpoints.up('md')]: {
                        ml: 2,
                      },
                    })}
                  >
                    Past Month
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    sx={(theme) => ({
                      fontWeight: 600,
                      ml: 3,
                      [theme.breakpoints.up('md')]: {
                        ml: 8,
                      },
                    })}
                  >
                    Past:
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    sx={(theme) => ({
                      ml: 0.5,
                      [theme.breakpoints.up('md')]: {
                        ml: 2,
                      },
                    })}
                  >
                    Beyond 30 Days
                  </Typography>
                </Box>
              </Box>
              {ybocsInfoNoAnswer && (
                <Box sx={{ display: 'flex' }}>
                  <Typography component="span" variant="subtitle2">
                    {ybocsInfoNoAnswer.question.questionText}
                  </Typography>
                </Box>
              )}
            </RoundedContainer>
          )}
          {clinicianHelpText && (
            <Typography
              dangerouslySetInnerHTML={{
                __html: clinicianHelpText,
              }}
              variant="body2"
              sx={{
                mt: 2,
                color: theme.xPalette.lightGrey,
                '> b': {
                  fontWeight: 600,
                },
              }}
            ></Typography>
          )}
        </>
      )}
    </Box>
  );
}
