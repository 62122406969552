export const QuestionAnswerType = {
  Binary: 'binary',
  Formula: 'formula',
  Grid: 'grid',
  Height: 'height',
  InfoNoAnswer: 'infoNoAnswer',
  MultiOptions: 'multiOptions',
  MultiSelectList: 'multiSelectList',
  Number: 'number',
  Options: 'options',
  SdsScale: 'sdsScale',
  StsEvent: 'stsEvent',
  StsScale: 'stsScale',
  Table: 'table',
  TargetSymptomListText: 'targetSymptomListText',
  Text: 'text',
  Time: 'time',
  TimeDayMonth: 'time (Day/Month)',
  Weight: 'weight',
  YbocsCheckList: 'ybocsCheckList',
  YbocsCheckOther: 'ybocsCheckOther',
  YbocsExitCondition: 'ybocsExitCondition',
  YbocsScale: 'ybocsScale',
  Ybocs: 'ybocs',
  Cybocs: 'cybocs',
  Questionnaire: 'questionnaire',
  InfoNoAnswerNoUI: 'infoNoAnswerNoUI',
  MonitorFormula: 'monitorFormula',
  PHQFormula: 'PHQFormula',
  ElectronicSignature: 'eSignature',
  Date: 'date',
};

export type AnswerUnit = 'imperial' | 'metrics';
