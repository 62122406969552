export const ReminderCommunicationTypes = [
  { name: 'Email', value: 'email' },
  { name: 'Text', value: 'text' },
  { name: 'Email and text', value: 'emailAndText' },
];

export const ReminderCommunicationType = {
  EMAIL: 'email',
  TEXT: 'text',
  EMAIL_AND_TEXT: 'emailAndText',
};

export const ReminderCommunication = {
  email: 'Email',
  text: 'Text',
  emailAndText: 'Email and text',
};
