import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../../app/hooks';
import { useGetDiagnosesQuery } from '../../../app/services/patient';
import {
  useAddTreatmentDiagnosisMutation,
  useEditTreatmentDiagnosisMutation,
} from '../../../app/services/treatment-plan/treatmentDiagnosis';
import { useToast } from '../../../app/toast';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { CommonItem } from '../../../types/PatientRequest';
import { ResponseError } from '../../../types/ResponseError';
import { TreatmentPlanDiagnosisItem } from '../../../types/treatment-plan/TreatmentPlanDiagnosisType';
import { StyledClinicianNote } from '../../interview/interviewUtils';
import { selectTreatmentPlanPatientId } from '../treatmentPlanSlice';

interface DiagnosisFormProps {
  parentPlanId: number;
  diagnosesInfoData?: TreatmentPlanDiagnosisItem;
  onFormSubmitCallback: (
    result: boolean,
    info?: any
  ) => void;
}

interface FormType {
  diagnosisId: string;
  timeframe: string;
  otherDiagnosis: string;
}

export default function TreatmentPlanDiagnosisForm({
  parentPlanId,
  diagnosesInfoData,
  onFormSubmitCallback,
}: DiagnosisFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const toast = useToast();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [disabledOtherForm, setDisabledOtherForm] = useState<boolean>(true);
  const [diagnoses, setDiagnosesData] = useState<CommonItem[]>([]);
  const [timeFramesData, setTimeFramesData] = useState<string[]>([]);

  const [addDiagnosis] = useAddTreatmentDiagnosisMutation();
  const [editDiagnosis] = useEditTreatmentDiagnosisMutation();
  const patientId = useAppSelector(selectTreatmentPlanPatientId);

  const { data: diagnosesData } = useGetDiagnosesQuery(!!patientId ? { patientId } : skipToken);

  const defaultFormValues: FormType = {
    diagnosisId: '',
    timeframe: '',
    otherDiagnosis: '',
  };

  const validationSchema = yup
    .object({
      diagnosisId: yup.string().required(t('patient.error.blankDiagnosis')),
      timeframe: yup.string().when('diagnosisId', {
        is: (diagnosisId: string) => diagnosisId === '0' || timeFramesData.length === 0,
        then: yup.string().trim(),
        otherwise: yup.string().trim().required(t('patient.error.blankTimeFrame')),
      }),
      otherDiagnosis: yup
        .string()
        .nullable()
        .when('diagnosisId', {
          is: (diagnosisId: string) => diagnosisId === '0',
          then: yup.string().trim().required(t('patient.error.blankOtherDiagnosis')),
          otherwise: yup.string().trim(),
        }),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    resetField,
    clearErrors,
  } = useForm<FormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (diagnosesData) {
      const newDiagnosesData = [...diagnosesData.diagnoses];
      newDiagnosesData.push({
        id: 0,
        name: t('patient.providerFeedback.form.otherDiagnosis'),
        availableTimeframes: diagnosesData.otherDiagnosesTimeFrames,
      });
      setDiagnosesData(newDiagnosesData);
    }
  }, [clearErrors, diagnosesData, t]);

  useEffect(() => {
    if (diagnosesInfoData) {
      if (diagnosesInfoData.diagnosisId) {
        setValue('diagnosisId', diagnosesInfoData.diagnosisId.toString());
      } else {
        setValue('diagnosisId', '0');
        setDisabledOtherForm(false);
      }

      if (diagnosesInfoData.timeframe) {
        setValue('timeframe', diagnosesInfoData.timeframe);
        setTimeFramesData(diagnosesInfoData.availableTimeframes || []);
      }
      setValue('otherDiagnosis', diagnosesInfoData.otherDiagnosis || "");
    }
  }, [diagnosesInfoData, setValue]);

  const onSubmit = async (diagnosesInfoFormData: TreatmentPlanDiagnosisItem) => {
    setDisableSubmit(true);
    try {
      let submitData = diagnosesInfoFormData;
      const diagnosisId =
        submitData?.diagnosisId?.toString() !== '0' ? submitData.diagnosisId : null;
      submitData.diagnosisId = diagnosisId;
      if (diagnosesInfoData?.id) {
        submitData = { ...submitData, id: diagnosesInfoData.id };
        await editDiagnosis(submitData).unwrap();
      } else if (parentPlanId) {
        submitData = {
          ...submitData,
          treatmentPlanId: parentPlanId,
        };
        await addDiagnosis(submitData).unwrap();
      }

      onFormSubmitCallback(true);

      setPageErrors([]);

      toast.publish(
        (diagnosesInfoData?.id && t('treatmentPlans.diagnosisForm.updateSuccess')) ||
          t('treatmentPlans.diagnosisForm.addSuccess'),
        'success'
      );
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
      onFormSubmitCallback(false, error);
    }
    setDisableSubmit(false);
  };

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  const filterDiagnosisOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getDiagnosisOptionLabel(option),
  });

  const getDiagnosisOptionLabel = (option: any) => option.name;

  const handleDiagnosisChange = (e: any, values: CommonItem) => {
    if (!values) {
      reset();
    } else {
      const value = values?.id;
      const availableTimeFrames = values?.availableTimeframes;
      setDisabledOtherForm(true);
      if (value === 0) {
        setDisabledOtherForm(false);
      }

      setTimeFramesData(availableTimeFrames);
      resetField('otherDiagnosis');
      resetField('timeframe');
      setValue('diagnosisId', value.toString());
    }
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        px: 0,
        pt: 0,
      }}
    >
      <RoundedContainer sx={{ paddingTop: 1 }}>
        <Box
          sx={{
            py: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Controller
            name="diagnosisId"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <Autocomplete
                {...props}
                options={diagnoses}
                getOptionLabel={getDiagnosisOptionLabel}
                onChange={handleDiagnosisChange}
                value={
                  value ? diagnoses?.find((item) => item.id === parseInt(value)) || null : null
                }
                filterOptions={filterDiagnosisOptions}
                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: CommonItem) => {
                  return (
                    <li {...props} key={option.id} value={option.id}>
                      {getDiagnosisOptionLabel(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.diagnosisId}
                    helperText={errors.diagnosisId?.message}
                    margin="normal"
                    fullWidth
                    label={t('patient.providerFeedback.form.diagnosis')}
                  />
                )}
              />
            )}
          />

          <Controller
            name="timeframe"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                select
                fullWidth
                label={t('patient.providerFeedback.form.timeFrames')}
                error={!!errors.timeframe}
                helperText={errors.timeframe?.message}
                variant="outlined"
                disabled={timeFramesData.length === 0}
              >
                {timeFramesData.map((timeFrame: string) => (
                  <MenuItem key={timeFrame} value={timeFrame}>
                    {timeFrame}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="otherDiagnosis"
            control={control}
            render={({ field }) => (
              <StyledClinicianNote
                {...field}
                sx={{
                  backgroundColor: theme.xPalette.white,
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: theme.xPalette.noteLightGrey,
                    borderRadius: '8px',
                    pb: 4,
                  },
                }}
                disabled={disabledOtherForm}
                multiline
                maxRows={4}
                fullWidth
                label={t('patient.providerFeedback.form.otherDiagnosis')}
                error={!!errors.otherDiagnosis}
                helperText={errors.otherDiagnosis?.message}
                variant="outlined"
              />
            )}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            pt: 3,
          }}
        >
          {!isMobile && <Divider sx={{ width: '100%' }} />}

          {pageErrors?.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            ))}
          <Button
            onClick={submitForm}
            disabled={disableSubmit}
            fullWidth
            variant="contained"
            sx={{
              width: isMobile ? '100%' : '35%',
              my: 2,
              py: 2,
              float: 'right',
            }}
          >
            {diagnosesInfoData?.id
              ? t('treatmentPlans.diagnosisForm.update')
              : t('treatmentPlans.diagnosisForm.add')}
          </Button>
        </Box>
      </RoundedContainer>
    </Box>
  );
}
