import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import {
  StyledRadioCheckIcon,
  StyledRadioIcon,
} from '../../../interview/questions/SdsScaleQuestion';
import useCustomQuestionInfo from './useCustomQuestionInfo';

let possibleAnswers = ['0', '1', '2', '3', '4'];

const StyledAnswerLeft = styled(Typography)(() => ({
  fontSize: '14px!important',
  textAlign: 'center',
  padding: '5px 15px',
  lineHeight: '25px',
}));

const StyledLayoutAnswerLeft = styled(Box)(() => ({
  height: '35px',
  display: 'flex',
  border: '1px solid #000000',
  width: '25%',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledHeader = styled(Box)(() => ({
  display: 'flex',
  marginLeft: '15px',
  minWidth: '70px',
  justifyContent: 'center',
}));

export default function StsEventAnswers({ questionAnswer, moduleType }: QuestionAnswerProps) {
  const { t } = useTranslation();
  const { questionDisplay, arrayAnswerValue } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
  });
  const stsMeasurement: string[] = Object.values(
    t('interview.form.sts.stsScale', { returnObjects: true })
  );

  const renderHeader = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '65%', marginLeft: '35%', display: 'flex' }}>
          {stsMeasurement.map((item) => {
            return (
              <Typography
                fontSize="14px!important"
                textAlign="center"
                sx={{ marginLeft: '15px', minWidth: '70px' }}
              >
                {item}
              </Typography>
            );
          })}
          <Typography
            fontSize="14px!important"
            textAlign="center"
            sx={{ marginLeft: '15px', minWidth: '70px' }}
          >
            {t('interview.form.sts.level')}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderAnswers = () => {
    return arrayAnswerValue?.map((answer, index) => {
      return (
        <Box sx={{ width: '100%', display: 'flex', my: '20px' }}>
          <StyledLayoutAnswerLeft>
            <StyledAnswerLeft sx={{ flexGrow: 1 }}>{`${index + 1}.`}</StyledAnswerLeft>
            <StyledAnswerLeft sx={{ flexGrow: 2, borderLeft: '1px solid #000000' }}>
              {answer?.date}
            </StyledAnswerLeft>
            <StyledAnswerLeft sx={{ flexGrow: 1, borderLeft: '1px solid #000000' }}>
              {answer?.how}
            </StyledAnswerLeft>
          </StyledLayoutAnswerLeft>
          <Box sx={{ marginLeft: '10%', width: '65%', display: 'flex' }}>
            {possibleAnswers.map((item) => {
              return (
                <StyledHeader>
                  {answer.scaleValue === item ? (
                    <StyledRadioCheckIcon>{item}</StyledRadioCheckIcon>
                  ) : (
                    <StyledRadioIcon>{item}</StyledRadioIcon>
                  )}
                </StyledHeader>
              );
            })}
            <StyledHeader>
              <Typography>{answer.level}</Typography>
            </StyledHeader>
          </Box>
        </Box>
      );
    });
  };

  return (
    <>
      <Box
        key={questionAnswer.questionId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          my: 3,
          width: '100%',
          pageBreakInside: 'avoid',
        }}
      >
        <Box sx={{ width: '15%' }}>
          <Typography fontWeight={500} component={'span'}>
            {questionAnswer.title || ''}
          </Typography>
          <Typography fontWeight={500} component={'span'}>
            {questionAnswer.subTitle || ''}
          </Typography>
        </Box>

        <Box sx={{ width: '75%' }}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: questionDisplay,
            }}
          ></Typography>
        </Box>

        <Typography
          sx={{ width: '10%' }}
          component={'span'}
          fontWeight={500}
          textAlign={'right'}
        ></Typography>
        <Typography
          sx={{ width: '5%' }}
          component={'span'}
          fontWeight={500}
          textAlign={'right'}
        ></Typography>
      </Box>
      {renderHeader()}
      {renderAnswers()}
      <Typography
        dangerouslySetInnerHTML={{
          __html: questionAnswer.questionHelpText || '',
        }}
      ></Typography>
    </>
  );
}
