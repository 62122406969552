import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectResetQuestion, setResetQuestion } from '../../interview/interviewSlice';
import { StyledRadioCheckIcon, StyledRadioIcon } from './SdsScaleQuestion';

const StyledFormControl = styled(FormControl)(({ theme, error }) => ({
  margin: '16px 0px',
  width: '100%',
  alignItems: 'start',
  '&.MuiFormControl-root .MuiFormGroup-root .MuiFormControlLabel-root': {
    borderColor: error ? theme.palette.error.main : '',
  },

  '&.MuiFormControl-root .MuiFormGroup-root': {
    flexWrap: 'wrap-reverse',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    alignItems: 'center',
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    textAlign: 'unset',
  },
  [theme.breakpoints.up('md')]: {
    [`&.${tableCellClasses.head}:last-of-type`]: {
      textAlign: 'left',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: '16px 8px',
    fontWeight: theme.typography.h4.fontWeight,
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    maxWidth: 100,
    wordBreak: 'break-word',
  },
}));

export default function TableQuestion({
  question,
  questionId,
  field,
  error,
  helperText,
  setValue,
}: InterviewQuestionProps) {
  const { columns, rows } = JSON.parse(question.questionText?.replace(/\\r\\n/g, '') || '');
  const [selectedValue, setSelectedValue] = useState<string>('');
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);
  const dispatch = useAppDispatch();
  const savedValue = field?.value;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (field?.name) {
      setValue(field.name, value);
    }
    dispatch(setResetQuestion({ reset: false }));
  };

  const controlProps = (itemValue: string) => {
    return {
      checked: selectedValue === itemValue || (savedValue.toString() === itemValue && !selectedValue),
      onChange: handleChange,
      value: itemValue,
      name: itemValue,
      inputProps: { 'aria-label': itemValue },
    };
  };

  useEffect(() => {
    setValue(field?.name!, selectedValue);
    if (isResetQuestion) {
      setSelectedValue('');
    }
  }, [field?.name, selectedValue, setValue, isResetQuestion]);

  return (
    <Box sx={{ mt: 2 }}>
      {question.name && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: `${question.name}:`,
          }}
        ></StyledTypography>
      )}
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <StyledFormControl error={error} variant="standard">
        <TableContainer
          data-testid="sts-table-question"
          elevation={0}
          component={Paper}
          sx={{ borderRadius: '16px 16px 0 0' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns.reverse().map((column: any) => (
                  <StyledTableCell key={column}>
                    <Typography
                      sx={{ fontWeight: 500 }}
                      dangerouslySetInnerHTML={{
                        __html: column,
                      }}
                    ></Typography>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => (
                <TableRow key={row}>
                  {row[2] && (
                    <StyledTableCell>
                      <Typography
                        sx={{ fontWeight: 500 }}
                        dangerouslySetInnerHTML={{
                          __html: row[2],
                        }}
                      ></Typography>
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    <Typography
                      sx={{ fontWeight: 500, textAlign: row[2] ? 'center' : 'unset' }}
                      dangerouslySetInnerHTML={{
                        __html: row[1],
                      }}
                    ></Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControlLabel
                      sx={{ ml: 0 }}
                      key={row[0]}
                      value={row[0]}
                      labelPlacement="start"
                      control={
                        <Radio
                          checkedIcon={<StyledRadioCheckIcon>{row[0]}</StyledRadioCheckIcon>}
                          icon={<StyledRadioIcon>{row[0]}</StyledRadioIcon>}
                          {...controlProps(row[0])}
                        />
                      }
                      label={
                        <Hidden xlDown xlUp>
                          row[0]
                        </Hidden>
                      }
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ my: 2, width: '100%' }}>
          {error && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
      </StyledFormControl>
    </Box>
  );
}
