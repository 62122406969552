import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';

interface PaginationValue {
  currentPage: number;
  pages: number;
  changePage: (e: any, value: any) => void;
  pageRef?: any;
}

export default function CustomPagination({
  currentPage,
  pages,
  changePage,
  pageRef,
}: PaginationValue) {
  const theme = useTheme();
  const StyledPagination = styled(Pagination)(() => ({
    '& .MuiPaginationItem-root:active': {
      color: theme.palette.primary.main,
    },
    '& .MuiPaginationItem-root': {
      backgroundColor: 'transparent',
      color: theme.xPalette.lightGrey,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: 600,
      padding: '0px 3px',

      [theme.breakpoints.down('sm')]: {
        padding: '0px',
        margin: '0px',
      },
    },
  }));

  return (
    <Stack spacing={4}>
      <StyledPagination
        onChange={(e: any, value: any) => {
          changePage(e, value);
          if (pageRef) {
            window.scrollTo({
              top: pageRef.current.offsetTop - 20,
              behavior: 'smooth',
            });
          }
        }}
        shape="rounded"
        count={pages}
        page={currentPage}
        renderItem={(item) => <PaginationItem {...item} />}
      />
    </Stack>
  );
}
