import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Header from '../../common/components/Header';
import RoundedContainer from '../../common/components/RoundedContainer';
import BaseLayout from '../../common/layouts/Base';
import { pageHeightStyle } from '../../common/utils/commonStyles';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';

interface ErrorPageProps extends PageProps {
  showStatusCode?: boolean;
  statusCode?: number | string;
  children?: ReactElement;
}

const statusMessage: { [key: number | string]: string } = {
  [HttpStatusCode.NotFound]: 'error.notFound',
  [HttpStatusCode.Forbidden]: 'error.forbidden',
  [HttpStatusCode.InternalServerError]: 'error.unexpectedError',
  [HttpStatusCode.BadRequest]: 'error.badRequest',
};

export default function ErrorPage({
  showStatusCode = true,
  statusCode = HttpStatusCode.NotFound,
  children,
}: ErrorPageProps) {
  const { t } = useTranslation();
  statusCode = typeof statusCode === 'number' ? statusCode : 500;

  return (
    <BaseLayout>
      <Helmet>
        <title>{getPageTitle('Error')} </title>
      </Helmet>
      <Box sx={(theme) => ({ ...pageHeightStyle(theme) })}>
        <Header />
        <RoundedContainer sx={{ my: 2, py: 4 }}>
          {showStatusCode && (
            <Typography variant="h1" gutterBottom>
              {statusCode}
            </Typography>
          )}
          {children || (
            <>
              <Typography variant="h2" mb={4}>
                {t(statusMessage[statusCode])}
              </Typography>
              <Typography>
                Back to <Link to="/">Home</Link>
              </Typography>
            </>
          )}
        </RoundedContainer>
      </Box>
    </BaseLayout>
  );
}
