import { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { MonitorQuestionProps } from '../../../types/MonitorTypes';
import { StyledClinicianNote as StyleTextQuestion } from '../../interview/interviewUtils';

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
}));

export default function TextQuestion({
  question,
  field,
  error,
  helperText,
  moduleId,
  questionId,
  checkRelativeQuestion,
  setValue,
}: MonitorQuestionProps) {
  const handleTextQuestionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const textValue = event.target.value;
    checkRelativeQuestion({ moduleId, questionId, questionAnswer: textValue });
    setValue(field?.name, textValue.trim());
  };

  return (
    <Box sx={{ my: 1 }}>
      {question.title && (
        <Typography
          dangerouslySetInnerHTML={{
            __html: question.title,
          }}
        ></Typography>
      )}
      <Typography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></Typography>
      <FormControl error={error} variant="standard" sx={{ width: 1 }}>
        <Box sx={{ mt: 1 }}>
          <StyleTextQuestion
            error={error}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            onBlur={handleTextQuestionChange}
          />
          {error && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
      </FormControl>
    </Box>
  );
}
