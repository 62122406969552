import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRequestEditInterviewMutation } from '../../app/services/interview';
import { useGetPatientQuery } from '../../app/services/patient';
import PageHeader from '../../common/components/PageHeader';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { commonStyledButton } from '../../common/utils/commonStyles';
import { addBreadcrumb, editBreadcrumbs, ReplaceType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { ResponseError } from '../../types/ResponseError';
import ErrorPage from '../error/ErrorPage';
import { clearAssessmentCachedData } from '../patient/patientUtils';

interface reasonForEditType {
  reasonForEdit: string;
}

export default function EditInterviewPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { state } = useLocation();
  const [requestEditInterview] = useRequestEditInterviewMutation();

  const navigate = useNavigate();
  const { patientId, interviewResultId: inputInterviewResultId } = useParams();
  const interviewResultId = parseInt(inputInterviewResultId!);

  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }];
  const [pageErrors, setPageErrors] = useState<string[]>([]);

  const { fromPage } = !!state && state;

  const { data: infoData, error } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const validationSchema = yup
    .object({
      reasonForEdit: yup
        .string()
        .trim()
        .required(t('interview.editInterview.error.blankReasonForm')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<reasonForEditType>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      reasonForEdit: '',
    },
  });

  const handleSubmitData = async (reasonForEdit: string) => {
    const submitData = {
      interviewTestId: interviewResultId,
      reasonForEdit: reasonForEdit.trim(),
    };
    try {
      return await requestEditInterview(submitData).unwrap();
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors([error as unknown as string]);
    }
  };

  const onSubmit = async ({ reasonForEdit }: reasonForEditType) => {
    const res = await handleSubmitData(reasonForEdit);

    if (res?.success) {
      clearAssessmentCachedData();
      navigate(`/dashboard/patient/${patientId}/${res?.interviewTestId}/start-interview`, {
        state: { resumeInterview: false, editInterview: true },
      });
      reset();
      setPageErrors([]);
    }
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout
      breadcrumbs={
        !fromPage
          ? editBreadcrumbs(breadcrumbs, ...replacePath)
          : addBreadcrumb(breadcrumbs!, fromPage)
      }
    >
      <Helmet>
        <title>{getPageTitle(t('interview.editInterview.title'))}</title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={t('interview.editInterview.title')}
          backUrl={fromPage?.link || `/dashboard/patient/${patientId}`}
        />
      </Container>
      <Container maxWidth="xl" disableGutters={true}>
        <RoundedContainer sx={{ py: 2 }}>
          <PatientInfoCard data={infoData!} />
        </RoundedContainer>
        <RoundedContainer sx={{ pt: 1, mt: 2 }}>
          <Box sx={{ mt: 2 }} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Typography fontWeight="bold" fontSize={matches ? 18 : 24}>
              {t('interview.editInterview.form.title')}
            </Typography>
            <Controller
              name="reasonForEdit"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  error={!!errors.reasonForEdit}
                  helperText={errors.reasonForEdit?.message}
                  margin="normal"
                  fullWidth
                  label={t('interview.editInterview.form.reason')}
                  variant="outlined"
                />
              )}
            />

            {!matches && <Divider sx={{ my: 2 }} />}
            {pageErrors.length > 0 &&
              pageErrors.map((error) => (
                <Alert key={error} severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              ))}
            <Box
              sx={{
                py: 2,
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: matches ? 'column' : 'row-reverse',
                columnGap: 1,
              }}
            >
              <Button
                sx={(theme) => ({ ...commonStyledButton(theme) })}
                disabled={pageErrors && pageErrors.length > 0}
                type="submit"
                variant="contained"
              >
                {t('interview.editInterview.form.submit')}
              </Button>
              <Button
                sx={(theme) => ({ ...commonStyledButton(theme) })}
                type="button"
                variant="outlined"
                onClick={() => {
                  if (!fromPage) {
                    navigate(`/dashboard/patient/${patientId}`);
                  } else {
                    navigate(fromPage.link);
                  }
                }}
              >
                {t('interview.editInterview.form.cancel')}
              </Button>
            </Box>
          </Box>
        </RoundedContainer>
      </Container>
    </DashboardLayout>
  );
}
