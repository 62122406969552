import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as yup from 'yup';
import { LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useRequestInterviewMutation } from '../../app/services/interview';
import { useRequestMonitorMutation } from '../../app/services/monitor';
import { useGetPatientQuery, useGetProvidersQuery } from '../../app/services/patient';
import { customMdBreakpoints } from '../../app/theme';
import { ToastContent, useToast } from '../../app/toast';
import CommonDatePicker from '../../common/components/CommonDatePicker';
import LocalPopupModal from '../../common/components/LocalPopupModal';
import MeasurementPacketsList from '../../common/components/MeasurementPacketsList';
import { AssessmentMode } from '../../common/utils/assessmentMode';
import { commonButtonStyle, commonWidthResponsive } from '../../common/utils/commonStyles';
import { endDateValidation } from '../../common/utils/endDateValidation';
import { intervals } from '../../common/utils/intervalResource';
import { interviewsModeCode } from '../../common/utils/interviewsMode';
import { LanguageType } from '../../common/utils/languages';
import { timeFrames } from '../../common/utils/timeFrameResource';
import { isBefore } from '../../common/utils/treatmentPlans';
import {
  clearPatientInterviewData,
  setInterviewMode,
  setPatientInterviewData,
  setTrialInterview,
} from '../../features/interview/interviewSlice';
import {
  addMeasurementPacket,
  removeMeasurementPacketList,
  selectAllowTrial,
  selectAssessmentMode,
  selectHasRestrictedTimeframes,
  selectInterviewsByLanguage,
  selectLanguagesForInterview,
  selectMeasurementPacketList,
  selectPatientHasEmail,
  selectPatientSMSOptIn,
  selectUserRole,
  setAssessmentMode,
  setDefaultLanguage,
  setInterviewTypesByLanguage,
} from '../../features/patient/patientSlice';
import { ContentPopup } from '../../types/CommonType';
import { MeasurementPacket } from '../../types/InterviewRequest';
import { InterviewInfo, InterviewType } from '../../types/PatientRequest';
import { PopupContent } from '../../types/PopupType';
import { ResponseError } from '../../types/ResponseError';
import { Provider } from '../../types/UserTypes';
import { getRequestData } from '../interview/interviewUtils';
import {
  clearPatientMonitorData,
  setMonitorMode,
  setPatientMonitorData,
} from '../monitor/monitorSlide';
import { clearAssessmentCachedData } from './patientUtils';

enum DefaultLanguage {
  English = 'en',
}

enum UserRoles {
  Administrator = 'superAdmin',
}

enum ReportTypes {
  Ybocs = 'ybocs',
  CyBocs = 'cybocs',
}

const StyledMenuItem = styled(MenuItem)(() => ({
  maxWidth: 440,
  '&.MuiMenuItem-root': {
    whiteSpace: 'unset',
    lineBreak: 'strict',
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

const getInterviewToastMessages = ({
  t,
  toast,
  patientHasEmail,
  patientSMSOptIn,
  isNotSendEmailToClinician,
}: {
  t: TFunction<'translation'>;
  toast: ToastContent;
  patientHasEmail?: boolean;
  patientSMSOptIn?: boolean;
  isNotSendEmailToClinician?: boolean;
}) => {
  if (isNotSendEmailToClinician) {
    if (patientHasEmail && patientSMSOptIn) {
      toast.publish(
        t('interview.InterviewInstructionsPage.eSignature.emailAndSMSSentSuccess'),
        'success'
      );
    } else {
      toast.publish(
        patientHasEmail
          ? t('interview.InterviewInstructionsPage.eSignature.emailSentSuccess')
          : t('interview.InterviewInstructionsPage.eSignature.smsSentSuccess'),
        'success'
      );
    }
  } else {
    if (patientHasEmail && patientSMSOptIn) {
      toast.publish(t('interview.InterviewInstructionsPage.emailAndSMSSentSuccess'), 'success');
    } else {
      toast.publish(
        patientHasEmail
          ? t('interview.InterviewInstructionsPage.emailSentSuccess')
          : t('interview.InterviewInstructionsPage.smsSentSuccess'),
        'success'
      );
    }
  }
};

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

interface StartInterviewProps {
  interviewTypeId: number;
  defaultLanguage: string;
}

export default function StartInterviewForm({
  interviewTypeId,
  defaultLanguage,
}: StartInterviewProps) {
  const ref = useRef<any>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { patientId } = useParams();
  const matches = useMediaQuery(theme.breakpoints.down(customMdBreakpoints));
  const [formHeight, setFormHeight] = useState(0);
  const [showTimeFrame, setShowTimeFrame] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [showPatientEmailForm, setShowPatientEmailForm] = useState<boolean>(false);
  const [showDateForm, setShowDateForm] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<string>('');
  const [isScheduleEnabled, setIsScheduleEnabled] = useState(false);
  const [isTrialDisabled, setTrialDisabled] = useState(false);
  const [isTrialChecked, setTrialChecked] = useState(false);
  const [mode, setMode] = useState(AssessmentMode.Clinician);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isProviderAllowTrial, setIsProviderAllowTrial] = useState<boolean>(false);
  const [isNotSendEmailToClinician, setIsNotSendEmailToClinician] = useState<boolean>(false);

  const interviewWithtimeframe = useRef<boolean>(false);

  const allowTrial = useAppSelector(selectAllowTrial);
  const hasRestrictedTimeframes = useAppSelector(selectHasRestrictedTimeframes);
  const patientHasEmail = useAppSelector(selectPatientHasEmail);
  const patientSMSOptIn = useAppSelector(selectPatientSMSOptIn);
  const languages = useAppSelector(selectLanguagesForInterview);
  const interviews = useAppSelector(selectInterviewsByLanguage);
  const userRole = useAppSelector(selectUserRole);
  const measurementPacketList = useAppSelector(selectMeasurementPacketList);
  const assessmentModeAcrossComponent = useAppSelector(selectAssessmentMode);
  const [requestInterview] = useRequestInterviewMutation();
  const [requestMonitor] = useRequestMonitorMutation();
  const haveInterviews = interviews && interviews.length !== 0;

  const interviewModes = t('patient.patientDashboard.interviewMode', { returnObjects: true });

  const { data: providerData } = useGetProvidersQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );
  const { data: infoData } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  dayjs.tz.setDefault(infoData?.timezone);

  const validationSchema = yup
    .object()
    .shape({
      language: yup.string(),
      interview: yup.string(),
      providerId: yup.string().required(t('patient.error.blankProvider')),
      interviewMode: yup.string(),
      trialInterview: yup.boolean(),
      timeFrames: yup.string(),
      interval: yup.string(),
      startDate: yup
        .date()
        .when('interval', {
          is: (interval: string) => parseInt(interval) !== 0,
          then: yup.date().required(t('patient.error.blankStartDate')),
        })
        .test('start-date', t('interview.form.errors.validStartDate'), function (startDate) {
          const endDate: Date = getValues('endDate');
          if (endDate && startDate) {
            return !isBefore(endDate, startDate);
          }
          return true;
        }),
      endDate: yup
        .date()
        .typeError(`${t('interview.form.errors.invalidEndDate')}`)
        .test('end-date', '', function (endDate) {
          const interviewMode = getValues('interviewMode');
          const interval = getValues('interval');
          const startDate = getValues('startDate');
          if (endDate) {
            const suggestDate = endDateValidation({
              assessmentMode: interviewMode,
              interval: parseInt(interval),
              timezone: infoData?.timezone,
              startDate,
              endDate,
            });

            if (suggestDate) {
              setEndDateError(`${t('interview.form.errors.validEndDate')} ${suggestDate}`);
              return false;
            }
          }
          setEndDateError('');
          return true;
        })
        .nullable(),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    resetField,
  } = useForm<InterviewInfo>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      patientId,
      language: DefaultLanguage.English,
      interview: interviews && interviews.length > 0 && interviews[0].interviewTypeId,
      interviewMode: assessmentModeAcrossComponent || Object.keys(interviewModes)[0],
      trialInterview: false,
      timeFrames: '',
      interval: '0',
      startDate: new Date(),
      endDate: null,
      providerId: undefined,
    },
  });

  const contentPopup: ContentPopup = {
    title: t('patient.patientDashboard.form.warningPopup.title'),
    description: t('patient.patientDashboard.form.warningPopup.description'),
    btnOk: t('patient.patientDashboard.form.warningPopup.btnOk'),
    btnClose: t('patient.patientDashboard.form.warningPopup.btnClose'),
  } as PopupContent;

  const showWarningPopup = async () => {
    setIsOpenPopup(true);
  };

  const resetData = () => {
    setShowPatientEmailForm(false);
    setShowDateForm(false);
    resetField('language');
    resetField('providerId');
    resetField('startDate');
    resetField('endDate');
    resetField('interval');
    setValue('timeFrames', timeFrames[0].validValue);
    setValue('interview', interviews && interviews.length > 0 && interviews[0].interviewTypeId);
    setValue('interviewMode', Object.keys(interviewModes)[0]);
    setValue('providerId', providers[0].id);
    let provider = providers.find((provider) => provider.id === providers[0].id);

    if (providerData && providerData?.defaultProviderId) {
      setValue('providerId', providerData.defaultProviderId);
      provider = providers.find((provider) => provider.id === providerData.defaultProviderId);
    }

    const disabledTrial = provider ? !provider.allowTrial : true;
    setTrialDisabled(disabledTrial);
    setIsProviderAllowTrial(provider?.allowTrial ?? false);
    setTrialChecked(false);
    setValue('trialInterview', false);

    const interviewItem = interviews.find(
      (interview: InterviewType) => interview.interviewTypeId === interviews[0].interviewTypeId
    );
    const isShowTimeFrame = interviewItem ? interviewItem.timeframeForLanguages !== '' : false;
    setShowTimeFrame(isShowTimeFrame);

    setMode(Object.keys(interviewModes)[0]);
    dispatch(setAssessmentMode({ mode: Object.keys(interviewModes)[0] }));
    dispatch(
      setInterviewTypesByLanguage({
        languageCode: getValues('language'),
      })
    );
    setIsOpenPopup(false);
    setPageErrors([]);
  };

  const onRemoveMeasurementPacketList = () => {
    dispatch(removeMeasurementPacketList());
    resetData();
  };

  const onClosePopup = () => {
    setIsOpenPopup(false);
  };

  const handleInterviewModeChange = (e: any) => {
    if (measurementPacketList.length === 0) {
      const modeValue = e.target.value;
      const isScheduledAndEmailMode = isScheduleEnabled && modeValue === AssessmentMode.Email;
      const disabledTrial =
        (isScheduledAndEmailMode && parseInt(getValues('interval')) > 0) || !isProviderAllowTrial;
      setShowPatientEmailForm(isScheduledAndEmailMode);
      setValue('interviewMode', modeValue);
      setTrialDisabled(disabledTrial);
      setMode(modeValue);
      dispatch(setAssessmentMode({ mode: modeValue }));
      dispatch(
        setInterviewTypesByLanguage({
          languageCode: getValues('language'),
        })
      );
    }
  };

  const handleIntervalChange = (e: any) => {
    const intervalValue = e.target.value;
    if (intervalValue !== 0) {
      setShowDateForm(true);
      setValue('trialInterview', false);
      setTrialDisabled(true);
      setTrialChecked(false);
    } else if (isProviderAllowTrial) {
      setShowDateForm(false);
      setTrialDisabled(false);
    }
    setEndDateError('');
    resetField('endDate');
    setValue('interval', intervalValue);
  };

  const renderIntervals = () => {
    return intervals.map((interval, index) => (
      <StyledMenuItem key={interval} value={index}>
        {interval}
      </StyledMenuItem>
    ));
  };

  const bindDataByInterview = useCallback(
    (interviewTypeId: any) => {
      const interviewItem: InterviewType = interviews.find(
        (interview: InterviewType) => interview.interviewTypeId === interviewTypeId
      );

      if (interviewItem) {
        const reportType = interviewItem.reportType;
        const isScheduledAndEmailMode =
          interviewItem?.enableSchedule && getValues('interviewMode') === AssessmentMode.Email;
        const disabledTrial = isScheduledAndEmailMode && parseInt(getValues('interval')) > 0;
        setShowTimeFrame(interviewItem.timeframeForLanguages !== '');
        interviewWithtimeframe.current = interviewItem.timeframeForLanguages !== '';
        setIsScheduleEnabled(!!interviewItem?.enableSchedule);
        setShowPatientEmailForm(!!isScheduledAndEmailMode);
        setIsFormDisabled(reportType === ReportTypes.Ybocs || reportType === ReportTypes.CyBocs);
        if (disabledTrial) {
          setValue('trialInterview', false);
          setTrialChecked(false);
        }
        setTrialDisabled(!!disabledTrial);
        setIsNotSendEmailToClinician(!!interviewItem?.isNotSendEmailToClinician);
      }
      setValue('interview', interviewItem ? interviewTypeId : interviews[0]?.interviewTypeId);
      setValue('timeFrames', timeFrames[0].validValue);
      resetField('interval');
      resetField('endDate');
      setShowDateForm(false);
      setEndDateError('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [interviews]
  );

  useEffect(() => {
    if (interviews && interviews.length > 0) {
      // in interview type id exists in query string -> pre-populate it
      if (interviewTypeId) {
        // delay to ensure interview data are ready
        setTimeout(() => {
          bindDataByInterview(interviewTypeId);
        }, 500);
      } else {
        bindDataByInterview(interviews[0]?.interviewTypeId);
      }
      setPageErrors([]);
    }
    dispatch(setTrialInterview({ trialInterview: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviews, interviewTypeId, bindDataByInterview]);

  useEffect(() => {
    if (providerData) {
      const sortProviders = [...(providerData.providers || [])].sort(
        (a: Provider, b: Provider) =>
          a.lastName?.localeCompare(b.lastName) || a.firstName?.localeCompare(b.firstName)
      );
      setProviders(sortProviders);
      if (sortProviders.length > 0) {
        setValue('providerId', sortProviders[0].id);
        let provider = sortProviders.find((provider) => provider.id === sortProviders[0].id);

        if (providerData.defaultProviderId) {
          setValue('providerId', providerData.defaultProviderId);
          provider = sortProviders.find(
            (provider) => provider.id === providerData.defaultProviderId
          );
        }
        const disabledTrial = provider ? !provider.allowTrial : true;
        setTrialDisabled(disabledTrial);
        setIsProviderAllowTrial(provider?.allowTrial ?? false);
      } else {
        setTrialDisabled(false);
      }
    }
  }, [setValue, providerData, getValues]);

  useEffect(() => {
    if (assessmentModeAcrossComponent) {
      setValue('interviewMode', assessmentModeAcrossComponent);
      setMode(assessmentModeAcrossComponent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentModeAcrossComponent]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      setFormHeight(ref.current.clientHeight);
    }
  });

  const checkHasInterviewsType = (error: string) => {
    if (!interviews || interviews.length < 1) {
      return (
        <Alert key={error} severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      );
    }
  };

  const renderLanguages = () => {
    return languages.map((language: LanguageType) => (
      <StyledMenuItem key={language.code} value={language.code}>
        {language.name}
      </StyledMenuItem>
    ));
  };

  const renderInterviews = () => {
    return interviews.map((interview: InterviewType) => (
      <StyledMenuItem key={interview.interviewTypeId} value={interview.interviewTypeId}>
        {interview.name}
      </StyledMenuItem>
    ));
  };

  const renderTimeframes = () => {
    return timeFrames.map((timeFrame) => (
      <StyledMenuItem key={timeFrame.validValue} value={timeFrame.validValue}>
        {timeFrame.name}
      </StyledMenuItem>
    ));
  };

  const renderMeasurementPacket = (width: string, height?: number) => {
    if (measurementPacketList?.length > 0)
      return (
        <Box sx={{ width: width }}>
          <MeasurementPacketsList
            items={measurementPacketList}
            providers={providers}
            isInInterview
            formHeight={height}
          />
        </Box>
      );
  };

  const renderInterviewModes = () => {
    return Object.keys(interviewModes)
      .filter((mode: string) => {
        if (mode === AssessmentMode.Email && !patientHasEmail && !patientSMSOptIn) {
          return false;
        }
        if (isFormDisabled && (mode === AssessmentMode.Patient || mode === AssessmentMode.Email)) {
          return false;
        }
        return true;
      })
      .map((mode: string) => {
        let label = interviewModes[mode as any];
        if (mode === AssessmentMode.Email && patientSMSOptIn) {
          label = t(
            `patient.patientDashboard.interviewSMSMode.${patientHasEmail ? 'emailAndSMS' : 'sms'}`
          );
        }

        return (
          <FormControlLabel
            disabled={measurementPacketList.length > 0}
            key={mode}
            value={mode}
            control={<Radio />}
            label={label}
          />
        );
      });
  };

  const setInterviewByLanguage = useCallback(
    (e?: any): void => {
      let languageCode = e?.target?.value || defaultLanguage || DefaultLanguage.English;
      if (languageCode === DefaultLanguage.English && languages && languages.length > 0) {
        const findLanguage = languages.find(
          (x: LanguageType) => x.code === DefaultLanguage.English
        );
        if (!findLanguage) {
          languageCode = languages[0].code;
        }
      }

      if (!interviewWithtimeframe.current) {
        setShowTimeFrame(false);
      } else {
        setShowTimeFrame(true);
      }

      if (!defaultLanguage) {
        dispatch(setDefaultLanguage({ languageCode: DefaultLanguage.English }));
      }
      dispatch(setInterviewTypesByLanguage({ languageCode }));

      setValue('timeFrames', timeFrames[0].validValue);
      setValue('providerId', providers[0]?.id);
      let provider = providers?.find((provider) => provider.id === providers[0].id);
      if (providerData && providerData.defaultProviderId) {
        setValue('providerId', providerData.defaultProviderId);
        provider = providers?.find((provider) => provider.id === providerData.defaultProviderId);
      }
      const disabledTrial = provider ? !provider.allowTrial : true;
      setTrialDisabled(disabledTrial);
      setIsProviderAllowTrial(provider?.allowTrial ?? false);
      setValue('trialInterview', false);
      setTrialChecked(false);
      setValue('language', languageCode);
    },
    [defaultLanguage, dispatch, languages, providerData, providers, setValue]
  );

  const getProviderOptionLabel = (option: any) => `${option.lastName}, ${option.firstName}`;

  const filterProviderOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getProviderOptionLabel(option),
  });

  const handleProviderChange = (e: any, values: any) => {
    const selectedState = values ? values.id : '';
    setValue('providerId', selectedState);
    const provider = providers.find((provider) => provider.id === selectedState);
    const disabledTrial = provider
      ? (showPatientEmailForm && parseInt(getValues('interval')) > 0) || !provider.allowTrial
      : true;
    setTrialDisabled(disabledTrial);
    setIsProviderAllowTrial(provider?.allowTrial ?? false);
    if (disabledTrial) {
      setValue('trialInterview', false);
      setTrialChecked(false);
    }
  };

  useEffect(() => {
    setInterviewByLanguage();
  }, [defaultLanguage, setInterviewByLanguage]);

  const handleInterviewChange = (e: any): void => {
    const interviewTypeId = e.target.value;
    bindDataByInterview(interviewTypeId);
  };

  const handleSubmitOption = async (data: InterviewInfo) => {
    const {
      interview,
      interviewMode,
      timeFrames,
      language,
      patientId,
      trialInterview,
      interval,
      startDate,
      endDate,
      providerId,
    } = data;
    if (measurementPacketList) {
      if (measurementPacketList[0]?.trialInterview) {
        dispatch(setTrialInterview({ trialInterview: measurementPacketList[0].trialInterview }));
      }
    } else {
      if (trialInterview) {
        dispatch(setTrialInterview({ trialInterview }));
      }
    }

    const interviewId = parseInt(interview);
    const interviewTimeFrame = timeFrames;
    const interviewLanguage = language;
    const trial = trialInterview;
    const startDateInterview = startDate;
    const endDateInterview = endDate;
    const providerIdInterview = providerId;

    const interviewInfo = {
      interviewId: interviewId,
      interviewTestId: null,
      interviewTimeFrame: interviewTimeFrame,
      interviewMode: interviewsModeCode[interviewMode].modeCode,
      emailInterview: interviewsModeCode[interviewMode].emailCode,
      language: interviewLanguage,
      nViewPatientId: patientId,
      trialInterview: trial,
      interval,
      startDate: startDateInterview,
      endDate: endDateInterview,
      isFromFollowUp: interviewTypeId === parseInt(interview) ? true : false,
      providerId: providerIdInterview,
      measurementPackets: measurementPacketList,
    };

    let requestData = null;

    if (measurementPacketList?.length) {
      requestData = getRequestData(measurementPacketList);
      if (requestData.interviewRequestData) {
        requestData.interviewRequestData = {
          ...interviewInfo,
          ...requestData.interviewRequestData,
        };
      } else if (requestData.monitorRequestData) {
        // These properties are the same for interview and monitor
        requestData.monitorRequestData.monitorMode = interviewInfo.interviewMode;
        requestData.monitorRequestData.emailMonitor = interviewInfo.emailInterview;
        requestData.monitorRequestData.nViewPatientId = interviewInfo.nViewPatientId;
      }
    } else {
      requestData = { interviewRequestData: interviewInfo };
    }

    try {
      let res: any = null;
      if (requestData.interviewRequestData) {
        res = await requestInterview(requestData.interviewRequestData).unwrap();
      } else {
        res = await requestMonitor(requestData.monitorRequestData).unwrap();
      }

      if (res && res.interviewTestId) {
        dispatch(clearPatientMonitorData());
        dispatch(setInterviewMode({ interviewMode, reportType: res.interview?.reportType }));
        dispatch(
          setPatientInterviewData({
            data: {
              interviewTestId: res.interviewTestId,
              accessToken: res.patientInterviewToken ? res.patientInterviewToken : '',
              lastActivityTs: Date.now(),
            },
          })
        );
      } else if (res && res.monitorTestId) {
        dispatch(clearPatientInterviewData());
        dispatch(setMonitorMode({ monitorMode: interviewMode }));
        dispatch(
          setPatientMonitorData({
            data: {
              monitorTestId: res.monitorTestId,
              accessToken: res.patientMonitorToken ? res.patientMonitorToken : '',
              lastActivityTs: Date.now(),
            },
          })
        );
      }

      dispatch(removeMeasurementPacketList());
      dispatch(setAssessmentMode({ mode: AssessmentMode.Clinician }));
      setPageErrors([]);
      return res;
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const onSubmit = async (data: InterviewInfo) => {
    const interviewResult = await handleSubmitOption(data);
    clearAssessmentCachedData();
    if (interviewResult?.interviewTestId) {
      if (data.interviewMode === AssessmentMode.Email && interviewResult) {
        getInterviewToastMessages({
          t,
          toast,
          patientHasEmail,
          patientSMSOptIn,
          isNotSendEmailToClinician,
        });
        resetData();
      }

      if (data.interviewMode !== AssessmentMode.Email && interviewResult) {
        navigate(
          `/dashboard/patient/${patientId}/${interviewResult?.interviewTestId}/start-interview`,
          { state: { resumeInterview: false } }
        );
      }
    } else if (interviewResult?.monitorTestId) {
      if (data.interviewMode === AssessmentMode.Email && interviewResult) {
        getInterviewToastMessages({
          t,
          toast,
          patientHasEmail,
          patientSMSOptIn,
          isNotSendEmailToClinician,
        });
      }
      if (data.interviewMode !== AssessmentMode.Email && interviewResult) {
        navigate(
          `/dashboard/patient/${patientId}/${interviewResult?.monitorTestId}/start-monitor`,
          { state: { resumeMonitor: false } }
        );
      }
    }
  };

  const onAddToPacket = async (data: any) => {
    const {
      interview,
      timeFrames,
      language,
      trialInterview,
      interval,
      startDate,
      endDate,
      providerId,
    } = data;
    const measurementPacket: MeasurementPacket = {
      interviewId: parseInt(interview),
      interviewTimeFrame: showTimeFrame ? timeFrames : undefined,
      language: language,
      trialInterview: trialInterview,
      interval: isScheduleEnabled ? interval : undefined,
      startDate: isScheduleEnabled ? startDate : undefined,
      endDate: isScheduleEnabled ? endDate : undefined,
      isFromFollowUp: interviewTypeId === parseInt(interview) ? true : false,
      providerId: parseInt(providerId),
      order: measurementPacketList.length,
      isExpanded: false,
    };
    const existMeasurementPacket = measurementPacketList.find(
      (item: MeasurementPacket) => item.interviewId === measurementPacket.interviewId
    );
    if (existMeasurementPacket) {
      setPageErrors([t('interview.form.errors.packetAlreadyExists')]);
    } else {
      dispatch(addMeasurementPacket({ measurementPacket: measurementPacket }));
      setPageErrors([]);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        py: 4,
        px: matches ? 4 : 10,
        display: 'flex',
        flexDirection: 'column',
        columnGap: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box
          ref={ref}
          sx={{
            width: commonWidthResponsive(matches),
            px: 2,
          }}
        >
          <Typography fontWeight={600}>
            {t('patient.patientDashboard.form.interviewMode')}
          </Typography>
          <Controller
            name="interviewMode"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={handleInterviewModeChange}
                aria-label="interviewMode"
                name="radio-buttons-group"
              >
                {renderInterviewModes()}
              </RadioGroup>
            )}
          />
          <>
            {languages && languages.length > 0 && (
              <Controller
                name="language"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.language}
                    helperText={errors.language?.message}
                    margin="normal"
                    select
                    fullWidth
                    onChange={setInterviewByLanguage}
                    label={t('patient.patientDashboard.form.language')}
                    variant="outlined"
                  >
                    {renderLanguages()}
                  </TextField>
                )}
              />
            )}
            {haveInterviews && (
              <Controller
                name="interview"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.interview}
                    helperText={errors.interview?.message}
                    margin="normal"
                    select
                    fullWidth
                    onChange={handleInterviewChange}
                    label={t('patient.patientDashboard.form.interview')}
                    variant="outlined"
                  >
                    {renderInterviews()}
                  </TextField>
                )}
              />
            )}
            <Controller
              name="providerId"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <Autocomplete
                  {...props}
                  data-testid="mui-component-select-provider"
                  options={providers || []}
                  getOptionLabel={getProviderOptionLabel}
                  value={value ? providers?.find((item) => item.id === value) || null : null}
                  filterOptions={filterProviderOptions}
                  onChange={handleProviderChange}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {getProviderOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.providerId}
                      helperText={errors.providerId?.message}
                      margin="normal"
                      fullWidth
                      label={t('patient.patientInfo.provider')}
                    />
                  )}
                />
              )}
            />
          </>
          {(hasRestrictedTimeframes || showTimeFrame) && (
            <Controller
              name="timeFrames"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.timeFrames}
                  helperText={errors.timeFrames?.message}
                  margin="normal"
                  select
                  fullWidth
                  label={t('patient.patientDashboard.form.timeFrames')}
                  variant="outlined"
                >
                  {renderTimeframes()}
                </TextField>
              )}
            />
          )}

          <Box sx={{ mt: 2 }}>
            {showPatientEmailForm && (
              <Box>
                <Controller
                  name="interval"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.interval}
                      helperText={errors.interval?.message}
                      margin="normal"
                      select
                      fullWidth
                      label={t('interview.form.interval')}
                      variant="outlined"
                      onChange={handleIntervalChange}
                    >
                      {renderIntervals()}
                    </TextField>
                  )}
                />
                {showDateForm && (
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.tz}>
                    <CommonDatePicker
                      name="startDate"
                      disablePast
                      control={control}
                      error={!!errors.startDate}
                      helperText={errors.startDate?.message}
                      label={t('interview.form.startDate')}
                      hideClearButton
                    />
                    <CommonDatePicker
                      name="endDate"
                      disablePast
                      control={control}
                      error={!!endDateError || !!errors.endDate}
                      helperText={endDateError || errors.endDate?.message}
                      label={t('interview.form.endDate')}
                    />
                  </LocalizationProvider>
                )}
              </Box>
            )}
            {(userRole === UserRoles.Administrator || allowTrial || isProviderAllowTrial) && (
              <Box sx={{ mt: 2 }}>
                <Typography fontWeight={600}>
                  {t('patient.patientDashboard.form.trialInterviewLabel')}
                </Typography>
                <Controller
                  name="trialInterview"
                  control={control}
                  render={({ field }) => (
                    <FormGroup {...field}>
                      <FormControlLabel
                        control={<Checkbox />}
                        disabled={isTrialDisabled || !isProviderAllowTrial}
                        checked={isTrialChecked}
                        onChange={(event: any) => {
                          setTrialChecked(event.target.checked);
                        }}
                        label={`${t('patient.patientDashboard.form.trialInterview')}`}
                      />
                    </FormGroup>
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>
        {!matches &&
          measurementPacketList?.length > 0 &&
          renderMeasurementPacket('50%', formHeight)}
      </Box>
      <Box sx={{ mx: 2 }}>
        {!matches && <Divider sx={{ my: 3 }} />}
        {pageErrors.length > 0 &&
          pageErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ))}
        {checkHasInterviewsType(t('patient.error.blankInterviews'))}
      </Box>
      <Box
        sx={{
          m: 2,
        }}
      >
        <Button
          onClick={handleSubmit(onAddToPacket)}
          variant="contained"
          disabled={
            mode === AssessmentMode.Clinician || measurementPacketList.length > 9 || !haveInterviews
          }
          sx={{
            ...commonButtonStyle(matches),
            mr: 2,
          }}
        >
          {t('patient.patientDashboard.form.addToPacket')}
        </Button>
        <Button
          onClick={showWarningPopup}
          variant="contained"
          disabled={measurementPacketList.length === 0 || !haveInterviews}
          sx={{
            ...commonButtonStyle(matches),
          }}
        >
          {t('patient.patientDashboard.form.deletePacket')}
        </Button>

        {matches && measurementPacketList?.length > 0 && renderMeasurementPacket('100%')}

        <Button
          type="submit"
          variant="contained"
          disabled={!haveInterviews}
          sx={{
            ...commonButtonStyle(matches),
            float: 'right',
          }}
        >
          {t('patient.patientDashboard.form.submit')}
        </Button>
      </Box>
      <LocalPopupModal
        isOpen={isOpenPopup}
        contentPopup={contentPopup}
        onSuccess={onRemoveMeasurementPacketList}
        onClose={onClosePopup}
      />
    </Box>
  );
}
