import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ErrorPage from '../../features/error/ErrorPage';
import DashboardLayout from '../layouts/Dashboard';
import RoundedContainer from './RoundedContainer';

export default function ResumeAssessment() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const theme = useTheme();

  if (!state?.assessmentUrl) {
    return <ErrorPage />;
  }

  return (
    <DashboardLayout showBreadcrumb={false} sessionExpiration={false}>
      <RoundedContainer
        sx={{
          py: 3,
          '& .MuiBox-root': {
            py: 1,
          },
        }}
      >
        <Box>
          <Typography variant="h1">{t('error.secureLogOff')}</Typography>
        </Box>
        <Box>
          <Typography variant="body1">{t('patient.error.assessmentExpire')}</Typography>
        </Box>
        <Box>
          <Link to={state.assessmentUrl} style={{ color: `${theme.palette.primary.main}` }}>
            {t('patient.error.linkToAssessment')}
          </Link>
        </Box>
      </RoundedContainer>
    </DashboardLayout>
  );
}
