import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ThemeProvider, styled } from '@mui/material/styles';
import { useGetTreatmentPlanReportByTokenQuery } from '../../../app/services/treatment-plan/treatmentPlanReport';
import theme, { reportTheme } from '../../../app/theme';
import proemLogo from '../../../assets/images/proem-logo.png';
import DashboardLayout from '../../../common/layouts/Dashboard';
import { getEventTypeListByKey } from '../../../common/utils/auditLogType';
import {
  formatDate,
  formatDateTimeByPattern,
  formatServerDateAndTime,
} from '../../../common/utils/dateTimeUtils';
import { HttpStatusCode } from '../../../common/utils/httpStatusCode';
import { getPageTitle } from '../../../common/utils/pageUtils';
import { toTitleCase } from '../../../common/utils/stringUtils';
import {
  goalProgressListWithKey,
  goalStatusListWithKey,
  truncate,
} from '../../../common/utils/treatmentPlans';
import { AuditLogItem } from '../../../types/AuditLog';
import { PageProps } from '../../../types/PageProps';
import { GoalItem } from '../../../types/treatment-plan/GoalType';
import { ObjectiveItem } from '../../../types/treatment-plan/ObjectiveType';
import { StrategyItem } from '../../../types/treatment-plan/StrategyType';
import { TreatmentPlanDiagnosisItem } from '../../../types/treatment-plan/TreatmentPlanDiagnosisType';
import { TreatmentPlanReportResponse } from '../../../types/treatment-plan/TreatmentPlanReportType';
import { TreatmentSignature } from '../../../types/treatment-plan/TreatmentPlanType';
import {
  TreatmentSignatureProviderType,
  TreatmentSignatureStatus,
  TreatmentSignatureType,
} from '../../../types/treatment-plan/TreatmentSignature';
import ReportPageBreak from '../../assessmentReport/reportModules/ReportPageBreak';
import ErrorPage from '../../error/ErrorPage';
import { PatientTreatmentAuditLogTypes } from '../TreatmentPlanAuditLogs';
import { Status } from '../TreatmentPlanListPage';

const eventTypeListByKey = getEventTypeListByKey();
const currentDate = dayjs(new Date()).format('MM/DD/YYYY');
const borderStyle = `1px solid ${theme.xPalette.black}`;
const StyleTitleSection = styled(Typography)(() => ({
  fontSize: '20px',
}));

const StyleBox = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingLeft: '20px',
  border: `2px solid ${theme.xPalette.black}`,
  lineHeight: '35px',
  minHeight: '130px',
  display: 'flex',
  alignItems: 'center',
}));

const NotBreakPageBox = styled(Box)(() => ({
  pageBreakInside: 'avoid',
}));

export default function TreatmentPlanReportPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const [displayTreatmentPlanData, setDisplayTreatmentPlanData] = useState<
    TreatmentPlanReportResponse | undefined
  >(undefined);
  const [displaySignatureData, setDisplaySignatureData] = useState<TreatmentSignature[]>([]);
  const [displayGoalData, setDisplayGoalData] = useState<GoalItem[]>([]);
  const [searchParams] = useSearchParams();
  const token = searchParams?.get('token') || null;
  const diagnosisHeaders = Object.values(
    t('treatmentPlans.report.diagnosisSection.headers', {
      returnObjects: true,
    })
  );
  const goalInfoHeaders = Object.entries(
    t('treatmentPlans.report.goalObjectivesStrategiesSection.goalHeaders.info', {
      returnObjects: true,
    })
  );
  const goalDetailHeaders = Object.entries(
    t('treatmentPlans.report.goalObjectivesStrategiesSection.goalHeaders.details', {
      returnObjects: true,
    })
  );
  const objectiveDetailHeaders = Object.entries(
    t('treatmentPlans.report.goalObjectivesStrategiesSection.objectiveHeaders.details', {
      returnObjects: true,
    })
  );
  const auditLogHeaders = Object.values(
    t('treatmentPlans.report.auditLogSection.headers', {
      returnObjects: true,
    })
  );
  const { data: treatmentPlanData, error } = useGetTreatmentPlanReportByTokenQuery(
    token ? { token } : skipToken
  );

  const {
    patient,
    name: treatmentPlanName,
    treatmentDiagnoses,
    symptoms,
    patientStrengths,
    patientAbilities,
    patientNeedsLimitations,
    patientPreferences,
    problemPatientWords,
    problemPatientWordsIfNeeded,
    startDate,
    status,
    projectedCompletionDate,
    completionDate,
    nextReviewDate,
    treatmentGoals,
    clinicalIssues,
    medicalIssues,
    psychiatricIssues,
    completionCriteria,
    treatmentSignatures,
    auditLogs,
  } = displayTreatmentPlanData || {};

  const patientName = patient && `${patient.firstName} ${patient.lastName}`;

  useEffect(() => {
    if (treatmentPlanData?.data) {
      setDisplayTreatmentPlanData(treatmentPlanData.data);
    }
  }, [treatmentPlanData]);

  useEffect(() => {
    if (treatmentGoals) {
      const sortedData = treatmentGoals
        .slice()
        .sort((a: GoalItem, b: GoalItem) => a.description.localeCompare(b.description));
      setDisplayGoalData(sortedData);
    }
  }, [treatmentGoals]);

  useEffect(() => {
    if (treatmentSignatures) {
      const sortedData = treatmentSignatures
        .slice()
        .sort((a: TreatmentSignature, b: TreatmentSignature) =>
          a.signatureProviderType.localeCompare(b.signatureProviderType)
        );
      setDisplaySignatureData(sortedData);
    }
  }, [treatmentSignatures]);

  if (!token || error) {
    return <ErrorPage statusCode={HttpStatusCode.NotFound} />;
  }

  const renderLogo = () => {
    if (treatmentPlanData?.data?.logoUrl) {
      return <img src={treatmentPlanData?.data?.logoUrl} width="220px" alt="Company Logo" />;
    } else {
      return <img src={proemLogo} width="220px" alt="Proem Logo" />;
    }
  };

  const renderDiagnosisTable = (items: TreatmentPlanDiagnosisItem[], columns: any) => {
    const displayItems = items.map((item) => {
      return {
        displayName: item.diagnosisName || item.otherDiagnosis,
        timeFrame: item.timeframe,
        created: formatServerDateAndTime(item.createdAt),
      };
    });
    return (
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col: string, colIdx: number) => (
              <TableCell
                key={colIdx}
                sx={{
                  border: borderStyle,
                  backgroundColor: theme.xPalette.bodyBackground,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  fontSize={'16px'}
                  dangerouslySetInnerHTML={{
                    __html: col,
                  }}
                ></Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayItems?.map((item, rowIdx: number) => (
            <TableRow key={rowIdx}>
              {Object.values(item).map((value, valueIdx: number) => (
                <TableCell key={valueIdx} sx={{ border: borderStyle }}>
                  {value || '-'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderStrategyData = (strategyData: StrategyItem[]) => {
    const sortedData = strategyData
      .slice()
      .sort((a: StrategyItem, b: StrategyItem) => a.description.localeCompare(b.description));
    return (
      <>
        {sortedData?.map((strategyItem) => (
          <>
            <Typography marginTop={1} display="flex">
              <Typography fontWeight={700}>
                {t('treatmentPlans.report.goalObjectivesStrategiesSection.strategyHeaders.title')}
                &nbsp;
              </Typography>
              {strategyItem.description}
            </Typography>
            <Box marginLeft={2} display="flex">
              {strategyItem.providedByData && (
                <Typography marginTop={1} display="flex" minWidth="15%" marginRight={2}>
                  <Typography fontWeight={700}>
                    {t(
                      'treatmentPlans.report.goalObjectivesStrategiesSection.strategyHeaders.details.providedBy'
                    )}
                    &nbsp;
                  </Typography>
                  {strategyItem.providedByData?.name}
                </Typography>
              )}
              {strategyItem.frequency && (
                <Typography marginTop={1} display="flex" minWidth="15%">
                  <Typography fontWeight={700}>
                    {t(
                      'treatmentPlans.report.goalObjectivesStrategiesSection.strategyHeaders.details.frequency'
                    )}
                    &nbsp;
                  </Typography>
                  {truncate(strategyItem.frequency, 180)}
                </Typography>
              )}
            </Box>
          </>
        ))}
      </>
    );
  };

  const renderObjectiveData = (objectiveData: ObjectiveItem[]) => {
    const sortedData = objectiveData
      .slice()
      .sort((a: ObjectiveItem, b: ObjectiveItem) => a.objective.localeCompare(b.objective));
    return (
      <>
        {sortedData.map((objectiveItem) => (
          <>
            <Typography marginTop={1} display="flex">
              <Typography fontWeight={700}>
                {t('treatmentPlans.report.goalObjectivesStrategiesSection.objectiveHeaders.title')}
                &nbsp;
              </Typography>
              {objectiveItem.objective}
            </Typography>
            <Box display="flex">
              {objectiveDetailHeaders.map(([key, name], index) => {
                return (
                  <Typography key={`${key} ${index}`} marginTop={1} display="flex" marginRight={2}>
                    <Typography fontWeight={700}>{name}&nbsp;</Typography>
                    {key === 'dateDue' || key === 'statusDate'
                      ? formatDate(objectiveItem[key])
                      : goalStatusListWithKey[objectiveItem[key]]}
                  </Typography>
                );
              })}
            </Box>
            {objectiveItem.strategies && renderStrategyData(objectiveItem.strategies)}
          </>
        ))}
      </>
    );
  };

  const renderGoalData = (goalData: GoalItem[]) => {
    return (
      <Box>
        {goalData.map((goalItem: GoalItem) => (
          <Box marginTop={5}>
            <Box>
              {goalInfoHeaders.map(([key, name], index) => {
                return (
                  <Typography key={`${key} ${index}`} marginTop={1} display="flex">
                    <Typography fontWeight={700}>{name}&nbsp;</Typography>
                    {goalItem[key]}
                  </Typography>
                );
              })}
            </Box>
            <Box display="flex">
              {goalDetailHeaders.map(([key, name], index) => {
                return (
                  <>
                    {goalItem[key] && (
                      <Typography
                        key={`${key} ${index}`}
                        marginTop={1}
                        display="flex"
                        minWidth="15%"
                        marginRight={2}
                      >
                        <Typography fontWeight={700}>{name}&nbsp;</Typography>
                        {key === 'startDate' || key === 'statusDate'
                          ? formatDate(goalItem[key])
                          : key === 'progress'
                          ? goalProgressListWithKey[goalItem[key]]
                          : goalStatusListWithKey[goalItem[key]]}
                      </Typography>
                    )}
                  </>
                );
              })}
            </Box>
            <Box marginLeft={2}>
              {goalItem?.objectives && renderObjectiveData(goalItem?.objectives)}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderDataSignatureList = (displayItems: TreatmentSignature[]) => {
    return (
      <Box
        sx={{
          width: '100%',
        }}
      >
        {displayItems.map((item: TreatmentSignature) => {
          return (
            <Box
              key={item.id}
              sx={{
                marginTop: 3,
              }}
            >
              <Box
                sx={{
                  marginBottom: 1,
                  display: 'flex',
                  columnGap: 2,
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <Typography>
                  <b>{toTitleCase(item?.signatureProviderType)}</b>
                  {`: ${
                    item?.signatureProviderType === TreatmentSignatureProviderType.Patient &&
                    (item.signatureType === TreatmentSignatureType.Participate ||
                      item.signatureType === TreatmentSignatureType.Email) &&
                    item.status === TreatmentSignatureStatus.Signed
                      ? t('treatmentPlans.signatureForm.signatureTypes.participate')
                      : ''
                  }`}
                </Typography>
              </Box>

              {item?.status === TreatmentSignatureStatus.Signed &&
                item?.signature &&
                item?.signature !== '' && <img width="30%" src={item?.signature} alt="" />}
              {item?.status !== TreatmentSignatureStatus.Signed && (
                <Box
                  sx={{
                    bgcolor: theme.xPalette.noteLightGrey,
                    width: '30%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: 0.6,
                    aspectRatio: '82/25',
                  }}
                >
                  <Typography
                    fontWeight="700"
                    align="center"
                    color={item?.status === TreatmentSignatureStatus.Refused ? 'red' : ''}
                    fontSize="30px!important"
                  >
                    {t(`treatmentPlans.signatureForm.${item?.status}`).toUpperCase()}
                  </Typography>
                </Box>
              )}
              <Typography display="flex" marginTop={1}>
                {item?.signatureProviderType === TreatmentSignatureProviderType.Patient
                  ? patientName
                  : item.providerName}
                <Typography marginLeft="20px">{`Date: ${formatDate(item.createdAt)}`}</Typography>
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderAuditLogTable = (items: AuditLogItem[], columns: any) => {
    const displayItems = items.map((item) => {
      return {
        date: formatDateTimeByPattern({
          dateValue: item?.updatedAt,
          pattern: 'MM/DD/YYYY hh:mm a',
          validPatterns: ['MM/DD/YYYY hh:mm a'],
        }),
        user: PatientTreatmentAuditLogTypes.includes(item?.actionType)
          ? t('treatmentPlans.auditLog.patient')
          : item?.actionByLName && item?.actionByFName
          ? `${item?.actionByLName}, ${item?.actionByFName}`
          : `${item?.actionByLName || item?.actionByFName}`,
        eventType: eventTypeListByKey[`${item.actionType}-${item.actionRole}`],
        name: item.targetName,
      };
    });
    return (
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col: string, colIdx: number) => (
              <TableCell
                key={colIdx}
                sx={{
                  border: borderStyle,
                  backgroundColor: theme.xPalette.bodyBackground,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  fontSize={'16px'}
                  dangerouslySetInnerHTML={{
                    __html: col,
                  }}
                ></Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayItems?.map((item, rowIdx: number) => (
            <TableRow key={rowIdx}>
              {Object.values(item).map((value, valueIdx: number) => (
                <TableCell
                  key={valueIdx}
                  sx={{ minWidth: valueIdx < 2 ? '20%' : '30%', border: borderStyle }}
                >
                  {value || '-'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <ThemeProvider theme={reportTheme}>
      <CssBaseline />
      <DashboardLayout
        showBreadcrumb={false}
        showCopyright={false}
        showHeader={false}
        background={reportTheme.xPalette.white}
      >
        <Helmet>
          <title>{getPageTitle(t('treatmentPlans.report.title'))}</title>
        </Helmet>
        <Container sx={{ width: '900px' }} disableGutters={true}>
          <Box marginTop="30px">{renderLogo()}</Box>
          <Box marginTop={1}>
            <StyleTitleSection display="flex">
              <StyleTitleSection fontWeight={700}>
                {t('treatmentPlans.report.infoSection.title')}&nbsp;
              </StyleTitleSection>
              {treatmentPlanName}
            </StyleTitleSection>
            <Typography marginTop={1} display="flex" gap={2}>
              <Box display="flex">
                <Typography fontWeight={700}>
                  {t('treatmentPlans.report.infoSection.name')}&nbsp;
                </Typography>
                {patientName}
              </Box>
              <Box display="flex">
                <Typography fontWeight={700}>
                  {t('treatmentPlans.report.infoSection.patientId')}&nbsp;
                </Typography>
                {patient?.id}
              </Box>
              <Box display="flex">
                <Typography fontWeight={700}>
                  {t('treatmentPlans.report.infoSection.externalId')}&nbsp;
                </Typography>
                {patient?.externalId || ''}
              </Box>
              <Box display="flex">
                <Typography fontWeight={700}>
                  {t('treatmentPlans.report.infoSection.dob')}&nbsp;
                </Typography>
                {patient?.birthdate || ''}
              </Box>
            </Typography>
            <Typography marginTop={1} display="flex">
              <Typography fontWeight={700}>
                {t('treatmentPlans.report.infoSection.printDate')}&nbsp;
              </Typography>
              {currentDate}
            </Typography>
          </Box>
          <NotBreakPageBox marginTop={1}>
            <StyleTitleSection fontWeight={700}>
              {t('treatmentPlans.report.diagnosisSection.title')}
            </StyleTitleSection>
            <Box marginY={1}>
              {renderDiagnosisTable(treatmentDiagnoses || [], diagnosisHeaders)}
            </Box>
          </NotBreakPageBox>
          <NotBreakPageBox marginTop={2}>
            <Typography fontWeight={700}>
              {t('treatmentPlans.report.patientSymptomsSection.title')}
            </Typography>
            <StyleBox whiteSpace="pre-wrap" marginTop={1}>
              {symptoms}
            </StyleBox>
          </NotBreakPageBox>

          <Box marginTop={2}>
            <StyleTitleSection fontWeight={700}>
              {t('treatmentPlans.report.patientAssessmentSection.title')}
            </StyleTitleSection>
            <NotBreakPageBox>
              <Typography marginTop={1} fontWeight={700}>
                {t('treatmentPlans.report.patientAssessmentSection.patientStrengths')}
              </Typography>
              <StyleBox>{patientStrengths}</StyleBox>
            </NotBreakPageBox>
            <NotBreakPageBox>
              <Typography marginTop={2} fontWeight={700}>
                {t('treatmentPlans.report.patientAssessmentSection.patientAbilities')}
              </Typography>
              <StyleBox>{patientAbilities}</StyleBox>
            </NotBreakPageBox>
            <NotBreakPageBox>
              <Typography marginTop={2} fontWeight={700}>
                {t('treatmentPlans.report.patientAssessmentSection.patientNeedLimitations')}
              </Typography>
              <StyleBox>{patientNeedsLimitations}</StyleBox>
            </NotBreakPageBox>
            <NotBreakPageBox>
              <Typography marginTop={2} fontWeight={700}>
                {t('treatmentPlans.report.patientAssessmentSection.patientPreferences')}
              </Typography>
              <StyleBox>{patientPreferences}</StyleBox>
            </NotBreakPageBox>
          </Box>

          <Box marginTop={2}>
            <StyleTitleSection fontWeight={700}>
              {t('treatmentPlans.report.treatmentPlanSection.title')}
            </StyleTitleSection>
            <NotBreakPageBox>
              <Typography marginTop={1} fontWeight={700}>
                {t('treatmentPlans.report.treatmentPlanSection.treatmentPlanNameOrSubject')}
              </Typography>
              <StyleBox>{treatmentPlanName}</StyleBox>
            </NotBreakPageBox>
            <NotBreakPageBox>
              <Typography marginTop={2} fontWeight={700}>
                {t('treatmentPlans.report.treatmentPlanSection.problemInPatientWords')}
              </Typography>
              <StyleBox>{problemPatientWords}</StyleBox>
            </NotBreakPageBox>
            <NotBreakPageBox>
              <Typography marginTop={2} fontWeight={700}>
                {t('treatmentPlans.report.treatmentPlanSection.problemInParentWords')}
              </Typography>
              <StyleBox>{problemPatientWordsIfNeeded}</StyleBox>
            </NotBreakPageBox>
          </Box>

          <ReportPageBreak />

          <Box display="flex" flexWrap="wrap" marginTop={2}>
            <Typography marginTop={1} display="flex" width="50%">
              <Typography fontWeight={700}>
                {t('treatmentPlans.report.treatmentPlanSection.treatmentPlanStartDate')}&nbsp;
              </Typography>
              {formatDate(startDate)}
            </Typography>
            <Typography marginTop={1} display="flex" width="50%">
              <Typography fontWeight={700}>
                {t('treatmentPlans.report.treatmentPlanSection.treatmentStatus')}&nbsp;
              </Typography>
              {status && Object.keys(Status).includes(status) ? Status[`${status}`] : ''}
            </Typography>
            <Typography marginTop={1} display="flex" width="50%">
              <Typography fontWeight={700}>
                {t('treatmentPlans.report.treatmentPlanSection.projectedCompletionDate')}&nbsp;
              </Typography>
              {formatDate(projectedCompletionDate)}
            </Typography>
            <Typography marginTop={1} display="flex" width="50%">
              <Typography fontWeight={700}>
                {t('treatmentPlans.report.treatmentPlanSection.completionDate')}&nbsp;
              </Typography>
              {formatDate(completionDate)}
            </Typography>
            <Typography marginTop={1} display="flex" width="50%">
              <Typography fontWeight={700}>
                {t('treatmentPlans.report.treatmentPlanSection.nextReviewDate')}&nbsp;
              </Typography>
              {formatDate(nextReviewDate)}
            </Typography>
          </Box>

          {renderGoalData(displayGoalData)}

          <ReportPageBreak />

          <Box marginTop={1}>
            <StyleTitleSection fontWeight={700}>
              {t('treatmentPlans.report.postTreatmentPlanSection.title')}
            </StyleTitleSection>
            <NotBreakPageBox>
              <Typography marginTop={1} fontWeight={700}>
                {t('treatmentPlans.report.postTreatmentPlanSection.headers.clinicalIssues')}
              </Typography>
              <StyleBox>{clinicalIssues}</StyleBox>
            </NotBreakPageBox>
            <NotBreakPageBox>
              <Typography marginTop={2} fontWeight={700}>
                {t('treatmentPlans.report.postTreatmentPlanSection.headers.medicalIssues')}
              </Typography>
              <StyleBox>{medicalIssues}</StyleBox>
            </NotBreakPageBox>
            <NotBreakPageBox>
              <Typography marginTop={2} fontWeight={700}>
                {t('treatmentPlans.report.postTreatmentPlanSection.headers.psychiatricIssues')}
              </Typography>
              <StyleBox>{psychiatricIssues}</StyleBox>
            </NotBreakPageBox>
            <NotBreakPageBox>
              <Typography marginTop={2} fontWeight={700}>
                {t('treatmentPlans.report.postTreatmentPlanSection.headers.completionCriteria')}
              </Typography>
              <StyleBox>{completionCriteria}</StyleBox>
            </NotBreakPageBox>
          </Box>
          {treatmentSignatures && (
            <>
              <ReportPageBreak />
              <Box marginTop={3}>
                <StyleTitleSection fontWeight={700}>
                  {t('treatmentPlans.report.signatureSection.title')}
                </StyleTitleSection>
                {renderDataSignatureList(displaySignatureData)}
              </Box>
            </>
          )}
          {auditLogs && (
            <>
              <ReportPageBreak />
              <Box marginY={2}>
                <StyleTitleSection fontWeight={700} marginBottom={2}>
                  {t('treatmentPlans.report.auditLogSection.title')}
                </StyleTitleSection>
                {renderAuditLogTable(auditLogs, auditLogHeaders)}
              </Box>
            </>
          )}
        </Container>
      </DashboardLayout>
    </ThemeProvider>
  );
}
