import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { skipToken, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  useAddAccountMutation,
  useEditAccountMutation,
  useGetAccountInfoQuery,
} from '../../app/services/account';
import { useToast } from '../../app/toast';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import DashboardLayout from '../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { AccountFormInfo, InterviewTypeItem, MonitorTypeItem } from '../../types/AccountType';
import { PageProps } from '../../types/PageProps';
import ErrorPage from '../error/ErrorPage';
import AccountForm from './AccountForm';

export default function AddOrEditAccountPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const params = useParams();
  const accountId: string | undefined = params.accountId;
  const navigate = useNavigate();
  const toast = useToast();

  const [editAccount] = useEditAccountMutation();
  const [addAccount] = useAddAccountMutation();

  const {
    data: accountInfoData,
    error,
    isLoading: loadAccountData,
    refetch: refetchEditAccount,
  } = useGetAccountInfoQuery(!!accountId ? { accountId } : skipToken);

  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const newBreadcrumb: BreadcrumbType = {
    text: t('account.manageAccounts.title'),
    link: navBackUrl || `/dashboard/accounts`,
  };

  const onFormSubmit = async (
    data: AccountFormInfo,
    interviewTypesData?: any,
    monitorTypesData?: any,
    accountId?: string,
    successCallback?: () => void
  ) => {
    if (accountId) {
      const interviewTypes = interviewTypesData?.map((interview: InterviewTypeItem) => ({
        interviewOrMonitorType: interview.id,
        order: interview.order,
      }));
      const monitorTypes = monitorTypesData?.map((monitor: MonitorTypeItem) => ({
        interviewOrMonitorType: monitor.id,
        order: monitor.order,
      }));
      const editInfo = {
        accountId,
        accountName: data.accountName,
        accountType: data.accountType,
        exportSetting: data.exportSetting,
        automateEmail: data.automateEmail,
        automateIntakePacket: data.automateIntakePacket,
        mfaEnabled: data.mfaEnabled,
        followUpEnabled: data.followUpEnabled,
        followUpReportEnabled: data.followUpReportEnabled,
        tokenForAdminOnly: data.tokenForAdminOnly,
        whiteLabel: data.whiteLabel,
        externalLink: data.externalLink,
        interviewTypes,
        monitorTypes,
        identityProviderId: data.identityProviderId,
        ehrEnabled: data.ehrEnabled,
        smsEnabled: data.smsEnabled,
        taraEnabled: data.taraEnabled,
        organizationId: data.ehrEnabled ? data.organizationId : undefined,
        ehrType: data.ehrEnabled ? data.ehrType : undefined,
        sendPdfEhr: data.ehrEnabled ? data.sendPdfEhr : undefined,
        sendPatientReportEhr: data.ehrEnabled ? data.sendPatientReportEhr : undefined,
        removeDays: data?.removeDays,
      };
      await editAccount(editInfo).unwrap();
      refetchEditAccount();
    } else {
      const addInfo = {
        accountName: data.accountName,
        accountType: data.accountType,
        exportSetting: data.exportSetting,
        automateEmail: data.automateEmail,
        automateIntakePacket: data.automateIntakePacket,
        mfaEnabled: data.mfaEnabled,
        followUpEnabled: data.followUpEnabled,
        followUpReportEnabled: data.followUpReportEnabled,

        siteName: data.siteName,
        siteLocation: data.siteLocation,
        siteCountry: data.siteCountry,
        siteAddressLine1: data.siteAddressLine1,
        siteAddressLine2: data.siteAddressLine2,
        siteCity: data.siteCity,
        siteState: data.siteState,
        siteZipCode: data.siteZipCode,
        siteTimezone: data.siteTimezone,

        userFirstName: data.userFirstName,
        userLastName: data.userLastName,
        userEmail: data.userEmail,
        userPhone: data.userPhone,
        userCellPhone: data.userCellPhone,
        mfaType: parseInt(data.mfaType),

        ehrEnabled: data.ehrEnabled,
        smsEnabled: data.smsEnabled,
        taraEnabled: data.taraEnabled,
        organizationId: data.ehrEnabled ? data.organizationId : undefined,
        ehrType: data.ehrEnabled ? data.ehrType : undefined,
        sendPdfEhr: data.ehrEnabled ? data.sendPdfEhr : undefined,
        sendPatientReportEhr: data.ehrEnabled ? data.sendPatientReportEhr : undefined,
        removeDays: data?.removeDays,
      };

      await addAccount(addInfo).unwrap();
      navigate('/dashboard/accounts');
    }

    if (successCallback) {
      successCallback();
    }

    toast.publish(
      accountId
        ? t('account.manageAccounts.updateSuccess')
        : t('account.manageAccounts.addSuccess'),
      'success',
      5000
    );
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  const isLoading = loadAccountData;

  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>
          {accountId
            ? getPageTitle(t('account.manageAccounts.editAccount'))
            : getPageTitle(t('account.manageAccounts.addAccount'))}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={
            accountId
              ? t('account.manageAccounts.editAccount')
              : t('account.manageAccounts.addAccount')
          }
          backUrl={navBackUrl || `/dashboard/accounts`}
        />
      </Container>

      {isLoading ? (
        <Box sx={{ my: 2 }}>
          <Loading />
        </Box>
      ) : (
        <AccountForm accountId={accountId} data={accountInfoData} onFormSubmit={onFormSubmit} />
      )}
    </DashboardLayout>
  );
}
