import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { accountApis, useTransferSingleAssessmentMutation } from '../../app/services/account';
import theme from '../../app/theme';
import { useToast } from '../../app/toast';
import RoundedContainer from '../../common/components/RoundedContainer';
import SubmitButton from '../../common/components/SubmitButton';
import { ImportType } from '../../common/utils/assessmentType';
import { getPatientName } from '../../common/utils/patientUtils';
import { ClinicAccountItems } from '../../types/ClinicAccountType';
import {
  CustomPatient,
  LatestAssessmentByPatientItem,
  LatestInterviewResultsByPatientResponse,
  TransferAssessmentRequest,
} from '../../types/PatientRequest';
import { LatestMonitorResultsByPatientResponse } from '../../types/PatientType';
import { ResponseError } from '../../types/ResponseError';

const getCommonOptionLabel = (option: any) => (option?.id ? `${option.name} (${option.id})` : '');
const getPatientOptionLabel = (option: any) => {
  let optionText = '';
  if (option?.id) {
    if (!!option.externalId && !option.lastName && !option.firstName) {
      optionText = `${option.externalId} (${option.id})`;
    } else if (!option.lastName && !option.firstName) {
      optionText = ` (${option.id})`;
    } else {
      optionText = `${option.lastName}, ${option.firstName} (${option.id})`;
    }
  }

  return optionText;
};

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ImportTypes: { [key: string]: string } = {
  Interview: ImportType.Interview,
  Monitor: ImportType.Monitor,
};

const defaultValues: {
  patientSrc: string;
  patientDest: string;
  clinicAccountSrc: string;
  clinicAccountDest: string;
  assessmentType: string;
  transferAssessmentResult?: number;
} = {
  patientSrc: '',
  patientDest: '',
  clinicAccountSrc: '',
  clinicAccountDest: '',
  assessmentType: ImportTypes.Interview,
};

interface Props {
  triggerGetInterviewResults: LazyQueryTrigger<
    QueryDefinition<
      {
        patientIds: string;
      },
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      string,
      LatestInterviewResultsByPatientResponse,
      string
    >
  >;
  triggerGetMonitorResults: LazyQueryTrigger<
    QueryDefinition<
      {
        patientIds: string;
      },
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      string,
      LatestMonitorResultsByPatientResponse,
      string
    >
  >;
  accountData: ClinicAccountItems[];
  interviewData: LatestAssessmentByPatientItem[];
  monitorData: LatestAssessmentByPatientItem[];
}

export default function TransferAssessmentForm({
  triggerGetInterviewResults,
  triggerGetMonitorResults,
  accountData,
  interviewData,
  monitorData,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [srcAndDestAccount, setSrcAndDestAccount] = useState<{
    src?: string;
    dest?: string;
  }>({});

  const [srcPatients, setSrcPatients] = useState<CustomPatient[]>([]);
  const [destPatients, setDestPatients] = useState<CustomPatient[]>([]);
  const [srcRequestInfo, setSrcRequestInfo] = useState<{ type?: string; patientId?: string }>({
    type: defaultValues.assessmentType,
  });

  const srcAssessments =
    srcRequestInfo.type === ImportTypes.Interview ? interviewData : monitorData;

  const [transferSingleAssessment] = useTransferSingleAssessmentMutation();

  const validationSchema = yup
    .object({
      clinicAccountSrc: yup.string().required(t('transferAssessment.error.blankAccount')),
      clinicAccountDest: yup.string().required(t('transferAssessment.error.blankAccount')),
      patientSrc: yup.string().required(t('patient.error.blankPatient')),
      patientDest: yup.string().required(t('patient.error.blankPatient')),
      transferAssessmentResult: yup
        .string()
        .required(t('transferAssessment.error.blankAssessmentResult')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const getAndSortPatientNameByAccountId = async (clinicAccountId: string) => {
    const query = await dispatch(
      accountApis.getPatients.initiate({
        clinicAccountId,
      })
    );

    return (
      query?.data?.patients
        ?.slice()
        .sort((a: CustomPatient, b: CustomPatient) =>
          getPatientName(a).toLowerCase() > getPatientName(b).toLowerCase() ? 1 : -1
        ) || []
    );
  };

  useEffect(() => {
    if (srcRequestInfo.type === ImportType.Interview && srcRequestInfo.patientId) {
      triggerGetInterviewResults({ patientIds: srcRequestInfo.patientId });
    } else if (srcRequestInfo.type === ImportType.Monitor && srcRequestInfo.patientId) {
      triggerGetMonitorResults({ patientIds: srcRequestInfo.patientId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [srcRequestInfo.patientId, srcRequestInfo.type]);

  const handleAccountSrcChange = async (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccountSrc', selectedValue);

    const srcPatients = await getAndSortPatientNameByAccountId(selectedValue);

    setSrcPatients(srcPatients);
    setSrcAndDestAccount({
      ...srcAndDestAccount,
      src: selectedValue,
    });
    setSrcRequestInfo({ ...srcRequestInfo, patientId: '' });
    setValue('transferAssessmentResult', '');
    setValue('patientSrc', '');
  };

  const handleAccountDestChange = async (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccountDest', selectedValue);

    const destPatients = await getAndSortPatientNameByAccountId(selectedValue);

    setDestPatients(destPatients);
    setSrcAndDestAccount({
      ...srcAndDestAccount,
      dest: selectedValue,
    });
    setValue('patientDest', '');
  };

  const handlePatientSrcChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('patientSrc', selectedValue);
    setValue('transferAssessmentResult', '');
    setSrcRequestInfo({ ...srcRequestInfo, patientId: selectedValue });
  };

  const handlePatientDestChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('patientDest', selectedValue);
  };

  const handleAssessmentSrcChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('transferAssessmentResult', selectedValue);
  };

  const handleAssessmentTypeChange = (e: any) => {
    const value = e.target.value;
    setValue('assessmentType', value);
    setValue('transferAssessmentResult', '');
    setSrcRequestInfo({ ...srcRequestInfo, type: value });
    setPageErrors([]);
  };

  const onSubmit = async (data: any) => {
    const body = {
      patientDest: data.patientDest,
      patientSrc: data.patientSrc,
    } as TransferAssessmentRequest;

    if (srcRequestInfo.type === ImportTypes.Interview) {
      body.interviewResultId = data.transferAssessmentResult;
    } else {
      body.monitorResultId = data.transferAssessmentResult;
    }

    try {
      const res = await transferSingleAssessment(body).unwrap();
      if (res) {
        toast.publish(res.message || t('transferAssessment.success'), 'success');
      }
      reset();
      setSrcRequestInfo({ type: ImportTypes.Interview });
      setPageErrors([]);
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <RoundedContainer sx={{ pt: 3 }}>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          px: 0,
          pt: 0,
        }}
      >
        <RoundedContainer>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Controller
              name="clinicAccountSrc"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  sx={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                  data-testid="mui-component-select-clinicAccountSrc"
                  options={accountData}
                  getOptionLabel={getCommonOptionLabel}
                  onChange={handleAccountSrcChange}
                  value={value ? accountData.find((item) => item.id === value) || [] : null}
                  renderOption={(props, option: any) => {
                    return (
                      <li
                        {...props}
                        key={option.id}
                        value={option.id}
                        data-testid={`accountSrc-${option.id}`}
                      >
                        {getCommonOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.clinicAccountSrc}
                      helperText={errors.clinicAccountSrc?.message}
                      fullWidth
                      label={t('transferAssessment.form.scrAccount')}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="patientSrc"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  sx={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                  data-testid="mui-component-select-scrPatient"
                  options={srcPatients}
                  getOptionLabel={getPatientOptionLabel}
                  onChange={handlePatientSrcChange}
                  value={value ? srcPatients.find((item) => item.id === value) || [] : null}
                  renderOption={(props, option: any) => {
                    return (
                      <li
                        {...props}
                        key={option.id}
                        value={option.id}
                        data-testid={`patient-src-${option.id}`}
                      >
                        {getPatientOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.patientSrc}
                      helperText={errors.patientSrc?.message}
                      fullWidth
                      label={t('transferAssessment.form.scrPatient')}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="assessmentType"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    width: '49%',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    [theme.breakpoints.down('md')]: {
                      width: '100%',
                    },
                  }}
                  margin="normal"
                  select
                  fullWidth
                  label={t('transferAssessment.form.assessmentType')}
                  data-testid="mui-component-select-assessmentType"
                  error={!!errors.assessmentType}
                  helperText={errors.assessmentType?.message}
                  variant="outlined"
                  onChange={handleAssessmentTypeChange}
                >
                  {Object.keys(ImportTypes).map((type: string) => (
                    <MenuItem key={type} value={type} data-testid={`type-${type}`}>
                      {ImportTypes[type]}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name="transferAssessmentResult"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  sx={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                  data-testid="mui-component-select-transferAssessmentResult"
                  options={srcAssessments}
                  getOptionLabel={getCommonOptionLabel}
                  onChange={handleAssessmentSrcChange}
                  value={value ? srcAssessments.find((item) => item.id === value) || [] : null}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {getCommonOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.transferAssessmentResult}
                      helperText={errors.transferAssessmentResult?.message}
                      fullWidth
                      label={t('transferAssessment.form.assessmentToTransfer')}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="clinicAccountDest"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  sx={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                  data-testid="mui-component-select-clinicAccountDest"
                  options={accountData}
                  getOptionLabel={getCommonOptionLabel}
                  onChange={handleAccountDestChange}
                  value={value ? accountData.find((item) => item.id === value) || [] : null}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {getCommonOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.clinicAccountDest}
                      helperText={errors.clinicAccountDest?.message}
                      fullWidth
                      label={t('transferAssessment.form.destAccount')}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="patientDest"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  sx={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                  data-testid="mui-component-select-patientDest"
                  options={destPatients}
                  getOptionLabel={getPatientOptionLabel}
                  onChange={handlePatientDestChange}
                  value={value ? destPatients.find((item) => item.id === value) || [] : null}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {getPatientOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.patientDest}
                      helperText={errors.patientDest?.message}
                      fullWidth
                      label={t('transferAssessment.form.destPatient')}
                    />
                  )}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <SubmitButton
              content={t('transferAssessment.submit')}
              pageErrors={pageErrors}
              matches={matches}
            />
          </Box>
        </RoundedContainer>
      </Box>
    </RoundedContainer>
  );
}
