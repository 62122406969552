import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useDeleteGoalMutation } from '../../../app/services/treatment-plan/goal';
import { useToast } from '../../../app/toast';
import DataList from '../../../common/components/DataList';
import LocalPopupModal from '../../../common/components/LocalPopupModal';
import RoundedContainer from '../../../common/components/RoundedContainer';
import AddIcon from '../../../common/components/icons/AddIcon';
import DeactivatePatientIcon from '../../../common/components/icons/DeactivatePatientIcon';
import EditUserIcon from '../../../common/components/icons/EditInterviewIcon';
import { sortItemsOnList } from '../../../common/utils/commonUtils';
import { formatDate } from '../../../common/utils/dateTimeUtils';
import { SortDirs } from '../../../common/utils/sortInfo';
import {
  goalProgressListWithKey,
  goalStatusListWithKey,
  truncate,
} from '../../../common/utils/treatmentPlans';
import { PopupContent } from '../../../types/PopupType';
import { GoalItem, GoalStatus } from '../../../types/treatment-plan/GoalType';
import { ObjectiveItem } from '../../../types/treatment-plan/ObjectiveType';
import ContextMenu from '../../patient/ContextMenu';
import ObjectiveForm from '../objective/ObjectiveForm';
import ObjectiveList from '../objective/ObjectiveList';
import {
  selectAddOrEditTreatmentGoalId,
  setAddOrEditTreatmentGoalId,
  setTreatmentPlanReloadOption,
} from '../treatmentPlanSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

interface GoalListProps {
  goalRows: GoalItem[];
  addOrEditGoal: (treatmentPlanId?: number) => void;
  treatmentPlanCompleted?: boolean;
}

export default function GoalList({
  goalRows,
  addOrEditGoal,
  treatmentPlanCompleted,
}: GoalListProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [goalData, setGoalData] = useState<GoalItem[]>([]);
  const [displayedItems, setDisplayedItems] = useState<GoalItem[]>([]);

  const defaultSortOptions = {
    sortField: 'description',
    sortDir: SortDirs.Asc,
  };

  const [sortOptions, setSortOptions] =
    useState<{ sortField: string; sortDir: SortDirs }>(defaultSortOptions);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Delete Popup
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState<boolean>(false);
  const [deletingId, setDeletingId] = useState<number>(0);

  // Add Objective Popup
  const [isOpenAddObjectivePopup, setIsOpenAddObjectivePopup] = useState<boolean>(false);
  const [parentGoal, setParentGoal] = useState<GoalItem | null>(null);

  const [goalRowStatus, setGoalRowStatus] = useState({});
  const [objectiveRowStatus, setObjectiveRowStatus] = useState({});

  const addOrEditGoalId = useAppSelector(selectAddOrEditTreatmentGoalId);

  const sortHeader = ['startDate', 'description', 'status', 'statusDate'];

  const tableHeaders: string[][] = Object.entries(
    t('treatmentPlans.goalList.tableHeader', {
      returnObjects: true,
    })
  );

  const [deleteGoal] = useDeleteGoalMutation();

  useEffect(() => {
    if (addOrEditGoalId) {
      // Expand row
      setGoalRowStatus({ ...goalRowStatus, [addOrEditGoalId]: true });
      dispatch(setAddOrEditTreatmentGoalId({ addOrEditGoalId: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOrEditGoalId, dispatch]);

  useEffect(() => {
    if (goalRows) {
      setGoalData(goalRows);
    }
  }, [goalRows]);

  useEffect(() => {
    if (goalData) {
      const sortedGoalItems = [...goalData];
      sortItemsOnList(sortedGoalItems, sortOptions);
      const formattedItems = sortedGoalItems.map((item) => ({
        ...item,
        startDate: item.startDate ? formatDate(item.startDate) : null,
        statusDate: item.statusDate ? formatDate(item.statusDate) : null,
      }));
      setDisplayedItems(formattedItems);
    }
  }, [goalData, sortOptions]);

  const StyledSortIconBox = styled(Box)(({ theme }) => ({
    display: 'inline',
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
  }));

  // ***************************** Delete Popup *****************************

  const deleteContentPopup = {
    title: t('treatmentPlans.goalList.delete.title'),
    description: `${t('treatmentPlans.goalList.delete.description')}`,
    btnOk: t('treatmentPlans.goalList.delete.btnOk'),
    btnClose: t('treatmentPlans.goalList.delete.btnClose'),
    toastMessage: t('treatmentPlans.goalList.delete.toastMessage'),
  } as PopupContent;

  const showDeleteGoalPopup = (goalId: number) => {
    setIsOpenDeletePopup(true);
    setDeletingId(goalId);
    handleClose();
  };

  const closeDeletePopup = async () => {
    setIsOpenDeletePopup(false);
  };

  const handleDeleteGoal = async (successCallback?: () => void) => {
    try {
      await deleteGoal({ id: deletingId }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('treatmentPlans.goalList.delete.success'), 'success');
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
    } catch (e) {
      toast.publish(t('treatmentPlans.goalList.delete.success'), 'error');
    }
    closeDeletePopup();
  };

  // ***************************** Add Objective Popup *****************************

  const addObjectiveContentPopup = {
    title: t('treatmentPlans.objectiveForm.add'),
  } as PopupContent;

  const showAddObjectivePopup = async (goalData: GoalItem) => {
    setIsOpenAddObjectivePopup(true);
    setParentGoal(goalData);
    handleClose();
  };

  const closeAddObjectivePopup = async () => {
    setIsOpenAddObjectivePopup(false);
  };

  const onAddObjectiveFormSubmitCallback = async (
    objectiveData: ObjectiveItem,
    result: boolean
  ) => {
    if (result) {
      closeAddObjectivePopup();
      dispatch(setTreatmentPlanReloadOption({ reload: true }));

      // Expand row
      setGoalRowStatus({ ...goalRowStatus, [objectiveData.treatmentGoalId]: true });
      setObjectiveRowStatus({ [objectiveData.id]: true });
    }
  };

  // ********************************************************************

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHeaderClicked = (header?: string) => {
    if (header && displayedItems?.length) {
      const newSortOptions = {
        sortField: header,
        sortDir: sortOptions.sortDir === SortDirs.Asc ? SortDirs.Desc : SortDirs.Asc,
      };

      setSortOptions(newSortOptions);
    }
  };

  const renderContextMenu = (item: GoalItem) => {
    const menuItems = [
      {
        label: t('treatmentPlans.goalList.menuContext.addObjective'),
        icon: <AddIcon />,
        onClick: () => showAddObjectivePopup(item),
      },
      {
        label: t('treatmentPlans.goalList.menuContext.edit'),
        icon: <EditUserIcon />,
        onClick: () => {
          addOrEditGoal(item.id);
        },
      },
      {
        isDisplayed: !treatmentPlanCompleted,
        label: t('treatmentPlans.goalList.menuContext.delete'),
        icon: <DeactivatePatientIcon />,
        onClick: () => showDeleteGoalPopup(item.id),
      },
    ];

    return (
      <ContextMenu
        id={`goal-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    );
  };

  const columnWidths = {
    icon: '66px',
    startDate: '15%',
    description: '29%',
    status: '14%',
    statusDate: '15%',
    progress: '14%',
    action: '8%',
  };

  const renderGoalRow = (rowData: GoalItem) => {
    return (
      <Fragment key={rowData.id}>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell
            sx={{
              width: columnWidths.icon,
              minWidth: columnWidths.icon,
              maxWidth: columnWidths.icon,
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() =>
                setGoalRowStatus({ ...goalRowStatus, [rowData.id]: !goalRowStatus[rowData.id] })
              }
            >
              {goalRowStatus[rowData.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell sx={{ width: columnWidths.startDate }}>
            {rowData.startDate}
          </StyledTableCell>
          <StyledTableCell sx={{ width: columnWidths.description }}>
            <Link
              textAlign="left"
              variant="body2"
              component="button"
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                addOrEditGoal(rowData.id);
              }}
            >
              {truncate(rowData.description, 180)}
            </Link>
          </StyledTableCell>
          <StyledTableCell sx={{ width: columnWidths.status }}>
            {goalStatusListWithKey[rowData.status]}
          </StyledTableCell>
          <StyledTableCell sx={{ width: columnWidths.statusDate }}>
            {rowData.statusDate}
          </StyledTableCell>
          <StyledTableCell sx={{ width: columnWidths.progress }}>
            {goalProgressListWithKey[rowData.progress]}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            sx={{
              width: columnWidths.action,
              '& .MuiButtonBase-root': {
                marginLeft: '10px',
              },
            }}
          >
            {renderContextMenu(rowData)}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={!!goalRowStatus[rowData.id]} timeout="auto" unmountOnExit>
              <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                <ObjectiveList
                  goalCompleted={rowData.status === GoalStatus.Completed}
                  objectiveRows={rowData.objectives || []}
                  goalData={rowData}
                  isNoIndent={false}
                  defaultRowStatus={objectiveRowStatus}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const renderDesktopData = () => {
    return (
      <Box sx={{ paddingBottom: 2 }}>
        <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                {tableHeaders.map(([headerKey, headerLabel], index) => {
                  const isSortable = sortHeader.includes(headerKey);
                  const hasSort = sortOptions.sortField === headerKey;
                  const sortArrowIcon =
                    sortOptions.sortDir === SortDirs.Asc ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    );
                  const sortIcon = hasSort ? (
                    <StyledSortIconBox>{sortArrowIcon}</StyledSortIconBox>
                  ) : (
                    <></>
                  );
                  return (
                    <StyledTableCell
                      sx={isSortable ? { cursor: 'pointer' } : {}}
                      key={`${headerKey} ${index}`}
                      onClick={isSortable ? () => onHeaderClicked(headerKey) : undefined}
                    >
                      {headerLabel} {sortIcon}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedItems.map((goalItemData) => renderGoalRow(goalItemData))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderMobileData = () => {
    return (
      <DataList
        lineHeight={'auto'}
        data={displayedItems.map((item: GoalItem) => {
          const listItems = [
            [
              <>{t('treatmentPlans.goalList.tableHeader.startDate')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.startDate}</Box>,
            ],
            [
              <>{t('treatmentPlans.goalList.tableHeader.status')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{goalStatusListWithKey[item.status]}</Box>,
            ],
            [
              <>{t('treatmentPlans.goalList.tableHeader.statusDate')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.statusDate}</Box>,
            ],
            [
              <>{t('treatmentPlans.goalList.tableHeader.progress')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{goalProgressListWithKey[item.progress]}</Box>,
            ],
          ];
          return {
            leftHeader: (
              <Link
                component="button"
                variant="body1"
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  addOrEditGoal(item.id);
                }}
              >
                {truncate(item.description, 180)}
              </Link>
            ),
            rightHeader: renderContextMenu(item),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  return (
    <>
      {displayedItems?.length === 0 ? (
        <Box>
          <RoundedContainer
            sx={{ mt: 1, p: 2, background: theme.xPalette.background, color: 'rgba(0, 0, 0, 0.7)' }}
          >
            <Typography>{t('treatmentPlans.goalList.error.notFound')}</Typography>
          </RoundedContainer>
        </Box>
      ) : (
        <>{isDesktop ? renderDesktopData() : renderMobileData()}</>
      )}

      {/* Delete Goal Popup */}
      <LocalPopupModal
        isOpen={isOpenDeletePopup}
        contentPopup={deleteContentPopup}
        onSuccess={handleDeleteGoal}
        onClose={closeDeletePopup}
      />

      {/* Add Objective Popup */}
      <LocalPopupModal
        isOpen={isOpenAddObjectivePopup}
        contentPopup={addObjectiveContentPopup}
        onClose={closeAddObjectivePopup}
        maxWidth={'md'}
        fullWidth={true}
      >
        {parentGoal?.id && (
          <ObjectiveForm
            parentGoalId={parentGoal.id}
            onFormSubmitCallback={onAddObjectiveFormSubmitCallback}
          />
        )}
      </LocalPopupModal>
    </>
  );
}
