import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { interviewApis, useQueryUsageReportQuery } from '../../app/services/interview';
import CustomPagination from '../../common/components/CustomPagination';
import DataTable from '../../common/components/DataTable';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import UsageReportInfoCard from '../../common/components/UsageReportInfoCard';
import DashboardLayout from '../../common/layouts/Dashboard';
import { ReportType } from '../../common/utils/assessmentType';
import { DefaultPageSize } from '../../common/utils/commonUtils';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { saveReportDataToFile } from '../../common/utils/reportUtils';
import { PageProps } from '../../types/PageProps';
import { UsageReportItem, UsageReportResponse } from '../../types/UsageReport';
import ErrorPage from '../error/ErrorPage';

export default function UsageReportResultPage({ breadcrumbs }: PageProps) {
  const { state } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const { reportData } = !!state && state;

  const tableRef = useRef<HTMLDivElement>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [displayedReportData, setDisplayedReportData] = useState<UsageReportResponse>();
  const searchOptions = {
    clinicAccountId: searchParams.get('clinicAccountId') || '',
    reportType: searchParams.get('reportType') || '',
    fromDate: searchParams.get('fromDate') || '',
    toDate: searchParams.get('toDate') || '',
    page: searchParams.get('page') || '1',
  };

  const baseParams =
    '?clinicAccountId=:clinicAccountId' +
    '&reportType=:reportType' +
    '&fromDate=:fromDate' +
    '&toDate=:toDate';

  const getNavBackUrl = () => {
    return generatePath(`/dashboard/report/${baseParams}`, searchOptions);
  };
  const newBreadcrumb: BreadcrumbType = {
    text: t('report.usageReport.title'),
    link: getNavBackUrl() || `/dashboard/report/`,
  };

  const currentPage = parseInt(searchOptions.page);
  // Query data
  const usageReportQuery = {
    clinicAccountId: searchOptions.clinicAccountId,
    reportType: searchOptions.reportType,
    fromDateString: searchOptions.fromDate || undefined,
    toDateString: searchOptions.toDate || undefined,
    timezoneOffset: new Date().getTimezoneOffset(),
    page: currentPage,
    pageSize: DefaultPageSize,
  };
  const {
    data: usageReportData,
    error,
    isLoading: isLoadingReport,
  } = useQueryUsageReportQuery(!reportData ? usageReportQuery : skipToken);

  const isLoading = isLoadingReport;
  const showPagingInfo = totalPages > 1;

  useEffect(() => {
    let total = 0;
    if (usageReportData) {
      total = usageReportData.total;
      setDisplayedReportData(usageReportData);
      if (usageReportData.assessments.length === 0) {
        setPageErrors([t('report.error.noDataFound')]);
      }
    } else if (reportData) {
      total = reportData.total;
      setDisplayedReportData(reportData);
    }
    setTotalPages(Math.ceil(total / DefaultPageSize));
  }, [usageReportData, reportData, t]);

  const updateSearchUrl = (updatedData: any) => {
    const params = {
      ...searchOptions,
      ...updatedData,
    };

    const url = generatePath(`/dashboard/report-result/${baseParams}&page=:page`, params);
    navigate(url, { replace: true });
  };

  const onDownLoadReport = async () => {
    const usageReportDownloadDispatcher = dispatch(
      interviewApis.queryUsageReportForDownload.initiate({
        clinicAccountId: usageReportQuery.clinicAccountId,
        reportType: usageReportQuery.reportType,
        fromDateString: usageReportQuery.fromDateString || undefined,
        toDateString: usageReportQuery.toDateString || undefined,
        timezoneOffset: new Date().getTimezoneOffset(),
      })
    );
    usageReportDownloadDispatcher.then((result: any) => {
      if (typeof result !== 'undefined') {
        const filenameStartDate = dayjs(usageReportQuery.fromDateString, 'MM/DD/YYYY').format(
          'YYYY-MM-DD'
        );
        const filenameEndDate = dayjs(usageReportQuery.toDateString, 'MM/DD/YYYY').format(
          'YYYY-MM-DD'
        );
        const fileName = `usage_report_${usageReportQuery.clinicAccountId}_${filenameStartDate}_${filenameEndDate}.csv`;
        saveReportDataToFile(result?.data?.csvData, { type: 'text/csv' }, fileName);
      }
    });
  };

  const handlePageChange = (value: any) => {
    const newPage = parseInt(value);
    updateSearchUrl({ page: newPage });
  };

  const getItemStyle = (trial: string) => {
    return trial?.toLocaleLowerCase() === 'yes' ? { color: theme.xPalette.disabledText } : {};
  };

  const getDateTime = (dateTime: string) => {
    const dateTimeArr = dateTime?.split(' ') || []; // original formatted value: 07/11/2022 12:00:33 am
    if (dateTimeArr?.length) {
      const date = dateTimeArr[0];
      dateTimeArr.shift();
      return (
        <>
          {date}
          <br />
          {dateTimeArr.join(' ')}
        </>
      );
    }
    return '';
  };

  const renderDesktopData = (data: UsageReportItem[]) => {
    return (
      <DataTable
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}:first-of-type`]: {
            maxWidth: 'none',
            wordBreak: 'normal',
          },
        }}
        headers={[
          { text: t('report.usageReport.items.start') },
          { text: t('report.usageReport.items.end') },
          { text: t('report.usageReport.items.length') },
          { text: t('report.usageReport.items.status') },
          { text: t('report.usageReport.items.site') },
          { text: t('report.usageReport.items.patientId') },
          { text: t('report.usageReport.items.firstName') },
          { text: t('report.usageReport.items.lastName') },
          { text: t('report.usageReport.items.externalId') },
          { text: t('report.usageReport.items.interviewer') },
          { text: t('report.usageReport.items.measurement') },
          { text: t('report.usageReport.items.method') },
          searchOptions.reportType === ReportType.Interview
            ? { text: t('report.usageReport.items.version') }
            : { text: t('report.usageReport.items.totalScore') },
          searchOptions.reportType === ReportType.Interview
            ? { text: t('report.usageReport.items.trial') }
            : { text: t('report.usageReport.items.standardizedScore') },
        ]}
        items={data.map((item: UsageReportItem) => {
          const itemStyle = getItemStyle(item.trial);
          return [
            {
              component: (
                <Typography sx={{ ...itemStyle, width: '120px' }}>
                  {getDateTime(item.timeBegan)}
                </Typography>
              ),
            },
            {
              component: (
                <Typography sx={{ ...itemStyle, width: '120px' }}>
                  {getDateTime(item.timeEnded)}
                </Typography>
              ),
            },
            {
              component: <Typography sx={{ ...itemStyle }}>{item.length}</Typography>,
            },
            {
              component: (
                <Typography sx={{ ...itemStyle, width: '100px' }}>{item.statusName}</Typography>
              ),
            },
            {
              component: (
                <Typography sx={{ ...itemStyle, width: '200px' }}>{item.siteName}</Typography>
              ),
            },
            { component: <Typography sx={itemStyle}>{item.patient}</Typography> },
            { component: <Typography sx={itemStyle}>{item.firstName}</Typography> },
            { component: <Typography sx={itemStyle}>{item.lastName}</Typography> },
            { component: <Typography sx={itemStyle}>{item.externalId}</Typography> },
            { component: <Typography sx={{ ...itemStyle, minWidth: '250px' }}>{item.interviewer}</Typography> },
            { component: <Typography sx={{ ...itemStyle, width: '250px' }}>{item.measurement}</Typography> },
            { component: <Typography sx={{ ...itemStyle, width: '160px' }}>{item.method}</Typography> },
            searchOptions.reportType === ReportType.Interview
              ? { component: <Typography sx={itemStyle}>{item.version}</Typography> }
              : { component: <Typography sx={itemStyle}>{item.totalScore}</Typography> },
            searchOptions.reportType === ReportType.Interview
              ? { component: <Typography sx={itemStyle}>{item.trial}</Typography> }
              : { component: <Typography sx={itemStyle}>{item.standardizedScore}</Typography> },
          ];
        })}
      />
    );
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }
  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>{getPageTitle(t('report.usageReport.output'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            py: 2,
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          ref={tableRef}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 3 : 2 }}
            headerText={t('report.usageReport.output')}
            backUrl={getNavBackUrl()}
          />
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Box ref={tableRef}>
              <RoundedContainer sx={{ py: 2, mb: 2 }}>
                <UsageReportInfoCard
                  clinicAccountId={displayedReportData?.clinicAccountId || ''}
                  clinicAccountName={displayedReportData?.clinicAccountName || ''}
                  reportType={searchOptions.reportType}
                  fromDate={searchOptions.fromDate || ''}
                  toDate={searchOptions.toDate || ''}
                  total={displayedReportData?.total || 0}
                  totalCompleted={displayedReportData?.totalCompleted || 0}
                  onDownLoadReport={onDownLoadReport}
                />
              </RoundedContainer>

              {displayedReportData && (
                <>
                  <Box sx={{ width: '100%' }}>
                    {displayedReportData?.assessments.length > 0 && (
                      <>
                        {renderDesktopData(displayedReportData?.assessments)}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            py: showPagingInfo ? 4 : 2,
                            borderBottomLeftRadius: '15px',
                            borderBottomRightRadius: '15px',
                            background: theme.xPalette.white,
                          }}
                        >
                          {showPagingInfo && (
                            <CustomPagination
                              changePage={(e, value) => handlePageChange(value)}
                              currentPage={currentPage}
                              pages={totalPages}
                              pageRef={tableRef}
                            />
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
            {pageErrors &&
              pageErrors.length > 0 &&
              pageErrors.map((error) => (
                <Alert key={error} severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              ))}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
}
