export interface LanguageType {
  name: string;
  code: string;
}

export interface LanguageTypeObject {
  [key: string]: LanguageType;
}

export const languages: LanguageTypeObject = {
  af: {
    name: 'Afrikaans',
    code: 'af',
  },
  'af-ZA': {
    name: 'Afrikaans (South Africa)',
    code: 'af-ZA',
  },
  sq: {
    name: 'Albanian',
    code: 'sq',
  },
  'sq-AL': {
    name: 'Albanian (Albania)',
    code: 'sq-AL',
  },
  ar: {
    name: 'Arabic',
    code: 'ar',
  },
  'ar-DZ': {
    name: 'Arabic (Algeria)',
    code: 'ar-DZ',
  },
  'ar-BH': {
    name: 'Arabic (Bahrain)',
    code: 'ar-BH',
  },
  'ar-EG': {
    name: 'Arabic (Egypt)',
    code: 'ar-EG',
  },
  'ar-IQ': {
    name: 'Arabic (Iraq)',
    code: 'ar-IQ',
  },
  'ar-JO': {
    name: 'Arabic (Jordan)',
    code: 'ar-JO',
  },
  'ar-KW': {
    name: 'Arabic (Kuwait)',
    code: 'ar-KW',
  },
  'ar-LB': {
    name: 'Arabic (Lebanon)',
    code: 'ar-LB',
  },
  'ar-LY': {
    name: 'Arabic (Libya)',
    code: 'ar-LY',
  },
  'ar-MA': {
    name: 'Arabic (Morocco)',
    code: 'ar-MA',
  },
  'ar-OM': {
    name: 'Arabic (Oman)',
    code: 'ar-OM',
  },
  'ar-QA': {
    name: 'Arabic (Qatar)',
    code: 'ar-QA',
  },
  'ar-SA': {
    name: 'Arabic (Saudi Arabia)',
    code: 'ar-SA',
  },
  'ar-SY': {
    name: 'Arabic (Syria)',
    code: 'ar-SY',
  },
  'ar-TN': {
    name: 'Arabic (Tunisia)',
    code: 'ar-TN',
  },
  'ar-AE': {
    name: 'Arabic (U.A.E.)',
    code: 'ar-AE',
  },
  'ar-YE': {
    name: 'Arabic (Yemen)',
    code: 'ar-YE',
  },
  hy: {
    name: 'Armenian',
    code: 'hy',
  },
  'hy-AM': {
    name: 'Armenian (Armenia)',
    code: 'hy-AM',
  },
  'az-AZ': {
    name: 'Azeri (Latin) (Azerbaijan)',
    code: 'az-AZ',
  },
  az: {
    name: 'Azeri (Latin)',
    code: 'az',
  },
  eu: {
    name: 'Basque',
    code: 'eu',
  },
  'eu-ES': {
    name: 'Basque (Spain)',
    code: 'eu-ES',
  },
  be: {
    name: 'Belarusian',
    code: 'be',
  },
  'be-BY': {
    name: 'Belarusian (Belarus)',
    code: 'be-BY',
  },
  'bs-BA': {
    name: 'Bosnian (Bosnia and Herzegovina)',
    code: 'bs-BA',
  },
  bg: {
    name: 'Bulgarian',
    code: 'bg',
  },
  'bg-BG': {
    name: 'Bulgarian (Bulgaria)',
    code: 'bg-BG',
  },
  ca: {
    name: 'Catalan',
    code: 'ca',
  },
  'ca-ES': {
    name: 'Catalan (Spain)',
    code: 'ca-ES',
  },
  zh: {
    name: 'Chinese',
    code: 'zh',
  },
  'zh-HK': {
    name: 'Chinese (Hong Kong)',
    code: 'zh-HK',
  },
  'zh-MO': {
    name: 'Chinese (Macau)',
    code: 'zh-MO',
  },
  'zh-CN': {
    name: 'Chinese (Simplified)',
    code: 'zh-CN',
  },
  'zh-SG': {
    name: 'Chinese (Singapore)',
    code: 'zh-SG',
  },
  'zh-TW': {
    name: 'Chinese (Taiwan)',
    code: 'zh-TW',
  },
  hr: {
    name: 'Croatian',
    code: 'hr',
  },
  'hr-BA': {
    name: 'Croatian (Bosnia and Herzegovina)',
    code: 'hr-BA',
  },
  'hr-HR': {
    name: 'Croatian (Croatia)',
    code: 'hr-HR',
  },
  cs: {
    name: 'Czech',
    code: 'cs',
  },
  'cs-CZ': {
    name: 'Czech (Czech Republic)',
    code: 'cs-CZ',
  },
  da: {
    name: 'Danish',
    code: 'da',
  },
  'da-DK': {
    name: 'Danish (Denmark)',
    code: 'da-DK',
  },
  dv: {
    name: 'Divehi',
    code: 'dv',
  },
  'dv-MV': {
    name: 'Divehi (Maldives)',
    code: 'dv-MV',
  },
  nl: {
    name: 'Dutch',
    code: 'nl',
  },
  'nl-BE': {
    name: 'Dutch (Belgium)',
    code: 'nl-BE',
  },
  'nl-NL': {
    name: 'Dutch (Netherlands)',
    code: 'nl-NL',
  },
  en: {
    name: 'English',
    code: 'en',
  },
  'en-AU': {
    name: 'English (Australia)',
    code: 'en-AU',
  },
  'en-BZ': {
    name: 'English (Belize)',
    code: 'en-BZ',
  },
  'en-CA': {
    name: 'English (Canada)',
    code: 'en-CA',
  },
  'en-CB': {
    name: 'English (Caribbean)',
    code: 'en-CB',
  },
  'en-IE': {
    name: 'English (Ireland)',
    code: 'en-IE',
  },
  'en-JM': {
    name: 'English (Jamaica)',
    code: 'en-JM',
  },
  'en-NZ': {
    name: 'English (New Zealand)',
    code: 'en-NZ',
  },
  'en-PH': {
    name: 'English (Republic of the Philippines)',
    code: 'en-PH',
  },
  'en-ZA': {
    name: 'English (South Africa)',
    code: 'en-ZA',
  },
  'en-TT': {
    name: 'English (Trinidad and Tobago)',
    code: 'en-TT',
  },
  'en-GB': {
    name: 'English (United Kingdom)',
    code: 'en-GB',
  },
  'en-US': {
    name: 'English (United States)',
    code: 'en-US',
  },
  'en-ZW': {
    name: 'English (Zimbabwe)',
    code: 'en-ZW',
  },
  eo: {
    name: 'Esperanto',
    code: 'eo',
  },
  et: {
    name: 'Estonian',
    code: 'et',
  },
  'et-EE': {
    name: 'Estonian (Estonia)',
    code: 'et-EE',
  },
  mk: {
    name: 'FYRO Macedonian',
    code: 'mk',
  },
  'mk-MK': {
    name: 'FYRO Macedonian (Former Yugoslav Republic of Macedonia)',
    code: 'mk-MK',
  },
  fo: {
    name: 'Faroese',
    code: 'fo',
  },
  'fo-FO': {
    name: 'Faroese (Faroe Islands)',
    code: 'fo-FO',
  },
  fa: {
    name: 'Farsi',
    code: 'fa',
  },
  'fa-IR': {
    name: 'Farsi (Iran)',
    code: 'fa-IR',
  },
  fi: {
    name: 'Finnish',
    code: 'fi',
  },
  'fi-FI': {
    name: 'Finnish (Finland)',
    code: 'fi-FI',
  },
  fr: {
    name: 'French',
    code: 'fr',
  },
  'fr-BE': {
    name: 'French (Belgium)',
    code: 'fr-BE',
  },
  'fr-CA': {
    name: 'French (Canada)',
    code: 'fr-CA',
  },
  'fr-FR': {
    name: 'French (France)',
    code: 'fr-FR',
  },
  'fr-LU': {
    name: 'French (Luxembourg)',
    code: 'fr-LU',
  },
  'fr-MC': {
    name: 'French (Principality of Monaco)',
    code: 'fr-MC',
  },
  'fr-CH': {
    name: 'French (Switzerland)',
    code: 'fr-CH',
  },
  gl: {
    name: 'Galician',
    code: 'gl',
  },
  'gl-ES': {
    name: 'Galician (Spain)',
    code: 'gl-ES',
  },
  ka: {
    name: 'Georgian',
    code: 'ka',
  },
  'ka-GE': {
    name: 'Georgian (Georgia)',
    code: 'ka-GE',
  },
  de: {
    name: 'German',
    code: 'de',
  },
  'de-AT': {
    name: 'German (Austria)',
    code: 'de-AT',
  },
  'de-DE': {
    name: 'German (Germany)',
    code: 'de-DE',
  },
  'de-LI': {
    name: 'German (Liechtenstein)',
    code: 'de-LI',
  },
  'de-LU': {
    name: 'German (Luxembourg)',
    code: 'de-LU',
  },
  'de-CH': {
    name: 'German (Switzerland)',
    code: 'de-CH',
  },
  el: {
    name: 'Greek',
    code: 'el',
  },
  'el-GR': {
    name: 'Greek (Greece)',
    code: 'el-GR',
  },
  gu: {
    name: 'Gujarati',
    code: 'gu',
  },
  'gu-IN': {
    name: 'Gujarati (India)',
    code: 'gu-IN',
  },
  he: {
    name: 'Hebrew',
    code: 'he',
  },
  'he-IL': {
    name: 'Hebrew (Israel)',
    code: 'he-IL',
  },
  hi: {
    name: 'Hindi',
    code: 'hi',
  },
  'hi-IN': {
    name: 'Hindi (India)',
    code: 'hi-IN',
  },
  hu: {
    name: 'Hungarian',
    code: 'hu',
  },
  'hu-HU': {
    name: 'Hungarian (Hungary)',
    code: 'hu-HU',
  },
  is: {
    name: 'Icelandic',
    code: 'is',
  },
  'is-IS': {
    name: 'Icelandic (Iceland)',
    code: 'is-IS',
  },
  id: {
    name: 'Indonesian',
    code: 'id',
  },
  'id-ID': {
    name: 'Indonesian (Indonesia)',
    code: 'id-ID',
  },
  it: {
    name: 'Italian',
    code: 'it',
  },
  'it-IT': {
    name: 'Italian (Italy)',
    code: 'it-IT',
  },
  'it-CH': {
    name: 'Italian (Switzerland)',
    code: 'it-CH',
  },
  ja: {
    name: 'Japanese',
    code: 'ja',
  },
  'ja-JP': {
    name: 'Japanese (Japan)',
    code: 'ja-JP',
  },
  kn: {
    name: 'Kannada',
    code: 'kn',
  },
  'kn-IN': {
    name: 'Kannada (India)',
    code: 'kn-IN',
  },
  kk: {
    name: 'Kazakh',
    code: 'kk',
  },
  'kk-KZ': {
    name: 'Kazakh (Kazakhstan)',
    code: 'kk-KZ',
  },
  kok: {
    name: 'Konkani',
    code: 'kok',
  },
  'kok-IN': {
    name: 'Konkani (India)',
    code: 'kok-IN',
  },
  ko: {
    name: 'Korean',
    code: 'ko',
  },
  'ko-KR': {
    name: 'Korean (Korea)',
    code: 'ko-KR',
  },
  ky: {
    name: 'Kyrgyz',
    code: 'ky',
  },
  'ky-KG': {
    name: 'Kyrgyz (Kyrgyzstan)',
    code: 'ky-KG',
  },
  lv: {
    name: 'Latvian',
    code: 'lv',
  },
  'lv-LV': {
    name: 'Latvian (Latvia)',
    code: 'lv-LV',
  },
  lt: {
    name: 'Lithuanian',
    code: 'lt',
  },
  'lt-LT': {
    name: 'Lithuanian (Lithuania)',
    code: 'lt-LT',
  },
  ms: {
    name: 'Malay',
    code: 'ms',
  },
  'ms-BN': {
    name: 'Malay (Brunei Darussalam)',
    code: 'ms-BN',
  },
  'ms-MY': {
    name: 'Malay (Malaysia)',
    code: 'ms-MY',
  },
  mt: {
    name: 'Maltese',
    code: 'mt',
  },
  'mt-MT': {
    name: 'Maltese (Malta)',
    code: 'mt-MT',
  },
  mi: {
    name: 'Maori',
    code: 'mi',
  },
  'mi-NZ': {
    name: 'Maori (New Zealand)',
    code: 'mi-NZ',
  },
  mr: {
    name: 'Marathi',
    code: 'mr',
  },
  'mr-IN': {
    name: 'Marathi (India)',
    code: 'mr-IN',
  },
  mn: {
    name: 'Mongolian',
    code: 'mn',
  },
  'mn-MN': {
    name: 'Mongolian (Mongolia)',
    code: 'mn-MN',
  },
  ns: {
    name: 'Northern Sotho',
    code: 'ns',
  },
  'ns-ZA': {
    name: 'Northern Sotho (South Africa)',
    code: 'ns-ZA',
  },
  nb: {
    name: 'Norwegian (Bokm?l)',
    code: 'nb',
  },
  'nb-NO': {
    name: 'Norwegian (Bokm?l) (Norway)',
    code: 'nb-NO',
  },
  'nn-NO': {
    name: 'Norwegian (Nynorsk) (Norway)',
    code: 'nn-NO',
  },
  ps: {
    name: 'Pashto',
    code: 'ps',
  },
  'ps-AR': {
    name: 'Pashto (Afghanistan)',
    code: 'ps-AR',
  },
  pl: {
    name: 'Polish',
    code: 'pl',
  },
  'pl-PL': {
    name: 'Polish (Poland)',
    code: 'pl-PL',
  },
  pt: {
    name: 'Portuguese',
    code: 'pt',
  },
  'pt-BR': {
    name: 'Portuguese (Brazil)',
    code: 'pt-BR',
  },
  'pt-PT': {
    name: 'Portuguese (Portugal)',
    code: 'pt-PT',
  },
  pa: {
    name: 'Punjabi',
    code: 'pa',
  },
  'pa-IN': {
    name: 'Punjabi (India)',
    code: 'pa-IN',
  },
  qu: {
    name: 'Quechua',
    code: 'qu',
  },
  'qu-BO': {
    name: 'Quechua (Bolivia)',
    code: 'qu-BO',
  },
  'qu-EC': {
    name: 'Quechua (Ecuador)',
    code: 'qu-EC',
  },
  'qu-PE': {
    name: 'Quechua (Peru)',
    code: 'qu-PE',
  },
  ro: {
    name: 'Romanian',
    code: 'ro',
  },
  'ro-RO': {
    name: 'Romanian (Romania)',
    code: 'ro-RO',
  },
  ru: {
    name: 'Russian',
    code: 'ru',
  },
  'ru-RU': {
    name: 'Russian (Russia)',
    code: 'ru-RU',
  },
  'se-FI': {
    name: 'Sami (Skolt) (Finland)',
    code: 'se-FI',
  },
  'se-NO': {
    name: 'Sami (Southern) (Norway)',
    code: 'se-NO',
  },
  'se-SE': {
    name: 'Sami (Southern) (Sweden)',
    code: 'se-SE',
  },
  se: {
    name: 'Sami (Northern)',
    code: 'se',
  },
  sa: {
    name: 'Sanskrit',
    code: 'sa',
  },
  'sa-IN': {
    name: 'Sanskrit (India)',
    code: 'sa-IN',
  },
  'sr-BA': {
    name: 'Serbian (Latin) (Bosnia and Herzegovina)',
    code: 'sr-BA',
  },
  'sr-SP': {
    name: 'Serbian (Latin) (Serbia and Montenegro)',
    code: 'sr-SP',
  },
  sk: {
    name: 'Slovak',
    code: 'sk',
  },
  'sk-SK': {
    name: 'Slovak (Slovakia)',
    code: 'sk-SK',
  },
  sl: {
    name: 'Slovenian',
    code: 'sl',
  },
  'sl-SI': {
    name: 'Slovenian (Slovenia)',
    code: 'sl-SI',
  },
  es: {
    name: 'Spanish',
    code: 'es',
  },
  'es-AR': {
    name: 'Spanish (Argentina)',
    code: 'es-AR',
  },
  'es-BO': {
    name: 'Spanish (Bolivia)',
    code: 'es-BO',
  },
  'es-ES': {
    name: 'Spanish (Spain)',
    code: 'es-ES',
  },
  'es-CL': {
    name: 'Spanish (Chile)',
    code: 'es-CL',
  },
  'es-CO': {
    name: 'Spanish (Colombia)',
    code: 'es-CO',
  },
  'es-CR': {
    name: 'Spanish (Costa Rica)',
    code: 'es-CR',
  },
  'es-DO': {
    name: 'Spanish (Dominican Republic)',
    code: 'es-DO',
  },
  'es-EC': {
    name: 'Spanish (Ecuador)',
    code: 'es-EC',
  },
  'es-SV': {
    name: 'Spanish (El Salvador)',
    code: 'es-SV',
  },
  'es-GT': {
    name: 'Spanish (Guatemala)',
    code: 'es-GT',
  },
  'es-HN': {
    name: 'Spanish (Honduras)',
    code: 'es-HN',
  },
  'es-MX': {
    name: 'Spanish (Mexico)',
    code: 'es-MX',
  },
  'es-NI': {
    name: 'Spanish (Nicaragua)',
    code: 'es-NI',
  },
  'es-PA': {
    name: 'Spanish (Panama)',
    code: 'es-PA',
  },
  'es-PY': {
    name: 'Spanish (Paraguay)',
    code: 'es-PY',
  },
  'es-PE': {
    name: 'Spanish (Peru)',
    code: 'es-PE',
  },
  'es-PR': {
    name: 'Spanish (Puerto Rico)',
    code: 'es-PR',
  },
  'es-UY': {
    name: 'Spanish (Uruguay)',
    code: 'es-UY',
  },
  'es-VE': {
    name: 'Spanish (Venezuela)',
    code: 'es-VE',
  },
  sw: {
    name: 'Swahili',
    code: 'sw',
  },
  'sw-KE': {
    name: 'Swahili (Kenya)',
    code: 'sw-KE',
  },
  sv: {
    name: 'Swedish',
    code: 'sv',
  },
  'sv-FI': {
    name: 'Swedish (Finland)',
    code: 'sv-FI',
  },
  'sv-SE': {
    name: 'Swedish (Sweden)',
    code: 'sv-SE',
  },
  syr: {
    name: 'Syriac',
    code: 'syr',
  },
  'syr-SY': {
    name: 'Syriac (Syria)',
    code: 'syr-SY',
  },
  tl: {
    name: 'Tagalog',
    code: 'tl',
  },
  'tl-PH': {
    name: 'Tagalog (Philippines)',
    code: 'tl-PH',
  },
  ta: {
    name: 'Tamil',
    code: 'ta',
  },
  'ta-IN': {
    name: 'Tamil (India)',
    code: 'ta-IN',
  },
  tt: {
    name: 'Tatar',
    code: 'tt',
  },
  'tt-RU': {
    name: 'Tatar (Russia)',
    code: 'tt-RU',
  },
  te: {
    name: 'Telugu',
    code: 'te',
  },
  'te-IN': {
    name: 'Telugu (India)',
    code: 'te-IN',
  },
  th: {
    name: 'Thai',
    code: 'th',
  },
  'th-TH': {
    name: 'Thai (Thailand)',
    code: 'th-TH',
  },
  ts: {
    name: 'Tsonga',
    code: 'ts',
  },
  tn: {
    name: 'Tswana',
    code: 'tn',
  },
  'tn-ZA': {
    name: 'Tswana (South Africa)',
    code: 'tn-ZA',
  },
  tr: {
    name: 'Turkish',
    code: 'tr',
  },
  'tr-TR': {
    name: 'Turkish (Turkey)',
    code: 'tr-TR',
  },
  uk: {
    name: 'Ukrainian',
    code: 'uk',
  },
  'uk-UA': {
    name: 'Ukrainian (Ukraine)',
    code: 'uk-UA',
  },
  ur: {
    name: 'Urdu',
    code: 'ur',
  },
  'ur-PK': {
    name: 'Urdu (Islamic Republic of Pakistan)',
    code: 'ur-PK',
  },
  'uz-UZ': {
    name: 'Uzbek (Latin) (Uzbekistan)',
    code: 'uz-UZ',
  },
  uz: {
    name: 'Uzbek (Latin)',
    code: 'uz',
  },
  vi: {
    name: 'Vietnamese',
    code: 'vi',
  },
  'vi-VN': {
    name: 'Vietnamese (Viet Nam)',
    code: 'vi-VN',
  },
  cy: {
    name: 'Welsh',
    code: 'cy',
  },
  'cy-GB': {
    name: 'Welsh (United Kingdom)',
    code: 'cy-GB',
  },
  xh: {
    name: 'Xhosa',
    code: 'xh',
  },
  'xh-ZA': {
    name: 'Xhosa (South Africa)',
    code: 'xh-ZA',
  },
  zu: {
    name: 'Zulu',
    code: 'zu',
  },
  'zu-ZA': {
    name: 'Zulu (South Africa)',
    code: 'zu-ZA',
  },
};

const uniLanguage = 'Universal';

export const populateLanguage = (languageCode: string): string => {
  return languages[languageCode] ? languages[languageCode].name : uniLanguage;
};

export enum DefaultLanguage {
  English = 'en',
  Universal = 'uni',
}
