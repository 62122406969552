import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { default as SignatureCanvas } from 'react-signature-canvas';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../../app/hooks';
import {
  useAddClinicalFollowUpSignatureMutation,
  useGetAccountProvidersQuery,
  useGetSignedSignatureProviderListQuery,
  useLazyGetProviderSignatureQuery,
} from '../../../app/services/account';
import theme from '../../../app/theme';
import { useToast } from '../../../app/toast';
import ElectronicSignature from '../../../common/components/ElectronicSignature';
import Loading from '../../../common/components/Loading';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { ResponseError } from '../../../types/ResponseError';
import { Provider } from '../../../types/UserTypes';
import {
  FollowUpSignatureAddRequest,
  FollowUpSignatureItem,
  FollowUpSignatureType,
} from '../../../types/clinical-follow-up/ClinicalFollowUpSignature';
import { selectUser } from '../../auth/authSlice';

const StyleErrorForm = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  paddingLeft: '8px',
}));

interface FollowUpSignatureFormProps {
  clinicAccountId: string;
  clinicalFollowUpId: number;
  onFormSubmitCallback: (result: boolean, info?: any) => void;
}

export default function ClinicalFollowUpsSignatureForm({
  clinicAccountId,
  clinicalFollowUpId,
  onFormSubmitCallback,
}: FollowUpSignatureFormProps) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const currentUser = useAppSelector(selectUser);
  const sigRef = useRef<SignatureCanvas>(null);
  const [pageErrors, setPageErrors] = useState<string[]>([]);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [isProviderSigned, setIsProviderSigned] = useState<boolean>(false);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const toast = useToast();

  const { data: providerData, isFetching } = useGetAccountProvidersQuery(
    clinicAccountId
      ? {
          accountId: clinicAccountId,
        }
      : skipToken
  );

  const { data: signedProviderIdsData } = useGetSignedSignatureProviderListQuery(
    clinicAccountId
      ? {
          clinicAccountId,
        }
      : skipToken
  );

  const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    '&.MuiCheckbox-root': {
      width: '45px',
      height: '45px',
    },
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
    '&.MuiCheckbox-indeterminate': {
      color: theme.palette.secondary.main,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 30,
    },
  }));

  const [addSignature] = useAddClinicalFollowUpSignatureMutation();

  const [triggerGetProviderSignature, { data: providerSignatureData }] =
    useLazyGetProviderSignatureQuery();

  const defaultFormValues: FollowUpSignatureItem = {
    providerId: null,
    signViaEmail: true,
    signature: '',
  };

  const validationSchema = yup
    .object({
      providerId: yup
        .string()
        .required(
          t(
            'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.error.blankProvider'
          )
        ),
      signViaEmail: yup.boolean(),
      signature: yup.string().when('signViaEmail', {
        is: (signViaEmail: boolean) => !signViaEmail,
        then: yup
          .string()
          .required(
            t(
              'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.error.blankSignature'
            )
          ),
      }),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm<FollowUpSignatureItem>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const getSignatureName = () => {
    return selectedProvider ? `${selectedProvider.firstName} ${selectedProvider.lastName}` : '';
  };

  const clearSignature = () => {
    sigRef?.current?.clear();
    setValue('signature', '');
  };

  const handleSignatureEnd = () => {
    setValue('signature', sigRef?.current?.toDataURL() || '');
    if (sigRef?.current?.toDataURL() && sigRef?.current?.toDataURL() !== '') {
      clearErrors('signature');
    }
  };

  const getProviderOptionLabel = (option: any) => {
    return `${option.firstName} ${option.lastName}`;
  };

  const filterProviderOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getProviderOptionLabel(option),
  });

  const handleProviderChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('providerId', selectedValue);
    setSelectedProvider(values);
    clearSignature();

    setValue('signViaEmail', selectedValue !== currentUser.id);

    if (selectedValue === currentUser.id) {
      triggerGetProviderSignature({
        clinicAccountId,
        providerId: selectedValue,
      });
    }

    setPageErrors([]);
  };

  const onSubmit = async (signatureInfoFormData: FollowUpSignatureItem) => {
    setDisableSubmit(true);
    try {
      const { providerId, signature } = signatureInfoFormData;
      const submitData: FollowUpSignatureAddRequest = {
        clinicAccountId,
        clinicalFollowUpId,
        providerId,
        signatureType:
          selectedProvider?.id === currentUser.id
            ? FollowUpSignatureType.Participate
            : FollowUpSignatureType.Email,
        signature,
      };

      await addSignature(submitData).unwrap();

      const isSigned = selectedProvider?.id === currentUser.id;
      setIsProviderSigned(isSigned);
      onFormSubmitCallback(true, { isSigned });

      setPageErrors([]);
      if (selectedProvider?.id === currentUser.id) {
        toast.publish(
          t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.addSuccess'),
          'success'
        );
      } else {
        toast.publish(
          t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.sentToEmail'),
          'success'
        );
      }
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
      onFormSubmitCallback(false, error);
    }
    setDisableSubmit(false);
  };

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (providerData) {
      const sortProviders = [...(providerData.providers || [])].sort(
        (a: Provider, b: Provider) =>
          a.lastName?.localeCompare(b.lastName) || a.firstName?.localeCompare(b.firstName)
      );

      setProviders(sortProviders);

      const currentProvider =
        sortProviders.find((provider) => provider.id === currentUser.id) || null;
      if (currentProvider) {
        handleProviderChange(null, currentProvider);
      } else if (sortProviders?.length) {
        handleProviderChange(null, sortProviders[0]);
      } else {
        setValue('providerId', null);
        setSelectedProvider(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, providerData, currentUser.id]);

  useEffect(() => {
    if (
      selectedProvider?.id === currentUser.id &&
      providerSignatureData?.followUpSignature?.signature
    ) {
      sigRef?.current?.fromDataURL(providerSignatureData.followUpSignature.signature);
      setValue('signature', providerSignatureData.followUpSignature.signature);
    }
  }, [
    currentUser.id,
    providerSignatureData?.followUpSignature?.signature,
    selectedProvider?.id,
    setValue,
  ]);

  return (
    <>
      {isFetching ? (
        <Box sx={{ my: 2 }}>
          <Loading />
        </Box>
      ) : (
        <Box
          component="form"
          noValidate
          sx={{
            px: 0,
            pt: 0,
          }}
        >
          <RoundedContainer sx={{ paddingTop: 0 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Controller
                name="providerId"
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <Autocomplete
                    {...props}
                    sx={{ width: '100%' }}
                    data-testid="mui-component-select-provider"
                    options={providers || []}
                    getOptionLabel={getProviderOptionLabel}
                    value={
                      value || value === 0
                        ? providers?.find((item) => item.id === value) || null
                        : null
                    }
                    filterOptions={filterProviderOptions}
                    onChange={handleProviderChange}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.id} value={option.id}>
                          {getProviderOptionLabel(option)}
                          {((isProviderSigned && option.id === currentUser.id) ||
                            signedProviderIdsData?.providerIds?.includes(option.id)) && (
                            <Typography sx={{ color: 'red', marginLeft: 1 }}>
                              -{' '}
                              {t(
                                'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.alreadyConsent'
                              )}
                            </Typography>
                          )}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.providerId}
                        helperText={errors.providerId?.message}
                        margin="normal"
                        fullWidth
                        label={t(
                          'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.provider'
                        )}
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                )}
              />
              {selectedProvider && selectedProvider.id !== currentUser.id && (
                <Controller
                  name="signViaEmail"
                  control={control}
                  render={({ field }) => (
                    <FormGroup {...field}>
                      <FormControlLabel
                        control={<StyledCheckbox />}
                        checked={field.value}
                        value={field.value}
                        disabled={true}
                        onChange={(event: any) => {
                          setValue('signViaEmail', event.target.checked);
                          clearSignature();
                        }}
                        label={`${t(
                          'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.emailText'
                        )}`}
                      />
                    </FormGroup>
                  )}
                />
              )}
              {selectedProvider && (
                <Box
                  sx={{
                    py: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.term')}
                </Box>
              )}
              {selectedProvider?.id === currentUser.id && (
                <Box>
                  <ElectronicSignature
                    headerText={t(
                      'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.signature'
                    )}
                    showTopClearButton
                    showDivider={false}
                    footerText={getSignatureName()}
                    ref={sigRef}
                    clear={clearSignature}
                    onEnd={handleSignatureEnd}
                    backgroundColor={theme.xPalette.noteLightGrey}
                    sx={{
                      minWidth: '100%',
                      '& .MuiBox-root:first-child .MuiTypography-root:first-child': {
                        fontWeight: 600,
                      },
                    }}
                  />
                  {!!errors.signature && (
                    <StyleErrorForm>
                      {errors.signature && (errors.signature as any).message}
                    </StyleErrorForm>
                  )}
                </Box>
              )}
            </Box>

            {selectedProvider && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                  pt: 1,
                }}
              >
                {!isMobile && <Divider sx={{ width: '100%' }} />}

                {pageErrors?.length > 0 &&
                  pageErrors.map((error) => (
                    <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                      {error}
                    </Alert>
                  ))}
                <Button
                  onClick={submitForm}
                  disabled={disableSubmit}
                  fullWidth
                  variant="contained"
                  sx={{
                    width: isMobile ? '100%' : '35%',
                    my: 2,
                    py: 2,
                    float: 'right',
                  }}
                >
                  {t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.submit')}
                </Button>
              </Box>
            )}
          </RoundedContainer>
        </Box>
      )}
    </>
  );
}
