import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { darken, lighten, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { auditLogSearchApi, useGetClinicAccountsQuery } from '../../app/services/account';
import ClinicSiteSearch from '../../common/components/ClinicSiteSearch';
import PatientSearch from '../../common/components/PatientSearch';
import UserSearch from '../../common/components/UserSearch';
import { eventTypeList } from '../../common/utils/auditLogType';
import { DefaultPageSize, DefaultSearchPageSize } from '../../common/utils/commonUtils';
import { Role } from '../../common/utils/loginRole';
import { canViewAuditLog } from '../../common/utils/permissionUtils';
import { AuditLogSearch } from '../../types/AuditLog';
import { AuditLogClinicSiteItems } from '../../types/ClinicSiteType';
import { SearchPatientResult } from '../../types/DashboardRequest';
import { ResponseError } from '../../types/ResponseError';
import { SearchUserResult } from '../../types/UserRequest';
import { selectUser } from '../auth/authSlice';

let defaultFormValues: AuditLogSearch = {
  clinicAccountId: '',
  clinicSiteId: '',
  userId: '',
  patientId: '',
  eventType: '',
  fromDate: undefined,
  toDate: undefined,
};

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyleDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const validationDate = ({ fromDate, toDate }: { fromDate?: Date; toDate?: Date }): { valid: boolean, error?: string } => {
  if ((fromDate && !toDate) || (!fromDate && toDate)) {
    return { valid: true };
  }
  if (fromDate && toDate) {
    const fromDateFormat = new Date(new Date(fromDate).toISOString().split('T')[0]);
    const toDateFormat = new Date(new Date(toDate).toISOString().split('T')[0]);
    if (fromDateFormat.getTime() > toDateFormat.getTime()) {
      return { valid: false, error: 'inValidDateRange' };
    } else {
      const fromDatePlus2Years = new Date(fromDateFormat.getFullYear() + 2, fromDateFormat.getMonth(), fromDateFormat.getDate() + 1);
      if (fromDatePlus2Years.getTime() < toDateFormat.getTime()) {
        return { valid: false, error: 'outOfRange' };
      }
    }
  }
  return { valid: true };
};

export default function AuditLogFilterForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams] = useSearchParams();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const currentUser = useAppSelector(selectUser);
  const currentDate = dayjs(new Date()).format('MM/DD/YYYY');

  const isValidInputDate = (inputDate: string) => dayjs(inputDate, 'MM/DD/YYYY').isValid();

  const searchOptions = {
    clinicAccountId: searchParams.get('clinicAccountId') || currentUser.clinicAccountId,
    clinicSiteId: searchParams.get('clinicSiteId') || '',
    userId: searchParams.get('userId') || '',
    patientId: searchParams.get('patientId') || '',
    eventType: searchParams.get('eventType') || '',
    fromDate: searchParams.get('fromDate') || currentDate,
    toDate: searchParams.get('toDate') || currentDate,
    page: searchParams.get('page') || '1',
  };

  defaultFormValues = {
    clinicAccountId: searchOptions.clinicAccountId,
    clinicSiteId: searchOptions.clinicSiteId,
    userId: searchOptions.userId,
    patientId: searchOptions.patientId,
    eventType: searchOptions.eventType,
    fromDate: isValidInputDate(searchOptions.fromDate)
      ? new Date(searchOptions.fromDate)
      : undefined,
    toDate: isValidInputDate(searchOptions.fromDate) ? new Date(searchOptions.toDate) : undefined,
  };
  const [clinicAccountId, setClinicAccountId] = useState<string>(searchOptions.clinicAccountId);
  const [eventType, setEventType] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [patientId, setPatientId] = useState<string>('');

  const [fromDate, setFromDate] = useState<string>(
    isValidInputDate(searchOptions.fromDate) ? searchOptions.fromDate : ''
  );
  const [toDate, setToDate] = useState<string>(
    isValidInputDate(searchOptions.toDate) ? searchOptions.toDate : ''
  );
  const [fromDateError, setFromDateError] = useState<string>('');
  const [toDateError, setToDateError] = useState<string>('');
  const canView = canViewAuditLog(currentUser.roleId);
  const dispatch = useAppDispatch();
  const { data: clinicAccountData } = useGetClinicAccountsQuery(undefined, { skip: !canView });
  const [accountDisabled, setAccountDisabled] = useState<boolean>(false);
  const [siteDisabled, setSiteDisabled] = useState<boolean>(false);

  const validationSchema = yup
    .object({
      clinicAccountId: yup.string(),
      clinicSiteId: yup.string(),
      userId: yup.string(),
      eventType: yup.string().required(t('auditLog.error.blankEventType')),
      fromDate: yup
        .date()
        .required(t('report.error.blankFromDate'))
        .typeError(t('report.error.blankFromDate'))
        .test('from-date', '', function (fromDateValue) {
          const toDateValue = getValues('toDate') || undefined;
          const isValidDate = isValidInputDate(fromDate);
          setPageErrors([]);
          if (!isValidDate && fromDateValue) {
            return false;
          }
          if (fromDateValue) {
            const validDate = validationDate({
              fromDate: fromDateValue,
              toDate: toDateValue,
            });

            if (!validDate.valid && validDate.error === 'inValidDateRange') {
              setFromDateError(t('report.error.inValidDateRange'));
              return false;
            }

            if (!validDate.valid) {
              setPageErrors([t('auditLog.error.outOfRange')]);
              return false;
            }
          }
          setFromDateError('');
          return true;
        })
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),

      toDate: yup
        .date()
        .required(t('report.error.blankToDate'))
        .typeError(t('report.error.blankToDate'))
        .test('from-date', '', function (toDateValue) {
          const fromDateValue = getValues('fromDate') || undefined;
          const isValidDate = isValidInputDate(toDate);
          if (!isValidDate && toDateValue) {
            return false;
          }
          if (toDateValue) {
            const validDate = validationDate({
              fromDate: fromDateValue,
              toDate: toDateValue,
            });

            if (!validDate.valid && validDate.error === 'inValidDateRange') {
              setToDateError(t('report.error.inValidToDateRange'));
              return false;
            }

            if (!validDate.valid) {
              setPageErrors([t('auditLog.error.outOfRange')]);
              return false;
            }
          }
          setToDateError('');
          return true;
        })
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    resetField,
  } = useForm<AuditLogSearch>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    const eventTypes = eventType ? eventType?.split('-') : searchOptions?.eventType?.split('-');
    const isSiteEvent = Boolean(eventTypes?.length >= 2 && eventTypes[1] === 'site');

    if (isSiteEvent) {
      setSiteDisabled(false);
      setAccountDisabled(false);
    } else {
      setSiteDisabled(true);
      setAccountDisabled(Boolean(userId || patientId));
      resetField('clinicSiteId');
    }
  }, [eventType, patientId, resetField, searchOptions?.eventType, setValue, userId]);

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccountId', selectedValue);
    setClinicAccountId(selectedValue);
    setValue('clinicSiteId', '');
    if (selectedValue) {
      clearErrors('userId');
    }
  };

  const onSiteSelected = async (selectedSite: AuditLogClinicSiteItems | null) => {
    if (selectedSite?.id) {
      setValue('clinicSiteId', selectedSite?.id.toString());
      clearErrors('userId');
    } else {
      setValue('clinicSiteId', '');
    }
  };

  const onUserSelected = async (selectedUser: SearchUserResult | null) => {
    setValue('userId', selectedUser?.id || '');
    setUserId(selectedUser?.id || '');
  };

  const onPatientSelected = async (selectedPatient: SearchPatientResult | string | null) => {
    if (
      typeof selectedPatient !== 'string' &&
      selectedPatient?.patientId &&
      selectedPatient?.patientId !== '-1'
    ) {
      setValue('patientId', selectedPatient?.patientId);
      setPatientId(selectedPatient?.patientId);
    } else {
      setValue('patientId', '');
      setPatientId('');
    }
  };

  const handleEventTypeChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    if (!selectedValue) {
      setValue('clinicSiteId', '');
      setSiteDisabled(true);
    }
    setValue('eventType', selectedValue);
    setEventType(selectedValue);
  };

  const getAccountOptionLabel = (option: any) => (option.id ? `${option.name} (${option.id})` : '');
  const getEventTypeOptionLabel = (option: any) => `${option.name}`;

  const onSubmit = async (data: AuditLogSearch) => {
    const clinicAccountId = accountDisabled ? '' : data.clinicAccountId;
    const clinicSiteId = siteDisabled ? '' : data.clinicSiteId;
    try {
      const auditLogSearchDispatcher = dispatch(
        auditLogSearchApi.endpoints.searchAuditLogs.initiate({
          clinicAccountId,
          clinicSiteId,
          userId: data.userId,
          patientId: data.patientId,
          eventType: data.eventType,
          fromDateString: fromDate,
          toDateString: toDate,
          page: 1,
          pageSize: DefaultPageSize,
        })
      );
      auditLogSearchDispatcher.then((result) => {
        if (typeof result !== 'undefined') {
          if (result.data?.auditLogs.length !== 0) {
            const params = `?clinicAccountId=${clinicAccountId}&clinicSiteId=${clinicSiteId}&userId=${data.userId}&patientId=${data.patientId}&eventType=${data.eventType}&fromDate=${fromDate}&toDate=${toDate}`;
            const updatedUrl = `/dashboard/audit-log-result/${params}`;
            navigate(updatedUrl, { state: { auditLogData: result.data || null } });
          } else {
            setPageErrors([t('report.error.noDataFound')]);
          }
        }
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        px: matches ? 0 : 3,
        pt: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {currentUser.roleId === Role.SuperAdmin && (
          <Controller
            name="clinicAccountId"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <StyleAutocomplete
                {...props}
                sx={{
                  marginTop: '0.5rem',
                  marginBottom: '0.5rem',
                }}
                data-testid="mui-component-select-clinic-account"
                options={clinicAccountData?.clinicAccounts || []}
                getOptionLabel={getAccountOptionLabel}
                onChange={handleAccountChange}
                disabled={accountDisabled}
                value={
                  value
                    ? clinicAccountData?.clinicAccounts?.find((item) => item.id === value) || []
                    : null
                }
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.id} value={option.id}>
                      {getAccountOptionLabel(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.clinicAccountId}
                    helperText={errors.clinicAccountId?.message}
                    fullWidth
                    label={t('report.usageReport.account')}
                  />
                )}
              />
            )}
          />
        )}

        <Controller
          name="clinicSiteId"
          control={control}
          render={({ field: { value } }) => (
            <ClinicSiteSearch
              sx={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                width: '49%',
                [theme.breakpoints.down('md')]: {
                  marginTop: '0.5rem',
                  width: '100%',
                },
              }}
              disabled={siteDisabled}
              label={t('auditLog.site')}
              clinicAccountId={clinicAccountId}
              selectedClinicSiteId={value}
              onSelect={onSiteSelected}
            />
          )}
        />

        <Controller
          name="userId"
          control={control}
          render={({ field: { value } }) => (
            <UserSearch
              sx={{
                width: '49%',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                  marginBottom: '0.5rem',
                },
              }}
              label={t('auditLog.user')}
              searchByFullName={true}
              searchByEmail={false}
              error={errors.userId}
              searchIfNoInput={true}
              selectedUserId={value}
              onSelect={onUserSelected}
            />
          )}
        />

        <Controller
          name="patientId"
          control={control}
          render={({ field: { value } }) => (
            <PatientSearch
              sx={{
                width: '49%',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
              label={t('auditLog.patient')}
              searchByFullName={true}
              searchByExternalId={false}
              keepSearchOnBlur={false}
              pageSize={DefaultSearchPageSize}
              freeSolo={false}
              searchIfNoInput={true}
              selectedPatientId={value}
              viewMore={false}
              onSelect={onPatientSelected}
            />
          )}
        />

        <Controller
          name="eventType"
          control={control}
          render={({ fieldState, formState, field: { value }, ...props }) => (
            <StyleAutocomplete
              {...props}
              data-testid="mui-component-select-event-type"
              options={eventTypeList}
              groupBy={(option: any) => option.group}
              getOptionLabel={getEventTypeOptionLabel}
              onChange={handleEventTypeChange}
              value={value ? eventTypeList.find((item) => item.id === value) || [] : null}
              renderOption={(props, option: any) => {
                return (
                  <li {...props} key={option.id} value={option.id}>
                    {getEventTypeOptionLabel(option)}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.eventType}
                  helperText={errors.eventType?.message}
                  fullWidth
                  label={t('auditLog.eventType')}
                />
              )}
              renderGroup={(params) => (
                <li key={params.group}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
              sx={{
                width: '49%',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
            />
          )}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '49%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
          >
            <Controller
              name="fromDate"
              control={control}
              render={({ field }) => (
                <StyleDesktopDatePicker
                  {...field}
                  label="From"
                  inputFormat="MM/DD/YYYY"
                  value={fromDate}
                  onChange={(newDateValue: any, inputValue: any) => {
                    if (newDateValue) {
                      const isValidDate = dayjs(newDateValue).isValid();
                      if (!isValidDate) {
                        setFromDate(inputValue);
                        setFromDateError(t('report.error.inValidFromDate'));
                      } else {
                        const dateString = dayjs(newDateValue).format('MM/DD/YYYY');
                        setValue('fromDate', new Date(dateString));
                        setFromDate(dateString);
                        setFromDateError('');
                      }
                    } else {
                      setValue('fromDate', newDateValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      data-testid="mui-component-from-date"
                      sx={{
                        marginTop: '0.5rem',
                        width: '100%',
                      }}
                      error={!!errors.fromDate || !!fromDateError}
                      helperText={errors.fromDate?.message}
                      margin="normal"
                      variant="outlined"
                      aria-describedby="from-date-helper-text"
                    />
                  )}
                />
              )}
            />
            <FormHelperText id="from-date-helper-text" error={true}>
              {fromDateError}
            </FormHelperText>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '49%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
          >
            <Controller
              name="toDate"
              control={control}
              render={({ field }) => (
                <StyleDesktopDatePicker
                  {...field}
                  label="To"
                  inputFormat="MM/DD/YYYY"
                  value={toDate}
                  onChange={(newDateValue: any, inputValue: any) => {
                    if (newDateValue) {
                      const isValidDate = dayjs(newDateValue).isValid();
                      if (!isValidDate) {
                        setToDate(inputValue);
                        setToDateError(t('report.error.inValidToDate'));
                      } else {
                        const dateString = dayjs(newDateValue).format('MM/DD/YYYY');
                        setValue('toDate', new Date(dateString));
                        setToDate(dateString);
                        setToDateError('');
                      }
                    } else {
                      setValue('toDate', newDateValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      data-testid="mui-component-to-date"
                      sx={{
                        marginTop: '0.5rem',
                        width: '100%',
                      }}
                      error={!!errors.toDate || !!toDateError}
                      helperText={errors.toDate?.message}
                      margin="normal"
                      variant="outlined"
                      aria-describedby="to-date-helper-text"
                    />
                  )}
                />
              )}
            />
            <FormHelperText id="to-date-helper-text" error={true}>
              {toDateError}
            </FormHelperText>
          </Box>
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          width: '100%',
          float: 'right',
          pb: 3,
        }}
      >
        {!matches && <Divider sx={{ py: 1 }} />}

        {pageErrors &&
          pageErrors.length > 0 &&
          pageErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ))}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            width: matches ? '100%' : '24%',
            my: 2,
            py: 2,
            float: 'right',
          }}
        >
          {t('report.usageReport.submit')}
        </Button>
      </Box>
    </Box>
  );
}
