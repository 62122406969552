import { JSXElementConstructor, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '../../../app/hooks';
import { QuestionAnswerType } from '../../../common/utils/questionAnswerTypes';
import { capitalizeFirstLetter } from '../../../common/utils/stringUtils';
import { MonitorQuestionProps, MonitorSubQuestionType } from '../../../types/MonitorTypes';
import { StyledRadioCheckIcon, StyledRadioIcon } from '../../interview/questions/SdsScaleQuestion';
import {
  StyledFormControl,
  StyledFormLabel,
  StyledRadioGroup,
} from '../../interview/questions/StsScaleQuestion';
import MultiOptions from '../monitorSubQuestion/MultiOptions';
import NumberQuestion from '../monitorSubQuestion/NumberQuestion';
import TextQuestion from '../monitorSubQuestion/TextQuestion';

const questionnaireOptions = ['0', '1', '2', '3', '4'];

const subComponentMap: {
  [type: string]: JSXElementConstructor<any>;
} = {
  [QuestionAnswerType.MultiOptions]: MultiOptions,
  [QuestionAnswerType.Text]: TextQuestion,
  [QuestionAnswerType.Number]: NumberQuestion,
};

export default function Questionnaire({
  question,
  field,
  error,
  helperText,
  setValue,
  moduleId,
  questionId,
  checkRelativeQuestion,
  subErrorsResponse,
  relativeAnswers,
}: MonitorQuestionProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const questionnaireMeasurement: string[] = Object.values(
    t('monitor.form.questionnaire.measurement', { returnObjects: true })
  );
  const questionText = capitalizeFirstLetter(question.questionText || '');
  const [subQuestionsData, setSubQuestions] = useState<any>({});
  const subQuestionItems: MonitorSubQuestionType[] | [] = question.subQuestions || [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const questionnaireValue = event.target.value;
    checkRelativeQuestion({
      moduleId: moduleId!,
      questionId: questionId!,
      questionAnswer: questionnaireValue,
    });
    setSelectedValue(questionnaireValue);
  };

  const handleSubQuestionChange = (subQuestion: any, subQuestionId: any) => {
    setSubQuestions({
      ...subQuestionsData,
      [`sub_question_${subQuestionId}`]: { subQuestionId, answer: subQuestion },
    });
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'questionnaire-radio-button',
    inputProps: { 'aria-label': item },
  });

  useEffect(() => {
    const questionnaireValue = {
      questionId: question.id,
      answer: parseInt(selectedValue),
      subQuestions:
        subQuestionItems.length > 0
          ? Object.values(subQuestionsData).filter(
              (subQuestion: any) => subQuestion.answer.length > 0
            ) || []
          : [],
    };
    setValue(field?.name!, questionnaireValue);
  }, [
    dispatch,
    field?.name,
    moduleId,
    question.id,
    questionId,
    selectedValue,
    setValue,
    subQuestionItems.length,
    subQuestionsData,
  ]);

  const renderAnswers = () => {
    let { possibleAnswers } = question;

    if (possibleAnswers[0] === '' || possibleAnswers.length < 1) {
      possibleAnswers = questionnaireOptions;
    }

    return possibleAnswers.map((item) => {
      return (
        <StyledFormLabel
          key={item}
          value={item}
          labelPlacement="bottom"
          control={
            <Radio
              checkedIcon={<StyledRadioCheckIcon>{item}</StyledRadioCheckIcon>}
              icon={<StyledRadioIcon>{item}</StyledRadioIcon>}
              {...controlProps(item)}
            />
          }
          label={questionnaireMeasurement[parseInt(item)]}
        />
      );
    });
  };

  const renderSubQuestions = () => {
    let defaultSubError = '';
    return subQuestionItems.map((subQuestion) => {
      const SubQuestionComponent = subComponentMap[subQuestion.questionAnswerType!];

      const subErrorResponse =
        subErrorsResponse && subErrorsResponse[subQuestion.id!]
          ? subErrorsResponse[subQuestion.id!]
          : '';
      if (!SubQuestionComponent) {
        return (
          <Typography key={subQuestion.id} color="red">
            *** Sub {`${subQuestion.questionAnswerType!}`} type is not implement yet!
          </Typography>
        );
      }

      return (
        <SubQuestionComponent
          key={subQuestion.id}
          possibleAnswers={subQuestion.possibleAnswers}
          subQuestionId={subQuestion.id}
          onChangeCallBack={SubQuestionComponent ? handleSubQuestionChange : undefined}
          questionText={subQuestion.questionText}
          isRequired={subQuestion.isRequired}
          error={!!defaultSubError || !!subErrorResponse}
          helperText={defaultSubError || subErrorResponse}
        ></SubQuestionComponent>
      );
    });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', columnGap: 1 }}>
        {question.title && (
          <Typography
            dangerouslySetInnerHTML={{
              __html: question.title,
            }}
          ></Typography>
        )}
        {question.questionText && (
          <Typography
            dangerouslySetInnerHTML={{
              __html: questionText.includes('%s')
                ? questionText.replace('%s', relativeAnswers || '')
                : questionText,
            }}
          ></Typography>
        )}
      </Box>
      <StyledFormControl error={error} variant="standard">
        <StyledRadioGroup {...field} row>
          {renderAnswers()}
        </StyledRadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </StyledFormControl>
      {renderSubQuestions()}
      <Divider sx={{ my: 2 }} />
    </Box>
  );
}
