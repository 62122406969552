import { forwardRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import theme from '../../app/theme';

interface Props extends Partial<DatePickerProps> {
  sx?: SxProps<Theme>;
  textFieldSx?: any;
  name: string;
  control: any;
  error: boolean;
  helperText: string;
  label: string;
  matches?: boolean;
  hideClearButton?: boolean;
}

const commonTextFieldStyle = {
  [`&.MuiFormControl-root`]: {
    width: '100%',
  },
};

const CommonDatePicker = forwardRef<HTMLDivElement, Props>(
  (
    {
      name,
      control,
      error,
      helperText,
      label,
      textFieldSx,
      disabled,
      hideClearButton,
      ...datePickerProps
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            ref={ref}
            PaperProps={{
              sx: {
                position: 'relative',
              },
            }}
            inputFormat="MM/DD/YYYY"
            open={isOpen}
            onClose={() => setOpen(false)}
            disabled={disabled}
            showToolbar
            ToolbarComponent={(props: any) => {
              return (
                <Toolbar
                  sx={{
                    position: 'absolute',
                    minHeight: '39px !important',
                    bottom: 0,
                    left: 0,
                    zIndex: 99,
                    alignItems: 'start',
                  }}
                >
                  {props.children}
                  {!hideClearButton && (
                    <IconButton
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        fontSize: '0.875rem',
                        lineHeight: '1.75',
                        padding: '0px 8px',
                        borderRadius: 'unset',
                      }}
                      size="small"
                      onClick={() => {
                        field.onChange(null);
                        props.setOpenView(false);
                        setOpen(false);
                      }}
                    >
                      {t('treatmentPlans.clear')}
                    </IconButton>
                  )}
                </Toolbar>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={
                  textFieldSx
                    ? {
                        ...textFieldSx,
                      }
                    : { ...commonTextFieldStyle }
                }
                onClick={() => {
                  if (!disabled) {
                    setOpen(true);
                  }
                }}
                error={!!error}
                helperText={helperText}
                margin="normal"
                fullWidth
                label={label}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
            {...datePickerProps}
          />
        )}
      />
    );
  }
);
export default CommonDatePicker;
