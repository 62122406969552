import { ReactElement, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import {
  useGetSuicideAlertListQuery,
  useMarkAlertAsSeenMutation,
} from '../../app/services/account';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import DashboardLayout from '../../common/layouts/Dashboard';
import { PageStatus, setPageStatus } from '../../common/slices/globalSlice';
import { AssessmentStatus } from '../../common/utils/assessmentStatus';
import { AssessmentType } from '../../common/utils/assessmentType';
import { statusBoxStyle } from '../../common/utils/commonStyles';
import { formatDate } from '../../common/utils/dateTimeUtils';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { SuicideAlertType } from '../../types/SuicideAlertType';
import useDownloadInterviewOrMonitorReport from './hooks/useDownloadInterviewOrMonitorReport';
import { DownloadReportRequest } from '../../types/InterviewReportRequest';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

export default function SuicideAlertPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [displaySuicideAlert, setDisplaySuicideAlert] = useState<SuicideAlertType[]>([]);
  const tableHeaders: string[] = Object.values(
    t('patient.suicidalPatients.tableHeader', {
      returnObjects: true,
    })
  );

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
  };

  const tableRef = useRef<HTMLDivElement>(null);

  const [markAlertAsSeen] = useMarkAlertAsSeenMutation();
  const { startDownloadReport } = useDownloadInterviewOrMonitorReport(t);

  const {
    data: suicidalData,
    isLoading,
    refetch,
  } = useGetSuicideAlertListQuery({
    page: searchOptions.page,
    pageSize,
  });

  useEffect(() => {
    if (suicidalData && suicidalData.suicideAlertList.length) {
      setDisplaySuicideAlert(suicidalData.suicideAlertList);
      setTotalPages(Math.ceil(suicidalData.totalItems / pageSize));
    }
  }, [pageSize, suicidalData]);

  const handleViewReport = async (
    suicideAlertId: number,
    interviewResult: number,
    monitorResult: number,
    status: string
  ) => {
    dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
    let data: DownloadReportRequest;
    if (interviewResult) {
      data = {
        interviewResultId: interviewResult,
        latestVersion: true,
        type: AssessmentType.Interview,
      };
    } else {
      data = {
        monitorResultId: monitorResult,
        type: AssessmentType.Monitor,
      };
    }

    if (status === AssessmentStatus.Completed) {
      await startDownloadReport(data);
    }

    try {
      await markAlertAsSeen({ suicideAlertId }).unwrap();
    } catch (e) {
      console.error(e);
    }

    refetch();
  };

  const handlePageChange = (value: number) => {
    const newRoute = `/dashboard/suicide-alerts?page=${value}`;
    navigate(newRoute, { replace: true });
  };

  const renderDesktopData = (data: SuicideAlertType[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <StyledTableCell key={`${header} ${index}`}>{header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data || !data.length ? (
              <TableRow>
                <StyledTableCell colSpan={tableHeaders.length}>
                  <Typography my={2} color="text.secondary" align="left">
                    {t('patient.suicidalPatients.noRecords')}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ) : (
              <>
                {data.map((item: SuicideAlertType) => {
                  const isInterview = item.interviewResult
                    ? t('interview.title')
                    : t('monitor.title');
                  return (
                    <TableRow key={item.suicideAlertId}>
                      <StyledTableCell>
                        {item.patientFirstName && item.patientLastName
                          ? `${item.patientFirstName} ${item.patientLastName}`
                          : item.externalId
                          ? t('patient.suicidalPatients.tableBody.externalId', {
                              externalId: item.externalId,
                            })
                          : t('patient.suicidalPatients.tableBody.patientId', {
                              patientId: item.patientId,
                            })}
                      </StyledTableCell>
                      <StyledTableCell>{`${item.interviewerFirstName} ${item.interviewerLastName}`}</StyledTableCell>
                      <StyledTableCell>{formatDate(item.dateScored)}</StyledTableCell>
                      <StyledTableCell sx={{ px: 2, width: 200 }}>
                        <Box
                          sx={{
                            ...statusBoxStyle(
                              !isDesktop,
                              item.dateViewed ? AssessmentStatus.SeenOn : AssessmentStatus.UnRead
                            ),
                            maxWidth: '100%',
                            width: 'fit-content',
                            p: 1,
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {item.dateViewed
                            ? t('patient.suicidalPatients.tableBody.seenOn', {
                                dateViewed: formatDate(item.dateViewed),
                              })
                            : t('patient.suicidalPatients.tableBody.unRead')}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{isInterview}</StyledTableCell>
                      <StyledTableCell align="center">
                        {item.status === AssessmentStatus.Completed && (
                          <IconButton
                            onClick={() =>
                              handleViewReport(
                                item.suicideAlertId,
                                item.interviewResult,
                                item.monitorResult,
                                item.status
                              )
                            }
                          >
                            <EyeIcon />
                          </IconButton>
                        )}
                        {item.isBelongToThisAccount &&
                          item.status !== AssessmentStatus.Completed &&
                          !item.hasAlertBeenSeen && (
                            <IconButton
                              onClick={() =>
                                handleViewReport(
                                  item.suicideAlertId,
                                  item.interviewResult,
                                  item.monitorResult,
                                  item.status
                                )
                              }
                            >
                              <CheckBoxOutlinedIcon />
                            </IconButton>
                          )}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: SuicideAlertType[]) => {
    return (
      <>
        {!data || !data.length ? (
          <Typography my={2} paddingLeft={2} color="text.secondary" align="left">
            {t('patient.suicidalPatients.noRecords')}
          </Typography>
        ) : (
          <DataList
            lineHeight={'auto'}
            data={data.map((item: SuicideAlertType) => {
              const isInterview = item.interviewResult ? t('interview.title') : t('monitor.title');

              let markAsReadComponent: ReactElement = <Box></Box>;

              if (item.status === AssessmentStatus.Completed) {
                markAsReadComponent = (
                  <Link
                    sx={{ fontSize: 16 }}
                    component="button"
                    onClick={() =>
                      handleViewReport(
                        item.suicideAlertId,
                        item.interviewResult,
                        item.monitorResult,
                        item.status
                      )
                    }
                  >
                    {t('patient.patientList.menu.viewReport')}
                  </Link>
                );
              }

              if (
                item.isBelongToThisAccount &&
                item.status !== AssessmentStatus.Completed &&
                !item.hasAlertBeenSeen
              ) {
                markAsReadComponent = (
                  <Link
                    sx={{ fontSize: 16 }}
                    component="button"
                    onClick={() =>
                      handleViewReport(
                        item.suicideAlertId,
                        item.interviewResult,
                        item.monitorResult,
                        item.status
                      )
                    }
                  >
                    {t('patient.patientList.menu.markAsRead')}
                  </Link>
                );
              }
              return {
                leftHeader: markAsReadComponent,
                rightHeader: (
                  <Box
                    sx={{
                      ...statusBoxStyle(
                        !isDesktop,
                        item.dateViewed ? AssessmentStatus.SeenOn : AssessmentStatus.UnRead
                      ),
                      maxWidth: '100%',
                      width: 'fit-content',
                      p: 1,
                    }}
                  >
                    {item.dateViewed
                      ? t('patient.suicidalPatients.tableBody.seenOn', {
                          dateViewed: formatDate(item.dateViewed),
                        })
                      : t('patient.suicidalPatients.tableBody.unRead')}
                  </Box>
                ),
                items: [
                  [
                    <>{t('patient.suicidalPatients.tableHeader.patient')}</>,
                    <Box sx={{ wordBreak: 'break-word' }}>
                      {item.patientFirstName && item.patientLastName
                        ? `${item.patientFirstName} ${item.patientLastName}`
                        : item.externalId
                        ? t('patient.suicidalPatients.tableBody.externalId', {
                            externalId: item.externalId,
                          })
                        : t('patient.suicidalPatients.tableBody.patientId', {
                            patientId: item.patientId,
                          })}
                    </Box>,
                  ],
                  [
                    <>{t('patient.suicidalPatients.tableHeader.interviewer')}</>,
                    <Box
                      sx={{ wordBreak: 'break-word' }}
                    >{`${item.interviewerFirstName} ${item.interviewerLastName}`}</Box>,
                  ],
                  [
                    <>{t('patient.suicidalPatients.tableHeader.assessmentType')}</>,
                    <>{isInterview}</>,
                  ],
                  [
                    <>{t('patient.suicidalPatients.tableHeader.dateScored')}</>,
                    <>{formatDate(item.dateScored)}</>,
                  ],
                ],
              };
            })}
            customStyle={{
              borderRadius: '16px 16px 0 0',
            }}
          />
        )}
      </>
    );
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('patient.suicidalPatients.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={!isDesktop}>
        <PageHeader
          sx={{ mb: 2, mx: isDesktop ? 0 : 2 }}
          headerText={t('patient.suicidalPatients.title')}
          backUrl={'/dashboard'}
        />

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={tableRef}>
            {isDesktop
              ? renderDesktopData(displaySuicideAlert)
              : renderMobileData(displaySuicideAlert)}

            {displaySuicideAlert?.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  py: 4,
                  borderBottomLeftRadius: '15px',
                  borderBottomRightRadius: '15px',
                  background: theme.xPalette.white,
                  mt: 0,
                  [theme.breakpoints.down('sm')]: {
                    mt: 1,
                    py: 2,
                  },
                }}
              >
                <CustomPagination
                  changePage={(e, value) => handlePageChange(value)}
                  currentPage={searchOptions.page}
                  pages={totalPages}
                  pageRef={tableRef}
                />
              </Box>
            )}
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
}
