import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import RoundedContainer from '../../common/components/RoundedContainer';
import { ResponseError } from '../../types/ResponseError';
import { TemplateDetail } from '../../types/TemplateType';
import { selectUser } from '../auth/authSlice';

interface TemplateFormProps {
  templateId?: number;
  templateData?: TemplateDetail;
  onFormSubmit: (
    templateData: TemplateDetail,
    templateId?: number,
    successCallback?: () => void
  ) => Promise<void>;
}

export default function AddOrEditForm({
  templateId,
  templateData,
  onFormSubmit,
}: TemplateFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const currentUser = useAppSelector(selectUser);
  const [initialValue, setInitialValue] = useState<string>('');

  const defaultFormValues = {
    name: '',
    description: '',
    template: '',
  };

  const validationSchema = yup
    .object({
      name: yup.string().required(t('eSignature.template.error.blankName')),
      description: yup.string(),
      template: yup.string().required(t('eSignature.template.error.blankHtml')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<TemplateDetail>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  // initial data load
  useEffect(() => {
    if (templateId && templateData) {
      if (!isDataLoaded) {
        setIsDataLoaded(true);
        setValue('name', templateData.name);
        setValue('template', templateData.template);
        setValue('description', templateData.description);
        setInitialValue(templateData.template);
      }
    }
  }, [templateId, templateData, isDataLoaded, setValue, currentUser]);

  const onSubmit = async (data: TemplateDetail) => {
    try {
      await onFormSubmit(data, templateId, () => {
        if (!templateId) {
          reset();
        }
        setPageErrors([]);
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  const onEditorChange = (newHtmlContent: string) => {
    setValue('template', newHtmlContent);
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        px: 0,
        pt: 0,
      }}
    >
      <RoundedContainer sx={{ paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            px: matches ? 0 : 3,
            pt: 0,
          }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.name}
                helperText={errors.name?.message}
                margin="normal"
                fullWidth
                label={t('eSignature.template.name.title')}
                variant="outlined"
                sx={{ width: '100%' }}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label={t('eSignature.template.description.title')}
                variant="outlined"
                sx={{ width: '100%', marginBottom: 3 }}
              />
            )}
          />

          <Controller
            name="template"
            control={control}
            render={({ field }) => (
              <TinyMCEEditor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                initialValue={initialValue}
                onEditorChange={onEditorChange}
                init={{
                  height: 500,
                  width: '100%',
                  plugins: [
                    // 'print',
                    'preview',
                    // 'paste',
                    'importcss',
                    'searchreplace',
                    'autolink',
                    'save',
                    'directionality',
                    'code',
                    'visualblocks',
                    'visualchars',
                    'fullscreen',
                    'image',
                    'link',
                    'template',
                    'codesample',
                    'table',
                    'charmap',
                    // 'hr',
                    'pagebreak',
                    'nonbreaking',
                    'anchor',
                    'insertdatetime',
                    'advlist',
                    'lists',
                    'wordcount',
                    // 'imagetools',
                    // 'textpattern',
                    // 'noneditable',
                    'help',
                    'charmap',
                    'quickbars',
                    'emoticons',
                  ],
                  menubar: 'file edit view insert format tools table help',
                  toolbar:
                    'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                  toolbar_sticky: true,
                  image_advtab: true,
                  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  image_caption: true,
                  quickbars_selection_toolbar:
                    'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  noneditable_noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  contextmenu: 'link image imagetools table',
                  skin: 'oxide',
                  content_css: 'default',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
            )}
          />
          {!!errors.template && (
            <Alert severity="error" sx={{ mt: 1, width: '100%' }}>
              {errors.template?.message}
            </Alert>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            pb: 3,
            px: matches ? 0 : 3,
            pt: 3,
          }}
        >
          {!matches && <Divider sx={{ width: '100%' }} />}

          {pageErrors?.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            ))}
          <Button
            onClick={submitForm}
            fullWidth
            variant="contained"
            sx={{
              width: matches ? '100%' : '24%',
              my: 2,
              py: 2,
              float: 'right',
            }}
          >
            {templateId ? t('eSignature.template.update') : t('eSignature.template.add')}
          </Button>
        </Box>
      </RoundedContainer>
    </Box>
  );
}
