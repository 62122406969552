export const CallActionType = {
  Delete: 'delete',
  Deactivate: 'deactivate',
  Reactivate: 'reactivate',
  StopSchedule: 'stopSchedule',
  DeleteReport: 'deleteReport',
  ResetUserPassword: 'resetUserPassword',
};
export const EditAccountActionType = {
  SetIdentityProviderId: 'setIdentityProviderId',
  EnableMfa: 'enableMfa',
  DisableIntakePacket: 'disableIntakePacket',
};

export const RescoreAction = {
  ChangeAccount: 'changeAccount',
  ChangeJobType: 'changeJobType',
};
