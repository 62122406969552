import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import axios from 'axios';
import isURL from 'validator/lib/isURL';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { buildAuthUrl } from '../../app/services';
import { useEhrCheckLoginQuery } from '../../app/services/auth';
import AnonymousLayout from '../../common/layouts/Anonymous';
import { newRelic } from '../../common/utils/newRelic';
import { getPageTitle } from '../../common/utils/pageUtils';
import { saveSessionCookie } from '../../common/utils/storageUtils';
import { clearCredentials, setCredentials, selectUser } from './authSlice';

export default function EhrLoginPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken') || '';
  const patient = searchParams.get('patient') || '';
  const ehrType = searchParams.get('ehrType') || '';
  const launch = searchParams.get('launch') || '';
  const iss = searchParams.get('iss') || '';
  const error = searchParams.get('error') || '';
  const currentUser = useAppSelector(selectUser);

  // Form setup
  const { data: ehrData, isLoading } = useEhrCheckLoginQuery(
    ehrType ? { ehrType, launch, iss } : skipToken
  );

  useEffect(() => {
    if (error && error !== '') {
      setPageErrors([error]);
    }
    if (ehrData?.errors) {
      setPageErrors(ehrData?.errors);
    }
    if (ehrData?.errorMessage) {
      setPageErrors([ehrData?.errorMessage]);
    }
  }, [error, ehrData]);

  useEffect(() => {
    if (ehrData && ehrData.redirectUrl) {
      if (isURL(ehrData.redirectUrl)) {
        if (
          isURL(ehrData.redirectUrl, {
            protocols: ['http', 'https', 'ftp'],
            require_protocol: true,
            require_valid_protocol: true,
          })
        ) {
          dispatch(clearCredentials());
          window.open(ehrData.redirectUrl, '_self');
        } else {
          dispatch(clearCredentials());
          window.open('https://' + ehrData.redirectUrl, '_self');
        }
      } else {
        setPageErrors([t('login.error.ehrConfigurationInvalid')]);
      }
    }
    // eslint-disable-next-line
  }, [ehrData, t]);

  useEffect(() => {
    if (accessToken) {
      const getUserInfo = async (token: string) => {
        const httpClient = axios.create();
        httpClient.defaults.timeout = 3000;
        const { data: infoUserLogin } = await httpClient.post(
          buildAuthUrl('/v1/auth/check-token-sso'),
          { token },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (infoUserLogin && infoUserLogin.user) {
          dispatch(clearCredentials());
          dispatch(
            setCredentials({
              user: infoUserLogin.user,
              accessToken: infoUserLogin.accessToken,
              refreshToken: infoUserLogin.refreshToken,
              isShowMfa: infoUserLogin.isShowMfa,
              remainingTime: infoUserLogin.remainingTime,
            })
          );

          setPageErrors([]);
          saveSessionCookie();
          // monitor logged in user
          newRelic.addPageAction('userLoggedIn', { nViewUserId: infoUserLogin.user.id });
          if (patient) {
            navigate(`/dashboard/patient/${patient}`, { replace: true });
          } else {
            navigate('/dashboard', { replace: true });
          }
        }
      };

      if (!Object.values(currentUser).length) {
        getUserInfo(accessToken);
      } else {
        if (patient) {
          navigate(`/dashboard/patient/${patient}`, { replace: true });
        } else {
          navigate('/dashboard', { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!accessToken && (
        <AnonymousLayout>
          <Helmet>
            <title>{getPageTitle(t('login.title'))} </title>
          </Helmet>
          <Container maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant="h1">{t('login.ehrTitle')}</Typography>
              {isLoading || ehrData?.redirectUrl ? (
                <Box sx={{ position: 'absolute', top: '16%' }}>
                  <Box sx={{ top: '16%' }}>
                    <Box className="spinner">
                      <Box className="dot1"></Box>
                      <Box className="dot2"></Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box textAlign="center" sx={{ mt: 1 }}>
                  {pageErrors.length > 0 &&
                    pageErrors.map((error) => (
                      <Alert key={error} severity="error" sx={{ mt: 2, textAlign: 'left' }}>
                        {error}
                      </Alert>
                    ))}
                </Box>
              )}
            </Box>
          </Container>
        </AnonymousLayout>
      )}
    </>
  );
}
