import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useActivateOrDeactivateSiteMutation, useGetSiteListQuery } from '../../app/services/site';
import { useToast } from '../../app/toast';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import DeactivatePatientIcon from '../../common/components/icons/DeactivatePatientIcon';
import EditSiteIcon from '../../common/components/icons/EditInterviewIcon';
import ReactivateSiteIcon from '../../common/components/icons/ReactivateSiteIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import NoteIcon from '../../common/components/icons/NoteIcon';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { CallActionType } from '../../common/utils/callActionType';
import { Role } from '../../common/utils/loginRole';
import { getPageTitle } from '../../common/utils/pageUtils';
import { canAddSite } from '../../common/utils/permissionUtils';
import { SiteStatus, SiteTabStatus } from '../../common/utils/siteListUtils';
import { SortFields, SortDirs } from '../../common/utils/sortInfo';
import { ClinicSiteListItem } from '../../types/ClinicSiteType';
import { PageProps } from '../../types/PageProps';
import { PopupContent } from '../../types/PopupType';
import { selectUser } from '../auth/authSlice';
import ContextMenu from '../patient/ContextMenu';
import PatientSortMenu from '../patient/PatientSortMenu';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledTab = styled(Tab)(() => ({
  width: '50%',
}));

const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.xPalette.white,
  flexBasis: '100%',
  minWidth: '200px',
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'unset',
    width: '74%',
    minWidth: 'unset',
  },
}));

const StyledAddWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 0px',
  width: '100%',
  padding: '0px 8px',
  columnGap: '8px',

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    width: 'auto',
    flexBasis: '40%',
    maxWidth: '380px',
  },

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',
  },
}));

const StyledSortIconBox = styled(Box)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.primary.main,
  verticalAlign: 'middle',
}));

const StyledAddUserBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: 0,
  width: '40%',
  minWidth: '110px',
  ml: 1,
  [theme.breakpoints.down('sm')]: {
    width: '96%',
    height: '56px',
    ml: 0,
  },
}));

export default function SiteListPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const toast = useToast();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [searchParams] = useSearchParams();
  const [displaySiteData, setDisplaySiteData] = useState<ClinicSiteListItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [navBackUrl, setNavBackUrl] = useState<string>('/dashboard/sites');
  const [siteIdAction, setSiteIdAction] = useState<number>(1);
  const [callAction, setCallAction] = useState<string>('');
  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);
  const currentUser = useAppSelector(selectUser);

  const tableHeaders: string[] = Object.values(
    t('clinicSite.manageSites.tableHeader', {
      returnObjects: true,
    })
  );

  const deactivateSiteContentPopup = {
    title: t('clinicSite.manageSites.deactivateSite.title'),
    description: t('clinicSite.manageSites.deactivateSite.description'),
    btnOk: t('clinicSite.manageSites.deactivateSite.btnOk'),
    btnClose: t('clinicSite.manageSites.deactivateSite.btnClose'),
    toastMessage: t('clinicSite.manageSites.deactivateSite.toastMessage'),
  } as PopupContent;

  const reactivateSiteContentPopup = {
    title: t('clinicSite.manageSites.reactivateSite.title'),
    description: t('clinicSite.manageSites.reactivateSite.description'),
    btnOk: t('clinicSite.manageSites.reactivateSite.btnOk'),
    btnClose: t('clinicSite.manageSites.reactivateSite.btnClose'),
    toastMessage: t('clinicSite.manageSites.reactivateSite.toastMessage'),
  } as PopupContent;

  const [activateOrdeactivateSite] = useActivateOrDeactivateSiteMutation();

  const searchTextboxRef = useRef<HTMLInputElement>(null);

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
    search: searchParams.get('search') || '',
    status: searchParams.get('status') || SiteStatus.ACTIVE,
    sortField: searchParams.get('sort') || SortFields.Name,
    sortDir: searchParams.get('dir') || SortDirs.Asc,
  };

  const currentPage = searchOptions.page;
  const tableRef = useRef<HTMLDivElement>(null);

  const { data: siteData, isLoading: isLoadingSite } = useGetSiteListQuery({
    search: searchOptions.search,
    page: currentPage,
    pageSize: pageSize,
    status: searchOptions.status,
    sort: `${searchOptions.sortField} ${searchOptions.sortDir}`,
  });

  const updateSearchUrl = (updatedData: any) => {
    const params = {
      ...searchOptions,
      ...updatedData,
    };
    const url = generatePath(
      // eslint-disable-next-line no-useless-concat
      '/dashboard/sites/' +
        '?search=:search' +
        '&status=:status' +
        '&page=:page' +
        '&sort=:sort' +
        '&dir=:dir',
      params
    );
    if (params?.status === SiteStatus.DEACTIVATED) {
      setTabIndex(1);
    }
    if (params?.status === SiteStatus.ACTIVE) {
      setTabIndex(0);
    }
    navigate(url, { replace: true });
    setNavBackUrl(url);
  };

  const showdeactivateSitePopup = async (siteId: number) => {
    dispatch(setContentPopup({ content: deactivateSiteContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setSiteIdAction(siteId);
    handleClose();
    setCallAction(CallActionType.Deactivate);
  };

  const showreactivateSitePopup = async (siteId: number) => {
    dispatch(setContentPopup({ content: reactivateSiteContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setSiteIdAction(siteId);
    handleClose();
    setCallAction(CallActionType.Reactivate);
  };

  useEffect(() => {
    if (searchParams && searchParams.get('status') === SiteStatus.DEACTIVATED) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [searchParams]);

  useEffect(() => {
    if (siteData) {
      setDisplaySiteData(siteData.clinicSites);
      setTotalPages(Math.ceil(siteData.totalItems / pageSize));
    }
  }, [pageSize, siteData]);

  const handlePageChange = (value: number) => {
    updateSearchUrl({ page: value, sort: searchOptions.sortField, dir: searchOptions.sortDir });
  };

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
    updateSearchUrl({
      page: 1,
      status: SiteTabStatus[newIndex] || 'active',
      sort: searchOptions.sortField,
      dir: searchOptions.sortDir,
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    const input = searchTextboxRef.current?.value;
    updateSearchUrl({
      page: 1,
      search: input || '',
      sort: searchOptions.sortField,
      dir: searchOptions.sortDir,
    });
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleMenuSorting = (sortField: string, sortDir: string) => {
    updateSearchUrl({
      sort: sortField,
      dir: sortDir,
    });
  };

  const onHeaderClicked = (header?: string) => {
    if (header) {
      updateSearchUrl({
        sort: header,
        dir:
          header !== searchOptions.sortField || searchOptions.sortDir === SortDirs.Desc
            ? SortDirs.Asc
            : SortDirs.Desc,
      });
    }
  };

  const handleActivateSite = async (successCallback?: () => void) => {
    try {
      await activateOrdeactivateSite({
        siteId: siteIdAction,
        status: SiteStatus.ACTIVE,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('clinicSite.manageSites.reactivateSite.success'), 'success');
      handleSearch();
    } catch (e) {
      toast.publish(t('clinicSite.manageSites.reactivateSite.fail'), 'error');
    }
  };

  const handledeactivateSite = async (successCallback?: () => void) => {
    try {
      await activateOrdeactivateSite({
        siteId: siteIdAction,
        status: SiteStatus.DEACTIVATED,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('clinicSite.manageSites.deactivateSite.success'), 'success');
      handleSearch();
    } catch (e) {
      toast.publish(t('clinicSite.manageSites.deactivateSite.fail'), 'error');
    }
  };

  const renderContextMenu = (item: ClinicSiteListItem) => {
    const isActive = item.status !== SiteStatus.DEACTIVATED;
    return (
      <ContextMenu
        id={`site-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={[
          {
            isDisplayed: isActive,
            label: t('clinicSite.manageSites.menuContext.editSite'),
            icon: <EditSiteIcon />,
            onClick: () => {
              navigate(`/dashboard/sites/edit-site/${item.id}`, { state: { navBackUrl } });
            },
          },
          {
            isDisplayed: !!item.optInPatientReminders,
            label: t('clinicSite.manageSites.menuContext.editPatientReminder'),
            icon: <NoteIcon sx={{ '& path': { fill: 'white' } }} />,
            onClick: () => {
              navigate(`/dashboard/sites/${item.id}/patient-reminders`, { state: { navBackUrl } });
            },
          },
          {
            isDisplayed: true,
            label: isActive
              ? t('clinicSite.manageSites.menuContext.deactivateSite')
              : t('clinicSite.manageSites.menuContext.activateSite'),
            icon: isActive ? <DeactivatePatientIcon /> : <ReactivateSiteIcon />,
            onClick: () =>
              isActive ? showdeactivateSitePopup(item.id) : showreactivateSitePopup(item.id),
          },
        ]}
      />
    );
  };

  const renderDesktopData = (data: ClinicSiteListItem[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders
                .filter((header) => {
                  if (currentUser.roleId !== Role.SuperAdmin) {
                    if (header === t('clinicSite.manageSites.tableHeader.account')) {
                      return false;
                    }
                    return true;
                  } else {
                    return true;
                  }
                })
                .map((header, index) => {
                  const eventHeaders = ['account', 'name'];
                  const checkSortEvent =
                    searchOptions.sortDir === SortDirs.Asc &&
                    searchOptions.sortField === header.toLowerCase();
                  const sortIcon = eventHeaders.includes(header.toLowerCase()) ? (
                    <StyledSortIconBox>
                      {checkSortEvent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </StyledSortIconBox>
                  ) : (
                    <></>
                  );
                  return (
                    <StyledTableCell
                      key={`${header} ${index}`}
                      align={`${
                        header === t('clinicSite.manageSites.tableHeader.action')
                          ? 'center'
                          : 'inherit'
                      }`}
                      onClick={
                        eventHeaders.includes(header.toLowerCase())
                          ? () => onHeaderClicked(header.toLowerCase())
                          : undefined
                      }
                    >
                      {header} {sortIcon}
                    </StyledTableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: ClinicSiteListItem) => {
              const isActive = item.status !== SiteStatus.DEACTIVATED;
              return (
                <TableRow key={item.id}>
                  <StyledTableCell>
                    {isActive ? (
                      <Link
                        sx={{ fontSize: '1em', textAlign: 'left' }}
                        component="button"
                        onClick={() => {
                          navigate(`/dashboard/sites/edit-site/${item.id}`, {
                            state: { navBackUrl },
                          });
                        }}
                      >
                        {`${item.name}`}
                      </Link>
                    ) : (
                      `${item.name}`
                    )}
                  </StyledTableCell>
                  {currentUser.roleId === Role.SuperAdmin && (
                    <StyledTableCell>{item.clinicAccountName}</StyledTableCell>
                  )}
                  <StyledTableCell>{item.location}</StyledTableCell>
                  <StyledTableCell align="center">{renderContextMenu(item)}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: ClinicSiteListItem[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: ClinicSiteListItem) => {
          const isActive = item.status !== SiteStatus.DEACTIVATED;

          const accountItem = [
            <>{t('clinicSite.manageSites.tableHeader.account')}</>,
            <Box sx={{ wordBreak: 'break-word' }}>{item.clinicAccountName}</Box>,
          ];
          const listItems = [
            [
              <>{t('clinicSite.manageSites.tableHeader.location')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.location}</Box>,
            ],
          ];

          if (currentUser.roleId === Role.SuperAdmin) {
            listItems.unshift(accountItem);
          }
          return {
            leftHeader: isActive ? (
              <Link
                sx={{ fontSize: '20px', textAlign: 'left', fontWeight: '600' }}
                component="button"
                onClick={() => {
                  navigate(`/dashboard/sites/edit-site/${item.id}`, {
                    state: { navBackUrl },
                  });
                }}
              >
                {`${item.name}`}
              </Link>
            ) : (
              <Typography variant="body1" fontWeight={600} fontSize={20}>
                {item.name}
              </Typography>
            ),
            rightHeader: renderContextMenu(item),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  const isLoading = isLoadingSite;

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('clinicSite.manageSites.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={!isDesktop}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 0 : 2 }}
            headerText={t('clinicSite.manageSites.title')}
            backUrl={'/dashboard'}
          />

          <StyledAddWrapper>
            <StyledSearchBox
              name="site-search-field"
              inputRef={searchTextboxRef}
              onKeyUp={handleSearchKeyUp}
              defaultValue={searchOptions.search}
              placeholder={t('clinicSite.manageSites.searchSite')}
              endAdornment={
                <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              }
            />
            {!isDesktop && (
              <PatientSortMenu
                sx={{
                  width: '20%',
                  flexShrink: 0,
                  borderRadius: '8px',
                  minWidth: 'unset',
                }}
                sortField={searchOptions.sortField}
                sortDir={searchOptions.sortDir}
                onSorted={handleMenuSorting}
                isSite={true}
              />
            )}
            {canAddSite(currentUser.roleId) && (
              <StyledAddUserBox
                type="button"
                fullWidth
                variant="contained"
                onClick={() => {
                  navigate('/dashboard/sites/add-site', { state: { navBackUrl } });
                }}
              >
                {t('clinicSite.manageSites.addSite')}
              </StyledAddUserBox>
            )}
          </StyledAddWrapper>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={tableRef}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, mx: 2 }}>
              <StyledTabs value={tabIndex} onChange={handleTabChange} aria-label="site-list-tabs">
                <StyledTab
                  label={t('clinicSite.manageSites.tableTabs.activeTab')}
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
                <StyledTab
                  label={t('clinicSite.manageSites.tableTabs.deactivatedTab')}
                  id="tab-2"
                  aria-controls="tabpanel-2"
                />
              </StyledTabs>
            </Box>
            {displaySiteData.length === 0 ? (
              <RoundedContainer sx={{ mt: 2, p: 2 }}>
                <Typography>{t('clinicSite.manageSites.error.sitesNotFound')}</Typography>
              </RoundedContainer>
            ) : (
              <>
                {isDesktop ? renderDesktopData(displaySiteData) : renderMobileData(displaySiteData)}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 4,
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    background: theme.xPalette.white,
                    mt: 0,
                    [theme.breakpoints.down('sm')]: {
                      mt: 1,
                      py: 2,
                    },
                  }}
                >
                  <CustomPagination
                    changePage={(e, value) => handlePageChange(value)}
                    currentPage={searchOptions.page}
                    pages={totalPages}
                    pageRef={tableRef}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={
            callAction === CallActionType.Deactivate ? handledeactivateSite : handleActivateSite
          }
        />
      </Container>
    </DashboardLayout>
  );
}
