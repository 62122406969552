import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { useCheckConfigSendReportQuery } from '../../app/services/account';
import { useGenerateReportAndUploadToS3Mutation, useSendPatientReportToEHRMutation } from '../../app/services/interview';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { PageStatus, setPageStatus } from '../../common/slices/globalSlice';
import { commonButtonStyle } from '../../common/utils/commonStyles';
import {
  ReplaceType,
  addBreadcrumb,
  editBreadcrumbs,
} from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import PatientChartReportComponent from './PatientChartReportComponent';
import { useEffect, useState } from 'react';
import { logger } from '../../common/utils/logger';

export default function PatientChartReportPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { patientId } = useParams();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { state } = useLocation();
  const { fromPage, navBackUrl } = !!state && state;
  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }];
  const [enableViewReport, setEnableViewReport] = useState(false);

  const [sendPatientReportToEHR] = useSendPatientReportToEHRMutation();

  const { data: accountConfig } = useCheckConfigSendReportQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const [generateReportAndUploadToS3] = useGenerateReportAndUploadToS3Mutation();

  useEffect(() => {
    if (patientId) {
      generateReportAndUploadToS3({ patientId }).unwrap().finally(() => {
        logger.info("Done for generating report and upload to S3");
        // Don't care sucess or error
        setEnableViewReport(true);
      }).catch((err) => {
        logger.error("Error when generating report and upload to S3", err);
      });
    }
  }, [generateReportAndUploadToS3, patientId]);


  const handleSendPatientReportToEHR = async () => {
    if (patientId) {
      try {
        const res = await sendPatientReportToEHR({ patientId }).unwrap();
        if (res && res.message) {
          toast.publish(res.message, 'success');
        } else {
          toast.publish(t('patientReport.error.cannotSendReportToEHR'), 'error');
        }
        dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
      } catch (e) {
        dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
        toast.publish(t('patientReport.error.cannotSendReportToEHR'), 'error');
      }
    }
  };

  return (
    <DashboardLayout
      breadcrumbs={
        !fromPage
          ? editBreadcrumbs(breadcrumbs, ...replacePath)
          : addBreadcrumb(breadcrumbs!, fromPage)
      }
    >
      <Helmet>
        <title>{getPageTitle(t('patientReport.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            px: matches ? 2 : 3,
          }}
        >
          <PageHeader headerText={t('patientReport.title')} backUrl={navBackUrl || `/dashboard/patient/${patientId}`} />
          <Box sx={{ display: 'flex', px: 2, columnGap: 1 }}>
            {patientId && enableViewReport && (
              <Link
                to={`/report/patient/${patientId}/Patient_Report.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="contained"
                  sx={{
                    ...commonButtonStyle(matches),
                  }}
                >
                  {t('patientReport.viewReport')}
                </Button>
              </Link>
            )}
            {patientId && !enableViewReport && (
              <Button
                variant="contained"
                disabled={true}
                sx={{
                  ...commonButtonStyle(matches),
                }}
              >
                {t('patientReport.viewReport')}
              </Button>
            )}
            {accountConfig?.ehrEnabled && accountConfig?.sendPatientReportEhr && (
              <Button
                disabled={!enableViewReport}
                variant="contained"
                sx={{
                  ...commonButtonStyle(matches),
                }}
                onClick={handleSendPatientReportToEHR}
              >
                {t('patientReport.sendToEHR')}
              </Button>
            )}
          </Box>
        </Box>
      </Container>
      <RoundedContainer sx={{ background: 'rgb(247, 248, 249)' }}>
        <PatientChartReportComponent />
      </RoundedContainer>
    </DashboardLayout>
  );
}
