import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { buildMonitorConductUrl } from '../../app/routes';
import { landingTextStyle } from '../../common/utils/commonStyles';
import { MonitorModeName } from '../../common/utils/monitorsMode';
import { MonitorSettingsResponse } from '../../types/MonitorTypes';
import { clearCredentials } from '../auth/authSlice';

interface PageProps {
  monitorMode: 'clinician' | 'patient';
  accountSettings?: MonitorSettingsResponse;
  resumeMonitor: boolean;
  pageErrors?: string[];
  cancelUrl: string;
}

// TODO: move to share file for interview pages
export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '16px 0',
  marginBottom: '8px',
  borderRadius: '8px',
  width: '100%',

  minWidth: 200,
  '&.MuiButton-text': {
    backgroundColor: theme.xPalette.white,
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up('md')]: {
    width: '20%',
    marginBottom: 0,
    marginLeft: '8px',
  },
}));

export default function ReadyOrResumeMonitor({
  monitorMode,
  resumeMonitor,
  accountSettings,
  pageErrors,
  cancelUrl,
}: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { patientId, monitorResultId } = useParams();

  const isClinicianMonitor = monitorMode === MonitorModeName.Clinician;
  const dispatch = useAppDispatch();

  const renderLandingHeader = () => {
    if (accountSettings?.landingHeader) {
      return (
        <Typography
          variant="h1"
          dangerouslySetInnerHTML={{
            __html: accountSettings.landingHeader,
          }}
        ></Typography>
      );
    }

    return resumeMonitor ? (
      <Typography variant="h1">{t('monitor.MonitorInstructionsPage.resume-monitor')}</Typography>
    ) : (
      <Typography variant="h1">{t('monitor.MonitorInstructionsPage.title')}</Typography>
    );
  };

  const renderLandingText = () => {
    if (accountSettings?.landingText) {
      return (
        <Typography
          dangerouslySetInnerHTML={{
            __html: accountSettings.landingText,
          }}
        ></Typography>
      );
    }

    return resumeMonitor ? (
      <Typography variant="body1">
        {t('monitor.MonitorInstructionsPage.body.p3_existing')}
      </Typography>
    ) : (
      <Typography
        dangerouslySetInnerHTML={{
          __html: t('monitor.MonitorInstructionsPage.landingPageMonitorText'),
        }}
      ></Typography>
    );
  };

  return (
    <>
      <Box sx={{ py: 3 }}>{renderLandingHeader()}</Box>
      <Box sx={{ mb: 1, ...landingTextStyle() }}>{renderLandingText()}</Box>
      {pageErrors &&
        pageErrors.length > 0 &&
        pageErrors.map((error) => (
          <Alert key={error} severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        ))}
      <Box
        sx={{
          py: 2,
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: matches ? 'column' : 'row-reverse',
        }}
      >
        <StyledButton
          disabled={pageErrors && pageErrors.length > 0}
          type="button"
          variant="contained"
          onClick={() => {
            const monitorConductUrl = buildMonitorConductUrl(monitorMode, {
              monitorResultId: monitorResultId!,
              patientId: patientId!,
            });

            if (isClinicianMonitor) {
            } else {
              dispatch(clearCredentials());
            }
            navigate(monitorConductUrl);
          }}
        >
          {resumeMonitor
            ? t('monitor.MonitorInstructionsPage.submit_existing')
            : t('monitor.MonitorInstructionsPage.submit_new')}
        </StyledButton>
        <StyledButton
          type="button"
          variant="outlined"
          onClick={() => {
            navigate(cancelUrl || `/dashboard/patient/${patientId}`);
          }}
        >
          {t('monitor.MonitorInstructionsPage.cancel')}
        </StyledButton>
      </Box>
    </>
  );
}
