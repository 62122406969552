import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetPatientQuery } from '../../../app/services/patient';
import { useDeleteTreatmentSignatureMutation } from '../../../app/services/treatment-plan/treatmentSignature';
import theme from '../../../app/theme';
import { useToast } from '../../../app/toast';
import LocalPopupModal from '../../../common/components/LocalPopupModal';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { formatDate } from '../../../common/utils/dateTimeUtils';
import { toTitleCase } from '../../../common/utils/stringUtils';
import { PopupContent } from '../../../types/PopupType';
import { TreatmentSignature } from '../../../types/treatment-plan/TreatmentPlanType';
import {
  TreatmentSignatureProviderType,
  TreatmentSignatureStatus,
  TreatmentSignatureType,
} from '../../../types/treatment-plan/TreatmentSignature';
import { setTreatmentPlanReloadOption } from '../treatmentPlanSlice';
import TreatmentSignatureForm from './SignatureForm';

interface SignatureListProps {
  parentPlanId?: number;
  treatmentPlanCompletedSigned?: boolean;
  signatureData?: TreatmentSignature[];
}

export default function SignatureList({
  parentPlanId,
  treatmentPlanCompletedSigned,
  signatureData,
}: SignatureListProps) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const [displayItems, setDisplayItems] = useState<TreatmentSignature[]>([]);
  const [isSignPopup, setIsSignPopup] = useState<boolean>(false);
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState<boolean>(false);
  const [deletingId, setDeletingId] = useState<number>(0);
  const { patientId } = params;

  const { data: patientInfo } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const { firstName: firstNamePatient, lastName: lastNamePatient } = patientInfo || {};
  const patientName =
    (firstNamePatient || lastNamePatient) && `${firstNamePatient} ${lastNamePatient}`;

  const [deleteSignature] = useDeleteTreatmentSignatureMutation();

  useEffect(() => {
    if (signatureData) {
      const sortedData = signatureData
        .slice()
        .sort((a: TreatmentSignature, b: TreatmentSignature) =>
          a.signatureProviderType.localeCompare(b.signatureProviderType)
        );
      setDisplayItems(sortedData);
    }
  }, [signatureData]);

  const renderDataList = (displayItems: TreatmentSignature[]) => {
    return (
      <Box
        sx={{
          width: '100%',
          margin: 1,
        }}
      >
        {displayItems.map((item: TreatmentSignature) => {
          return (
            <Box
              key={item.id}
              sx={{
                width: isDesktop ? '55%' : '100%',
                marginTop: 3,
              }}
            >
              <Box
                sx={{
                  marginBottom: 1,
                  display: 'flex',
                  columnGap: 2,
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <Typography>
                  <b>{toTitleCase(item?.signatureProviderType)}</b>
                  {`: ${
                    item?.signatureProviderType === TreatmentSignatureProviderType.Patient &&
                    (item.signatureType === TreatmentSignatureType.Participate ||
                      item.signatureType === TreatmentSignatureType.Email) &&
                    item.status === TreatmentSignatureStatus.Signed
                      ? t('treatmentPlans.signatureForm.signatureTypes.participate')
                      : ''
                  }`}
                </Typography>
                <Link
                  textAlign="right"
                  component="button"
                  type="reset"
                  variant="body1"
                  onClick={() => {
                    showDeletePopup(item.id);
                  }}
                >
                  {t('treatmentPlans.signatureForm.delete')}
                </Link>
              </Box>

              {item?.status === TreatmentSignatureStatus.Signed &&
                item?.signature &&
                item?.signature !== '' && <img width="100%" src={item?.signature} alt="" />}
              {item?.status !== TreatmentSignatureStatus.Signed && (
                <Box
                  sx={{
                    bgcolor: theme.xPalette.noteLightGrey,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: 0.6,
                    aspectRatio: '82/25',
                  }}
                >
                  <Typography
                    fontWeight="700"
                    align="center"
                    color={item?.status === TreatmentSignatureStatus.Refused ? 'red' : ''}
                    fontSize="30px!important"
                  >
                    {t(`treatmentPlans.signatureForm.${item?.status}`).toUpperCase()}
                  </Typography>
                </Box>
              )}
              <Typography display="flex" justifyContent="space-between" marginTop={1}>
                {item?.signatureProviderType === TreatmentSignatureProviderType.Patient
                  ? patientName
                  : item.providerName}
                <Typography>{`Date: ${
                  item?.status !== TreatmentSignatureStatus.NotSigned
                    ? formatDate(item.signedDate)
                    : formatDate(item.createdAt)
                }`}</Typography>
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  const signContentPopup = {
    title: t('treatmentPlans.signatureForm.title'),
  } as PopupContent;

  const deleteContentPopup = {
    title: t('treatmentPlans.treatmentSignatureList.delete.title'),
    description: `${t('treatmentPlans.treatmentSignatureList.delete.description')}`,
    btnOk: t('treatmentPlans.treatmentSignatureList.delete.btnOk'),
    btnClose: t('treatmentPlans.treatmentSignatureList.delete.btnClose'),
    toastMessage: t('treatmentPlans.treatmentSignatureList.delete.toastMessage'),
  } as PopupContent;

  const showAddEditPopup = async () => {
    setIsSignPopup(true);
  };

  const closeAddEditPopup = async () => {
    setIsSignPopup(false);
  };

  const showDeletePopup = async (signatureId: number) => {
    setIsOpenDeletePopup(true);
    setDeletingId(signatureId);
  };

  const closeDeletePopup = async () => {
    setIsOpenDeletePopup(false);
  };

  const onFormSubmitCallback = async (result: boolean) => {
    if (result) {
      setIsSignPopup(false);
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
    }
  };

  const handleDeleteSignature = async () => {
    closeDeletePopup();
    try {
      await deleteSignature({ treatmentSignatureId: deletingId }).unwrap();
      toast.publish(t('treatmentPlans.treatmentSignatureList.delete.success'), 'success');
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
    } catch (e) {
      toast.publish(t('treatmentPlans.treatmentSignatureList.delete.fail'), 'error');
    }
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: isDesktop ? 'center' : 'normal',
          paddingTop: 1,
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            fontSize: '18px!important',
            fontWeight: 'bold',
            paddingBottom: isDesktop ? 0 : 2,
          }}
        >
          {t('treatmentPlans.treatmentSignatureList.title')}
        </Typography>
        {!!parentPlanId && (
          <Button
            onClick={() => {
              showAddEditPopup();
            }}
            fullWidth
            variant="contained"
            disabled={treatmentPlanCompletedSigned}
            sx={{
              width: isDesktop ? '24%' : '100%',
              py: 2,
              float: 'right',
            }}
          >
            {t('treatmentPlans.signatureForm.sign')}
          </Button>
        )}
      </Box>
      <Box sx={{ width: '100%' }}>
        {displayItems?.length === 0 ? (
          <RoundedContainer
            sx={{
              mt: 2,
              p: 2,
              background: theme.xPalette.background,
              color: 'rgba(0, 0, 0, 0.7)',
            }}
          >
            <Typography>{t('treatmentPlans.treatmentSignatureList.error.notFound')}</Typography>
          </RoundedContainer>
        ) : (
          <Box sx={{ mt: 2 }}>{renderDataList(displayItems)}</Box>
        )}
      </Box>
      {!!parentPlanId && (
        <>
          {/* Delete Popup */}
          <LocalPopupModal
            isOpen={isOpenDeletePopup}
            contentPopup={deleteContentPopup}
            onSuccess={handleDeleteSignature}
            onClose={closeDeletePopup}
          />
          <LocalPopupModal
            isOpen={isSignPopup}
            contentPopup={signContentPopup}
            onClose={closeAddEditPopup}
            maxWidth={'md'}
            fullWidth={true}
          >
            <TreatmentSignatureForm
              parentPlanId={parentPlanId}
              onFormSubmitCallback={onFormSubmitCallback}
            />
          </LocalPopupModal>
        </>
      )}
    </Box>
  );
}
