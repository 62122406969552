import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as SiteIconSvg } from '../../../assets/icons/usage-report.svg';

export default function UsageReportIcon() {
  return (
    <SvgIcon aria-label="usage-report-icon" fontSize="large" fill="white" viewBox='0 -2 25 25'>
      <SiteIconSvg />
    </SvgIcon>
  );
}
