// EventType will format "action type - action role"
export enum EventType {
  LegacyLogin = 'legacyLogin-user',
  NewUILogin = 'newUILogin-user',
  LegacyLogout = 'legacyLogout-user',
  NewUILogout = 'newUILogout-user',

  AddAccount = 'createdAccount-clinicAccount',
  EditAccount = 'editedAccount-clinicAccount',
  DeactivateAccount = 'deactivatedAccount-clinicAccount',

  AddPatientDiagnosis = 'addedPatientDiagnosis-diagnosis',
  EditPatientDiagnosis = 'editedPatientDiagnosis-diagnosis',
  DeletePatientDiagnosis = 'deletedPatientDiagnosis-diagnosis',

  StartClinicianInterview = 'clinician-patient-interview',
  StartEmailedPatientInterview = 'emailPatient-patient-interview',
  CreateInterviewSchedule = 'createdInterviewSchedule-schedule',
  EditInterviewSchedule = 'editedInterviewSchedule-schedule',
  StartPatientInterview = 'patient-patient-interview',
  DeletePatientInterview = 'deletedPatientInterview-interview',
  EditPatientInterview = 'editedPatientInterview-interview',
  ViewInterviewReport = 'readInterviewResult-patient',
  DisplayInterviewHistory = 'displayInterviewHistory-interview',

  StartClinicianTracker = 'clinician-patient-tracker',
  StartEmailedPatientTracker = 'schedule-patient-tracker',
  ViewMonitorReport = 'readMonitorResult-patient',
  DeleteMonitorReport = 'deletedMonitorResult-patient',
  AddMonitorSchedule = 'createdSchedule-schedule',
  EditMonitorSchedule = 'editedSchedule-schedule',
  DeleteMonitorSchedule = 'deletedMonitorSchedule-schedule',
  StartPatientTracker = 'patient-patient-tracker',

  AddPatient = 'created-patient',
  DeactivatePatient = 'deactivated-patient',
  DeletePatient = 'deleted-patient',
  EditPatient = 'edited-patient',
  ViewPatient = 'read-patient',
  ViewPatientList = 'viewedPatientList-patient',
  AddPatientNote = 'addedPatientNote-patient',

  ViewProfile = 'viewedProfile-profile',
  UpdateProfile = 'updatedProfile-profile',

  AddSite = 'created-site',
  EditSite = 'edited-site',
  DeactivateSite = 'deactivatedSite-site',

  ViewSupportPage = 'viewSupportPage-support',
  ViewSupportDocument = 'viewSupportDocument-support',
  ViewFAQ = 'viewFAQ-support',
  ViewSupportVideo = 'viewSupportVideos-support',

  UnlockAUser = 'unlockAUser-user',
  UnlockAnIPAddress = 'unlockAnIpAddress-unlockAnIpAddress',

  AddUser = 'created-user',
  EditUser = 'edited-user',
  DeactivateUser = 'deactivated-user',
  // Users: Delete a user
  ResetPassword = 'resetPassword-user',
  ImportUser = 'importUser-user',

  // Treatment Plan
  AddTreatmentPlan = 'addedTreatmentPlan-treatmentPlan',
  StartedTreatmentPlan = 'startedTreatmentPlan-treatmentPlan',
  EditTreatmentPlan = 'editedTreatmentPlan-treatmentPlan',
  CompletedSignedTreatmentPlan = 'completedSignedTreatmentPlan-treatmentPlan',
  CompletedNotSignedTreatmentPlan = 'completedNotSignedTreatmentPlan-treatmentPlan',
  DeleteTreatmentPlan = 'deletedTreatmentPlan-treatmentPlan',

  // Treatment Goal
  AddTreatmentGoal = 'addedTreatmentGoal-treatmentGoal',
  StartedTreatmentGoal = 'startedTreatmentGoal-treatmentGoal',
  ReviewTreatmentGoal = 'reviewTreatmentGoal-treatmentGoal',
  CompletedTreatmentGoal = 'completedTreatmentGoal-treatmentGoal',
  EditTreatmentGoal = 'editedTreatmentGoal-treatmentGoal',
  DeleteTreatmentGoal = 'deletedTreatmentGoal-treatmentGoal',

  // Treatment Objective
  AddTreatmentObjective = 'addedTreatmentObjective-treatmentObjective',
  StartedTreatmentObjective = 'startedTreatmentObjective-treatmentObjective',
  ReviewTreatmentObjective = 'reviewTreatmentObjective-treatmentObjective',
  CompletedTreatmentObjective = 'completedTreatmentObjective-treatmentObjective',
  EditTreatmentObjective = 'editedTreatmentObjective-treatmentObjective',
  DeleteTreatmentObjective = 'deletedTreatmentObjective-treatmentObjective',

  // Treatment Strategy
  AddTreatmentStrategy = 'addedTreatmentStrategy-treatmentStrategy',
  EditTreatmentStrategy = 'editedTreatmentStrategy-treatmentStrategy',
  DeleteTreatmentStrategy = 'deletedTreatmentStrategy-treatmentStrategy',

  // Treatment Diagnosis
  AddTreatmentDiagnosis = 'addedTreatmentDiagnosis-treatmentDiagnosis',
  EditTreatmentDiagnosis = 'editedTreatmentDiagnosis-treatmentDiagnosis',
  DeleteTreatmentDiagnosis = 'deletedTreatmentDiagnosis-treatmentDiagnosis',

  // Treatment Signature
  SignedTreatmentSignatureByProvider = 'signedTreatmentSignatureByProvider-treatmentSignature',
  SignedTreatmentSignatureByPatient = 'signedTreatmentSignatureByPatient-treatmentSignature',
  RefusedTreatmentSignatureByPatient = 'refusedTreatmentSignatureByPatient-treatmentSignature',
  EmailedTextedTreatmentSignatureByPatient = 'emailedTextedTreatmentSignatureByPatient-treatmentSignature',
  SignedViaEmailTextTreatmentSignatureByPatient = 'signedViaEmailTextTreatmentSignatureByPatient-treatmentSignature',
  RefusedViaEmailTextTreatmentSignatureByPatient = 'refusedViaEmailTextTreatmentSignatureByPatient-treatmentSignature',
  DeleteTreatmentSignature = 'deleteTreatmentSignature-treatmentSignature',
}

export const eventTypeList = [
  { id: EventType.LegacyLogin, name: 'Legacy Login', group: 'Authentication' },
  { id: EventType.NewUILogin, name: 'New App Login', group: 'Authentication' },
  {
    id: EventType.LegacyLogout,
    name: 'Legacy Logout',
    group: 'Authentication',
  },
  {
    id: EventType.NewUILogout,
    name: 'New App Logout',
    group: 'Authentication',
  },

  { id: EventType.AddAccount, name: 'Add an account', group: 'Accounts' },
  { id: EventType.EditAccount, name: 'Edit an account', group: 'Accounts' },
  { id: EventType.DeactivateAccount, name: 'Deactivate an account', group: 'Accounts' },

  { id: EventType.AddPatientDiagnosis, name: 'Add a diagnosis', group: 'Diagnosis' },
  { id: EventType.EditPatientDiagnosis, name: 'Edit a diagnosis', group: 'Diagnosis' },
  { id: EventType.DeletePatientDiagnosis, name: 'Delete a diagnosis', group: 'Diagnosis' },

  {
    id: EventType.StartClinicianInterview,
    name: 'Started a clinician led interview',
    group: 'Interviews',
  },
  {
    id: EventType.StartEmailedPatientInterview,
    name: 'Emailed one interview to a patient',
    group: 'Interviews',
  },
  {
    id: EventType.CreateInterviewSchedule,
    name: 'Setup an interview schedule',
    group: 'Interviews',
  },
  {
    id: EventType.EditInterviewSchedule,
    name: 'Edit an interview schedule',
    group: 'Interviews',
  },
  {
    id: EventType.StartPatientInterview,
    name: 'Started a patient led interview',
    group: 'Interviews',
  },
  {
    id: EventType.DeletePatientInterview,
    name: 'Delete a report',
    group: 'Interviews',
  },
  {
    id: EventType.EditPatientInterview,
    name: 'Edit a report',
    group: 'Interviews',
  },
  { id: EventType.ViewInterviewReport, name: 'View a report', group: 'Interviews' },
  {
    id: EventType.DisplayInterviewHistory,
    name: 'Display interview history',
    group: 'Interviews',
  },
  {
    id: EventType.StartClinicianTracker,
    name: 'Started a clinician led monitor',
    group: 'Monitors',
  },
  {
    id: EventType.StartEmailedPatientTracker,
    name: 'Emailed one monitor to a patient',
    group: 'Monitors',
  },
  {
    id: EventType.ViewMonitorReport,
    name: 'View a report',
    group: 'Monitors',
  },
  {
    id: EventType.DeleteMonitorReport,
    name: 'Delete a report',
    group: 'Monitors',
  },
  {
    id: EventType.StartPatientTracker,
    name: 'Started a patient led monitor',
    group: 'Monitors',
  },
  {
    id: EventType.AddMonitorSchedule,
    name: 'Setup a monitor schedule',
    group: 'Monitors',
  },
  {
    id: EventType.EditMonitorSchedule,
    name: 'Edit a monitor schedule',
    group: 'Monitors',
  },
  {
    id: EventType.DeleteMonitorSchedule,
    name: 'Delete a monitor schedule',
    group: 'Monitors',
  },

  { id: EventType.AddPatient, name: 'Created a patient', group: 'Patient' },
  { id: EventType.DeactivatePatient, name: 'Deactivated a patient', group: 'Patient' },
  { id: EventType.DeletePatient, name: 'Deleted a patient', group: 'Patient' },
  { id: EventType.EditPatient, name: 'Edit a patient', group: 'Patient' },
  { id: EventType.ViewPatient, name: 'Viewed a patient', group: 'Patient' },
  { id: EventType.ViewPatientList, name: 'Viewed patient list', group: 'Patient' },
  { id: EventType.AddPatientNote, name: 'Added patient notes', group: 'Patient' },

  { id: EventType.ViewProfile, name: 'Viewed the profile', group: 'Profile' },
  { id: EventType.UpdateProfile, name: 'Updated the profile', group: 'Profile' },

  { id: EventType.AddSite, name: 'Add a site', group: 'Sites' },
  { id: EventType.EditSite, name: 'Edit a site', group: 'Sites' },
  { id: EventType.DeactivateSite, name: 'Deactivate a site', group: 'Sites' },

  { id: EventType.ViewSupportPage, name: 'View support page', group: 'Support' },
  { id: EventType.ViewSupportDocument, name: 'Download documentation', group: 'Support' },
  { id: EventType.ViewFAQ, name: "View FAQ's", group: 'Support' },
  { id: EventType.ViewSupportVideo, name: 'View videos', group: 'Support' },

  { id: EventType.UnlockAUser, name: 'Unlock a user', group: 'Users' },
  { id: EventType.UnlockAnIPAddress, name: 'Unlock an IP address', group: 'Users' },

  { id: EventType.AddUser, name: 'Add a user', group: 'Users' },
  { id: EventType.EditUser, name: 'Edit a user', group: 'Users' },
  { id: EventType.DeactivateUser, name: 'Deactivate a user', group: 'Users' },
  { id: EventType.ResetPassword, name: 'Reset password', group: 'Users' },
  { id: EventType.ImportUser, name: 'Import users', group: 'Users' },

  // Treatment Plan
  { id: EventType.AddTreatmentPlan, name: 'Add a treatment plan', group: 'Treatment Plan' },
  { id: EventType.StartedTreatmentPlan, name: 'Started treatment plan', group: 'Treatment Plan' },
  { id: EventType.EditTreatmentPlan, name: 'Edit a treatment plan', group: 'Treatment Plan' },
  {
    id: EventType.CompletedSignedTreatmentPlan,
    name: 'Completed and signed treatment plan',
    group: 'Treatment Plan',
  },
  {
    id: EventType.CompletedNotSignedTreatmentPlan,
    name: 'Completed but not signed treatment plan',
    group: 'Treatment Plan',
  },
  { id: EventType.DeleteTreatmentPlan, name: 'Delete a treatment plan', group: 'Treatment Plan' },

  // Treatment Goal
  { id: EventType.AddTreatmentGoal, name: 'Add a treatment goal', group: 'Treatment Goal' },
  { id: EventType.StartedTreatmentGoal, name: 'Started treatment goal', group: 'Treatment Goal' },
  { id: EventType.ReviewTreatmentGoal, name: 'Review treatment goal', group: 'Treatment Goal' },
  {
    id: EventType.CompletedTreatmentGoal,
    name: 'Completed treatment goal',
    group: 'Treatment Goal',
  },
  { id: EventType.EditTreatmentGoal, name: 'Edit a treatment goal', group: 'Treatment Goal' },
  { id: EventType.DeleteTreatmentGoal, name: 'Delete a treatment goal', group: 'Treatment Goal' },

  // Treatment Objective
  {
    id: EventType.AddTreatmentObjective,
    name: 'Add a treatment objective',
    group: 'Treatment Objective',
  },
  {
    id: EventType.StartedTreatmentObjective,
    name: 'Started treatment objective',
    group: 'Treatment Objective',
  },
  {
    id: EventType.ReviewTreatmentObjective,
    name: 'Review treatment objective',
    group: 'Treatment Objective',
  },
  {
    id: EventType.CompletedTreatmentObjective,
    name: 'Completed treatment objective',
    group: 'Treatment Objective',
  },
  {
    id: EventType.EditTreatmentObjective,
    name: 'Edit a treatment objective',
    group: 'Treatment Objective',
  },
  {
    id: EventType.DeleteTreatmentObjective,
    name: 'Delete a treatment objective',
    group: 'Treatment Objective',
  },

  // Treatment Strategy
  {
    id: EventType.AddTreatmentStrategy,
    name: 'Add a treatment strategy',
    group: 'Treatment Strategy',
  },
  {
    id: EventType.EditTreatmentStrategy,
    name: 'Edit a treatment strategy',
    group: 'Treatment Strategy',
  },
  {
    id: EventType.DeleteTreatmentStrategy,
    name: 'Delete a treatment strategy',
    group: 'Treatment Strategy',
  },

  // Treatment Diagnosis
  {
    id: EventType.AddTreatmentDiagnosis,
    name: 'Add a treatment diagnosis',
    group: 'Treatment Diagnosis',
  },
  {
    id: EventType.EditTreatmentDiagnosis,
    name: 'Edit a treatment diagnosis',
    group: 'Treatment Diagnosis',
  },
  {
    id: EventType.DeleteTreatmentDiagnosis,
    name: 'Delete a treatment diagnosis',
    group: 'Treatment Diagnosis',
  },
  // Treatment Signature
  {
    id: EventType.SignedTreatmentSignatureByProvider,
    name: 'Signed treatment plan by provider',
    group: 'Treatment Signature',
  },
  {
    id: EventType.SignedTreatmentSignatureByPatient,
    name: 'Signed treatment plan by patient',
    group: 'Treatment Signature',
  },
  {
    id: EventType.RefusedTreatmentSignatureByPatient,
    name: 'Refused treatment signature by patient',
    group: 'Treatment Signature',
  },
  {
    id: EventType.EmailedTextedTreatmentSignatureByPatient,
    name: 'Emailed/texted to patient to sign treatment plan',
    group: 'Treatment Signature',
  },
  {
    id: EventType.SignedViaEmailTextTreatmentSignatureByPatient,
    name: 'Signed treatment plan via email/text by patient',
    group: 'Treatment Signature',
  },
  {
    id: EventType.RefusedViaEmailTextTreatmentSignatureByPatient,
    name: 'Refused treatment plan via email/text by patient',
    group: 'Treatment Signature',
  },
  {
    id: EventType.DeleteTreatmentSignature,
    name: 'Delete a treatment signature',
    group: 'Treatment Signature',
  },
];

export const getEventTypeListByKey = () => {
  const eventTypeListByKey: Record<string, string> = {};
  eventTypeList.forEach((eventType) => {
    eventTypeListByKey[eventType.id] = eventType.name;
  });
  return eventTypeListByKey;
};
