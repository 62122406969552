import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import {
  useGetClinicAccountsQuery,
  useLazyStructuredDataDownloadQuery,
} from '../../app/services/account';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import SubmitButton from '../../common/components/SubmitButton';
import DashboardLayout from '../../common/layouts/Dashboard';
import { formatDate } from '../../common/utils/dateTimeUtils';
import { InterviewDownloadTypes } from '../../common/utils/exportUtils';
import { DefaultLanguage, LanguageType } from '../../common/utils/languages';
import { Role } from '../../common/utils/loginRole';
import { getPageTitle } from '../../common/utils/pageUtils';
import { saveReportDataToFile } from '../../common/utils/reportUtils';
import { AuthUserType } from '../../types/AuthTypes';
import { ClinicAccountItems } from '../../types/ClinicAccountType';
import { ExportStructuredType } from '../../types/ExportTypes';
import { PageProps } from '../../types/PageProps';
import { selectUser } from '../auth/authSlice';
import useFilterInterviewTypeByLanguage from './hooks/useFilterInterviewTypeByLanguage';

const defaultValues: ExportStructuredType = {
  clinicAccountId: '',
  startDate: new Date().toString(),
  endDate: new Date().toString(),
  language: 'noLanguage',
  interviewType: 0,
  downloadType: Object.keys(InterviewDownloadTypes)[0],
  clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const getAccountOptionLabel = (option: ClinicAccountItems) => `${option.name} (${option.id})`;

const filterAccountOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: ClinicAccountItems) => getAccountOptionLabel(option),
});

export const StructuredDataExportFileName = {
  incompleteInterview: 'StructuredDataExport-IncompleteAnswers.csv',
  completedInterview: 'StructuredDataExport-CompletedAnswers.csv',
  scoredInterview: 'StructuredDataExport-CompletedScores.csv',
};

export default function StructuredDataExportPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [clinicAccounts, setClinicAccounts] = useState<ClinicAccountItems[]>([]);
  const currentUser = useAppSelector<AuthUserType>(selectUser);
  const disabledClinicAccount = currentUser.roleId === Role.ClinicAdmin;

  const { data: accountData, isLoading: accountLoading } = useGetClinicAccountsQuery();
  const [triggerDownloadFile, { data: structuredData, error: exportError }] =
    useLazyStructuredDataDownloadQuery();

  const errorMessages = exportError
    ? JSON.parse(((exportError as FetchBaseQueryError)?.data as any)?.csvDataStream)?.error || [
        t('error.badRequest'),
      ]
    : [];

  const {
    isLoading: interviewTypeLoading,
    interviewTypesByLanguage,
    languages,
    setCurrentLanguage,
    setClinicAccountId,
    defaultValue,
  } = useFilterInterviewTypeByLanguage({
    initialAccountId: currentUser.clinicAccountId,
    initialLanguage: DefaultLanguage.English,
  });

  const validationSchema = yup
    .object({
      clinicAccountId: yup.string().required(t('user.manageUsers.error.blankAccount')),
      startDate: yup.string().nullable().required(t('exportData.error.blankStartDate')),
      endDate: yup.string().nullable().required(t('exportData.error.blankEndDate')),
      language: yup
        .string()
        .required(t('exportData.error.blankLanguage'))
        .test('export-language', t('exportData.error.blankLanguage'), function (language) {
          if (language === 'noLanguage') return false;
          return true;
        }),
      interviewType: yup
        .number()
        .required(t('exportData.error.blankInterviewFormat'))
        .test(
          'export-interview-format',
          t('exportData.error.blankInterviewFormat'),
          function (interviewFormat) {
            if (interviewFormat === 0) return false;
            return true;
          }
        ),
      downloadType: yup.string().required(t('exportData.error.blankDownloadType')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<ExportStructuredType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleAccountChange = (e: SyntheticEvent<Element, Event>, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccountId', selectedValue);
    setClinicAccountId(selectedValue);
  };

  const handleLanguageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const language = e.target?.value;
    setValue('language', language);
    setCurrentLanguage(language);
  };

  useEffect(() => {
    if (structuredData) {
      saveReportDataToFile(
        structuredData.csvDataStream,
        { type: 'text/csv' },
        StructuredDataExportFileName[getValues('downloadType')]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structuredData]);

  useEffect(() => {
    if (accountData?.clinicAccounts) {
      setValue('clinicAccountId', currentUser.clinicAccountId);
      setClinicAccounts(accountData.clinicAccounts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData]);

  useEffect(() => {
    const { defaultInterviewType, defaultLanguage } = defaultValue;
    setValue('interviewType', defaultInterviewType);
    setValue('language', defaultLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onSubmit = (data: ExportStructuredType) => {
    data.startDate = formatDate(data.startDate);
    data.endDate = formatDate(data.endDate);
    triggerDownloadFile(data);
  };

  const isLoading = accountLoading || interviewTypeLoading;

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('exportData.structured.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: 'flex',
              flexDirection: matches ? 'column' : 'row',
              justifyContent: 'space-between',
              rowGap: 1,
              py: 2,
            }}
          >
            <PageHeader headerText={t('exportData.structured.title')} backUrl={'/dashboard'} />
            <Box
              sx={{
                display: 'flex',
                rowGap: 1,
                flexDirection: matches ? 'column' : 'row',
                columnGap: 2,
                alignItems: matches ? 'flex-start' : 'flex-end',
                '&> .MuiLink-root': {
                  textDecoration: 'none',
                  fontSize: 16,
                },
              }}
            >
              <Link color="primary" href="/dashboard/structured-export/structured-data-codebook">
                {t('exportData.structured.codebook.linkName')}
              </Link>
              <Link
                color="primary"
                href="/dashboard/structured-export/structured-scored-data-codebook"
              >
                {t('exportData.structured.scored.codebook.linkName')}
              </Link>
            </Box>
          </Box>
        </Container>
        {isLoading ? (
          <Loading />
        ) : (
          <RoundedContainer
            sx={{
              py: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <Controller
                  name="clinicAccountId"
                  control={control}
                  render={({ fieldState, formState, field: { value }, ...props }) => (
                    <Autocomplete
                      {...props}
                      disabled={disabledClinicAccount}
                      fullWidth
                      data-testid="select-clinic-account-structured-data"
                      options={clinicAccounts || []}
                      getOptionLabel={getAccountOptionLabel}
                      filterOptions={filterAccountOptions}
                      value={
                        value ? clinicAccounts?.find((item) => item.id === value) || null : null
                      }
                      onChange={handleAccountChange}
                      renderOption={(props, option: any) => {
                        return (
                          <li {...props} key={option.id} value={option.id}>
                            {getAccountOptionLabel(option)}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.clinicAccountId}
                          helperText={errors.clinicAccountId?.message}
                          margin="normal"
                          fullWidth
                          label={t('exportData.structured.form.clinicAccount')}
                        />
                      )}
                    />
                  )}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '49%',
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                    }}
                  >
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          label={t('exportData.raw.form.startDate')}
                          inputFormat="MM/DD/YYYY"
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              data-testid="mui-component-start-date"
                              sx={{
                                marginTop: '0.5rem',
                                width: '100%',
                              }}
                              error={!!errors.startDate}
                              helperText={errors.startDate?.message}
                              margin="normal"
                              variant="outlined"
                              aria-describedby="start-date-helper-text"
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '49%',
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                    }}
                  >
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          label={t('exportData.raw.form.endDate')}
                          inputFormat="MM/DD/YYYY"
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              data-testid="mui-component-from-date"
                              sx={{
                                marginTop: '0.5rem',
                                width: '100%',
                              }}
                              error={!!errors.endDate}
                              helperText={errors.endDate?.message}
                              margin="normal"
                              variant="outlined"
                              aria-describedby="end-date-helper-text"
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                </LocalizationProvider>

                <Controller
                  name="language"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      select
                      fullWidth
                      label={t('exportData.structured.form.language')}
                      error={!!errors.language}
                      helperText={errors.language?.message}
                      variant="outlined"
                      onChange={handleLanguageChange}
                    >
                      {languages?.length > 0 ? (
                        languages.map((language: LanguageType) => (
                          <MenuItem key={language.code} value={language.code}>
                            {language.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={'default-key-1'} value="noLanguage">
                          {'No language available'}
                        </MenuItem>
                      )}
                    </TextField>
                  )}
                />

                <Controller
                  name="interviewType"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      select
                      fullWidth
                      label={t('exportData.structured.form.interviewFormat')}
                      error={!!errors.interviewType}
                      helperText={errors.interviewType?.message}
                      variant="outlined"
                    >
                      {interviewTypesByLanguage?.length > 0 ? (
                        interviewTypesByLanguage.map((interviewType: any) => (
                          <MenuItem key={interviewType.id} value={interviewType.id}>
                            {interviewType.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={'default-key-2'} value={0}>
                          {'No interview format available'}
                        </MenuItem>
                      )}
                    </TextField>
                  )}
                />

                <Controller
                  name="downloadType"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      select
                      fullWidth
                      label={t('exportData.structured.form.downloadType')}
                      error={!!errors.downloadType}
                      helperText={errors.downloadType?.message}
                      variant="outlined"
                    >
                      {Object.keys(InterviewDownloadTypes).map((type: string) => (
                        <MenuItem key={type} value={type}>
                          {InterviewDownloadTypes[type]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
              <SubmitButton
                content={t('exportData.raw.submit')}
                matches={matches}
                pageErrors={errorMessages}
              />
            </Box>
          </RoundedContainer>
        )}
      </Container>
    </DashboardLayout>
  );
}
