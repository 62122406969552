import FormHelperText from '@mui/material/FormHelperText';
import { alpha, styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import theme from '../../app/theme';
import { AssessmentStatus } from './assessmentStatus';

export const roundedSquareButtonStyle: SxProps<Theme> = {
  ml: 2,
  bgcolor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: '5px',
};

// TODO: check why it doesn't work in Dashboard page
// pageHeight = 100% - [appBar's height]
export const pageHeightStyle: (theme: Theme) => any = (theme) => ({
  minHeight: 'calc(100vh - 56px)',

  [theme.breakpoints.up('sm')]: {
    minHeight: 'calc(100vh - 128px)',

    '.login-layout &': {
      minHeight: 'calc(100vh - 64px)',
    },
  },
});

export const roundedBoxStyle: (theme: Theme) => any = (theme) => ({
  backgroundColor: theme.xPalette.white,
  borderRadius: 4,
});

export const landingTextStyle = () => ({
  '& hr': {
    border: 'none',
    borderBottom: 'solid 0.5px #CCCCCC',
    my: 2,
  },
  '& li': {
    display: 'list-item',
    listStyle: 'disc',
  },
});

export const linkWrapperStyle: (theme: Theme) => any = (theme) => ({
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  },
});

export const commonStyledButton: (theme: Theme) => any = (theme) => ({
  padding: '16px 0',
  marginBottom: '8px',
  borderRadius: '8px',
  width: '100%',

  minWidth: 200,
  '&.MuiButton-text': {
    backgroundColor: theme.xPalette.white,
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up('md')]: {
    width: '20%',
    marginBottom: 0,
    marginLeft: '8px',
  },
});

export const getStatusStyle = (status: string) => {
  const { notStarted, inProgress, completed } = theme.xPalette;
  if (status === AssessmentStatus.NotStarted) {
    return {
      backgroundColor: notStarted.bg,
      color: notStarted.color,
    };
  }
  if (
    status === AssessmentStatus.InProgress ||
    status === AssessmentStatus.PendingScoring ||
    status === AssessmentStatus.UnRead
  ) {
    return {
      backgroundColor: inProgress.bg,
      color: inProgress.color,
    };
  }
  if (status === AssessmentStatus.Completed || status === AssessmentStatus.SeenOn) {
    return {
      backgroundColor: completed.bg,
      color: completed.color,
    };
  }
};

export const statusBoxStyle: any = (isPhone: boolean, status: string) => ({
  maxWidth: 140,
  width: isPhone ? 110 : 140,
  py: 0.6,
  borderRadius: 1,
  textAlign: 'center',
  backgroundColor: getStatusStyle(status),
  color: getStatusStyle(status),
});

export const commonButtonStyle = (matches: boolean): { [key: string]: number | string } => ({
  my: 2,
  p: 2,
  borderRadius: 2,
  minWidth: matches ? '100%' : '20%',
  whiteSpace: 'nowrap',
});

export const commonWidthResponsive = (matches: boolean): string => (matches ? '100%' : '50%');

export const commonFlexDirection = (matches: boolean): 'column' | 'row' =>
  matches ? 'column' : 'row';

export const StyleErrorForm = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}));
