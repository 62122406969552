import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  useGetClinicAccountsQuery,
  useGetUserInfoQuery,
  useGetClinicSitesQuery,
  useGetUserRolesQuery,
  useAddUserMutation,
  useEditUserMutation,
} from '../../app/services/account';
import { useResetUserMfaMutation } from '../../app/services/auth';
import { useToast } from '../../app/toast';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import UserInfoCard from '../../common/components/UserInfoCard';
import DashboardLayout from '../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { UserRequest } from '../../types/UserTypes';
import ErrorPage from '../error/ErrorPage';
import UserForm from './UserForm';

export default function AddOrEditUserPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const params = useParams();
  const userId: string | undefined = params.userId;
  const navigate = useNavigate();
  const toast = useToast();

  const [addUser] = useAddUserMutation();
  const [editUser] = useEditUserMutation();
  const [resetUserMfa] = useResetUserMfaMutation();

  const {
    data: userInfoData,
    error,
    isLoading: loadUserData,
    refetch: refetchEditUser,
  } = useGetUserInfoQuery(!!userId ? { userId: parseInt(userId) } : skipToken);

  const { data: accountData, isLoading: loadAccountsData } = useGetClinicAccountsQuery(undefined, {
    skip: !!userId && !userInfoData,
  });
  const { data: siteData, isLoading: loadSitesData } = useGetClinicSitesQuery(undefined, {
    skip: !!userId && !userInfoData,
  });
  const { data: roleData, isLoading: loadRolesData } = useGetUserRolesQuery(undefined, {
    skip: !!userId && !userInfoData,
  });

  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const newBreadcrumb: BreadcrumbType = {
    text: t('user.manageUsers.title'),
    link: navBackUrl || `/dashboard/users`,
  };

  const onResetMfa = async () => {
    await resetUserMfa({ userId: parseInt(userId!) });
    toast.publish(t('user.manageUsers.resetMfaSuccess'), 'success', 3000);
  };

  const onFormSubmit = async (data: UserRequest, userId?: string, successCallback?: () => void) => {
    if (userId) {
      await editUser({ ...data, editUserId: parseInt(userId) }).unwrap();
      refetchEditUser();
    } else {
      await addUser(data).unwrap();
      navigate('/dashboard/users');
    }

    if (successCallback) {
      successCallback();
    }

    toast.publish(
      (userId && t('user.manageUsers.updateSuccess')) || t('user.manageUsers.addSuccess'),
      'success',
      5000
    );
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>
          {userId
            ? getPageTitle(t('user.manageUsers.editUser'))
            : getPageTitle(t('user.manageUsers.addUser'))}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={userId ? t('user.manageUsers.editUser') : t('user.manageUsers.addUser')}
          backUrl={navBackUrl || `/dashboard/users`}
        />
      </Container>

      {!!userId && (
        <RoundedContainer sx={{ py: 2, my: 2 }}>
          <UserInfoCard editUserData={userInfoData} />
        </RoundedContainer>
      )}

      {loadUserData || loadAccountsData || loadSitesData || loadRolesData ? (
        <Box sx={{ my: 2 }}>
          <Loading />
        </Box>
      ) : (
        <UserForm
          clinicAccounts={accountData?.clinicAccounts}
          clinicSites={siteData?.clinicSites}
          userRoles={roleData?.userRoles}
          data={userInfoData}
          userId={userId}
          onFormSubmit={onFormSubmit}
          onResetMfa={onResetMfa}
        />
      )}
    </DashboardLayout>
  );
}
