const urlRegex = new RegExp(
  '((ftp|http|https):\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
    '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);

export const RegexValidation = {
  NumberOrEmptyString: /^(\s*|\d+)$/,
  NumberOnly: /^\d+$/,
  PhoneNumber: /^\+?[0-9]\d{1,16}$/g,
  PhoneNumberOrWhiteSpace: /^(\s*|\+?[0-9]\d{1,16})$/,
  MinLengthPhoneNumber: /^(|.{4,})$/,
  OnlyDigits: /\d/g,
  Email:
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
  URL: urlRegex,
};

export const PhoneNumberRequired = {
  MinLengthPhoneNumber: 4,
};

export const validatePhoneNumber = (phone: any): boolean => {
  if (phone) {
    const digits = phone.match(RegexValidation.OnlyDigits);
    return !!digits && digits.length >= PhoneNumberRequired.MinLengthPhoneNumber;
  }
  return true;
};

export const validateEmail = (email: string): boolean => {
  return RegexValidation.Email.test(email.toLowerCase());
};

export const maximumImportFileUpload = 10; // MB
export const maximumImportPatientFileUpload = 2; // MB
