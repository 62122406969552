import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { convertCopyRight } from '../../../common/utils/reportUtils';
import { AssessmentReport } from '../../../types/ReportType';
import ReportCoverPage from '../reportModules/ReportCoverPage';
import ReportModulePage from '../reportModules/ReportModulePage';
import ReportNextSteps from '../reportModules/ReportNextSteps';
import ReportPageBreak from '../reportModules/ReportPageBreak';

export default function SdsStsMonitorReportPage({
  logoUrl,
  moduleQuestions,
  assessmentResult: monitorResult,
  patient,
  interviewer,
  assessment: monitor,
  nextSteps,
}: AssessmentReport) {
  const { t } = useTranslation();

  return (
    <>
      <ReportCoverPage
        logo={logoUrl}
        result={monitorResult}
        patient={patient}
        interviewer={interviewer}
        interview={monitor}
        isInterview={false}
      />

      {nextSteps && nextSteps.length > 0 && <ReportNextSteps nextSteps={nextSteps} />}

      <ReportPageBreak />
      <RoundedContainer sx={{ my: 2, py: 2 }}>
        <Typography variant="h1" fontWeight={'bold'} textAlign={'center'}>
          {monitor?.title}
        </Typography>
        {moduleQuestions?.map((module: any) => (
          <ReportModulePage
            key={module?.id}
            moduleTitle={module?.title}
            questionAnswers={module?.questionAnswers}
            moduleType={monitor?.reportType}
            assessmentType={monitorResult?.assessmentType}
            isHideModuleName={module?.isHideModuleName}
            subTitle={monitor?.subTitle}
          />
        ))}
      </RoundedContainer>

      <Typography
        my={2}
        variant="body1"
        textAlign="left"
        dangerouslySetInnerHTML={{
          __html: convertCopyRight(t('assessmentReport.titlePage.copyright')),
        }}
      ></Typography>
    </>
  );
}
