import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { useGetInterviewReportHistoryQuery } from '../../app/services/interview';
import { useGetPatientQuery } from '../../app/services/patient';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import DataTable from '../../common/components/DataTable';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { PageStatus, setPageStatus } from '../../common/slices/globalSlice';
import { AssessmentType } from '../../common/utils/assessmentType';
import { statusBoxStyle } from '../../common/utils/commonStyles';
import { DefaultPageSize } from '../../common/utils/commonUtils';
import { formatServerDate } from '../../common/utils/dateTimeUtils';
import { BreadcrumbType, addBreadcrumb } from '../../common/utils/editBreadcrums';
import { PageSource } from '../../common/utils/pageInfo';
import { getPageTitle } from '../../common/utils/pageUtils';
import { DownloadReportRequest, InterviewReportHistoryItem } from '../../types/InterviewReportRequest';
import { PageProps } from '../../types/PageProps';
import ErrorPage from '../error/ErrorPage';
import useDownloadInterviewOrMonitorReport from '../patient/hooks/useDownloadInterviewOrMonitorReport';

const standardizeStatus = (status: string) =>
  status ? status[0].toUpperCase() + status.substring(1) : status;

export default function ReportHistoryPage({ breadcrumbs }: PageProps) {
  const { patientId, interviewResultId: inputInterviewResultId } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const shouldRenderDesktopData = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useAppDispatch();
  const [{ page, pageSize }, setPagingInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: DefaultPageSize,
  });
  const tableRef = useRef<HTMLDivElement>(null);
  const { startDownloadReport } = useDownloadInterviewOrMonitorReport(t);

  const interviewResultId = parseInt(inputInterviewResultId || '0');
  const fromPatientList = searchParams.get('src') === PageSource.PatientList;
  const newBreadcrumb: BreadcrumbType = fromPatientList
    ? {
        text: t('patient.patientList.title'),
        link: `/dashboard/patients`,
      }
    : {
        text: t('patient.patientDashboard.title'),
        link: `/dashboard/patient/${patientId}`,
      };

  // Data binding
  const {
    data: reportHistory,
    error: historyLoadError,
    isLoading: historyLoading,
    refetch: refetchHistory,
  } = useGetInterviewReportHistoryQuery({
    interviewResultId,
    page,
    pageSize,
  });
  const { data: patientInfo, error: patientLoadError } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );
  const totalPages = Math.ceil((reportHistory?.totalItems || 0) / pageSize);

  const handleDownloadReport = async (interviewResultId: number, pdfName: string, version?: number) => {
    dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
    const data = {
      interviewResultId,
      type: AssessmentType.Interview,
      pdfName,
      version,
    } as DownloadReportRequest;
    const res = await startDownloadReport(data);
    if (res) {
      refetchHistory();
    }
    dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
  };

  const handlePageChange = (_event: any, value: any) => {
    const newPage = parseInt(value);
    setPagingInfo({ page: newPage, pageSize });
  };

  const renderStatusField = (status: string, isDesktop = false) => {
    return (
      <Box
        sx={{
          ...statusBoxStyle(matches, status),
          height: '32px',
          ...(isDesktop ? { margin: 'auto' } : {}),
        }}
      >
        {standardizeStatus(status)}
      </Box>
    );
  };

  const renderPhoneData = (data: InterviewReportHistoryItem[]) => {
    return (
      <DataList
        data={data.map((item) => ({
          leftHeader: (
            <Link
              component="button"
              onClick={() => handleDownloadReport(interviewResultId, item.pdfName, item.version)}
            >
              {t('interviewReport.history.viewReport')}
            </Link>
          ),
          rightHeader: renderStatusField(item.status),
          items: [
            [t('interviewReport.history.dateStarted'), formatServerDate(item.startedAt)],
            [t('interviewReport.history.dateCompleted'), formatServerDate(item.completedAt)],
            [t('interviewReport.history.actionType'), item.actionType],
            [t('interviewReport.history.version'), item.version?.toString()],
          ],
        }))}
      />
    );
  };

  const renderDesktopData = (data: InterviewReportHistoryItem[]) => {
    return (
      <DataTable
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '8px 16px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '14px 16px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
        }}
        headers={[
          { text: t('interviewReport.history.dateStarted') },
          { text: t('interviewReport.history.dateCompleted') },
          { text: t('interviewReport.history.actionType') },
          { text: t('interviewReport.history.version') },
          { text: t('interviewReport.history.status'), sx: { textAlign: 'center' } },
          { text: t('interviewReport.history.action') },
        ]}
        items={data.map((item) => {
          return [
            { component: <Typography>{formatServerDate(item.startedAt)}</Typography> },
            { component: <Typography>{formatServerDate(item.completedAt)}</Typography> },
            { component: <Typography>{item.actionType}</Typography> },
            { component: <Typography>{item.version?.toString()}</Typography> },
            { component: <>{renderStatusField(item.status, true)}</> },
            {
              component: (
                <Link
                  component="button"
                  onClick={() => handleDownloadReport(interviewResultId, item.pdfName, item.version)}
                  title={t('interviewReport.history.viewReport')}
                  sx={{ ml: 2, display: 'flex' }}
                  color={theme.palette.text.primary}
                >
                  <EyeIcon />
                </Link>
              ),
            },
          ];
        })}
      />
    );
  };

  if (patientLoadError) {
    return <ErrorPage statusCode={(patientLoadError as FetchBaseQueryError).status} />;
  }

  if (historyLoadError) {
    return <ErrorPage statusCode={(historyLoadError as FetchBaseQueryError).status} />;
  }

  // TODO: udpate style
  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>{getPageTitle(t('interviewReport.history.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            px: matches ? 2 : 3,
          }}
        >
          <PageHeader
            headerText={t('interviewReport.history.title')}
            backUrl={newBreadcrumb.link}
          />
        </Box>

        <RoundedContainer sx={{ py: 2 }}>
          <PatientInfoCard data={patientInfo!} />
        </RoundedContainer>

        {historyLoading ? (
          <Loading />
        ) : (
          <>
            <RoundedContainer sx={{ mt: 2, py: 2 }} ref={tableRef}>
              <Typography fontWeight={600}>{t('interviewReport.history.interview')}</Typography>
              {reportHistory?.interviewFormat && (
                <Typography>{reportHistory.interviewFormat}</Typography>
              )}
            </RoundedContainer>

            {reportHistory?.data && (
              <>
                {shouldRenderDesktopData
                  ? renderDesktopData(reportHistory.data)
                  : renderPhoneData(reportHistory.data)}
              </>
            )}

            {totalPages > 1 && (
              <RoundedContainer sx={{ mt: 2, py: 1, display: 'flex', justifyContent: 'center' }}>
                <CustomPagination
                  changePage={handlePageChange}
                  currentPage={page}
                  pages={totalPages}
                  pageRef={tableRef}
                />
              </RoundedContainer>
            )}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
}
