import { QueryCacheDuration, buildAccountUrl, rootApi } from '..';
import {
  TreatmentPlanAuditLogRequest,
  TreatmentPlanAuditLogResponse,
} from '../../../types/AuditLog';
import {
  GetTreatmentPlanReportRequest,
  GetTreatmentPlanReportResponse,
  TreatmentPlanReportRequest,
} from '../../../types/treatment-plan/TreatmentPlanReportType';

const api = rootApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getTreatmentPlanReportByToken: builder.query<
      GetTreatmentPlanReportResponse,
      GetTreatmentPlanReportRequest
    >({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-plan-report`),
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    downloadTreatmentPlanReport: builder.query<any, TreatmentPlanReportRequest>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-plan-report/download`),
        method: 'GET',
        params,
        responseHandler: async (response) => {
          return window.URL.createObjectURL(await response.blob());
        },
      }),
    }),

    sendReportToEHR: builder.mutation<any, TreatmentPlanReportRequest>({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-plan-report/send-report-to-ehr`),
        method: 'POST',
        body,
      }),
    }),

    getTreatmentPlanAuditLog: builder.query<
      TreatmentPlanAuditLogResponse,
      TreatmentPlanAuditLogRequest
    >({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-plan-report/audit-logs`),
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTreatmentPlanReportByTokenQuery,
  useDownloadTreatmentPlanReportQuery,
  useSendReportToEHRMutation,
  useGetTreatmentPlanAuditLogQuery,
} = api;
