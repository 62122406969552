//TODO: remove InterviewMode and MonitorMode
export const AssessmentMode = {
  Patient: 'patient',
  Clinician: 'clinician',
  Email: 'email',
  EmailPatient: 'emailPatient',
  Schedule: 'schedule',
};

export enum AnswerTypeConditions {
  TEXT_MAX_LENGTH = 10,
}
