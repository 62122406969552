import { ClientDisplayConditionType } from '../../types/MonitorTypes';

export const checkCompareCondition = (
  clientCondition: ClientDisplayConditionType,
  currentValue: any
) => {
  const { questionId, questionAnswerValue, questionAnswerComparisonType } = clientCondition;
  let conditionMet = false;

  if (!clientCondition && !currentValue) {
    return true;
  }

  switch (questionAnswerComparisonType) {
    case 'greaterthan': {
      conditionMet = (currentValue ? currentValue[questionId!] : '0') > questionAnswerValue!;
      break;
    }

    case 'greaterThanOrEqual': {
      conditionMet = (currentValue ? currentValue[questionId!] : 'N/A') >= questionAnswerValue!;
      break;
    }

    case 'lessThan': {
      conditionMet = (currentValue ? currentValue[questionId!] : 'N/A') < questionAnswerValue!;
      break;
    }

    case 'lessThanOrEqual': {
      conditionMet = (currentValue ? currentValue[questionId!] : 'N/A') <= questionAnswerValue!;
      break;
    }

    case 'equal': {
      conditionMet = (currentValue ? currentValue[questionId!] : 'N/A') === questionAnswerValue!;
      break;
    }

    case 'notEqual': {
      conditionMet = (currentValue ? currentValue[questionId!] : 'N/A') !== questionAnswerValue!;
      break;
    }
  }

  return conditionMet;
};
