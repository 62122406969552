export const Genders: { name: string; value: string }[] = [
  {
    name: 'Male',
    value: 'male',
  },
  { name: 'Female', value: 'female' },
  {
    name: 'Transgender',
    value: 'transgender',
  },
  {
    name: 'Other',
    value: 'Other',
  },
];

export const Races: string[] = [
  'Alaskan Native',
  'American Indian',
  'Black or African American',
  'Native Hawaiian',
  'Pacific Islander',
  'White',
  'Other',
];
