import Box from '@mui/material/Box';
import { useState } from 'react';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import ShowMoreIcon from '../../common/components/icons/ShowMoreIcon';
import ShowLessIcon from '../../common/components/icons/ShowLessIcon';

interface FieldNoteProps {
  note: string
}
const StyledDiv = styled('div')(() => ({
}));

export default function FieldNote({note}: FieldNoteProps) {
  const { t } = useTranslation();
  const [isReadMore, setIsReadMore] = useState(false);
  const fullText = note;
  const readMoreText = t("patientNote.readMore");
  const readLessText = t("patientNote.showLess");
  const theme = useTheme();
  const shouldRenderDesktopData = useMediaQuery(theme.breakpoints.up('md'));
  const shortText = shouldRenderDesktopData && note && note.length > 200 ?
    note.slice(0,200) + '...': (!shouldRenderDesktopData && note && note.length > 50) ?
    note.slice(0,50) + '...' : note;

  const ReadMore = () => (((note.length <= 50) || !readMoreText) ? null :
    <StyledDiv>
      <Link
        sx={{
          fontSize: '14px',
          lineHeight: '30px',
          borderBottom: '1px',
          display: 'flex'
        }}
        component='button'
        onClick={() => {setIsReadMore(true);}}
      >
        <StyledDiv>
          {readMoreText}
        </StyledDiv>
        <StyledDiv sx={{paddingTop: '2px'}}>
          <ShowMoreIcon />
        </StyledDiv>
      </Link>
    </StyledDiv>
  );

  const ReadLess = () => (((note.length <= 50) || !readLessText) ? null :
  <StyledDiv>
    <Link
      sx={{
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center'
      }}
      component='button'
      onClick={() => {setIsReadMore(false);}}
    >
    <StyledDiv>
      {readLessText}
    </StyledDiv>
        <StyledDiv sx={{paddingTop: '13px'}}>
          <ShowLessIcon />
        </StyledDiv>
    </Link>
  </StyledDiv>
  );


  const readMoreFinal = () => {
    return(
      <Container maxWidth="xl" disableGutters={true}
        key={'readMoreFinal'}
      >
        <Box
          sx={{
            flexBasis: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {isReadMore ? fullText : shortText}
        </Box>
        <Box
          sx={{
            flexBasis: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {isReadMore ? <ReadLess /> : <ReadMore />}
        </Box>
      </Container>
    )
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '4px 16px',
        mx: 2,
        height: 'auto',
        borderRadius: '5px',
        minHeight: '40px',
        [theme.breakpoints.up('sm')]: {
          mx: 0,
        },
      })}
    >
      <Box
        sx={{
          flexBasis: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          textAlign: 'left',
          color: '#727272'
        }}
      >
        {((shouldRenderDesktopData && note && note.length > 200) ||
        (!shouldRenderDesktopData && note && note.length > 50)) ? readMoreFinal() : note}
      </Box>
    </Box>
  );
}
