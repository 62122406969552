import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function PHQFormulaAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
  notes,
}: QuestionAnswerProps) {
  const { questionDisplay, promptDisplay, answerValue, questionTitle } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <>
      <Box
        key={questionAnswer.questionId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          my: 3,
          width: '100%',
          pageBreakInside: 'avoid',
        }}
      >
        <Box sx={{ width: '15%', display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Typography component={'span'} fontWeight={500}>
            {questionTitle}
          </Typography>
          <Typography component={'span'} fontWeight={500}>
            {questionAnswer.subTitle}
          </Typography>
        </Box>
        <Box sx={{ width: '75%', display: 'flex', alignItems: 'center' }}>
          {promptDisplay && (
            <Typography
              fontWeight="bold"
              dangerouslySetInnerHTML={{
                __html: promptDisplay,
              }}
            ></Typography>
          )}
          <Typography
            fontWeight={500}
            dangerouslySetInnerHTML={{
              __html: questionDisplay,
            }}
          ></Typography>
        </Box>
        <Box
          sx={{ width: '15%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <Typography sx={{ textAlign: 'right' }} component={'span'} fontWeight={'bold'}>
            {answerValue || ''}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
