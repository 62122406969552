import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import { InterviewReportType } from '../../../interview/interviewUtils';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function InfoNoAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const isPHQ =
    moduleType === InterviewReportType.Screener && subModuleType === InterviewReportType.PHQ;
  const isMonitor = moduleType === InterviewReportType.Monitor;
  const { questionDisplay, promptDisplay } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <Box
      key={questionAnswer.questionId}
      sx={{
        display: 'flex',
        justifyContent: isMonitor ? 'flex-start' : 'center',
        alignItems: isPHQ ? 'flex-start' : 'center',
        flexDirection: isPHQ ? 'column' : 'row',
        columnGap: 1,
        my: 3,
        width: '100%',
        pageBreakInside: 'avoid',
      }}
    >
      {isPHQ && (
        <Typography
          fontWeight={'bold'}
          dangerouslySetInnerHTML={{
            __html: promptDisplay,
          }}
        ></Typography>
      )}
      <Typography
        sx={{
          '& h2': {
            textAlign: 'center',
          },
        }}
        width={'100%'}
        fontWeight={500}
        dangerouslySetInnerHTML={{
          __html: questionDisplay.replace(/(?:\r\n|\r|\n|\\r\\n)/g, ' ') || '',
        }}
      ></Typography>
    </Box>
  );
}
