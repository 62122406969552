import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../../app/hooks';
import { useDeleteObjectiveMutation } from '../../../app/services/treatment-plan/objective';
import { useToast } from '../../../app/toast';
import DataList from '../../../common/components/DataList';
import LocalPopupModal from '../../../common/components/LocalPopupModal';
import RoundedContainer from '../../../common/components/RoundedContainer';
import AddIcon from '../../../common/components/icons/AddIcon';
import DeactivatePatientIcon from '../../../common/components/icons/DeactivatePatientIcon';
import EditUserIcon from '../../../common/components/icons/EditInterviewIcon';
import { sortItemsOnList } from '../../../common/utils/commonUtils';
import { formatDate } from '../../../common/utils/dateTimeUtils';
import { SortDirs } from '../../../common/utils/sortInfo';
import { goalStatusListWithKey, truncate } from '../../../common/utils/treatmentPlans';
import { PopupContent } from '../../../types/PopupType';
import { GoalItem } from '../../../types/treatment-plan/GoalType';
import { ObjectiveItem, ObjectiveStatus } from '../../../types/treatment-plan/ObjectiveType';
import { StrategyItem } from '../../../types/treatment-plan/StrategyType';
import ContextMenu from '../../patient/ContextMenu';
import StrategyForm from '../strategy/StrategyForm';
import StrategyList from '../strategy/StrategyList';
import { setTreatmentPlanReloadOption } from '../treatmentPlanSlice';
import ObjectiveForm from './ObjectiveForm';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  [`&.no-indent`]: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

interface ObjectiveListProps {
  objectiveRows: ObjectiveItem[];
  goalData: GoalItem;
  isNoIndent?: boolean;
  defaultRowStatus?: Record<string, boolean>;
  goalCompleted?: boolean;
}

export default function ObjectiveList({
  objectiveRows,
  goalData,
  isNoIndent,
  defaultRowStatus,
  goalCompleted,
}: ObjectiveListProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const toast = useToast();
  const dispatch = useAppDispatch();

  const defaultSortOptions = {
    sortField: 'objective',
    sortDir: SortDirs.Asc,
  };

  const [sortOptions, setSortOptions] =
    useState<{ sortField: string; sortDir: SortDirs }>(defaultSortOptions);

  const [displayedItems, setDisplayedItems] = useState<ObjectiveItem[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Delete Popup
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState<boolean>(false);
  const [deletingId, setDeletingId] = useState<number>(0);

  // Edit Popup
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const [editingData, setEditingData] = useState<ObjectiveItem | null>(null);

  // Add Strategy Popup
  const [isOpenAddStrategyPopup, setIsOpenAddStrategyPopup] = useState<boolean>(false);
  const [parentObjective, setParentObjective] = useState<ObjectiveItem | null>(null);

  const [deleteObjective] = useDeleteObjectiveMutation();

  const [objectiveRowStatus, setObjectiveRowStatus] = useState({});

  const sortHeader = ['objective', 'dateDue', 'status', 'statusDate'];

  const tableHeaders: string[][] = Object.entries(
    t('treatmentPlans.objectiveList.tableHeader', {
      returnObjects: true,
    })
  );

  const StyledSortIconBox = styled(Box)(({ theme }) => ({
    display: 'inline',
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
  }));

  useEffect(() => {
    if (defaultRowStatus) {
      setObjectiveRowStatus({
        ...objectiveRowStatus,
        ...defaultRowStatus,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRowStatus]);

  useEffect(() => {
    if (objectiveRows) {
      const sortedItems = [...objectiveRows];
      sortItemsOnList(sortedItems, sortOptions);
      const formattedItems = sortedItems.map((item) => ({
        ...item,
        dateDue: item.dateDue,
        statusDate: item.statusDate,
      }));
      setDisplayedItems(formattedItems);
    }
  }, [objectiveRows, sortOptions]);

  // ***************************** Delete Popup *****************************

  const deleteContentPopup = {
    title: t('treatmentPlans.objectiveList.delete.title'),
    description: `${t('treatmentPlans.objectiveList.delete.description')}`,
    btnOk: t('treatmentPlans.objectiveList.delete.btnOk'),
    btnClose: t('treatmentPlans.objectiveList.delete.btnClose'),
    toastMessage: t('treatmentPlans.objectiveList.delete.toastMessage'),
  } as PopupContent;

  const showDeletePopup = async (objectiveId: number) => {
    setIsOpenDeletePopup(true);
    setDeletingId(objectiveId);
    handleClose();
  };

  const closeDeletePopup = async () => {
    setIsOpenDeletePopup(false);
  };

  const handleDeleteObjective = async (successCallback?: () => void) => {
    try {
      await deleteObjective({ id: deletingId }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('treatmentPlans.objectiveList.delete.success'), 'success');
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
    } catch (e) {
      toast.publish(t('treatmentPlans.objectiveList.delete.fail'), 'error');
    }
    closeDeletePopup();
  };

  // ***************************** Edit Popup *****************************

  const editContentPopup = {
    title: t('treatmentPlans.objectiveForm.edit'),
  } as PopupContent;

  const showEditPopup = async (objectiveData: ObjectiveItem) => {
    setIsOpenEditPopup(true);
    setEditingData(objectiveData);
    handleClose();
  };

  const closeEditPopup = async () => {
    setIsOpenEditPopup(false);
  };

  const onFormSubmitCallback = async (objectiveData: ObjectiveItem, result: boolean) => {
    if (result) {
      closeEditPopup();
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
      setObjectiveRowStatus({
        ...objectiveRowStatus,
        [objectiveData.id]: true,
      });
    }
  };

  // ***************************** Add Strategy Popup *****************************

  const addStrategyContentPopup = {
    title: t('treatmentPlans.strategyForm.add'),
  } as PopupContent;

  const showAddStrategyPopup = async (objectiveData: ObjectiveItem) => {
    setIsOpenAddStrategyPopup(true);
    setParentObjective(objectiveData);
    handleClose();
  };

  const closeAddStrategyPopup = async () => {
    setIsOpenAddStrategyPopup(false);
  };

  const onAddStrategyFormSubmitCallback = async (strategyData: StrategyItem, result: boolean) => {
    if (result) {
      closeAddStrategyPopup();
      dispatch(setTreatmentPlanReloadOption({ reload: true }));

      setObjectiveRowStatus({
        ...objectiveRowStatus,
        [strategyData.treatmentObjectiveId]: true,
      });
    }
  };

  // ********************************************************************

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHeaderClicked = (header?: string) => {
    if (header && displayedItems?.length) {
      const newSortOptions = {
        sortField: header,
        sortDir: sortOptions.sortDir === SortDirs.Asc ? SortDirs.Desc : SortDirs.Asc,
      };

      setSortOptions(newSortOptions);
    }
  };

  const renderContextMenu = (item: ObjectiveItem) => {
    const menuItems = [
      {
        label: t('treatmentPlans.objectiveList.menuContext.addStrategy'),
        icon: <AddIcon />,
        onClick: () => showAddStrategyPopup(item),
      },
      {
        label: t('treatmentPlans.objectiveList.menuContext.edit'),
        icon: <EditUserIcon />,
        onClick: () => showEditPopup(item),
      },
      {
        isDisplayed: !goalCompleted,
        label: t('treatmentPlans.objectiveList.menuContext.delete'),
        icon: <DeactivatePatientIcon />,
        onClick: () => showDeletePopup(item.id),
      },
    ];

    return (
      <ContextMenu
        id={`objective-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    );
  };

  const columnWidths = isNoIndent
    ? {
        icon: '66px',
        name: '34%',
        status: '17%',
        statusDate: '17%',
        dueDate: '17%',
        action: '10%',
      }
    : {
        space: '5%',
        icon: '66px',
        name: '39%',
        status: '14%',
        statusDate: '15%',
        dueDate: '14%',
        action: '8%',
      };

  const renderObjectiveRow = (rowData: ObjectiveItem) => {
    return (
      <Fragment key={rowData.id}>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          {columnWidths.space && <StyledTableCell sx={{ width: columnWidths.space }} />}
          <StyledTableCell
            sx={{
              width: columnWidths.icon,
              minWidth: columnWidths.icon,
              maxWidth: columnWidths.icon,
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() =>
                setObjectiveRowStatus({
                  ...objectiveRowStatus,
                  [rowData.id]: !objectiveRowStatus[rowData.id],
                })
              }
            >
              {objectiveRowStatus[rowData.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell sx={{ width: columnWidths.name }}>
            <Link
              sx={{ cursor: 'pointer' }}
              component="div"
              variant="body2"
              onClick={() => {
                showEditPopup(rowData);
              }}
            >
              {truncate(rowData.objective, 180)}
            </Link>
          </StyledTableCell>
          <StyledTableCell sx={{ width: columnWidths.dueDate }}>
            {formatDate(rowData.dateDue)}
          </StyledTableCell>
          <StyledTableCell sx={{ width: columnWidths.status }}>
            {goalStatusListWithKey[rowData.status]}
          </StyledTableCell>
          <StyledTableCell sx={{ width: columnWidths.statusDate }}>
            {formatDate(rowData.statusDate)}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            sx={{
              width: columnWidths.action,
              '& .MuiButtonBase-root': {
                marginLeft: '10px',
              },
            }}
          >
            {renderContextMenu(rowData)}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={!!objectiveRowStatus[rowData.id]} timeout="auto" unmountOnExit>
              <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                <StrategyList
                  objectiveCompleted={rowData.status === ObjectiveStatus.Completed}
                  strategyRows={rowData.strategies || []}
                  objectiveData={rowData}
                  isNoIndent={isNoIndent}
                />
              </Box>
            </Collapse>
          </StyledTableCell>
        </TableRow>
      </Fragment>
    );
  };

  const renderTable = () => {
    return (
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {columnWidths.space && <StyledTableCell />}
            <StyledTableCell className={isNoIndent ? 'no-indent' : 'indent'} />
            {tableHeaders.map(([headerKey, headerLabel], index) => {
              const isSortable = sortHeader.includes(headerKey);
              const hasSort = sortOptions.sortField === headerKey;
              const sortArrowIcon =
                sortOptions.sortDir === SortDirs.Asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
              const sortIcon = hasSort ? (
                <StyledSortIconBox>{sortArrowIcon}</StyledSortIconBox>
              ) : (
                <></>
              );
              return (
                <StyledTableCell
                  sx={isSortable ? { cursor: 'pointer' } : {}}
                  className={isNoIndent ? 'no-indent' : 'indent'}
                  key={`${headerKey} ${index}`}
                  onClick={isSortable ? () => onHeaderClicked(headerKey) : undefined}
                >
                  {headerLabel} {sortIcon}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{displayedItems.map((rowData) => renderObjectiveRow(rowData))}</TableBody>
      </Table>
    );
  };

  const renderDesktopData = () => {
    return isNoIndent ? (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        {renderTable()}
      </TableContainer>
    ) : (
      renderTable()
    );
  };

  const renderMobileData = () => {
    return (
      <DataList
        lineHeight={'auto'}
        data={displayedItems.map((item: ObjectiveItem) => {
          const listItems = [
            [
              <>{t('treatmentPlans.objectiveList.tableHeader.dateDue')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{formatDate(item.dateDue)}</Box>,
            ],
            [
              <>{t('treatmentPlans.objectiveList.tableHeader.status')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{goalStatusListWithKey[item.status]}</Box>,
            ],
            [
              <>{t('treatmentPlans.objectiveList.tableHeader.statusDate')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{formatDate(item.statusDate)}</Box>,
            ],
          ];
          return {
            leftHeader: (
              <Box sx={{ fontSize: '20px', textAlign: 'left', fontWeight: '600' }}>
                <Link
                  sx={{ cursor: 'pointer' }}
                  component="div"
                  variant="body1"
                  onClick={() => {
                    showEditPopup(item);
                  }}
                >
                  {truncate(item.objective, 180)}
                </Link>
              </Box>
            ),
            rightHeader: renderContextMenu(item),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {displayedItems?.length === 0 ? (
          isNoIndent ? (
            <RoundedContainer
              sx={{
                mt: 2,
                p: 2,
                background: theme.xPalette.background,
                color: 'rgba(0, 0, 0, 0.7)',
              }}
            >
              <Typography>{t('treatmentPlans.objectiveList.error.notFound')}</Typography>
            </RoundedContainer>
          ) : (
            <RoundedContainer sx={{ mt: 2, p: 2 }}>
              <Typography sx={{ fontSize: '16px !important', textAlign: 'center' }}>
                {t('treatmentPlans.objectiveList.error.notFoundOnList')}
              </Typography>
            </RoundedContainer>
          )
        ) : (
          <Box sx={{ mt: 2 }}>{isDesktop ? renderDesktopData() : renderMobileData()}</Box>
        )}
      </Box>

      {/* Delete Objective Popup */}
      <LocalPopupModal
        isOpen={isOpenDeletePopup}
        contentPopup={deleteContentPopup}
        onSuccess={handleDeleteObjective}
        onClose={closeDeletePopup}
      />

      {/* Edit Objective Popup */}
      <LocalPopupModal
        isOpen={isOpenEditPopup}
        contentPopup={editContentPopup}
        onClose={closeEditPopup}
        maxWidth={'lg'}
        fullWidth={true}
      >
        {editingData && (
          <ObjectiveForm
            parentGoalId={goalData.id}
            objectiveData={editingData}
            onFormSubmitCallback={onFormSubmitCallback}
          />
        )}
      </LocalPopupModal>

      {/* Add Strategy Popup */}
      <LocalPopupModal
        isOpen={isOpenAddStrategyPopup}
        contentPopup={addStrategyContentPopup}
        onClose={closeAddStrategyPopup}
        maxWidth={'md'}
        fullWidth={true}
      >
        {parentObjective?.id && (
          <StrategyForm
            parentObjectiveId={parentObjective.id}
            onFormSubmitCallback={onAddStrategyFormSubmitCallback}
          />
        )}
      </LocalPopupModal>
    </>
  );
}
