import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, matchRoutes, useLocation } from 'react-router-dom';
import RequireAuth from '../common/components/RequireAuth';
import { saveRouteHistoryData } from '../features/patient/patientUtils';
import { PageBreadcrumb } from '../types/PageBreadcrumb';
import { PageProps, SharpenPageProps } from '../types/PageProps';
import './i18n';
import { RouteConfigItem, getRoutes, getSharpenRoutes, getSortedRoutes } from './routes';

const buildBreadcrumbs = (
  { path: currentPath, breadcrumbName, showDashboardBreadcrumb }: RouteConfigItem,
  routes: RouteConfigItem[]
) => {
  const breadcrumbs = routes
    .filter((r) => {
      if (r.path === '/dashboard' && !showDashboardBreadcrumb) {
        return false;
      }
      return currentPath.startsWith(r.path) && r.path !== currentPath;
    })
    .map((r) => {
      // TODO: replace values for dynamic routes
      // ...

      const breadcrumb: PageBreadcrumb = {
        text: r.breadcrumbName!,
        link: r.path,
      };
      return breadcrumb;
    });

  breadcrumbs.push({ text: breadcrumbName! });
  return breadcrumbs;
};

export default function App() {
  const { t } = useTranslation();
  const routes = getRoutes(t);
  const location = useLocation();
  const baseRoute = matchRoutes(routes, location);
  const sharpenRoutes = getSharpenRoutes(t);

  useEffect(() => {
    const basePath = baseRoute?.[0]?.route?.path;
    const baseParams = baseRoute?.[0]?.params;
    if (basePath && location?.pathname) {
      saveRouteHistoryData({ path: location.pathname, basePath }, { ...baseParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Routes>
      {routes.map((r) => {
        const pageProps: PageProps = {
          breadcrumbs: r.disableAutoBreadcrumb ? [] : buildBreadcrumbs(r, getSortedRoutes(t)),
          path: r.path,
        };

        const Component = r.component!;
        const element = r.redirectTo ? (
          <Navigate to={r.redirectTo} replace={true} />
        ) : r.requireAuth ? (
          <RequireAuth>
            <Component {...pageProps}></Component>
          </RequireAuth>
        ) : (
          <Component {...pageProps}></Component>
        );

        return <Route key={r.path} path={r.path} element={element} />;
      })}

      {sharpenRoutes.map((r) => {
        const pageProps: SharpenPageProps = {
          src: r.src,
          title: r.title,
        };

        const Component = r.component!;
        const element = <Component {...pageProps}></Component>;

        return <Route key={r.path} path={r.path} element={element} />;
      })}
    </Routes>
  );
}
