import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { convertCopyRight } from '../../../common/utils/reportUtils';
import { AssessmentReport, ModuleQuestions } from '../../../types/ReportType';
import ReportCoverPage from '../reportModules/ReportCoverPage';
import ReportModulePage from '../reportModules/ReportModulePage';
import ReportPageBreak from '../reportModules/ReportPageBreak';
import ReportScreenerPage from '../reportModules/ReportScreenerPage';
import ReportSummaryPage from '../reportModules/ReportSummaryPage';

export default function PHQReportPage({
  logoUrl,
  moduleQuestions,
  assessmentResult: interviewResult,
  patient,
  interviewer,
  assessment: interview,
  nextSteps,
  questionNotes,
}: AssessmentReport) {
  const { t } = useTranslation();
  return (
    <>
      <ReportCoverPage
        logo={logoUrl}
        result={interviewResult}
        patient={patient}
        interviewer={interviewer}
        interview={interview}
        isInterview
      />

      <ReportPageBreak />
      <ReportScreenerPage
        assessmentResult={interviewResult}
        assessment={interview}
        moduleQuestions={moduleQuestions}
        patient={patient}
        nextSteps={nextSteps}
        interviewer={interviewer}
      />

      <ReportPageBreak />
      <ReportSummaryPage
        assessmentResult={interviewResult}
        patient={patient}
        interview={interview}
        interviewer={interviewer}
        moduleQuestions={moduleQuestions}
      />

      <ReportPageBreak />
      <RoundedContainer sx={{ my: 2, py: 2 }}>
        <Typography variant="h2" fontWeight={600} textAlign={'center'}>
          {t('assessmentReport.reportModule.testResults')}
        </Typography>
        {moduleQuestions?.map((module: ModuleQuestions) => (
          <ReportModulePage
            key={module?.id}
            moduleTitle={module?.title}
            moduleLetter={module?.letter}
            questionAnswers={module?.questionAnswers}
            questionScores={module?.questionScores}
            moduleType={interview?.reportType}
            subModuleType={interview?.subReportType}
            questionNotes={questionNotes}
          />
        ))}
      </RoundedContainer>

      <Typography
        my={2}
        variant="body1"
        textAlign="left"
        dangerouslySetInnerHTML={{
          __html: convertCopyRight(interview?.copyright),
        }}
      ></Typography>
    </>
  );
}
