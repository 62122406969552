import { rootApi, buildMonitorUrl, TagTypes, buildInterviewUrl } from '.';
import { AssessmentType } from '../../common/utils/assessmentType';
import { AssessmentScheduleDeleteRequest, AssessmentScheduleRequest, AssessmentScheduleResponse, AssessmentScheduleUpdateRequest, AssessmentScheduleUpdateResponse } from '../../types/AssessmentSchedule';
import { LatestAssessmentRequest, LatestAssessmentResponse } from '../../types/PatientRequest';

const api = rootApi
  .enhanceEndpoints({ addTagTypes: [TagTypes.InterviewSchedule, TagTypes.MonitorSchedule] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAssessmentSchedule: builder.query<AssessmentScheduleResponse, AssessmentScheduleRequest>({
        query: (request) => ({
          url: request.assessmentType === AssessmentType.Interview
            ? buildInterviewUrl(`/v1/interview/get-interview-schedule`)
            : buildMonitorUrl(`/v1/monitor/get-monitor-schedule`),
          method: 'GET',
          params: {
            patientId: request.patientId,
            assessmentTypeId: request.assessmentTypeId,
          },
        }),
        keepUnusedDataFor: 0,
      }),
      getLastestAssessments: builder.query<LatestAssessmentResponse, LatestAssessmentRequest>({
        query: ({ patientId, assessmentType, assessmentTypeId, page, pageSize }) => {
          const inputParam = assessmentType === AssessmentType.Interview
          ? {
              patientId,
              interviewTypeId: assessmentTypeId,
              page,
              pageSize,
            }
          : {
              patientId,
              monitorTypeId: assessmentTypeId,
              page,
              pageSize,
            };
          return ({
          url: assessmentType === AssessmentType.Interview
            ? buildInterviewUrl(`/v1/interview/latest-interviews`)
            : buildMonitorUrl(`/v1/monitor/latest-monitors`),
          method: 'GET',
          params: inputParam,
        })},
        keepUnusedDataFor: 0,
      }),
      updateAssessmentSchedule: builder.mutation<
        AssessmentScheduleUpdateResponse,
        AssessmentScheduleUpdateRequest
      >({
        query: (updateInfo) => ({
          url: updateInfo.assessmentType === AssessmentType.Interview
              ? buildInterviewUrl(`/v1/interview/update-interview-schedule`)
              : buildMonitorUrl(`/v1/monitor/update-monitor-schedule`),
          method: 'POST',
          body: updateInfo,
        }),
      }),
      stopAssessmentSchedule: builder.mutation<
        AssessmentScheduleUpdateResponse,
        AssessmentScheduleDeleteRequest>({
        query: (deleteInfo) => ({
          url: deleteInfo.assessmentType === AssessmentType.Interview
              ? buildInterviewUrl(`/v1/interview/delete-interview-schedule`)
              : buildMonitorUrl(`/v1/monitor/delete-monitor-schedule`),
          method: 'POST',
          body: deleteInfo,
        }),
      }),
    }),
  });

export const {
  useGetAssessmentScheduleQuery,
  useGetLastestAssessmentsQuery,
  useUpdateAssessmentScheduleMutation,
  useStopAssessmentScheduleMutation,
} = api;

export const { endpoints: assessmentScheduleApis } = api;
