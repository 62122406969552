import { rest } from 'msw';
import { PatientDetailRequest, PatientInfo, PatientEditRequest } from '../types/PatientRequest';

const mocks = [
  rest.get<PatientDetailRequest>('/v1/account/patient', (req, res, ctx) => {
    console.log(req.url.searchParams.get('patientId'));
    return res(
      ctx.status(200),
      ctx.json({
        firstName: 'Cao Huu',
        lastName: 'Nhat',
        externalId: '123453',
        email: 'caohuunhat@gmail.com',
        cellPhone: '0352956126',
        homePhone: '',
        workPhone: '',
        dob: '12/12/2021',
        gender: 'Male',
        race: 'White',
        country: 'US',
        streetAddress1: '',
        streetAddress2: '',
        city: 'California',
        state: 'AR',
        zipCode: '550000',
      })
    );
  }),

  rest.post<PatientInfo>('/v1/account/patient', (req, res, ctx) => {
    console.log(req.body);

    const { externalId } = req.body;
    if (externalId === '111') {
      return res(
        ctx.status(400),
        ctx.json({
          error: ['Some add field was not incorrect.'],
        })
      );
    }

    return res(ctx.status(200));
  }),

  rest.put<PatientEditRequest>('/v1/account/patient/:patientId', (req, res, ctx) => {
    console.log(req);
    const externalId = req.body.data?.externalId;
    if (externalId === '111') {
      return res(
        ctx.status(400),
        ctx.json({
          error: ['Some update field was not incorrect.'],
        })
      );
    }
    return res(ctx.status(200));
  }),

  rest.get<PatientInfo>('/v1/dashboard/patient?patientId', (req, res, ctx) => {
    console.log(req.url.searchParams.get('patientId'));
    const patientId = req.url.searchParams.get('patientId');
    console.log(patientId);

    return res(
      ctx.status(200),
      ctx.json({
        patientInfo: {
          name: 'Joseph Peterson',
          patientId: '13214144',
          externalId: '13214234',
        },
        interviews: [
          { name: 'M.I.N.I. 7.0.2', hasTimeframe: true },
          {
            name: 'SDS',
            hasTimeframe: true,
          },
          { name: 'Yale-Brown Obsessive Compulsive Scale (CY-BOCS)', hasTimeframe: true },
        ],
      })
    );
  }),
];

export default process.env.REACT_APP_ACCOUNT_MOCK_DISABLED === 'true' ? [] : mocks;
