import { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { MonitorSubQuestionProps } from '../../../types/MonitorTypes';
import { StyledClinicianNote as StyleTextQuestion } from '../../interview/interviewUtils';

export default function TextQuestion({
  subQuestionId,
  onChangeCallBack,
  questionText,
  error,
  helperText,
}: MonitorSubQuestionProps) {
  const handleTextQuestionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const textValue = event.target.value;
    if (onChangeCallBack) {
      onChangeCallBack(textValue.trim() ? [textValue.trim()] : [], subQuestionId);
    }
  };

  return (
    <Box sx={{ width: 1 }}>
      <FormControl error={error} variant="standard" sx={{ width: 1 }}>
        {questionText && (
          <Typography
            mt={1}
            dangerouslySetInnerHTML={{
              __html: questionText,
            }}
          ></Typography>
        )}
        <Box>
          <StyleTextQuestion
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            onBlur={handleTextQuestionChange}
          />
          {error && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
      </FormControl>
    </Box>
  );
}
