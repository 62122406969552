import Alert from '@mui/material/Alert';

interface ErrorProps {
  errors: any;
  message: string;
}

export default function InterviewSummaryError({ errors, message }: ErrorProps) {
  return (
    <>
      {errors && Object.keys(errors).length > 0 && (
        <Alert severity="error" sx={{ width: '100%!important', my: 2 }}>
          {message}
        </Alert>
      )}
    </>
  );
}
