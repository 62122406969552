import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { buildInterviewConductUrl } from '../../app/routes';
import { landingTextStyle } from '../../common/utils/commonStyles';
import { InterviewMode } from '../../common/utils/interviewsMode';
import { InterviewSettingsResponse } from '../../types/InterviewRequest';
import { clearCredentials } from '../auth/authSlice';
import { clearAssessmentCachedData } from '../patient/patientUtils';

interface Props {
  isTrialRun?: boolean;
  interviewMode: 'clinician' | 'patient';
  reportType?: string;
  resumeInterview: boolean;
  accountSettings?: InterviewSettingsResponse;
  pageErrors: string[];
  cancelUrl: string;
  editInterview: boolean;
}

// TODO: move to share file for interview pages
export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '16px 0',
  marginBottom: '8px',
  borderRadius: '8px',
  width: '100%',

  minWidth: 200,
  '&.MuiButton-text': {
    backgroundColor: theme.xPalette.white,
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up('md')]: {
    width: '20%',
    marginBottom: 0,
    marginLeft: '8px',
  },
}));

export default function ReadyOrResumeInterview({
  isTrialRun,
  interviewMode,
  reportType,
  resumeInterview,
  accountSettings,
  pageErrors,
  cancelUrl,
  editInterview,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { patientId, interviewResultId } = useParams();

  const dispatch = useAppDispatch();
  let isClinicianInterview = interviewMode === InterviewMode.Clinician;

  const renderLandingHeader = () => {
    if (accountSettings?.landingHeader) {
      return (
        <Typography
          variant="h1"
          dangerouslySetInnerHTML={{
            __html: accountSettings.landingHeader,
          }}
        ></Typography>
      );
    }
    return resumeInterview ? (
      <Typography variant="h1">{t('interview.resumeInterview.title')}</Typography>
    ) : (
      <Typography variant="h1">{t('interview.InterviewInstructionsPage.title')}</Typography>
    );
  };

  const renderLandingText = () => {
    if (accountSettings?.landingText) {
      return (
        <Typography
          dangerouslySetInnerHTML={{
            __html: accountSettings.landingText,
          }}
        ></Typography>
      );
    }
    return resumeInterview ? (
      <Typography variant="subtitle1">{t('interview.resumeInterview.body')}</Typography>
    ) : (
      <Typography
        variant="subtitle1"
        dangerouslySetInnerHTML={{
          __html: t('interview.InterviewInstructionsPage.landingPageClinicianText'),
        }}
      ></Typography>
    );
  };

  return (
    <>
      <Box sx={{ py: 3 }}>{renderLandingHeader()}</Box>
      <Box sx={{ mb: 1, ...landingTextStyle() }}>
        {isTrialRun && (
          <Typography variant="h2" fontWeight={400} mb={2} color="red">
            {t('interview.InterviewInstructionsPage.trialRun')}
          </Typography>
        )}

        {renderLandingText()}
      </Box>
      {pageErrors &&
        pageErrors.length > 0 &&
        pageErrors.map((error) => (
          <Alert key={error} severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        ))}
      <Box
        sx={{
          py: 2,
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: matches ? 'column' : 'row-reverse',
        }}
      >
        <StyledButton
          disabled={pageErrors && pageErrors.length > 0}
          type="button"
          variant="contained"
          onClick={() => {
            const interviewConductUrl = buildInterviewConductUrl(
              reportType!,
              interviewMode,
              {
                interviewResultId: interviewResultId!,
                patientId: patientId!,
              },
              editInterview
            );

            if (editInterview) {
              isClinicianInterview = true;
            }

            if (isClinicianInterview) {
            } else {
              dispatch(clearCredentials());
            }

            navigate(interviewConductUrl);
          }}
        >
          {resumeInterview
            ? t('interview.resumeInterview.submit')
            : t('interview.InterviewInstructionsPage.submit')}
        </StyledButton>
        <StyledButton
          type="button"
          variant="outlined"
          onClick={() => {
            navigate(cancelUrl || `/dashboard/patient/${patientId}`);
            clearAssessmentCachedData();
          }}
        >
          {t('interview.InterviewInstructionsPage.cancel')}
        </StyledButton>
      </Box>
    </>
  );
}
