import { QueryCacheDuration, buildAccountUrl, rootApi } from '..';
import {
  DeleteTreatmentSignatureParam,
  TreatmentSignatureAddRequest,
  getTreatmentSignatureDetailRequest,
  getTreatmentSignatureDetailResponse,
} from '../../../types/treatment-plan/TreatmentSignature';

const api = rootApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({

    deleteTreatmentSignature: builder.mutation<void, DeleteTreatmentSignatureParam>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-signature/${params.treatmentSignatureId}`),
        method: 'DELETE',
      }),
    }),

    getTreatmentSignatureData: builder.query<
      getTreatmentSignatureDetailResponse,
      getTreatmentSignatureDetailRequest
    >({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-signature/${params.treatmentSignatureId}`),
        method: 'GET',
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    addTreatmentSignature: builder.mutation<
      void,
      TreatmentSignatureAddRequest
    >({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-signature`),
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useDeleteTreatmentSignatureMutation,
  useGetTreatmentSignatureDataQuery,
  useAddTreatmentSignatureMutation,
} = api;
