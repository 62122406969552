import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { loginLayoutClass } from '../../app/theme';
import { BaseProps } from '../../types/PageProps';
import Copyright from '../components/Copyright';
import Logo from '../components/Logo';
import Notification from '../components/Notification';
import { pageHeightStyle } from '../utils/commonStyles';

interface SubDashboardProps extends BaseProps {
  isShowFooter?: boolean;
}

export default function SubDashboardLayout({ children, isShowFooter }: SubDashboardProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  // for rendering bottom background image
  useEffect(() => {
    document.body.classList.add(loginLayoutClass);
    return () => {
      document.body.classList.remove(loginLayoutClass);
    };
  });

  return (
    <Container maxWidth="xl" disableGutters sx={{ position: 'relative' }}>
      <Notification />
      <Box sx={(theme) => ({ px: 3, py: 4, ...pageHeightStyle(theme) })}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 5,
          }}
        >
          <Logo width={200} />
          <Typography variant="subtitle1" color={theme.xPalette.logoText} marginTop="12px">
            {t('site.logoText')}
          </Typography>
        </Box>
        {children}
      </Box>
      <Box sx={{ pb: 1 }}>{isShowFooter && <Copyright />}</Box>
    </Container>
  );
}
