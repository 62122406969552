import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Hidden from '@mui/material/Hidden';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setGridSelection, selectResetQuestion, setResetQuestion } from '../../interviewSlice';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: '8px',
  fontSize: '16px !important',
  [theme.breakpoints.down('md')]: {
    fontWeight: '500',
    fontSize: '18px',
  },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transitionDuration: '0s !important',
  padding: '8px',
  '&:nth-of-type(2n+2)': {
    backgroundColor: theme.xPalette.noteLightGrey,
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'start',
    '&:nth-of-type(2n+2)': {
      backgroundColor: theme.xPalette.white,
    },
  },
}));

const StyledCheckIcon = styled(Box)(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  border: `1px solid ${theme.xPalette.lightGrey}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledRadioButton = styled(Radio)(({ theme }) => ({
  padding: '2px',
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  '&.MuiRadio-root .MuiSvgIcon-root': {
    fontSize: '1.4rem',
  },
}));

interface Props {
  title: string;
  text: string;
  selectedValue: any;
  questionOptions: any;
  isOther: boolean;
  questionTitles: string[];
  previousAnswers?: any;
}

function GridItem({
  title,
  text,
  selectedValue,
  questionOptions,
  isOther,
  questionTitles,
  previousAnswers,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState<string>('');
  const [currentFactor, setCurrentFactor] = useState<string>('');
  const [factorQuestion, setFactorQuestion] = useState<{ [key: string]: string }>({});
  const dispatch = useAppDispatch();
  const [gridValue, setGridValue] = useState<any>({});
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);

  useEffect(() => {
    if (previousAnswers) {
      const previousAnswer = JSON.parse(previousAnswers.split('|'));

      if (previousAnswer[title] && previousAnswer[title].factor) {
        setCurrentValue(previousAnswer[title].value);
        setCurrentFactor(previousAnswer[title].factor);
        setFactorQuestion({ [title]: previousAnswer[title].factor });
        setGridValue({
          [title]: `${previousAnswer[title].value}|${previousAnswer[title].factor}`,
        });
      }
      if (previousAnswer[title] && !previousAnswer[title].factor) {
        setCurrentValue(previousAnswer[title].value);
        setGridValue({ [title]: previousAnswer[title].value });
      }
    }
  }, [previousAnswers, selectedValue, title]);

  useEffect(() => {
    if (selectedValue) {
      setCurrentValue(selectedValue);
    }
  }, [selectedValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let gridItemValue = value;
    setCurrentValue(value);
    if (factorQuestion[name]) {
      gridItemValue = `${value}|${factorQuestion[name]}`;
    }
    setGridValue({ [name]: gridItemValue });
    dispatch(setResetQuestion({ reset: false }));
  };

  const handleFactorChange = (event: any) => {
    const { name, value } = event.target;
    if (gridValue[name]) {
      const splitValue = gridValue[name].split('|');
      setGridValue({ [name]: `${splitValue[0]}|${value}` });
    }
    setCurrentFactor(value);
    setFactorQuestion({ ...factorQuestion, [name]: value });
  };

  useEffect(() => {
    dispatch(
      setGridSelection({
        value: gridValue,
      })
    );
  }, [dispatch, gridValue]);

  useEffect(() => {
    setGridValue({});
    if (isResetQuestion) {
      setCurrentValue('');
      setFactorQuestion({});
    }
  }, [isResetQuestion]);

  const renderOptions = (questionTitle: any) => {
    return questionOptions?.map((option: any, index: any) => (
      <FormControlLabel
        sx={{ mx: 0, my: 1 }}
        key={option}
        value={option}
        labelPlacement="end"
        control={
          <StyledRadioButton
            icon={<StyledCheckIcon></StyledCheckIcon>}
            checkedIcon={<CircleIcon fontSize="small" />}
            checked={currentValue === option}
            value={option}
            name={questionTitle}
            inputProps={{ 'aria-label': option }}
          />
        }
        label={
          <Hidden mdUp>
            <Typography
              sx={{
                ml: 1,
                '&>*': {
                  all: 'unset',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: questionTitles[index],
              }}
            ></Typography>
          </Hidden>
        }
      />
    ));
  };

  return (
    <StyledRadioGroup
      name={`{group_${title}}`}
      key={title}
      value={currentValue}
      onChange={handleChange}
      row
    >
      <Box
        sx={{
          width: '30%',
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        }}
      >
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: `${title}. ${text}`,
          }}
        ></StyledTypography>
        {isOther && (
          <TextField
            name={title}
            value={currentFactor}
            margin="normal"
            fullWidth
            label={t('interview.form.grid.factor')}
            variant="outlined"
            onBlur={handleFactorChange}
            onChange={handleFactorChange}
          />
        )}
      </Box>
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
          },
        }}
      >
        {renderOptions(title)}
      </Box>
    </StyledRadioGroup>
  );
}

export default memo(GridItem);
