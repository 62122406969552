import { initReactI18next } from 'react-i18next';
import i18n, { i18n as i18nType } from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import storage from 'redux-persist/lib/storage';
import { AppConfig } from '../common/utils/commonUtils';
import { logger } from '../common/utils/logger';

const supportedLanguages = ['en', 'fr', 'es', 'de', 'af', 'pl'];
const resourcePrefix = 'i18next_res_';

const getI18nLanguage = () => localStorage.getItem('i18nextLng');

// logic to get storage key is similar to: https://github.com/i18next/i18next-localstorage-backend/blob/master/src/index.js
const getResourceStorageKey = (i18n: i18nType) => {
  return `${resourcePrefix}${getI18nLanguage()}-${
    i18n.options.defaultNS ? i18n.options.defaultNS[0] : 'translation'
  }`;
};

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: supportedLanguages,
    fallbackLng: 'en',
    keySeparator: '.', // we use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          prefix: resourcePrefix,
          expirationTime: process.env.NODE_ENV === 'development' ? 5 * 1000 : 60 * 60 * 1000,
          defaultVersion: AppConfig.version,
        },
        {
          loadPath: '/locales/{{lng}}.json?v=' + AppConfig.version,
        },
      ],
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;

export const invalidateI18nCache = (appVersion: string) => {
  const storedVersionKey = 'appVersion';
  const storedAppVersion = localStorage.getItem(storedVersionKey);
  if (storedAppVersion !== appVersion) {
    localStorage.setItem(storedVersionKey, appVersion);
    const storageKey = getResourceStorageKey(i18n);
    logger.debug('Removing i18n resource cache...');
    storage.removeItem(storageKey);

    // force reload page to flush client side caching
    window.location.reload();
  }
};
