import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { searchPatientsEndpoint } from '../../app/services/account';
import { RootState } from '../../app/store';

export enum PageStatus {
  Idle = 'idle',
  Loading = 'loading',
}
interface ContentPopup {
  title: string;
  description: string;
  btnOk: string;
  btnClose: string;
  toastMessage: string;
}

interface GlobalStateType {
  pageStatus: PageStatus; // idle, loading, ...
  screenLockDisabled?: boolean;
  isOpenPopup?: boolean;
  contentPopup?: ContentPopup;
}

const initialState: GlobalStateType = {
  pageStatus: PageStatus.Idle,
  isOpenPopup: false,
};

const isScreenLockDisabled = (action: any) => {
  const ignoredActions = [
    searchPatientsEndpoint,
    'getLatestInterviews', // TODO: remove hardcode or add timeout for show/hide loading icon globally
    'getLatestMonitors',
    'getLatestInterviewsByPatients',
    'getLatestMonitorsByPatients',
  ];
  return ignoredActions.includes(action.meta?.arg?.endpointName);
};

const getIdleState = () => ({ pageStatus: PageStatus.Idle });

const getLoadingState = (action: any) => ({
  pageStatus: PageStatus.Loading,
  screenLockDisabled: isScreenLockDisabled(action),
});

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setPageStatus: (
      state,
      { payload: { pageStatus } }: PayloadAction<{ pageStatus: PageStatus }>
    ) => {
      state.pageStatus = pageStatus;
    },
    setIsOpenPopup: (state, { payload: { value } }: PayloadAction<{ value: boolean }>) => {
      state.isOpenPopup = value;
    },
    setContentPopup: (
      state,
      { payload: { content } }: PayloadAction<{ content?: ContentPopup }>
    ) => {
      state.contentPopup = content;
    },
  },
  extraReducers: (builder) => {
    builder
      // Query
      .addCase('api/executeQuery/pending', (_state, action) => getLoadingState(action))
      .addCase('api/executeQuery/fulfilled', getIdleState)
      .addCase('api/executeQuery/rejected', getIdleState)

      // Mutation
      .addCase('api/executeMutation/pending', (_state, action) => getLoadingState(action))
      .addCase('api/executeMutation/fulfilled', getIdleState)
      .addCase('api/executeMutation/rejected', getIdleState);
  },
});

export const {
  setPageStatus,
  setIsOpenPopup,
  setContentPopup,
} = globalSlice.actions;

export const selectPageStatus = (state: RootState): PageStatus => state.global.pageStatus;
export const selectIsOpenPopup = (state: RootState): boolean => state.global.isOpenPopup;
export const selectContentPopup = (state: RootState): ContentPopup => state.global.contentPopup;
export const selectShowGlobalLoading = (state: RootState): boolean =>
  state.global.pageStatus === PageStatus.Loading && !state.global.screenLockDisabled;

export default globalSlice.reducer;
