import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { default as SignatureCanvas } from 'react-signature-canvas';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../../app/hooks';
import { buildRedirectUrl } from '../../../app/routes';
import {
  useGetFollowUpSignatureByTokenQuery,
  useUpdateFollowUpSignatureByTokenMutation,
} from '../../../app/services/account';
import ElectronicSignature from '../../../common/components/ElectronicSignature';
import Loading from '../../../common/components/Loading';
import RoundedContainer from '../../../common/components/RoundedContainer';
import SubmitButton from '../../../common/components/SubmitButton';
import DashboardLayout from '../../../common/layouts/Dashboard';
import { StyleErrorForm } from '../../../common/utils/commonStyles';
import { getPageTitle } from '../../../common/utils/pageUtils';
import { SubResponseError, URLErrorType } from '../../../types/ResponseError';
import { clearCredentials } from '../../auth/authSlice';
import ErrorPage from '../../error/ErrorPage';
import { clearAssessmentCachedData } from '../../patient/patientUtils';

export const StyleRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));

const defaultValues: any = {
  signature: '',
};

const redirectLinkToProem = (
  t: TFunction<'translation', undefined>,
  navigate: NavigateFunction
) => (
  <Link
    component="button"
    onClick={() => {
      navigate(buildRedirectUrl('https://proemhealth.com'));
    }}
    fontWeight="500"
    variant="body1"
  >
    {t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.proemhealth')}
  </Link>
);

export default function ClinicalFollowUpsEmailSignaturePage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [signature, setSignature] = useState<string>('');
  const [pageErrors, setPageErrors] = useState<URLErrorType | undefined>(undefined);
  const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false);

  const sigRef = useRef<SignatureCanvas>(null);
  const { token, tokenHash } = useParams();

  const [updateSignatureByToken] = useUpdateFollowUpSignatureByTokenMutation();
  const {
    data,
    isLoading,
    error: checkedError,
  } = useGetFollowUpSignatureByTokenQuery(token && tokenHash ? { token, tokenHash } : skipToken);

  const validationSchema = yup
    .object({
      signature: yup
        .string()
        .required(
          t(
            'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.error.blankSignature'
          )
        ),
    })
    .required();

  const {
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleSignatureEnd = () => {
    setSignature(sigRef?.current?.toDataURL() || '');
  };

  const clearSignature = () => {
    sigRef?.current?.clear();
    setSignature('');
  };

  useEffect(() => {
    setValue('signature', signature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  useEffect(() => {
    dispatch(clearCredentials());
    clearAssessmentCachedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async (data: any) => {
    const { signature } = data;
    try {
      if (token && tokenHash) {
        const res = await updateSignatureByToken({
          token,
          tokenHash,
          signature,
        }).unwrap();

        if (res) {
          setPageErrors(undefined);
          setShowSuccessPage(true);
        }
      }
    } catch (e) {
      const {
        data: { error },
      } = e as SubResponseError;
      setPageErrors(error[0]);
    }
  };

  if (checkedError || pageErrors) {
    let errorMessage: URLErrorType | undefined = pageErrors;
    if (checkedError) {
      const {
        data: { error },
      } = checkedError as SubResponseError;
      errorMessage = error?.[0];
    }
    return (
      <ErrorPage
        showStatusCode={false}
        children={
          errorMessage && (
            <>
              <Typography fontWeight="bold" variant="h2">
                {errorMessage?.title}
              </Typography>
              <Typography fontWeight="500" variant="body1" mt={3} mb={1}>
                {errorMessage?.body}
              </Typography>
              {redirectLinkToProem(t, navigate)}
            </>
          )
        }
      />
    );
  }

  return (
    <DashboardLayout
      showBreadcrumb={false}
      accountLogo={data?.accountLogo || (checkedError as unknown as SubResponseError)?.accountLogo}
    >
      <Helmet>
        <title>
          {getPageTitle(
            t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.title')
          )}
        </title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        {isLoading ? (
          <Loading />
        ) : showSuccessPage ? (
          <RoundedContainer sx={{ mt: 2, py: 2 }}>
            <Typography fontWeight="500" variant="body1" mb={1}>
              {t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.complete')}
            </Typography>
            {redirectLinkToProem(t, navigate)}
          </RoundedContainer>
        ) : (
          <>
            <Container maxWidth="xl" sx={{ my: 2 }}>
              <Typography variant="h1">
                {t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.header')}
              </Typography>
            </Container>
            <RoundedContainer
              sx={{
                pt: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    rowGap: 1,
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <Typography fontWeight="bold" variant="body1">
                      {data?.followUpName}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      py: 1,
                    }}
                  >
                    {t('account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.term')}
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <ElectronicSignature
                      ref={sigRef}
                      clear={clearSignature}
                      onEnd={handleSignatureEnd}
                      matches={matches}
                      headerText={t(
                        'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.signature'
                      )}
                      footerText={data?.providerFullName || ''}
                      backgroundColor={theme.xPalette.noteLightGrey}
                      showTopClearButton
                      showDivider={false}
                      sx={{
                        width: matches ? '100%' : 500,
                        p: 0,
                        '& .MuiBox-root:first-child .MuiTypography-root:first-child': {
                          fontWeight: 600,
                        },
                      }}
                    />
                    {errors?.signature && (
                      <StyleErrorForm>
                        {errors.signature && (errors.signature as any).message}
                      </StyleErrorForm>
                    )}
                  </Box>
                </Box>
                <SubmitButton
                  content={t(
                    'account.manageAccounts.accountForm.clinicalFollowUps.signatureForm.submit'
                  )}
                  matches={matches}
                  pageErrors={[]}
                />
              </Box>
            </RoundedContainer>
          </>
        )}
      </Container>
    </DashboardLayout>
  );
}
