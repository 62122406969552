import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import useCustomQuestionInfo from './useCustomQuestionInfo';

const borderStyle = `1px solid ${theme.xPalette.black}`;

export default function TargetSymptomListTextAnswer({
  questionAnswer: yBocsQuestionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const { answerValue, questionNameDisplay } = useCustomQuestionInfo({
    initialState: yBocsQuestionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <Box
      key={yBocsQuestionAnswer.questionId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        pageBreakInside: 'avoid',
        rowGap: 1,
        height: '100%',
      }}
    >
      <Box sx={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', px: 1, py: 2 }}>
        <Typography variant="h2" fontWeight={'bold'} fontSize={'16px'}>
          {questionNameDisplay}
        </Typography>
      </Box>
      {!!answerValue && (
        <Box
          sx={{
            flex: 3,
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: borderStyle,
            px: 1,
            py: 2,
          }}
        >
          <Typography
            fontWeight="bold"
            fontSize={'16px'}
            dangerouslySetInnerHTML={{
              __html: answerValue,
            }}
          ></Typography>
        </Box>
      )}
    </Box>
  );
}
