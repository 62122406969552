import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useGetPatientQuery } from '../../app/services/patient';
import {
  useDeleteTreatmentPlanMutation,
  useGetTreatmentPlanListQuery,
} from '../../app/services/treatment-plan/treatmentPlan';
import { useToast } from '../../app/toast';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import DeactivatePatientIcon from '../../common/components/icons/DeactivatePatientIcon';
import EditUserIcon from '../../common/components/icons/EditInterviewIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { formatDate } from '../../common/utils/dateTimeUtils';
import { ReplaceType, editBreadcrumbs } from '../../common/utils/editBreadcrums';
import { Role } from '../../common/utils/loginRole';
import { getPageTitle } from '../../common/utils/pageUtils';
import { SortDirs, SortFields } from '../../common/utils/sortInfo';
import { truncate } from '../../common/utils/treatmentPlans';
import { PageProps } from '../../types/PageProps';
import { PopupContent } from '../../types/PopupType';
import { TreatmentPlanItem } from '../../types/treatment-plan/TreatmentPlanType';
import { selectUser } from '../auth/authSlice';
import ContextMenu from '../patient/ContextMenu';
import PatientSortMenu from '../patient/PatientSortMenu';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.xPalette.white,
  flexBasis: '100%',
  minWidth: '260px',
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'unset',
    width: '74%',
    minWidth: 'unset',
  },
}));

const StyledAddTreatmentBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: '18px',
  width: '40%',
  minWidth: '200px',
  ml: 1,
  [theme.breakpoints.down('sm')]: {
    width: '96%',
    height: '56px',
    ml: 0,
  },
}));

const StyledAddWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 0px',
  width: '100%',
  columnGap: '8px',
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    width: 'auto',
    flexBasis: '40%',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',
  },
}));

const defaultPage = 1;

export const Status = {
  active: 'Active',
  completedNotSigned: 'Completed - Not Signed',
  completedSigned: 'Completed - Signed',
};

export default function TreatmentPlanListPage({ breadcrumbs }: PageProps) {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const toast = useToast();
  const currentUser = useAppSelector(selectUser);

  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [searchParams] = useSearchParams();
  const [displayTreatmentPlanData, setDisplayTreatmentPlanData] = useState<any[]>([]);
  const [treatmentIdAction, setTreatmentIdAction] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [navBackUrl, setNavBackUrl] = useState<string>('/dashboard/treatment-plan');

  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);

  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }];

  const tableHeaders = Object.entries(
    t('treatmentPlans.manageTreatmentPlan.tableHeader', {
      returnObjects: true,
    })
  );

  const deleteTreatmentPlanPopup = {
    title: t('treatmentPlans.manageTreatmentPlan.deleteTreatmentPlan.title'),
    description: t('treatmentPlans.manageTreatmentPlan.deleteTreatmentPlan.description'),
    btnOk: t('treatmentPlans.manageTreatmentPlan.deleteTreatmentPlan.btnOk'),
    btnClose: t('treatmentPlans.manageTreatmentPlan.deleteTreatmentPlan.btnClose'),
    toastMessage: t('treatmentPlans.manageTreatmentPlan.deleteTreatmentPlan.toastMessage'),
  } as PopupContent;

  const [deletedTreatmentPlan] = useDeleteTreatmentPlanMutation();

  const searchTextboxRef = useRef<HTMLInputElement>(null);

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
    search: searchParams.get('search') || '',
    sortField: searchParams.get('sort') || SortFields.Name,
    sortDir: searchParams.get('dir') || SortDirs.Asc,
  };

  const currentPage = searchOptions.page;
  const tableRef = useRef<HTMLDivElement>(null);

  const {
    data: treatmentPlanListData,
    isLoading: isLoadingTreatmentPlan,
    refetch: refetchTreatmentPlanList,
  } = useGetTreatmentPlanListQuery(
    patientId
      ? {
          patientId: patientId,
          search: searchOptions.search,
          page: currentPage,
          pageSize: pageSize,
          sort: `${searchOptions.sortField} ${searchOptions.sortDir}`,
        }
      : skipToken
  );

  const { data: patientInfo } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const StyledSortIconBox = styled(Box)(({ theme }) => ({
    display: 'inline',
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
  }));

  const showDeleteTreatmentPlanPopup = (treatmentPlanId: number) => {
    dispatch(setContentPopup({ content: deleteTreatmentPlanPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setTreatmentIdAction(treatmentPlanId);
    handleClose();
  };

  useEffect(() => {
    if (treatmentPlanListData) {
      setDisplayTreatmentPlanData(treatmentPlanListData.items);
      setTotalPages(Math.ceil(treatmentPlanListData.totalItems / pageSize));
    }
  }, [pageSize, treatmentPlanListData]);

  const updateUrl = (page: number, search: string | undefined, sort: string, dir: string) => {
    const updatedUrl = `/dashboard/patient/${patientId}/treatment-plan?page=${page}&search=${encodeURIComponent(
      search || ''
    )}&sort=${sort}&dir=${dir}`;
    navigate(updatedUrl);
    setNavBackUrl(updatedUrl);
  };

  const handlePageChange = (value: number) => {
    updateUrl(value, searchOptions.search, searchOptions.sortField, searchOptions.sortDir);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    const searchInput = searchTextboxRef.current?.value || '';
    updateUrl(defaultPage, searchInput, searchOptions.sortField, searchOptions.sortDir);
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleMenuSorting = (sortField: string, sortDir: string) => {
    updateUrl(defaultPage, searchOptions.search, sortField, sortDir);
  };

  const onHeaderClicked = (header?: string) => {
    if (header) {
      updateUrl(
        defaultPage,
        searchOptions.search,
        header,
        header !== searchOptions.sortField || searchOptions.sortDir === SortDirs.Desc
          ? SortDirs.Asc
          : SortDirs.Desc
      );
    }
  };

  const handleDeleteTreatmentPlan = async (successCallback?: () => void) => {
    try {
      await deletedTreatmentPlan({
        id: treatmentIdAction,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('treatmentPlans.manageTreatmentPlan.deleteTreatmentPlan.success'), 'success');
      refetchTreatmentPlanList();
      updateUrl(defaultPage, searchOptions.search, searchOptions.sortField, searchOptions.sortDir);
    } catch (e) {
      toast.publish(t('treatmentPlans.manageTreatmentPlan.deleteTreatmentPlan.fail'), 'error');
    }
  };

  const renderContextMenu = (item: any) => {
    const menuItems = [
      {
        label: t('treatmentPlans.manageTreatmentPlan.menuContext.editTreatmentPlan'),
        icon: <EditUserIcon />,
        onClick: () => {
          navigate(`/dashboard/patient/${patientId}/treatment-plan/edit/${item.id}`, {
            state: { navBackUrl },
          });
        },
      },
      {
        isDisplayed: currentUser.roleId !== Role.Interviewer,
        label: t('treatmentPlans.manageTreatmentPlan.menuContext.deleteTreatmentPlan'),
        icon: <DeactivatePatientIcon />,
        onClick: () => showDeleteTreatmentPlanPopup(item.id),
      },
    ];

    // check if not use then hide
    return (
      <ContextMenu
        id={`treatment-plan-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    );
  };

  const renderDesktopData = (data: TreatmentPlanItem[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map(([key, name], index) => {
                const sortHeader = ['name', 'startDate', 'status'];
                const checkSortEvent =
                  searchOptions.sortDir === SortDirs.Asc && searchOptions.sortField === key;

                const sortIcon =
                  searchOptions.sortField === key ? (
                    <StyledSortIconBox>
                      {checkSortEvent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </StyledSortIconBox>
                  ) : (
                    <></>
                  );
                return (
                  <StyledTableCell
                    key={`${key} ${index}`}
                    sx={{
                      cursor: sortHeader.includes(key) ? 'pointer' : 'unset',
                    }}
                    onClick={sortHeader.includes(key) ? () => onHeaderClicked(key) : undefined}
                  >
                    {name} {sortIcon}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: TreatmentPlanItem) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell sx={{ minWidth: '100px' }}>
                    {formatDate(item.startDate)}
                  </StyledTableCell>
                  <StyledTableCell sx={{ maxWidth: '500px', minWidth: '150px' }}>
                    <Link
                      sx={{ fontSize: '1em', textAlign: 'left' }}
                      component="button"
                      onClick={() => {
                        navigate(`/dashboard/patient/${patientId}/treatment-plan/edit/${item.id}`, {
                          state: { navBackUrl },
                        });
                      }}
                    >
                      {truncate(item.name, 180)}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>
                    {Object.keys(Status).includes(item.status) ? Status[`${item.status}`] : ''}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      '& .MuiButtonBase-root': {
                        marginLeft: '10px',
                      },
                    }}
                  >
                    {renderContextMenu(item)}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: TreatmentPlanItem[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: TreatmentPlanItem) => {
          const listItems = [
            [
              <>{t('treatmentPlans.manageTreatmentPlan.tableHeader.startDate')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{formatDate(item.startDate)}</Box>,
            ],
            [
              <>{t('treatmentPlans.manageTreatmentPlan.tableHeader.status')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>
                {Object.keys(Status).includes(item.status) ? Status[`${item.status}`] : ''}
              </Box>,
            ],
          ];
          return {
            leftHeader: (
              <Link
                component="button"
                variant="body1"
                onClick={() => {
                  navigate(`/dashboard/patient/${patientId}/treatment-plan/edit/${item.id}`, {
                    state: { navBackUrl },
                  });
                }}
              >
                {truncate(item.name, 180)}
              </Link>
            ),
            rightHeader: renderContextMenu(item),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  const isLoading = isLoadingTreatmentPlan;

  return (
    <DashboardLayout breadcrumbs={editBreadcrumbs(breadcrumbs, ...replacePath)}>
      <Helmet>
        <title>{getPageTitle(t('treatmentPlans.title'))}</title>
      </Helmet>
      <Container sx={{ px: 1 }} maxWidth="xl" disableGutters={!isDesktop}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 0 : 2 }}
            headerText={t('treatmentPlans.title')}
            backUrl={`/dashboard/patient/${patientId}`}
          />

          <StyledAddWrapper>
            <StyledSearchBox
              name="treatment-plan-search-field"
              data-testid="treatment-plan-search-field"
              inputRef={searchTextboxRef}
              onKeyUp={handleSearchKeyUp}
              defaultValue={searchOptions.search}
              placeholder={t('treatmentPlans.manageTreatmentPlan.searchTreatmentPlan')}
              endAdornment={
                <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              }
            />
            {!isDesktop && (
              <PatientSortMenu
                sx={{
                  width: '20%',
                  flexShrink: 0,
                  borderRadius: '8px',
                }}
                sortField={searchOptions.sortField}
                sortDir={searchOptions.sortDir}
                onSorted={handleMenuSorting}
                isTreatmentPlan
              />
            )}
            <StyledAddTreatmentBox
              type="button"
              fullWidth
              variant="contained"
              onClick={() => {
                navigate(`/dashboard/patient/${patientId}/treatment-plan/add`, {
                  state: { navBackUrl },
                });
              }}
            >
              {t('treatmentPlans.manageTreatmentPlan.addTreatmentPlan')}
            </StyledAddTreatmentBox>
          </StyledAddWrapper>
        </Box>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
              paddingX: 0,
            },
            [theme.breakpoints.down('md')]: {
              paddingX: 1,
            },
          }}
        >
          <RoundedContainer sx={{ py: 2 }}>
            <PatientInfoCard data={patientInfo!} />
          </RoundedContainer>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={tableRef} sx={{ paddingX: isDesktop ? 0 : 1 }}>
            {displayTreatmentPlanData.length === 0 ? (
              <RoundedContainer sx={{ mt: 2, p: 2 }}>
                <Typography>
                  {t('treatmentPlans.manageTreatmentPlan.error.treatmentPlanNotFound')}
                </Typography>
              </RoundedContainer>
            ) : (
              <Box sx={{ mt: 2 }}>
                {isDesktop
                  ? renderDesktopData(displayTreatmentPlanData)
                  : renderMobileData(displayTreatmentPlanData)}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 4,
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    background: theme.xPalette.white,
                    mt: 0,
                    [theme.breakpoints.down('sm')]: {
                      mt: 1,
                      py: 2,
                    },
                  }}
                >
                  <CustomPagination
                    changePage={(e, value) => handlePageChange(value)}
                    currentPage={searchOptions.page}
                    pages={totalPages}
                    pageRef={tableRef}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={handleDeleteTreatmentPlan}
        />
      </Container>
    </DashboardLayout>
  );
}
