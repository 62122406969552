export const toTitleCase = (text: string) => {
  return text
    .toLowerCase()
    .split(' ')
    .filter((word: string) => word)
    .map((word: string) => {
      return word?.replace(word[0], word[0]?.toUpperCase());
    })
    .join(' ');
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const csvToArray = (str: string, delimiter = ',') => {
  const rows = str.split('\n');
  const arr = rows
    .filter((row) => !!row)
    .map((row) => row.replace(/"/g, '').replace('\r', '').split(delimiter));
  return arr;
};

export const isURL = (url: string) => {
  const pattern = new RegExp(
    '^((ftp|http|https):\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?' + // port
      '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
      '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(url);
};
