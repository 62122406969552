import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Container from '@mui/material/Container';
import {
  useGetClinicAccountsQuery,
  useImportPatientsMutation,
} from '../../../app/services/account';
import { useToast } from '../../../app/toast';
import Loading from '../../../common/components/Loading';
import PageHeader from '../../../common/components/PageHeader';
import RoundedContainer from '../../../common/components/RoundedContainer';
import DashboardLayout from '../../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../../common/utils/editBreadcrums';
import { getPageTitle } from '../../../common/utils/pageUtils';
import { PageProps } from '../../../types/PageProps';
import { ImportPatientData } from '../../../types/PatientRequest';
import { ImportPatientError } from '../../../types/ResponseError';
import ImportPatientForm from './ImportPatientForm';
import LineErrorTable from './LineErrorTable';

export default function ImportPatientPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [errorLineItems, setErrorLineItems] = useState<{ [key: string]: string[] }[]>([]);

  const [importPatients] = useImportPatientsMutation();

  const { data: accountData, isLoading: loadAccountsData } = useGetClinicAccountsQuery();

  const validationSchema = yup
    .object({
      clinicAccount: yup.string().required(t('user.manageUsers.error.blankClinicAccount')),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ImportPatientData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      clinicAccount: '',
      file: undefined,
    },
  });

  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const newBreadcrumb: BreadcrumbType = {
    text: t('dashboard.accountStats.totalPatients.title'),
    link: navBackUrl || `/dashboard/patients`,
  };

  const handlePageError = (pageErrors: string[]) => {
    setPageErrors(pageErrors);
  };

  const handleErrorLineItems = (errorItems: { [key: string]: string[] }[]) => {
    setErrorLineItems(errorItems);
  };

  const onSubmit = async (data: ImportPatientData) => {
    const { clinicAccount, file } = data;
    const formData = new FormData();

    formData.append('accountId', clinicAccount);
    if (file) {
      formData.append('file', file);
    }

    try {
      const res = await importPatients(formData).unwrap();
      if (res) {
        setPageErrors([]);
        setErrorLineItems([]);
        toast.publish(res.message, 'success');
      }
    } catch (e) {
      const {
        data: { error, errorImportLines },
      } = e as ImportPatientError;
      if (error) {
        setPageErrors(error);
      }
      if (errorImportLines) {
        setErrorLineItems(errorImportLines);
      }
    }
  };

  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>{getPageTitle(t('patient.importPatient.title'))}</title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader
          sx={{ mb: 2 }}
          headerText={t('patient.importPatient.title')}
          backUrl={navBackUrl || `/dashboard/patients`}
        />
      </Container>

      {loadAccountsData ? (
        <Loading />
      ) : (
        <RoundedContainer>
          <ImportPatientForm
            control={control}
            setValue={setValue}
            errors={errors}
            setPageErrors={handlePageError}
            pageErrors={pageErrors}
            handleSubmit={handleSubmit(onSubmit)}
            clinicAccounts={accountData?.clinicAccounts}
            setErrorLineItems={handleErrorLineItems}
            hasErrorLineItems={errorLineItems.length > 0}
          />
        </RoundedContainer>
      )}

      {errorLineItems.length > 0 && (
        <RoundedContainer sx={{ my: 2, py: 3 }}>
          <LineErrorTable errorLineItems={errorLineItems} />
        </RoundedContainer>
      )}
    </DashboardLayout>
  );
}
