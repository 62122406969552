import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  PersistConfig,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import globalReducer from '../common/slices/globalSlice';
import authReducer from '../features/auth/authSlice';
import interviewReducer from '../features/interview/interviewSlice';
import MonitorReducer from '../features/monitor/monitorSlide';
import patientReducer from '../features/patient/patientSlice';
import reminderReducer from '../features/site/patient-reminders/ReminderSlice';
import treatmentPlanReducer from '../features/treatment-plan/treatmentPlanSlice';
import { rootApi } from './services';

// All app's reducers are put here
export const rootReducer = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
  global: globalReducer,
  auth: authReducer,
  patient: patientReducer,
  interview: interviewReducer,
  monitor: MonitorReducer,
  treatmentPlan: treatmentPlanReducer,
  reminder: reminderReducer,
});

// Persist store to local storage
const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  version: 1,
  blacklist: ['api', 'treatmentPlan'], // ignore api slice from local storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(rootApi.middleware),
});
export const persistor = persistStore(store);
export default store;

// For redux typescript
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
