import { buildAccountUrl, QueryCacheDuration, rootApi } from '..';
import { DeleteParam } from '../../../types/CommonType';
import {
  TreatmentDiagnosisAddRequest,
  TreatmentDiagnosisAddResponse,
  TreatmentDiagnosisEditRequest,
  TreatmentDiagnosisEditResponse,
  TreatmentDiagnosisListReponse,
  TreatmentDiagnosisListRequest,
  TreatmentPlanDiagnosisItem,
} from '../../../types/treatment-plan/TreatmentPlanDiagnosisType';

const api = rootApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getTreatmentDiagnosisList: builder.query<
      TreatmentDiagnosisListReponse,
      TreatmentDiagnosisListRequest
    >({
      query: (params) => ({
        url: buildAccountUrl('/v1/account/treatment-diagnosis'),
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    deleteTreatmentDiagnosis: builder.mutation<void, DeleteParam>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-diagnosis/${params.id}`),
        method: 'DELETE',
      }),
    }),

    getTreatmentDiagnosisData: builder.query<{ data: TreatmentPlanDiagnosisItem }, { id: number }>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-diagnosis/${params.id}`),
        method: 'GET',
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    addTreatmentDiagnosis: builder.mutation<
      TreatmentDiagnosisAddResponse,
      TreatmentDiagnosisAddRequest
    >({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-diagnosis`),
        method: 'POST',
        body,
      }),
    }),

    editTreatmentDiagnosis: builder.mutation<
      TreatmentDiagnosisEditResponse,
      TreatmentDiagnosisEditRequest
    >({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-diagnosis/${body.id}`),
        method: 'PUT',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTreatmentDiagnosisListQuery,
  useDeleteTreatmentDiagnosisMutation,
  useGetTreatmentDiagnosisDataQuery,
  useAddTreatmentDiagnosisMutation,
  useEditTreatmentDiagnosisMutation,
} = api;
