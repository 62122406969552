import { buildAccountUrl, QueryCacheDuration, rootApi } from '..';
import { DeleteParam } from '../../../types/CommonType';
import {
  StrategyAddRequest,
  StrategyAddResponse,
  StrategyEditRequest,
  StrategyEditResponse,
  StrategyItem,
  StrategyListReponse,
  StrategyListRequest,
} from '../../../types/treatment-plan/StrategyType';

const api = rootApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getStrategyList: builder.query<StrategyListReponse, StrategyListRequest>({
      query: (params) => ({
        url: buildAccountUrl('/v1/account/treatment-strategy'),
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    deleteStrategy: builder.mutation<void, DeleteParam>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-strategy/${params.id}`),
        method: 'DELETE',
      }),
    }),

    getStrategyData: builder.query<{ data: StrategyItem }, { id: number }>({
      query: (params) => ({
        url: buildAccountUrl(`/v1/account/treatment-strategy/${params.id}`),
        method: 'GET',
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),

    addStrategy: builder.mutation<StrategyAddResponse, StrategyAddRequest>({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-strategy`),
        method: 'POST',
        body,
      }),
    }),

    editStrategy: builder.mutation<StrategyEditResponse, StrategyEditRequest>({
      query: (body) => ({
        url: buildAccountUrl(`/v1/account/treatment-strategy/${body.id}`),
        method: 'PUT',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetStrategyListQuery,
  useLazyGetStrategyListQuery,
  useDeleteStrategyMutation,
  useGetStrategyDataQuery,
  useAddStrategyMutation,
  useEditStrategyMutation,
} = api;
