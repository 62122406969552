import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import RoundedContainer from '../../common/components/RoundedContainer';
import checkNullData from '../../common/utils/checkNullData';
import { Role } from '../../common/utils/loginRole';
import { UserCredentials } from '../../common/utils/userListUtils';
import { PhoneNumberRequired, validatePhoneNumber } from '../../common/utils/validationUtils';
import { ClinicAccountItems } from '../../types/ClinicAccountType';
import { ClinicSiteItems } from '../../types/ClinicSiteType';
import { ResponseError } from '../../types/ResponseError';
import { UserRequest, UserResponse, UserRoleItems } from '../../types/UserTypes';
import { selectUser } from '../auth/authSlice';

const StyleFormItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  height: 'auto',
  paddingBottom: '16px',
}));

interface UserFormProps {
  userId?: string;
  data?: UserResponse;
  clinicAccounts?: ClinicAccountItems[];
  clinicSites?: ClinicSiteItems[];
  userRoles?: UserRoleItems[];
  onFormSubmit: (data: UserRequest, userId?: string, successCallback?: () => void) => Promise<void>;
  onResetMfa: () => Promise<void>;
}

const defaultFormValues: UserResponse = {
  clinicAccount: '',
  userId: '',
  clinicSite: 0,
  userRole: '',
  firstName: '',
  lastName: '',
  email: '',
  userPhone: '',
  userCellPhone: '',
  idCardCode: '',
  mobilIdCode: '',
  smartIdCode: '',
  allowTrial: false,
  autoLockout: false,
  byPassSso: false,
  showManagementReport: false,
  mfaType: 2,
  nationalProviderIdentifier: '',
  externalId: '',
  ehrProviderId: '',
  userCredentials: [],
};

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '49%',
    },
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    width: '45px',
    height: '45px',
  },
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 30,
  },
}));

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  '& .MuiOutlinedInput-root': {
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default function UserForm({
  userId,
  data,
  clinicAccounts,
  clinicSites,
  userRoles,
  onFormSubmit,
  onResetMfa,
}: UserFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [isTrialInterview, setIsTrialInterview] = useState(false);
  const [isDisableSystemLogin, setIsDisableSystemLogin] = useState(false);
  const [byPassSso, setByPassSso] = useState(false);
  const [showManagementReport, setShowManagementReport] = useState(false);
  const [showManagementReportCheckbox, setShowManagementReportCheckbox] = useState(false);
  const [multiCheckSite, setMultiCheckSite] = useState<number[]>([]);
  const [selectRole, setSelectRole] = useState<string | undefined>('');

  const [clinicSiteData, setClinicSiteData] = useState<ClinicSiteItems[]>([]);
  const [isEnabledMfa, setIsEnabledMfa] = useState<boolean>(false);
  const [openSiteDrop, setOpenSiteDrop] = useState<boolean>(false);
  const [selectedClinicAccount, setSelectedClinicAccount] = useState<any>(null);
  const [openDrop, setOpenDrop] = useState<boolean>(false);
  const [multiCheckUserCredentials, setMultiCheckUserCredentials] = useState<string[]>([]);
  const [pageErrors, setPageErrors] = useState<string[]>([]);

  const currentUser = useAppSelector(selectUser);

  const validationSchema = yup
    .object()
    .shape(
      {
        clinicAccount: userId
          ? yup.string()
          : yup.string().required(t('user.manageUsers.error.blankAccount')),
        clinicSite: yup
          .mixed()
          .test(
            'validate-clinicSiteId',
            t('user.manageUsers.error.blankSite'),
            function (clinicSiteId) {
              if (Array.isArray(clinicSiteId) && clinicSiteId.length === 0) {
                return false;
              }
              if (!Array.isArray(clinicSiteId) && (clinicSiteId === 0 || clinicSiteId === null)) {
                return false;
              }
              return true;
            }
          ),
        userRole: yup.string().required(t('user.manageUsers.error.blankLoginRole')),
        firstName: yup.string().required(t('user.manageUsers.error.blankFirstName')),
        lastName: yup.string().required(t('user.manageUsers.error.blankLastName')),
        email: yup
          .string()
          .email(t('user.manageUsers.error.invalidEmail'))
          .required(t('user.manageUsers.error.blankEmail')),
        userPhone: yup.string().when('userCellPhone', {
          is: '',
          then: yup
            .string()
            .trim()
            .required(t('account.manageAccounts.error.blankPhoneNumber'))
            .test(
              'validate-phone-number',
              t('user.manageUsers.error.phoneNumberTooShort', {
                minLength: PhoneNumberRequired.MinLengthPhoneNumber,
              }),
              function (userPhone) {
                return validatePhoneNumber(userPhone);
              }
            ),
        }),
        userCellPhone: yup.string().when('userPhone', {
          is: '',
          then: yup
            .string()
            .trim()
            .required(t('account.manageAccounts.error.blankPhoneNumber'))
            .test(
              'validate-cell-phone',
              t('user.manageUsers.error.cellPhoneTooShort', {
                minLength: PhoneNumberRequired.MinLengthPhoneNumber,
              }),
              function (userCellPhone) {
                return validatePhoneNumber(userCellPhone);
              }
            ),
        }),
        mfaType: yup.number().required(t('user.editProfile.error.blankMfa')),
        allowTrialInterview: yup.mixed(),
        disableSystemLogin: yup.mixed(),
        idCardCode: yup.string(),
        mobilIdCode: yup.string(),
        smartIdCode: yup.string(),
        externalId: yup.string(),
        ehrProviderId: yup.string(),
      },
      [['userPhone', 'userCellPhone']]
    )
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    resetField,
  } = useForm<UserResponse>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const handleClick = () => {
    setOpenSiteDrop(!openSiteDrop);
  };

  const populateSite = useCallback(
    (clinicAccountId: string) => {
      const clinicSiteData =
        clinicSites?.filter((site) => site.clinicAccountId === clinicAccountId) || [];
      setClinicSiteData(clinicSiteData);

      if (clinicAccounts) {
        const checkMfaEnabled = clinicAccounts?.some((account) => {
          return account.id === clinicAccountId && account.mfaEnabled;
        });
        setIsEnabledMfa(checkMfaEnabled);
      }
    },
    [clinicAccounts, clinicSites]
  );

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getAccountOptionLabel(option),
  });

  const filterSiteOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getSiteOptionLabel(option),
  });

  const getAccountOptionLabel = (option: any) => `${option.name} (${option.id})`;
  const getSiteOptionLabel = (option: any) => `${option.name}`;

  useEffect(() => {
    if (userId && data !== undefined) {
      const bindEditControls = () => {
        Object.keys(defaultFormValues).forEach((field: any) => {
          setValue(field, checkNullData(data[field as keyof UserResponse]));
        });
        if (data.clinicAccount) {
          setSelectedClinicAccount(
            clinicAccounts ? clinicAccounts.find((item) => item?.id === data.clinicAccount) : null
          );
          populateSite(data.clinicAccount);
        }
        if (data.clinicSite) {
          const siteAssign = Array.isArray(data.clinicSite)
            ? data.clinicSite.filter((site) =>
                clinicSites?.some((siteData) => siteData.id.toString() === site.toString())
              )
            : clinicSites?.find((site) => site.id.toString() === data.clinicSite?.toString())
            ? [data.clinicSite]
            : [];
          setMultiCheckSite(siteAssign);
          if (data.userRole === Role.MultiSiteAdmin.toString()) {
            setValue('clinicSite', siteAssign);
          } else {
            setValue('clinicSite', siteAssign[0]);
          }
        }
        if (data.allowTrial) {
          setIsTrialInterview(data.allowTrial);
        }
        if (data.autoLockout) {
          setIsDisableSystemLogin(data.autoLockout);
        }
        if (data.userRole) {
          setSelectRole(data.userRole.toString());
          setShowManagementReportCheckbox(
            [Role.SuperAdmin, Role.ClinicAdmin].includes(parseInt(data.userRole))
          );
        }
        if (data.byPassSso) {
          setByPassSso(data.byPassSso);
        }
        if (data.showManagementReport) {
          setShowManagementReport(data.showManagementReport);
        }
        if (data.userCredentials?.length) {
          setMultiCheckUserCredentials(data.userCredentials);
          setValue('userCredentials', data.userCredentials);
        }
      };

      bindEditControls();
    }
    // eslint-disable-next-line
  }, [clinicSites, data, populateSite, setValue, userId]);

  useEffect(() => {
    if (!userId && currentUser.roleId !== Role.SuperAdmin && clinicAccounts) {
      setValue('clinicAccount', clinicAccounts[0].id);
      setSelectedClinicAccount(
        clinicAccounts ? clinicAccounts.find((item) => item?.id === clinicAccounts[0].id) : null
      );
      populateSite(clinicAccounts[0].id);
    }
  }, [clinicAccounts, currentUser.roleId, populateSite, setValue, userId]);

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccount', selectedValue);
    setSelectedClinicAccount(
      clinicAccounts ? clinicAccounts.find((item) => item?.id === selectedValue) : null
    );
    setValue('clinicSite', 0);
    populateSite(selectedValue);
    setMultiCheckSite([]);
  };

  const handleSiteChange = (e: any, values: any) => {
    const selectedValue = values && values.id;
    setValue('clinicSite', selectedValue);
    setMultiCheckSite([selectedValue]);
  };

  const handleIdCardCodeChange = (e: any) => {
    const value = e.target.value.replace(',', '');
    setValue('idCardCode', value);
  };

  const handleMobilIdCodeChange = (e: any) => {
    const value = e.target.value.replace(',', '');
    setValue('mobilIdCode', value);
  };

  const handleSmartIdCodeChange = (e: any) => {
    const value = e.target.value.replace(',', '');
    setValue('smartIdCode', value);
  };

  const handleChangeSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let siteIds: number[] = [];
    if (isChecked) {
      siteIds = (clinicSiteData.length !== 0 ? clinicSiteData! : clinicSites!).map(
        (site) => site.id
      );
    } else {
      siteIds = [];
    }

    setValue('clinicSite', siteIds);
    setMultiCheckSite(siteIds);
  };

  const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    let siteIds: number[] = [];
    const checkExistValue = multiCheckSite.includes(parseInt(value));
    if (!checkExistValue) {
      siteIds = [...multiCheckSite, parseInt(value)];
      setMultiCheckSite(siteIds);
    } else {
      siteIds = [...multiCheckSite.filter((item) => item !== parseInt(value))];
      setMultiCheckSite(siteIds);
    }

    setValue('clinicSite', siteIds);
  };

  const handleRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectRole(value);
    setValue('userRole', value);
    if (parseInt(value) !== Role.MultiSiteAdmin) {
      setValue('clinicSite', multiCheckSite[0]);
    } else {
      setValue('clinicSite', multiCheckSite);
    }

    // If creating new user and role is super admin
    // it should check by default
    if (!userId) {
      setShowManagementReport(parseInt(value) === Role.SuperAdmin);
    }
    setShowManagementReportCheckbox([Role.SuperAdmin, Role.ClinicAdmin].includes(parseInt(value)));
  };

  const handleResetUserMfa = async () => {
    try {
      await onResetMfa();
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const handleClickUserCredentials = () => {
    setOpenDrop(!openDrop);
  };
  const handleSelectAllUserCredentials = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let userCredentials: string[] = [];
    if (isChecked) {
      userCredentials = UserCredentials;
    } else {
      resetField('userCredentials');
    }
    setValue('userCredentials', userCredentials);
    setMultiCheckUserCredentials(userCredentials);
  };
  const handleSelectUserCredentials = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let userCredentials: string[] = [];
    const existValue = multiCheckUserCredentials.includes(value);
    if (!existValue) {
      userCredentials = [...multiCheckUserCredentials, value];
    } else {
      userCredentials = [...multiCheckUserCredentials.filter((item) => item !== value)];
    }
    setMultiCheckUserCredentials(userCredentials);
    setValue('userCredentials', userCredentials);
  };
  const userCredentialItems = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {UserCredentials.map((userCredential: string) => {
        return (
          <FormControlLabel
            key={userCredential}
            label={`${userCredential}`}
            value={userCredential}
            control={
              <StyledCheckbox
                checked={multiCheckUserCredentials.includes(userCredential)}
                onChange={handleSelectUserCredentials}
              />
            }
          />
        );
      })}
    </Box>
  );

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {(clinicSiteData && clinicSiteData.length !== 0 ? clinicSiteData : clinicSites || []).map(
        (site: ClinicSiteItems) => {
          return (
            <FormControlLabel
              key={site.id}
              label={`${site.name}`}
              value={site.id}
              control={
                <StyledCheckbox
                  checked={multiCheckSite.includes(site.id)}
                  onChange={handleChangeCheck}
                />
              }
            />
          );
        }
      )}
    </Box>
  );

  const onSubmit = async (data: UserResponse) => {
    const submitData = {
      firstName: data.firstName,
      lastName: data.lastName,
      clinicSite: data.clinicSite,
      clinicAccount: data.clinicAccount,
      email: data.email,
      userPhone: data.userPhone,
      userCellPhone: data.userCellPhone,
      allowTrial: data.allowTrial,
      byPassSso: data.byPassSso,
      showManagementReport: data.showManagementReport,
      logoutFromLogin: data.autoLockout,
      mfaType: data.mfaType,
      idCardCode: data.idCardCode ? data.idCardCode.trim() : data.idCardCode,
      mobilIdCode: data.mobilIdCode ? data.mobilIdCode.trim() : data.mobilIdCode,
      smartIdCode: data.smartIdCode ? data.smartIdCode.trim() : data.smartIdCode,
      nationalProviderIdentifier: data.nationalProviderIdentifier,
      externalId: data.externalId,
      ehrProviderId: data.ehrProviderId,
      userCredentials: data.userCredentials,
    };

    if (data.userRole) {
      Object.assign(submitData, {
        userRole: parseInt(data.userRole),
      });
    }

    try {
      await onFormSubmit(submitData, userId, () => {
        if (!userId) {
          reset();
        }
        setOpenSiteDrop(false);
        setPageErrors([]);
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        px: 0,
        pt: 0,
      }}
    >
      <RoundedContainer sx={{ paddingTop: 2, marginBottom: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            px: 0,
            py: 0,
            pb: 2,
          }}
        >
          {!!!userId && currentUser.roleId === Role.SuperAdmin && (
            <Controller
              name="clinicAccount"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  disabled={!!userId && data !== undefined && true}
                  data-testid="mui-component-select-clinic-account"
                  options={clinicAccounts || []}
                  getOptionLabel={getAccountOptionLabel}
                  onChange={handleAccountChange}
                  value={value ? clinicAccounts?.find((item) => item.id === value) || null : null}
                  filterOptions={filterAccountOptions}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {getAccountOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.clinicAccount}
                      helperText={errors.clinicAccount?.message}
                      margin="normal"
                      fullWidth
                      label={t('user.manageUsers.userForm.account')}
                    />
                  )}
                />
              )}
            />
          )}

          {selectRole && parseInt(selectRole) === Role.MultiSiteAdmin ? (
            <FormControl
              error={!!errors.clinicSite}
              sx={{ width: matches ? '100%' : '49%', height: '83px' }}
            >
              <Box
                sx={{
                  borderRadius: 2,
                  border: `1px solid ${
                    !!!errors.clinicSite ? theme.xPalette.sliver : theme.xPalette.warning
                  }`,
                  height: '-webkit-fill-available',
                  p: '5px',
                  m: '16px 0px 8px 0px',
                  position: 'relative',
                }}
              >
                <ListItemButton
                  onClick={handleClick}
                  sx={{
                    borderRadius: '8px 8px 0px 0px',
                    bottom: '2px',
                    pb: 0,
                    px: 1,
                  }}
                >
                  <ListItemText
                    sx={{
                      '& .MuiTypography-root': {
                        display: 'inline-block',
                        width: '98%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      },
                    }}
                    primary={
                      multiCheckSite.length > 0
                        ? clinicSiteData
                            .filter((site) =>
                              multiCheckSite.some(
                                (mulSite) => mulSite?.toString() === site.id?.toString()
                              )
                            )
                            .map((site) => site.name)
                            .join(', ')
                        : t('user.manageUsers.userForm.site')
                    }
                  />
                  {openSiteDrop ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={openSiteDrop}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: '52px',
                    zIndex: 99,
                    backgroundColor: 'white',
                    width: '100%',
                    border: `1px solid ${
                      !!!errors.clinicSite ? theme.xPalette.sliver : theme.xPalette.warning
                    }`,
                    maxHeight: '400px',
                    overflow: 'auto',
                    borderRadius: '0 0 10px 10px',
                  }}
                >
                  <List component="div" sx={{ m: '16px 40px' }}>
                    <Controller
                      name="clinicSite"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            {...field}
                            label={`${t('user.manageUsers.userForm.selectAllSite')}`}
                            control={
                              <StyledCheckbox
                                checked={
                                  clinicSiteData.length === multiCheckSite.length ||
                                  clinicSites?.length === multiCheckSite.length
                                }
                                indeterminate={
                                  multiCheckSite.length > 0 &&
                                  multiCheckSite.length !== clinicSiteData.length &&
                                  multiCheckSite.length !== clinicSites?.length
                                }
                                onChange={handleChangeSelectAll}
                              />
                            }
                          />
                          {parseInt(selectRole) === Role.MultiSiteAdmin &&
                            clinicSiteData.length > 0 &&
                            children}
                        </>
                      )}
                    />
                  </List>
                </Collapse>
              </Box>
              {!!errors.clinicSite && (
                <FormHelperText>
                  {errors.clinicSite && (errors.clinicSite as any).message}
                </FormHelperText>
              )}
            </FormControl>
          ) : (
            <Controller
              name="clinicSite"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  data-testid="mui-component-select-site"
                  options={
                    clinicSiteData && clinicSiteData.length !== 0
                      ? clinicSiteData
                      : userId
                      ? clinicSiteData
                      : clinicSites || []
                  }
                  getOptionLabel={getSiteOptionLabel}
                  onChange={handleSiteChange}
                  value={
                    value
                      ? (clinicSiteData.length !== 0 ? clinicSiteData! : clinicSites!)?.find(
                          (item) => item.id === value
                        ) || null
                      : null
                  }
                  filterOptions={filterSiteOptions}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.clinicSite}
                      helperText={errors.clinicSite && (errors.clinicSite as any).message}
                      margin="normal"
                      fullWidth
                      label={t('user.manageUsers.userForm.site')}
                    />
                  )}
                />
              )}
            />
          )}

          <Controller
            name="userRole"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                margin="normal"
                select
                fullWidth
                label={t('user.manageUsers.userForm.role')}
                error={!!errors.userRole}
                helperText={errors.userRole?.message}
                variant="outlined"
                onChange={handleRoleChange}
              >
                {userRoles?.map((role: UserRoleItems) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.prettyName}
                  </MenuItem>
                ))}
              </StyleTextField>
            )}
          />

          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                margin="normal"
                fullWidth
                label={t('user.manageUsers.userForm.firstName')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                margin="normal"
                fullWidth
                label={t('user.manageUsers.userForm.lastName')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.email}
                helperText={errors.email?.message}
                margin="normal"
                fullWidth
                label={t('user.manageUsers.userForm.email')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="userPhone"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.userPhone}
                helperText={errors.userPhone?.message}
                margin="normal"
                fullWidth
                label={t('user.manageUsers.userForm.phone')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="userCellPhone"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.userCellPhone}
                helperText={errors.userCellPhone?.message}
                margin="normal"
                fullWidth
                label={t('user.manageUsers.userForm.cellPhone')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="nationalProviderIdentifier"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.nationalProviderIdentifier}
                helperText={errors.nationalProviderIdentifier?.message}
                margin="normal"
                fullWidth
                label={t('user.manageUsers.userForm.nationalProviderIdentifier')}
                variant="outlined"
              />
            )}
          />
          <FormControl
            error={!!errors.userCredentials}
            sx={{ width: matches ? '100%' : '49%', height: '83px' }}
          >
            <Box
              sx={{
                borderRadius: 2,
                border: `1px solid ${
                  !!!errors.userCredentials ? theme.xPalette.sliver : theme.xPalette.warning
                }`,
                height: '-webkit-fill-available',
                p: '5px',
                m: '16px 0px 8px 0px',
                position: 'relative',
                width: '100%',
              }}
            >
              <ListItemButton
                onClick={handleClickUserCredentials}
                sx={{
                  borderRadius: '8px 8px 0px 0px',
                  bottom: '2px',
                  pb: 0,
                  px: 1,
                }}
              >
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      display: 'inline-block',
                      width: '98%',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    },
                  }}
                  primary={
                    multiCheckUserCredentials.length
                      ? UserCredentials.filter((userCredential) =>
                          multiCheckUserCredentials.some(
                            (multiUserCredential) => multiUserCredential === userCredential
                          )
                        )
                          .map((userCredential) => `${userCredential}`)
                          .join(', ')
                      : t('user.editProfile.form.userCredentials')
                  }
                />
                {openDrop ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={openDrop}
                timeout="auto"
                unmountOnExit
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: '52px',
                  zIndex: 99,
                  backgroundColor: 'white',
                  width: '100%',
                  border: `1px solid ${
                    !!!errors.userCredentials ? theme.xPalette.sliver : theme.xPalette.warning
                  }`,
                  maxHeight: '400px',
                  overflow: 'auto',
                  borderRadius: '0 0 10px 10px',
                }}
              >
                <List component="div" sx={{ m: '16px 40px' }}>
                  <Controller
                    name="userCredentials"
                    control={control}
                    render={({ field }) => (
                      <>
                        <FormControlLabel
                          {...field}
                          label={`${t('user.editProfile.form.allUserCredentials')}`}
                          control={
                            <StyledCheckbox
                              checked={UserCredentials.length === multiCheckUserCredentials.length}
                              indeterminate={
                                multiCheckUserCredentials.length > 0 &&
                                multiCheckUserCredentials.length !== UserCredentials.length
                              }
                              onChange={handleSelectAllUserCredentials}
                            />
                          }
                        />
                        {userCredentialItems}
                      </>
                    )}
                  />
                </List>
              </Collapse>
            </Box>
          </FormControl>
        </Box>
      </RoundedContainer>
      <RoundedContainer sx={{ paddingTop: 2, marginBottom: 2 }}>
        <Typography
          sx={{ color: 'rgba(0, 0, 0, 0.5)', marginBottom: 1, paddingLeft: 0, paddingTop: 1 }}
        >
          {t('user.manageUsers.userForm.ehrSettings')}
        </Typography>
        <Box>
          <StyleFormItem>
            <Controller
              name="externalId"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.externalId}
                  helperText={errors.externalId?.message}
                  margin="normal"
                  fullWidth
                  disabled={!selectedClinicAccount?.ehrEnabled}
                  label={t('user.manageUsers.userForm.externalId')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="ehrProviderId"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.ehrProviderId}
                  helperText={errors.ehrProviderId?.message}
                  margin="normal"
                  fullWidth
                  disabled={!selectedClinicAccount?.ehrEnabled}
                  label={t('user.manageUsers.userForm.ehrProviderId')}
                  variant="outlined"
                />
              )}
            />
          </StyleFormItem>
        </Box>
      </RoundedContainer>
      <RoundedContainer sx={{ paddingTop: 2, marginBottom: 2 }}>
        <Typography
          sx={{ color: 'rgba(0, 0, 0, 0.5)', marginBottom: 1, paddingLeft: 0, paddingTop: 1 }}
        >
          {t('user.manageUsers.userForm.taraSettings')}
        </Typography>
        <Box>
          <StyleFormItem>
            <Controller
              name="idCardCode"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.idCardCode}
                  helperText={errors.idCardCode?.message}
                  margin="normal"
                  fullWidth
                  disabled={!selectedClinicAccount?.taraEnabled}
                  label={t('user.manageUsers.userForm.idCardCode')}
                  variant="outlined"
                  onChange={handleIdCardCodeChange}
                />
              )}
            />
            <Controller
              name="mobilIdCode"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.mobilIdCode}
                  helperText={errors.mobilIdCode?.message}
                  margin="normal"
                  fullWidth
                  disabled={!selectedClinicAccount?.taraEnabled}
                  label={t('user.manageUsers.userForm.mobilIdCode')}
                  variant="outlined"
                  onChange={handleMobilIdCodeChange}
                />
              )}
            />
            <Controller
              name="smartIdCode"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.smartIdCode}
                  helperText={errors.smartIdCode?.message}
                  margin="normal"
                  fullWidth
                  disabled={!selectedClinicAccount?.taraEnabled}
                  label={t('user.manageUsers.userForm.smartIdCode')}
                  variant="outlined"
                  onChange={handleSmartIdCodeChange}
                />
              )}
            />
          </StyleFormItem>
        </Box>
      </RoundedContainer>
      <RoundedContainer sx={{ paddingTop: 2, marginBottom: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            height: 'auto',
            px: 0,
            pt: 0,
            pb: 2,
          }}
        >
          <Box
            sx={{
              width: matches ? '100%' : '49%',
            }}
          >
            {(isEnabledMfa || data?.mfaEnabled) && (
              <div>
                <Typography fontWeight="bold" variant="body1">
                  {t('user.editProfile.form.multiFactor')}
                </Typography>
                <Controller
                  name="mfaType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} row={!matches}>
                      <FormControlLabel
                        value="2"
                        labelPlacement="end"
                        control={<Radio />}
                        label={`${t('user.editProfile.form.cellPhone')}`}
                      />
                      <FormControlLabel
                        value="1"
                        labelPlacement="end"
                        control={<Radio />}
                        label={`${t('user.editProfile.form.email')}`}
                      />
                    </RadioGroup>
                  )}
                />
              </div>
            )}
            {(currentUser.roleId === Role.SuperAdmin ||
              currentUser.roleId === Role.ClinicAdmin ||
              currentUser.roleId === Role.MultiUserProvider) && (
              <Controller
                name="autoLockout"
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={isDisableSystemLogin}
                      value={isDisableSystemLogin}
                      onChange={(event: any) => {
                        setIsDisableSystemLogin(event.target.checked);
                      }}
                      label={`${t('user.manageUsers.userForm.disabledSystemLogin')}`}
                    />
                  </FormGroup>
                )}
              />
            )}
            {currentUser.roleId === Role.SuperAdmin && (
              <Controller
                name="allowTrial"
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={isTrialInterview}
                      value={isTrialInterview}
                      onChange={(event: any) => {
                        setIsTrialInterview(event.target.checked);
                      }}
                      label={`${t('user.manageUsers.userForm.enableTrialInterview')}`}
                    />
                  </FormGroup>
                )}
              />
            )}
          </Box>
          <Box
            sx={{
              width: matches ? '100%' : '49%',
            }}
          >
            {(currentUser.roleId === Role.SuperAdmin ||
              currentUser.roleId === Role.ClinicAdmin) && (
              <Controller
                name="byPassSso"
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={byPassSso}
                      value={byPassSso}
                      onChange={(event: any) => {
                        setByPassSso(event.target.checked);
                      }}
                      label={`${t('user.manageUsers.userForm.byPassSso')}`}
                    />
                  </FormGroup>
                )}
              />
            )}
            {showManagementReportCheckbox &&
              (currentUser.roleId === Role.SuperAdmin ||
                currentUser.roleId === Role.ClinicAdmin) && (
                <Controller
                  name="showManagementReport"
                  control={control}
                  render={({ field }) => (
                    <FormGroup {...field}>
                      <FormControlLabel
                        control={<StyledCheckbox />}
                        checked={showManagementReport}
                        value={showManagementReport}
                        onChange={(event: any) => {
                          setShowManagementReport(event.target.checked);
                        }}
                        label={`${t('user.manageUsers.userForm.showManagementReport')}`}
                      />
                    </FormGroup>
                  )}
                />
              )}
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            float: 'right',
            pb: 3,
          }}
        >
          {!matches && <Divider sx={{ py: 1 }} />}
          {data?.mfaEnabled && (
            <Link
              sx={{ fontSize: 16, mt: 2 }}
              type="button"
              component="button"
              variant="body1"
              onClick={() => handleResetUserMfa()}
            >
              {t('user.manageUsers.userForm.resetMfa')}
            </Link>
          )}
          {pageErrors?.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            ))}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              width: matches ? '100%' : '24%',
              my: 2,
              py: 2,
              float: 'right',
            }}
          >
            {userId
              ? t('user.manageUsers.userForm.updateUser')
              : t('user.manageUsers.userForm.createUser')}
          </Button>
        </Box>
      </RoundedContainer>
    </Box>
  );
}
