import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { MeasurementPacket } from '../../types/InterviewRequest';
import { MeasurementPacketType } from '../../types/PatientType';

export const getQuestionControlName = (questionId: number | string) => `question_${questionId}`;

export const ClinicianNoteField = 'clinicianNote';

export const InterviewReportType = {
  MINI: 'mini',
  Screener: 'miniScreener',
  SdsSts: 'sdsSts',
  YBOCS: 'ybocs',
  CYBOCS: 'cybocs',
  PHQ: 'phq',
  Monitor: 'tracker',
};

export const InterviewAction = {
  NavigateBack: 'navigateBack',
};

export const StyledClinicianNote = styled(TextField)(({ theme, ...props }) => ({
  margin: '16px 0',
  backgroundColor: theme.xPalette.noteLightGrey,
  borderRadius: '12px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&:hover fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&.Mui-disabled fieldset': {
      borderColor: theme.xPalette.white,
    },
  },
}));

export const getFirstInterviewInPackets = (
  measurementPackets: MeasurementPacket[]
): MeasurementPacket | null => {
  if (measurementPackets?.length) {
    return (
      measurementPackets.find((measurementPacket) => Boolean(measurementPacket.interviewId)) || null
    );
  }
  return null;
};

export const getFirstMonitorInPackets = (
  measurementPackets: MeasurementPacket[]
): MeasurementPacket | null => {
  if (measurementPackets?.length) {
    return (
      measurementPackets.find((measurementPacket) => Boolean(measurementPacket.monitorId)) || null
    );
  }
  return null;
};

export const getRequestData = (
  measurementPackets: MeasurementPacket[]
): { interviewRequestData: any; monitorRequestData: any } => {
  let interviewRequestData = null;
  let monitorRequestData = null;

  const firstInterview = getFirstInterviewInPackets(measurementPackets);
  if (firstInterview) {
    interviewRequestData = {
      interviewId: firstInterview?.interviewId ? firstInterview.interviewId : 0,
      interviewTestId: null,
      interviewTimeFrame: firstInterview.interviewTimeFrame,
      language: firstInterview.language,
      trialInterview: firstInterview.trialInterview,
      endDate: firstInterview.endDate,
      providerId: firstInterview.providerId || 0,
      measurementPacketType: MeasurementPacketType.MeasurementPacket,
      measurementPackets,
    };
  } else {
    const firstMonitor = getFirstMonitorInPackets(measurementPackets);
    if (firstMonitor) {
      monitorRequestData = {
        monitorId: firstMonitor?.monitorId ? firstMonitor.monitorId : 0,
        language: firstMonitor.language,
        providerId: firstMonitor.providerId || 0,
        endDate: firstMonitor.endDate,
        measurementPacketType: MeasurementPacketType.MeasurementPacket,
        measurementPackets,
      };
    }
  }
  return { interviewRequestData, monitorRequestData };
};
