import { SyntheticEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import { useGetClinicAccountsQuery, useLazyRawDataDownloadQuery } from '../../app/services/account';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import SubmitButton from '../../common/components/SubmitButton';
import DashboardLayout from '../../common/layouts/Dashboard';
import { formatDate } from '../../common/utils/dateTimeUtils';
import { InterviewDownloadTypes, MonitorDownloadTypes } from '../../common/utils/exportUtils';
import { getPageTitle } from '../../common/utils/pageUtils';
import { saveReportDataToFile } from '../../common/utils/reportUtils';
import { ClinicAccountItems } from '../../types/ClinicAccountType';
import { ExportTypeValues } from '../../types/ExportTypes';
import { PageProps } from '../../types/PageProps';
import { selectUser } from '../auth/authSlice';

const defaultFormValues: ExportTypeValues = {
  clinicAccountId: '',
  startDate: new Date().toString(),
  endDate: new Date().toString(),
  exportType: 'interview',
  downloadType: 'incompleteInterview',
  clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '49%',
    },
  },
}));

const ExportTypes: { [key: string]: string } = {
  interview: 'Interview',
  monitor: 'Monitor',
};

const RawDataExportFileName = {
  completedInterview: 'RawDataExport-CompletedAnswers.csv',
  scoredInterview: 'RawDataExport-CompletedScores.csv',
  incompleteInterview: 'RawDataExport-IncompleteAnswers.csv',
  completedMonitor: 'RawDataExport-MonitorAnswers.csv',
};

export default function RawDataExportPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const currentUser = useAppSelector(selectUser);
  const [clinicAccounts, setClinicAccounts] = useState<ClinicAccountItems[]>([]);
  const [selectedExportType, setSelectedExportType] = useState<string>('interview');
  const [disabledDownloadType, setDisabledDownloadType] = useState<boolean>(false);

  const [trigger, { data: rawData, error: exportError }] = useLazyRawDataDownloadQuery();

  const { data, isLoading } = useGetClinicAccountsQuery();

  const errorMessages = exportError
    ? JSON.parse(((exportError as FetchBaseQueryError).data as any).csvDataStream).error
    : [];

  const validationSchema = yup
    .object({
      clinicAccountId: yup.string().required(t('user.manageUsers.error.blankAccount')),
      startDate: yup.string().nullable().required(t('exportData.error.blankStartDate')),
      endDate: yup.string().nullable().required(t('exportData.error.blankEndDate')),
      exportType: yup.string().required(t('exportData.error.blankExportType')),
      downloadType: yup.string().required(t('exportData.error.blankDownloadType')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<ExportTypeValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getAccountOptionLabel(option),
  });

  const getAccountOptionLabel = (option: any) => `${option.name} (${option.id})`;

  const handleAccountChange = (e: SyntheticEvent<Element, Event>, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccountId', selectedValue);
  };

  useEffect(() => {
    if (data) {
      setValue('clinicAccountId', currentUser.clinicAccountId);
      setClinicAccounts(data.clinicAccounts);
    }
  }, [currentUser.clinicAccountId, data, setValue]);

  useEffect(() => {
    if (rawData) {
      saveReportDataToFile(
        rawData.csvDataStream,
        { type: 'text/csv' },
        RawDataExportFileName[getValues('downloadType')]
      );
    }
  }, [getValues, rawData]);

  const onSubmit = async (data: ExportTypeValues) => {
    data.startDate = formatDate(data.startDate);
    data.endDate = formatDate(data.endDate);
    trigger(data);
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('exportData.raw.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <PageHeader
            sx={{ mt: 1, mb: 2 }}
            headerText={t('exportData.raw.title')}
            backUrl={'/dashboard'}
          />
        </Container>
        {isLoading ? (
          <Loading />
        ) : (
          <RoundedContainer
            sx={{
              py: 1,
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <Controller
                  name="clinicAccountId"
                  control={control}
                  render={({ fieldState, formState, field: { value }, ...props }) => (
                    <Autocomplete
                      {...props}
                      fullWidth
                      data-testid="mui-component-select-clinic-account"
                      options={clinicAccounts || []}
                      getOptionLabel={getAccountOptionLabel}
                      onChange={handleAccountChange}
                      value={
                        value ? clinicAccounts?.find((item) => item.id === value) || null : null
                      }
                      filterOptions={filterAccountOptions}
                      renderOption={(props, option: any) => {
                        return (
                          <li {...props} key={option.id} value={option.id}>
                            {getAccountOptionLabel(option)}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.clinicAccountId}
                          helperText={errors.clinicAccountId?.message}
                          margin="normal"
                          fullWidth
                          label={t('exportData.raw.form.clinicAccount')}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  name="exportType"
                  control={control}
                  render={({ field }) => (
                    <StyleTextField
                      {...field}
                      margin="normal"
                      select
                      fullWidth
                      label={t('exportData.raw.form.exportType')}
                      error={!!errors.exportType}
                      helperText={errors.exportType?.message}
                      variant="outlined"
                      value={selectedExportType}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        setValue('exportType', value);
                        setValue('downloadType', Object.keys(InterviewDownloadTypes)[0]);
                        setDisabledDownloadType(false);
                        if (value === 'monitor') {
                          setValue('downloadType', Object.keys(MonitorDownloadTypes)[0]);
                          setDisabledDownloadType(true);
                        }
                        setSelectedExportType(value);
                      }}
                    >
                      {Object.keys(ExportTypes).map((type: string) => (
                        <MenuItem key={type} value={type}>
                          {ExportTypes[type]}
                        </MenuItem>
                      ))}
                    </StyleTextField>
                  )}
                />

                <Controller
                  name="downloadType"
                  control={control}
                  render={({ field }) => (
                    <StyleTextField
                      {...field}
                      disabled={disabledDownloadType}
                      margin="normal"
                      select
                      fullWidth
                      label={t('exportData.raw.form.downloadType')}
                      error={!!errors.downloadType}
                      helperText={errors.downloadType?.message}
                      variant="outlined"
                    >
                      {selectedExportType === 'monitor' ? (
                        <MenuItem
                          key={Object.keys(MonitorDownloadTypes)[0]}
                          value={Object.keys(MonitorDownloadTypes)[0]}
                        >
                          {MonitorDownloadTypes.completedMonitor}
                        </MenuItem>
                      ) : (
                        Object.keys(InterviewDownloadTypes).map((type: string) => (
                          <MenuItem key={type} value={type}>
                            {InterviewDownloadTypes[type]}
                          </MenuItem>
                        ))
                      )}
                    </StyleTextField>
                  )}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '49%',
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                    }}
                  >
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          label={t('exportData.raw.form.startDate')}
                          inputFormat="MM/DD/YYYY"
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              data-testid="mui-component-start-date"
                              sx={{
                                marginTop: '0.5rem',
                                width: '100%',
                              }}
                              error={!!errors.startDate}
                              helperText={errors.startDate?.message}
                              margin="normal"
                              variant="outlined"
                              aria-describedby="start-date-helper-text"
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '49%',
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                    }}
                  >
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          label={t('exportData.raw.form.endDate')}
                          inputFormat="MM/DD/YYYY"
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              data-testid="mui-component-from-date"
                              sx={{
                                marginTop: '0.5rem',
                                width: '100%',
                              }}
                              error={!!errors.endDate}
                              helperText={errors.endDate?.message}
                              margin="normal"
                              variant="outlined"
                              aria-describedby="end-date-helper-text"
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
              <SubmitButton
                content={t('exportData.raw.submit')}
                matches={matches}
                pageErrors={errorMessages}
              />
            </Box>
          </RoundedContainer>
        )}
      </Container>
    </DashboardLayout>
  );
}
