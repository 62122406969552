import { ReactComponent as ReactivatePatientIconSvg } from '../../../assets/icons/reactivate-patient.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

export default function ReactivatePatientIcon() {
  const theme = useTheme();
  return (
    <SvgIcon
      component={ReactivatePatientIconSvg}
      sx={{ '& path': { fill: 'white', stroke: theme.xPalette.shadeGrey } }}
    />
  );
}
