import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import { InterviewReportType } from '../../../interview/interviewUtils';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function FormulaAnswer({
  questionAnswer: formulaQuestionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const isYBocsCYBocs =
    moduleType === InterviewReportType.YBOCS || moduleType === InterviewReportType.CYBOCS;
  const isPHQ =
    moduleType === InterviewReportType.Screener && subModuleType === InterviewReportType.PHQ;
  const { questionDisplay, questionTitle } = useCustomQuestionInfo({
    initialState: formulaQuestionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <Box
      key={formulaQuestionAnswer.questionId}
      sx={{
        display: 'flex',
        width: '100%',
        pageBreakInside: 'avoid',
        alignItems: 'center',
        columnGap: 1,
        my: 3,
      }}
    >
      <Box
        sx={{
          flex: isYBocsCYBocs ? 3 : 0.7,
          display: 'flex',
          justifyContent: isYBocsCYBocs ? 'flex-end' : 'flex-start',
          columnGap: 1,
        }}
      >
        {!isPHQ && (
          <>
            <Typography
              component={'span'}
              fontWeight={'bold'}
              dangerouslySetInnerHTML={{
                __html: questionTitle,
              }}
            ></Typography>
            {!!formulaQuestionAnswer.subTitle && (
              <Typography
                component={'span'}
                fontWeight={'bold'}
                dangerouslySetInnerHTML={{
                  __html: formulaQuestionAnswer.subTitle,
                }}
              ></Typography>
            )}
          </>
        )}
      </Box>
      {!isYBocsCYBocs && (
        <Box sx={{ flex: 3, display: 'flex', justifyContent: 'flex-start' }}>
          <Typography
            fontWeight={'bold'}
            dangerouslySetInnerHTML={{
              __html: questionDisplay,
            }}
          ></Typography>
        </Box>
      )}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          sx={{
            py: 1,
            px: 2,
            border: `1px solid ${theme.xPalette.black}`,
            fontWeight: 'bold',
          }}
        >
          {formulaQuestionAnswer.questionAnswer || '0'}
        </Typography>
      </Box>
    </Box>
  );
}
