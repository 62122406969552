import { ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { useAppSelector } from '../../app/hooks';
import { useGetApiKeyQuery, useGetSupportUrlQuery } from '../../app/services/account';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getPageTitle } from '../../common/utils/pageUtils';
import { canViewApiKey } from '../../common/utils/permissionUtils';
import { PageProps } from '../../types/PageProps';
import { selectUser } from '../auth/authSlice';

const StyleTypography = styled(Typography)(() => ({
  fontSize: 14,
  padding: '10px 8px 10px 8px',
}));

const StyleCardHeader = styled(CardHeader)(({ theme }) => ({
  borderBottom: `1px solid ${theme.xPalette.disabled}`,
  '&.MuiCardHeader-root .MuiCardHeader-content .MuiTypography-root': {
    fontSize: 16,
    fontWeight: 'bold',
    padding: '0 8px 0 8px',
  },
}));

const StyleCardContent = styled(CardContent)(({ theme }) => ({
  padding: '8px 16px 0 16px',
  display: 'flex',
  flexDirection: 'column',
  '&.MuiCardContent-root:last-child': {
    paddingBottom: 0,
  },
  '&.MuiCardContent-root .MuiBox-root:nth-of-type(2n+1)': {
    backgroundColor: theme.xPalette.tableAltLine,
  },
}));

const StyleCard = styled(Card)(() => ({
  minWidth: '100%',
  borderRadius: 16,
}));

const StyleBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
}));

interface DocumentsType {
  name: string;
  keyForUrl: string;
  urlType: 'video' | 'document';
}

const softwareTraining: DocumentsType[] = [
  {
    name: 'Proem User Guide',
    keyForUrl: 'documents/Proem User Guide v3.pdf',
    urlType: 'document',
  },
  {
    name: 'API Documentation',
    keyForUrl: 'documents/nview_api_documentation_latest.pdf',
    urlType: 'document',
  },
  { name: 'FAQs', keyForUrl: 'documents/Proem FAQs 3.0.pdf', urlType: 'document' },
];

const trainingVideos: DocumentsType[] = [
  {
    name: 'Login and Reset Password',
    keyForUrl: 'videos/Resetting Password v2.mp4',
    urlType: 'video',
  },
  {
    name: 'Navigation in Proem',
    keyForUrl: 'videos/Navigation Bar v2.mp4',
    urlType: 'video',
  },
  {
    name: 'Patient Dashboard',
    keyForUrl: 'videos/Accessing the Patient Dashboard v2.mp4',
    urlType: 'video',
  },
  {
    name: 'How to Start an Interview',
    keyForUrl: 'videos/Beginning an Interview v2.mp4',
    urlType: 'video',
  },
  {
    name: 'Clinical Follow Ups',
    keyForUrl: 'videos/Clinical Follow ups v2.mp4',
    urlType: 'video',
  },
  {
    name: 'How to Start a Monitor',
    keyForUrl: 'videos/Starting a Monitor v2.mp4',
    urlType: 'video',
  },
  {
    name: 'Suicide Alerts',
    keyForUrl: 'videos/Suicide Alerts v2.mp4',
    urlType: 'video',
  },
];

enum TrainingType {
  Document = 'document',
  Video = 'video',
}

const customTypography = (contactType: 'tel' | 'mailto', supportInfo: string, theme: Theme) => (
  <Typography
    sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', mr: 1 }}
    component="a"
    href={`${contactType}:${supportInfo}`}
    color={theme.palette.primary.main}
  >
    {supportInfo}
  </Typography>
);

export default function SupportPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectUser);
  const theme = useTheme();
  const navigate = useNavigate();
  const [keyForUrl, setKeyForUrl] = useState<string>('page');
  const [urlType, setUrlType] = useState<string>('');

  const { data: supportData } = useGetSupportUrlQuery(
    keyForUrl
      ? {
          key: keyForUrl,
        }
      : skipToken
  );

  const { data: apiKeyData } = useGetApiKeyQuery();

  useEffect(() => {
    if (supportData && supportData.url !== '' && urlType !== '') {
      if (urlType === TrainingType.Video) {
        navigate('/dashboard/support/video', {
          state: { supportVideo: supportData.url },
        });
      } else {
        window.open(supportData.url);
      }

      setKeyForUrl('');
      setUrlType('');
    }
  }, [supportData, navigate, urlType]);

  const contentItem = (title: string, key?: string, type?: string) => {
    return (
      <>
        <StyleBox
          key={title}
          sx={{
            py: 2,
            pl: 2,
            rowGap: 1,
            flexDirection:
              title === 'API Documentation' && canViewApiKey(currentUser.roleId) ? 'column' : 'row',
          }}
        >
          <Link
            textAlign="left"
            component="button"
            variant="subtitle1"
            onClick={() => {
              setKeyForUrl(key || '');
              setUrlType(type || '');
            }}
          >
            {title}
          </Link>
          {title === 'API Documentation' && canViewApiKey(currentUser.roleId) && (
            <Typography variant="subtitle1">
              {`${t('support.apiKey')}: ${apiKeyData?.apiKey}`}
            </Typography>
          )}
        </StyleBox>
      </>
    );
  };

  const supportInfoContent = () => {
    return (
      <>
        <StyleBox>
          <StyleTypography sx={{ flexGrow: 1 }} color="text.secondary">
            {t('support.contactInfo.phone')}
          </StyleTypography>
          {customTypography('tel', t('support.contactInfo.phoneInfo'), theme)}
        </StyleBox>
        <StyleBox>
          <StyleTypography sx={{ flexGrow: 1 }} color="text.secondary">
            {t('support.contactInfo.email')}
          </StyleTypography>
          {customTypography('mailto', t('support.contactInfo.emailInfo'), theme)}
        </StyleBox>
      </>
    );
  };

  const trainingLinks = (trainingItems: DocumentsType[]) => {
    return trainingItems.map((item: DocumentsType) =>
      contentItem(item.name, item.keyForUrl, item.urlType)
    );
  };

  const renderCardHeader = (title: string, contentItem: ReactNode): ReactNode => {
    return (
      <StyleCard>
        <StyleCardHeader title={title || ''} />
        <StyleCardContent>{contentItem}</StyleCardContent>
      </StyleCard>
    );
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('support.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <PageHeader sx={{ mb: 2 }} headerText={t('support.title')} backUrl={'/dashboard'} />
        </Container>
        <RoundedContainer sx={{ py: 3, mb: 2, backgroundColor: theme.xPalette.background }}>
          {renderCardHeader(t('support.contactInfo.title'), supportInfoContent())}
        </RoundedContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
          {renderCardHeader(t('support.training.software'), trainingLinks(softwareTraining))}
          {renderCardHeader(t('support.training.videos'), trainingLinks(trainingVideos))}
        </Box>
      </Container>
    </DashboardLayout>
  );
}
