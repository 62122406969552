import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { newlinesToBr } from '../../../common/utils/reportUtils';

interface ReportNotesType {
  reportNotes?: any;
  questionId?: number;
}

export default function ReportNotes({ reportNotes }: ReportNotesType) {
  const { t } = useTranslation();

  return (
    <Box sx={{ my: 2 }}>
      {reportNotes &&
        reportNotes.length > 0 &&
        reportNotes.map((note: any) => (
          <Box key={note.id}>
            {note.version ? (
              <>
                <Typography component="span" variant="body1" fontWeight={'bold'}>
                  {t('patientNote.clinicianNote')}:
                </Typography>
                <Typography component="span" variant="body1" fontStyle={'italic'} fontWeight={500}>
                  {` ${t('patientNote.noteVersion', { version: note.version })} ${
                    note.interviewerName ||
                    note.interviewer.lastName + ', ' + note.interviewer.firstName
                  }`}
                </Typography>
                <Typography
                  sx={{ marginLeft: '2rem' }}
                  variant="body1"
                  fontWeight={500}
                  dangerouslySetInnerHTML={{
                    __html: newlinesToBr(note?.noteContent),
                  }}
                />
              </>
            ) : (
              <>
                <Typography variant="body1" fontWeight={'bold'}>
                  {t('patientNote.clinicianNotes')}
                </Typography>
                <Typography
                  sx={{ marginLeft: '2rem' }}
                  fontWeight={500}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: newlinesToBr(note?.noteContent),
                  }}
                />
              </>
            )}
          </Box>
        ))}
    </Box>
  );
}
