const shouldLog = () => process.env.REACT_APP_ENVIRONMENT_TYPE !== 'production';
const enrichLog = (data: any[]) => [`[nView] - ${new Date().toISOString()} - `, ...data];

export const logger = {
  debug: (...data: any[]) => {
    if (shouldLog()) {
      console.debug.apply(null, enrichLog(data));
    }
  },
  info: (...data: any[]) => console.info.apply(null, enrichLog(data)),
  error: (...data: any[]) => console.error.apply(null, enrichLog(data)),
  warn: (...data: any[]) => console.warn.apply(null, enrichLog(data)),
};
