import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useGetLoginMethodsQuery } from '../../app/services/identityProvider';
import PopupModal from '../../common/components/PopupModal';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import checkNullData from '../../common/utils/checkNullData';
import { Protocol } from '../../common/utils/protocol';
import { RegexValidation } from '../../common/utils/validationUtils';
import { PopupContent } from '../../types/PopupType';
import { ResponseError } from '../../types/ResponseError';
import { IdentityProviderItem } from '../../types/SsoType';

interface SsoClientFormProps {
  identityProviderId?: number;
  identityProviderDetail?: IdentityProviderItem;
  onFormSubmit: (
    identityProviderDetail: IdentityProviderItem,
    identityProviderId?: number,
    successCallback?: () => void
  ) => Promise<void>;
}

const defaultFormValues: IdentityProviderItem = {
  name: '',
  description: '',
  status: 'inactive',
  loginMethodId: 1,
  clientId: '',
  ehrType: '',
  //
  clientSecret: '',
  baseUrl: '',
  scope: '',
  getProfileUri: '',
  locale: 'en',
  authorizeUri: '',
  getTokenUri: '',
  //
  entityId: '',
  logoutUrl: '',
  postUrl: '',
  idpInitiatedUrl: '',
  cert: '',
  userAttribute: '',
  subAttribute: '',
  digestAlgorithm: 'sha256',
};

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '49%',
    },
  },
}));
const StyleTextArea = styled(TextField)(({ theme }) => ({
  width: '100%',
}));

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const statusData = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'InActive' },
];

const digestAlgorithmOptions = [
  { id: 'sha1', name: 'sha1' },
  { id: 'sha256', name: 'sha256' },
  { id: 'sha512', name: 'sha512' },
];

const ehrTypes = [
  { id: 'athena', name: 'Athena' },
  { id: 'epic', name: 'Epic' },
  { id: 'cerner', name: 'Cerner' },
  { id: 'meditech', name: 'Meditech' },
  { id: 'allscripts', name: 'Allscripts' },
  { id: 'eclinicalworks', name: 'eClinicalWorks' },
];

export default function AddOrEditForm({
  identityProviderId,
  identityProviderDetail,
  onFormSubmit,
}: SsoClientFormProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const contentPopup = useAppSelector(selectContentPopup);
  const isOpenPopup = useAppSelector(selectIsOpenPopup);

  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [loginMethod, setLoginMethod] = useState<number>(1);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const { data: dataLoginMethods } = useGetLoginMethodsQuery();
  const [usedEHRType, setUsedEHRType] = useState<boolean>(false);
  const [isUsed, setIsUsed] = useState<boolean>(false);
  const localesData = [{ id: 'en', name: 'En' }];

  const ehrTypeContentPopup = {
    title: t('ssoClient.manageSsoClient.ehrTypePopup.title'),
    description: t('ssoClient.manageSsoClient.ehrTypePopup.description'),
    btnOk: t('ssoClient.manageSsoClient.ehrTypePopup.btnOk'),
    btnClose: t('ssoClient.manageSsoClient.ehrTypePopup.btnClose'),
  } as PopupContent;

  const validationSchema = yup
    .object({
      loginMethodId: yup
        .mixed()
        .test(
          'validate-loginMethodId',
          t('ssoClient.manageSsoClient.error.blankLoginMethod'),
          function (loginMethodId) {
            if (loginMethodId === 0 || loginMethodId === null) {
              return false;
            }
            return true;
          }
        ),
      name: yup.string().required(t('ssoClient.manageSsoClient.error.blankName')),
      description: yup.string().required(t('ssoClient.manageSsoClient.error.blankDescription')),
      status: yup.string().required(t('ssoClient.manageSsoClient.error.blankStatus')),
      clientId: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.OpenId || loginMethodId === Protocol.Oauth2;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankClientId')),
      }),
      ehrType: yup.string(),
      // openId and Oauth2 and Saml
      locale: yup.string().required(t('ssoClient.manageSsoClient.error.blankLocale')),
      // Only for openId and Oauth2
      clientSecret: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.OpenId || loginMethodId === Protocol.Oauth2;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankClientSecret')),
      }),
      baseUrl: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.OpenId || loginMethodId === Protocol.Oauth2;
        },
        then: yup
          .string()
          .required(t('ssoClient.manageSsoClient.error.blankBaseUrl'))
          .when('baseUrl', {
            is: (baseUrl: string) => {
              return baseUrl && baseUrl.length > 0;
            },
            then: yup
              .string()
              .matches(RegexValidation.URL, t('ssoClient.manageSsoClient.error.baseURLInvalid')),
          }),
      }),
      scope: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.OpenId || loginMethodId === Protocol.Oauth2;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankScope')),
      }),
      getProfileUri: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.OpenId || loginMethodId === Protocol.Oauth2;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankGetProfileUri')),
      }),
      authorizeUri: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.OpenId || loginMethodId === Protocol.Oauth2;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankAuthorizeUri')),
      }),
      getTokenUri: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.OpenId || loginMethodId === Protocol.Oauth2;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankGetTokenUri')),
      }),
      // Only for Saml
      logoutUrl: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.Saml;
        },
        then: yup.string().when('logoutUrl', {
          is: (logoutUrl: string) => {
            return logoutUrl && logoutUrl.length > 0;
          },
          then: yup
            .string()
            .matches(
              RegexValidation.URL,
              t('ssoClient.manageSsoClient.error.logoutUrlInvalid')
            ),
        }),
      }),
      postUrl: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.Saml;
        },
        then: yup
          .string()
          .test('oneOfRequired', t('ssoClient.manageSsoClient.error.blankPostUrl'), function () {
            return this.parent.idpInitiatedUrl || this.parent.postUrl;
          })
          .when('postUrl', {
            is: (postUrl: string) => {
              return postUrl && postUrl.length > 0;
            },
            then: yup
              .string()
              .matches(RegexValidation.URL, t('ssoClient.manageSsoClient.error.postUrlInvalid')),
          }),
      }),
      idpInitiatedUrl: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.Saml;
        },
        then: yup
          .string()
          .test('oneOfRequired', t('ssoClient.manageSsoClient.error.blankPostUrl'), function () {
            return this.parent.idpInitiatedUrl || this.parent.postUrl;
          })
          .when('idpInitiatedUrl', {
            is: (idpInitiatedUrl: string) => {
              return idpInitiatedUrl && idpInitiatedUrl.length > 0;
            },
            then: yup
              .string()
              .matches(
                RegexValidation.URL,
                t('ssoClient.manageSsoClient.error.idpInitiatedUrlInvalid')
              ),
          }),
      }),
      cert: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.Saml;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankCert')),
      }),
      userAttribute: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.Saml;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankUserAttribute')),
      }),
      subAttribute: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.Saml;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.subUserAttribute')),
      }),
      digestAlgorithm: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.Saml;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankDigestAlgorithm')),
      }),
      entityId: yup.string().when('loginMethodId', {
        is: (loginMethodId: number) => {
          return loginMethodId === Protocol.Saml;
        },
        then: yup.string().required(t('ssoClient.manageSsoClient.error.blankEntityId')),
      }),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<IdentityProviderItem>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  // initial data load
  useEffect(() => {
    if (!isDataLoaded && identityProviderId && identityProviderDetail !== undefined) {
      setIsDataLoaded(true);
      const bindEditControls = async () => {
        Object.keys(defaultFormValues).forEach((field: any) => {
          setValue(
            field,
            checkNullData(identityProviderDetail[field as keyof IdentityProviderItem])
          );
          if (identityProviderDetail.loginMethodId) {
            setLoginMethod(identityProviderDetail.loginMethodId);
          }
          if (identityProviderDetail.digestAlgorithm) {
            setValue('digestAlgorithm', identityProviderDetail.digestAlgorithm);
          } else {
            setValue('digestAlgorithm', 'sha256');
          }
          if (identityProviderDetail.ehrType) {
            setUsedEHRType(true);
          }
          if (identityProviderDetail.isUsed) {
            setIsUsed(identityProviderDetail.isUsed);
          }
        });
      };
      bindEditControls();
    }
  }, [identityProviderId, isDataLoaded, setValue, identityProviderDetail]);

  // bind controls based on selected Protocol
  useEffect(() => {
    if (loginMethod) {
      setValue('loginMethodId', loginMethod);
    }
  }, [loginMethod, setValue]);

  const showEHRTypePopup = async () => {
    dispatch(setContentPopup({ content: ehrTypeContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
  };

  const onRemoveEHRType = async (successCallback?: () => void) => {
    setValue('ehrType', '');
    if (successCallback) {
      successCallback();
    }
  };

  const handleLoginMethodChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('loginMethodId', selectedValue);
    setLoginMethod(selectedValue);
  };
  const handleStatusChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('status', selectedValue);
  };
  const handleLocaleChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('locale', selectedValue);
  };
  const handleDigestAlgorithmChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue('digestAlgorithm', value);
  };
  const handleEHRTypeChange = (e: any, values: any) => {
    if (!values && usedEHRType) {
      showEHRTypePopup();
    } else {
      const selectedValue = values ? values.id : '';
      setValue('ehrType', selectedValue);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });

  const onSubmit = async (data: IdentityProviderItem) => {
    try {
      if (data && !data.ehrType) {
        delete data.ehrType;
      }
      await onFormSubmit(data, identityProviderId, () => {
        if (!identityProviderId) {
          reset();
        }
        setPageErrors([]);
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const openIdOrOauth2Fileds = () => {
    return (
      <>
        {loginMethod && (loginMethod === Protocol.OpenId || loginMethod === Protocol.Oauth2) && (
          <>
            <Controller
              name="clientId"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.clientId}
                  helperText={errors.clientId?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.clientId')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="clientSecret"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.clientSecret}
                  helperText={errors.clientSecret?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.clientSecret')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="baseUrl"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.baseUrl}
                  helperText={errors.baseUrl?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.baseUrl')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="scope"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.scope}
                  helperText={errors.scope?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.scope')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="getProfileUri"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  id="getProfileUri"
                  error={!!errors.getProfileUri}
                  helperText={errors.getProfileUri?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.getProfileUri')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="locale"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  data-testid="mui-component-select-locale"
                  options={localesData}
                  getOptionLabel={(option: any) => (option.name ? option.name : '')}
                  onChange={handleLocaleChange}
                  value={value ? localesData.find((item) => item.id === value) || [] : null}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.locale}
                      helperText={errors.locale?.message}
                      margin="normal"
                      fullWidth
                      label={t('ssoClient.manageSsoClient.ssoClientForm.locale')}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="authorizeUri"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  id="authorizeUri"
                  error={!!errors.authorizeUri}
                  helperText={errors.authorizeUri?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.authorizeUri')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="getTokenUri"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  id="getTokenUri"
                  error={!!errors.getTokenUri}
                  helperText={errors.getTokenUri?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.getTokenUri')}
                  variant="outlined"
                />
              )}
            />
          </>
        )}
      </>
    );
  };

  const samlFileds = () => {
    return (
      <>
        {loginMethod && loginMethod === Protocol.Saml && (
          <>
            <Controller
              name="cert"
              control={control}
              render={({ field }) => (
                <StyleTextArea
                  {...field}
                  error={!!errors.cert}
                  helperText={errors.cert?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.cert')}
                  variant="outlined"
                  multiline
                  minRows={2}
                />
              )}
            />
            <Controller
              name="entityId"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.entityId}
                  helperText={errors.entityId?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.entityId')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="digestAlgorithm"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  margin="normal"
                  select
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.digestAlgorithm')}
                  error={!!errors.digestAlgorithm}
                  helperText={errors.digestAlgorithm?.message}
                  variant="outlined"
                  onChange={handleDigestAlgorithmChange}
                >
                  {digestAlgorithmOptions?.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </StyleTextField>
              )}
            />
            <Controller
              name="idpInitiatedUrl"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.idpInitiatedUrl}
                  helperText={errors.idpInitiatedUrl?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.idpInitiatedUrl')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="postUrl"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  id="postUrl"
                  error={!!errors.postUrl}
                  helperText={errors.postUrl?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.postUrl')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="userAttribute"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.userAttribute}
                  helperText={errors.userAttribute?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.userAttribute')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="subAttribute"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.subAttribute}
                  helperText={errors.subAttribute?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.subAttribute')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="locale"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  data-testid="mui-component-select-locale"
                  options={localesData}
                  getOptionLabel={(option: any) => (option.name ? option.name : '')}
                  onChange={handleLocaleChange}
                  value={value ? localesData.find((item) => item.id === value) || [] : null}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.locale}
                      helperText={errors.locale?.message}
                      margin="normal"
                      fullWidth
                      label={t('ssoClient.manageSsoClient.ssoClientForm.locale')}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="logoutUrl"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.logoutUrl}
                  helperText={errors.logoutUrl?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.logoutUrl')}
                  variant="outlined"
                />
              )}
            />
          </>
        )}
      </>
    );
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        px: matches ? 0 : 3,
        pt: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Controller
          name="loginMethodId"
          control={control}
          render={({ fieldState, formState, field: { value }, ...props }) => (
            <StyleAutocomplete
              {...props}
              data-testid="mui-component-select-login-method"
              options={dataLoginMethods?.loginMethods || []}
              getOptionLabel={(option: any) => (option.name ? option.name : '')}
              onChange={handleLoginMethodChange}
              value={
                value
                  ? dataLoginMethods?.loginMethods?.find((item) => item.id === value) || []
                  : null
              }
              filterOptions={filterOptions}
              renderOption={(props, option: any) => {
                return (
                  <li {...props} key={option.id} value={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.loginMethodId}
                  helperText={errors.loginMethodId?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.protocol')}
                />
              )}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.name}
              helperText={errors.name?.message}
              margin="normal"
              fullWidth
              label={t('ssoClient.manageSsoClient.ssoClientForm.name')}
              variant="outlined"
              sx={{
                width: '49%',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <StyleTextField
              {...field}
              error={!!errors.description}
              helperText={errors.description?.message}
              margin="normal"
              fullWidth
              label={t('ssoClient.manageSsoClient.ssoClientForm.description')}
              variant="outlined"
            />
          )}
        />
        <Controller
          name="status"
          control={control}
          render={({ fieldState, formState, field: { value }, ...props }) => (
            <StyleAutocomplete
              {...props}
              data-testid="mui-component-select-status"
              options={statusData}
              getOptionLabel={(option: any) => (option.name ? option.name : '')}
              onChange={handleStatusChange}
              value={value ? statusData.find((item) => item.id === value) || [] : null}
              renderOption={(props, option: any) => {
                return (
                  <li {...props} key={option.id} value={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.status')}
                />
              )}
            />
          )}
        />
        {openIdOrOauth2Fileds()}
        {samlFileds()}
        <Controller
          name="ehrType"
          control={control}
          render={({ fieldState, formState, field: { value }, ...props }) => (
            <StyleAutocomplete
              {...props}
              disabled={isUsed}
              data-testid="mui-component-ehr-type"
              options={ehrTypes || []}
              getOptionLabel={(option: any) => (option.name ? option.name : '')}
              onChange={handleEHRTypeChange}
              value={value ? ehrTypes?.find((item) => item.id === value) || [] : null}
              filterOptions={filterOptions}
              renderOption={(props, option: any) => {
                return (
                  <li {...props} key={option.id} value={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.ehrType}
                  helperText={errors.ehrType?.message}
                  margin="normal"
                  fullWidth
                  label={t('ssoClient.manageSsoClient.ssoClientForm.ehrType')}
                />
              )}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          float: 'right',
          pb: 3,
        }}
      >
        {!matches && <Divider sx={{ py: 1 }} />}

        {pageErrors.length > 0 &&
          pageErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ))}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            width: matches ? '100%' : '24%',
            my: 2,
            py: 2,
            float: 'right',
          }}
        >
          {identityProviderId
            ? t('ssoClient.manageSsoClient.ssoClientForm.updateClient')
            : t('ssoClient.manageSsoClient.ssoClientForm.createClient')}
        </Button>
      </Box>
      <PopupModal isOpen={isOpenPopup} contentPopup={contentPopup} onClick={onRemoveEHRType} />
    </Box>
  );
}
