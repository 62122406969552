import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatDate } from '../../../common/utils/dateTimeUtils';
import { DisorderScreenerInterview, LineItemScores } from '../../../common/utils/reportTypes';
import { parseLegal } from '../../../common/utils/reportUtils';
import {
  AssessmentResult,
  Assessment,
  ModuleQuestions,
  Patient,
  Interviewer,
  ScoringLineItemNextStep,
} from '../../../types/ReportType';
import { InterviewReportType } from '../../interview/interviewUtils';
import ReportNextSteps from './ReportNextSteps';
import ReportSummaryPage from './ReportSummaryPage';

interface ReportScreenerType {
  assessmentResult?: AssessmentResult;
  assessment?: Assessment;
  moduleQuestions?: ModuleQuestions[];
  patient?: Patient;
  nextSteps?: ScoringLineItemNextStep[];
  interviewer?: Interviewer;
}

const StyleTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 500,
}));

const StylePatientInfo = styled(Typography)(() => ({
  fontWeight: 500,
}));

export default function ReportScreenerPage({
  assessmentResult,
  assessment,
  moduleQuestions,
  patient,
  nextSteps,
  interviewer,
}: ReportScreenerType) {
  const { t } = useTranslation();

  const checkShowPositiveSectionLabel = (): {
    isShowPositiveSectionLabel: string;
    positiveSectionLabel: string[];
  } => {
    let isShowPositiveSectionLabel = false;
    const positiveSectionLabel = [];

    const questionModules = moduleQuestions || [];
    for (const questionModule of questionModules) {
      const scoringSections = questionModule?.questionScores || [];

      for (const scoringSection of scoringSections) {
        const scoringLineItems = scoringSection?.interviewModuleScoringLineItems || [];

        for (const scoringLineItem of scoringLineItems) {
          const { hideFromSummary, lineItemScore, title } = scoringLineItem || {};

          if (!hideFromSummary && lineItemScore === LineItemScores.Met) {
            isShowPositiveSectionLabel = true;
            positiveSectionLabel.push(title);
          }
        }

        if (isShowPositiveSectionLabel) {
          break;
        }
      }

      if (isShowPositiveSectionLabel) {
        break;
      }
    }

    return {
      isShowPositiveSectionLabel: isShowPositiveSectionLabel
        ? t('assessmentReport.screenerReport.positiveSectionLabel')
        : t('assessmentReport.screenerReport.negativeSectionLabel'),
      positiveSectionLabel,
    };
  };

  let completedAtDisplay = '';
  if (patient && assessmentResult?.completedAt) {
    completedAtDisplay = formatDate(dayjs(assessmentResult.completedAt).tz(patient.timezone));
  }

  return (
    <Box
      sx={{
        my: 2,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 4,
        justifyContent: 'space-between',
        height: '1200px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StyleTitle variant="body1">{t('assessmentReport.screenerReport.preTitle')}</StyleTitle>
          <Typography variant="body1" sx={{ fontSize: '28px', fontWeight: 'bold' }}>
            {assessment?.title || ''}
          </Typography>
          <StyleTitle variant="body1">{assessment?.subTitle || ''}</StyleTitle>
        </Box>

        <StylePatientInfo variant="body1">
          {`${t('assessmentReport.screenerReport.prePatientNameText')}`}{' '}
          {patient?.lastName && patient?.firstName ? (
            <>
              <Typography component="u">{patient.lastName}</Typography>
              {', '}
              <Typography component="u">{patient.firstName}</Typography>
            </>
          ) : (
            <Typography component="u">{patient?.externalId}</Typography>
          )}{' '}
          {`${t('assessmentReport.screenerReport.postPatientNameText')} ${
            assessment?.title || ''
          } ${t('assessmentReport.screenerReport.preDateText')} ${completedAtDisplay}`}
        </StylePatientInfo>

        {assessment?.reportType === InterviewReportType.Screener &&
          assessment.subReportType !== InterviewReportType.PHQ && (
            <>
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  {checkShowPositiveSectionLabel().isShowPositiveSectionLabel}
                </Typography>
                <Typography component={'ul'}>
                  {checkShowPositiveSectionLabel().positiveSectionLabel.map((label: string) => (
                    <Typography key={label} component={'li'} fontSize={'16px'}>
                      {label}
                    </Typography>
                  ))}
                </Typography>
              </Box>

              {nextSteps && nextSteps?.length > 0 && <ReportNextSteps nextSteps={nextSteps} />}

              {!!assessmentResult?.reasonForEdit && (
                <ReportSummaryPage
                  assessmentResult={assessmentResult}
                  patient={patient}
                  interview={assessment}
                  interviewer={interviewer}
                  moduleQuestions={moduleQuestions}
                />
              )}
            </>
          )}
      </Box>

      <Box
        sx={{
          '&.MuiBox-root .MuiTypography-root': {
            fontSize: '12px',
            fontWeight: 500,
          },
        }}
      >
        {assessment?.legal && (
          <Typography
            my={2}
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: parseLegal(assessment.legal),
            }}
          ></Typography>
        )}

        {assessment?.subReportType !== InterviewReportType.PHQ && (
          <Typography fontStyle={'italic'}>
            {assessment?.name && DisorderScreenerInterview[assessment.name]
              ? t(DisorderScreenerInterview[assessment.name])
              : t('assessmentReport.screenerReport.disclaimerText')}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
