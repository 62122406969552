import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  useGetClinicAccountsQuery,
  useUpdateClinicalFollowUpAccountSettingsMutation,
} from '../../../app/services/account';
import { useToast } from '../../../app/toast';
import Loading from '../../../common/components/Loading';
import PageHeader from '../../../common/components/PageHeader';
import DashboardLayout from '../../../common/layouts/Dashboard';
import { getPageTitle } from '../../../common/utils/pageUtils';
import {
  ClinicalFollowUpInfo,
  UpdateClinicalFollowUpInfoItem,
} from '../../../types/ClinicalFollowUps';
import { PageProps } from '../../../types/PageProps';
import ClinicalFollowUpsForm from './ClinicalFollowUpsForm';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../auth/authSlice';
import { Role } from '../../../common/utils/loginRole';

export default function ClinicalFollowUpsPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const params = useParams();
  const toast = useToast();
  const accountId: string | undefined = params.clinicalAccountId;
  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const { data: accountData, isLoading } = useGetClinicAccountsQuery();
  const [updateClinicalFollowUpAccount] = useUpdateClinicalFollowUpAccountSettingsMutation();
  const currentUser = useAppSelector(selectUser);

  const onFormSubmit = async (data: ClinicalFollowUpInfo, successCallback?: () => void) => {
    const updateItems: UpdateClinicalFollowUpInfoItem[] = [];
    data.clinicalFollowUpNextStep.forEach((item) => {
      const followUpItem = {
        id: item.clinicalFollowUpAccountId ?? undefined,
        clinicAccountId: data.clinicAccountId,
        clinicalFollowUpNextStepActionId: item.nextStepId ?? undefined,
        reportText: item?.reportText ?? '',
        nextStepText: item?.nextStepText ?? '',
        followUpText: item?.followUpText ?? '',
        type: item.type,
        nextStepAction: item.nextStepAction ?? '',
        applyNextStep: item.applyNextStep,
        automateFollowup: item.automateFollowup,
        interval: item.interval || null,
        startAfterDays: item.startAfterDays || null,
        maxSendTimes: item.maxSendTimes || null,
      };
      updateItems.push(followUpItem);
    });
    await updateClinicalFollowUpAccount({ updatedItems: updateItems }).unwrap();
    if (successCallback) {
      successCallback();
    }
    toast.publish(
      t('account.manageAccounts.accountForm.clinicalFollowUps.form.updateSuccess'),
      'success',
      5000
    );
  };

  if(breadcrumbs?.length && currentUser.roleId === Role.ClinicAdmin){
    breadcrumbs = [breadcrumbs[0], breadcrumbs[2]];
  }
  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>
          {getPageTitle(t('account.manageAccounts.accountForm.clinicalFollowUps.title'))}
        </title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={t('account.manageAccounts.accountForm.clinicalFollowUps.title')}
          backUrl={navBackUrl || `/dashboard`}
        />
      </Container>

      {isLoading ? (
        <Box sx={{ my: 2 }}>
          <Loading />
        </Box>
      ) : (
        <ClinicalFollowUpsForm
          accountId={accountId}
          onFormSubmit={onFormSubmit}
          clinicAccounts={accountData?.clinicAccounts || []}
        />
      )}
    </DashboardLayout>
  );
}
