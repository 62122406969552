import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useAppDispatch } from '../../app/hooks';
import Typography from '@mui/material/Typography';
import { userSearchApi } from '../../app/services/account';
import { debounce } from 'lodash';
import { SxProps, Theme } from '@mui/system';
import { SearchUserRequest, SearchUserResult } from '../../types/UserRequest';
import { DefaultSearchPageSize } from '../utils/commonUtils';
import { FieldError } from 'react-hook-form';

interface UserSearchProps {
  selectedUserId?: string; // default id
  onSelect?: (user: SearchUserResult | null) => void;
  sx?: SxProps<Theme>;
  pageSize?: number;
  searchIfNoInput?: boolean;
  label?: string;
  error?: FieldError;
  searchByFullName?: boolean;
  searchByEmail?: boolean;
}

export default function UserSearch({ selectedUserId, onSelect, sx, searchIfNoInput, label, error, searchByFullName, searchByEmail }: UserSearchProps) {
  searchByEmail = searchByEmail === false ? false : true;

  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [isSearching, setIsSearching] = useState(true);
  const [users, setUsers] = useState<SearchUserResult[]>([]);
  const [isFocusing, setIsFocusing] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SearchUserResult | null>(null);

  const userSearch = useCallback((searchUserRequest: SearchUserRequest) => {
    const userDispatcher = dispatch(userSearchApi.endpoints.searchUsers.initiate(searchUserRequest));
    userDispatcher.then((result) => {
      if (typeof result !== 'undefined') {
        let userResults: SearchUserResult[] = [];
        if (result.data && Array.isArray(result.data.users)) {
          for (let i = 0; i < result.data.users.length; i++) {
            const user = result.data.users[i];
            let option = {
              id: user.id,
              name: `${user.lastName}, ${user.firstName}`,
            } as SearchUserResult;
            userResults.push(option);
          }
        }
        setUsers(userResults);
      }
    })
    .catch(err => {
      console.error(`Error when searching patients ${err.getMessage()}`);
    });
  }, [dispatch]);

  const debouncedUserSearch = useMemo(() => debounce((searchUserRequest: SearchUserRequest) => {
    userSearch(searchUserRequest);
  }, 500),
  [userSearch]);

  useEffect(() => {
      const selectedUserItem = users.find((user) => user?.id?.toString() === selectedUserId?.toString());
      setSelectedUser(selectedUserItem || null);
      // if selectedPatientId is not null but this patient is not the list then set null
      if (selectedUserId && !selectedUserItem && onSelect) {
        onSelect(null);
      }
      if (!isFocusing && !selectedUserItem) {
        setIsSearching(false);
        setInputValue('');
      }
    },
    [inputValue, isFocusing, onSelect, selectedUserId, users]
  );

  useEffect(() => {
    if (inputValue === '' && !searchIfNoInput) {
        setUsers([]);
        return undefined;
      }

      if (isSearching) {
        debouncedUserSearch(
          { searchTerm: inputValue, searchByFullName, searchByEmail, pageSize: DefaultSearchPageSize } as SearchUserRequest
        );
      }
      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue, debouncedUserSearch, searchIfNoInput]
  );

  const searchUser = (selectedUser: SearchUserResult | null) => {
    if (onSelect) {
      onSelect(selectedUser);
      return;
    }
  }

  return (
    <Autocomplete
      id='userSearch'
      sx={sx || {
        '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
          pr: 1
        },
        width: 1,
      }}
      inputValue={inputValue}
      onFocus={() => {
        setIsFocusing(true);
      }}
      onBlur={() => {
        setIsSearching(false);
        setIsFocusing(false);
        if (!selectedUser) {
          setInputValue('');
        }
      }}
      value={selectedUser}
      getOptionLabel={(option) => typeof option !== 'undefined' ? typeof option === 'string' ? option : option.name! : '' }
      options={users}
      filterOptions={(options) => options}
      onChange={(event, newInputValue) => {
        setIsSearching(false);
        setInputValue(newInputValue?.name || '');
        searchUser(newInputValue as SearchUserResult);
      }}
      onInputChange={(event, newInputValue) => {
        if (event?.type === 'change') {
          setIsSearching(true);
          setInputValue(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error?.message}
          label={label}
        />
      )}
      renderOption={(props, user) => {
        return (
          <li {...props} key={user.id}>
            <Typography sx={{
              fontStyle: user.id === '-1' ? 'italic' : 'normal',
            }}>
              {user.name}
            </Typography>
          </li>
        );
      }}
    />
  );
}
