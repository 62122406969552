import accountMocks from './accountMocks';
import authMocks from './authMocks';
import interviewMocks from './interviewMocks';
import monitorMocks from './monitorMocks';
import patientMocks from './patientMocks';

export const handlers = [
  ...authMocks,
  ...accountMocks,
  ...interviewMocks,
  ...monitorMocks,
  ...patientMocks
];
