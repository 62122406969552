import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '../../app/hooks';
import { routeReportTypeMap } from '../../app/routes';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  addBreadcrumb,
  BreadcrumbType,
  editBreadcrumbs,
  ReplaceType,
} from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { clearCredentials } from '../auth/authSlice';
import { StyledButton } from '../interview/ReadyOrResumeInterview';
import { StyledBox } from '../interview/StartInterviewPage';
import {
  setClinicalFollowUps,
  setInterviewMode,
} from '../interview/interviewSlice';
import { clearAssessmentCachedData } from '../patient/patientUtils';
import { setMonitorMode } from './monitorSlide';

export default function ContinueNextMonitorPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { patientId, trackerResultId, reportType } = useParams();
  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }]; // TODO: should create common logic?
  const newBreadcrumb: BreadcrumbType = {
    text: 'Patient Dashboard',
    link: `/dashboard/patient/${patientId}`,
  };
  const { pathname } = useLocation();
  const isInterview = !!pathname.includes('continue-next-interview');

  useEffect(() => {
    if (patientId && isInterview && trackerResultId) {
      dispatch(setClinicalFollowUps({ patientId, interviewResultId: parseInt(trackerResultId) }));
    }
  }, [dispatch, isInterview, trackerResultId, patientId]);

  const renderStaticCompletePageBody = () => {
    return (
      <>
        <Typography variant="subtitle1">{t('monitor.continueNextMonitor.title')}</Typography>
      </>
    );
  };

  return (
    <DashboardLayout
      breadcrumbs={editBreadcrumbs(addBreadcrumb(breadcrumbs!, newBreadcrumb), ...replacePath)}
      showBreadcrumb={false}
    >
      <Helmet>
        <title>{getPageTitle(t('monitor.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <StyledBox>
          <Box sx={{ py: 3 }}>
            <Typography variant="h1">{t('monitor.continueNextMonitor.completePage')}</Typography>
          </Box>
          <>
            <Box sx={{ mb: 1 }}>{renderStaticCompletePageBody()}</Box>
            <Box
              sx={{
                py: 2,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <StyledButton
                type="button"
                variant="contained"
                onClick={async () => {
                  if (isInterview) {
                    dispatch(
                      setInterviewMode({
                        interviewMode: 'P',
                        reportType: reportType!,
                      })
                    );
                    dispatch(clearCredentials());
                    clearAssessmentCachedData();
                    const interviewConductUrl = `/current-interview/${
                      routeReportTypeMap[reportType!]
                    }/${trackerResultId}`;
                    navigate(interviewConductUrl);
                  } else {
                    dispatch(setMonitorMode({ monitorMode: 'P' }));
                    dispatch(clearCredentials());
                    const monitorConductUrl = `/current-monitor/screenerPage/${trackerResultId}`;
                    navigate(monitorConductUrl);
                  }
                }}
              >
                {t('monitor.continueNextMonitor.continue')}
              </StyledButton>
            </Box>
          </>
        </StyledBox>
      </Container>
    </DashboardLayout>
  );
}
