import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';

interface ErrorProps {
  errors: any;
}

export default function InterviewSummaryError({ errors }: ErrorProps) {
  const { t } = useTranslation();

  return (
    <>
      {errors && Object.keys(errors).length > 0 && (
        <Alert severity="error" sx={{ my: 2 }}>
          {t('interview.error.summary')}
        </Alert>
      )}
    </>
  );
}
