import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';

interface Props {
  headerText: string;
  backUrl?: string;
  backLabel?: string;
  sx?: SxProps;
}
export default function PageHeader({ headerText, backUrl, backLabel, sx }: Props) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      <IconButton
        size="small"
        color="inherit"
        onClick={() => {
          navigate(backUrl || '/dashboard');
        }}
        aria-label={backLabel || 'go back to dashboard'}
      >
        <ArrowBackIcon viewBox="5 0 24 24" />
      </IconButton>
      <Typography variant="h1">{headerText}</Typography>
    </Box>
  );
}
