import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';

const StyledTypography = styled(Typography)(() => ({
  margin: '16px 0px',
  fontWeight: 'bold',
}));

export default function InfoNoAnswer(InfoNoAnswerType: InterviewQuestionProps) {
  const interviewQuestion = InfoNoAnswerType as InterviewQuestionProps;

  return (
    <Box sx={{ mt: 2 }}>
      {interviewQuestion && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: interviewQuestion.question.questionPrompt!,
          }}
        ></StyledTypography>
      )}
      <Typography
        dangerouslySetInnerHTML={{
          __html: interviewQuestion.question.questionText!,
        }}
      ></Typography>
    </Box>
  );
}
