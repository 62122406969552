import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MonitorQuestionProps } from '../../../types/MonitorTypes';

export default function InfoNoAnswer({ question }: MonitorQuestionProps) {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></Typography>
    </Box>
  );
}
