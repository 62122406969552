import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { useProcessRescoreJobMutation } from '../../app/services/interview';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getPageTitle } from '../../common/utils/pageUtils';
import { JobTypes } from '../../common/utils/rescoreType';
import { PageProps } from '../../types/PageProps';
import { StartRescoreInfoRequest, RescoreInterviewFormInfo } from '../../types/RescoreInterviewJob';
import AddJobForm from './AddJobForm';

export default function AddJobPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [startRescore] = useProcessRescoreJobMutation();
  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const onFormSubmit = async (data: RescoreInterviewFormInfo, successCallback?: () => void) => {
    const accountIds =
      data.rescoreClinicAccounts && data.rescoreClinicAccounts.length > 0
        ? data.rescoreClinicAccounts.map((account) => account.id)
        : [];

    const clinicAccountIds =
      accountIds && data.jobType === JobTypes.accounts ? accountIds : [data.clinicAccount ?? ''];

    const patientIdArray =
      data.rescorePatients && data.rescorePatients.length > 0 && data.jobType === JobTypes.patients
        ? data.rescorePatients.map((patient) => patient.id)
        : [];

    const startInfo: StartRescoreInfoRequest = {
      jobName: data.jobName,
      jobType: data.jobType,
      clinicAccountIds: clinicAccountIds,
      patientIds: patientIdArray,
    };

    await startRescore(startInfo).unwrap();
    navigate('/dashboard/rescore-jobs');

    if (successCallback) {
      successCallback();
    }

    toast.publish(t('interviews.rescoreJobs.addJobs.form.startSuccess'), 'success', 5000);
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('interviews.rescoreJobs.addJobs.title'))}</title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={t('interviews.rescoreJobs.addJobs.title')}
          backUrl={navBackUrl || `/dashboard/rescore-jobs`}
        />
      </Container>

      <AddJobForm onFormSubmit={onFormSubmit} />
    </DashboardLayout>
  );
}
