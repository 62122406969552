import { useEffect, useRef, useState } from 'react';
import { default as SignatureCanvas } from 'react-signature-canvas';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import theme from '../../../app/theme';
import ElectronicSignature from '../../../common/components/ElectronicSignature';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectResetQuestion, setResetQuestion } from '../interviewSlice';

export default function ElectronicSignatureQuestion({
  question,
  field,
  setValue,
  error,
  helperText,
}: InterviewQuestionProps) {
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const [signature, setSignature] = useState<string>('');
  const sigRef = useRef<SignatureCanvas>(null);
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);

  const handleSignatureEnd = () => {
    setSignature(sigRef?.current?.toDataURL() || '');
  };

  const clearSignature = () => {
    sigRef?.current?.clear();
    setSignature('');
  };

  useEffect(() => {
    setValue(field?.name!, signature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  useEffect(() => {
    if (isResetQuestion) {
      clearSignature();
      dispatch(setResetQuestion({ reset: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetQuestion]);

  return (
    <FormControl sx={{ display: 'flex', alignItems: 'start' }} error={error} variant="standard">
      <ElectronicSignature
        ref={sigRef}
        clear={clearSignature}
        onEnd={handleSignatureEnd}
        matches={matches}
        headerText={question?.questionText || ''}
        backgroundColor={theme.xPalette.noteLightGrey}
        sx={{
          width: matches ? '100%' : 500,
        }}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
