import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '../../app/hooks';
import theme from '../../app/theme';
import { ConfirmContentPopup } from '../../types/CommonType';
import { RescoreAccount, RescorePatient } from '../../types/RescoreInterviewJob';
import { setContentPopup, setIsOpenPopup } from '../slices/globalSlice';
import { getPatientFullName } from '../utils/patientUtils';

interface ConfirmRescorePopupProps {
  isOpen: boolean;
  contentPopup: ConfirmContentPopup;
  onSuccess: () => Promise<void>;
  onClose?: () => void;
}

export default function ConfirmRescorePopup({
  isOpen,
  contentPopup,
  onSuccess,
  onClose,
}: ConfirmRescorePopupProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleClose = (event: SyntheticEvent) => {
    if (['btnClose'].includes(event.currentTarget.id)) {
      dispatch(setIsOpenPopup({ value: false }));
      dispatch(setContentPopup({ content: undefined }));
      setOpen(false);
      if (onClose) {
        onClose();
      }
    }
  };

  const handleOk = (event: SyntheticEvent) => {
    if (['btnOk'].includes(event.currentTarget.id)) {
      dispatch(setIsOpenPopup({ value: false }));
      dispatch(setContentPopup({ content: undefined }));
      setOpen(false);
      onSuccess();
    }
  };
  useCallback(() => {
    dispatch(setIsOpenPopup({ value: false }));
  }, [dispatch]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open || false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle
        sx={{ pt: 6, textAlign: 'center', fontSize: (theme) => theme.typography.h1.fontSize }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="span" sx={{ color: theme.xPalette.warning, fontWeight: 900 }}>
            {t('interviews.rescoreJobs.addJobs.form.confirmRescorePopup.information.warning')}
          </Typography>
          {contentPopup?.title}
        </Box>
        <IconButton
          id="btnClose"
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography sx={{ color: theme.xPalette.black, paddingTop: 1 }}>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {t('interviews.rescoreJobs.addJobs.form.confirmRescorePopup.information.jobName')}
            </Typography>
            {`: ${contentPopup.information.jobName}`}
          </Typography>
          <Typography sx={{ color: theme.xPalette.black, paddingTop: 1 }}>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {t('interviews.rescoreJobs.addJobs.form.confirmRescorePopup.information.jobType')}
            </Typography>
            {`: ${contentPopup.information.jobType}`}
          </Typography>
          {contentPopup.information.affectedPatients && (
            <>
              <Typography sx={{ color: theme.xPalette.black, paddingTop: 1 }}>
                <Typography component="span" sx={{ fontWeight: 600 }}>
                  {t(
                    'interviews.rescoreJobs.addJobs.form.confirmRescorePopup.information.affectedPatients'
                  )}
                </Typography>
                {`: ${contentPopup.information.affectedPatients.length}`}
              </Typography>
              <ul>
                {contentPopup.information.affectedPatients.map((patient: RescorePatient) => (
                  <li>
                    <Typography sx={{ color: theme.xPalette.black }}>
                      {`${patient.id}${
                        getPatientFullName(patient) !== '' ? `: ${getPatientFullName(patient)}` : ''
                      }`}
                    </Typography>
                  </li>
                ))}
              </ul>
            </>
          )}
          {contentPopup.information.affectedClinicAccounts && (
            <>
              <Typography sx={{ color: theme.xPalette.black, paddingTop: 1 }}>
                <Typography component="span" sx={{ fontWeight: 600 }}>
                  {t(
                    'interviews.rescoreJobs.addJobs.form.confirmRescorePopup.information.affectedClinicAccounts'
                  )}
                </Typography>
                {`: ${contentPopup.information.affectedClinicAccounts.length}`}
              </Typography>
              <ul>
                {contentPopup.information.affectedClinicAccounts.map((account: RescoreAccount) => (
                  <li>
                    <Typography sx={{ color: theme.xPalette.black }}>
                      {`${account.id}: ${account.name}`}
                    </Typography>
                  </li>
                ))}
              </ul>
            </>
          )}

          <Typography sx={{ color: theme.xPalette.black }}>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {t(
                'interviews.rescoreJobs.addJobs.form.confirmRescorePopup.information.totalInterviewsToBeRescored'
              )}
            </Typography>
            {`: ${contentPopup.information.totalInterviewsToBeRescored}`}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            height: '130px',
          },
        })}
      >
        <Link
          id="btnClose"
          component="button"
          sx={(theme) => ({
            fontSize: '14px',
            [theme.breakpoints.down('sm')]: {
              height: '130px',
            },
            textTransform: 'none',
            width: '100%',
            padding: '12px 0px',
            [theme.breakpoints.up('sm')]: {
              width: '20%',
              minWidth: '120px',
            },
            [theme.breakpoints.down('sm')]: {
              position: 'absolute',
              width: '95%',
              marginTop: '60px',
              textAlign: 'center',
            },
          })}
          onClick={handleClose}
        >
          {contentPopup?.btnClose}
        </Link>
        <Button
          id="btnOk"
          variant="contained"
          sx={(theme) => ({
            backgroundColor: '#388AC8',
            textTransform: 'none',
            width: '100%',
            padding: '12px 0px',
            borderRadius: '6px',
            [theme.breakpoints.up('sm')]: {
              width: '20%',
              minWidth: '150px',
            },
            [theme.breakpoints.down('sm')]: {
              position: 'absolute',
              width: '95%',
              marginBottom: '60px',
            },
          })}
          onClick={handleOk}
        >
          {contentPopup?.btnOk}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
