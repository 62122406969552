import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useGetMonitorSettingsQuery, useRequestMonitorMutation } from '../../app/services/monitor';
import { useGetPatientQuery } from '../../app/services/patient';
import Loading from '../../common/components/Loading';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import DashboardLayout from '../../common/layouts/Dashboard';
import { editBreadcrumbs, ReplaceType } from '../../common/utils/editBreadcrums';
import { InterviewSettingsViewName as MonitorSettingsViewName } from '../../common/utils/interviewSettings';
import { interviewsModeName as monitorsModeName } from '../../common/utils/interviewsMode';
import { MonitorModeName } from '../../common/utils/monitorsMode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { ResponseError } from '../../types/ResponseError';
import ErrorPage from '../error/ErrorPage';
import ReadyOrResumeMonitor from './ReadyOrResumeMonitor';
import { selectMonitorMode, setMonitorMode, setPatientMonitorData } from './monitorSlide';

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.xPalette.white,
  padding: '0px 24px',
  borderRadius: '16px',
  '&.MuiBox-root:first-of-type': {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  '&.MuiBox-root:last-child': {
    margin: '16px 0',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
}));

export default function StartMonitorPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const { patientId, monitorResultId: inputMonitorResultId } = useParams();
  const dispatch = useAppDispatch();
  const monitorMode = useAppSelector(selectMonitorMode);
  const [requestMonitor] = useRequestMonitorMutation();
  const [pageErrors, setPageErrors] = useState<string[]>([]);

  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }];
  const monitorResultId = parseInt(inputMonitorResultId!);
  const { state, pathname } = useLocation();
  const { resumeMonitor, cancelUrl } = !!state && state;
  const isResumeRoute = pathname.includes('resume-monitor');

  const { data: infoData, error } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const { data: accountSettings, error: settingError } = useGetMonitorSettingsQuery(
    monitorResultId && monitorMode
      ? {
          monitorTestId: monitorResultId,
          monitorType:
            monitorMode === MonitorModeName.Email ? MonitorModeName.Schedule : monitorMode,
          viewName:
            resumeMonitor || isResumeRoute
              ? MonitorSettingsViewName.Resume
              : MonitorSettingsViewName.Start,
        }
      : skipToken
  );

  useEffect(() => {
    const loadMonitor = async () => {
      try {
        const responseData = await requestMonitor({
          monitorTestId: monitorResultId,
        }).unwrap();

        dispatch(
          setMonitorMode({
            monitorMode: monitorsModeName[responseData.monitorMode],
          })
        );

        dispatch(
          setPatientMonitorData({
            data: {
              monitorTestId: monitorResultId,
              accessToken: responseData.patientMonitorToken!,
              lastActivityTs: Date.now(),
            },
          })
        );
        setPageErrors([]);
      } catch (e) {
        const {
          data: { error },
        } = e as ResponseError;
        setPageErrors(error);
      }
    };

    loadMonitor();
  }, [dispatch, isResumeRoute, monitorResultId, requestMonitor, resumeMonitor]);

  if (error || settingError) {
    return <ErrorPage statusCode={((error || settingError) as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={editBreadcrumbs(breadcrumbs, ...replacePath)}>
      <Helmet>
        <title>{getPageTitle(t('monitor.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <StyledBox>
          <PatientInfoCard data={infoData!} />
        </StyledBox>
        <StyledBox>
          {!accountSettings && !pageErrors ? (
            <Loading />
          ) : (
            <ReadyOrResumeMonitor
              monitorMode={monitorMode}
              resumeMonitor={resumeMonitor || isResumeRoute}
              accountSettings={accountSettings}
              pageErrors={pageErrors}
              cancelUrl={cancelUrl}
            />
          )}
        </StyledBox>
      </Container>
    </DashboardLayout>
  );
}
