import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useDeleteTemplateMutation, useGetListTemplateQuery } from '../../app/services/template';
import { useToast } from '../../app/toast';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import DeactivatePatientIcon from '../../common/components/icons/DeactivatePatientIcon';
import EditUserIcon from '../../common/components/icons/EditInterviewIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { getPageTitle } from '../../common/utils/pageUtils';
import { SortDirs, SortFields } from '../../common/utils/sortInfo';
import { InterviewResultTemplate } from '../../types/AccountType';
import { PageProps } from '../../types/PageProps';
import { PopupContent } from '../../types/PopupType';
import ContextMenu from '../patient/ContextMenu';
import PatientSortMenu from '../patient/PatientSortMenu';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.xPalette.white,
  flexBasis: '100%',
  minWidth: '220px',
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'unset',
    width: '74%',
    minWidth: 'unset',
  },
}));

const StyledAddTemplateBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: '18px',
  width: '40%',
  minWidth: '140px',
  ml: 1,
  [theme.breakpoints.down('sm')]: {
    width: '96%',
    height: '56px',
    ml: 0,
  },
}));

const StyledAddWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 0px',
  width: '100%',
  padding: '0px 8px',
  columnGap: '8px',
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    width: 'auto',
    flexBasis: '40%',
    maxWidth: '380px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',
  },
}));

const defaultPage = 1;

export default function TemplateListPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const toast = useToast();

  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [searchParams] = useSearchParams();
  const [displayTemplateData, setDisplayTemplateData] = useState<InterviewResultTemplate[]>([]);
  const [templateIdAction, setTemplateIdAction] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [navBackUrl, setNavBackUrl] = useState<string>('/dashboard/templates');

  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);

  const tableHeaders: string[] = Object.values(
    t('eSignature.manageTemplateList.tableHeader', {
      returnObjects: true,
    })
  );

  const deactivateAccountContentPopup = {
    title: t('eSignature.manageTemplateList.deleteTemplate.title'),
    description: t('eSignature.manageTemplateList.deleteTemplate.description'),
    btnOk: t('eSignature.manageTemplateList.deleteTemplate.btnOk'),
    btnClose: t('eSignature.manageTemplateList.deleteTemplate.btnClose'),
    toastMessage: t('eSignature.manageTemplateList.deleteTemplate.toastMessage'),
  } as PopupContent;

  const [deletedTemplate] = useDeleteTemplateMutation();

  const searchTextboxRef = useRef<HTMLInputElement>(null);

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
    name: searchParams.get('name') || '',
    sortField: searchParams.get('sort') || SortFields.Name,
    sortDir: searchParams.get('dir') || SortDirs.Asc,
  };

  const currentPage = searchOptions.page;
  const tableRef = useRef<HTMLDivElement>(null);

  const {
    data: templateListData,
    isLoading: isLoadingTemplate,
    refetch: refetchTemplateList,
  } = useGetListTemplateQuery({
    name: searchOptions.name,
    page: currentPage,
    pageSize: pageSize,
    sort: searchOptions.sortDir.toUpperCase(),
  });

  const StyledSortIconBox = styled(Box)(({ theme }) => ({
    display: 'inline',
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
  }));

  const showDeleteTemplatePopup = async (templateId: number) => {
    dispatch(setContentPopup({ content: deactivateAccountContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setTemplateIdAction(templateId);
    handleClose();
  };

  useEffect(() => {
    if (templateListData) {
      setDisplayTemplateData(templateListData.data);
      setTotalPages(Math.ceil(templateListData.totalItems / pageSize));
    }
  }, [dispatch, pageSize, templateListData]);

  const updateUrl = (page: number, name: string | undefined, sort: string, dir: string) => {
    const updatedUrl = `/dashboard/templates?page=${page}&name=${encodeURIComponent(
      name || ''
    )}&sort=${sort}&dir=${dir}`;
    navigate(updatedUrl);
    setNavBackUrl(updatedUrl);
  };

  const handlePageChange = (value: number) => {
    updateUrl(value, searchOptions.name, searchOptions.sortField, searchOptions.sortDir);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    const searchInput = searchTextboxRef.current?.value || '';
    updateUrl(defaultPage, searchInput, searchOptions.sortField, searchOptions.sortDir);
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleMenuSorting = (sortField: string, sortDir: string) => {
    updateUrl(defaultPage, searchOptions.name, sortField, sortDir);
  };

  const onHeaderClicked = (header?: string) => {
    if (header) {
      updateUrl(
        defaultPage,
        searchOptions.name,
        header,
        header !== searchOptions.sortField || searchOptions.sortDir === SortDirs.Desc
          ? SortDirs.Asc
          : SortDirs.Desc
      );
    }
  };

  const handleDeleteTemplate = async (successCallback?: () => void) => {
    try {
      await deletedTemplate({
        templateId: templateIdAction,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('eSignature.manageTemplateList.deleteTemplate.success'), 'success');
      refetchTemplateList();
      updateUrl(defaultPage, searchOptions.name, searchOptions.sortField, searchOptions.sortDir);
    } catch (e) {
      toast.publish(t('eSignature.manageTemplateList.deleteTemplate.fail'), 'error');
    }
  };

  const renderContextMenu = (item: InterviewResultTemplate) => {
    const menuItems = [
      {
        label: t('eSignature.manageTemplateList.menuContext.editTemplate'),
        icon: <EditUserIcon />,
        onClick: () => {
          navigate(`/dashboard/templates/edit-template/${item.id}`, { state: { navBackUrl } });
        },
      },
      {
        isDisplayed: !item.isTemplateBeingUsed,
        label: t('eSignature.manageTemplateList.menuContext.deleteTemplate'),
        icon: <DeactivatePatientIcon />,
        onClick: () => showDeleteTemplatePopup(item.id),
      },
    ];

    // check if not use then hide
    return (
      <ContextMenu
        id={`template-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    );
  };

  const renderDesktopData = (data: InterviewResultTemplate[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => {
                const sortHeader = ['name'];
                const checkSortEvent =
                  searchOptions.sortDir === SortDirs.Asc &&
                  searchOptions.sortField === header.toLowerCase();

                const sortIcon = sortHeader.includes(header.toLowerCase()) ? (
                  <StyledSortIconBox>
                    {checkSortEvent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </StyledSortIconBox>
                ) : (
                  <></>
                );
                return (
                  <StyledTableCell
                    key={`${header} ${index}`}
                    onClick={
                      sortHeader.includes(header.toLowerCase())
                        ? () => onHeaderClicked(header.toLowerCase())
                        : undefined
                    }
                  >
                    {header} {sortIcon}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: InterviewResultTemplate) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell sx={{ maxWidth: '500px', minWidth: '150px' }}>
                    <Link
                      sx={{ fontSize: '1em', textAlign: 'left' }}
                      component="button"
                      onClick={() => {
                        navigate(`/dashboard/templates/edit-template/${item.id}`, {
                          state: { navBackUrl },
                        });
                      }}
                    >
                      {`${item.name}`}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>{item.description}</StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      '& .MuiButtonBase-root': {
                        marginLeft: '10px',
                      },
                    }}
                  >
                    {renderContextMenu(item)}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: InterviewResultTemplate[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: InterviewResultTemplate) => {
          const listItems = [
            [
              <>{t('eSignature.manageTemplateList.tableHeader.name')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.description}</Box>,
            ],
          ];
          return {
            leftHeader: (
              <Link
                sx={{ fontSize: '20px', textAlign: 'left', fontWeight: '600' }}
                component="button"
                onClick={() => {
                  navigate(`/dashboard/templates/edit-template/${item.id}`, {
                    state: { navBackUrl },
                  });
                }}
              >
                {`${item.name}`}
              </Link>
            ),
            rightHeader: renderContextMenu(item),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  const isLoading = isLoadingTemplate;

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('eSignature.manageTemplateList.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={!isDesktop}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 0 : 2 }}
            headerText={t('eSignature.manageTemplateList.title')}
            backUrl={'/dashboard'}
          />

          <StyledAddWrapper>
            <StyledSearchBox
              name="template-search-field"
              data-testid="template-search-field"
              inputRef={searchTextboxRef}
              onKeyUp={handleSearchKeyUp}
              defaultValue={searchOptions.name}
              placeholder={t('eSignature.manageTemplateList.searchTemplate')}
              endAdornment={
                <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              }
            />
            {!isDesktop && (
              <PatientSortMenu
                sx={{
                  width: '20%',
                  flexShrink: 0,
                  borderRadius: '8px',
                  minWidth: 'unset',
                }}
                sortField={searchOptions.sortField}
                sortDir={searchOptions.sortDir}
                onSorted={handleMenuSorting}
                isTemplate={true}
              />
            )}
            <StyledAddTemplateBox
              type="button"
              fullWidth
              variant="contained"
              onClick={() => {
                navigate('/dashboard/templates/add-template', { state: { navBackUrl } });
              }}
            >
              {t('eSignature.manageTemplateList.addTemplate')}
            </StyledAddTemplateBox>
          </StyledAddWrapper>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={tableRef}>
            {displayTemplateData.length === 0 ? (
              <RoundedContainer sx={{ mt: 2, p: 2 }}>
                <Typography>
                  {t('eSignature.manageTemplateList.error.templatesNotFound')}
                </Typography>
              </RoundedContainer>
            ) : (
              <Box sx={{ mt: 2 }}>
                {isDesktop
                  ? renderDesktopData(displayTemplateData)
                  : renderMobileData(displayTemplateData)}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 4,
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    background: theme.xPalette.white,
                    mt: 0,
                    [theme.breakpoints.down('sm')]: {
                      mt: 1,
                      py: 2,
                    },
                  }}
                >
                  <CustomPagination
                    changePage={(e, value) => handlePageChange(value)}
                    currentPage={searchOptions.page}
                    pages={totalPages}
                    pageRef={tableRef}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={handleDeleteTemplate}
        />
      </Container>
    </DashboardLayout>
  );
}
