import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AnswerTypeConditions } from '../../../../common/utils/assessmentMode';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import { InterviewReportType } from '../../../interview/interviewUtils';
import ReportNotes from '../ReportNotes';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function OptionAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
  notes,
}: QuestionAnswerProps) {
  const isMonitor = moduleType === InterviewReportType.Monitor;
  const { questionDisplay, promptDisplay, answerValue, questionTitle } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <>
      <Box
        key={questionAnswer.questionId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          my: 3,
          width: '100%',
          pageBreakInside: 'avoid',
        }}
      >
        <Box sx={{ width: '15%', display: 'flex', columnGap: 1 }}>
          <Typography component={'span'} fontWeight={500}>
            {questionTitle}
          </Typography>
          <Typography component={'span'} fontWeight={500}>
            {questionAnswer.subTitle}
          </Typography>
        </Box>
        <Box sx={{ width: '75%' }}>
          {promptDisplay && (
            <Typography
              fontWeight="bold"
              dangerouslySetInnerHTML={{
                __html: promptDisplay,
              }}
            ></Typography>
          )}
          <Typography
            fontWeight={500}
            dangerouslySetInnerHTML={{
              __html: questionDisplay,
            }}
          ></Typography>
          <Typography fontWeight={'bold'} my={1}>
            {answerValue?.length > AnswerTypeConditions.TEXT_MAX_LENGTH ? answerValue : ''}
          </Typography>
          {notes?.length && (
            <ReportNotes reportNotes={notes} questionId={questionAnswer.questionId} />
          )}
        </Box>
        <>
          <Typography
            sx={{ width: isMonitor ? '15%' : '10%', textAlign: 'right' }}
            component={'span'}
            fontWeight={'bold'}
          >
            {answerValue?.length <= AnswerTypeConditions.TEXT_MAX_LENGTH ? answerValue : ''}
          </Typography>
          {!isMonitor && (
            <Typography sx={{ width: '5%' }} component={'span'} fontWeight={'bold'}></Typography>
          )}
        </>
      </Box>
    </>
  );
}
