import { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useGetLoginUserProfileQuery,
  useUpdateLoginUserProfileMutation,
} from '../../app/services/account';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import UserInfoCard from '../../common/components/UserInfoCard';
import DashboardLayout from '../../common/layouts/Dashboard';
import checkNullData from '../../common/utils/checkNullData';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PhoneNumberRequired, validatePhoneNumber } from '../../common/utils/validationUtils';
import { PageProps } from '../../types/PageProps';
import { ResponseError } from '../../types/ResponseError';
import { LoginUserProfile, UpdateLoginUserProfileRequest } from '../../types/UserTypes';
import ErrorPage from '../error/ErrorPage';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Checkbox from '@mui/material/Checkbox';
import { UserCredentials } from '../../common/utils/userListUtils';

const defaultFormValues: LoginUserProfile = {
  firstName: '',
  lastName: '',
  email: '',
  cellPhone: '',
  otherPhone: '',
  mfaType: '',
  userCredentials: [],
};

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '49%',
    },
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    width: '45px',
    height: '45px',
  },
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 30,
  },
}));

export default function EditProfile({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const toast = useToast();
  const [updateUserProfile] = useUpdateLoginUserProfileMutation();
  const { userId } = useParams();
  const [openDrop, setOpenDrop] = useState<boolean>(false);
  const [multiCheckUserCredentials, setMultiCheckUserCredentials] = useState<string[]>([]);
  const { data: userInfo, error: userInfoError } = useGetLoginUserProfileQuery(
    userId
      ? {
          userId: parseInt(userId),
        }
      : skipToken
  );

  const [pageErrors, setPageErrors] = useState<string[]>([]);

  const validationSchema = yup
    .object({
      firstName: yup.string().required(t('user.editProfile.error.blankFirstName')),
      lastName: yup.string().required(t('user.editProfile.error.blankLastName')),
      email: yup
        .string()
        .email(t('user.editProfile.error.invalidEmail'))
        .required(t('user.editProfile.error.blankEmail')),
      cellPhone: yup.string().test(
        'validate-cell-phone',
        t('user.editProfile.error.cellPhoneNumberTooShort', {
          minLength: PhoneNumberRequired.MinLengthPhoneNumber,
        }),
        function (cellPhone) {
          return validatePhoneNumber(cellPhone);
        }
      ),
      otherPhone: yup.string().test(
        'validate-other-phone',
        t('user.editProfile.error.otherPhoneNumberTooShort', {
          minLength: PhoneNumberRequired.MinLengthPhoneNumber,
        }),
        function (otherPhone) {
          return validatePhoneNumber(otherPhone);
        }
      ),
      mfaType: yup.number().required(t('user.error.blankMfa')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
  } = useForm<LoginUserProfile>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (userInfo) {
      const bindEditInfo = async () => {
        Object.keys(userInfo).forEach((field: any) => {
          setValue(field, checkNullData(userInfo[field as keyof LoginUserProfile]));
        });
        if (userInfo.userCredentials?.length) {
          setMultiCheckUserCredentials(userInfo.userCredentials);
          setValue('userCredentials', userInfo.userCredentials);
        }
      };

      bindEditInfo();
    }
  }, [setValue, userInfo]);

  const onSubmit = async (data: UpdateLoginUserProfileRequest) => {
    const { firstName, lastName } = data;
    const submitData = {
      ...data,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
    };

    try {
      const res = await updateUserProfile(submitData).unwrap();
      if (res) {
        toast.publish(t('user.editProfile.success'), 'success');
        setPageErrors([]);
      }
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };
  const handleClickUserCredentials = () => {
    setOpenDrop(!openDrop);
  };

  const handleSelectAllUserCredentials = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let userCredentials: string[] = [];
    if (isChecked) {
      userCredentials = UserCredentials;
    } else {
      resetField('userCredentials');
    }
    setValue('userCredentials', userCredentials);
    setMultiCheckUserCredentials(userCredentials);
  };
  const handleSelectUserCredentials = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    let userCredentials: string[] = [];
    const existValue = multiCheckUserCredentials.includes(value);
    if (!existValue) {
      userCredentials = [...multiCheckUserCredentials, value];
    } else {
      userCredentials = [...multiCheckUserCredentials.filter((item) => item !== value)];
    }
    setMultiCheckUserCredentials(userCredentials);
    setValue('userCredentials', userCredentials);
  };

  const userCredentialItems = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {(UserCredentials
      ).map((userCredential: string) => {
        return (
          <FormControlLabel
            key={userCredential}
            label={`${userCredential}`}
            value={userCredential}
            control={
              <StyledCheckbox
                checked={multiCheckUserCredentials.includes(userCredential)}
                onChange={handleSelectUserCredentials}
              />
            }
          />
        );
      })}
    </Box>
  );

  if (userInfoError) {
    return <ErrorPage statusCode={(userInfoError as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('user.editProfile.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <PageHeader
            sx={{ mt: 1, mb: 2 }}
            headerText={t('user.editProfile.title')}
            backUrl={'/dashboard'}
          />
        </Container>
        <RoundedContainer sx={{ py: 2 }}>
          <UserInfoCard data={userInfo!} />
        </RoundedContainer>
        <RoundedContainer
          sx={{
            py: 1,
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.editProfile.form.firstName')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.editProfile.form.lastName')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.editProfile.form.email')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="cellPhone"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.cellPhone}
                    helperText={errors.cellPhone?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.editProfile.form.cellPhone')}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="otherPhone"
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    error={!!errors.otherPhone}
                    helperText={errors.otherPhone?.message}
                    margin="normal"
                    fullWidth
                    label={t('user.editProfile.form.otherPhone')}
                    variant="outlined"
                  />
                )}
              />
              <FormControl
                error={!!errors.userCredentials}
                sx={{ width: matches ? '100%' : '49%', height: '83px' }}
              >
                <Box
                  sx={{
                    borderRadius: 2,
                    border: `1px solid ${
                      !!!errors.userCredentials ? theme.xPalette.sliver : theme.xPalette.warning
                    }`,
                    height: '-webkit-fill-available',
                    p: '5px',
                    m: '16px 0px 8px 0px',
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  <ListItemButton
                    onClick={handleClickUserCredentials}
                    sx={{
                      borderRadius: '8px 8px 0px 0px',
                      bottom: '2px',
                      pb: 0,
                      px: 1,
                    }}
                  >
                    <ListItemText
                      sx={{
                        '& .MuiTypography-root': {
                          display: 'inline-block',
                          width: '98%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        },
                      }}
                      primary={
                        multiCheckUserCredentials.length
                          ? UserCredentials
                              .filter((userCredentials) =>
                                multiCheckUserCredentials.some(
                                  (multiUserCredential) =>
                                  multiUserCredential === userCredentials
                                )
                              )
                              .map(
                                (userCredentials) =>
                                  `${userCredentials}`
                              )
                              .join(', ')
                          : t('user.editProfile.form.userCredentials')
                      }
                    />
                    {openDrop ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={openDrop}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: '52px',
                      zIndex: 99,
                      backgroundColor: 'white',
                      width: '100%',
                      border: `1px solid ${
                        !!!errors.userCredentials
                          ? theme.xPalette.sliver
                          : theme.xPalette.warning
                      }`,
                      maxHeight: '400px',
                      overflow: 'auto',
                      borderRadius: '0 0 10px 10px',
                    }}
                  >
                    <List component="div" sx={{ m: '16px 40px' }}>
                      <Controller
                        name="userCredentials"
                        control={control}
                        render={({ field }) => (
                          <>
                            <FormControlLabel
                              {...field}
                              label={`${t('user.editProfile.form.allUserCredentials')}`}
                              control={
                                <StyledCheckbox
                                  checked={UserCredentials.length === multiCheckUserCredentials.length}
                                  indeterminate={
                                    multiCheckUserCredentials.length > 0 &&
                                    multiCheckUserCredentials.length !== UserCredentials.length
                                  }
                                  onChange={handleSelectAllUserCredentials}
                                />
                              }
                            />
                            {userCredentialItems}
                          </>
                        )}
                      />
                    </List>
                  </Collapse>
                </Box>
              </FormControl>
              {userInfo?.mfaEnabled && (
                <Box sx={{ width: matches ? '100%' : '49%', mt: 2 }}>
                  <Typography fontWeight="bold" variant="body1">
                    {t('user.editProfile.form.multiFactor')}
                  </Typography>
                  <Controller
                    name="mfaType"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} row={!matches}>
                        <FormControlLabel
                          value="2"
                          labelPlacement="end"
                          control={<Radio />}
                          label={`${t('user.editProfile.form.cellPhone')}`}
                        />
                        <FormControlLabel
                          value="1"
                          labelPlacement="end"
                          control={<Radio />}
                          label={`${t('user.editProfile.form.email')}`}
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              )}
            </Box>
            {!matches && <Divider sx={{ my: 2 }} />}
            <Box
              sx={{
                width: '100%',
                float: 'right',
              }}
            >
              {pageErrors.length > 0 &&
                pageErrors.map((error) => (
                  <Alert key={error} severity="error" sx={{ mt: 2 }}>
                    {error}
                  </Alert>
                ))}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  width: matches ? '100%' : '24%',
                  my: 2,
                  py: 2,
                  float: 'right',
                }}
              >
                {t('user.editProfile.form.submit')}
              </Button>
            </Box>
          </Box>
        </RoundedContainer>
      </Container>
    </DashboardLayout>
  );
}
