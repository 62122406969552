import { GoalItem } from './GoalType';
import { TreatmentPlanDiagnosisItem } from './TreatmentPlanDiagnosisType';

export interface GetTreatmentPlanListRequest {
  patientId: string;
  search?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
}

export interface GetTreatmentPlanListResponse {
  items: TreatmentPlanItem[];
  totalItems: number;
}

export interface TreatmentPlanItem {
  id: number;
  name: string;
  startDate: Date;
  status: string;
}

export interface DeleteTreatmentPlanRequest {
  id: number;
}

export interface TreatmentPlanInfo {
  patientId: string;
  symptoms?: string;
  name: string;
  problemPatientWords?: string;
  problemPatientWordsIfNeeded?: string;
  startDate: Date | any;
  projectedCompletionDate: Date | any;
  completionDate: Date | any;
  nextReviewDate: Date | any;
  patientStrengths?: string;
  patientAbilities?: string;
  patientNeedsLimitations?: string;
  patientPreferences?: string;
  clinicalIssues?: string;
  medicalIssues?: string;
  psychiatricIssues?: string;
  completionCriteria: string;
}

export interface editTreatmentPlanRequest extends TreatmentPlanInfo {
  treatmentPlanId?: number;
}

export interface GetTreatmentDetailRequest {
  treatmentPlanId: number;
  isIncludeGoals?: boolean;
  isIncludeDiagnose?: boolean;
  isIncludeSignatures?: boolean;
}
export interface GetTreatmentDetailResponse {
  data: TreatmentPlanResponse;
}

export interface TreatmentPlanResponse {
  id: number;
  patientId: string;
  name: string;
  symptoms?: string;
  problemPatientWords?: string;
  problemPatientWordsIfNeeded?: string;
  startDate?: Date;
  projectedCompletionDate?: Date;
  completionDate?: Date;
  nextReviewDate?: Date;
  patientStrengths?: string;
  patientAbilities?: string;
  patientNeedsLimitations?: string;
  patientPreferences?: string;
  clinicalIssues?: string;
  medicalIssues?: string;
  psychiatricIssues?: string;
  patientHasEmail?: boolean;
  patientSMSOptIn?: boolean;
  completionCriteria?: string;
  status?: string;
  createdBy: number;
  lastModifiedBy: number;
  treatmentGoals?: GoalItem[];
  treatmentDiagnoses?: TreatmentPlanDiagnosisItem[];
  treatmentSignatures?: TreatmentSignature[];
  logo?: string;
}

export interface TreatmentSignature {
  id: number;
  treatmentPlanId: number;
  signatureProviderType: string;
  providerName: string;
  providerId?: number;
  signatureType?: string;
  signature?: string;
  guardianName?: string;
  tokenExpiration?: Date;
  tokenForEmail?: string;
  signingUrl?: string;
  status?: string;
  createdAt?: string;
  lastModifiedBy?: number;
  signedDate?: Date;
}

export enum TreatmentPlanStatus {
  Active = 'active',
  CompletedNotSigned = 'completedNotSigned',
  CompletedSigned = 'completedSigned',
}
