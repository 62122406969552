import { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SubmitButton from '../../../common/components/SubmitButton';
import { maximumImportPatientFileUpload } from '../../../common/utils/validationUtils';
import { ClinicAccountItems } from '../../../types/ClinicAccountType';
import { SubmitButtonProps } from '../../../types/CommonType';

interface ImportPatientFormProps {
  clinicAccounts?: ClinicAccountItems[];
  handleSubmit: any;
  control: any;
  setValue: any;
  errors: any;
  setPageErrors: (pageErrors: string[]) => void;
  pageErrors: string[];
  setErrorLineItems: (errorItems: { [key: string]: string[] }[]) => void;
  hasErrorLineItems?: boolean;
}

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  '& .MuiOutlinedInput-root': {
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default function ImportPatientForm({
  clinicAccounts,
  handleSubmit,
  control,
  setValue,
  errors,
  setPageErrors,
  pageErrors,
  setErrorLineItems,
  hasErrorLineItems,
}: ImportPatientFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [dataFile, setDataFile] = useState<File | null>(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const fileInputRef = useRef<HTMLInputElement>(null);

  const NewSubmitButton = withSubmitButton(
    SubmitButton,
    t('patient.importPatient.submit'),
    matches,
    pageErrors
  );

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getAccountOptionLabel(option),
  });

  const getAccountOptionLabel = (option: any) => `${option.name} (${option.id})`;

  const handleAccountChange = (e: React.SyntheticEvent, value: any) => {
    const accountId = value ? value.id : '';
    setValue('clinicAccount', accountId);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const fileValidateErrors: string[] = [];
    setPageErrors([]);

    if (file) {
      if (!file.name.includes('csv') && !file.name.includes('excel')) {
        fileValidateErrors.push(t('user.manageUsers.error.invalidFileFormat'));
      }

      if (file.size / (1024 * 1024) > maximumImportPatientFileUpload) {
        fileValidateErrors.push(
          t('user.manageUsers.error.importFileLargeThanMaximum', {
            maximumFileUpload: maximumImportPatientFileUpload,
          })
        );
      }
      setPageErrors(fileValidateErrors);
      setErrorLineItems([]);
      setDataFile(file);

      if (fileValidateErrors.length === 0) {
        setValue('file', file);
      }
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{
        px: matches ? 0 : 1,
        py: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Controller
          name="clinicAccount"
          control={control}
          render={({ fieldState, formState, field: { value }, ...props }) => (
            <StyleAutocomplete
              style={{ width: isDesktop ? '78%' : '100%' }}
              {...props}
              data-testid="mui-component-select-clinic-account"
              options={clinicAccounts || []}
              getOptionLabel={getAccountOptionLabel}
              onChange={handleAccountChange}
              value={value ? clinicAccounts?.find((item) => item.id === value) || null : null}
              filterOptions={filterAccountOptions}
              renderOption={(props, option: any) => {
                return (
                  <li {...props} key={option.id} value={option.id}>
                    {getAccountOptionLabel(option)}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  fullWidth
                  label={t('user.manageUsers.userForm.account')}
                  error={!!errors.clinicAccount}
                  helperText={errors.clinicAccount?.message}
                />
              )}
            />
          )}
        />

        <Controller
          name="file"
          control={control}
          render={() => (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  fileInputRef.current?.click();
                }}
                sx={{
                  width: matches ? '100%' : '20%',
                  minHeight: '60px',
                  height: '60px',
                  mt: 2,
                  mb: 1,
                  py: 2,
                  [theme.breakpoints.down('md')]: {
                    mt: 2,
                    mb: 1,
                  },
                }}
              >
                {t('patient.importPatient.selectFile')}
              </Button>
              <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                onChange={handleFileChange}
                onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                  const element = event.target as HTMLInputElement;
                  element.value = '';
                }}
                style={{ display: 'none' }}
              />
            </>
          )}
        />
      </Box>
      <Typography
        component="div"
        variant="body1"
        sx={isDesktop ? { marginLeft: '0%' } : { width: '100%', float: 'right' }}
      >
        {dataFile?.name && `${t('user.manageUsers.fileName')}: ${dataFile?.name}`}
      </Typography>
      {dataFile && !hasErrorLineItems && <NewSubmitButton />}
    </Box>
  );
}

function withSubmitButton<P extends SubmitButtonProps>(
  WrappedComponent: React.ComponentType<P>,
  content?: string,
  matches?: boolean,
  pageErrors?: string[]
): React.FC<P> {
  return (props: P) => (
    <Box
      sx={{
        '&.MuiBox-root .MuiBox-root': {
          display: pageErrors && pageErrors.length > 0 ? 'none' : 'flex',
          justifyContent: 'flex-start',
        },
      }}
    >
      <WrappedComponent {...props} content={content} pageErrors={pageErrors} matches={matches} />
    </Box>
  );
}
