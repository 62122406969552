import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as ResetPasswordIconSvg } from '../../../assets/icons/password-check.svg';

export default function ResetPasswordIcon() {
  const theme = useTheme();
  return (
    <SvgIcon
      component={ResetPasswordIconSvg}
      sx={{ '& path': { fill: 'white', stroke: theme.xPalette.shadeGrey } }}
    />
  );
}
