import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { convertCmToFeetInches } from '../../../../common/utils/commonCalculate';
import { AnswerUnit } from '../../../../common/utils/reportTypes';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import ReportNotes from '../ReportNotes';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function HeightAnswer({ questionAnswer, moduleType, notes }: QuestionAnswerProps) {
  const { questionDisplay, promptDisplay, scoreForQuestion, answerValue } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
  });

  const displayHeightAnswer = () => {
    let newQuestionAnswer = answerValue || 0;
    if (answerValue && answerValue !== '') {
      if (questionAnswer.answeredUnitSystem === AnswerUnit.Imperial) {
        // Convert to feet/inches
        const imperialConversion = convertCmToFeetInches(answerValue);
        newQuestionAnswer = imperialConversion.feet + "' " + imperialConversion.inches + '"';
      } else {
        newQuestionAnswer += ' cm';
      }
    }

    return newQuestionAnswer;
  };

  return (
    <Box
      key={questionAnswer.questionId}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 1,
        my: 3,
        width: '100%',
        pageBreakInside: 'avoid',
      }}
    >
      <Box sx={{ width: '15%', display: 'flex', columnGap: 1 }}>
        <Typography sx={{ fontWeight: 500 }} component={'span'}>
          {questionAnswer.title || ''}
        </Typography>
        <Typography sx={{ fontWeight: 500 }} component={'span'}>
          {questionAnswer.subTitle || ''}
        </Typography>
      </Box>

      <Box sx={{ width: '75%' }}>
        {promptDisplay && (
          <Typography
            fontWeight="bold"
            dangerouslySetInnerHTML={{
              __html: promptDisplay,
            }}
          ></Typography>
        )}
        <Typography
          fontWeight={500}
          dangerouslySetInnerHTML={{
            __html: questionDisplay,
          }}
        ></Typography>
        {notes?.length && (
          <ReportNotes reportNotes={notes} questionId={questionAnswer.questionId} />
        )}
      </Box>
      <Typography sx={{ width: '10%', textAlign: 'right' }} component={'span'} fontWeight={'bold'}>
        {displayHeightAnswer() || ''}
      </Typography>
      <Typography sx={{ width: '5%', textAlign: 'right' }} component={'span'} fontWeight={'bold'}>
        {scoreForQuestion}
      </Typography>
    </Box>
  );
}
