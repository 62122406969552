export const MonitorModeName: { Patient: string; Clinician: string; Email: string; Schedule: string } =
  {
    Patient: 'patient',
    Clinician: 'clinician',
    Email: 'email',
    Schedule: 'schedule',
  };

  interface ModeNameType {
    [key: string]: string;
  }

  export const monitorModeCode: ModeNameType = {
    C: MonitorModeName.Clinician,
    P: MonitorModeName.Patient,
  };
