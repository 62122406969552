import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { toTitleCase } from '../../../common/utils/stringUtils';
import { MonitorQuestionProps } from '../../../types/MonitorTypes';
import {
  BinaryValue,
  StyledFormControl,
  StyledFormLabelNo,
  StyledFormLabelYes,
  StyledRadio
} from '../../interview/questions/BinaryQuestion';

const binaryPossibleValue = ['yes', 'no'];

export default function BinaryQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  moduleId,
  questionId,
  checkRelativeQuestion,
}: MonitorQuestionProps) {
  const [binaryQuestionValue, setBinaryValue] = useState<string>('');
  const renderAnswers = (binaryValue: string) => {
    let { possibleAnswers } = question;

    let items: any = {};
    if (possibleAnswers[0] === '' || possibleAnswers.length < 1) {
      possibleAnswers = binaryPossibleValue;
    }
    for (let i = 0; i < possibleAnswers.length; i++) {
      const value = possibleAnswers[i];
      const text = toTitleCase('' + possibleAnswers[i].trim()).trim();
      items = { ...items, [value]: text };
    }
    return items[binaryValue];
  };

  const handleBinaryChange = (e: any) => {
    const binaryValue = e.target.value;
    checkRelativeQuestion({
      moduleId: moduleId!,
      questionId: questionId!,
      questionAnswer: binaryValue.toUpperCase(),
    });
    setBinaryValue(binaryValue);
  };

  useEffect(() => {
    setValue(field?.name, binaryQuestionValue);
  }, [binaryQuestionValue, field?.name, setValue]);

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', columnGap: 1 }}>
        {question.title && (
          <Typography
            dangerouslySetInnerHTML={{
              __html: question.title,
            }}
          ></Typography>
        )}
        <Typography
          dangerouslySetInnerHTML={{
            __html: question.questionText!,
          }}
        ></Typography>
      </Box>
      <StyledFormControl error={error} variant="standard">
        <RadioGroup onChange={handleBinaryChange} row>
          <StyledFormLabelNo
            value={BinaryValue.No}
            itemScope={binaryQuestionValue === BinaryValue.No}
            labelPlacement="start"
            control={<StyledRadio checkedIcon={<CheckIcon />} />}
            label={renderAnswers(BinaryValue.No)}
          />
          <StyledFormLabelYes
            value={BinaryValue.Yes}
            itemScope={binaryQuestionValue === BinaryValue.Yes}
            labelPlacement="start"
            control={<StyledRadio checkedIcon={<CheckIcon />} />}
            label={renderAnswers(BinaryValue.Yes)}
          />
        </RadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </StyledFormControl>
    </Box>
  );
}
