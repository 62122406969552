import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SubQuestionAnswerProps } from '../../../../../types/ReportType';

const renderCheckBoxIcon = (answer: string, isCheck?: boolean): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', columnGap: 1 }}>
      <Box>
        {isCheck ? <CheckBoxIcon fontSize="medium" /> : <CheckBoxBlankIcon fontSize="medium" />}
      </Box>
      <Box>
        <Typography variant="body1" fontWeight={500}>
          {answer || ''}
        </Typography>
      </Box>
    </Box>
  );
};

export default function SubMultiOptionsAnswer({
  monitorSubQuestion,
  subQuestionAnswer,
}: SubQuestionAnswerProps) {
  const renderSubAnswer = () => {
    const newSubAnswers =
      (subQuestionAnswer &&
        Array.isArray(subQuestionAnswer[0]) &&
        subQuestionAnswer[0].filter((sub: string) => !!sub)) ||
      [];
    const possibleAnswers =
      monitorSubQuestion?.possibleAnswers && monitorSubQuestion?.possibleAnswers.length > 0
        ? monitorSubQuestion?.possibleAnswers[0].split(',')
        : [];

    return possibleAnswers.map((possibleAnswer: string) => {
      if (
        newSubAnswers.some((newSubAnswer: string) => possibleAnswer.trim() === newSubAnswer.trim())
      ) {
        return renderCheckBoxIcon(possibleAnswer, true);
      } else {
        return renderCheckBoxIcon(possibleAnswer);
      }
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1, ml: '15%' }}>
      <Typography
        variant="body1"
        fontWeight="bold"
        dangerouslySetInnerHTML={{
          __html: monitorSubQuestion?.questionText || '',
        }}
      ></Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>{renderSubAnswer()}</Box>
    </Box>
  );
}
