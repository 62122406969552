import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as yup from 'yup';
import { LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { useGetPatientQuery } from '../../app/services/patient';
import { useUpdateAssessmentScheduleMutation } from '../../app/services/schedule';
import { useToast } from '../../app/toast';
import CommonDatePicker from '../../common/components/CommonDatePicker';
import { setContentPopup, setIsOpenPopup } from '../../common/slices/globalSlice';
import { AssessmentMode } from '../../common/utils/assessmentMode';
import { AssessmentType } from '../../common/utils/assessmentType';
import checkNullData from '../../common/utils/checkNullData';
import { formatServerDate } from '../../common/utils/dateTimeUtils';
import { endDateValidation } from '../../common/utils/endDateValidation';
import { intervals } from '../../common/utils/intervalResource';
import { languages } from '../../common/utils/languages';
import { isBefore } from '../../common/utils/treatmentPlans';
import {
  AssessmentScheduleItem,
  AssessmentScheduleResponse,
  AssessmentScheduleUpdateRequest,
} from '../../types/AssessmentSchedule';
import { ResponseError } from '../../types/ResponseError';

const StyledMenuItem = styled(MenuItem)(() => ({
  maxWidth: 440,
  '&.MuiMenuItem-root': {
    whiteSpace: 'unset',
    lineBreak: 'strict',
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '50%',
  padding: '12px 0px',
  borderRadius: '10px',
  textTransform: 'none',
  [theme.breakpoints.up('md')]: {
    width: '20%',
    minWidth: '200px',
  },
}));

interface AssessmentScheduleProps {
  assessmentType: AssessmentType;
  assessmentScheduleData: AssessmentScheduleResponse;
  onSuccessCallback: () => Promise<void>;
}

interface PopupContent {
  title: string;
  description: string;
  btnOk: string;
  btnClose: string;
  toastMessage: string;
}

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

export default function ScheduleForm({
  assessmentType,
  assessmentScheduleData,
  onSuccessCallback,
}: AssessmentScheduleProps) {
  const { id, language, createdAt, interval, startDate, endDate } =
    assessmentScheduleData.assessmentSchedule;
  const theme = useTheme();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [endDateError, setEndDateError] = useState<string>('');
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const { patientId } = useParams();
  const toast = useToast();
  const [updateAssessmentSchedule] = useUpdateAssessmentScheduleMutation();
  const dispatch = useAppDispatch();

  const { data: infoData } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  dayjs.tz.setDefault(infoData?.timezone);

  const defaultFormValues: AssessmentScheduleItem = {
    id,
    language,
    interval: interval || 1,
    startDate: startDate ? dayjs(startDate).tz(infoData?.timezone) : null,
    endDate: endDate ? dayjs(endDate).tz(infoData?.timezone) : null,
  };

  const validationSchema = yup
    .object({
      interval: yup.string(),
      startDate: yup
        .date()
        .when('interval', {
          is: (interval: string) => parseInt(interval) !== 0,
          then: yup.date().required(t('patient.error.blankStartDate')),
        })
        .test('start-date', t('interview.form.errors.validStartDate'), function (startDate) {
          const endDate: Date = getValues('endDate');
          if (endDate && startDate) {
            return !isBefore(endDate, startDate);
          }
          return true;
        }),
      endDate: yup
        .date()
        .typeError(`${t('interview.form.errors.invalidEndDate')}`)
        .test('end-date', '', function (endDate) {
          const interval = getValues('interval') || 1;
          const startDate = getValues('startDate');

          if (endDate) {
            const suggestDate = endDateValidation({
              assessmentMode: AssessmentMode.Email,
              interval,
              timezone: infoData?.timezone,
              startDate,
              endDate,
            });

            if (suggestDate) {
              setEndDateError(`${t('interview.form.errors.validEndDate')} ${suggestDate}`);
              return false;
            }
          }
          setEndDateError('');
          return true;
        })
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<AssessmentScheduleItem>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const stopScheduleContentPopup = {
    title: t('patient.stopSchedule.title'),
    description: t('patient.stopSchedule.description'),
    btnOk: t('patient.stopSchedule.btnOk'),
    btnClose: t('patient.stopSchedule.btnClose'),
    toastMessage: t('patient.stopSchedule.toastMessage'),
  } as PopupContent;

  const onClick = async () => {
    dispatch(setContentPopup({ content: stopScheduleContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
  };

  const renderIntervals = () => {
    return intervals
      .filter((x) => x !== 'Once')
      .map((interval, index) => (
        <StyledMenuItem key={interval} value={index + 1}>
          {interval}
        </StyledMenuItem>
      ));
  };

  useEffect(() => {
    if (!isDataLoaded && id !== undefined) {
      setIsDataLoaded(true);
      setValue('interval', checkNullData(interval));
    }
  }, [isDataLoaded, setValue, id, endDate, interval, startDate]);

  const onSubmit = async (data: AssessmentScheduleUpdateRequest) => {
    try {
      const res = await updateAssessmentSchedule({ ...data, assessmentType }).unwrap();
      if (res) {
        toast.publish(t('schedule.form.success'), 'success');
        setPageErrors([]);
      }
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        px: matches ? 4 : 10,
        display: 'flex',
        flexDirection: 'column',
        columnGap: 2,
      }}
    >
      <Box
        sx={{
          columnGap: 1,
          display: 'flex',
          justifyContent: 'space-between',
          width: matches ? '100%' : '50%',
        }}
      >
        <Box>
          <Typography fontWeight={600}>{t('schedule.form.language')}</Typography>
        </Box>
        <Box>{language && languages[language].name}</Box>
      </Box>
      <Box
        sx={{
          columnGap: 1,
          display: 'flex',
          mt: 1,
          justifyContent: 'space-between',
          width: matches ? '100%' : '50%',
        }}
      >
        <Box>
          <Typography fontWeight={600}>{t('schedule.form.dateCreated')}</Typography>
        </Box>
        <Box>{formatServerDate(createdAt)}</Box>
      </Box>
      <Box
        sx={{
          columnGap: 1,
          display: 'flex',
          justifyContent: 'space-between',
          width: matches ? '100%' : '50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography fontWeight={600}>{t('schedule.form.interval')}</Typography>
        </Box>
        <Box
          sx={{
            flexBasis: '50%',
          }}
        >
          <Controller
            name="interval"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.interval}
                helperText={errors.interval?.message}
                margin="normal"
                select
                fullWidth
                variant="outlined"
              >
                {renderIntervals()}
              </TextField>
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          columnGap: 1,
          display: 'flex',
          flexDirection: 'column',
          width: matches ? '100%' : '50%',
        }}
      >
        <Box
          sx={{
            columnGap: 1,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography fontWeight={600}>{t('schedule.form.startDate')}</Typography>
          </Box>
          <Box
            sx={{
              flexBasis: '50%',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.tz}>
              <CommonDatePicker
                name="startDate"
                minDate={dayjs.tz(new Date(), infoData?.timezone)}
                control={control}
                error={!!errors.startDate}
                helperText={errors.startDate?.message}
                label={t('schedule.form.startDate')}
                hideClearButton
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box
          sx={{
            columnGap: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          columnGap: 1,
          display: 'flex',
          flexDirection: 'column',
          width: matches ? '100%' : '50%',
        }}
      >
        <Box
          sx={{
            columnGap: 1,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography fontWeight={600}>{t('schedule.form.endDate')}</Typography>
          </Box>
          <Box
            sx={{
              flexBasis: '50%',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.tz}>
              <CommonDatePicker
                name="endDate"
                minDate={dayjs.tz(new Date(), infoData?.timezone)}
                control={control}
                error={!!endDateError || !!errors.endDate}
                helperText={endDateError || errors.endDate?.message}
                label={t('schedule.form.endDate')}
                matches={matches}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box
          sx={{
            columnGap: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        ></Box>
      </Box>
      {pageErrors.length > 0 &&
        pageErrors.map((error) => (
          <Alert key={error} severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        ))}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          columnGap: 1,
          mt: 1,
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            columnGap: 1,
          },
        }}
      >
        <StyledButton variant="outlined" onClick={onClick}>
          {t('schedule.form.stopSchedule')}
        </StyledButton>
        <StyledButton type="submit" variant="contained">
          {t('schedule.form.save')}
        </StyledButton>
      </Box>
    </Box>
  );
}
