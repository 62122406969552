import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectResetQuestion, setResetQuestion } from '../../interview/interviewSlice';
import { StyledRadioCheckIcon, StyledRadioIcon } from './SdsScaleQuestion';

export const StyledFormLabel = styled(FormControlLabel)(() => ({
  margin: 0,
  '&.MuiFormControlLabel-root .MuiFormControlLabel-label ': {
    fontSize: '12px',
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme, error }) => ({
  flexDirection: 'column',
  margin: '16px 0px',
  width: '100%',
  '&.MuiFormControl-root .MuiFormGroup-root .MuiFormControlLabel-root': {
    borderColor: error ? theme.palette.error.main : '',
  },

  '&.MuiFormControl-root .MuiFormGroup-root': {
    flexWrap: 'wrap-reverse',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  columnGap: '8px',
  width: '100%',
  justifyContent: 'space-around',
  marginBottom: '8px',
}));

export default function StsScaleQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
}: InterviewQuestionProps) {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);
  const dispatch = useAppDispatch();
  const previousAnswer = field?.value;
  const stsMeasurement: string[] = Object.values(
    t('interview.form.sts.stsScale', { returnObjects: true })
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stsValue = event.target.value;
    setSelectedValue(stsValue);
    dispatch(setResetQuestion({ reset: false }));
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item || (previousAnswer.toString() === item && !selectedValue),
    onChange: handleChange,
    value: item,
    name: 'sts-scale-radio-button',
    inputProps: { 'aria-label': item },
  });

  useEffect(() => {
    setValue(field?.name!, selectedValue);
  }, [field?.name, selectedValue, setValue]);

  useEffect(() => {
    setValue(field?.name!, selectedValue);
    if (isResetQuestion) {
      setSelectedValue('');
    }
  }, [field?.name, selectedValue, setValue, isResetQuestion]);

  const renderAnswers = () => {
    const { possibleAnswers } = question;

    return possibleAnswers.map((item) => {
      return (
        <StyledFormLabel
          key={item}
          value={item}
          labelPlacement="bottom"
          control={
            <Radio
              checkedIcon={<StyledRadioCheckIcon>{item}</StyledRadioCheckIcon>}
              icon={<StyledRadioIcon>{item}</StyledRadioIcon>}
              {...controlProps(item)}
            />
          }
          label={stsMeasurement[parseInt(item)]}
        />
      );
    });
  };

  return (
    <Box sx={{ mt: 2 }}>
      {question.name && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: `${question.name}:`,
          }}
        ></StyledTypography>
      )}
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <Typography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></Typography>
      <StyledFormControl error={error} variant="standard">
        <StyledRadioGroup row>{renderAnswers()}</StyledRadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </StyledFormControl>
    </Box>
  );
}
