import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '../../app/hooks';
import { ContentPopup } from '../../types/CommonType';
import { setContentPopup, setIsOpenPopup } from '../slices/globalSlice';

interface PopupModalProps {
  isOpen: boolean;
  contentPopup: ContentPopup;
  onClick: () => Promise<void>;
  onClose?: () => void;
}

export default function PopupModal({ isOpen, contentPopup, onClick, onClose }: PopupModalProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleClose = (event: SyntheticEvent) => {
    if (['btnClose'].includes(event.currentTarget.id)) {
      dispatch(setIsOpenPopup({ value: false }));
      dispatch(setContentPopup({ content: undefined }));
      setOpen(false);
      if (onClose) {
        onClose();
      }
    }
  };

  const handleOk = (event: SyntheticEvent) => {
    if (['btnOk'].includes(event.currentTarget.id)) {
      dispatch(setIsOpenPopup({ value: false }));
      dispatch(setContentPopup({ content: undefined }));
      setOpen(false);
      onClick();
    }
  };
  useCallback(() => {
    dispatch(setIsOpenPopup({ value: false }));
  }, [dispatch]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open || false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={contentPopup?.warningTitle && contentPopup?.warningContent ? 'sm' : 'md'}
    >
      <DialogTitle
        sx={{ pt: 6, textAlign: 'center', fontSize: (theme) => theme.typography.h1.fontSize }}
      >
        {contentPopup?.title}
        <IconButton
          id="btnClose"
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {contentPopup?.warningTitle && contentPopup?.warningContent && (
          <DialogContentText>
            <Typography
              component="span"
              sx={(theme) => ({ color: theme.xPalette.warning, fontWeight: 900 })}
            >
              {contentPopup?.warningTitle}:
            </Typography>
            <Typography
              component="span"
              sx={(theme) => ({ color: theme.xPalette.warning, marginLeft: '5px' })}
            >
              {contentPopup?.warningContent}
            </Typography>
          </DialogContentText>
        )}
        <DialogContentText sx={{ whiteSpace: 'pre-wrap' }}>
          {contentPopup?.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            height: '130px',
          },
        })}
      >
        <Link
          id="btnClose"
          component="button"
          sx={(theme) => ({
            fontSize: '14px',
            [theme.breakpoints.down('sm')]: {
              height: '130px',
            },
            textTransform: 'none',
            width: '100%',
            padding: '12px 0px',
            [theme.breakpoints.up('sm')]: {
              width: '20%',
              minWidth: '120px',
            },
            [theme.breakpoints.down('sm')]: {
              position: 'absolute',
              width: '95%',
              marginTop: '60px',
              textAlign: 'center',
            },
          })}
          onClick={handleClose}
        >
          {contentPopup?.btnClose}
        </Link>
        <Button
          id="btnOk"
          variant="contained"
          sx={(theme) => ({
            backgroundColor: '#388AC8',
            textTransform: 'none',
            width: '100%',
            padding: '12px 0px',
            borderRadius: '6px',
            [theme.breakpoints.up('sm')]: {
              width: '20%',
              minWidth: '150px',
            },
            [theme.breakpoints.down('sm')]: {
              position: 'absolute',
              width: '95%',
              marginBottom: '60px',
            },
          })}
          onClick={handleOk}
        >
          {contentPopup?.btnOk}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
