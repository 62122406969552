import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ClinicSiteDetail } from '../../types/ClinicSiteType';
import { formatDate } from '../utils/dateTimeUtils';
import SiteIcon from './icons/SiteIcon';
import { useTheme } from '@mui/material/styles';

interface Props {
  data: ClinicSiteDetail;
}

export default function SiteInfoCard({ data }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { id, clinicAccountId, clinicAccountName, createdAt } = data || {};

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <SiteIcon />
        <Box sx={(theme) => ({ color: theme.xPalette.lightGrey, ml: 1 })}>
          <Typography variant="body1">
            {t('clinicSite.manageSites.siteInfoCard.siteId')} {' '}
            <Typography component="span" sx={{ fontWeight: 600, color: theme.xPalette.black }}>
              {id}
            </Typography>
          </Typography>
          <Typography variant="body1">
            {t('clinicSite.manageSites.siteInfoCard.account')} {' '}
            <Typography component="span" sx={{ fontWeight: 600, color: theme.xPalette.black }}>
              {clinicAccountName ? clinicAccountName + ' (' + clinicAccountId + ')' : ''}
            </Typography>
          </Typography>
          <Typography variant="body1">
            {t('clinicSite.manageSites.siteInfoCard.createdDate')} {' '}
            <Typography component="span" sx={{ fontWeight: 600, color: theme.xPalette.black }}>
              {formatDate(createdAt)}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
