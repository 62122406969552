import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Container from '@mui/material/Container';
import { useAppSelector } from '../../app/hooks';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import DashboardLayout from '../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { canAddEditTemplate } from '../../common/utils/permissionUtils';
import { PageProps } from '../../types/PageProps';
import { selectUser } from '../auth/authSlice';
import ErrorPage from '../error/ErrorPage';
import TemplateForm from './TemplateForm';
import { useAddTemplateMutation, useEditTemplateMutation, useGetTemplateDataQuery } from '../../app/services/template';
import { TemplateDetail } from '../../types/TemplateType';

export default function AddOrEditTemplatePage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const params = useParams();
  const templateId: string | undefined = params.templateId;

  const currentUser = useAppSelector(selectUser);
  const canAddEdit = canAddEditTemplate(currentUser.roleId);
  const [addTemplate] = useAddTemplateMutation();
  const [editTemplate] = useEditTemplateMutation();

  const { data, error } = useGetTemplateDataQuery(
    templateId && canAddEdit ? { id: parseInt(templateId) } : skipToken
  );

  const templateData = data?.data;

  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const newBreadcrumb: BreadcrumbType = {
    text: t('eSignature.template.title'),
    link: navBackUrl || `/dashboard/templates`,
  };

  const onFormSubmit = async (templateData: TemplateDetail, templateId?: number, successCallback?: () => void) => {
    if (templateId) {
      await editTemplate({ ...templateData, id: templateId }).unwrap();
    } else {
      await addTemplate({ ...templateData }).unwrap();
      navigate(`/dashboard/templates`);
    }

    if (successCallback) {
      successCallback();
    }

    toast.publish(
      (templateId && t('eSignature.template.updateSuccess')) ||
        t('eSignature.template.addSuccess'),
      'success'
    );
  };

  if (templateId && !canAddEdit) {
    return <ErrorPage statusCode={HttpStatusCode.Forbidden} />;
  }
  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>
          {templateId
            ? getPageTitle(t('eSignature.template.edit'))
            : getPageTitle(t('eSignature.template.add'))}
        </title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={
            templateId ? t('eSignature.template.edit') : t('eSignature.template.add')
          }
          backUrl={navBackUrl || `/dashboard/templates`}
        />
      </Container>

      <TemplateForm
        templateId={templateId ? parseInt(templateId) : 0}
        templateData={templateData}
        onFormSubmit={onFormSubmit}
      />
    </DashboardLayout>
  );
}
