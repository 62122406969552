import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetRescoreJobsQuery } from '../../app/services/interview';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { formatDate } from '../../common/utils/dateTimeUtils';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { RescoreJobInterview } from '../../types/RescoreInterviewJob';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledAddWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '12px 0px',
  width: '250px',
  minWidth: '110px',
  padding: '0px 8px',
  columnGap: '8px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    width: '100%',
  },
}));

const StyledAddUserBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: 0,
  height: '56px',
  ml: 1,
  [theme.breakpoints.down('sm')]: {
    width: '96%',
    ml: 0,
  },
  [theme.breakpoints.down('md')]: {
    width: '96%',
    ml: 0,
  },
}));
export default function RescoreJobListPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [searchParams] = useSearchParams();
  const [displayRescoreData, setDisplayRescoreData] = useState<RescoreJobInterview[]>([]);
  const [navBackUrl, setNavBackUrl] = useState<string>('/dashboard/rescore-jobs');
  const tableHeaders: string[] = Object.values(
    t('interviews.rescoreJobs.manageJobList.tableHeader', {
      returnObjects: true,
    })
  );

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
  };

  const currentPage = searchOptions.page;
  const tableRef = useRef<HTMLDivElement>(null);

  const { data: rescoreData, isLoading: isLoadingSite } = useGetRescoreJobsQuery({
    page: currentPage,
    pageSize: pageSize,
  });

  const updateSearchUrl = (updatedData: any) => {
    const params = {
      ...searchOptions,
      ...updatedData,
    };
    // eslint-disable-next-line no-useless-concat
    const url = generatePath('/dashboard/rescore-jobs' + '?page=:page', params);
    navigate(url, { replace: true });
    setNavBackUrl(url);
  };

  useEffect(() => {
    if (rescoreData) {
      setDisplayRescoreData(rescoreData.items);
      setTotalPages(rescoreData.totalPages);
    }
  }, [pageSize, rescoreData]);

  const handlePageChange = (value: number) => {
    updateSearchUrl({ page: value });
  };

  const renderDesktopData = (data: RescoreJobInterview[]) => {
    return (
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ borderRadius: '16px 16px 0 0', marginTop: '10pxÏ' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => {
                return (
                  <StyledTableCell key={`${header} ${index}`} align={'inherit'}>
                    {header}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: RescoreJobInterview) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell sx={{ maxWidth: '300px', minWidth: '150px' }}>{item.name}</StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '200px' }}>
                    {item.affectedRecords}
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>{item.progress}</StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>{formatDate(item.dateStarted)}</StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>{`${item.startedBy.lastName}, ${item.startedBy.firstName}`}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: RescoreJobInterview[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: RescoreJobInterview) => {
          const listItems = [
            [
              <>{t('interviews.rescoreJobs.manageJobList.tableHeader.affectedRecords')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.affectedRecords}</Box>,
            ],
            [
              <>{t('interviews.rescoreJobs.manageJobList.tableHeader.progress')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.progress}</Box>,
            ],
            [
              <>{t('interviews.rescoreJobs.manageJobList.tableHeader.startedAt')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{formatDate(item.dateStarted)}</Box>,
            ],
            [
              <>{t('interviews.rescoreJobs.manageJobList.tableHeader.startedBy')}</>,
              <Box
                sx={{ wordBreak: 'break-word' }}
              >{`${item.startedBy.lastName}, ${item.startedBy.firstName}`}</Box>,
            ],
          ];

          return {
            leftHeader: (
              <Typography variant="body1" fontWeight={600} fontSize={20}>
                {item.name}
              </Typography>
            ),
            rightHeader: <></>,
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  const isLoading = isLoadingSite;

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('interviews.rescoreJobs.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={!isDesktop}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '10px',
            },
          }}
        >
          <PageHeader
            sx={{ mx: isDesktop ? 0 : 2 }}
            headerText={t('interviews.rescoreJobs.title')}
            backUrl={'/dashboard'}
          />
          <StyledAddWrapper>
            <StyledAddUserBox
              type="button"
              fullWidth
              variant="contained"
              onClick={() => {
                navigate('/dashboard/rescore-jobs/add-jobs', { state: { navBackUrl } });
              }}
            >
              {t('interviews.rescoreJobs.addJobs.title')}
            </StyledAddUserBox>
          </StyledAddWrapper>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={tableRef}>
            {displayRescoreData.length === 0 ? (
              <RoundedContainer sx={{ mt: 2, p: 2 }}>
                <Typography>
                  {t('interviews.rescoreJobs.manageJobList.error.rescoreJobsNotFound')}
                </Typography>
              </RoundedContainer>
            ) : (
              <>
                {isDesktop
                  ? renderDesktopData(displayRescoreData)
                  : renderMobileData(displayRescoreData)}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 4,
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    background: theme.xPalette.white,
                    mt: 0,
                    [theme.breakpoints.down('sm')]: {
                      mt: 1,
                      py: 2,
                    },
                  }}
                >
                  <CustomPagination
                    changePage={(e, value) => handlePageChange(value)}
                    currentPage={searchOptions.page}
                    pages={totalPages}
                    pageRef={tableRef}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
}
