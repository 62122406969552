import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import { InterviewReportType } from '../../../interview/interviewUtils';
import ReportNotes from '../ReportNotes';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function BinaryAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
  notes,
}: QuestionAnswerProps) {
  const isSdsSts = moduleType === InterviewReportType.SdsSts;
  const isMonitor = moduleType === InterviewReportType.Monitor;

  const { questionDisplay, promptDisplay, scoreForQuestion, answerValue, questionTitle } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <Box
      key={questionAnswer.questionId}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: 1,
        my: 3,
        width: '100%',
        pageBreakInside: 'avoid',
      }}
    >
      <Box sx={{ width: '15%', display: 'flex', columnGap: 1 }}>
        <Typography component={'span'} fontWeight={500}>
          {questionTitle || ''}
        </Typography>
        <Typography component={'span'} fontWeight={500}>
          {(moduleType !== InterviewReportType.Monitor && questionAnswer.subTitle) || ''}
        </Typography>
      </Box>
      <Box sx={{ width: '75%' }}>
        {moduleType !== InterviewReportType.SdsSts && promptDisplay && (
          <Typography
            fontWeight="bold"
            dangerouslySetInnerHTML={{
              __html: promptDisplay,
            }}
          ></Typography>
        )}
        <Typography
          fontWeight={500}
          color={
            isMonitor &&
            questionAnswer.questionText?.includes('%r') &&
            answerValue &&
            answerValue === 'yes'
              ? 'red'
              : 'unset'
          }
          dangerouslySetInnerHTML={{
            __html: questionDisplay,
          }}
        ></Typography>
        {notes?.length && (
          <ReportNotes reportNotes={notes} questionId={questionAnswer.questionId} />
        )}
      </Box>
      {!isSdsSts ? (
        <>
          <Typography
            sx={{ width: '10%' }}
            textAlign={'right'}
            component={'span'}
            fontWeight={'bold'}
          >
            {answerValue || ''}
          </Typography>
          <Typography
            sx={{ width: '5%' }}
            textAlign={'right'}
            component={'span'}
            fontWeight={'bold'}
          >
            {scoreForQuestion}
          </Typography>
        </>
      ) : (
        <>
          <Typography
            sx={{ width: '10%' }}
            textAlign={'right'}
            component={'span'}
            fontWeight={'bold'}
          >
            {answerValue || ''}
          </Typography>
          <Typography
            sx={{ width: '5%' }}
            textAlign={'right'}
            component={'span'}
            fontWeight={'bold'}
          >
          </Typography>
        </>
      )}
    </Box>
  );
}
