import { useState, useEffect, useRef } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectResetQuestion, setResetQuestion } from '../../interview/interviewSlice';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: '8px',
  marginTop: '20px',
  border: `1px solid ${theme.xPalette.disabled}`,
  height: '52px',
  [theme.breakpoints.up('md')]: {
    height: '62px',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
  },
  '&.MuiBox-root': {
    width: 1,
  },
  '&.MuiListItemButton-root.Mui-selected': {
    '.scale-value': {
      color: theme.palette.secondary.main,
    },
    '.scale-value-circle': {
      border: 0,
    },
    '.scale-possible-answers-text': {
      color: theme.xPalette.white,
    },
  },
  '&.MuiListItemButton-root:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function YbocsScaleQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
}: InterviewQuestionProps) {
  const theme = useTheme();
  const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [expanded, setExpanded] = useState<string>();
  const savedValue = field?.value;
  const shouldRequestInterviewRef = useRef(true);
  const dispatch = useAppDispatch();
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);

  const handleExpandDesc = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleListItemClick = (event: any, index: any) => {
    setSelectedIndex(index);
    dispatch(setResetQuestion({ reset: false }));
  };

  useEffect(() => {
    if (savedValue) {
      if (shouldRequestInterviewRef.current) {
        shouldRequestInterviewRef.current = false;
        setSelectedIndex(parseInt(savedValue || -1));
      }
    }
    setValue(field?.name!, selectedIndex! < 0 ? '' : selectedIndex);

    if (isResetQuestion) {
      setSelectedIndex(-1);
      dispatch(setResetQuestion({ reset: false }));
    }
  }, [savedValue, selectedIndex, field, setValue, isResetQuestion, dispatch]);

  const renderQuestions = () => {
    return (
      <Box>
        <Box key={`${field?.name}`}>
          <Box>
            <Typography variant="body2">{question.name}</Typography>
          </Box>
          <Box>
            <Accordion
              expanded={isTabletOrDesktop ? true : expanded === 'descPanel'}
              onChange={handleExpandDesc('descPanel')}
              sx={{
                boxShadow: 'none',
                '.MuiAccordionSummary-root, .MuiAccordionSummary-root.Mui-expanded': {
                  width: '190px',
                  height: '50px',
                  minHeight: 'auto',
                },
                '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded': {
                  margin: 0,
                },
              }}
            >
              <AccordionSummary
                sx={{
                  [theme.breakpoints.up('md')]: {
                    display: 'none',
                  },
                  '.MuiSvgIcon-root': {
                    fontSize: 20,
                  },
                }}
                expandIcon={<ExpandCircleDownOutlinedIcon />}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                  Show descriptions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="subtitle2"
                  dangerouslySetInnerHTML={{
                    __html: question.questionText!,
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          {error && <FormHelperText>{helperText}</FormHelperText>}
          <Box>{renderListItems()}</Box>
        </Box>
      </Box>
    );
  };
  const renderListItems = () => {
    const items = [];
    for (let i = 0; i < question.possibleAnswers.length; i++) {
      const value = question.possibleAnswers[i];
      const displayAnswer = question.displayAnswers[i];
      const possibleAnswersText = question.possibleAnswersText[i];
      items.push(
        <Box
          key={`${field?.name}_${value}`}
          sx={{
            display: 'flex',
          }}
        >
          <FormControl
            variant="standard"
            sx={{
              width: 1,
            }}
          >
            <FormGroup>
              <FormControlLabel
                sx={{ mx: 0 }}
                value={value}
                control={
                  <StyledListItemButton
                    selected={selectedIndex === i}
                    onClick={(event) => handleListItemClick(event, i)}
                  >
                    <Box
                      sx={{
                        backgroundColor: `${theme.xPalette.white}`,
                        border: `1.5px solid ${theme.xPalette.shadeGrey}`,
                        width: '30px',
                        height: '30px',
                        borderRadius: '100px',
                        [theme.breakpoints.up('md')]: {
                          width: '40px',
                          height: '40px',
                        },
                      }}
                      className="scale-value-circle"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: theme.xPalette.lightGrey,
                            fontWeight: theme.typography.h1.fontWeight,
                            fontSize: theme.typography.body2,
                          }}
                          className="scale-value"
                        >
                          {i}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        ml: 2,
                      }}
                    >
                      <Typography className="scale-possible-answers-text">
                        {possibleAnswersText}
                      </Typography>
                    </Box>
                  </StyledListItemButton>
                }
                label=""
              />
              <Typography
                variant="subtitle2"
                sx={{
                  color: theme.xPalette.lightGrey,
                  ml: 2,
                }}
                dangerouslySetInnerHTML={{
                  __html: displayAnswer,
                }}
              ></Typography>
            </FormGroup>
          </FormControl>
        </Box>
      );
    }
    return (
      <Box sx={{ width: 1 }}>
        <List>{items}</List>
      </Box>
    );
  };
  return (
    <FormControl error={error} sx={{ width: 1 }} variant="standard">
      {renderQuestions()}
    </FormControl>
  );
}
