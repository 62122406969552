import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SubQuestionAnswerProps } from '../../../../../types/ReportType';

export default function SubTextAnswer({
  monitorSubQuestion,
  subQuestionAnswer,
}: SubQuestionAnswerProps) {
  return (
    <Box sx={{ display: 'flex', columnGap: 1, ml: '15%' }}>
      <Typography
        variant="body1"
        fontWeight="bold"
        dangerouslySetInnerHTML={{
          __html: monitorSubQuestion?.questionText || '',
        }}
      ></Typography>
      <Typography variant="body1" fontWeight="bold">
        {Array.isArray(subQuestionAnswer[0]) ? subQuestionAnswer[0][0] : ''}
      </Typography>
    </Box>
  );
}
