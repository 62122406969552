import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import theme from '../../app/theme';
import { selectMonitorTypesData } from '../../features/monitor/monitorSlide';
import {
  removeMeasurementPacket,
  selectInterviewTypes,
  setCollapsePacket,
  setMeasurementPacket,
} from '../../features/patient/patientSlice';
import { MeasurementPacket } from '../../types/InterviewRequest';
import { MonitorDataType } from '../../types/MonitorTypes';
import { InterviewType } from '../../types/PatientRequest';
import { Provider } from '../../types/UserTypes';
import { formatDate } from '../utils/dateTimeUtils';
import { intervals } from '../utils/intervalResource';
import { populateLanguage } from '../utils/languages';
import { timeFrames, timeFramesType } from '../utils/timeFrameResource';

interface MeasurementsTableProps {
  items: MeasurementPacket[];
  providers: Provider[];
  isInInterview?: boolean;
  formHeight?: number;
}
interface AssessmentProps {
  assessment: MeasurementPacket;
  providerName: string;
  interviewName?: string;
  isInInterview?: boolean;
}

const StyledLabel = styled(InputLabel)(() => ({
  '&.MuiInputLabel-root': {
    fontSize: '18px',
    transform: ' translate(14px, -10px) scale(0.75)',
  },
}));

const StyledCommonTypo = styled('span')(() => ({
  fontWeight: 'bold',
}));

const StyledDragComponent = styled(Box)(() => ({
  '&.dragging-helper-class': {
    boxShadow: `${theme.xPalette.sliver} 0px 1px 6px, ${theme.xPalette.sliver} 0px 1px 4px`,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  '&:hover': {
    boxShadow: `${theme.xPalette.sliver} 0px 1px 6px, ${theme.xPalette.sliver} 0px 1px 4px`,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  cursor: 'move',
  display: 'table',
  width: '100%',
}));

function Assessment({ assessment, providerName, interviewName, isInInterview }: AssessmentProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const timeFrameItem = timeFrames.find(
    (item: timeFramesType) => item.validValue === assessment.interviewTimeFrame
  );
  const timeFrameName = timeFrameItem?.name ?? '';
  const { isExpanded, interviewId, monitorId } = assessment;

  const setCollapse = () => {
    if (interviewId) {
      dispatch(setCollapsePacket({ interviewId: interviewId }));
    }
    if (monitorId) {
      dispatch(setCollapsePacket({ monitorId: monitorId }));
    }
  };

  const deleteMeasurementPacketItem = () => {
    if (interviewId) {
      dispatch(
        removeMeasurementPacket({
          interviewId: interviewId,
        })
      );
    }
    if (monitorId) {
      dispatch(
        removeMeasurementPacket({
          monitorId: monitorId,
        })
      );
    }
  };

  return (
    <Fragment>
      <StyledDragComponent>
        <TableRow sx={{ width: '100%' }}>
          <TableCell sx={{ width: '50px', paddingX: 1 }}>
            <IconButton type="button" aria-label="expand row" size="small" onClick={setCollapse}>
              {isExpanded ? (
                <KeyboardArrowUpIcon sx={{ pointerEvents: 'none' }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ pointerEvents: 'none' }} />
              )}
            </IconButton>
          </TableCell>
          <TableCell sx={{ paddingX: 1 }} align="left" component="th" scope="row">
            <Typography sx={{ wordBreak: 'break-word' }}>{interviewName}</Typography>
          </TableCell>
          <TableCell sx={{ paddingX: 1 }} align="right">
            <IconButton onClick={deleteMeasurementPacketItem}>
              <HighlightOffIcon sx={{ pointerEvents: 'none' }} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={3}
          >
            <Collapse in={isExpanded}>
              <Box sx={{ margin: 1 }}>
                {assessment.language && (
                  <Typography>
                    {`${t('patient.patientDashboard.form.language')}: `}
                    <StyledCommonTypo>{populateLanguage(assessment.language)}</StyledCommonTypo>
                  </Typography>
                )}
                <Typography>
                  {`${t('patient.patientInfo.provider')}: `}
                  <StyledCommonTypo>{providerName}</StyledCommonTypo>
                </Typography>
                {assessment?.interviewTimeFrame && (
                  <Typography>
                    {`${t('patient.patientDashboard.form.timeFrames')}: `}
                    <StyledCommonTypo>{timeFrameName}</StyledCommonTypo>
                  </Typography>
                )}
                {assessment.interval && (
                  <Typography>
                    {`${t('interview.form.interval')}: `}
                    <StyledCommonTypo>{intervals[assessment.interval]}</StyledCommonTypo>
                  </Typography>
                )}
                {assessment.startDate && (
                  <Typography>
                    {`${t('interview.form.startDate')}: `}
                    <StyledCommonTypo>{formatDate(assessment.startDate)}</StyledCommonTypo>
                  </Typography>
                )}
                {assessment.endDate && (
                  <Typography>
                    {`${t('interview.form.endDate')}: `}
                    <StyledCommonTypo>{formatDate(assessment.endDate)}</StyledCommonTypo>
                  </Typography>
                )}
                {!!assessment?.interviewId && (
                  <Typography>
                    {`${t('interview.form.isTrial')}: `}
                    <StyledCommonTypo>{assessment.trialInterview ? 'Yes' : 'No'}</StyledCommonTypo>
                  </Typography>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </StyledDragComponent>
    </Fragment>
  );
}

export default function MeasurementPacketsList({
  items,
  providers,
  isInInterview,
  formHeight,
}: MeasurementsTableProps) {
  const { t } = useTranslation();
  const allInterviews = useAppSelector(selectInterviewTypes);
  const allMonitors = useAppSelector(selectMonitorTypesData);
  const matches = useMediaQuery(theme.breakpoints.down('xl'));
  const dispatch = useAppDispatch();

  const providerName = (providerId: number) => {
    const provider = providers.find(
      (item: Provider) => item.id.toString() === providerId?.toString()
    );
    return provider ? `${provider.lastName}, ${provider.firstName}` : '';
  };

  const interviewName = ({
    interviewId,
    monitorId,
  }: {
    interviewId?: number;
    monitorId?: number;
  }): string => {
    if (interviewId) {
      const interview = allInterviews?.find(
        (item: InterviewType) => item.interviewTypeId === interviewId
      );
      return interview?.name || '';
    }

    if (monitorId) {
      const monitor = allMonitors?.find((item: MonitorDataType) => item.id === monitorId);
      return monitor?.name || '';
    }

    return '';
  };

  const TableBodySortable = SortableContainer(({ children }: { children: any }) => (
    <TableBody>{children}</TableBody>
  ));

  const DragAssessment = SortableElement(
    ({ assessment, providerName, interviewName }: AssessmentProps) => {
      return (
        <Assessment
          assessment={assessment}
          providerName={providerName}
          interviewName={interviewName}
          isInInterview={isInInterview}
        />
      );
    }
  );

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const newMeasurementPacketList = arrayMove(items, oldIndex, newIndex).map((item, index) => {
      return { ...item, order: index + 1 };
    });
    dispatch(setMeasurementPacket({ newMeasurementPacketList: newMeasurementPacketList }));
  };
  return (
    <FormControl
      variant="outlined"
      sx={{
        width: '100%',
        padding: 2,
        border: '1px solid #ddd',
        borderRadius: 2,

        marginTop: '16px',
        marginBottom: '8px',
      }}
      component="fieldset"
    >
      <StyledLabel
        sx={{
          padding: '0 5px',
          backgroundColor: 'white',
        }}
      >
        {t('patient.patientDashboard.form.measurementPacket')}
      </StyledLabel>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Paper
          sx={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <TableContainer
            sx={{
              borderRadius: '16px 16px 0 0',
              maxHeight: formHeight ? formHeight - 58 : '100%',
            }}
          >
            <Table>
              <TableBodySortable
                onSortEnd={onSortEnd}
                pressDelay={matches ? 150 : 50}
                helperClass="dragging-helper-class"
              >
                {items.map((item: any, index: number) => (
                  <DragAssessment
                    key={index}
                    assessment={item}
                    providerName={providerName(item.providerId)}
                    interviewName={interviewName({
                      interviewId: item.interviewId,
                      monitorId: item.monitorId,
                    })}
                    index={index}
                  />
                ))}
              </TableBodySortable>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </FormControl>
  );
}
