import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { AssessmentMode } from '../../common/utils/assessmentMode';
import { languages, LanguageType } from '../../common/utils/languages';
import { MonitorModeName } from '../../common/utils/monitorsMode';
import { MeasurementPacket } from '../../types/InterviewRequest';
import { InterviewType, InterviewTypesResponse } from '../../types/PatientRequest';
import { clearCredentials } from '../auth/authSlice';
import { InterviewReportType } from '../interview/interviewUtils';

export interface PatientType {
  interviewsByLanguage: InterviewType[] | [];
  interviewTypesData: InterviewType[];
  hasRestrictedTimeframes: boolean;
  allowTrial: boolean;
  languagesForInterview: LanguageType[];
  defaultLanguage: string;
  patientHasEmail: boolean;
  patientSMSOptIn: boolean;
  userRole: string;
  measurementPacketList: MeasurementPacket[];
  assessmentMode: string;
}

const initialState: PatientType = {
  interviewsByLanguage: [],
  interviewTypesData: [],
  hasRestrictedTimeframes: false,
  allowTrial: false,
  languagesForInterview: [],
  defaultLanguage: 'en',
  patientHasEmail: false,
  patientSMSOptIn: false,
  userRole: '',
  measurementPacketList: [],
  assessmentMode: MonitorModeName.Clinician,
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setInterviewTypesData: (
      state,
      {
        payload: { interviewTypesData },
      }: PayloadAction<{ interviewTypesData: InterviewTypesResponse }>
    ) => {
      let allLanguagesCode = '';
      let interviewTypes = interviewTypesData?.interviewTypes;
      interviewTypes = interviewTypes.slice().sort((a, b) => a.order - b.order);
      interviewTypes.forEach((interviewType) => {
        allLanguagesCode = allLanguagesCode + ',' + interviewType.languages;
      });
      const uniqueLanguages = new Set(allLanguagesCode.slice(1).trim().split(','));

      state.languagesForInterview = Object.values(languages).filter((item) =>
        uniqueLanguages.has(item.code)
      );
      if (state.languagesForInterview && state.languagesForInterview.length > 0) {
        const findLanguageDefault = state.languagesForInterview.find(
          (x) => x.code === state.defaultLanguage
        );
        if (!findLanguageDefault) {
          state.defaultLanguage = state.languagesForInterview[0].code;
        }
      }
      state.interviewsByLanguage = interviewTypes.filter(
        (interview) =>
          interview.language === state.defaultLanguage ||
          interview.languages.split(',').some((langCode) => langCode === state.defaultLanguage)
      );
      state.interviewTypesData = interviewTypes;
      state.hasRestrictedTimeframes = interviewTypesData.hasRestrictedTimeframes;
      state.allowTrial = interviewTypesData.allowTrial;
      state.patientHasEmail = interviewTypesData.patientHasEmail;
      state.patientSMSOptIn = interviewTypesData.patientSMSOptIn;
      state.userRole = interviewTypesData.userRole;
    },
    setInterviewTypesByLanguage: (
      state,
      { payload: { languageCode } }: PayloadAction<{ languageCode: string }>
    ) => {
      let allLanguagesCode = '';
      let interviewTypes = state.interviewTypesData;
      interviewTypes = interviewTypes.slice().sort((a, b) => a.order - b.order);
      interviewTypes.forEach((interviewType) => {
        allLanguagesCode = allLanguagesCode + ',' + interviewType.languages;
      });
      const uniqueLanguages = new Set(allLanguagesCode.slice(1).trim().split(','));

      const languageAll = Object.values(languages).filter((item) => uniqueLanguages.has(item.code));
      if (languageAll && languageAll.length > 0) {
        const findLanguageDefault = languageAll.find((x) => x.code === languageCode);
        if (!findLanguageDefault) {
          languageCode = languageAll[0].code;
        }
      }

      state.defaultLanguage = languageCode;
      let interviewTypesByLanguage = state.interviewTypesData.filter(
        (interview) =>
          interview.language === languageCode ||
          interview.languages.split(',').some((langCode) => langCode === languageCode)
      );
      if (
        state.assessmentMode === AssessmentMode.Email ||
        state.assessmentMode === AssessmentMode.Patient
      ) {
        interviewTypesByLanguage = interviewTypesByLanguage.filter(
          (interview) =>
            interview.reportType !== InterviewReportType.CYBOCS &&
            interview.reportType !== InterviewReportType.YBOCS
        );
      }
      state.interviewsByLanguage = interviewTypesByLanguage;
    },
    setDefaultLanguage: (
      state,
      { payload: { languageCode } }: PayloadAction<{ languageCode: string }>
    ) => {
      let allLanguagesCode = '';
      let interviewTypes = state.interviewTypesData;
      interviewTypes = interviewTypes.slice().sort((a, b) => a.order - b.order);
      interviewTypes.forEach((interviewType) => {
        allLanguagesCode = allLanguagesCode + ',' + interviewType.languages;
      });
      const uniqueLanguages = new Set(allLanguagesCode.slice(1).trim().split(','));

      const languageAll = Object.values(languages).filter((item) => uniqueLanguages.has(item.code));
      if (languageAll && languageAll.length > 0) {
        const findLanguageDefault = languageAll.find((x) => x.code === languageCode);
        if (!findLanguageDefault) {
          languageCode = languageAll[0].code;
        }
      }
      state.defaultLanguage = languageCode;
    },
    addMeasurementPacket: (
      state,
      { payload: { measurementPacket } }: PayloadAction<{ measurementPacket: MeasurementPacket }>
    ) => {
      const packetItem = { ...measurementPacket, order: state.measurementPacketList.length + 1 };
      state.measurementPacketList.push(packetItem);
    },
    setMeasurementPacket: (
      state,
      {
        payload: { newMeasurementPacketList },
      }: PayloadAction<{ newMeasurementPacketList: MeasurementPacket[] }>
    ) => {
      state.measurementPacketList = newMeasurementPacketList;
    },
    setCollapsePacket: (
      state,
      {
        payload: { interviewId, monitorId },
      }: PayloadAction<{ interviewId?: number; monitorId?: number }>
    ) => {
      let packetIndex = interviewId
        ? state.measurementPacketList.findIndex((item) => item.interviewId === interviewId)
        : state.measurementPacketList.findIndex((item) => item.monitorId === monitorId);
      state.measurementPacketList[packetIndex].isExpanded =
        !state.measurementPacketList[packetIndex].isExpanded;
    },
    setAssessmentMode: (state, { payload: { mode } }: PayloadAction<{ mode: string }>) => {
      state.assessmentMode = mode;
    },
    setHiddenEmailOption: (
      state,
      {
        payload: { patientHasEmail, patientSMSOptIn },
      }: PayloadAction<{ patientHasEmail?: boolean; patientSMSOptIn?: boolean }>
    ) => {
        state.patientHasEmail = patientHasEmail ?? false;
        state.patientSMSOptIn = patientSMSOptIn ?? false;
    },
    removeMeasurementPacket: (
      state,
      {
        payload: { interviewId, monitorId },
      }: PayloadAction<{ interviewId?: number; monitorId?: number }>
    ) => {
      const oldPacket = interviewId
        ? state.measurementPacketList.find((packet) => interviewId === packet.interviewId)
        : state.measurementPacketList.find((packet) => monitorId === packet.monitorId);
      let newMeasurementPacketList = interviewId
        ? state.measurementPacketList.filter(
            (item: MeasurementPacket) => interviewId !== item.interviewId
          )
        : state.measurementPacketList.filter(
            (item: MeasurementPacket) => monitorId !== item.monitorId
          );
      newMeasurementPacketList = newMeasurementPacketList.map((item: MeasurementPacket) => {
        if (item.order && oldPacket?.order && item?.order > oldPacket?.order) {
          return { ...item, order: item.order - 1 };
        }
        return item;
      });
      state.measurementPacketList = newMeasurementPacketList;
    },
    removeMeasurementPacketList: (state) => {
      state.measurementPacketList = [];
    },
  },

  extraReducers: (builder) => {
    // clear patient data when log out
    builder.addCase(clearCredentials, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const {
  setInterviewTypesData,
  setInterviewTypesByLanguage,
  setDefaultLanguage,
  addMeasurementPacket,
  setMeasurementPacket,
  setHiddenEmailOption,
  removeMeasurementPacket,
  removeMeasurementPacketList,
  setCollapsePacket,
  setAssessmentMode,
} = patientSlice.actions;

export const selectInterviewTypes = (state: RootState) => state.patient.interviewTypesData;
export const selectLanguagesForInterview = (state: RootState) =>
  state.patient.languagesForInterview;
export const selectInterviewsByLanguage = (state: RootState) => state.patient.interviewsByLanguage;
export const selectAllowTrial = (state: RootState) => state.patient.allowTrial;
export const selectHasRestrictedTimeframes = (state: RootState) =>
  state.patient.hasRestrictedTimeframes;
export const selectDefaultLanguage = (state: RootState) => state.patient.defaultLanguage;
export const selectPatientHasEmail = (state: RootState) => state.patient.patientHasEmail;
export const selectPatientSMSOptIn = (state: RootState) => state.patient.patientSMSOptIn;
export const selectUserRole = (state: RootState) => state.patient.userRole;
export const selectAssessmentMode = (state: RootState) => state.patient.assessmentMode;
export const selectMeasurementPacketList = (state: RootState): MeasurementPacket[] =>
  state.patient.measurementPacketList as MeasurementPacket[];

export default patientSlice.reducer;
