export interface countryType {
  [key: string]: {
    code: string;
    name: string;
    adminDistrict: string;
    timezone: string[] | string;
  };
}

const countryConfigData: countryType = {
  AF: {
    code: 'AF',
    name: 'Afghanistan',
    adminDistrict: 'Province',
    timezone: 'Asia/Kabul',
  },
  AX: {
    code: 'AX',
    name: 'Åland Islands',
    adminDistrict: 'Municipality',
    timezone: 'Europe/Mariehamn',
  },
  AL: {
    code: 'AL',
    name: 'Albania',
    adminDistrict: 'County',
    timezone: 'Europe/Tirane',
  },
  DZ: {
    code: 'DZ',
    name: 'Algeria',
    adminDistrict: 'Province',
    timezone: 'Africa/Algiers',
  },
  AS: {
    code: 'AS',
    name: 'American Samoa',
    adminDistrict: 'District',
    timezone: 'Pacific/Pago_Pago',
  },
  AD: {
    code: 'AD',
    name: 'Andorra',
    adminDistrict: 'Community',
    timezone: 'Europe/Andorra',
  },
  AO: {
    code: 'AO',
    name: 'Angola',
    adminDistrict: 'Province',
    timezone: 'Africa/Luanda',
  },
  AI: {
    code: 'AI',
    name: 'Anguilla',
    adminDistrict: 'District',
    timezone: 'America/Anguilla',
  },
  AQ: {
    code: 'AQ',
    name: 'Antarctica',
    adminDistrict: 'NO STATE',
    timezone: [
      'Antarctica/Casey',
      'Antarctica/Davis',
      'Antarctica/DumontDUrville',
      'Antarctica/Mawson',
      'Antarctica/McMurdo',
      'Antarctica/Palmer',
      'Antarctica/Rothera',
      'Antarctica/Syowa',
      'Antarctica/Troll',
      'Antarctica/Vostok',
    ],
  },
  AG: {
    code: 'AG',
    name: 'Antigua and Barbuda',
    adminDistrict: 'Parish',
    timezone: 'America/Antigua',
  },
  AR: {
    code: 'AR',
    name: 'Argentina',
    adminDistrict: 'Province',
    timezone: [
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Catamarca',
      'America/Argentina/Cordoba',
      'America/Argentina/Jujuy',
      'America/Argentina/La_Rioja',
      'America/Argentina/Mendoza',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
    ],
  },
  AM: {
    code: 'AM',
    name: 'Armenia',
    adminDistrict: 'Province',
    timezone: 'Asia/Yerevan',
  },
  AW: {
    code: 'AW',
    name: 'Aruba',
    adminDistrict: 'Region',
    timezone: 'America/Aruba',
  },
  AU: {
    code: 'AU',
    name: 'Australia',
    adminDistrict: 'State',
    timezone: [
      'Antarctica/Macquarie',
      'Australia/Adelaide',
      'Australia/Brisbane',
      'Australia/Broken_Hill',
      'Australia/Currie',
      'Australia/Darwin',
      'Australia/Eucla',
      'Australia/Hobart',
      'Australia/Lindeman',
      'Australia/Lord_Howe',
      'Australia/Melbourne',
      'Australia/Perth',
      'Australia/Sydney',
    ],
  },
  AT: {
    code: 'AT',
    name: 'Austria',
    adminDistrict: 'State',
    timezone: 'Europe/Vienna',
  },
  AZ: {
    code: 'AZ',
    name: 'Azerbaijan',
    adminDistrict: 'District',
    timezone: 'Asia/Baku',
  },
  BS: {
    code: 'BS',
    name: 'Bahamas',
    adminDistrict: 'District',
    timezone: 'America/Nassau',
  },
  BH: {
    code: 'BH',
    name: 'Bahrain',
    adminDistrict: 'Governorate',
    timezone: 'Asia/Bahrain',
  },
  BD: {
    code: 'BD',
    name: 'Bangladesh',
    adminDistrict: 'Division',
    timezone: 'Asia/Dhaka',
  },
  BB: {
    code: 'BB',
    name: 'Barbados',
    adminDistrict: 'Parish',
    timezone: 'America/Barbados',
  },
  BY: {
    code: 'BY',
    name: 'Belarus',
    adminDistrict: 'Region',
    timezone: 'Europe/Minsk',
  },
  BE: {
    code: 'BE',
    name: 'Belgium',
    adminDistrict: 'Region',
    timezone: 'Europe/Brussels',
  },
  BZ: {
    code: 'BZ',
    name: 'Belize',
    adminDistrict: 'District',
    timezone: 'America/Belize',
  },
  BJ: {
    code: 'BJ',
    name: 'Benin',
    adminDistrict: 'Department',
    timezone: 'Africa/Porto-Novo',
  },
  BM: {
    code: 'BM',
    name: 'Bermuda',
    adminDistrict: 'Parish',
    timezone: 'Atlantic/Bermuda',
  },
  BT: {
    code: 'BT',
    name: 'Bhutan',
    adminDistrict: 'District',
    timezone: 'Asia/Thimphu',
  },
  BO: {
    code: 'BO',
    name: 'Bolivia, Plurinational State of',
    adminDistrict: 'Department',
    timezone: 'America/La_Paz',
  },
  BQ: {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
    adminDistrict: 'Province',
    timezone: 'America/Kralendijk',
  },
  BA: {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    adminDistrict: 'State',
    timezone: 'Europe/Sarajevo',
  },
  BW: {
    code: 'BW',
    name: 'Botswana',
    adminDistrict: 'District',
    timezone: 'Africa/Gaborone',
  },
  BV: {
    code: 'BV',
    name: 'Bouvet Island',
    adminDistrict: 'Municipality',
    timezone: 'Europe/Oslo',
  },
  BR: {
    code: 'BR',
    name: 'Brazil',
    adminDistrict: 'Federative Unit',
    timezone: [
      'America/Araguaina',
      'America/Bahia',
      'America/Belem',
      'America/Boa_Vista',
      'America/Campo_Grande',
      'America/Cuiaba',
      'America/Eirunepe',
      'America/Fortaleza',
      'America/Maceio',
      'America/Manaus',
      'America/Noronha',
      'America/Porto_Velho',
      'America/Recife',
      'America/Rio_Branco',
      'America/Santarem',
      'America/Sao_Paulo',
    ],
  },
  IO: {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    adminDistrict: 'NO STATE',
    timezone: 'Indian/Chagos',
  },
  BN: {
    code: 'BN',
    name: 'Brunei Darussalam',
    adminDistrict: 'District',
    timezone: 'Asia/Brunei',
  },
  BG: {
    code: 'BG',
    name: 'Bulgaria',
    adminDistrict: 'Province',
    timezone: 'Europe/Sofia',
  },
  BF: {
    code: 'BF',
    name: 'Burkina Faso',
    adminDistrict: 'Region',
    timezone: 'Africa/Ouagadougou',
  },
  BI: {
    code: 'BI',
    name: 'Burundi',
    adminDistrict: 'Province',
    timezone: 'Africa/Bujumbura',
  },
  KH: {
    code: 'KH',
    name: 'Cambodia',
    adminDistrict: 'Province',
    timezone: 'Asia/Phnom_Penh',
  },
  CM: {
    code: 'CM',
    name: 'Cameroon',
    adminDistrict: 'Region',
    timezone: 'Africa/Douala',
  },
  CA: {
    code: 'CA',
    name: 'Canada',
    adminDistrict: 'Province',
    timezone: [
      'America/Atikokan',
      'America/Blanc-Sablon',
      'America/Cambridge_Bay',
      'America/Creston',
      'America/Dawson',
      'America/Dawson_Creek',
      'America/Edmonton',
      'America/Fort_Nelson',
      'America/Glace_Bay',
      'America/Goose_Bay',
      'America/Halifax',
      'America/Inuvik',
      'America/Iqaluit',
      'America/Moncton',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Regina',
      'America/Resolute',
      'America/St_Johns',
      'America/Swift_Current',
      'America/Thunder_Bay',
      'America/Toronto',
      'America/Vancouver',
      'America/Whitehorse',
      'America/Winnipeg',
      'America/Yellowknife',
    ],
  },
  CV: {
    code: 'CV',
    name: 'Cape Verde',
    adminDistrict: 'Municipality',
    timezone: 'Atlantic/Cape_Verde',
  },
  KY: {
    code: 'KY',
    name: 'Cayman Islands',
    adminDistrict: 'District',
    timezone: 'America/Cayman',
  },
  CF: {
    code: 'CF',
    name: 'Central African Republic',
    adminDistrict: 'Prefecture',
    timezone: 'Africa/Bangui',
  },
  TD: {
    code: 'TD',
    name: 'Chad',
    adminDistrict: 'Region',
    timezone: 'Africa/Ndjamena',
  },
  CL: {
    code: 'CL',
    name: 'Chile',
    adminDistrict: 'Region',
    timezone: ['America/Punta_Arenas', 'America/Santiago', 'Pacific/Easter'],
  },
  CN: {
    code: 'CN',
    name: 'China',
    adminDistrict: 'Province',
    timezone: ['Asia/Shanghai', 'Asia/Urumqi'],
  },
  CX: {
    code: 'CX',
    name: 'Christmas Island',
    adminDistrict: 'State',
    timezone: 'Indian/Christmas',
  },
  CC: {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    adminDistrict: 'State',
    timezone: 'Indian/Cocos',
  },
  CO: {
    code: 'CO',
    name: 'Colombia',
    adminDistrict: 'Department',
    timezone: 'America/Bogota',
  },
  KM: {
    code: 'KM',
    name: 'Comoros',
    adminDistrict: 'Island',
    timezone: 'Indian/Comoro',
  },
  CG: {
    code: 'CG',
    name: 'Congo',
    adminDistrict: 'Department',
    timezone: 'Africa/Brazzaville',
  },
  CD: {
    code: 'CD',
    name: 'Congo, the Democratic Republic of the',
    adminDistrict: 'Province',
    timezone: ['Africa/Kinshasa', 'Africa/Lubumbashi'],
  },
  CK: {
    code: 'CK',
    name: 'Cook Islands',
    adminDistrict: 'Island Council',
    timezone: 'Pacific/Rarotonga',
  },
  CR: {
    code: 'CR',
    name: 'Costa Rica',
    adminDistrict: 'Province',
    timezone: 'America/Costa_Rica',
  },
  CI: {
    code: 'CI',
    name: "Côte d'Ivoire",
    adminDistrict: 'District',
    timezone: 'Africa/Abidjan',
  },
  HR: {
    code: 'HR',
    name: 'Croatia',
    adminDistrict: 'County',
    timezone: 'Europe/Zagreb',
  },
  CU: {
    code: 'CU',
    name: 'Cuba',
    adminDistrict: 'Province',
    timezone: 'America/Havana',
  },
  CW: {
    code: 'CW',
    name: 'Curaçao',
    adminDistrict: 'NO STATE',
    timezone: 'America/Curacao',
  },
  CY: {
    code: 'CY',
    name: 'Cyprus',
    adminDistrict: 'District',
    timezone: ['Asia/Famagusta', 'Asia/Nicosia'],
  },
  CZ: {
    code: 'CZ',
    name: 'Czech Republic',
    adminDistrict: 'District',
    timezone: 'Europe/Prague',
  },
  DK: {
    code: 'DK',
    name: 'Denmark',
    adminDistrict: 'Island',
    timezone: 'Europe/Copenhagen',
  },
  DJ: {
    code: 'DJ',
    name: 'Djibouti',
    adminDistrict: 'Region',
    timezone: 'Africa/Djibouti',
  },
  DM: {
    code: 'DM',
    name: 'Dominica',
    adminDistrict: 'Parish',
    timezone: 'America/Dominica',
  },
  DO: {
    code: 'DO',
    name: 'Dominican Republic',
    adminDistrict: 'Province',
    timezone: 'America/Santo_Domingo',
  },
  EC: {
    code: 'EC',
    name: 'Ecuador',
    adminDistrict: 'Province',
    timezone: ['America/Guayaquil', 'Pacific/Galapagos'],
  },
  EG: {
    code: 'EG',
    name: 'Egypt',
    adminDistrict: 'Governorate',
    timezone: 'Africa/Cairo',
  },
  SV: {
    code: 'SV',
    name: 'El Salvador',
    adminDistrict: 'Department',
    timezone: 'America/El_Salvador',
  },
  GQ: {
    code: 'GQ',
    name: 'Equatorial Guinea',
    adminDistrict: 'Province',
    timezone: 'Africa/Malabo',
  },
  ER: {
    code: 'ER',
    name: 'Eritrea',
    adminDistrict: 'Region',
    timezone: 'Africa/Asmara',
  },
  EE: {
    code: 'EE',
    name: 'Estonia',
    adminDistrict: 'County',
    timezone: 'Europe/Tallinn',
  },
  ET: {
    code: 'ET',
    name: 'Ethiopia',
    adminDistrict: 'Region',
    timezone: 'Africa/Addis_Ababa',
  },
  FK: {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
    adminDistrict: 'NO STATE',
    timezone: 'Atlantic/Stanley',
  },
  FO: {
    code: 'FO',
    name: 'Faroe Islands',
    adminDistrict: 'Municipality',
    timezone: 'Atlantic/Faroe',
  },
  FJ: {
    code: 'FJ',
    name: 'Fiji',
    adminDistrict: 'Division',
    timezone: 'Pacific/Fiji',
  },
  FI: {
    code: 'FI',
    name: 'Finland',
    adminDistrict: 'Region',
    timezone: 'Europe/Helsinki',
  },
  FR: {
    code: 'FR',
    name: 'France',
    adminDistrict: 'Region',
    timezone: 'Europe/Paris',
  },
  GF: {
    code: 'GF',
    name: 'French Guiana',
    adminDistrict: 'Region',
    timezone: 'America/Cayenne',
  },
  PF: {
    code: 'PF',
    name: 'French Polynesia',
    adminDistrict: 'Subdivision',
    timezone: ['Pacific/Gambier', 'Pacific/Marquesas', 'Pacific/Tahiti'],
  },
  TF: {
    code: 'TF',
    name: 'French Southern Territories',
    adminDistrict: 'District',
    timezone: 'Indian/Kerguelen',
  },
  GA: {
    code: 'GA',
    name: 'Gabon',
    adminDistrict: 'Province',
    timezone: 'Africa/Libreville',
  },
  GM: {
    code: 'GM',
    name: 'Gambia',
    adminDistrict: 'Region',
    timezone: 'Africa/Banjul',
  },
  GE: {
    code: 'GE',
    name: 'Georgia',
    adminDistrict: 'Region',
    timezone: 'Asia/Tbilisi',
  },
  DE: {
    code: 'DE',
    name: 'Germany',
    adminDistrict: 'State',
    timezone: ['Europe/Berlin', 'Europe/Busingen'],
  },
  GH: {
    code: 'GH',
    name: 'Ghana',
    adminDistrict: 'Region',
    timezone: 'Africa/Accra',
  },
  GI: {
    code: 'GI',
    name: 'Gibraltar',
    adminDistrict: 'NO STATE',
    timezone: 'Europe/Gibraltar',
  },
  GR: {
    code: 'GR',
    name: 'Greece',
    adminDistrict: 'Decentralized Administrations',
    timezone: 'Europe/Athens',
  },
  GL: {
    code: 'GL',
    name: 'Greenland',
    adminDistrict: 'Municipality',
    timezone: ['America/Danmarkshavn', 'America/Godthab', 'America/Scoresbysund', 'America/Thule'],
  },
  GD: {
    code: 'GD',
    name: 'Grenada',
    adminDistrict: 'Parish',
    timezone: 'America/Grenada',
  },
  GP: {
    code: 'GP',
    name: 'Guadeloupe',
    adminDistrict: 'Island',
    timezone: 'America/Guadeloupe',
  },
  GU: {
    code: 'GU',
    name: 'Guam',
    adminDistrict: 'Village',
    timezone: 'Pacific/Guam',
  },
  GT: {
    code: 'GT',
    name: 'Guatemala',
    adminDistrict: 'Department',
    timezone: 'America/Guatemala',
  },
  GG: {
    code: 'GG',
    name: 'Guernsey',
    adminDistrict: 'Parish',
    timezone: 'Europe/Guernsey',
  },
  GN: {
    code: 'GN',
    name: 'Guinea',
    adminDistrict: 'Region',
    timezone: 'Africa/Conakry',
  },
  GW: {
    code: 'GW',
    name: 'Guinea-Bissau',
    adminDistrict: 'Region',
    timezone: 'Africa/Bissau',
  },
  GY: {
    code: 'GY',
    name: 'Guyana',
    adminDistrict: 'Region',
    timezone: 'America/Guyana',
  },
  HT: {
    code: 'HT',
    name: 'Haiti',
    adminDistrict: 'Region',
    timezone: 'America/Port-au-Prince',
  },
  HM: {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
    adminDistrict: 'Local Government',
    timezone: 'Indian/Kerguelen',
  },
  VA: {
    code: 'VA',
    name: 'Holy See (Vatican City State)',
    adminDistrict: 'NO STATE',
    timezone: 'Europe/Vatican',
  },
  HN: {
    code: 'HN',
    name: 'Honduras',
    adminDistrict: 'Department',
    timezone: 'America/Tegucigalpa',
  },
  HK: {
    code: 'HK',
    name: 'Hong Kong',
    adminDistrict: 'District',
    timezone: 'Asia/Hong_Kong',
  },
  HU: {
    code: 'HU',
    name: 'Hungary',
    adminDistrict: 'County',
    timezone: 'Europe/Budapest',
  },
  IS: {
    code: 'IS',
    name: 'Iceland',
    adminDistrict: 'Municipality',
    timezone: 'Atlantic/Reykjavik',
  },
  IN: {
    code: 'IN',
    name: 'India',
    adminDistrict: 'State',
    timezone: 'Asia/Kolkata',
  },
  ID: {
    code: 'ID',
    name: 'Indonesia',
    adminDistrict: 'Province',
    timezone: ['Asia/Jakarta', 'Asia/Jayapura', 'Asia/Makassar', 'Asia/Pontianak'],
  },
  IR: {
    code: 'IR',
    name: 'Iran, Islamic Republic of',
    adminDistrict: 'Province',
    timezone: 'Asia/Tehran',
  },
  IQ: {
    code: 'IQ',
    name: 'Iraq',
    adminDistrict: 'Province',
    timezone: 'Asia/Baghdad',
  },
  IE: {
    code: 'IE',
    name: 'Ireland',
    adminDistrict: 'County',
    timezone: 'Europe/Dublin',
  },
  IM: {
    code: 'IM',
    name: 'Isle of Man',
    adminDistrict: 'Sheadings',
    timezone: 'Europe/Isle_of_Man',
  },
  IL: {
    code: 'IL',
    name: 'Israel',
    adminDistrict: 'District',
    timezone: 'Asia/Jerusalem',
  },
  IT: {
    code: 'IT',
    name: 'Italy',
    adminDistrict: 'Region',
    timezone: 'Europe/Rome',
  },
  JM: {
    code: 'JM',
    name: 'Jamaica',
    adminDistrict: 'Parish',
    timezone: 'America/Jamaica',
  },
  JP: {
    code: 'JP',
    name: 'Japan',
    adminDistrict: 'Prefecture',
    timezone: 'Asia/Tokyo',
  },
  JE: {
    code: 'JE',
    name: 'Jersey',
    adminDistrict: 'Parish',
    timezone: 'Europe/Jersey',
  },
  JO: {
    code: 'JO',
    name: 'Jordan',
    adminDistrict: 'Governorate',
    timezone: 'Asia/Amman',
  },
  KZ: {
    code: 'KZ',
    name: 'Kazakhstan',
    adminDistrict: 'Region',
    timezone: [
      'Asia/Almaty',
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Atyrau',
      'Asia/Oral',
      'Asia/Qyzylorda',
    ],
  },
  KE: {
    code: 'KE',
    name: 'Kenya',
    adminDistrict: 'County',
    timezone: 'Africa/Nairobi',
  },
  KI: {
    code: 'KI',
    name: 'Kiribati',
    adminDistrict: 'Local Council',
    timezone: ['Pacific/Enderbury', 'Pacific/Kiritimati', 'Pacific/Tarawa'],
  },
  KP: {
    code: 'KP',
    name: "Korea, Democratic People's Republic of",
    adminDistrict: 'Province',
    timezone: 'Asia/Pyongyang',
  },
  KR: {
    code: 'KR',
    name: 'Korea, Republic of',
    adminDistrict: 'Province',
    timezone: 'Asia/Seoul',
  },
  KW: {
    code: 'KW',
    name: 'Kuwait',
    adminDistrict: 'Governorate',
    timezone: 'Asia/Kuwait',
  },
  KG: {
    code: 'KG',
    name: 'Kyrgyzstan',
    adminDistrict: 'Region',
    timezone: 'Asia/Bishkek',
  },
  LA: {
    code: 'LA',
    name: "Lao People's Democratic Republic",
    adminDistrict: 'Province',
    timezone: 'Asia/Vientiane',
  },
  LV: {
    code: 'LV',
    name: 'Latvia',
    adminDistrict: 'Municipality',
    timezone: 'Europe/Riga',
  },
  LB: {
    code: 'LB',
    name: 'Lebanon',
    adminDistrict: 'Governorate',
    timezone: 'Asia/Beirut',
  },
  LS: {
    code: 'LS',
    name: 'Lesotho',
    adminDistrict: 'District',
    timezone: 'Africa/Maseru',
  },
  LR: {
    code: 'LR',
    name: 'Liberia',
    adminDistrict: 'County',
    timezone: 'Africa/Monrovia',
  },
  LY: {
    code: 'LY',
    name: 'Libya',
    adminDistrict: 'District',
    timezone: 'Africa/Tripoli',
  },
  LI: {
    code: 'LI',
    name: 'Liechtenstein',
    adminDistrict: 'Municipality',
    timezone: 'Europe/Vaduz',
  },
  LT: {
    code: 'LT',
    name: 'Lithuania',
    adminDistrict: 'County',
    timezone: 'Europe/Vilnius',
  },
  LU: {
    code: 'LU',
    name: 'Luxembourg',
    adminDistrict: 'Canton',
    timezone: 'Europe/Luxembourg',
  },
  MO: {
    code: 'MO',
    name: 'Macao',
    adminDistrict: 'District',
    timezone: 'Asia/Macau',
  },
  MK: {
    code: 'MK',
    name: 'Macedonia, the Former Yugoslav Republic of',
    adminDistrict: 'Region',
    timezone: 'Europe/Skopje',
  },
  MG: {
    code: 'MG',
    name: 'Madagascar',
    adminDistrict: 'Region',
    timezone: 'Indian/Antananarivo',
  },
  MW: {
    code: 'MW',
    name: 'Malawi',
    adminDistrict: 'Region',
    timezone: 'Africa/Blantyre',
  },
  MY: {
    code: 'MY',
    name: 'Malaysia',
    adminDistrict: 'State',
    timezone: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
  },
  MV: {
    code: 'MV',
    name: 'Maldives',
    adminDistrict: 'Island',
    timezone: 'Indian/Maldives',
  },
  ML: {
    code: 'ML',
    name: 'Mali',
    adminDistrict: 'Region',
    timezone: 'Africa/Bamako',
  },
  MT: {
    code: 'MT',
    name: 'Malta',
    adminDistrict: 'Region',
    timezone: 'Europe/Malta',
  },
  MH: {
    code: 'MH',
    name: 'Marshall Islands',
    adminDistrict: 'Municipality',
    timezone: ['Pacific/Kwajalein', 'Pacific/Majuro'],
  },
  MQ: {
    code: 'MQ',
    name: 'Martinique',
    adminDistrict: 'NO STATE',
    timezone: 'America/Martinique',
  },
  MR: {
    code: 'MR',
    name: 'Mauritania',
    adminDistrict: 'Region',
    timezone: 'Africa/Nouakchott',
  },
  MU: {
    code: 'MU',
    name: 'Mauritius',
    adminDistrict: 'Island',
    timezone: 'Indian/Mauritius',
  },
  YT: {
    code: 'YT',
    name: 'Mayotte',
    adminDistrict: 'NO STATE',
    timezone: 'Indian/Mayotte',
  },
  MX: {
    code: 'MX',
    name: 'Mexico',
    adminDistrict: 'State',
    timezone: [
      'America/Bahia_Banderas',
      'America/Cancun',
      'America/Chihuahua',
      'America/Hermosillo',
      'America/Matamoros',
      'America/Mazatlan',
      'America/Merida',
      'America/Mexico_City',
      'America/Monterrey',
      'America/Ojinaga',
      'America/Tijuana',
    ],
  },
  FM: {
    code: 'FM',
    name: 'Micronesia, Federated States of',
    adminDistrict: 'State',
    timezone: ['Pacific/Chuuk', 'Pacific/Kosrae', 'Pacific/Pohnpei'],
  },
  MD: {
    code: 'MD',
    name: 'Moldova, Republic of',
    adminDistrict: 'District',
    timezone: 'Europe/Chisinau',
  },
  MC: {
    code: 'MC',
    name: 'Monaco',
    adminDistrict: 'Municipality',
    timezone: 'Europe/Monaco',
  },
  MN: {
    code: 'MN',
    name: 'Mongolia',
    adminDistrict: 'Province',
    timezone: ['Asia/Choibalsan', 'Asia/Hovd', 'Asia/Ulaanbaatar'],
  },
  ME: {
    code: 'ME',
    name: 'Montenegro',
    adminDistrict: 'Municipality',
    timezone: 'Europe/Podgorica',
  },
  MS: {
    code: 'MS',
    name: 'Montserrat',
    adminDistrict: 'Parish',
    timezone: 'America/Montserrat',
  },
  MA: {
    code: 'MA',
    name: 'Morocco',
    adminDistrict: 'Region',
    timezone: 'Africa/Casablanca',
  },
  MZ: {
    code: 'MZ',
    name: 'Mozambique',
    adminDistrict: 'Province',
    timezone: 'Africa/Maputo',
  },
  MM: {
    code: 'MM',
    name: 'Myanmar',
    adminDistrict: 'State',
    timezone: ['Asia/Rangoon', 'Asia/Yangon'],
  },
  NA: {
    code: 'NA',
    name: 'Namibia',
    adminDistrict: 'Region',
    timezone: 'Africa/Windhoek',
  },
  NR: {
    code: 'NR',
    name: 'Nauru',
    adminDistrict: 'District',
    timezone: 'Pacific/Nauru',
  },
  NP: {
    code: 'NP',
    name: 'Nepal',
    adminDistrict: 'Province',
    timezone: 'Asia/Kathmandu',
  },
  NL: {
    code: 'NL',
    name: 'Netherlands',
    adminDistrict: 'Province',
    timezone: 'Europe/Amsterdam',
  },
  NC: {
    code: 'NC',
    name: 'New Caledonia',
    adminDistrict: 'Province',
    timezone: 'Pacific/Noumea',
  },
  NZ: {
    code: 'NZ',
    name: 'New Zealand',
    adminDistrict: 'Region',
    timezone: ['Pacific/Auckland', 'Pacific/Chatham'],
  },
  NI: {
    code: 'NI',
    name: 'Nicaragua',
    adminDistrict: 'Department',
    timezone: 'America/Managua',
  },
  NE: {
    code: 'NE',
    name: 'Niger',
    adminDistrict: 'Region',
    timezone: 'Africa/Niamey',
  },
  NG: {
    code: 'NG',
    name: 'Nigeria',
    adminDistrict: 'State',
    timezone: 'Africa/Lagos',
  },
  NU: {
    code: 'NU',
    name: 'Niue',
    adminDistrict: 'Village',
    timezone: 'Pacific/Niue',
  },
  NF: {
    code: 'NF',
    name: 'Norfolk Island',
    adminDistrict: 'State',
    timezone: 'Pacific/Norfolk',
  },
  MP: {
    code: 'MP',
    name: 'Northern Mariana Islands',
    adminDistrict: 'Municipality',
    timezone: 'Pacific/Saipan',
  },
  NO: {
    code: 'NO',
    name: 'Norway',
    adminDistrict: 'Region',
    timezone: 'Europe/Oslo',
  },
  OM: {
    code: 'OM',
    name: 'Oman',
    adminDistrict: 'Governorate',
    timezone: 'Asia/Muscat',
  },
  PK: {
    code: 'PK',
    name: 'Pakistan',
    adminDistrict: 'Province',
    timezone: 'Asia/Karachi',
  },
  PW: {
    code: 'PW',
    name: 'Palau',
    adminDistrict: 'State',
    timezone: 'Pacific/Palau',
  },
  PS: {
    code: 'PS',
    name: 'Palestine, State of',
    adminDistrict: 'Governorate',
    timezone: ['Asia/Gaza', 'Asia/Hebron'],
  },
  PA: {
    code: 'PA',
    name: 'Panama',
    adminDistrict: 'Province',
    timezone: 'America/Panama',
  },
  PG: {
    code: 'PG',
    name: 'Papua New Guinea',
    adminDistrict: 'Province',
    timezone: ['Pacific/Bougainville', 'Pacific/Port_Moresby'],
  },
  PY: {
    code: 'PY',
    name: 'Paraguay',
    adminDistrict: 'Department',
    timezone: 'America/Asuncion',
  },
  PE: {
    code: 'PE',
    name: 'Peru',
    adminDistrict: 'Region',
    timezone: 'America/Lima',
  },
  PH: {
    code: 'PH',
    name: 'Philippines',
    adminDistrict: 'Region',
    timezone: 'Asia/Manila',
  },
  PN: {
    code: 'PN',
    name: 'Pitcairn',
    adminDistrict: 'NO STATE',
    timezone: 'Pacific/Pitcairn',
  },
  PL: {
    code: 'PL',
    name: 'Poland',
    adminDistrict: 'Province',
    timezone: 'Europe/Warsaw',
  },
  PT: {
    code: 'PT',
    name: 'Portugal',
    adminDistrict: 'District',
    timezone: ['Atlantic/Azores', 'Atlantic/Madeira', 'Europe/Lisbon'],
  },
  PR: {
    code: 'PR',
    name: 'Puerto Rico',
    adminDistrict: 'Municipality',
    timezone: 'America/Puerto_Rico',
  },
  QA: {
    code: 'QA',
    name: 'Qatar',
    adminDistrict: 'Municipality',
    timezone: 'Asia/Qatar',
  },
  RE: {
    code: 'RE',
    name: 'Réunion',
    adminDistrict: 'NO STATE',
    timezone: 'Indian/Reunion',
  },
  RO: {
    code: 'RO',
    name: 'Romania',
    adminDistrict: 'County',
    timezone: 'Europe/Bucharest',
  },
  RU: {
    code: 'RU',
    name: 'Russian Federation',
    adminDistrict: 'Federal Subject',
    timezone: [
      'Asia/Anadyr',
      'Asia/Barnaul',
      'Asia/Chita',
      'Asia/Irkutsk',
      'Asia/Kamchatka',
      'Asia/Khandyga',
      'Asia/Krasnoyarsk',
      'Asia/Magadan',
      'Asia/Novokuznetsk',
      'Asia/Novosibirsk',
      'Asia/Omsk',
      'Asia/Sakhalin',
      'Asia/Srednekolymsk',
      'Asia/Tomsk',
      'Asia/Ust-Nera',
      'Asia/Vladivostok',
      'Asia/Yakutsk',
      'Asia/Yekaterinburg',
      'Europe/Astrakhan',
      'Europe/Kaliningrad',
      'Europe/Kirov',
      'Europe/Moscow',
      'Europe/Samara',
      'Europe/Saratov',
      'Europe/Ulyanovsk',
      'Europe/Volgograd',
    ],
  },
  RW: {
    code: 'RW',
    name: 'Rwanda',
    adminDistrict: 'Province',
    timezone: 'Africa/Kigali',
  },
  BL: {
    code: 'BL',
    name: 'Saint Barthélemy',
    adminDistrict: 'NO STATE',
    timezone: 'America/St_Barthelemy',
  },
  SH: {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    adminDistrict: 'District',
    timezone: 'Atlantic/St_Helena',
  },
  KN: {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    adminDistrict: 'Parish',
    timezone: 'America/St_Kitts',
  },
  LC: {
    code: 'LC',
    name: 'Saint Lucia',
    adminDistrict: 'Quarter',
    timezone: 'America/St_Lucia',
  },
  MF: {
    code: 'MF',
    name: 'Saint Martin (French part)',
    adminDistrict: 'NO STATE',
    timezone: 'America/Marigot',
  },
  PM: {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
    adminDistrict: 'NO STATE',
    timezone: 'America/Miquelon',
  },
  VC: {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    adminDistrict: 'Parish',
    timezone: 'America/St_Vincent',
  },
  WS: {
    code: 'WS',
    name: 'Samoa',
    adminDistrict: 'District',
    timezone: 'Pacific/Apia',
  },
  SM: {
    code: 'SM',
    name: 'San Marino',
    adminDistrict: 'Municipality',
    timezone: 'Europe/San_Marino',
  },
  ST: {
    code: 'ST',
    name: 'Sao Tome and Principe',
    adminDistrict: 'District',
    timezone: 'Africa/Sao_Tome',
  },
  SA: {
    code: 'SA',
    name: 'Saudi Arabia',
    adminDistrict: 'Region',
    timezone: 'Asia/Riyadh',
  },
  SN: {
    code: 'SN',
    name: 'Senegal',
    adminDistrict: 'Region',
    timezone: 'Africa/Dakar',
  },
  RS: {
    code: 'RS',
    name: 'Serbia',
    adminDistrict: 'Division',
    timezone: 'Europe/Belgrade',
  },
  SC: {
    code: 'SC',
    name: 'Seychelles',
    adminDistrict: 'District',
    timezone: 'Indian/Mahe',
  },
  SL: {
    code: 'SL',
    name: 'Sierra Leone',
    adminDistrict: 'Province',
    timezone: 'Africa/Freetown',
  },
  SG: {
    code: 'SG',
    name: 'Singapore',
    adminDistrict: 'District',
    timezone: 'Asia/Singapore',
  },
  SX: {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)',
    adminDistrict: 'NO STATE',
    timezone: 'America/Lower_Princes',
  },
  SK: {
    code: 'SK',
    name: 'Slovakia',
    adminDistrict: 'Region',
    timezone: 'Europe/Bratislava',
  },
  SI: {
    code: 'SI',
    name: 'Slovenia',
    adminDistrict: 'Municipality',
    timezone: 'Europe/Ljubljana',
  },
  SB: {
    code: 'SB',
    name: 'Solomon Islands',
    adminDistrict: 'Province',
    timezone: 'Pacific/Guadalcanal',
  },
  SO: {
    code: 'SO',
    name: 'Somalia',
    adminDistrict: 'Region',
    timezone: 'Africa/Mogadishu',
  },
  ZA: {
    code: 'ZA',
    name: 'South Africa',
    adminDistrict: 'Province',
    timezone: 'Africa/Johannesburg',
  },
  GS: {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    adminDistrict: 'NO STATE',
    timezone: 'Atlantic/South_Georgia',
  },
  SS: {
    code: 'SS',
    name: 'South Sudan',
    adminDistrict: 'State',
    timezone: 'Africa/Juba',
  },
  ES: {
    code: 'ES',
    name: 'Spain',
    adminDistrict: 'Division',
    timezone: ['Africa/Ceuta', 'Atlantic/Canary', 'Europe/Madrid'],
  },
  LK: {
    code: 'LK',
    name: 'Sri Lanka',
    adminDistrict: 'Province',
    timezone: 'Asia/Colombo',
  },
  SD: {
    code: 'SD',
    name: 'Sudan',
    adminDistrict: 'State',
    timezone: 'Africa/Khartoum',
  },
  SR: {
    code: 'SR',
    name: 'Suriname',
    adminDistrict: 'District',
    timezone: 'America/Paramaribo',
  },
  SJ: {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
    adminDistrict: 'NO STATE',
    timezone: 'Arctic/Longyearbyen',
  },
  SZ: {
    code: 'SZ',
    name: 'Swaziland',
    adminDistrict: 'Region',
    timezone: 'Africa/Mbabane',
  },
  SE: {
    code: 'SE',
    name: 'Sweden',
    adminDistrict: 'County',
    timezone: 'Europe/Stockholm',
  },
  CH: {
    code: 'CH',
    name: 'Switzerland',
    adminDistrict: 'Canton',
    timezone: 'Europe/Zurich',
  },
  SY: {
    code: 'SY',
    name: 'Syrian Arab Republic',
    adminDistrict: 'Province',
    timezone: 'Asia/Damascus',
  },
  TW: {
    code: 'TW',
    name: 'Taiwan, Province of China',
    adminDistrict: 'Municipality',
    timezone: 'Asia/Taipei',
  },
  TJ: {
    code: 'TJ',
    name: 'Tajikistan',
    adminDistrict: 'District',
    timezone: 'Asia/Dushanbe',
  },
  TZ: {
    code: 'TZ',
    name: 'Tanzania, United Republic of',
    adminDistrict: 'Region',
    timezone: 'Africa/Dar_es_Salaam',
  },
  TH: {
    code: 'TH',
    name: 'Thailand',
    adminDistrict: 'Province',
    timezone: 'Asia/Bangkok',
  },
  TL: {
    code: 'TL',
    name: 'Timor-Leste',
    adminDistrict: 'Municipality',
    timezone: 'Asia/Dili',
  },
  TG: {
    code: 'TG',
    name: 'Togo',
    adminDistrict: 'Region',
    timezone: 'Africa/Lome',
  },
  TK: {
    code: 'TK',
    name: 'Tokelau',
    adminDistrict: 'Community',
    timezone: 'Pacific/Fakaofo',
  },
  TO: {
    code: 'TO',
    name: 'Tonga',
    adminDistrict: 'Province',
    timezone: 'Pacific/Tongatapu',
  },
  TT: {
    code: 'TT',
    name: 'Trinidad and Tobago',
    adminDistrict: 'Region',
    timezone: 'America/Port_of_Spain',
  },
  TN: {
    code: 'TN',
    name: 'Tunisia',
    adminDistrict: 'Governorate',
    timezone: 'Africa/Tunis',
  },
  TR: {
    code: 'TR',
    name: 'Turkey',
    adminDistrict: 'Province',
    timezone: 'Europe/Istanbul',
  },
  TM: {
    code: 'TM',
    name: 'Turkmenistan',
    adminDistrict: 'Region',
    timezone: 'Asia/Ashgabat',
  },
  TC: {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    adminDistrict: 'District',
    timezone: 'America/Grand_Turk',
  },
  TV: {
    code: 'TV',
    name: 'Tuvalu',
    adminDistrict: 'Municipality',
    timezone: 'Pacific/Funafuti',
  },
  UG: {
    code: 'UG',
    name: 'Uganda',
    adminDistrict: 'District',
    timezone: 'Africa/Kampala',
  },
  UA: {
    code: 'UA',
    name: 'Ukraine',
    adminDistrict: 'Region',
    timezone: ['Europe/Kiev', 'Europe/Simferopol', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
  },
  AE: {
    code: 'AE',
    name: 'United Arab Emirates',
    adminDistrict: 'Emirate',
    timezone: 'Asia/Dubai',
  },
  GB: {
    code: 'GB',
    name: 'United Kingdom',
    adminDistrict: 'County',
    timezone: 'Europe/London',
  },
  US: {
    code: 'US',
    name: 'United States',
    adminDistrict: 'State',
    timezone: [
      'America/Adak',
      'America/Anchorage',
      'America/Boise',
      'America/Chicago',
      'America/Denver',
      'America/Detroit',
      'America/Indiana/Indianapolis',
      'America/Indiana/Knox',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Tell_City',
      'America/Indiana/Vevay',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Juneau',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Los_Angeles',
      'America/Menominee',
      'America/Metlakatla',
      'America/New_York',
      'America/Nome',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Phoenix',
      'America/Sitka',
      'America/Yakutat',
      'Pacific/Honolulu',
    ],
  },
  UM: {
    code: 'UM',
    name: 'United States Minor Outlying Islands',
    adminDistrict: 'Insular Area',
    timezone: ['Pacific/Midway', 'Pacific/Wake'],
  },
  UY: {
    code: 'UY',
    name: 'Uruguay',
    adminDistrict: 'Department',
    timezone: 'America/Montevideo',
  },
  UZ: {
    code: 'UZ',
    name: 'Uzbekistan',
    adminDistrict: 'Region',
    timezone: ['Asia/Samarkand', 'Asia/Tashkent'],
  },
  VU: {
    code: 'VU',
    name: 'Vanuatu',
    adminDistrict: 'Province',
    timezone: 'Pacific/Efate',
  },
  VE: {
    code: 'VE',
    name: 'Venezuela, Bolivarian Republic of',
    adminDistrict: 'State',
    timezone: 'America/Caracas',
  },
  VN: {
    code: 'VN',
    name: 'Viet Nam',
    adminDistrict: 'Province',
    timezone: 'Asia/Ho_Chi_Minh',
  },
  VG: {
    code: 'VG',
    name: 'Virgin Islands, British',
    adminDistrict: 'District',
    timezone: 'America/Tortola',
  },
  VI: {
    code: 'VI',
    name: 'Virgin Islands, U.S.',
    adminDistrict: 'District',
    timezone: 'America/St_Thomas',
  },
  WF: {
    code: 'WF',
    name: 'Wallis and Futuna',
    adminDistrict: 'Kingdom',
    timezone: 'Pacific/Wallis',
  },
  EH: {
    code: 'EH',
    name: 'Western Sahara',
    adminDistrict: 'Region',
    timezone: 'Africa/El_Aaiun',
  },
  YE: {
    code: 'YE',
    name: 'Yemen',
    adminDistrict: 'Governorate',
    timezone: 'Asia/Aden',
  },
  ZM: {
    code: 'ZM',
    name: 'Zambia',
    adminDistrict: 'Province',
    timezone: 'Africa/Lusaka',
  },
  ZW: {
    code: 'ZW',
    name: 'Zimbabwe',
    adminDistrict: 'Province',
    timezone: 'Africa/Harare',
  },
};

export const USCountryCode = countryConfigData.US.code;

export const sortedCountryCodes: countryType = {
  US: countryConfigData[USCountryCode],
  ...countryConfigData,
};
