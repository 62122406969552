import { Theme } from '@emotion/react';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';

interface Props extends Partial<StandardTextFieldProps> {
  data: string[];
  sx?: Theme;
  matches?: boolean;
  disabled?: boolean;
  setValue: any;
  label: string;
  extendedItem?: string;
  field: ControllerRenderProps<any, any>;
}

const AutoCompleteWithChips = forwardRef<JSX.Element, Props>(
  (
    { data, sx, setValue, matches, disabled = false, field, extendedItem, ...textFieldProps },
    ref
  ) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [autoCompleteValues, setAutoCompleteValues] = useState<string[] | null>(null);
    const valueRef = useRef<string>('');

    const handleChanges = useCallback(
      (value: any) => {
        let values = [];

        if (Array.isArray(value)) {
          values.push(...value);
        } else if (value) {
          values = [...autoCompleteValues || [], value];
        } else {
          values = autoCompleteValues || [];
        }

        const uniqueValues = new Set([...values]);

        setAutoCompleteValues(Array.from(uniqueValues));
        setInputValue('');
        setValue(field.name, Array.from(uniqueValues));
      },
      [autoCompleteValues, field.name, setValue]
    );

    const handleInputValueChange = (e: React.SyntheticEvent, value: string) => {
      setInputValue(value);
    };

    const handleInPutChangeChange = (e: React.SyntheticEvent<EventTarget>, value: any) => {
      handleChanges(value);
    };

    const onInputBlur = (e: React.SyntheticEvent<EventTarget>) => {
      const target = e.target as HTMLInputElement;
      const value = target.value.trim();
      handleChanges(value);
    };

    const handleExtendedItem = (value?: string) => {
      const validValue = value?.trim() || '';

      const newItems = new Set(autoCompleteValues || data);
      if (valueRef.current && validValue !== valueRef.current) {
        if (validValue) {
          newItems.add(validValue);
        }
        newItems.delete(valueRef.current);
      }

      valueRef.current = validValue;
      handleChanges(Array.from(newItems));
    };

    useEffect(() => {
      if (Array.isArray(data)) {
        handleExtendedItem(extendedItem);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extendedItem]);

    return (
      <Autocomplete
        ref={ref}
        sx={{ width: matches ? '100%' : '49%', ...sx }}
        multiple
        freeSolo
        disabled={disabled}
        value={autoCompleteValues || []}
        options={[]}
        inputValue={inputValue}
        onInputChange={handleInputValueChange}
        onChange={handleInPutChangeChange}
        renderTags={(value: unknown[], getTagProps) => {
          return value.map((option: unknown, index: number) => (
            <Chip variant="outlined" label={String(option)} {...getTagProps({ index })} />
          ));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            margin="normal"
            fullWidth
            onBlur={onInputBlur}
          />
        )}
      />
    );
  }
);

export default AutoCompleteWithChips;
