import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../../app/hooks';
import CommonDatePicker from '../../../common/components/CommonDatePicker';
import LocalPopupModal from '../../../common/components/LocalPopupModal';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { goalProgressList, goalStatusList, isBefore } from '../../../common/utils/treatmentPlans';
import { PopupContent } from '../../../types/PopupType';
import { ResponseError } from '../../../types/ResponseError';
import { GoalItem, GoalProgress, GoalStatus } from '../../../types/treatment-plan/GoalType';
import { ObjectiveItem } from '../../../types/treatment-plan/ObjectiveType';
import ObjectiveForm from '../objective/ObjectiveForm';
import ObjectiveList from '../objective/ObjectiveList';
import { setTreatmentPlanReloadOption } from '../treatmentPlanSlice';

interface GoalFormProps {
  goalData?: GoalItem;
  onFormSubmit: (goalData: GoalItem, successCallback?: () => void) => Promise<void>;
}

export default function GoalForm({ goalData, onFormSubmit }: GoalFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [objectiveRowStatus, setObjectiveRowStatus] = useState({});

  // Add Objective Popup
  const [isOpenAddObjectivePopup, setIsOpenAddObjectivePopup] = useState<boolean>(false);

  const addObjectiveContentPopup = {
    title: t('treatmentPlans.objectiveForm.add'),
  } as PopupContent;

  const showAddObjectivePopup = async () => {
    setIsOpenAddObjectivePopup(true);
  };

  const closeAddObjectivePopup = async () => {
    setIsOpenAddObjectivePopup(false);
  };

  const onAddObjectiveFormSubmitCallback = async (
    objectiveData: ObjectiveItem,
    result: boolean
  ) => {
    if (result) {
      setIsOpenAddObjectivePopup(false);
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
      setObjectiveRowStatus({ [objectiveData.id]: true });
    }
  };

  const defaultFormValues = {
    diagnoses: '',
    description: '',
    progress: GoalProgress.NotStarted,
    status: GoalStatus.NotStarted,
    statusDate: null,
    startDate: null,
  };

  const validationSchema = yup.object({
    diagnoses: yup.string().required(t('treatmentPlans.goalForm.error.blankCat')),
    description: yup.string().required(t('treatmentPlans.goalForm.error.blankDesc')),
    progress: yup.string().required(t('treatmentPlans.goalForm.error.blankProgress')),
    status: yup.string().required(t('treatmentPlans.goalForm.error.blankStatus')),
    statusDate: yup
      .date()
      .nullable()
      .test(
        'status-date',
        t('treatmentPlans.goalForm.error.invalidStatusDate'),
        function (statusDate) {
          const startDate: Date = getValues('startDate') || undefined;
          if (statusDate) {
            return !isBefore(statusDate, startDate);
          }
          return true;
        }
      )
      .when('status', {
        is: (status: GoalStatus) => status && status !== GoalStatus.NotStarted,
        then: yup
          .date()
          .nullable()
          .transform((current: any, origin: any) => (origin === '' ? null : current))
          .required(t('treatmentPlans.goalForm.error.blankStatusDate')),
      }),
    startDate: yup
      .date()
      .nullable()
      .transform((current: any, origin: any) => (origin === '' ? null : current))
      .required(t('treatmentPlans.goalForm.error.blankStartDate')),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<GoalItem>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  // initial data load
  useEffect(() => {
    if (goalData?.id) {
      if (!isDataLoaded) {
        setIsDataLoaded(true);
        Object.keys(goalData).forEach((goalAttr) => {
          if (goalAttr !== 'id') {
            setValue(goalAttr as any, goalData[goalAttr]);
          }
        });
      }
    }
  }, [goalData, isDataLoaded, setValue]);

  const onSubmit = async (data: GoalItem) => {
    setDisableSubmit(true);
    try {
      await onFormSubmit(data, () => {
        setPageErrors([]);
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
    setDisableSubmit(false);
  };

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  const handleProgressChange = (e: any, values: any) => {
    const selectedValue = values ? values.code : '';
    setValue('progress', selectedValue);
  };

  const handleStatusChange = (e: any, values: any) => {
    const selectedValue = values ? values.code : '';
    setValue('status', selectedValue);
  };

  return (
    <Box component="form" noValidate>
      <RoundedContainer sx={{ marginTop: 2, paddingTop: 1, paddingBottom: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            px: isMobile ? 0 : 3,
            pt: 0,
          }}
        >
          <Controller
            name="diagnoses"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.goalForm.category.title')}
                variant="outlined"
                error={!!errors.diagnoses}
                helperText={errors.diagnoses?.message}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            px: isMobile ? 0 : 3,
            pt: 0,
          }}
        >
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.goalForm.description.title')}
                variant="outlined"
                error={!!errors.description}
                helperText={errors.description?.message}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            px: isMobile ? 0 : 3,
            pt: 0,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CommonDatePicker
              name="startDate"
              control={control}
              error={!!errors.startDate}
              helperText={errors.startDate?.message}
              label={t('treatmentPlans.goalForm.startDate.title')}
              matches={isMobile}
              textFieldSx={{
                width: isMobile ? '100%' : '48%',
              }}
            />
          </LocalizationProvider>
          <Controller
            name="progress"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <Autocomplete
                {...props}
                sx={{ width: isMobile ? '100%' : '48%' }}
                data-testid="mui-component-select-goal-progress"
                options={goalProgressList}
                getOptionLabel={(option: any) => (option.name ? option.name : '')}
                onChange={handleProgressChange}
                value={value ? goalProgressList.find((progress) => progress.code === value) : null}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.code} value={option.code}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.progress}
                    helperText={errors.progress?.message}
                    margin="normal"
                    fullWidth
                    label={t('treatmentPlans.goalForm.progress.title')}
                  />
                )}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            px: isMobile ? 0 : 3,
            pt: 0,
          }}
        >
          <Controller
            name="status"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <Autocomplete
                {...props}
                sx={{ width: isMobile ? '100%' : '48%' }}
                data-testid="mui-component-select-goal-status"
                options={goalStatusList}
                getOptionLabel={(option: any) => (option.name ? option.name : '')}
                onChange={handleStatusChange}
                value={value ? goalStatusList.find((status) => status.code === value) : null}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.code} value={option.code}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.status}
                    helperText={errors.status?.message}
                    margin="normal"
                    fullWidth
                    label={t('treatmentPlans.goalForm.status.title')}
                  />
                )}
              />
            )}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CommonDatePicker
              name="statusDate"
              control={control}
              error={!!errors.statusDate}
              helperText={errors.statusDate?.message}
              label={t('treatmentPlans.goalForm.statusDate.title')}
              matches={isMobile}
              textFieldSx={{
                width: isMobile ? '100%' : '48%',
              }}
            />
          </LocalizationProvider>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            pb: goalData?.id ? 0 : 3,
            px: isMobile ? 0 : 3,
            pt: 3,
          }}
        >
          {!isMobile && <Divider sx={{ width: '100%' }} />}

          {pageErrors?.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            ))}

          <Button
            onClick={submitForm}
            disabled={disableSubmit}
            fullWidth
            variant="contained"
            sx={{
              width: isMobile ? '100%' : '24%',
              my: 2,
              py: 2,
              mb: goalData?.id ? 1 : 3,
              float: 'right',
            }}
          >
            {goalData?.id ? t('treatmentPlans.goalForm.update') : t('treatmentPlans.goalForm.add')}
          </Button>

          {goalData?.id && (
            <Button
              onClick={showAddObjectivePopup}
              fullWidth
              variant="contained"
              sx={{
                width: isMobile ? '100%' : '24%',
                my: 2,
                py: 2,
                mb: goalData?.id ? 1 : 3,
                ml: isMobile ? 0 : 2,
                float: 'right',
              }}
            >
              {t('treatmentPlans.objectiveList.add')}
            </Button>
          )}
        </Box>

        {goalData?.id && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
              px: isMobile ? 0 : 3,
            }}
          >
            <ObjectiveList
              goalCompleted={goalData.status === GoalStatus.Completed}
              objectiveRows={goalData.objectives || []}
              goalData={goalData}
              isNoIndent={true}
              defaultRowStatus={objectiveRowStatus}
            />
          </Box>
        )}
      </RoundedContainer>
      {/* Add Objective Popup */}
      {goalData?.id && (
        <LocalPopupModal
          isOpen={isOpenAddObjectivePopup}
          contentPopup={addObjectiveContentPopup}
          onClose={closeAddObjectivePopup}
          maxWidth={'lg'}
          fullWidth={true}
        >
          <ObjectiveForm
            parentGoalId={goalData.id}
            onFormSubmitCallback={onAddObjectiveFormSubmitCallback}
          />
        </LocalPopupModal>
      )}
    </Box>
  );
}
