import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { buildRedirectUrl } from '../../app/routes';
import {
  useGetInterviewSettingsQuery,
  useGetPatientInterviewSettingsQuery,
} from '../../app/services/interview';
import { useGetPatientQuery } from '../../app/services/patient';
import { useToast } from '../../app/toast';
import Loading from '../../common/components/Loading';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import DashboardLayout from '../../common/layouts/Dashboard';
import { linkWrapperStyle } from '../../common/utils/commonStyles';
import {
  addBreadcrumb,
  BreadcrumbType,
  editBreadcrumbs,
  ReplaceType,
} from '../../common/utils/editBreadcrums';
import { standardizeRedirectText } from '../../common/utils/editText';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { InterviewSettingsViewName } from '../../common/utils/interviewSettings';
import { InterviewMode } from '../../common/utils/interviewsMode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import ErrorPage from '../error/ErrorPage';
import { clearPatientMonitorData } from '../monitor/monitorSlide';
import { clearAssessmentCachedData } from '../patient/patientUtils';
import { StyledButton } from './ReadyOrResumeInterview';
import { StyledBox } from './StartInterviewPage';
import {
  selectInterviewMode,
  selectPatientInterviewData,
  selectPreviousInterviewTestId,
  setClinicalFollowUps,
  clearPatientInterviewData,
} from './interviewSlice';

export default function CompleteInterviewPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { patientId, interviewResultId: inputInterviewResultId } = useParams();
  const interviewResultId = parseInt(inputInterviewResultId!);
  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }]; // TODO: should create common logic?
  const newBreadcrumb: BreadcrumbType = {
    text: 'Patient Dashboard',
    link: `/dashboard/patient/${patientId}`,
  };
  const interviewMode = useAppSelector(selectInterviewMode);
  const patientInterviewData = useAppSelector(selectPatientInterviewData);
  const previousInterviewTestId = useAppSelector(selectPreviousInterviewTestId);
  const { state } = useLocation();
  const { editInterview } = !!state && state;
  const isClinicianInterview = interviewMode === InterviewMode.Clinician || editInterview;
  const toast = useToast();
  const showToastOneTime = useRef<boolean>(true);

  const { data: infoData, error } = useGetPatientQuery(
    isClinicianInterview && patientId ? { patientId } : skipToken
  );

  const { data: patientAccountSettings, isLoading: isSettingsLoading } =
    useGetPatientInterviewSettingsQuery(
      !isClinicianInterview && patientInterviewData?.accessToken
        ? {
            accessToken: patientInterviewData.accessToken,
            interviewType: InterviewMode.Patient,
            viewName: InterviewSettingsViewName.Complete,
          }
        : skipToken
    );

  const { data: clinicianAccountSettings } = useGetInterviewSettingsQuery(
    isClinicianInterview
      ? {
          interviewTestId: interviewResultId,
          interviewType: InterviewMode.Clinician,
          viewName: InterviewSettingsViewName.Complete,
        }
      : skipToken
  );

  const accountSettings = patientAccountSettings || clinicianAccountSettings;
  const hasAccountSettings = !!accountSettings?.completeText;

  useEffect(() => {
    if (patientId && interviewResultId) {
      dispatch(setClinicalFollowUps({ patientId, interviewResultId }));
    }
  }, [dispatch, interviewResultId, patientId]);

  useEffect(() => {
    if (showToastOneTime.current) {
      showToastOneTime.current = false;
      toast.publish(t('interview.completePage.notify'), 'success', 3000);
    }
  }, [t, toast, showToastOneTime]);

  const savedListResultIds = localStorage.getItem('listResultIds');
  const parsedListResultIds = savedListResultIds ? JSON.parse(savedListResultIds) : [];
  const isValidResultId =
    !parsedListResultIds || parsedListResultIds.includes(interviewResultId?.toString());

  // check if interview result id in URL is valid
  if (
    (isClinicianInterview && previousInterviewTestId !== interviewResultId) ||
    (!isClinicianInterview && !isValidResultId)
  ) {
    return <ErrorPage statusCode={HttpStatusCode.NotFound} />;
  }

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  const renderStaticCompletePageBody = () => {
    return isClinicianInterview ? (
      <>
        <Typography variant="subtitle1">{t('interview.completePage.clinician.body.p1')}</Typography>
        <Typography variant="subtitle1" sx={{ py: 2 }}>
          {t('interview.completePage.clinician.body.p2')}
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="subtitle1">{t('interview.completePage.patient.body.p1')}</Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle1" sx={{ pt: 2 }}>
          {t('interview.completePage.patient.closeBtnHelpText')}
        </Typography>
      </>
    );
  };

  const renderCompletePageBody = () => {
    if (hasAccountSettings) {
      return (
        <>
          <Typography
            dangerouslySetInnerHTML={{
              __html: `${accountSettings.completeText}`,
            }}
            sx={(theme) => ({ ...linkWrapperStyle(theme) })}
          ></Typography>
        </>
      );
    }
    return renderStaticCompletePageBody();
  };

  return (
    <DashboardLayout
      breadcrumbs={editBreadcrumbs(addBreadcrumb(breadcrumbs!, newBreadcrumb), ...replacePath)}
      showBreadcrumb={isClinicianInterview}
      sessionExpiration={isClinicianInterview}
    >
      <Helmet>
        <title>{getPageTitle(t('interview.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        {isClinicianInterview && (
          <StyledBox>
            <PatientInfoCard data={infoData!} />
          </StyledBox>
        )}
        <StyledBox>
          <Box sx={{ py: 3 }}>
            <Typography variant="h1">{t('interview.completePage.title')}</Typography>
          </Box>
          {isSettingsLoading ? (
            <Loading />
          ) : (
            <>
              <Box sx={{ mb: 1 }}>{renderCompletePageBody()}</Box>
              <Box
                sx={{
                  py: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {isClinicianInterview ? (
                  <StyledButton
                    type="button"
                    variant="contained"
                    onClick={() => {
                      navigate(`/dashboard/patient/${patientId}`);
                      clearAssessmentCachedData();
                      dispatch(clearPatientInterviewData());
                      dispatch(clearPatientMonitorData());
                      localStorage.removeItem('listResultIds');
                    }}
                  >
                    {t('interview.completePage.done')}
                  </StyledButton>
                ) : (
                  <StyledButton
                    type="button"
                    variant="contained"
                    onClick={() => {
                      navigate(buildRedirectUrl(accountSettings?.redirectUrl));
                      dispatch(clearPatientInterviewData());
                      dispatch(clearPatientMonitorData());
                      localStorage.removeItem('listResultIds');
                    }}
                  >
                    {accountSettings?.redirectText
                      ? standardizeRedirectText(accountSettings?.redirectText)
                      : t('interview.completePage.patient.closeBtn')}
                  </StyledButton>
                )}
              </Box>
            </>
          )}
        </StyledBox>
      </Container>
    </DashboardLayout>
  );
}
