import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { BaseProps } from '../../types/PageProps';
import Copyright from '../components/Copyright';
import Notification from '../components/Notification';

export default function BaseLayout({ children, showCopyright = true, background = "inherit" }: BaseProps) {
  return (
    <Container maxWidth="xl" disableGutters sx={{ position: 'relative', background }}>
      <Notification />
      {children}
      {showCopyright && <Box sx={{ pb: 1 }}>
        <Copyright />
      </Box>}
    </Container>
  );
}
