import { useDownloadInterviewOrMonitorReportMutation } from '../../../app/services/interview';
import { useToast } from '../../../app/toast';
import { openReport } from '../../../common/utils/reportUtils';
import {
  DownloadReportRequest,
  DownloadReportResponse,
} from '../../../types/InterviewReportRequest';
import { TranslateFunction } from '../../../types/TranslateFunction';

export default function useDownloadInterviewOrMonitorReport(t: TranslateFunction): {
  startDownloadReport: (data: DownloadReportRequest) => Promise<DownloadReportResponse | void>;
} {
  const toast = useToast();
  const [downloadReport] = useDownloadInterviewOrMonitorReportMutation();

  const startDownloadReport = async (
    data: DownloadReportRequest
  ): Promise<DownloadReportResponse | void> => {
    const w = window.open(`/loading`, '_blank');
    try {
      const downloadResponse = await downloadReport(data).unwrap();
      if (downloadResponse && downloadResponse.token) {
        Object.assign(data, { token: downloadResponse.token, fileName: downloadResponse.fileName });
        openReport(data as DownloadReportRequest & { fileName: string; token: string }, w);
        return downloadResponse;
      } else {
        w?.close();
        toast.publish(t('interviewReport.error.cannotViewReport'), 'error');
      }
    } catch (e) {
      w?.close();
      toast.publish(t('interviewReport.error.cannotViewReport'), 'error');
    }
  };

  return {
    startDownloadReport,
  };
}
