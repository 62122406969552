import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import { buildInterviewUrl } from '../../app/services';
import { useGetReportTokenQuery } from '../../app/services/patient';
import { getPageTitle } from '../../common/utils/pageUtils';
import { useCallback, useState } from 'react';
import { GlobalLoading } from '../../common/components/GlobalLoading';

export default function PatientReportContentPage() {
  const { t } = useTranslation();
  const { patientId, reportName } = useParams();
  const [reportLoaded, setReportLoaded] = useState(false);

  const { data: reportTokenRes } = useGetReportTokenQuery(
    reportName && patientId ? { patientId } : skipToken
  );

  const onLoad = useCallback(() => {
    setReportLoaded(true);
  }, []);

  return (
    <Box>
      <Helmet>
        <title>{getPageTitle(t('patientReport.title'))}</title>
      </Helmet>
      {!reportLoaded && <GlobalLoading appBarHeight={0} loadingText={t('patientReport.generatingReport')} />}
      {patientId && reportName && reportTokenRes?.token && (
        <iframe
          onLoad={onLoad}
          title={reportName}
          src={`${buildInterviewUrl(
            '/v1/patient-report/download-report'
          )}/${reportName}?patientId=${patientId}&token=${reportTokenRes?.token}`}
          style={{ height: '100vh', width: '100%' }}
        ></iframe>
      )}
    </Box>
  );
}
