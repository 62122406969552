import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import RoundedContainer from '../../common/components/RoundedContainer';
import TagList from '../../common/components/TagList';
import {
  getAccountInterviewTypes,
  getAutoIntakePacket,
  getEhrEnabled,
} from '../../common/utils/accountUtils';
import checkNullData from '../../common/utils/checkNullData';
import { countryType, sortedCountryCodes, USCountryCode } from '../../common/utils/countries';
import { populateLanguage } from '../../common/utils/languages';
import { Role } from '../../common/utils/loginRole';
import { getStateErrorKey, usStates } from '../../common/utils/usStates';
import { validateEmail } from '../../common/utils/validationUtils';
import { InterviewTypeItem } from '../../types/AccountType';
import { ClinicAccountItems } from '../../types/ClinicAccountType';
import { SiteInfo } from '../../types/ClinicSiteType';
import { ResponseError } from '../../types/ResponseError';
import { Provider } from '../../types/UserTypes';
import { selectUser } from '../auth/authSlice';

interface SiteFormProps {
  siteId?: number;
  data?: SiteInfo;
  clinicAccounts?: ClinicAccountItems[];
  onFormSubmit: (data: SiteInfo, siteId?: number, successCallback?: () => void) => Promise<void>;
}

const defaultFormValues: SiteInfo = {
  clinicAccountId: '',
  name: '',
  location: '',
  country: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  timezone: '',
  locationId: '',
  suicideEmails: [],
  interviewResultEmails: [],
  intakePacketInterviews: [],
  intakePacketDefaultProvider: undefined,
  optInPatientReminders: false,
};

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '49%',
    },
  },
}));

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    width: '45px',
    height: '45px',
  },
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 30,
  },
}));

export default function AddOrEditForm({
  siteId,
  data,
  clinicAccounts,
  onFormSubmit,
}: SiteFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [stateLabel, setStateLabel] = useState<string>(t('clinicSite.manageSites.siteForm.state'));
  const [isUSA, setIsUSA] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>('');
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [timezones, setTimezones] = useState<string[]>([]);
  const currentUser = useAppSelector(selectUser);
  const [isStateDisabled, setStateDisabled] = useState<boolean>(false);
  const [autoIntakePacket, setAutoIntakePacket] = useState<boolean | undefined>(false);
  const [openDrop, setOpenDrop] = useState<boolean>(false);
  const [intakePacketInterviews, setIntakePacketInterviews] = useState<InterviewTypeItem[]>([]);
  const [multiCheckInterview, setMultiCheckInterview] = useState<number[]>([]);
  const [activeProviderList, setActiveProviderList] = useState<Provider[]>([]);
  const [ehrEnabled, setEhrEnabled] = useState<boolean | undefined>(false);
  const [stateData, setStateData] = useState<string>('');

  const noState = 'NO STATE';

  const validationSchema = yup
    .object({
      clinicAccountId: yup
        .string()
        .trim()
        .test(
          'validate-account',
          t('clinicSite.manageSites.error.blankAccount'),
          function (clinicAccountId) {
            return clinicAccountId !== '';
          }
        ),
      name: yup.string().required(t('clinicSite.manageSites.error.blankName')),
      location: yup.string().required(t('clinicSite.manageSites.error.blankLocation')),
      addressLine1: yup.string().required(t('clinicSite.manageSites.error.blankAddressLine1')),
      addressLine2: yup.string(),
      country: yup.string().required(t('clinicSite.manageSites.error.blankCountry')),
      city: yup.string().required(t('clinicSite.manageSites.error.blankCity')),
      state: yup
        .string()
        .typeError(`${t('clinicSite.manageSites.error.blankState')}`)
        .test('state', '', function (state) {
          if (!state && state !== noState) {
            return this.createError({ message: t(getStateErrorKey(stateLabel)) });
          }
          return true;
        }),
      zip: yup.string().required(t('clinicSite.manageSites.error.blankZipCode')),
      timezone: yup.string().required(t('clinicSite.manageSites.error.blankTimeZone')),
      locationId: yup.string(),
      intakePacketInterviews: yup.mixed(),
      intakePacketDefaultProvider:
        activeProviderList.length === 0
          ? yup.string()
          : yup.string().when('intakePacketInterviews', {
              is: (intakePacketInterviews: number[]) => {
                return intakePacketInterviews.length > 0;
              },
              then: yup
                .string()
                .required(t('clinicSite.manageSites.error.blankIntakePacketDefaultProvider')),
            }),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    resetField,
    reset,
  } = useForm<SiteInfo>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const validateEmails = useCallback((value: string[]): boolean => {
    return !value.some((email) => !validateEmail(email));
  }, []);

  // bind controls based on selected country code
  useEffect(() => {
    if (countryCode) {
      setIsUSA(countryCode === USCountryCode);
      const { adminDistrict, timezone } = sortedCountryCodes[countryCode];
      setTimezones(Array.isArray(timezone) ? timezone : [timezone]);
      setStateLabel(adminDistrict);
      const isNoState = adminDistrict === noState;
      setStateDisabled(isNoState);

      if (isNoState) {
        setValue('state', noState);
        setStateData(noState);
      }
      setValue('country', countryCode);
      setCountryCode(countryCode);
    }
  }, [countryCode, setValue, isUSA]);

  // initial data load
  useEffect(() => {
    if (siteId && data !== undefined) {
      if (!isDataLoaded) {
        setIsDataLoaded(true);
        const bindEditControls = async () => {
          Object.keys(defaultFormValues).forEach((field: any) => {
            setValue(field, checkNullData(data[field as keyof SiteInfo]));

            if (data.country) {
              setStateLabel(sortedCountryCodes[data.country as keyof countryType].adminDistrict);
              setCountryCode(data.country);
            } else {
              setStateDisabled(true);
            }
            if (data.state) {
              setStateData(data.state);
            }
            if (data.intakePacketInterviews?.length !== 0 && data.intakePacketInterviews) {
              setMultiCheckInterview(data.intakePacketInterviews);
              setValue('intakePacketInterviews', data.intakePacketInterviews);
              if (data.intakePacketDefaultProvider) {
                setValue('intakePacketDefaultProvider', data.intakePacketDefaultProvider);
              }
            }
            if (data.activeProviderList) {
              setActiveProviderList(
                [...data.activeProviderList].sort(
                  (a: Provider, b: Provider) =>
                    a.lastName?.localeCompare(b.lastName) || a.firstName?.localeCompare(b.firstName)
                )
              );
            }
          });
        };
        bindEditControls();
      }
      if (clinicAccounts) {
        setAutoIntakePacket(getAutoIntakePacket(clinicAccounts, data.clinicAccountId));
        setIntakePacketInterviews(getAccountInterviewTypes(clinicAccounts, data.clinicAccountId));
        setEhrEnabled(getEhrEnabled(clinicAccounts, data.clinicAccountId));
      }
    }
    if (!siteId) {
      if (currentUser !== Role.SuperAdmin) {
        setValue('clinicAccountId', currentUser.clinicAccountId);
      }
      setAutoIntakePacket(getAutoIntakePacket(clinicAccounts, currentUser.clinicAccountId));
      setIntakePacketInterviews(
        getAccountInterviewTypes(clinicAccounts, currentUser.clinicAccountId)
      );
      setEhrEnabled(getEhrEnabled(clinicAccounts, currentUser.clinicAccountId));
    }
  }, [siteId, isDataLoaded, clinicAccounts, setValue, data, currentUser, control]);

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccountId', selectedValue);
    setAutoIntakePacket(getAutoIntakePacket(clinicAccounts, selectedValue));
    setMultiCheckInterview([]);
    setEhrEnabled(getEhrEnabled(clinicAccounts, selectedValue));
    setOpenDrop(false);

    if (getAutoIntakePacket(clinicAccounts, selectedValue)) {
      setIntakePacketInterviews(getAccountInterviewTypes(clinicAccounts, selectedValue));
      setValue('intakePacketInterviews', []);
    } else {
      resetField('intakePacketInterviews');
      resetField('intakePacketDefaultProvider');
    }
  };

  const handleCountryChange = (e: any, values: any) => {
    const selectedValue = values ? values.code : '';

    setValue('state', '');
    setStateData('');

    setValue('timezone', '');
    setCountryCode(selectedValue);
    setValue('country', selectedValue);

    if (!selectedValue) {
      setStateDisabled(true);
    }
  };

  const handleStateChange = (e: any, values: any) => {
    const selectedState = values ? values.code : '';
    setValue('state', selectedState);
    setStateData(selectedState);
    if (!selectedState) {
      setValue('timezone', '');
    }
  };

  const handleTimezoneChange = (e: any, values: any) => {
    const selectedState = values || '';
    setValue('timezone', selectedState);
  };

  const handleDefaultProviderChange = (e: any, values: any) => {
    const selectedState = values ? values.id : '';
    setValue('intakePacketDefaultProvider', selectedState);
  };

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getAccountOptionLabel(option),
  });
  const filterCountryOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });
  const filterStateOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });
  const filterOptionsTimezone = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option,
  });
  const filterDefaultProviderOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getDefaultProviderLabel(option),
  });

  const getAccountOptionLabel = (option: any) => `${option.name} (${option.id})`;

  const getDefaultProviderLabel = (option: any) => `${option.lastName}, ${option.firstName}`;

  const handleClick = () => {
    setOpenDrop(!openDrop);
  };

  const handleChangeSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let interviewIds: number[] = [];
    if (isChecked) {
      interviewIds = (intakePacketInterviews.length !== 0 ? intakePacketInterviews! : []).map(
        (interview) => interview.id
      );
    } else {
      resetField('intakePacketDefaultProvider');
    }
    setValue('intakePacketInterviews', interviewIds);
    setMultiCheckInterview(interviewIds);
  };

  const handleOptInPatientRemindersChange = (event: any) => {
    const isChecked = event?.target?.checked;
    setValue('optInPatientReminders', isChecked);
  };

  const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    let interviewIds: number[] = [];
    const checkExistValue = multiCheckInterview.includes(parseInt(value));
    if (!checkExistValue) {
      interviewIds = [...multiCheckInterview, parseInt(value)];
      setMultiCheckInterview(interviewIds);
    } else {
      interviewIds = [...multiCheckInterview.filter((item) => item !== parseInt(value))];
      setMultiCheckInterview(interviewIds);
    }
    setValue('intakePacketInterviews', interviewIds);
    if (interviewIds.length === 0) {
      resetField('intakePacketDefaultProvider');
    }
  };

  const onSubmit = async (data: SiteInfo) => {
    // This error is only for set error manually
    if (errors.suicideEmails) {
      setError('suicideEmails', errors.suicideEmails as any);
    }
    if (errors.interviewResultEmails) {
      setError('interviewResultEmails', errors.interviewResultEmails as any);
    }

    if (errors.suicideEmails || errors.interviewResultEmails) {
      return;
    }

    try {
      if (!autoIntakePacket && data && data.intakePacketInterviews) {
        data.intakePacketInterviews = undefined;
      }
      if (!ehrEnabled) {
        data.locationId = undefined;
      }
      await onFormSubmit(data, siteId, () => {
        if (!siteId) {
          reset();
        }
        setPageErrors([]);
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const onInvalidSubmit = () => {
    // This error is only for set error manually
    if (errors.suicideEmails) {
      setError('suicideEmails', errors.suicideEmails as any);
    }
    if (errors.interviewResultEmails) {
      setError('interviewResultEmails', errors.interviewResultEmails as any);
    }
  };

  const submitForm = async () => {
    handleSubmit(onSubmit, onInvalidSubmit)();
  };

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {(intakePacketInterviews && intakePacketInterviews.length !== 0
        ? intakePacketInterviews
        : []
      ).map((interview: InterviewTypeItem) => {
        return (
          <FormControlLabel
            key={interview.id}
            label={`${interview.name} (${populateLanguage(interview.language)})`}
            value={interview.id}
            control={
              <StyledCheckbox
                checked={multiCheckInterview.includes(interview.id)}
                onChange={handleChangeCheck}
              />
            }
          />
        );
      })}
    </Box>
  );

  return (
    <Box
      component="form"
      noValidate
      sx={{
        px: 0,
        pt: 0,
      }}
    >
      <RoundedContainer sx={{ marginBottom: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            px: matches ? 0 : 3,
            py: 3,
          }}
        >
          {!siteId && currentUser.roleId === Role.SuperAdmin && (
            <Controller
              name="clinicAccountId"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  data-testid="mui-component-select-clinic-account"
                  options={clinicAccounts || []}
                  getOptionLabel={getAccountOptionLabel}
                  onChange={handleAccountChange}
                  value={value ? clinicAccounts?.find((item) => item.id === value) || [] : null}
                  filterOptions={filterAccountOptions}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {getAccountOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.clinicAccountId}
                      helperText={errors.clinicAccountId?.message}
                      margin="normal"
                      fullWidth
                      label={t('clinicSite.manageSites.siteForm.account')}
                    />
                  )}
                />
              )}
            />
          )}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.name}
                helperText={errors.name?.message}
                margin="normal"
                fullWidth
                label={t('clinicSite.manageSites.siteForm.name')}
                variant="outlined"
                sx={{
                  width: siteId || currentUser.roleId !== Role.SuperAdmin ? '100%' : '49%',
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
              />
            )}
          />
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.location}
                helperText={errors.location?.message}
                margin="normal"
                fullWidth
                label={t('clinicSite.manageSites.siteForm.location')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="country"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <StyleAutocomplete
                {...props}
                data-testid="mui-component-select-country"
                options={Object.values(sortedCountryCodes)}
                getOptionLabel={(option: any) => (option.name ? option.name : '')}
                onChange={handleCountryChange}
                value={value ? sortedCountryCodes[value] : null}
                filterOptions={filterCountryOptions}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.code} value={option.code}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.country}
                    helperText={errors.country?.message}
                    margin="normal"
                    fullWidth
                    label={t('clinicSite.manageSites.siteForm.country')}
                  />
                )}
              />
            )}
          />

          <Controller
            name="addressLine1"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.addressLine1}
                helperText={errors.addressLine1?.message}
                margin="normal"
                fullWidth
                label={t('clinicSite.manageSites.siteForm.addressLine1')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="addressLine2"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.addressLine2}
                helperText={errors.addressLine2?.message}
                margin="normal"
                fullWidth
                label={t('clinicSite.manageSites.siteForm.addressLine2')}
                variant="outlined"
              />
            )}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: matches ? 'column' : 'row',
              justifyContent: 'space-between',
              width: matches ? '100%' : '49%',
            }}
          >
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  margin="normal"
                  fullWidth
                  label={t('clinicSite.manageSites.siteForm.city')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              render={({ fieldState, formState, field, ...props }) =>
                isUSA ? (
                  <StyleAutocomplete
                    {...props}
                    data-testid="mui-component-select-state"
                    options={usStates}
                    getOptionLabel={(option: any) => (option.name ? option.name : '')}
                    onChange={handleStateChange}
                    disabled={isStateDisabled || !countryCode}
                    value={
                      field.value ? usStates.find((state) => state.code === field.value) : null
                    }
                    filterOptions={filterStateOptions}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.code} value={option.code}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.state}
                        helperText={errors.state?.message}
                        margin="normal"
                        fullWidth
                        label={stateLabel}
                      />
                    )}
                  />
                ) : (
                  <StyleTextField
                    {...field}
                    error={!!errors.state}
                    helperText={errors.state?.message}
                    margin="normal"
                    fullWidth
                    disabled={isStateDisabled || !countryCode}
                    label={stateLabel}
                    variant="outlined"
                  />
                )
              }
            />
          </Box>
          <Controller
            name="zip"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.zip}
                helperText={errors.zip?.message}
                margin="normal"
                fullWidth
                label={t('clinicSite.manageSites.siteForm.zipCode')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="timezone"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <StyleAutocomplete
                {...props}
                data-testid="mui-component-select-timezone"
                options={timezones || []}
                disabled={isUSA && !stateData}
                getOptionLabel={(option: any) => option || null}
                onChange={handleTimezoneChange}
                value={value ? timezones?.find((timezone) => timezone === value) || null : null}
                filterOptions={filterOptionsTimezone}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option} value={option}>
                      {option}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.timezone}
                    helperText={errors.timezone?.message}
                    margin="normal"
                    fullWidth
                    disabled={isUSA && !stateData}
                    label={t('clinicSite.manageSites.siteForm.timezone')}
                  />
                )}
              />
            )}
          />
          <Controller
            name="locationId"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                disabled={!ehrEnabled}
                error={!!errors.locationId}
                helperText={errors.locationId?.message}
                margin="normal"
                fullWidth
                label={t('clinicSite.manageSites.siteForm.locationId')}
                variant="outlined"
              />
            )}
          />
        </Box>
      </RoundedContainer>
      <RoundedContainer sx={{ paddingTop: 1, paddingBottom: 3, marginBottom: 2 }}>
        <Typography
          sx={{ color: 'rgba(0, 0, 0, 0.5)', marginBottom: 1, paddingLeft: 3, paddingTop: 1 }}
        >
          {t('clinicSite.manageSites.siteForm.siteSettings')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            px: matches ? 0 : 3,
          }}
        >
          <Controller
            name="suicideEmails"
            control={control}
            render={({ field }) => (
              <TagList
                sx={{ width: matches ? '100%' : '49%' }}
                label={t('clinicSite.manageSites.siteForm.suicideAlertEmails')}
                tags={field.value}
                error={errors.suicideEmails}
                setTags={(tags: string[]) => {
                  setValue('suicideEmails', tags);
                }}
                validationFn={validateEmails}
                setError={() => {
                  setError('suicideEmails', {
                    message: t('clinicSite.manageSites.error.invalidEmail'),
                  });
                }}
                clearError={() => {
                  clearErrors('suicideEmails');
                }}
              />
            )}
          />
          <Controller
            name="interviewResultEmails"
            control={control}
            render={({ field }) => (
              <TagList
                sx={{ width: matches ? '100%' : '49%' }}
                label={t('clinicSite.manageSites.siteForm.interviewResultEmails')}
                tags={field.value}
                error={errors.interviewResultEmails}
                setTags={(tags: string[]) => {
                  setValue('interviewResultEmails', tags);
                }}
                validationFn={validateEmails}
                setError={() => {
                  setError('interviewResultEmails', {
                    message: t('clinicSite.manageSites.error.invalidEmail'),
                  });
                }}
                clearError={() => {
                  clearErrors('interviewResultEmails');
                }}
              />
            )}
          />
          <Controller
            name="optInPatientReminders"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<StyledCheckbox />}
                checked={field.value}
                onChange={handleOptInPatientRemindersChange}
                label={`${t('patient.patientInfo.optInPatientReminders')}`}
              />
            )}
          />
        </Box>
      </RoundedContainer>
      <RoundedContainer sx={{ paddingTop: 1 }}>
        <Typography
          sx={{ color: 'rgba(0, 0, 0, 0.5)', marginBottom: 1, paddingLeft: 3, paddingTop: 1 }}
        >
          {t('clinicSite.manageSites.siteForm.intakePacketSettings')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            px: matches ? 0 : 3,
            pt: 0,
          }}
        >
          <FormControl
            error={!!errors.intakePacketInterviews}
            sx={{ width: matches ? '100%' : '49%', height: '83px' }}
            disabled={!autoIntakePacket}
          >
            <Box
              sx={{
                borderRadius: 2,
                border: `1px solid ${
                  !!!errors.intakePacketInterviews ? theme.xPalette.sliver : theme.xPalette.warning
                }`,
                height: '-webkit-fill-available',
                p: '5px',
                m: '16px 0px 8px 0px',
                position: 'relative',
              }}
            >
              <ListItemButton
                onClick={handleClick}
                disabled={!autoIntakePacket}
                sx={{
                  borderRadius: '8px 8px 0px 0px',
                  bottom: '2px',
                  pb: 0,
                  px: 1,
                }}
              >
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      display: 'inline-block',
                      width: '98%',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    },
                  }}
                  primary={
                    multiCheckInterview.length > 0
                      ? intakePacketInterviews
                          .filter((interview) =>
                            multiCheckInterview.some(
                              (mulInterview) =>
                                mulInterview?.toString() === interview.id?.toString()
                            )
                          )
                          .map(
                            (interview) =>
                              `${interview.name} (${populateLanguage(interview.language)})`
                          )
                          .join(', ')
                      : t('clinicSite.manageSites.siteForm.interviewsForIntakePacket')
                  }
                />
                {openDrop ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={openDrop}
                timeout="auto"
                unmountOnExit
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: '52px',
                  zIndex: 99,
                  backgroundColor: 'white',
                  width: '100%',
                  border: `1px solid ${
                    !!!errors.intakePacketInterviews
                      ? theme.xPalette.sliver
                      : theme.xPalette.warning
                  }`,
                  maxHeight: '400px',
                  overflow: 'auto',
                  borderRadius: '0 0 10px 10px',
                }}
              >
                <List component="div" sx={{ m: '16px 40px' }}>
                  <Controller
                    name="intakePacketInterviews"
                    control={control}
                    render={({ field }) => (
                      <>
                        <FormControlLabel
                          {...field}
                          label={`${t('clinicSite.manageSites.siteForm.selectAllInterviews')}`}
                          control={
                            <StyledCheckbox
                              checked={intakePacketInterviews.length === multiCheckInterview.length}
                              indeterminate={
                                multiCheckInterview.length > 0 &&
                                multiCheckInterview.length !== intakePacketInterviews.length
                              }
                              onChange={handleChangeSelectAll}
                            />
                          }
                        />
                        {children}
                      </>
                    )}
                  />
                </List>
              </Collapse>
            </Box>
            {!!errors.intakePacketInterviews && (
              <FormHelperText>
                {errors.intakePacketInterviews && (errors.intakePacketInterviews as any).message}
              </FormHelperText>
            )}
          </FormControl>
          <Controller
            name="intakePacketDefaultProvider"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <Autocomplete
                {...props}
                fullWidth
                data-testid="mui-component-select-default-provider"
                options={activeProviderList || []}
                getOptionLabel={getDefaultProviderLabel}
                onChange={handleDefaultProviderChange}
                sx={{ width: matches ? '100%' : '49%' }}
                value={value ? activeProviderList?.find((item) => item.id === value) || null : null}
                disabled={
                  !autoIntakePacket ||
                  !getValues('intakePacketInterviews') ||
                  getValues('intakePacketInterviews')?.length === 0 ||
                  activeProviderList.length === 0
                }
                filterOptions={filterDefaultProviderOptions}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.id} value={option.id}>
                      {getDefaultProviderLabel(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.intakePacketDefaultProvider}
                    helperText={errors.intakePacketDefaultProvider?.message}
                    margin="normal"
                    fullWidth
                    label={t('clinicSite.manageSites.siteForm.defaultProviderForIntakePacket')}
                  />
                )}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            pb: 3,
            px: matches ? 0 : 3,
            pt: 3,
          }}
        >
          {!matches && <Divider sx={{ width: '100%' }} />}

          {pageErrors.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            ))}
          <Button
            onClick={submitForm}
            fullWidth
            variant="contained"
            sx={{
              width: matches ? '100%' : '24%',
              my: 2,
              py: 2,
              float: 'right',
            }}
          >
            {siteId
              ? t('clinicSite.manageSites.siteForm.updateSite')
              : t('clinicSite.manageSites.siteForm.createSite')}
          </Button>
        </Box>
      </RoundedContainer>
    </Box>
  );
}
