import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import {
  useGenerateDataFileMutation,
  useGetAllInterviewTypePagingQuery,
} from '../../app/services/interview';
import { useGetMonitorTypesByBaseTrackerQuery } from '../../app/services/monitor';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { canViewBuilder } from '../../common/utils/permissionUtils';
import { toTitleCase } from '../../common/utils/stringUtils';
import { AssessmentTypesPaging, InterviewDataFile } from '../../types/MonitorTypes';
import { PageProps } from '../../types/PageProps';
import { selectUser } from '../auth/authSlice';
import ErrorPage from '../error/ErrorPage';
import { useToast } from '../../app/toast';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledAddMonitorTypeBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: '18px',
  height: '56px',
  width: '30%',
  minWidth: '200px',
  padding: '6px 16px',
  ml: 1,
  [theme.breakpoints.down('md')]: {
    width: '96%',
    ml: 0,
  },
}));

const useCustomAssessmentTypesRequest = ({
  assessmentId,
  isInterviewType,
  isMonitorType,
  currentPage,
  pageSize,
}: {
  assessmentId?: string;
  isInterviewType?: boolean;
  isMonitorType?: boolean;
  currentPage?: number;
  pageSize?: number;
}): { data: any; error: any; isLoading: boolean } => {
  const {
    data: interviewTypesData,
    isLoading: isLoadingInterview,
    error: errorInterview,
  } = useGetAllInterviewTypePagingQuery(
    assessmentId && isInterviewType
      ? {
          interviewId: assessmentId,
          page: currentPage,
          pageSize: pageSize,
        }
      : skipToken
  );

  const {
    data: monitorTypesData,
    isLoading: isLoadingMonitor,
    error: errorMonitor,
  } = useGetMonitorTypesByBaseTrackerQuery(
    assessmentId && isMonitorType
      ? {
          baseTracker: assessmentId,
          page: currentPage,
          pageSize: pageSize,
        }
      : skipToken
  );

  return {
    data: interviewTypesData || monitorTypesData,
    error: errorInterview || errorMonitor,
    isLoading: isLoadingInterview || isLoadingMonitor,
  };
};

export default function AssessmentTypeListPage({ breadcrumbs }: PageProps) {
  const { assessmentId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const toast = useToast();
  const isInterviewType = pathname?.includes('interview-types');
  const isMonitorType = pathname?.includes('monitor-types');
  const type = isInterviewType ? 'interview' : 'monitor';
  const currentUser = useAppSelector(selectUser);
  const [searchParams] = useSearchParams();
  const [displayAssessmentTypesData, setDisplayAssessmentTypesData] = useState<
    AssessmentTypesPaging[]
  >([]);
  const [interviewDataFile, setInterviewDataFile] = useState<InterviewDataFile | undefined>(
    undefined
  );
  const tableHeaders: string[] = Object.values(
    t('assessmentBuilder.assessmentType.manageTypes.TableHeader', {
      returnObjects: true,
    })
  );

  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize] = useState<number>(10);

  const [navBackUrl, setNavBackUrl] = useState<string>('/dashboard/builder');

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
  };

  const currentPage = searchOptions.page;

  const updateUrl = (page: number) => {
    const updatedUrl = `/dashboard/assessment-builder/${type}/${assessmentId}/${type}-types?page=${page}`;
    navigate(updatedUrl);
    setNavBackUrl(updatedUrl);
  };

  const handlePageChange = (value: number) => {
    updateUrl(value);
  };

  const [generateDataFile] = useGenerateDataFileMutation();

  const {
    data: assessmentTypesData,
    isLoading,
    error,
  } = useCustomAssessmentTypesRequest({
    assessmentId,
    isInterviewType,
    isMonitorType,
    currentPage,
    pageSize,
  });

  useEffect(() => {
    if (assessmentTypesData) {
      setDisplayAssessmentTypesData(assessmentTypesData.assessmentTypes);
      setInterviewDataFile(assessmentTypesData.interviewDetail);
      setTotalPages(Math.ceil(assessmentTypesData.totalPages));
    }
  }, [pageSize, assessmentTypesData]);

  const renderDesktopData = (data: AssessmentTypesPaging[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => {
                return (
                  <StyledTableCell key={`${header} ${index}`}>
                    {header === t('assessmentBuilder.assessmentType.manageTypes.TableHeader.id')
                      ? `${toTitleCase(type)} ${header}`
                      : header}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: AssessmentTypesPaging) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell sx={{ maxWidth: '500px', minWidth: '150px' }}>
                    <Link
                      sx={{ fontSize: '1em', textAlign: 'left' }}
                      component="button"
                      onClick={() => {
                        navigate(
                          `/dashboard/assessment-builder/${type}/${assessmentId}/${type}-types/edit/${item.id}`,
                          {
                            state: { navBackUrl },
                          }
                        );
                      }}
                    >
                      {`${item.name}`}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>
                    {item?.modules?.join(', ')}
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>{item.id}</StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>
                    {toTitleCase(item.status)}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: AssessmentTypesPaging[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: AssessmentTypesPaging) => {
          const listItems = [
            [
              <>{t('assessmentBuilder.assessmentType.manageTypes.TableHeader.modules')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item?.modules?.join(', ')}</Box>,
            ],
            [
              <>{`${toTitleCase(type)} ${t(
                'assessmentBuilder.assessmentType.manageTypes.TableHeader.id'
              )}`}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.id}</Box>,
            ],
            [
              <>{t('assessmentBuilder.assessmentType.manageTypes.TableHeader.status')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{toTitleCase(item.status)}</Box>,
            ],
          ];
          return {
            leftHeader: (
              <Link
                sx={{ fontSize: '20px', textAlign: 'left', fontWeight: '600' }}
                component="button"
                onClick={() => {
                  navigate(
                    `/dashboard/assessment-builder/${type}/${assessmentId}/${type}-types/edit/${item.id}`,
                    {
                      state: { navBackUrl },
                    }
                  );
                }}
              >
                {`${item.name}`}
              </Link>
            ),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  if (!assessmentId || !canViewBuilder(currentUser.roleId)) {
    return <ErrorPage statusCode={HttpStatusCode.Forbidden} />;
  }

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t(`assessmentBuilder.assessmentType.${type}Type.title`))}</title>
      </Helmet>
      <Container sx={{ px: 1 }} maxWidth="xl" disableGutters={!isDesktop}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 0,
            },
            [theme.breakpoints.down('md')]: {
              px: 1,
              rowGap: 2,
            },
          }}
        >
          <PageHeader
            sx={{
              [theme.breakpoints.up('md')]: { width: '40%' },
              [theme.breakpoints.down('md')]: {
                mb: 2,
                mx: 1,
                width: '100%',
              },
            }}
            headerText={t(`assessmentBuilder.assessmentType.${type}Type.title`)}
            backUrl={`/dashboard/assessment-builder`}
          />
          <Box
            sx={{
              display: 'flex',
              width: isDesktop ? '60%' : '100%',
              justifyContent: isDesktop ? 'flex-end' : isPhone ? 'center' : 'flex-start',
              columnGap: isDesktop ? 2 : 0,
              rowGap: isDesktop ? 0 : 2,
              flexDirection: isDesktop ? 'row' : 'column',
            }}
          >
            {interviewDataFile && (
              <Box
                sx={{
                  maxWidth: isDesktop ? '50%' : '100%',
                  display: !isDesktop ? 'flex' : '',
                  justifyContent: !isDesktop ? 'center' : '',
                }}
              >
                <StyledAddMonitorTypeBox
                  type="button"
                  fullWidth
                  variant="contained"
                  onClick={async () => {
                    const res = await generateDataFile({
                      name: interviewDataFile?.name,
                      language: interviewDataFile?.language,
                    }).unwrap();
                    if (res) {
                      toast.publish(res?.message, 'success');
                    }
                  }}
                >
                  {t('assessmentBuilder.assessmentType.interviewType.generateDataFile')}
                </StyledAddMonitorTypeBox>
              </Box>
            )}

            <Box
              sx={{
                maxWidth: isDesktop ? '50%' : '100%',
                display: !isDesktop ? 'flex' : '',
                justifyContent: !isDesktop ? 'center' : '',
              }}
            >
              <StyledAddMonitorTypeBox
                type="button"
                fullWidth
                variant="contained"
                onClick={() => {
                  navigate(
                    `/dashboard/assessment-builder/${type}/${assessmentId}/${type}-types/add`,
                    {
                      state: { navBackUrl },
                    }
                  );
                }}
              >
                {t(`assessmentBuilder.assessmentType.${type}Type.add`)}
              </StyledAddMonitorTypeBox>
            </Box>
          </Box>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={null} sx={{ paddingX: isDesktop ? 0 : 1 }}>
            {displayAssessmentTypesData.length === 0 ? (
              <RoundedContainer sx={{ mt: 2, p: 2 }}>
                <Typography>
                  {t(`assessmentBuilder.assessmentType.manageTypes.error.${type}TypesNotFound`)}
                </Typography>
              </RoundedContainer>
            ) : (
              <Box sx={{ mt: 2 }}>
                {isDesktop
                  ? renderDesktopData(displayAssessmentTypesData)
                  : renderMobileData(displayAssessmentTypesData)}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 4,
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    background: theme.xPalette.white,
                    mt: 0,
                    [theme.breakpoints.down('sm')]: {
                      mt: 1,
                      py: 2,
                    },
                  }}
                >
                  <CustomPagination
                    changePage={(e, value) => handlePageChange(value)}
                    currentPage={searchOptions.page}
                    pages={totalPages}
                    pageRef={null}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
}
