export interface timeFramesType {
  name: string;
  validValue: string;
}

export const timeFrames: timeFramesType[] = [
  { name: 'No timeframe specified', validValue: '0' },
  { name: 'Past day', validValue: '1' },
  { name: 'Past week', validValue: '2' },
  { name: 'Past 2 weeks', validValue: '3' },
  { name: 'Past month', validValue: '4' },
  { name: 'Past 3 months', validValue: '5' },
  { name: 'Past 6 months', validValue: '6' },
  { name: 'Past year', validValue: '7' },
];
