import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import proemLogo from '../../../assets/images/proem-logo.png';
import Logo from '../../../common/components/Logo';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { getTotalTime } from '../../../common/utils/dateTimeUtils';
import { DefaultLanguage, languages, populateLanguage } from '../../../common/utils/languages';

interface ReportCoverPageProps {
  logo?: any;
  req?: any;
  // InterviewResult or TrackerResult
  result?: any;
  patient?: any;
  interviewer?: any;
  interview?: any;
  isInterview?: boolean;
}

const StyledTypographyHeading = styled(Typography)(() => ({
  fontSize: '35px!important',
  fontWeight: 'bold',
  marginTop: '1rem',
  marginBottom: '1rem',
}));

const StyledTypographyDetailLeft = styled(Typography)(() => ({
  fontSize: '16px!important',
  fontWeight: 'bold',
  textAlign: 'right',
  width: '200px',
}));
const StyledTypographyDetailRight = styled(Typography)(() => ({
  fontSize: '16px!important',
  fontWeight: '500',
  maxWidth: '200px',
  textAlign: 'left',
}));
const StyledTypographyEdited = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: '500',
  fontSize: '40px!important',
  marginBottom: '15px',
  marginTop: '10px',
}));

export default function ReportCoverPage({
  logo,
  result,
  patient,
  interviewer,
  interview,
  isInterview,
}: ReportCoverPageProps) {
  const { t } = useTranslation();
  const renderLogo = () => {
    if (typeof logo !== 'undefined' && logo) {
      return <img src={logo} width="220px" alt="Company Logo" />;
    } else {
      return <img src={proemLogo} width="220px" alt="Proem Logo" />;
    }
  };
  const renderDetail = () => {
    let interviewerFullName = '';
    interviewerFullName = interviewer?.lastName + ', ' + interviewer?.firstName;

    let startedAt = null;
    if (result?.startedAt) {
      startedAt = dayjs(result?.startedAt).tz(patient.timezone).format('MM/DD/YYYY h:mm:ss a');
    }

    let completedAt = null;
    if (result?.completedAt) {
      completedAt = dayjs(result?.completedAt).tz(patient.timezone).format('MM/DD/YYYY h:mm:ss a');
    }

    const totalTime = getTotalTime(result.startedAt, result.completedAt, result.duration);

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box>
          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {patient.lastName
                ? t('assessmentReport.labels.patientName')
                : patient.externalId
                ? t('assessmentReport.labels.patientExternalId')
                : ''}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>
              {patient.lastName
                ? patient.lastName + ', ' + patient.firstName
                : patient.externalId
                ? patient.externalId
                : ''}
            </StyledTypographyDetailRight>
          </Box>
          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {t('assessmentReport.labels.patientNumber')}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>{patient.id}</StyledTypographyDetailRight>
          </Box>
          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {t('assessmentReport.labels.accountName')}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>{patient.clinicAccountName}</StyledTypographyDetailRight>
          </Box>
          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {t('assessmentReport.labels.clinicSite')}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>{patient.clinicSiteName}</StyledTypographyDetailRight>
          </Box>
          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {t('assessmentReport.labels.interviewerName')}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>{interviewerFullName}&nbsp;</StyledTypographyDetailRight>
          </Box>
          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {t('assessmentReport.labels.startDateTime')}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>
              {typeof result?.startedAt !== 'undefined' && !!startedAt ? startedAt : ''}
            </StyledTypographyDetailRight>
          </Box>
          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {t('assessmentReport.labels.endDateTime')}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>
              {typeof result?.completedAt !== 'undefined' && !!completedAt ? completedAt : ''}
            </StyledTypographyDetailRight>
          </Box>
          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {t('assessmentReport.labels.totalTime')}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>{totalTime}</StyledTypographyDetailRight>
          </Box>
          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {t('assessmentReport.labels.interviewName')}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>
            {isInterview
                ? result?.reducedInterview?.name || interview?.name
                : interview.parentMonitor
                ? `${interview.parentMonitor + ':'} ${result?.reducedTracker?.name}`
                : result?.reducedTracker?.name}
            </StyledTypographyDetailRight>
          </Box>

          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <StyledTypographyDetailLeft>
              {t('assessmentReport.labels.language')}
            </StyledTypographyDetailLeft>
            <StyledTypographyDetailRight>
              {interview?.language === DefaultLanguage.Universal
                ? languages[DefaultLanguage.English].name
                : populateLanguage(interview?.language)}
            </StyledTypographyDetailRight>
          </Box>
        </Box>
      </Box>
    );
  };
  const renderBottom = () => {
    return (
      <Box>
        <Typography fontWeight={500}>{t('assessmentReport.labels.poweredBy')}</Typography>
        <Logo width={50} />
      </Box>
    );
  };
  return (
    <RoundedContainer sx={{ position: 'relative', textAlign: 'center', width: '100%' }}>
      <Box sx={{ margin: '190px 0' }}>
        {renderLogo()}
        <StyledTypographyHeading>
          {isInterview
            ? t('assessmentReport.coverPage.interviewHeading')
            : t('assessmentReport.coverPage.monitorHeading')}
        </StyledTypographyHeading>
        {result?.reasonForEdit && typeof result?.reasonForEdit != 'undefined' && (
          <StyledTypographyEdited>{t('assessmentReport.labels.edited')}</StyledTypographyEdited>
        )}
        {renderDetail()}
      </Box>
      {renderBottom()}
    </RoundedContainer>
  );
}
