import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUpdatePatientReminderMutation } from '../../../app/services/site';
import theme from '../../../app/theme';
import { useToast } from '../../../app/toast';
import RoundedContainer from '../../../common/components/RoundedContainer';
import SubmitButton from '../../../common/components/SubmitButton';
import { AssessmentBuilder } from '../../../common/utils/assessmentType';
import checkNullData from '../../../common/utils/checkNullData';
import {
  ReminderCommunicationType,
  ReminderCommunicationTypes,
} from '../../../common/utils/communicationTypes';
import { populateLanguage } from '../../../common/utils/languages';
import {
  PatientReminderDetailResponse,
  UpdatePatientReminderRequest,
} from '../../../types/PatientReminderType';
import { ResponseError } from '../../../types/ResponseError';
import { setReminderGroupId } from './ReminderSlice';

interface ReminderFormProps {
  reminderData: PatientReminderDetailResponse;
  refetchReminders: () => void;
}

const StyledMenuItem = styled(MenuItem)(() => ({
  maxWidth: 440,
  '&.MuiMenuItem-root': {
    whiteSpace: 'unset',
    lineBreak: 'strict',
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

const CommunicationTypeOptions = {
  EMAIL_SUBJECT: 'emailSubject',
  EMAIL_MESSAGE: 'emailMessage',
  TEXT_MESSAGE: 'textMessage',
};

export default function ReminderForm({ reminderData, refetchReminders }: ReminderFormProps) {
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isRenderEmail, setIsRenderEmail] = useState<boolean>(
    reminderData?.communicationType?.toLowerCase().includes(ReminderCommunicationType.EMAIL) ||
      false
  );
  const [isRenderText, setIsRenderText] = useState<boolean>(
    reminderData?.communicationType?.toLowerCase().includes(ReminderCommunicationType.TEXT) || false
  );

  const [updatePatientReminder] = useUpdatePatientReminderMutation();

  const defaultFormValues: UpdatePatientReminderRequest = {
    id: reminderData?.id,
    days: reminderData?.days,
    times: reminderData?.times,
    communicationType: reminderData?.communicationType || ReminderCommunicationType.EMAIL,
    emailSubject: reminderData?.emailSubject,
    emailMessage: reminderData?.emailMessage,
    textMessage: reminderData?.textMessage,
  };

  const validationSchema = yup.object({
    days: yup
      .number()
      .min(0, t('clinicSite.manageSites.manageReminders.reminderForm.error.dayMin'))
      .typeError(t('clinicSite.manageSites.manageReminders.reminderForm.error.dayNumberOnly')),
    times: yup
      .number()
      .min(0, t('clinicSite.manageSites.manageReminders.reminderForm.error.timeMin'))
      .typeError(t('clinicSite.manageSites.manageReminders.reminderForm.error.timeNumberOnly')),
    communicationType: yup.string(),
    emailSubject: yup.string().when('communicationType', {
      is: (communicationType: string) =>
        communicationType.toLowerCase().includes(ReminderCommunicationType.EMAIL),
      then: yup
        .string()
        .required(t('clinicSite.manageSites.manageReminders.reminderForm.error.blankEmailSubject')),
    }),
    emailMessage: yup.string().when('communicationType', {
      is: (communicationType: string) =>
        communicationType.toLowerCase().includes(ReminderCommunicationType.EMAIL),
      then: yup
        .string()
        .required(t('clinicSite.manageSites.manageReminders.reminderForm.error.blankEmailMessage'))
        .matches(
          /\{interview_link\}/,
          t('clinicSite.manageSites.manageReminders.reminderForm.error.emailMissingInterviewLink')
        ),
    }),
    textMessage: yup.string().when('communicationType', {
      is: (communicationType: string) =>
        communicationType.toLowerCase().includes(ReminderCommunicationType.TEXT),
      then: yup
        .string()
        .required(t('clinicSite.manageSites.manageReminders.reminderForm.error.blankTextMessage'))
        .matches(
          /\{interview_link\}/,
          t('clinicSite.manageSites.manageReminders.reminderForm.error.textMissingInterviewLink')
        )
        .max(200, t('clinicSite.manageSites.manageReminders.reminderForm.error.textMessageMax')),
    }),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (reminderData) {
      if (reminderData.interviewTypeId || reminderData.trackerTypeId) {
        dispatch(
          setReminderGroupId({
            reminderGroupId: reminderData.interviewTypeId ?? reminderData.trackerTypeId!,
          })
        );
      }
      Object.values(CommunicationTypeOptions).forEach((field) => {
        setValue(field, checkNullData(reminderData[field as keyof UpdatePatientReminderRequest]));
      });
    }
  }, [dispatch, reminderData, setValue]);

  const onCommunicationChange = (e: any) => {
    const selectedValue = e?.target?.value;
    setValue('communicationType', selectedValue);
    const type = selectedValue.toLowerCase();
    setIsRenderEmail(type.includes(ReminderCommunicationType.EMAIL));
    setIsRenderText(type.includes(ReminderCommunicationType.TEXT));
  };

  const renderCommunicationTypes = () => {
    return ReminderCommunicationTypes.map((type) => (
      <StyledMenuItem key={type.value} value={type.value}>
        {type.name}
      </StyledMenuItem>
    ));
  };

  const onSubmit = async (data: UpdatePatientReminderRequest) => {
    try {
      let updateData = data;
      if (data.communicationType === ReminderCommunicationType.EMAIL) {
        delete updateData.textMessage;
      }
      if (data.communicationType === ReminderCommunicationType.TEXT) {
        delete updateData.emailMessage;
        delete updateData.emailSubject;
      }
      const res = await updatePatientReminder(updateData).unwrap();
      if (res) {
        toast.publish(t('clinicSite.manageSites.manageReminders.reminderForm.success'), 'success');
        setPageErrors([]);
        refetchReminders();
      }
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      <RoundedContainer sx={{ mt: 2, py: 2 }}>
        <Typography fontWeight={600}>
          {t(
            `clinicSite.manageSites.manageReminders.reminderForm.${
              reminderData?.interviewTypeId
                ? AssessmentBuilder.INTERVIEW
                : AssessmentBuilder.MONITOR
            }`
          )}
        </Typography>
        {true && <Typography>{reminderData.assessmentTypeName}</Typography>}
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            px: matches ? 4 : 10,
            display: 'flex',
            flexDirection: 'column',
            columnGap: 2,
          }}
        >
          <Box
            sx={{
              columnGap: 1,
              display: 'flex',
              justifyContent: 'space-between',
              width: matches ? '100%' : '50%',
            }}
          >
            <Typography fontWeight={600}>
              {t('clinicSite.manageSites.manageReminders.reminderForm.language')}
            </Typography>
            <Typography>{populateLanguage(reminderData.language)}</Typography>
          </Box>
          <Box
            sx={{
              columnGap: 1,
              display: 'flex',
              mt: 1,
              justifyContent: 'space-between',
              width: matches ? '100%' : '50%',
            }}
          >
            <Box>
              <Typography fontWeight={600}>
                {t('clinicSite.manageSites.manageReminders.reminderForm.interval')}
              </Typography>
            </Box>
            <Box>{reminderData.interval}</Box>
          </Box>
          <Box
            sx={{
              columnGap: 1,
              display: 'flex',
              justifyContent: 'space-between',
              width: matches ? '100%' : '50%',
            }}
          >
            <Box
              sx={{
                width: '60%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography fontWeight={600}>
                {t('clinicSite.manageSites.manageReminders.reminderForm.reminderSentEvery')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '40%',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Controller
                name="days"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.days}
                    helperText={errors.days?.message}
                    margin="normal"
                    type="number"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
              <Typography>
                &nbsp;{t('clinicSite.manageSites.manageReminders.reminderForm.days')}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              columnGap: 1,
              display: 'flex',
              justifyContent: 'space-between',
              width: matches ? '100%' : '50%',
            }}
          >
            <Box
              sx={{
                width: '60%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography fontWeight={600}>
                {t('clinicSite.manageSites.manageReminders.reminderForm.reminderSentUpTo')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '40%',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Controller
                name="times"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.times}
                    helperText={errors.times?.message}
                    margin="normal"
                    type="number"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
              <Typography>
                {t('clinicSite.manageSites.manageReminders.reminderForm.times')}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              columnGap: 1,
              display: 'flex',
              justifyContent: 'space-between',
              width: matches ? '100%' : '50%',
            }}
          >
            <Box
              sx={{
                width: '60%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography fontWeight={600}>
                {t('clinicSite.manageSites.manageReminders.reminderForm.communicationType')}
              </Typography>
            </Box>
            <Box
              sx={{
                width: '40%',
              }}
            >
              <Controller
                name="communicationType"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.communicationType}
                    helperText={errors.communicationType?.message}
                    margin="normal"
                    select
                    fullWidth
                    variant="outlined"
                    onChange={onCommunicationChange}
                  >
                    {renderCommunicationTypes()}
                  </TextField>
                )}
              />
            </Box>
          </Box>
        </Box>
      </RoundedContainer>
      <RoundedContainer
        sx={{
          marginTop: 2,
          py: 2,
        }}
      >
        <Box
          sx={{
            marginTop: 2,
            py: 2,
          }}
        >
          <Typography
            fontWeight={500}
            color={theme.xPalette.lightGrey}
            dangerouslySetInnerHTML={{
              __html: t('clinicSite.manageSites.manageReminders.reminderForm.notes'),
            }}
          />
          <Box>
            {isRenderEmail && (
              <>
                <Controller
                  name="emailSubject"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.emailSubject}
                      helperText={errors.emailSubject?.message}
                      label={t('clinicSite.manageSites.manageReminders.reminderForm.emailSubject')}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
                <Controller
                  name="emailMessage"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.emailMessage}
                      helperText={errors.emailMessage?.message}
                      label={t('clinicSite.manageSites.manageReminders.reminderForm.emailMessage')}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      multiline
                      minRows={7}
                    />
                  )}
                />
              </>
            )}
            {isRenderText && (
              <Controller
                name="textMessage"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.textMessage}
                    helperText={errors.textMessage?.message}
                    label={t('clinicSite.manageSites.manageReminders.reminderForm.textMessage')}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={7}
                  />
                )}
              />
            )}
          </Box>
        </Box>
        <SubmitButton
          content={t('clinicSite.manageSites.manageReminders.reminderForm.save')}
          matches={matches}
          pageErrors={pageErrors}
        />
      </RoundedContainer>
    </Box>
  );
}
