import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/system';
import { useGetAuditLogSitesQuery } from '../../app/services/site';
import { AuditLogClinicSiteItems } from '../../types/ClinicSiteType';

interface SiteSearchProps {
  clinicAccountId?: string;
  selectedClinicSiteId?: string; // default id
  onSelect?: (user: AuditLogClinicSiteItems | null) => void;
  sx?: SxProps<Theme>;
  pageSize?: number;
  label?: string;
  disabled?: boolean;
}

export default function ClinicSiteSearch({
  clinicAccountId,
  selectedClinicSiteId,
  onSelect,
  sx,
  label,
  disabled,
}: SiteSearchProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [clinicSites, setClinicSites] = useState<AuditLogClinicSiteItems[]>([]);
  const [selectedClinicSite, setSelectedClinicSite] = useState<AuditLogClinicSiteItems | null>(
    null
  );

  const { data } = useGetAuditLogSitesQuery(clinicAccountId ? { clinicAccountId } : skipToken);

  useEffect(() => {
    const selectedClinicSiteItem = clinicSites.find(
      (clinicSite) => clinicSite.id.toString() === selectedClinicSiteId?.toString()
    );
    setSelectedClinicSite(selectedClinicSiteItem || null);
    setInputValue(selectedClinicSiteItem?.name || '');
  }, [clinicSites, selectedClinicSiteId]);

  useEffect(() => {
    if (!clinicAccountId) {
      setClinicSites([]);
      return;
    }
    setClinicSites(data?.clinicSites?.length ? data?.clinicSites : []);
  }, [clinicAccountId, data?.clinicSites]);

  const searchSite = (selectedSite: AuditLogClinicSiteItems | null) => {
    if (onSelect) {
      onSelect(selectedSite);
      return;
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });

  return (
    <Autocomplete
      id="siteSearch"
      sx={
        sx || {
          '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
            pr: 1,
          },
          width: 1,
        }
      }
      inputValue={inputValue}
      value={selectedClinicSite}
      getOptionLabel={(option) =>
        typeof option !== 'undefined' ? (typeof option === 'string' ? option : option.name!) : ''
      }
      options={clinicSites}
      filterOptions={filterOptions}
      onChange={(event, newInputValue) => {
        searchSite(newInputValue as AuditLogClinicSiteItems);
      }}
      onInputChange={(event, newInputValue) => {
        if (event?.type === 'change') {
          setInputValue(newInputValue);
        }
      }}
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} label={label || t('patient.patientInfo.site')} />
      )}
      renderOption={(props, site) => {
        return (
          <li {...props} key={site.id}>
            <Typography
              sx={{
                fontStyle: site.id.toString() === '-1' ? 'italic' : 'normal',
              }}
            >
              {site.name}
            </Typography>
          </li>
        );
      }}
    />
  );
}
