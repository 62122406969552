import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import { StyledRadioCheckIcon } from '../../../interview/questions/SdsScaleQuestion';
import useCustomQuestionInfo from './useCustomQuestionInfo';

const borderStyle = `1px solid ${theme.xPalette.black}`;

export default function TableAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const { questionDisplay, answerValue, questionNameDisplay } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  const renderTableAnswer = () => {
    const columns = questionDisplay.columns;
    const rows = questionDisplay.rows;

    return (
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col: string, colIdx: number) => (
              <TableCell
                key={colIdx}
                sx={{
                  border: borderStyle,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  textAlign={'center'}
                  fontSize={'16px'}
                  dangerouslySetInnerHTML={{
                    __html: col,
                  }}
                ></Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: string[], rowIdx: number) => (
            <TableRow key={rowIdx}>
              {row.map((col: string, colIdx: number) => {
                const selected =
                  colIdx === 0 && answerValue === col ? (
                    <StyledRadioCheckIcon>{col}</StyledRadioCheckIcon>
                  ) : (
                    <Typography
                      fontWeight={500}
                      pl={2}
                      dangerouslySetInnerHTML={{
                        __html: col,
                      }}
                    ></Typography>
                  );

                return (
                  <TableCell sx={{ border: borderStyle }} key={colIdx}>
                    {selected}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Box
      key={questionAnswer.questionId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        columnGap: 1,
        width: '100%',
        my: 2,
        pageBreakInside: 'avoid',
      }}
    >
      <Box sx={{ my: 2 }}>
        <Typography variant="body1" component={'span'} fontWeight={500}>
          {questionNameDisplay}
        </Typography>
      </Box>
      <Box>{renderTableAnswer()}</Box>
    </Box>
  );
}
