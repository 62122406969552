import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LineItemScores } from '../../../../common/utils/reportTypes';

interface ScoringSectionModuleType {
  scoringLineItems: any[];
  scoringSectionTitle: string;
}

const CheckBoxIcon = (lineItemScore: string): JSX.Element => {
  let backgroundColor = '#fff';
  let opacity = '1';

  // This for you to understand the rules
  if (lineItemScore === LineItemScores.NotMet) {
    backgroundColor = '#fff';
  }

  if (
    lineItemScore === LineItemScores.Met ||
    lineItemScore === LineItemScores.NeedClinicianReview
  ) {
    backgroundColor = '#000';
  }

  if (lineItemScore === LineItemScores.Na) {
    backgroundColor = '#000';
    opacity = '0.3';
  }

  return (
    <Box
      sx={{
        border: '1px solid #696969',
        height: '16px',
        width: '16px',
        display: 'inline-block',
        position: 'relative',
        top: '2px',
        marginRight: '0.5rem',
        borderRadius: '4px',
        backgroundColor,
        opacity,
      }}
    ></Box>
  );
};

export default function ScoringSection({
  scoringLineItems,
  scoringSectionTitle,
}: ScoringSectionModuleType) {
  const handleTitle = (title: string, summaryTitle?: string, timeframe?: string): string => {
    const lineItemTitle = summaryTitle || title;
    return lineItemTitle && timeframe ? `${lineItemTitle}(${timeframe})` : lineItemTitle;
  };

  return (
    <Box sx={{ border: '1px solid black', py: 2, px: 6, mx: '20%', my: 3, pageBreakInside: "avoid" }}>
      <Typography variant="h2" fontWeight={600} textAlign={'center'}>
        {scoringSectionTitle}
      </Typography>
      {scoringLineItems?.map((lineItem: any) => (
        <Box key={lineItem?.id} sx={{ display: 'flex', columnGap: 2, my: 3, width: '100%' }}>
          <Typography
            sx={{ width: '90%' }}
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: handleTitle(lineItem.title, lineItem.summaryTitle, lineItem.timeframe),
            }}
          ></Typography>
          <Typography sx={{ width: '5%' }} component={'span'}>
            {CheckBoxIcon(lineItem?.lineItemScore)}
          </Typography>
        </Box>
      )) || <></>}
    </Box>
  );
}
