import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import { useGetClinicAccountsQuery } from '../../app/services/account';
import { useLazyGetLatestInterviewResultsByPatientQuery } from '../../app/services/interview';
import { useLazyGetLatestMonitorResultsByPatientQuery } from '../../app/services/monitor';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import TransferAssessmentForm from './TransferAssessmentForm';

export default function TransferAssessmentPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();

  const { data: accountData, isLoading: isLoadingAccount } = useGetClinicAccountsQuery();
  const [triggerGetInterviewResults, { data: interviewData }] =
    useLazyGetLatestInterviewResultsByPatientQuery();

  const [triggerGetMonitorResults, { data: monitorData }] =
    useLazyGetLatestMonitorResultsByPatientQuery();

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('transferAssessment.title'))}</title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader sx={{ mt: 1, mb: 2 }} headerText={t('transferAssessment.title')} />
      </Container>

      {isLoadingAccount ? (
        <Loading />
      ) : (
        <TransferAssessmentForm
          accountData={accountData?.clinicAccounts || []}
          interviewData={interviewData?.interviewResults || []}
          monitorData={monitorData?.monitorResults || []}
          triggerGetInterviewResults={triggerGetInterviewResults}
          triggerGetMonitorResults={triggerGetMonitorResults}
        />
      )}
    </DashboardLayout>
  );
}
