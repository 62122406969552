import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import UsageReportIcon from './icons/UsageReportIcon';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface AuditLogResultInfoCardProps {
  fromDate: string;
  toDate: string;
  disabled?: boolean;
  onDownLoadReport: () => Promise<void>;
};

export default function AuditLogResultInfoCard({ fromDate, toDate, disabled, onDownLoadReport }: AuditLogResultInfoCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <UsageReportIcon />
        <Box sx={{
          ml: 1,
        }}>
          <>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {t('auditLog.title')}{' '}
            </Typography>
            <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
              <Typography component="span" variant="body1" sx={(theme) => ({ color: theme.xPalette.shadeGrey })}>{t(
                'auditLog.infoCard.time'
              )}</Typography>
              {' '}<Typography component="span" variant="body1" sx={(theme) => ({ color: theme.xPalette.black })}>{`${fromDate} - ${toDate}`}</Typography>
            </Box>
          </>
        </Box>
      </Box>
      <Button
        type="button"
        fullWidth
        variant="contained"
        disabled={disabled}
        sx={{
          width: matches ? '100%' : '30%',
          my: 2,
          py: 2,
          float: 'right',
        }}
        onClick={async () => {
          await onDownLoadReport();
        }}
      >
        {t('auditLog.infoCard.downloadReport')}
      </Button>
    </Box>
  );
}
