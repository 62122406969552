import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SxProps } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useDeleteInterviewOrMonitorMutation } from '../../app/services/interview';
import { useGetPatientQuery } from '../../app/services/patient';
import {
  useGetAssessmentScheduleQuery,
  useGetLastestAssessmentsQuery,
  useStopAssessmentScheduleMutation,
} from '../../app/services/schedule';
import { useToast } from '../../app/toast';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import DataTable from '../../common/components/DataTable';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import DeleteReportIcon from '../../common/components/icons/DeleteReportIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  PageStatus,
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
  setPageStatus,
} from '../../common/slices/globalSlice';
import { AssessmentStatus } from '../../common/utils/assessmentStatus';
import { AssessmentType } from '../../common/utils/assessmentType';
import { statusBoxStyle } from '../../common/utils/commonStyles';
import { DefaultPageSize } from '../../common/utils/commonUtils';
import { formatServerDate } from '../../common/utils/dateTimeUtils';
import { BreadcrumbType, addBreadcrumb } from '../../common/utils/editBreadcrums';
import { PageSource } from '../../common/utils/pageInfo';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { AssessmentItem } from '../../types/PatientRequest';
import { ResponseError } from '../../types/ResponseError';
import ErrorPage from '../error/ErrorPage';
import ContextMenu from '../patient/ContextMenu';
import useDownloadInterviewOrMonitorReport from '../patient/hooks/useDownloadInterviewOrMonitorReport';
import ScheduleForm from './ScheduleForm';
import { DownloadReportRequest } from '../../types/InterviewReportRequest';

const standardizeStatus = (status: string) =>
  status ? status[0].toUpperCase() + status.substring(1) : status;

interface PopupContent {
  title: string;
  description: string;
  btnOk: string;
  btnClose: string;
  toastMessage: string;
  hidePatient?: boolean;
}
export default function SchedulePage({ breadcrumbs }: PageProps) {
  const {
    patientId,
    assessmentType: inputAssessmentType,
    assessmentTypeId: inputAssessmentTypeId,
  } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const shouldRenderDesktopData = useMediaQuery(theme.breakpoints.up('md'));
  const toast = useToast();
  const dispatch = useAppDispatch();
  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);
  const [testIdAction, setTestIdAction] = useState<number>(0);
  const [deleteReport] = useDeleteInterviewOrMonitorMutation();
  const [stopAssessmentSchedule] = useStopAssessmentScheduleMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [{ page, pageSize }, setPagingInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: DefaultPageSize,
  });
  const [callDeleteReport, setCallDeleteReport] = useState<boolean>(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const assessmentTypeId = parseInt(inputAssessmentTypeId || '0');
  const assessmentType = parseInt(inputAssessmentType || '0');
  const isInterview = assessmentType === AssessmentType.Interview;
  const fromPatientList = searchParams.get('src') === PageSource.PatientList;
  const newBreadcrumb: BreadcrumbType = fromPatientList
    ? {
        text: t('patient.patientList.title'),
        link: `/dashboard/patients`,
      }
    : {
        text: t('patient.patientDashboard.title'),
        link: `/dashboard/patient/${patientId}`,
      };

  const { startDownloadReport } = useDownloadInterviewOrMonitorReport(t);

  const {
    data: assessmentScheduleData,
    isLoading: assessmentScheduleLoading,
    error: assessmentScheduleLoadError,
  } = useGetAssessmentScheduleQuery({
    patientId,
    assessmentType: isInterview ? AssessmentType.Interview : AssessmentType.Monitor,
    assessmentTypeId,
  });

  let assessmentScheduleErrors: string[] = [];
  if (assessmentScheduleLoadError) {
    const {
      data: { error },
    } = assessmentScheduleLoadError as ResponseError;
    assessmentScheduleErrors = error;
  }

  const { data: patientInfo, error: patientLoadError } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const {
    data: lastestAssessments,
    error: lastestAssessmentsLoadError,
    isLoading: lastestAssessmentsLoading,
    refetch: refetchLatestAssessments,
  } = useGetLastestAssessmentsQuery(
    patientId ? { patientId, assessmentType, assessmentTypeId, page, pageSize } : skipToken
  );

  const isFetchingAssessments = assessmentScheduleLoading || lastestAssessmentsLoading;

  const totalPages = Math.ceil((lastestAssessments?.totalItems || 0) / pageSize);

  const viewReport = async (assessmentId: number, type: AssessmentType) => {
    dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
    handleClose();

    let data: DownloadReportRequest;
    if (type === AssessmentType.Interview) {
      data = {
        interviewResultId: assessmentId,
        latestVersion: true,
        type: AssessmentType.Interview,
      };
    } else {
      data = {
        monitorResultId: assessmentId,
        type: AssessmentType.Monitor,
      };
    }
    await startDownloadReport(data);
  };

  const handlePageChange = (_event: any, value: any) => {
    const newPage = parseInt(value);
    setPagingInfo({ page: newPage, pageSize });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderContextMenu = (item: AssessmentItem) => {
    return (
      <ContextMenu
        id={`assessment-id-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={[
          {
            isDisplayed:
              item &&
              item.status !== AssessmentStatus.NotStarted &&
              item.status !== AssessmentStatus.PendingScoring,
            label: t('patient.patientList.menu.deleteReport'),
            icon: <DeleteReportIcon />,
            onClick: () => showDeleteReportPopup(item.id),
          },
        ]}
      />
    );
  };

  const onSuccessCallback = async () => {
    navigate(fromPatientList ? `/dashboard/patients` : `/dashboard/patient/${patientId}`);
  };

  const deleteInterviewContentPopup = {
    title: t('patient.deleteInterview.title'),
    description: t('patient.deleteInterview.description'),
    btnOk: t('patient.deleteInterview.btnOk'),
    btnClose: t('patient.deleteInterview.btnClose'),
    toastMessage: t('patient.deleteInterview.toastMessage'),
    hidePatient: true,
  } as PopupContent;

  const deleteMonitorContentPopup = {
    title: t('patient.deleteMonitor.title'),
    description: t('patient.deleteMonitor.description'),
    btnOk: t('patient.deleteMonitor.btnOk'),
    btnClose: t('patient.deleteMonitor.btnClose'),
    toastMessage: t('patient.deleteMonitor.toastMessage'),
    hidePatient: true,
  } as PopupContent;

  const showDeleteReportPopup = async (testId: number) => {
    dispatch(
      setContentPopup({
        content:
          assessmentType === AssessmentType.Interview
            ? deleteInterviewContentPopup
            : deleteMonitorContentPopup,
      })
    );
    dispatch(setIsOpenPopup({ value: true }));
    setTestIdAction(testId);
    handleClose();
    setCallDeleteReport(true);
  };
  const onStopSchedule = async () => {
    try {
      if (assessmentScheduleData?.assessmentSchedule?.id) {
        await stopAssessmentSchedule({
          id: assessmentScheduleData?.assessmentSchedule?.id,
          assessmentType,
        }).unwrap();
      }
      if (onSuccessCallback) {
        onSuccessCallback();
      }
      setCallDeleteReport(false);
      toast.publish(t('patient.stopSchedule.toastMessage'), 'success');
    } catch {
      toast.publish(t('patient.stopSchedule.toastMessageFail'), 'error');
    }
  };

  const onDeleteReport = async (successCallback?: () => void) => {
    try {
      if (patientId! && testIdAction) {
        if (assessmentType === AssessmentType.Interview) {
          await deleteReport({
            patientId: patientId!,
            interviewResultId: testIdAction,
            type: assessmentType,
          }).unwrap();
        }
        if (assessmentType === AssessmentType.Monitor) {
          await deleteReport({
            patientId: patientId!,
            monitorResultId: testIdAction,
            type: assessmentType,
          }).unwrap();
        }
      }
      if (successCallback) {
        successCallback();
      }
      setCallDeleteReport(false);
      toast.publish(
        t(
          assessmentType === AssessmentType.Interview
            ? 'patient.deleteInterview.toastMessage'
            : 'patient.deleteMonitor.toastMessage'
        ),
        'success'
      );
      refetchLatestAssessments();
    } catch (e) {
      toast.publish(
        t(
          assessmentType === AssessmentType.Interview
            ? 'patient.deleteInterview.toastMessageFail'
            : 'patient.deleteMonitor.toastMessageFail'
        ),
        'error'
      );
    }
  };

  const renderStatusField = (status: string, isDesktop = false) => {
    return (
      <Box
        sx={{
          ...statusBoxStyle(matches, status),
          height: '32px',
          ...(isDesktop ? { margin: 'auto' } : {}),
        }}
      >
        {standardizeStatus(status)}
      </Box>
    );
  };

  const getScore = (score?: number) => {
    return score ? score.toLocaleString() : '';
  };

  const renderPhoneData = (data: AssessmentItem[]) => {
    return (
      <DataList
        data={data.map((item) => {
          const assessments = [
            [t('schedule.history.dateStarted'), formatServerDate(item.createdAt)],
            [t('schedule.history.dateCompleted'), formatServerDate(item.completedAt)],
          ];
          if (assessmentType === AssessmentType.Monitor) {
            assessments.push([t('schedule.history.totalScore'), getScore(item.totalScore)]);
            assessments.push([t('schedule.history.stdScore'), getScore(item.stdScore)]);
          }
          return {
            leftHeader:
              item.status === AssessmentStatus.Completed ? (
                <Link href="#" onClick={() => viewReport(item.id, assessmentType)}>
                  {t('interviewReport.history.viewReport')}
                </Link>
              ) : (
                <Box></Box>
              ),
            rightHeader: (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    mr: 1,
                  }}
                >
                  {renderStatusField(item.status)}
                </Box>
                {item.status === AssessmentStatus.Completed ||
                item.status === AssessmentStatus.InProgress ? (
                  <Box>{renderContextMenu(item)}</Box>
                ) : (
                  <></>
                )}
              </Box>
            ),
            items: assessments,
          };
        })}
      />
    );
  };

  const renderDesktopData = (data: AssessmentItem[]) => {
    const assessmentHeaders: { text: string; sx?: SxProps; sortKey?: string }[] = [
      { text: t('schedule.history.dateStarted') },
      { text: t('schedule.history.dateCompleted') },
      { text: t('schedule.history.status'), sx: { textAlign: 'center' } },
    ];
    if (assessmentType === AssessmentType.Monitor) {
      assessmentHeaders.push({
        text: t('schedule.history.totalScore'),
        sx: { textAlign: 'center' },
      });
      assessmentHeaders.push({ text: t('schedule.history.stdScore'), sx: { textAlign: 'center' } });
    }
    assessmentHeaders.push({ text: t('schedule.history.action') });
    return (
      <DataTable
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '8px 16px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '14px 16px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
        }}
        headers={assessmentHeaders}
        items={data.map((item) => {
          const assessments: { component: JSX.Element; sx?: SxProps }[] = [
            { component: <Typography>{formatServerDate(item.createdAt)}</Typography> },
            { component: <Typography>{formatServerDate(item.completedAt)}</Typography> },
            { component: <>{renderStatusField(item.status, true)}</> },
          ];
          if (assessmentType === AssessmentType.Monitor) {
            assessments.push({
              component: (
                <Typography sx={{ textAlign: 'center' }}>{getScore(item.totalScore)}</Typography>
              ),
            });
            assessments.push({
              component: (
                <Typography sx={{ textAlign: 'center' }}>{getScore(item.stdScore)}</Typography>
              ),
            });
          }
          assessments.push({
            component: (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box>
                  {item.status === AssessmentStatus.Completed ? (
                    <Link
                      href="#"
                      onClick={() => viewReport(item.id, assessmentType)}
                      title={t('schedule.history.viewReport')}
                      sx={{
                        display: 'flex',
                        ml: 1,
                        width: '30px',
                      }}
                      color={theme.palette.text.primary}
                    >
                      <EyeIcon />
                    </Link>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '38px',
                      }}
                    ></Box>
                  )}
                </Box>
                {item.status === AssessmentStatus.Completed ||
                item.status === AssessmentStatus.InProgress ? (
                  <Box>{renderContextMenu(item)}</Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '24px',
                    }}
                  ></Box>
                )}
              </Box>
            ),
          });
          return assessments;
        })}
      />
    );
  };

  if (patientLoadError) {
    return <ErrorPage statusCode={(patientLoadError as FetchBaseQueryError).status} />;
  }

  if (lastestAssessmentsLoadError) {
    return <ErrorPage statusCode={(lastestAssessmentsLoadError as FetchBaseQueryError).status} />;
  }

  // TODO: udpate style
  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>{getPageTitle(t('schedule.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            px: matches ? 2 : 3,
          }}
        >
          <PageHeader headerText={t('schedule.title')} backUrl={newBreadcrumb.link} />
        </Box>

        <RoundedContainer sx={{ py: 2 }}>
          <PatientInfoCard data={patientInfo!} />
        </RoundedContainer>

        {isFetchingAssessments ? (
          <Loading />
        ) : (
          <>
            <RoundedContainer sx={{ mt: 2, py: 2 }} ref={tableRef}>
              {assessmentScheduleErrors && assessmentScheduleErrors.length ? (
                <Typography>{assessmentScheduleErrors[0]}</Typography>
              ) : (
                <></>
              )}
              {assessmentScheduleData && (
                <>
                  <Typography fontWeight={600}>
                    {t(`schedule.${isInterview ? 'interview' : 'monitor'}`)}
                  </Typography>
                  {assessmentScheduleData.name && (
                    <Typography>{assessmentScheduleData.name}</Typography>
                  )}
                  <Divider sx={{ my: 2 }} />
                  {assessmentScheduleData.assessmentSchedule ? (
                    <ScheduleForm
                      {...{ assessmentType, assessmentScheduleData, onSuccessCallback }}
                    />
                  ) : (
                    <Typography>{t(`schedule.form.noPatientSchedule`)}</Typography>
                  )}
                </>
              )}
            </RoundedContainer>

            {lastestAssessments?.items && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2,
                    px: matches ? 2 : 3,
                  }}
                >
                  <Typography variant="h1">{t(`schedule.history.title`)}</Typography>
                </Box>
                {shouldRenderDesktopData
                  ? renderDesktopData(lastestAssessments.items)
                  : renderPhoneData(lastestAssessments.items)}
              </>
            )}

            {totalPages > 1 && (
              <RoundedContainer sx={{ mt: 2, py: 1, display: 'flex', justifyContent: 'center' }}>
                <CustomPagination
                  changePage={handlePageChange}
                  currentPage={page}
                  pages={totalPages}
                  pageRef={tableRef}
                />
              </RoundedContainer>
            )}
          </>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={callDeleteReport ? onDeleteReport : onStopSchedule}
        />
      </Container>
    </DashboardLayout>
  );
}
