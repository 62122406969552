export const convertLbsToKg = (pounds: any): number => {
  const convertValue = 0.45359237;
  const poundsVal = Number(pounds);

  if (isNaN(poundsVal)) {
    return 0;
  }

  // Only show one decimal place
  return Number((poundsVal * convertValue).toFixed(1));
};

export const convertKgToLbs = (kilograms: any): number => {
  const convertValue = 0.45359237;
  const kgVal = Number(kilograms);

  if (isNaN(kgVal)) {
    return 0;
  }

  // Return whole numbers only
  return Math.round(kgVal / convertValue);
};

export const convertCmToFeetInches = (centimeters: any): { feet: number; inches: number } => {
  var centimetersNum = parseInt(centimeters);

  const returnValue = {
    feet: 0,
    inches: 0,
  };

  if (!isNaN(centimetersNum)) {
    let inches = Number((centimetersNum * 0.393700787).toFixed(0));
    const feet = Math.floor(inches / 12);
    inches %= 12;

    returnValue.feet = feet;
    returnValue.inches = inches;
  }

  return returnValue;
};
