import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as DeletePatientIconSvg } from '../../../assets/icons/delete-patient.svg';

export default function DeletePatientIcon() {
  const theme = useTheme();
  return (
    <SvgIcon
      component={DeletePatientIconSvg}
      sx={{ '& path': { fill: 'white', stroke: theme.xPalette.shadeGrey } }}
    />
  );
}
