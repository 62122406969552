import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import {
  StyledRadioCheckIcon,
  StyledRadioIcon,
} from '../../../interview/questions/SdsScaleQuestion';
import ReportNotes from '../ReportNotes';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export const StyledFormLabel = styled(FormControlLabel)(() => ({
  margin: 0,
  '&.MuiFormControlLabel-root .MuiFormControlLabel-label ': {
    fontSize: '12px',
  },
}));

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

const StyleLayoutRadio = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px 8px',
  width: '100%',
  justifyContent: 'space-around',
  marginBottom: '8px',
  alignItems: 'center',
}));

const possibleNumberAnswers = ['0', '1', '2', '3', '4'];

export default function StsScaleAnswer({ questionAnswer, moduleType, notes }: QuestionAnswerProps) {
  const { questionDisplay } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
  });
  const { t } = useTranslation();
  const stsMeasurement: string[] = Object.values(
    t('interview.form.sts.stsScale', { returnObjects: true })
  );

  const renderAnswers = () => {
    return possibleNumberAnswers.map((item) => {
      return (
        <StyleLayoutRadio>
          <Typography fontSize="14px!important" fontWeight={500}>
            {stsMeasurement[parseInt(item)]}
          </Typography>
          {questionAnswer.questionAnswer === item ? (
            <StyledRadioCheckIcon>{item}</StyledRadioCheckIcon>
          ) : (
            <StyledRadioIcon>{item}</StyledRadioIcon>
          )}
        </StyleLayoutRadio>
      );
    });
  };

  return (
    <Box>
      <Box
        key={questionAnswer.questionId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          my: 2,
          width: '100%',
        }}
      >
        <Box sx={{ width: '15%', display: 'flex', columnGap: 1 }}>
          <Typography fontWeight={500} component={'span'}>
            {questionAnswer.title || ''}
          </Typography>
          <Typography fontWeight={500} component={'span'}>
            {questionAnswer.subTitle || ''}
          </Typography>
        </Box>
        <Box sx={{ width: '75%' }}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: questionDisplay,
            }}
          ></Typography>
          {notes?.length && (
            <ReportNotes reportNotes={notes} questionId={questionAnswer.questionId} />
          )}
        </Box>
        <Typography
          sx={{ width: '10%', textAlign: 'right', fontWeight: 500 }}
          component={'span'}
        ></Typography>
        <Typography sx={{ width: '5%', fontWeight: 500 }} component={'span'}></Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', my: 2 }}>{renderAnswers()}</Box>
    </Box>
  );
}
