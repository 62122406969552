import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as DeactivatePatientIconSvg } from '../../../assets/icons/deactivate-patient.svg';

export default function DeactivatePatientIcon() {
  const theme = useTheme();
  return (
    <SvgIcon
      component={DeactivatePatientIconSvg}
      sx={{ '& path': { fill: 'white', stroke: theme.xPalette.shadeGrey } }}
    />
  );
}
