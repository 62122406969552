import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tab from '@mui/material/Tab';
import { tableCellClasses } from '@mui/material/TableCell';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../../app/hooks';
import {
  useGetPatientReminderListQuery,
  useUpdatePatientReminderStatusMutation,
} from '../../../app/services/site';
import theme from '../../../app/theme';
import { useToast } from '../../../app/toast';
import CustomPagination from '../../../common/components/CustomPagination';
import DataList from '../../../common/components/DataList';
import DataTable from '../../../common/components/DataTable';
import Loading from '../../../common/components/Loading';
import PageHeader from '../../../common/components/PageHeader';
import PopupModal from '../../../common/components/PopupModal';
import RoundedContainer from '../../../common/components/RoundedContainer';
import DeactivatePatientIcon from '../../../common/components/icons/DeactivatePatientIcon';
import EditSiteIcon from '../../../common/components/icons/EditInterviewIcon';
import ReactivateSiteIcon from '../../../common/components/icons/ReactivateSiteIcon';
import DashboardLayout from '../../../common/layouts/Dashboard';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../../common/slices/globalSlice';
import { AssessmentBuilder } from '../../../common/utils/assessmentType';
import { CallActionType } from '../../../common/utils/callActionType';
import { Status } from '../../../common/utils/commonUtils';
import { ReminderCommunication } from '../../../common/utils/communicationTypes';
import { getPageTitle } from '../../../common/utils/pageUtils';
import { ReminderType } from '../../../common/utils/reminderListUtils';
import { SortDirs, SortFields } from '../../../common/utils/sortInfo';
import { toTitleCase } from '../../../common/utils/stringUtils';
import { PageProps } from '../../../types/PageProps';
import { PatientReminderItem, ReminderGroup } from '../../../types/PatientReminderType';
import { PopupContent } from '../../../types/PopupType';
import ErrorPage from '../../error/ErrorPage';
import ContextMenu from '../../patient/ContextMenu';
import PatientSortMenu from '../../patient/PatientSortMenu';
import { selectReminderGroupId, setReminderGroupId } from './ReminderSlice';

const StyledAddWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 0px',
  width: '100%',
  padding: '0px 8px',
  columnGap: '8px',

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    width: 'auto',
    flexBasis: '40%',
    maxWidth: '380px',
  },

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',
  },
}));

const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.xPalette.white,
  flexBasis: '100%',
  minWidth: '200px',
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'unset',
    width: '74%',
    minWidth: 'unset',
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledTab = styled(Tab)(() => ({
  width: '50%',
}));

const defaultPage = 1;

export default function ReminderListPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);
  const reminderGroupId = useAppSelector(selectReminderGroupId);
  const siteId: number | undefined = params.siteId ? parseInt(params.siteId) : undefined;
  const expandReminderRef = useRef<HTMLDivElement>(null);
  const searchTextboxRef = useRef<HTMLInputElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const [pageSize] = useState<number>(10);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [reminderIdAction, setReminderIdAction] = useState<number>(1);
  const [callAction, setCallAction] = useState<string>('');
  const [navBackUrl, setNavBackUrl] = useState<string>(
    `/dashboard/sites/${siteId}/patient-reminders`
  );
  const [expandState, setExpandState] = useState<number[]>([]);
  const [displayReminderData, setDisplayReminderData] = useState<ReminderGroup[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
    search: searchParams.get('search') || '',
    sortField: searchParams.get('sort') || SortFields.Name,
    sortDir: searchParams.get('dir') || SortDirs.Asc,
    type: searchParams.get('type') || AssessmentBuilder.INTERVIEW,
  };

  const currentPage = searchOptions.page;

  const {
    data: reminderData,
    isLoading,
    refetch: refetchReminderList,
    error,
  } = useGetPatientReminderListQuery({
    siteId: siteId,
    search: searchOptions.search,
    page: currentPage,
    pageSize: pageSize,
    sort: `${searchOptions.sortField} ${searchOptions.sortDir}`,
    reminderAssessmentType: searchOptions.type,
  });

  const deactivateReminderContentPopup = {
    title: t('clinicSite.manageSites.manageReminders.deactivateReminder.title'),
    description: t('clinicSite.manageSites.manageReminders.deactivateReminder.description'),
    btnOk: t('clinicSite.manageSites.manageReminders.deactivateReminder.btnOk'),
    btnClose: t('clinicSite.manageSites.manageReminders.deactivateReminder.btnClose'),
    toastMessage: t('clinicSite.manageSites.manageReminders.deactivateReminder.toastMessage'),
  } as PopupContent;

  const reactivateReminderContentPopup = {
    title: t('clinicSite.manageSites.manageReminders.reactivateReminder.title'),
    description: t('clinicSite.manageSites.manageReminders.reactivateReminder.description'),
    btnOk: t('clinicSite.manageSites.manageReminders.reactivateReminder.btnOk'),
    btnClose: t('clinicSite.manageSites.manageReminders.reactivateReminder.btnClose'),
    toastMessage: t('clinicSite.manageSites.manageReminders.reactivateReminder.toastMessage'),
  } as PopupContent;

  useEffect(() => {
    if (searchParams && searchParams.get('type') === AssessmentBuilder.MONITOR) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [searchParams]);

  const [activateOrDeactivateReminder] = useUpdatePatientReminderStatusMutation();
  useEffect(() => {
    if (reminderData) {
      setDisplayReminderData(reminderData.items);
      setTotalPages(Math.ceil(reminderData.totalItems / pageSize));
    }
  }, [pageSize, reminderData]);

  useEffect(() => {
    if (reminderGroupId) {
      setExpandState((prevState) => [...prevState, reminderGroupId]);
    }
  }, [reminderGroupId]);

  useLayoutEffect(() => {
    if (displayReminderData && expandReminderRef.current && reminderGroupId) {
      expandReminderRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      dispatch(setReminderGroupId({ reminderGroupId: 0 }));
    }
  }, [dispatch, displayReminderData, reminderGroupId]);

  const showDeactivateReminderPopup = async (reminderId: number) => {
    dispatch(setContentPopup({ content: deactivateReminderContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setReminderIdAction(reminderId);
    handleClose();
    setCallAction(CallActionType.Deactivate);
  };

  const showReactivateReminderPopup = async (reminderId: number) => {
    dispatch(setContentPopup({ content: reactivateReminderContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setReminderIdAction(reminderId);
    handleClose();
    setCallAction(CallActionType.Reactivate);
  };

  const handleActivateReminder = async (successCallback?: () => void) => {
    try {
      await activateOrDeactivateReminder({
        id: reminderIdAction,
        status: Status.ACTIVE,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      refetchReminderList();
      toast.publish(
        t('clinicSite.manageSites.manageReminders.reactivateReminder.success'),
        'success'
      );
    } catch (e) {
      toast.publish(t('clinicSite.manageSites.manageReminders.reactivateReminder.fail'), 'error');
    }
  };

  const handleDeactivateReminder = async (successCallback?: () => void) => {
    try {
      await activateOrDeactivateReminder({
        id: reminderIdAction,
        status: Status.DEACTIVATED,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      refetchReminderList();
      toast.publish(
        t('clinicSite.manageSites.manageReminders.deactivateReminder.success'),
        'success'
      );
    } catch (e) {
      toast.publish(t('clinicSite.manageSites.manageReminders.deactivateReminder.fail'), 'error');
    }
  };

  const setNavBack = (updatedData: any) => {
    const params = {
      ...searchOptions,
      ...updatedData,
    };
    const url = generatePath(
      `/dashboard/sites/${siteId}/patient-reminders` +
        '?search=:search' +
        '&type=:type' +
        '&page=:page' +
        '&sort=:sortField' +
        '&dir=:sortDir',
      params
    );
    if (params?.status === AssessmentBuilder.INTERVIEW) {
      setTabIndex(0);
    }
    if (params?.status === AssessmentBuilder.MONITOR) {
      setTabIndex(1);
    }
    setNavBackUrl(url);
    return url;
  };

  const updateSearchUrl = (updatedData: any) => {
    const url = setNavBack(updatedData);
    navigate(url, { replace: true });
    setExpandState([]);
  };

  const handleTableSorting = (sortField: string) => {
    if (sortField === searchOptions.sortField) {
      const newDir = searchOptions.sortDir === 'asc' ? 'desc' : 'asc';
      updateSearchUrl({ sortDir: newDir });
    } else {
      updateSearchUrl({ sortField, sortDir: sortField === 'status' ? 'desc' : 'asc' });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSorting = (sortField: string, sortDir: string) => {
    updateSearchUrl({
      page: defaultPage,
      tabIndex,
      search: searchOptions.search,
      sortField,
      sortDir,
    });
  };

  const handlePageChange = (value: number) => {
    updateSearchUrl({
      page: value,
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
    if (searchTextboxRef?.current) {
      searchTextboxRef.current.value = '';
    }
    updateSearchUrl({
      search: '',
      page: String(1),
      type: ReminderType[newIndex] || 'interview',
      sort: searchOptions.sortField,
      dir: searchOptions.sortDir,
    });
  };

  const handleSearch = () => {
    const input = searchTextboxRef.current?.value;
    updateSearchUrl({
      page: 1,
      search: input || '',
      sort: searchOptions.sortField,
      dir: searchOptions.sortDir,
    });
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const renderContextMenu = (item: PatientReminderItem) => {
    const isActive = item.status !== Status.DEACTIVATED;
    return (
      <ContextMenu
        id={`reminder-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={[
          {
            id: `edit-reminder-${item.id}`,
            isDisplayed: true,
            label: t('clinicSite.manageSites.manageReminders.editReminder'),
            icon: <EditSiteIcon />,
            onClick: () => {
              const url = setNavBack({
                search: '',
              });
              navigate(`/dashboard/sites/${siteId}/patient-reminders/edit-reminder/${item.id}`, {
                state: { navBackUrl: url },
              });
            },
          },
          {
            id: `action-reminder-${item.id}`,
            isDisplayed: true,
            label: isActive
              ? t('clinicSite.manageSites.manageReminders.deactivate')
              : t('clinicSite.manageSites.manageReminders.reactivate'),
            icon: isActive ? <DeactivatePatientIcon /> : <ReactivateSiteIcon />,
            onClick: () =>
              isActive
                ? showDeactivateReminderPopup(item.id)
                : showReactivateReminderPopup(item.id),
          },
        ]}
      />
    );
  };

  const renderMobileData = (data: ReminderGroup[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.flatMap((group: ReminderGroup) => {
          const result = group.reminders.map((item: PatientReminderItem) => {
            const listItems = [
              [
                <>{t('clinicSite.manageSites.manageReminders.tableHeader.days')}</>,
                <Box sx={{ wordBreak: 'break-word' }}>{item?.days}</Box>,
              ],
              [
                <>{`${t('clinicSite.manageSites.manageReminders.tableHeader.times')}`}</>,
                <Box sx={{ wordBreak: 'break-word' }}>{item?.times}</Box>,
              ],
              [
                <>{`${t('clinicSite.manageSites.manageReminders.tableHeader.interval')}`}</>,
                <Box sx={{ wordBreak: 'break-word' }}>{item?.interval}</Box>,
              ],
              [
                <>{t('clinicSite.manageSites.manageReminders.tableHeader.type')}</>,
                <Box sx={{ wordBreak: 'break-word' }}>
                  {ReminderCommunication[item?.communicationType]}
                </Box>,
              ],
              [
                <>{t('clinicSite.manageSites.manageReminders.tableHeader.status')}</>,
                <Box sx={{ wordBreak: 'break-word' }}>{toTitleCase(item?.status)}</Box>,
              ],
            ];
            return {
              leftHeader: (
                <Link
                  sx={{ fontSize: '20px', textAlign: 'left', fontWeight: '600' }}
                  component="button"
                  onClick={() => {
                    navigate(
                      `/dashboard/sites/${siteId}/patient-reminders/edit-reminder/${item.id}`,
                      {
                        state: { navBackUrl },
                      }
                    );
                  }}
                >
                  {`${group.name}`}
                </Link>
              ),
              rightHeader: renderContextMenu(item),
              items: [...listItems],
            };
          });
          return [...result];
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  const reminderList = (reminderData: PatientReminderItem[], assessmentTypeId: number) => {
    return (
      <>
        <Box sx={{ width: '100%' }}>
          {reminderData?.length === 0 ? (
            <RoundedContainer
              sx={{
                mt: 2,
                p: 2,
                background: theme.xPalette.background,
                color: 'rgba(0, 0, 0, 0.7)',
              }}
            >
              <Typography>{t('treatmentPlans.objectiveList.error.notFound')}</Typography>
            </RoundedContainer>
          ) : (
            <Box
              sx={{ mt: 2 }}
              key={assessmentTypeId}
              ref={expandState.includes(assessmentTypeId) ? expandReminderRef : null}
            >
              <DataTable
                commonCellStyles={{
                  [`&.${tableCellClasses.head}`]: {
                    padding: '16px 8px',
                    '&:first-of-type': {
                      paddingLeft: '24px',
                    },
                  },
                  [`&.${tableCellClasses.body}`]: {
                    padding: '16px 8px',
                    '&:first-of-type': {
                      paddingLeft: '24px',
                    },
                  },
                }}
                headers={[
                  {
                    text: '',
                    sx: { width: '10%' },
                  },
                  {
                    text: t('clinicSite.manageSites.manageReminders.tableHeader.interval'),
                    sx: { width: '15%' },
                  },
                  {
                    text: t('clinicSite.manageSites.manageReminders.tableHeader.days'),
                    sx: { width: '15%' },
                  },
                  {
                    text: t('clinicSite.manageSites.manageReminders.tableHeader.times'),
                    sx: { width: '15%' },
                  },
                  {
                    text: t('clinicSite.manageSites.manageReminders.tableHeader.type'),
                    sx: { width: '15%' },
                  },
                  {
                    text: t('clinicSite.manageSites.manageReminders.tableHeader.status'),
                    sx: { width: '15%' },
                  },
                  {
                    text: t('clinicSite.manageSites.manageReminders.tableHeader.action'),
                    sx: { width: '15%', textAlign: 'center' },
                  },
                ]}
                items={reminderData.map((item) => [
                  {
                    component: <Box />,
                  },
                  {
                    component: (
                      <Link
                        sx={{ fontSize: '1em', textAlign: 'left' }}
                        component="button"
                        onClick={() => {
                          navigate(
                            `/dashboard/sites/${siteId}/patient-reminders/edit-reminder/${item.id}`,
                            {
                              state: { navBackUrl },
                            }
                          );
                        }}
                      >
                        {toTitleCase(item?.interval)}
                      </Link>
                    ),
                  },
                  {
                    component: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontWeight={400} textAlign="left">
                          {item?.days}
                        </Typography>
                      </Box>
                    ),
                  },
                  {
                    component: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontWeight={400} textAlign="left">
                          {item?.times}
                        </Typography>
                      </Box>
                    ),
                  },
                  {
                    component: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontWeight={400} textAlign="left">
                          {ReminderCommunication[item?.communicationType]}
                        </Typography>
                      </Box>
                    ),
                  },
                  {
                    component: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontWeight={400} textAlign="left">
                          {toTitleCase(item?.status)}
                        </Typography>
                      </Box>
                    ),
                  },
                  {
                    component: renderContextMenu(item),
                    sx: { textAlign: 'center' },
                  },
                ])}
              />
            </Box>
          )}
        </Box>
      </>
    );
  };

  const renderDesktopData = (data?: ReminderGroup[]) => {
    return (
      <DataTable
        sort={{ field: searchOptions.sortField, dir: searchOptions.sortDir }}
        onSorted={handleTableSorting}
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '16px 8px',
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '16px 8px',
          },
        }}
        headers={[
          {
            text: '',
            sx: { width: '5%' },
          },
          {
            text: t('clinicSite.manageSites.manageReminders.tableHeader.name'),
            sx: { width: '50%' },
            sortKey: 'name',
          },
          {
            text: t('clinicSite.manageSites.manageReminders.tableHeader.category'),
            sx: { width: '45%' },
            sortKey: 'category',
          },
        ]}
        items={
          data?.map((item: ReminderGroup, index) => [
            {
              component: (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton
                    data-testid={`expand-${item.assessmentTypeId}`}
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      setExpandState((prevState) => {
                        if (expandState.includes(item.assessmentTypeId)) {
                          return prevState.filter((expandId) => expandId !== item.assessmentTypeId);
                        } else {
                          return [...prevState, item.assessmentTypeId];
                        }
                      });
                    }}
                  >
                    {expandState.includes(item.assessmentTypeId) ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </Box>
              ),
            },
            {
              component: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography fontWeight={400} textAlign="left">
                    {item?.name}
                  </Typography>
                </Box>
              ),
            },
            {
              component: (
                <Typography fontWeight={400} textAlign="left">
                  {item?.category}
                </Typography>
              ),
            },
          ]) || []
        }
        group={data?.map((item: ReminderGroup) => {
          return {
            component: reminderList(item.reminders, item.assessmentTypeId),
            expand: expandState.includes(item.assessmentTypeId),
          };
        })}
      />
    );
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('clinicSite.manageSites.manageReminders.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={!isDesktop}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 0 : 2 }}
            headerText={t('clinicSite.manageSites.manageReminders.title')}
            backUrl={'/dashboard/sites'}
          />
          <StyledAddWrapper>
            <StyledSearchBox
              name="reminder-search-field"
              data-testid="z-search-field"
              inputRef={searchTextboxRef}
              onKeyUp={handleSearchKeyUp}
              defaultValue={searchOptions.search}
              placeholder={t('clinicSite.manageSites.manageReminders.search')}
              endAdornment={
                <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              }
            />
            {!isDesktop && (
              <PatientSortMenu
                sx={{
                  width: '20%',
                  flexShrink: 0,
                  borderRadius: '8px',
                  minWidth: 'unset',
                }}
                sortField={searchOptions.sortField}
                sortDir={searchOptions.sortDir}
                onSorted={handleMenuSorting}
              />
            )}
          </StyledAddWrapper>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={tableRef}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, mx: 2 }}>
              <StyledTabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="reminder-list-tabs"
              >
                <StyledTab
                  label={t('clinicSite.manageSites.manageReminders.tableTabs.interviewTab')}
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
                <StyledTab
                  label={t('clinicSite.manageSites.manageReminders.tableTabs.monitorTab')}
                  id="tab-2"
                  aria-controls="tabpanel-2"
                />
              </StyledTabs>
            </Box>
            {displayReminderData?.length === 0 ? (
              <RoundedContainer sx={{ mt: 2, p: 2 }}>
                <Typography>
                  {t('clinicSite.manageSites.manageReminders.errors.remindersNotFound')}
                </Typography>
              </RoundedContainer>
            ) : (
              <Box sx={{ mt: 2 }}>
                {isDesktop
                  ? renderDesktopData(displayReminderData)
                  : renderMobileData(displayReminderData)}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 4,
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    background: theme.xPalette.white,
                    mt: 0,
                    [theme.breakpoints.down('sm')]: {
                      mt: 1,
                      py: 2,
                    },
                  }}
                >
                  <CustomPagination
                    changePage={(e, value) => {
                      handlePageChange(value);
                    }}
                    currentPage={Number(searchOptions.page)}
                    pages={totalPages || 0}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={
            callAction === CallActionType.Deactivate
              ? handleDeactivateReminder
              : handleActivateReminder
          }
        />
      </Container>
    </DashboardLayout>
  );
}
