import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import { formatDate } from '../../../../common/utils/dateTimeUtils';
import { StsEventFormType } from '../../../../types/InterviewQuestionTypes';
import { StyledRadioCheckIcon, StyledRadioIcon } from '../SdsScaleQuestion';

const StyledFormLabel = styled(FormControlLabel)(() => ({
  margin: 0,
  '&.MuiFormControlLabel-root .MuiFormControlLabel-label ': {
    fontSize: '12px',
  },
}));

const StyledRadioGroup = styled(RadioGroup)(() => ({
  width: '100%',
  justifyContent: 'space-around',
  marginTop: '8px',
}));

const levels = ['1', '2', '3'];

interface StsEventFormProps {
  formKey: number;
  error?: boolean;
  callBackData: (data: any) => void;
  savedAnswer?: StsEventFormType;
}

export default function StsEventForm({
  formKey,
  error,
  callBackData,
  savedAnswer,
}: StsEventFormProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [inputWhen, setWhen] = useState<Date | string>('');
  const [inputHow, setHow] = useState<string>('');
  const [inputLevel, setLevel] = useState<string>('');
  const [scaleValue, setScaleValue] = useState<string>('');
  const stsMeasurement: string[] = Object.values(
    t('interview.form.sts.stsScale', { returnObjects: true })
  );

  const valueSetterMap: { [key: string]: (value: any) => void } = {
    valueHow: setHow,
    valueLevel: setLevel,
    valueWhen: setWhen,
    valueScale: setScaleValue,
  };

  const onValueChanged = (event: any) => {
    const setter = valueSetterMap[event.target.name];
    if (setter) {
      setter(event.target.value);
    }

    const valueStsEvent = {
      formKey,
      date: formatDate(inputWhen),
      how: inputHow,
      scaleValue: scaleValue,
      level: inputLevel,
    };

    if (inputWhen && inputHow && inputLevel && scaleValue) {
      callBackData(valueStsEvent);
    }
  };

  const controlProps = (item: string) => ({
    checked: scaleValue === item,
    onChange: onValueChanged,
    onBlur: onValueChanged,
    value: item,
    name: 'valueScale',
    inputProps: { 'aria-label': item },
  });

  useEffect(() => {
    if (savedAnswer) {
      const { date, how, scaleValue, level } = savedAnswer;
      setWhen(date || '');
      setHow(how || '');
      setScaleValue(scaleValue.toString() || '');
      setLevel(level.toString() || '');
    }
  }, [savedAnswer]);

  const renderAnswers = () => {
    return stsMeasurement.map((item, index) => {
      return (
        <StyledFormLabel
          key={item}
          value={index}
          labelPlacement="bottom"
          control={
            <Radio
              checkedIcon={<StyledRadioCheckIcon>{index}</StyledRadioCheckIcon>}
              icon={<StyledRadioIcon>{index}</StyledRadioIcon>}
              {...controlProps(index.toString())}
            />
          }
          label={item}
        />
      );
    });
  };

  return (
    <Box
      sx={{
        width: '40%',
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          disableFuture
          inputFormat="MM/DD/YYYY"
          onChange={(newValue: any) => {
            onValueChanged({
              target: {
                name: 'valueWhen',
                value: newValue,
              },
            });
          }}
          value={inputWhen}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              margin="normal"
              label={t('interview.form.sts.when')}
              fullWidth
              variant="outlined"
            />
          )}
        />
      </LocalizationProvider>
      <TextField
        name="valueHow"
        error={error}
        value={inputHow}
        margin="normal"
        fullWidth
        label={t('interview.form.sts.how')}
        variant="outlined"
        onChange={onValueChanged}
        onBlur={onValueChanged}
      />
      <TextField
        name="valueLevel"
        error={error}
        value={inputLevel}
        margin="normal"
        select
        fullWidth
        variant="outlined"
        label={t('interview.form.sts.level')}
        onChange={onValueChanged}
      >
        {levels.map((item: any) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>

      <StyledRadioGroup row>{renderAnswers()}</StyledRadioGroup>
    </Box>
  );
}
