import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { Theme, styled } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { rootApi } from '../../app/services';
import { useForgotPasswordMutation, useLogoutMutation } from '../../app/services/auth';
import theme from '../../app/theme';
import { useToast } from '../../app/toast';
import { ReactComponent as UnlockIconSvg } from '../../assets/icons/lock.svg';
import { ReactComponent as LogoutIconSvg } from '../../assets/icons/logout.svg';
import { ReactComponent as SupportIconSvg } from '../../assets/icons/message-question.svg';
import { ReactComponent as ResetPasswordIconSvg } from '../../assets/icons/password-reset.svg';
import { ReactComponent as UserIconSvg } from '../../assets/icons/profile-2user.svg';
import { ReactComponent as ProfileIconSvg } from '../../assets/icons/profile-circle.svg';
import { ReactComponent as ReportIconSvg } from '../../assets/icons/report.svg';
import { ReactComponent as SiteIconSvg } from '../../assets/icons/sites.svg';
import { ReactComponent as SSOIconSvg } from '../../assets/icons/sso-client.svg';
import { clearCredentials, selectUser } from '../../features/auth/authSlice';
import { clearAssessmentCachedData } from '../../features/patient/patientUtils';
import { AuthUserType } from '../../types/AuthTypes';
import { roundedSquareButtonStyle } from '../utils/commonStyles';
import {
  canAddEditSsoClient,
  canAddEditTemplate,
  canResetCache,
  canViewAccountSettingsPage,
  canViewAccounts,
  canViewBuilder,
  canViewAuditLog,
  canViewExportPage,
  canViewImportInterviewPage,
  canViewInterviewMenu,
  canViewMasterUsageReport,
  canViewOrAddUser,
  canViewRescoreInterviewPage,
  canViewSites,
  canViewUsageReport,
  canViewMenuClinicalFollowupsPage,
  canViewBySuperAdmin,
} from '../utils/permissionUtils';
import AccountIcon from './icons/AccountIcon';
import AddIcon from './icons/AddIcon';
import ArrowDownIcon from './icons/ArrowDownIcon';
import ArrowUpIcon from './icons/ArrowUpIcon';
import SettingIcon from './icons/SettingIcon';

interface NavigationProps {
  open: boolean;
  toggle: (open: boolean) => void;
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const SiteIcon = () => <SvgIcon component={SiteIconSvg} />;
const SSOIcon = () => <SvgIcon component={SSOIconSvg} />;
const ReportIcon = () => <SvgIcon component={ReportIconSvg} />;
const UnlockIcon = () => <SvgIcon component={UnlockIconSvg} />;
const LogoutIcon = () => <SvgIcon component={LogoutIconSvg} />;
const SupportIcon = () => <SvgIcon component={SupportIconSvg} />;
const ProfileIcon = () => <SvgIcon component={ProfileIconSvg} />;
const UserIcon = () => <SvgIcon component={UserIconSvg} />;
const ResetPasswordIcon = () => <SvgIcon component={ResetPasswordIconSvg} />;

const wrapperStyle: SxProps<Theme> = {
  width: 250,
  color: theme.palette.text.secondary,

  '& svg': {
    mr: 2,
    fill: 'transparent',
  },
  '& a': {
    color: theme.palette.text.primary,
    display: 'flex',
  },
  '& li': {
    py: 1.5,
  },
  '& li:hover': {
    background: theme.xPalette.background,

    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    '& svg': {
      fontWeight: 'normal',

      '& path': {
        stroke: theme.palette.primary.main,
      },
    },
  },
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.xPalette.menuBlack,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

// TODO: show/hide menu items based on role
export default function Navigation({ open, toggle }: NavigationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const accountUser = useAppSelector<AuthUserType>(selectUser);
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const toast = useToast();
  const [forgotPassword] = useForgotPasswordMutation();
  const currentUser = useAppSelector(selectUser);
  const followUpPage = `/dashboard/accounts/clinical-follow-ups/${currentUser.clinicAccountId}`;

  const [openItems, setOpenItems] = useState<Record<string, boolean>>({});

  const handleCollapse = (e: React.MouseEvent<HTMLLIElement>) => {
    const elementId = e.currentTarget.dataset.id;
    if (elementId) {
      setOpenItems({ ...openItems, [elementId]: !openItems[elementId] });
    }
  };

  const handleLogout = async () => {
    await logout().unwrap();
    clearAssessmentCachedData();
    navigate('/login', { replace: true });
    dispatch(clearCredentials());
    dispatch(rootApi.util.resetApiState());
  };

  const resetPassword = async () => {
    try {
      await forgotPassword({
        clinicAccount: accountUser.clinicAccountId,
        email: accountUser.email,
      }).unwrap();
      toast.publish(t('resetPassword.menuSuccess'), 'success');
    } catch (e) {
      toast.publish(t('resetPassword.menuFail'), 'error');
    }
  };

  return (
    <Drawer
      anchor="right"
      hideBackdrop={false}
      open={open}
      onClose={() => {
        toggle(false);
      }}
    >
      <DrawerHeader>
        <IconButton onClick={() => toggle(false)} sx={roundedSquareButtonStyle}>
          <ArrowForward />
        </IconButton>
      </DrawerHeader>
      <Box sx={wrapperStyle} role="presentation">
        <List>
          <ListItem>
            <Link href={`/dashboard/profile/${accountUser.id}`}>
              <ProfileIcon />
              {t('menu.profile')}
            </Link>
          </ListItem>
          {canViewAccounts(currentUser.roleId) && (
            <ListItem>
              <Link href="/dashboard/accounts">
                <AccountIcon />
                {t('menu.accounts')}
              </Link>
            </ListItem>
          )}
          {canViewAccountSettingsPage(currentUser.roleId) && (
            <ListItem>
              <Link href="/dashboard/account-messaging">
                <SettingIcon />
                {t('accountMessaging.title')}
              </Link>
            </ListItem>
          )}
          {canViewInterviewMenu(currentUser.roleId) && (
            <>
              <ListItem data-id="menu.assessment" onClick={handleCollapse}>
                <StyledTypography>
                  <ReportIcon />
                  {t('menu.assessment')}
                  {openItems['menu.assessment'] ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </StyledTypography>
              </ListItem>
              <Collapse in={openItems['menu.assessment']} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {canViewBuilder(currentUser.roleId) && (
                    <ListItem sx={{ pl: 4 }}>
                      <Link href="/dashboard/assessment-builder">
                        <AddIcon />
                        {t('menu.assessmentBuilder')}
                      </Link>
                    </ListItem>
                  )}
                  {canResetCache(currentUser.roleId) && (
                    <ListItem sx={{ pl: 4 }}>
                      <Link href="/dashboard/cache-management">
                        <AddIcon />
                        {t('cacheManagement.title')}
                      </Link>
                    </ListItem>
                  )}
                  {canViewImportInterviewPage(currentUser.roleId) && (
                    <>
                      <ListItem sx={{ pl: 4 }}>
                        <Link href="/dashboard/import-interviews">
                          <AddIcon />
                          {t('interviews.importInterviews.title')}
                        </Link>
                      </ListItem>
                    </>
                  )}
                  {canViewRescoreInterviewPage(currentUser.roleId) && (
                    <ListItem sx={{ pl: 4 }}>
                      <Link href="/dashboard/rescore-jobs">
                        <AddIcon />
                        {t('interviews.rescoreJobs.titleMenu')}
                      </Link>
                    </ListItem>
                  )}
                  {canAddEditTemplate(currentUser.roleId) && (
                    <ListItem sx={{ pl: 4 }}>
                      <Link href="/dashboard/templates">
                        <AddIcon />
                        {t('eSignature.template.title')}
                      </Link>
                    </ListItem>
                  )}
                  {canViewBySuperAdmin(currentUser.roleId) && (
                    <ListItem sx={{ pl: 4 }}>
                      <Link href="/dashboard/transfer-assessment">
                        <AddIcon />
                        {t('menu.transferAssessment')}
                      </Link>
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}
          {canViewAuditLog(currentUser.roleId) && (
            <ListItem>
              <Link href="/dashboard/audit-log">
                <ReportIcon />
                {t('menu.auditLog')}
              </Link>
            </ListItem>
          )}
          {canViewMenuClinicalFollowupsPage(currentUser.roleId) && (
            <ListItem>
              <Link href={followUpPage}>
                <ReportIcon />
                {t('menu.clinicalFollowups')}
              </Link>
            </ListItem>
          )}
          {canViewExportPage(currentUser.roleId) && (
            <>
              <ListItem data-id="exportData.title" onClick={handleCollapse}>
                <StyledTypography>
                  <ReportIcon />
                  {t('exportData.title')}
                  {openItems['exportData.title'] ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </StyledTypography>
              </ListItem>

              <Collapse in={openItems['exportData.title']} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem sx={{ pl: 4 }}>
                    <Link href="/dashboard/raw-export">
                      <AddIcon />
                      {t('exportData.raw.title')}
                    </Link>
                  </ListItem>
                  <ListItem sx={{ pl: 4 }}>
                    <Link href="/dashboard/structured-export">
                      <AddIcon />
                      {t('exportData.structured.title')}
                    </Link>
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
          {canViewUsageReport(currentUser.roleId) && (
            <>
              <ListItem data-id="menu.report" onClick={handleCollapse}>
                <StyledTypography>
                  <ReportIcon />
                  {t('menu.report')}
                  {openItems['menu.report'] ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </StyledTypography>
              </ListItem>

              <Collapse in={openItems['menu.report']} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {canViewMasterUsageReport(currentUser.roleId) && (
                    <ListItem sx={{ pl: 4 }}>
                      <Link href="/dashboard/master-usage-report">
                        <AddIcon />
                        {t('masterUsageReport.title')}
                      </Link>
                    </ListItem>
                  )}
                  <ListItem sx={{ pl: 4 }}>
                    <Link href="/dashboard/report">
                      <AddIcon />
                      {t('report.title')}
                    </Link>
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
          <ListItem>
            <Link href="#" onClick={resetPassword}>
              <ResetPasswordIcon />
              {t('menu.resetPassword')}
            </Link>
          </ListItem>
          {canViewSites(currentUser.roleId) && (
            <ListItem>
              <Link href="/dashboard/sites">
                <SiteIcon />
                {t('menu.sites')}
              </Link>
            </ListItem>
          )}
          {canAddEditSsoClient(currentUser.roleId) && (
            <ListItem>
              <Link href="/dashboard/ssoClients">
                <SSOIcon />
                {t('menu.ssoClients')}
              </Link>
            </ListItem>
          )}
          <ListItem>
            <Link href="/dashboard/support">
              <SupportIcon />
              {t('menu.support')}
            </Link>
          </ListItem>
          {canViewOrAddUser(currentUser.roleId) && (
            <ListItem>
              <Link href="/dashboard/unlock">
                <UnlockIcon />
                {t('menu.unlock')}
              </Link>
            </ListItem>
          )}
          {canViewOrAddUser(currentUser.roleId) && (
            <ListItem>
              <Link href="/dashboard/users">
                <UserIcon />
                {t('menu.users')}
              </Link>
            </ListItem>
          )}

          <Divider></Divider>
          <ListItem>
            <Link href="#" onClick={handleLogout}>
              <LogoutIcon />
              {t('menu.logOut')}
            </Link>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
