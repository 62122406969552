import { rest } from 'msw';
import { LoginRequest } from '../types/AuthRequests';

const mocks = [
  rest.post<LoginRequest>('/v1/auth/login', (req, res, ctx) => {
    sessionStorage.setItem('isAuthenticated', 'true');

    const { clinicAccount } = req.body;
    if (clinicAccount === '000') {
      return res(
        ctx.status(400),
        ctx.json({
          error: ['Either the account number, email, or password was incorrect.'],
        })
      );
    }

    return res(
      ctx.json({
        accessToken: `xxx-${new Date().getTime()}`,
        refreshToken: `yyy-${new Date().getTime()}`,
        user: {
          id: 1,
          email: 'xxx@mail.com',
          firstName: 'Tri',
          lastName: 'Nguyen',
          roleId: 1,
          roleName: 'Admin',
          clinicAccountId: '2020202020',
          clinicAccountName: 'KMS Tech',
        },
      })
    );
  }),

  rest.post('/v1/auth/logout', (req, res, ctx) => {
    return res();
  }),
];

export default process.env.REACT_APP_AUTH_MOCK_DISABLED === 'true' ? [] : mocks;
