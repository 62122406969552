import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HistoryIcon from '@mui/icons-material/History';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tab from '@mui/material/Tab';
import { tableCellClasses } from '@mui/material/TableCell';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useListPatientsQuery } from '../../app/services/account';
import { useDeleteInterviewOrMonitorMutation } from '../../app/services/interview';
import {
  useDeletePatientMutation,
  useUpdateStatusPatientMutation,
} from '../../app/services/patient';
import { useToast } from '../../app/toast';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import DataTable from '../../common/components/DataTable';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import DeactivatePatientIcon from '../../common/components/icons/DeactivatePatientIcon';
import DeletePatientIcon from '../../common/components/icons/DeletePatientIcon';
import DeleteReportIcon from '../../common/components/icons/DeleteReportIcon';
import EditInterviewIcon from '../../common/components/icons/EditInterviewIcon';
import EditPatientIcon from '../../common/components/icons/EditPatientIcon';
import ProfileIcon from '../../common/components/icons/ProfileIcon';
import ReactivatePatientIcon from '../../common/components/icons/ReactivatePatientIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  PageStatus,
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
  setPageStatus,
} from '../../common/slices/globalSlice';
import { AssessmentStatus } from '../../common/utils/assessmentStatus';
import { AssessmentType } from '../../common/utils/assessmentType';
import { CallActionType } from '../../common/utils/callActionType';
import { statusBoxStyle } from '../../common/utils/commonStyles';
import { DefaultPageSize } from '../../common/utils/commonUtils';
import { logger } from '../../common/utils/logger';
import { Role } from '../../common/utils/loginRole';
import { PageSource } from '../../common/utils/pageInfo';
import { getPageTitle } from '../../common/utils/pageUtils';
import { getPatientName } from '../../common/utils/patientUtils';
import { canViewImportPatientPage } from '../../common/utils/permissionUtils';
import { PageProps } from '../../types/PageProps';
import { PatientListItem } from '../../types/PatientRequest';
import { PopupContent } from '../../types/PopupType';
import { selectUser } from '../auth/authSlice';
import { setTrialInterview } from '../interview/interviewSlice';
import ContextMenu from './ContextMenu';
import PatientSortMenu from './PatientSortMenu';
import useDownloadInterviewOrMonitorReport from './hooks/useDownloadInterviewOrMonitorReport';
import { getAssessmentStatus } from './patientUtils';
import { DownloadReportRequest } from '../../types/InterviewReportRequest';

interface DisplayedPatientInfo {
  patientId: string;
  patientName: string;
  patientNameForDeleting?: string;
  clinicAccount: string;
  clinicSite: string;
  measurement: {
    id: number;
    name: string;
    type: AssessmentType;
    completedAt: Date;
    status: string;
    trial?: boolean;
    reducedTypeId: number;
    hasInterval?: boolean;
  };
}

const StyledLable = styled('p')(() => ({
  textAlign: 'right',
  color: 'red',
}));

const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.xPalette.white,
  flexBasis: '100%',
  minWidth: '240px',
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'unset',
    width: '74%',
    minWidth: 'unset',
  },
}));

const StyledSearchWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 0px',
  width: '100%',
  padding: '0px 8px',
  columnGap: '8px',
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    width: 'auto',
    flexBasis: '40%',
    maxWidth: '1200px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',
  },
}));

const StyledImportPatientBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: 0,
  width: '40%',
  minWidth: '140px',
  ml: 1,
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    width: '96%',
    height: '56px',
    ml: 0,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledTab = styled(Tab)(() => ({
  width: '50%',
}));

const TabStatusMap: { [key: number]: string } = {
  0: 'active',
  1: 'inactive',
};

export default function PatientListPage({ breadcrumbs }: PageProps) {
  const contentPopup = useAppSelector(selectContentPopup);
  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const { pathname, search } = useLocation();
  const [patientIdAction, setPatientIdAction] = useState<string>('');
  const [testIdAction, setTestIdAction] = useState<number>(0);
  const [assessmentTypeAction, setAssessmentTypeAction] = useState<AssessmentType>(
    AssessmentType.Interview
  );
  const [callAction, setCallAction] = useState<string>('');
  const [cancelUrl, setCancelUrl] = useState<string>(pathname + search);
  const { t } = useTranslation();
  const theme = useTheme();
  const shouldRenderDesktopData = useMediaQuery(theme.breakpoints.up('md'));
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const tableRef = useRef<HTMLDivElement>(null);
  const searchTextboxRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [displayedPatientData, setDisplayedPatientData] = useState<DisplayedPatientInfo[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const currentUser = useAppSelector(selectUser);

  const searchOptions = {
    search: searchParams.get('search') || '',
    status: searchParams.get('status') || 'active',
    page: searchParams.get('page') || '1',
    sortField: searchParams.get('sort') || 'status',
    sortDir: searchParams.get('dir') || 'desc',
  };
  const currentPage = parseInt(searchOptions.page);
  const { startDownloadReport } = useDownloadInterviewOrMonitorReport(t);

  // Query data
  const {
    data: patientData,
    isLoading: isLoadingPatient,
    refetch: handleRefetchData,
  } = useListPatientsQuery({
    search: searchOptions.search,
    page: currentPage,
    pageSize: DefaultPageSize,
    sort: searchOptions.sortField + ' ' + searchOptions.sortDir,
    status: searchOptions.status,
  });
  const isLoading = isLoadingPatient;
  const showPagingInfo = totalPages > 1;

  const deleteInterviewContentPopup = {
    title: t('patient.deleteInterview.title'),
    description: t('patient.deleteInterview.description'),
    btnOk: t('patient.deleteInterview.btnOk'),
    btnClose: t('patient.deleteInterview.btnClose'),
    toastMessage: t('patient.deleteInterview.toastMessage'),
    hidePatient: true,
  } as PopupContent;

  const deleteMonitorContentPopup = {
    title: t('patient.deleteMonitor.title'),
    description: t('patient.deleteMonitor.description'),
    btnOk: t('patient.deleteMonitor.btnOk'),
    btnClose: t('patient.deleteMonitor.btnClose'),
    toastMessage: t('patient.deleteMonitor.toastMessage'),
    hidePatient: true,
  } as PopupContent;

  useEffect(() => {
    if (searchParams && searchParams.get('status') === 'inactive') {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [searchParams]);

  useEffect(() => {
    if (patientData?.patients) {
      const getDisplayedPatientName = (patient: PatientListItem) =>
        patient.lastName
          ? patient.firstName
            ? `${patient.lastName}, ${patient.firstName}`
            : patient.lastName
          : patient.externalId
          ? `${t('patient.patientInfo.externalId')}: ${patient.externalId}`
          : `${t('patient.patientInfo.id')}: ${patient.id}`;
      const displayedData = patientData.patients.map((p: PatientListItem) => {
        return {
          patientId: p.id,
          patientName: getDisplayedPatientName(p),
          patientNameForDeleting: getPatientName(p),
          clinicAccount: p.clinicAccountName,
          clinicSite: p.clinicSiteName,
          measurement: {
            id: p?.assessmentId,
            name: p?.name,
            type: p?.type,
            completedAt: p?.completedAt,
            status: p?.status,
            trial: p?.trial,
            reducedTypeId: p?.reducedTypeId,
            hasInterval: p.hasInterval,
          },
        } as DisplayedPatientInfo;
      });
      setDisplayedPatientData(displayedData);
      setTotalPages(Math.ceil(patientData.totalItems / DefaultPageSize));
    }
  }, [patientData, t]);

  const updateSearchUrl = (updatedData: any) => {
    const params = {
      ...searchOptions,
      ...updatedData,
    };
    const url = generatePath(
      '/dashboard/patients/' +
        '?search=:search' +
        '&status=:status' +
        '&page=:page' +
        '&sort=:sortField' +
        '&dir=:sortDir',
      params
    );
    if (params?.status === 'inactive') {
      setTabIndex(1);
    }
    if (params?.status === 'active') {
      setTabIndex(0);
    }
    setCancelUrl(url);
    navigate(url, { replace: true });
  };

  const handlePageChange = (value: any) => {
    const newPage = parseInt(value);
    updateSearchUrl({ page: newPage });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTableSorting = (sortField: string) => {
    if (sortField === searchOptions.sortField) {
      const newDir = searchOptions.sortDir === 'asc' ? 'desc' : 'asc';
      updateSearchUrl({ sortDir: newDir });
    } else {
      updateSearchUrl({ sortField, sortDir: sortField === 'status' ? 'desc' : 'asc' });
    }
  };

  const handleMenuSorting = (sortField: string, sortDir: string) => {
    updateSearchUrl({ sortField, sortDir });
  };

  const handleSearch = () => {
    const input = searchTextboxRef.current?.value;
    updateSearchUrl({ page: 1, search: input || '' });
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
    updateSearchUrl({ page: 1, status: TabStatusMap[newIndex] || 'active' });
  };

  const viewReport = async (assessmentId: number, type: AssessmentType) => {
    dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
    handleClose();

    let data: DownloadReportRequest;
    if (type === AssessmentType.Interview) {
      data = {
        interviewResultId: assessmentId,
        latestVersion: true,
        type: AssessmentType.Interview,
      };
    } else {
      data = {
        monitorResultId: assessmentId,
        type: AssessmentType.Monitor,
      };
    }
    await startDownloadReport(data);
  };

  const showDeletePatientPopup = async (patientId: string, patientNameForDeleting?: string) => {
    const deletePatientContentPopup = {
      title: t('patient.deletePatient.title'),
      description: t('patient.deletePatient.description', {
        patientInfo: `${patientNameForDeleting}`,
      }),
      warningTitle: t('patient.deletePatient.warning'),
      warningContent: t('patient.deletePatient.warningContent'),
      btnOk: t('patient.deletePatient.btnOk'),
      btnClose: t('patient.deletePatient.btnClose'),
      toastMessage: t('patient.deletePatient.toastMessage'),
    } as PopupContent;

    dispatch(setContentPopup({ content: deletePatientContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setPatientIdAction(patientId);
    handleClose();
    setCallAction(CallActionType.Delete);
  };
  const showDeactivatePatientPopup = async (patientId: string, patientNameForDeleting?: string) => {
    const deactivatePatientContentPopup = {
      title: t('patient.deactivatePatient.title'),
      description: t('patient.deactivatePatient.description', {
        patientInfo: patientNameForDeleting,
      }),
      btnOk: t('patient.deactivatePatient.btnOk'),
      btnClose: t('patient.deactivatePatient.btnClose'),
      toastMessage: t('patient.deactivatePatient.toastMessage'),
    } as PopupContent;

    dispatch(setContentPopup({ content: deactivatePatientContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setPatientIdAction(patientId);
    handleClose();
    setCallAction(CallActionType.Deactivate);
  };

  const showReactivatePatientPopup = async (patientId: string, patientNameForDeleting?: string) => {
    const reactivatePatientContentPopup = {
      title: t('patient.reactivatePatient.title'),
      description: t('patient.reactivatePatient.description', {
        patientInfo: patientNameForDeleting,
      }),
      btnOk: t('patient.reactivatePatient.btnOk'),
      btnClose: t('patient.reactivatePatient.btnClose'),
      toastMessage: t('patient.reactivatePatient.toastMessage'),
    } as PopupContent;

    dispatch(setContentPopup({ content: reactivatePatientContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setPatientIdAction(patientId);
    handleClose();
    setCallAction(CallActionType.Reactivate);
  };

  const showDeleteReportPopup = async (patientId: string, type: AssessmentType, testId: number) => {
    dispatch(
      setContentPopup({
        content:
          type === AssessmentType.Interview
            ? deleteInterviewContentPopup
            : deleteMonitorContentPopup,
      })
    );
    dispatch(setIsOpenPopup({ value: true }));
    setPatientIdAction(patientId);
    setTestIdAction(testId);
    setAssessmentTypeAction(type);
    handleClose();
    setCallAction(CallActionType.DeleteReport);
  };

  const [deletePatient] = useDeletePatientMutation();
  const [updateStatusPatient] = useUpdateStatusPatientMutation();
  const [deleteReport] = useDeleteInterviewOrMonitorMutation();

  const onDelete = async (successCallback?: () => void) => {
    try {
      if (patientIdAction) {
        await deletePatient({ patientId: patientIdAction }).unwrap();
      }
      if (successCallback) {
        successCallback();
      }
      handleSearch();
      toast.publish(t('patient.deletePatient.toastMessage'), 'success');
    } catch (e) {
      toast.publish(t('patient.toastMessageFail.toastMessageFail'), 'error');
    }
  };

  const onDeactivate = async (successCallback?: () => void) => {
    try {
      if (patientIdAction) {
        await updateStatusPatient({ patientId: patientIdAction, status: 'inactive' }).unwrap();
      }
      if (successCallback) {
        successCallback();
      }
      handleSearch();
      toast.publish(t('patient.deactivatePatient.toastMessage'), 'success');
    } catch (e) {
      toast.publish(t('patient.deactivatePatient.toastMessageFail'), 'error');
    }
  };

  const onReactivate = async (successCallback?: () => void) => {
    try {
      if (patientIdAction) {
        await updateStatusPatient({ patientId: patientIdAction, status: 'active' }).unwrap();
      }
      if (successCallback) {
        successCallback();
      }
      handleSearch();
      toast.publish(t('patient.reactivatePatient.toastMessage'), 'success');
    } catch (e) {
      toast.publish(t('patient.reactivatePatient.toastMessageFail'), 'error');
    }
  };

  const onDeleteReport = async (successCallback?: () => void) => {
    try {
      if (patientIdAction && testIdAction) {
        if (assessmentTypeAction === AssessmentType.Interview) {
          await deleteReport({
            patientId: patientIdAction,
            interviewResultId: testIdAction,
            type: assessmentTypeAction,
          }).unwrap();
        }
        if (assessmentTypeAction === AssessmentType.Monitor) {
          await deleteReport({
            patientId: patientIdAction,
            monitorResultId: testIdAction,
            type: assessmentTypeAction,
          }).unwrap();
        }
      }
      if (successCallback) {
        successCallback();
      }
      toast.publish(
        t(
          assessmentTypeAction === AssessmentType.Interview
            ? 'patient.deleteInterview.toastMessage'
            : 'patient.deleteMonitor.toastMessage'
        ),
        'success'
      );
      handleRefetchData();
    } catch (e) {
      toast.publish(
        t(
          assessmentTypeAction === AssessmentType.Interview
            ? 'patient.deleteInterview.toastMessageFail'
            : 'patient.deleteMonitor.toastMessageFail'
        ),
        'error'
      );
    }
  };

  const renderContextMenu = (item: DisplayedPatientInfo) => {
    return (
      <ContextMenu
        id={`patient-${item.patientId}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={[
          {
            isGroupHeader: true,
            label: t('patient.patientList.menu.patient'),
          },
          {
            isDisplayed: tabIndex === 0,
            label: t('patient.patientList.menu.editPatient'),
            icon: <EditPatientIcon />,
            onClick: () => {
              navigate(
                `/dashboard/edit-patient/` + item.patientId + `?src=${PageSource.PatientList}`,
                { state: { cancelUrl } }
              );
            },
          },
          {
            isDisplayed:
              tabIndex === 0 || (tabIndex === 1 && currentUser.roleId === Role.SuperAdmin),
            label:
              tabIndex === 0
                ? t('patient.patientList.menu.deactivatePatient')
                : t('patient.patientList.menu.reactivatePatient'),
            icon: tabIndex === 0 ? <DeactivatePatientIcon /> : <ReactivatePatientIcon />,
            onClick: () =>
              tabIndex === 0
                ? showDeactivatePatientPopup(item.patientId, item.patientNameForDeleting)
                : showReactivatePatientPopup(item.patientId, item.patientNameForDeleting),
          },
          {
            label: t('patient.patientList.menu.deletePatient'),
            icon: <DeletePatientIcon />,
            onClick: () => {
              showDeletePatientPopup(item.patientId, item.patientNameForDeleting);
            },
          },
          {
            isDisplayed:
              (tabIndex === 0 &&
                item.measurement?.id > 0 &&
                (item.measurement?.status === AssessmentStatus.Completed ||
                  item.measurement?.status === AssessmentStatus.InProgress ||
                  ((item.measurement?.status === AssessmentStatus.Completed ||
                    item.measurement?.status === AssessmentStatus.InProgress) &&
                    item.measurement?.type === AssessmentType.Interview) ||
                  item.measurement?.hasInterval === true)) ||
              (item.measurement?.status === AssessmentStatus.Completed && tabIndex === 1),
            isGroupHeader: true,
            label: t('patient.patientList.menu.latestReport'),
          },
          {
            isDisplayed: item.measurement?.status === AssessmentStatus.Completed,
            label: t('patient.patientList.menu.viewReport'),
            icon: <EyeIcon />,
            onClick: () => viewReport(item.measurement?.id, item.measurement?.type),
          },
          {
            isDisplayed:
              item.measurement?.status === AssessmentStatus.Completed &&
              item.measurement?.type === AssessmentType.Interview &&
              tabIndex === 0 &&
              new Date().getTime() <
                new Date(
                  new Date(item.measurement?.completedAt).setDate(
                    new Date(item.measurement?.completedAt).getDate() + 14
                  )
                ).getTime(),
            label: t('patient.patientList.menu.editReport'),
            icon: <EditInterviewIcon />,
            onClick: () => {
              navigate(
                `/dashboard/patient/${item.patientId}/${item.measurement.id}/edit-interview`,
                {
                  state: {
                    fromPage: {
                      text: t('patient.patientList.title'),
                      link: cancelUrl,
                    },
                  },
                }
              );
            },
          },
          {
            isDisplayed:
              item.measurement?.status === AssessmentStatus.Completed &&
              item.measurement?.type === AssessmentType.Interview,
            label: t('patient.patientList.menu.displayInterviewHistory'),
            icon: <HistoryIcon />,
            onClick: () => {
              const url = generatePath(
                `/dashboard/patient/:patientId/report-history/:interviewResultId?src=${PageSource.PatientList}`,
                {
                  patientId: item.patientId,
                  interviewResultId: item.measurement?.id + '',
                }
              );
              navigate(url);
            },
          },
          {
            isDisplayed:
              tabIndex === 0 &&
              item.measurement?.id > 0 &&
              item.measurement?.reducedTypeId > 0 &&
              item.measurement?.hasInterval === true,
            label: t('patient.patientList.menu.editSchedule'),
            icon: <EditInterviewIcon />,
            onClick: () => {
              const url = generatePath(
                `/dashboard/patient/:patientId/edit-schedule/:assessmentType/:assessmentTypeId?src=${PageSource.PatientList}`,
                {
                  patientId: item.patientId,
                  assessmentType: item.measurement?.type + '',
                  assessmentTypeId: item.measurement?.reducedTypeId?.toString(),
                }
              );
              navigate(url);
            },
          },
          {
            isDisplayed:
              tabIndex === 0 &&
              item.measurement?.id > 0 &&
              item.measurement?.status !== AssessmentStatus.NotStarted &&
              item.measurement?.status !== AssessmentStatus.PendingScoring,
            label: t('patient.patientList.menu.deleteReport'),
            icon: <DeleteReportIcon />,
            onClick: () =>
              showDeleteReportPopup(item.patientId, item.measurement?.type, item.measurement?.id),
          },
        ]}
      />
    );
  };

  const handleResumeInterviewOrMonitor = async (item: DisplayedPatientInfo) => {
    const { id, type } = item.measurement;
    try {
      if (type === AssessmentType.Interview) {
        navigate(`/dashboard/patient/${item.patientId}/${id}/resume-interview`, {
          state: { resumeInterview: true, cancelUrl },
        });
        dispatch(setTrialInterview({ trialInterview: false || false }));
      } else {
        navigate(`/dashboard/patient/${item.patientId}/${id}/resume-monitor`, {
          state: { resumeMonitor: true, cancelUrl },
        });
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const renderDesktopData = (data: DisplayedPatientInfo[], tabIndex: number) => {
    return (
      <DataTable
        sort={{ field: searchOptions.sortField, dir: searchOptions.sortDir }}
        onSorted={handleTableSorting}
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
        }}
        headers={
          currentUser && currentUser.roleId === Role.SuperAdmin
            ? [
                { text: t('patient.patientList.name'), sx: { width: '25%' }, sortKey: 'name' },
                {
                  text: t('patient.patientList.account'),
                  sx: { width: '15%' },
                  sortKey: 'account',
                },
                { text: t('patient.patientList.site'), sx: { width: '15%' }, sortKey: 'site' },
                { text: t('patient.patientList.measurement'), sx: { width: '20%' } },
                {
                  text: t('patient.patientList.complete'),
                  sx: { textAlign: 'left', width: '15%' },
                  sortKey: 'status',
                },
                {
                  text: t('patient.patientList.action'),
                  sx: { textAlign: 'center', width: '10%' },
                },
              ]
            : [
                { text: t('patient.patientList.name'), sx: { width: '25%' }, sortKey: 'name' },
                { text: t('patient.patientList.site'), sx: { width: '15%' }, sortKey: 'site' },
                { text: t('patient.patientList.measurement'), sx: { width: '20%' } },
                {
                  text: t('patient.patientList.complete'),
                  sx: { textAlign: 'left', width: '15%' },
                  sortKey: 'status',
                },
                {
                  text: t('patient.patientList.action'),
                  sx: { textAlign: 'center', width: '10%' },
                },
              ]
        }
        items={
          currentUser && currentUser.roleId === Role.SuperAdmin
            ? data.map((item) => {
                return [
                  {
                    component: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ProfileIcon />
                        <Link href={`/dashboard/patient/${item.patientId}`}>
                          {item.patientName}
                        </Link>
                      </Box>
                    ),
                  },
                  { component: <Typography>{item.clinicAccount}</Typography> },
                  { component: <Typography>{item.clinicSite}</Typography> },
                  item.measurement?.status === AssessmentStatus.InProgress && tabIndex === 0
                    ? {
                        component: (
                          <Typography>
                            <Link
                              sx={{
                                textAlign: 'left',
                                color: item.measurement?.trial ? 'red' : '',
                              }}
                              component="button"
                              variant="body2"
                              onClick={() => {
                                handleResumeInterviewOrMonitor(item);
                              }}
                            >
                              {item.measurement?.name}
                            </Link>
                          </Typography>
                        ),
                      }
                    : {
                        component: (
                          <Typography sx={{ color: item.measurement?.trial ? 'red' : '' }}>
                            {item.measurement?.name}
                          </Typography>
                        ),
                      },
                  {
                    component: item.measurement && (
                      <Box
                        sx={{
                          ...statusBoxStyle(!shouldRenderDesktopData, item.measurement.status),
                        }}
                      >
                        {getAssessmentStatus({
                          status: item.measurement.status,
                          completedAt: item.measurement.completedAt,
                        })}
                      </Box>
                    ),
                  },
                  {
                    component: renderContextMenu(item),
                    sx: { textAlign: 'center' },
                  },
                ];
              })
            : data.map((item) => {
                return [
                  {
                    component: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ProfileIcon />
                        <Link href={`/dashboard/patient/${item.patientId}`}>
                          {item.patientName}
                        </Link>
                      </Box>
                    ),
                  },
                  { component: <Typography>{item.clinicSite}</Typography> },
                  item.measurement?.status === AssessmentStatus.InProgress && tabIndex === 0
                    ? {
                        component: (
                          <Typography>
                            <Link
                              sx={{
                                textAlign: 'left',
                                color: item.measurement?.trial ? 'red' : '',
                              }}
                              component="button"
                              variant="body2"
                              onClick={() => {
                                handleResumeInterviewOrMonitor(item);
                              }}
                            >
                              {item.measurement?.name}
                            </Link>
                          </Typography>
                        ),
                      }
                    : {
                        component: (
                          <Typography
                            sx={{ textAlign: 'left', color: item.measurement?.trial ? 'red' : '' }}
                          >
                            {item.measurement?.name}
                          </Typography>
                        ),
                      },
                  {
                    component: item.measurement && (
                      <Box
                        sx={{
                          ...statusBoxStyle(!shouldRenderDesktopData, item.measurement.status),
                        }}
                      >
                        {getAssessmentStatus({
                          status: item.measurement.status,
                          completedAt: item.measurement.completedAt,
                        })}
                      </Box>
                    ),
                  },
                  {
                    component: renderContextMenu(item),
                    sx: { textAlign: 'center' },
                  },
                ];
              })
        }
      />
    );
  };

  const listItemsFilterPhone = (item: DisplayedPatientInfo, userRole: number) => {
    if (userRole === Role.SuperAdmin) {
      return [
        [t('patient.patientList.account'), item.clinicAccount],
        [t('patient.patientList.site'), item.clinicSite],
        item.measurement?.status === AssessmentStatus.InProgress && tabIndex === 0
          ? [
              t('patient.patientList.measurement'),
              <Link
                sx={{ textAlign: 'right', color: item.measurement?.trial ? 'red' : '' }}
                component="button"
                variant="body2"
                onClick={() => {
                  handleResumeInterviewOrMonitor(item);
                }}
              >
                {item.measurement?.name && item.measurement?.name.length > 60
                  ? item.measurement?.name.substr(0, 60) + '...'
                  : item.measurement?.name}
              </Link>,
            ]
          : [
              t('patient.patientList.measurement'),
              item.measurement?.name &&
              item.measurement?.name.length > 60 &&
              item.measurement?.trial ? (
                <StyledLable>{item.measurement?.name.substr(0, 60) + '...'}</StyledLable>
              ) : item.measurement?.name &&
                item.measurement?.name.length < 60 &&
                item.measurement?.trial ? (
                <StyledLable>{item.measurement?.name}</StyledLable>
              ) : (
                item.measurement?.name
              ),
            ],
        [
          t('patient.patientList.complete'),
          item.measurement && (
            <Box
              sx={{
                ...statusBoxStyle(!shouldRenderDesktopData, item.measurement.status),
                marginRight: '-16px',
                width: 'auto',
                maxWidth: 'auto',
                px: '16px',
              }}
            >
              {getAssessmentStatus({
                status: item.measurement.status,
                completedAt: item.measurement.completedAt,
              })}
            </Box>
          ),
        ],
      ];
    }
    return [
      [t('patient.patientList.site'), item.clinicSite],
      item.measurement?.status === AssessmentStatus.InProgress && tabIndex === 0
        ? [
            t('patient.patientList.measurement'),
            <Link
              sx={{ textAlign: 'right', color: item.measurement?.trial ? 'red' : '' }}
              component="button"
              variant="body2"
              onClick={() => {
                handleResumeInterviewOrMonitor(item);
              }}
            >
              {item.measurement?.name && item.measurement?.name.length > 60
                ? item.measurement?.name.substr(0, 60) + '...'
                : item.measurement?.name}
            </Link>,
          ]
        : [
            t('patient.patientList.measurement'),
            item.measurement?.name &&
            item.measurement?.name.length > 60 &&
            item.measurement?.trial ? (
              <StyledLable>{item.measurement?.name.substr(0, 60) + '...'}</StyledLable>
            ) : item.measurement?.name &&
              item.measurement?.name.length < 60 &&
              item.measurement?.trial ? (
              <StyledLable>{item.measurement?.name}</StyledLable>
            ) : (
              item.measurement?.name
            ),
          ],
      [
        t('patient.patientList.complete'),
        item.measurement && (
          <Box
            sx={{
              ...statusBoxStyle(!shouldRenderDesktopData, item.measurement.status),
              marginRight: '-16px',
              width: 'auto',
              maxWidth: 'auto',
              px: '16px',
            }}
          >
            {getAssessmentStatus({
              status: item.measurement.status,
              completedAt: item.measurement.completedAt,
            })}
          </Box>
        ),
      ],
    ];
  };
  const renderPhoneData = (data: DisplayedPatientInfo[], tabIndex: number) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item) => ({
          leftHeader: (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ProfileIcon />
              <Link href={`/dashboard/patient/${item.patientId}`}>
                {item.patientName && item.patientName.length > 60
                  ? item.patientName.substr(0, 60) + '...'
                  : item.patientName}
              </Link>
            </Box>
          ),
          rightHeader: renderContextMenu(item),
          items: listItemsFilterPhone(item, currentUser && currentUser.roleId),
        }))}
      />
    );
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('patient.patientList.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
          ref={tableRef}
        >
          <PageHeader
            sx={{ mb: 2, mx: shouldRenderDesktopData ? 0 : 2 }}
            headerText={t('patient.patientList.title')}
            backUrl="/dashboard"
          />
          <StyledSearchWrapper>
            <StyledSearchBox
              inputRef={searchTextboxRef}
              onKeyUp={handleSearchKeyUp}
              defaultValue={searchOptions.search}
              placeholder={t('patient.patientList.searchPlaceholder')}
              endAdornment={
                <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              }
            />
            {!shouldRenderDesktopData && (
              <PatientSortMenu
                sx={{ width: '20%', flexShrink: 0, borderRadius: '8px', minWidth: 'unset' }}
                sortField={searchOptions.sortField}
                sortDir={searchOptions.sortDir}
                onSorted={handleMenuSorting}
                isPatient
              />
            )}
            {canViewImportPatientPage(currentUser.roleId) && (
              <StyledImportPatientBox
                type="button"
                fullWidth
                variant="contained"
                onClick={() => {
                  navigate('/dashboard/patients/import-patient', {
                    state: { navBackUrl: '/dashboard/patients' },
                  });
                }}
              >
                {t('patient.importPatient.title')}
              </StyledImportPatientBox>
            )}
          </StyledSearchWrapper>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={tableRef}>
            {displayedPatientData && (
              <>
                <Box sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      px: 2,
                      borderBottom: shouldRenderDesktopData ? 1 : 0,
                      borderColor: shouldRenderDesktopData ? 'divider' : 'unset',
                    }}
                  >
                    <StyledTabs
                      sx={
                        !shouldRenderDesktopData ? { borderBottom: 1, borderColor: 'divider' } : {}
                      }
                      value={tabIndex}
                      onChange={handleTabChange}
                      aria-label="patient-list-tabs"
                    >
                      <StyledTab
                        label={t('patient.patientList.activeTab')}
                        id="tab-1"
                        aria-controls="tabpanel-1"
                      />
                      <StyledTab
                        label={t('patient.patientList.deactivatedTab')}
                        id="tab-2"
                        aria-controls="tabpanel-2"
                      />
                    </StyledTabs>
                  </Box>
                  {displayedPatientData.length === 0 ? (
                    <RoundedContainer sx={{ mt: 2, p: 2 }}>
                      <Typography>{t('patient.patientList.patientsNotFound')}</Typography>
                    </RoundedContainer>
                  ) : (
                    <>
                      {shouldRenderDesktopData
                        ? renderDesktopData(displayedPatientData, tabIndex)
                        : renderPhoneData(displayedPatientData, tabIndex)}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          py: showPagingInfo ? 4 : 2,
                          borderBottomLeftRadius: '15px',
                          borderBottomRightRadius: '15px',
                          background: theme.xPalette.white,
                        }}
                      >
                        {showPagingInfo && (
                          <CustomPagination
                            changePage={(e, value) => handlePageChange(value)}
                            currentPage={currentPage}
                            pages={totalPages}
                            pageRef={tableRef}
                          />
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={
            callAction === CallActionType.Delete
              ? onDelete
              : callAction === CallActionType.Deactivate
              ? onDeactivate
              : callAction === CallActionType.DeleteReport
              ? onDeleteReport
              : onReactivate
          }
        />
      </Container>
    </DashboardLayout>
  );
}
