import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Email from '@mui/icons-material/Email';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import AnonymousLayout from '../../common/layouts/Anonymous';
import { getPageTitle } from '../../common/utils/pageUtils';
import { ForgotPasswordRequest } from '../../types/AuthRequests';
import { useForgotPasswordMutation } from '../../app/services/auth';
import { useToast } from '../../app/toast';
import { useState } from 'react';
import { ResponseError } from '../../types/ResponseError';
import Alert from '@mui/material/Alert';
import { ControlledTextField } from './ControlledTextField';

export default function ForgotPasswordPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [forgotPassword] = useForgotPasswordMutation();
  const toast = useToast();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  // Form setup
  const validationSchema = yup
    .object({
      clinicAccount: yup.string().required(t('login.error.blankClinicAccount')),
      email: yup
        .string()
        .email(t('login.error.invalidEmail'))
        .required(t('login.error.blankEmail')),
    })
    .required();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordRequest>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: ForgotPasswordRequest) => {
    try{
      const { message } = await forgotPassword(data).unwrap();
      toast.publish(message, 'success');
      setPageErrors([]);
     } catch (e) {
      const responseError = e as ResponseError;
      if (responseError.data?.error) {
        setPageErrors(typeof responseError.data.error === 'string' ? [responseError.data.error] : responseError.data.error);
      } else {
        setPageErrors([t('error.unexpectedError')]);
      }
    }
  };

  return (
    <AnonymousLayout>
      <Helmet>
        <title>{getPageTitle(t('forgotPassword.title'))} </title>
      </Helmet>

      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h1">{t('forgotPassword.title')}</Typography>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
          {t('forgotPassword.instructions1')}
          </Typography>
          <Typography variant="subtitle2" sx={{ mt: 2, marginTop: '0px',}}>
          {t('forgotPassword.instructions2')}
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1, width: '100%' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledTextField
              name="clinicAccount"
              control={control}
              fieldError={errors.clinicAccount}
              label={t('login.accountNumber')}
              icon={<PeopleAlt />}
            />
            <ControlledTextField
              name="email"
              control={control}
              fieldError={errors.email}
              label={t('login.email')}
              icon={<Email />}
            />
            <Box textAlign="center" sx={{ mt: 1 }}>
              {pageErrors.length > 0 &&
                pageErrors.map((error) => (
                  <Alert key={error} severity="error" sx={{ mt: 2, textAlign: 'left' }}>
                    {error}
                  </Alert>
                ))}
               <Button type="submit" size="large" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                {t('forgotPassword.submit')}
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: theme.palette.primary.main,
              }}
            >
              <ArrowBack />
              <Link href="/login">{t('forgotPassword.goBack')}</Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </AnonymousLayout>
  );
}
