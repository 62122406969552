import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { customMdBreakpoints } from '../../app/theme';
import { AssessmentMode } from '../../common/utils/assessmentMode';
import StartInterviewForm from './StartInterviewForm';
import StartMonitorForm from './StartMonitorForm';
import { removeMeasurementPacketList, setAssessmentMode } from './patientSlice';

interface StyledType {
  theme: any;
  matches: string;
  menuposition: number;
  deviceheight: number;
}

enum PageNumber {
  One = 0,
  Two = 1,
  Three = 2,
}

const StyledActionNavigation = styled(BottomNavigation)(
  ({ theme, matches, menuposition, deviceheight }: StyledType) => {
    const isMatches = matches === 'true';
    return {
      '&.MuiBottomNavigation-root': {
        backgroundColor: '#E5F5FD',
        margin: isMatches ? '0' : '0 80px',
        display: 'flex',
        columnGap: 20,
        justifyContent: isMatches ? 'space-between' : 'flex-start',
      },
      [theme.breakpoints.down('sm')]: {
        '&.MuiBottomNavigation-root': {
          position: menuposition < deviceheight ? 'none' : 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      '& .MuiBottomNavigationAction-label': {
        fontSize: '14px',
        fontWeight: theme.typography.h4.fontWeight,
        color: theme.xPalette.black,
        lineHeight: '20px',
      },
      '& .MuiButtonBase-root': {
        maxWidth: isMatches ? '100%' : '22.5%',
        fontSize: '14px',
        borderBottom: '4px solid #BBDEFB',
      },
      '& .Mui-selected': {
        borderBottomColor: '#388AC8',
        color: theme.palette.primary.main,
      },
    };
  }
);

export default function PatientInterviewMenu() {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(customMdBreakpoints));
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any>(null);
  const deviceHeight = window.innerHeight;
  const [menuPosition, setMenuPosition] = useState(0);
  const interviewTypeId = parseInt(searchParams.get('interview') || '0');
  const monitorTypeId = parseInt(searchParams.get('monitor') || '0');
  const language = searchParams.get('language') || '';
  const dispatch = useAppDispatch();

  useEffect(() => {
    const menuOffset = (ref.current as HTMLDivElement).getBoundingClientRect().top;
    setMenuPosition(menuOffset);
  }, [setMenuPosition]);

  useEffect(() => {
    if (interviewTypeId) {
      setValue(PageNumber.One);
    }
    if (monitorTypeId) {
      setValue(PageNumber.Two);
    }
  }, [interviewTypeId, monitorTypeId]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(removeMeasurementPacketList());
    dispatch(setAssessmentMode({ mode: AssessmentMode.Clinician }));
  }, [dispatch]);

  useEffect(() => {
    if (value === PageNumber.One) {
      setData(<StartInterviewForm interviewTypeId={interviewTypeId} defaultLanguage={language} />);
    }
    if (value === PageNumber.Two) {
      setData(<StartMonitorForm monitorTypeId={monitorTypeId} defaultLanguage={language} />);
    }

    // scroll to tab area when interview type id exists in query string
    if (interviewTypeId || monitorTypeId) {
      setTimeout(executeScroll, 800);
    }
  }, [value, setData, interviewTypeId, monitorTypeId, language]);

  const handleScroll = () => {
    if (!ref.current) return;
    const menuOffset = (ref.current as HTMLDivElement).getBoundingClientRect().top;
    setMenuPosition(menuOffset);
  };

  const executeScroll = () => {
    (ref.current as HTMLDivElement)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box
      sx={{
        pb: 6,
      }}
      ref={ref}
    >
      <Container maxWidth="lg" disableGutters>
        <StyledActionNavigation
          theme={theme}
          matches={matches.toString()}
          menuposition={menuPosition}
          deviceheight={deviceHeight}
          showLabels
          value={value}
          onChange={(e, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            onClick={executeScroll}
            label={t('patient.patientDashboard.form.screenersAndInterviews')}
          />
          <BottomNavigationAction
            onClick={executeScroll}
            label={t('patient.patientDashboard.form.outcomeMeasures')}
          />
        </StyledActionNavigation>
      </Container>
      <Container maxWidth="lg" disableGutters>
        <Box sx={{ backgroundColor: theme.xPalette.white, borderRadius: 4, mt: 2 }}>{data}</Box>
      </Container>
    </Box>
  );
}
