import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ShowLessIconSvg } from '../../../assets/icons/show-less.svg';

export default function ShowLessIcon() {
  return <SvgIcon component={ShowLessIconSvg} sx={{ 
    '& path': { fill: 'white' },
    height: '1.5em',
    marginLeft: '5px'
  }} />;
}
