import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { buildInterviewConductUrl } from '../../app/routes';
import {
  useCheckExpireTokenMutation,
  useGetPatientInterviewSettingsQuery,
} from '../../app/services/interview';
import { useMonitorCheckExpireTokenMutation } from '../../app/services/monitor';
import Loading from '../../common/components/Loading';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { landingTextStyle } from '../../common/utils/commonStyles';
import { InterviewSettingsViewName } from '../../common/utils/interviewSettings';
import { InterviewMode } from '../../common/utils/interviewsMode';
import { MfaInterviewType, MfaType } from '../../common/utils/mfa';
import { ResponseError } from '../../types/ResponseError';
import MfaPage from '../auth/MfaPage';
import { clearCredentials } from '../auth/authSlice';
import { setInterviewMode, setPatientInterviewData } from './interviewSlice';

enum InterviewsStatus {
  InProgress = 'inProgress',
}

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '16px 0',
  borderRadius: '8px',
  width: '20%',
  minWidth: 200,
  '&.MuiButton-text': {
    backgroundColor: theme.xPalette.white,
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default function PatientStartInterviewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const interviewInstructions = t('interview.emailPatientInterview.body.ul', {
    returnObjects: true,
  });
  const dispatch = useAppDispatch();
  const { token, tokenHash } = useParams();
  const [isShowMfa, setMfaData] = useState<boolean>(false);
  const [mfaInterviewData, setMfaInterviewData] = useState<any>({});
  const [searchParams] = useSearchParams();
  const checkTokenExpireInterview = useCheckExpireTokenMutation();
  const checkTokenExpireMonitor = useMonitorCheckExpireTokenMutation();
  const isFromMonitor = searchParams.get('isFromMonitor');
  const checkTokenExpire = isFromMonitor
    ? checkTokenExpireMonitor[0]
    : checkTokenExpireInterview[0];
  const [{ interviewStatus, interviewResultId, accessToken, reportType }, setInterviewData] =
    useState<{
      interviewStatus?: string;
      interviewResultId?: number;
      accessToken?: string;
      reportType?: string;
    }>({});

  const [pageErrors, setPageErrors] = useState<any[]>([]);
  const resumeInterview = interviewStatus === InterviewsStatus.InProgress;
  const { data: accountSettings, isLoading } = useGetPatientInterviewSettingsQuery(
    accessToken
      ? {
          accessToken,
          interviewType: InterviewMode.EmailPatient,
          viewName: resumeInterview
            ? InterviewSettingsViewName.Resume
            : InterviewSettingsViewName.Start,
        }
      : skipToken
  );
  const hasError = pageErrors.length > 0;

  useEffect(() => {
    const handleCheckTokenExpire = async () => {
      dispatch(clearCredentials());
      try {
        if (token && tokenHash) {
          const res = await checkTokenExpire({ token, tokenHash }).unwrap();

          let redirectUrl = '';
          if (res?.nextUrl) {
            redirectUrl = res.nextUrl;
          } else if (!res.interviewTestId) {
            redirectUrl = `/monitor/${token}/${tokenHash}?isFromInterview=true`;
          }

          if (redirectUrl) {
            navigate(redirectUrl);
          }

          if (res) {
            if (res.isShowMfa) {
              setMfaData(true);
            }

            setMfaInterviewData({
              isShowMfa: res.isShowMfa!,
              mfaType: MfaType.Phone,
              mfaVerified: res.mfaVerified!,
              testId: res?.interviewTestId!,
              type: MfaInterviewType.Interview,
              patientId: res.patientId,
              accessToken: res?.patientInterviewToken,
              phoneNumber: res.phoneNumber!,
              lockoutExpiration: res.lockoutExpiration!,
            });

            setInterviewData({
              interviewResultId: res?.interviewTestId!,
              interviewStatus: res?.interviewStatus!,
              accessToken: res?.patientInterviewToken!,
              reportType: res?.reportType!,
            });

            dispatch(
              setPatientInterviewData({
                data: {
                  interviewTestId: res?.interviewTestId!,
                  lastActivityTs: Date.now(),
                  accessToken: res?.patientInterviewToken!,
                },
              })
            );
          }
        }
      } catch (e) {
        const {
          data: { error },
        } = e as ResponseError;
        setPageErrors(error);
      }
    };
    handleCheckTokenExpire();
  }, [checkTokenExpire, token, tokenHash, dispatch, navigate]);

  const onMfaPageEventChange = () => {
    setMfaData(false);
  };

  const renderStaticLandingText = () => {
    dispatch(clearCredentials());
    return (
      <>
        <Typography pb={2} variant="body1">
          {t('interview.emailPatientInterview.body.p1')}
        </Typography>
        <Typography variant="body1">{t('interview.emailPatientInterview.body.p2')}</Typography>
        <List sx={{ ml: 3 }}>
          {Object.keys(interviewInstructions).map((item: any) => (
            <ListItem key={item} disablePadding>
              <ListItemText primary={interviewInstructions[item]} />
            </ListItem>
          ))}
        </List>
        <Typography pt={2} variant="body1">
          {resumeInterview
            ? t('interview.emailPatientInterview.body.p3_existing')
            : t('interview.emailPatientInterview.body.p3_new')}
        </Typography>
        <Typography variant="body1">{t('interview.emailPatientInterview.body.p4')}</Typography>
      </>
    );
  };

  const renderLandingHeader = () => {
    if (!isLoading) {
      if (accountSettings?.landingHeader) {
        return (
          <Typography
            variant="h1"
            dangerouslySetInnerHTML={{
              __html: accountSettings.landingHeader,
            }}
          ></Typography>
        );
      }
      return <Typography variant="h1">{t('interview.emailPatientInterview.title')}</Typography>;
    }
  };

  const renderLandingText = () => {
    if (!isLoading) {
      if (accountSettings?.landingText) {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: accountSettings.landingText,
            }}
          ></Typography>
        );
      }

      return renderStaticLandingText();
    }
  };

  return (
    <DashboardLayout showBreadcrumb={false} sessionExpiration={false}>
      {isShowMfa ? (
        <MfaPage onMfaPageEventChange={onMfaPageEventChange} mfaPatientData={mfaInterviewData} />
      ) : !(interviewResultId || hasError) ? (
        <Loading />
      ) : (
        <RoundedContainer sx={{ mt: 2 }}>
          <Box sx={{ py: 3 }}>
            {hasError ? (
              <Typography variant="h1">{pageErrors[0].title}</Typography>
            ) : (
              renderLandingHeader()
            )}
          </Box>
          <Box sx={{ mb: 1, ...landingTextStyle() }}>
            {hasError ? (
              <Typography variant="body1">{pageErrors[0].body}</Typography>
            ) : (
              renderLandingText()
            )}
          </Box>
          <Box
            sx={{
              py: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: matches ? 'column' : 'row-reverse',
            }}
          >
            {!hasError && (
              <StyledButton
                type="button"
                variant="contained"
                onClick={() => {
                  dispatch(clearCredentials());
                  dispatch(setInterviewMode({ interviewMode: InterviewMode.EmailPatient }));
                  const interviewConductUrl = buildInterviewConductUrl(
                    reportType!,
                    InterviewMode.EmailPatient,
                    { interviewResultId: interviewResultId! + '' }
                  );
                  navigate(interviewConductUrl);
                }}
              >
                {resumeInterview
                  ? t('interview.emailPatientInterview.body.submit_existing')
                  : t('interview.emailPatientInterview.body.submit_new')}
              </StyledButton>
            )}
          </Box>
        </RoundedContainer>
      )}
    </DashboardLayout>
  );
}
