import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import {
  StyledRadioCheckIcon,
  StyledRadioIcon,
} from '../../../interview/questions/SdsScaleQuestion';

const possibleNumberAnswers = ['0', '1', '2', '3', '4'];

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

const StyleLayoutRadio = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px 10px',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '8px',
  alignItems: 'center',
}));

export default function YBocsScaleAnswer({
  questionAnswer: yBocsQuestionAnswer,
}: QuestionAnswerProps) {
  const { t } = useTranslation();
  const stsMeasurement: string[] = Object.values(
    t('interview.form.sts.stsScale', { returnObjects: true })
  );

  const renderAnswers = () => {
    const questionAnswerForYBocs = {
      ...yBocsQuestionAnswer,
      possibleAnswers: yBocsQuestionAnswer?.possibleAnswers?.slice(1),
      questionAnswer: !!yBocsQuestionAnswer.questionAnswer
        ? yBocsQuestionAnswer.questionAnswer
        : '0',
      fillPlaceholder: !!yBocsQuestionAnswer.fillPlaceholder
        ? yBocsQuestionAnswer.fillPlaceholder.split(',')
        : [],
    };

    return possibleNumberAnswers.map((item: string) => {
      return (
        <StyleLayoutRadio>
          <Box>
            <Typography fontSize={'14px'} fontWeight={'bold'} textAlign={'center'}>
              {questionAnswerForYBocs.possibleAnswers
                ? questionAnswerForYBocs.possibleAnswers[parseInt(item)]
                : stsMeasurement[parseInt(item)]}
            </Typography>
            <Typography fontSize={'12px'} fontWeight={500} textAlign={'center'}>
              {questionAnswerForYBocs.fillPlaceholder[parseInt(item)] || ''}
            </Typography>
          </Box>

          {questionAnswerForYBocs.questionAnswer === item ? (
            <StyledRadioCheckIcon>{item}</StyledRadioCheckIcon>
          ) : (
            <StyledRadioIcon>{item}</StyledRadioIcon>
          )}
        </StyleLayoutRadio>
      );
    });
  };

  return (
    <Box
      key={yBocsQuestionAnswer.questionId}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        pageBreakInside: 'avoid',
        alignItems: 'center',
        columnGap: 1,
        px: 1,
        my: 3,
        border: `1px solid ${theme.xPalette.black}`,
      }}
    >
      <Box sx={{ flex: 2 }}>
        <Typography component={'span'} fontWeight={500}>
          {yBocsQuestionAnswer.title || ''}
        </Typography>
      </Box>
      <Box sx={{ marginY: '16px!important', flex: 3, display: 'flex' }}>{renderAnswers()}</Box>
    </Box>
  );
}
