import { JSXElementConstructor, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { useGetInterviewReportByTokenQuery } from '../../app/services/interview';
import { useGetMonitorReportByTokenQuery } from '../../app/services/monitor';
import { reportTheme } from '../../app/theme';
import Loading from '../../common/components/Loading';
import DashboardLayout from '../../common/layouts/Dashboard';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { AssessmentReport } from '../../types/ReportType';
import ErrorPage from '../error/ErrorPage';
import { InterviewReportType } from '../interview/interviewUtils';
import MINIReportPage from './reportPages/MINIReportPage';
import MINIScreenerReportPage from './reportPages/MINIScreenerReportPage';
import PHQReportPage from './reportPages/PHQReportPage';
import SdsStsMonitorReportPage from './reportPages/SdsStsMonitorReportPage';
import SdsStsReportPage from './reportPages/SdsStsReportPage';
import YbocsCybocsReportPage from './reportPages/YbocsCybocsReportPage';
import InterviewResultTemplate from './template/InterviewResultTemplate';

// Implement more interview report type
const componentMap: { [type: string]: JSXElementConstructor<AssessmentReport> } = {
  [InterviewReportType.MINI]: MINIReportPage,
  [InterviewReportType.Screener]: MINIScreenerReportPage,
  [InterviewReportType.SdsSts]: SdsStsReportPage,
  [InterviewReportType.PHQ]: PHQReportPage,
  [InterviewReportType.Monitor]: SdsStsMonitorReportPage,
  [InterviewReportType.YBOCS]: YbocsCybocsReportPage,
  [InterviewReportType.CYBOCS]: YbocsCybocsReportPage,
};

export default function MainReportPage() {
  const interviewContainerRef = useRef<HTMLDivElement>(null);
  const watermarkRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const token = searchParams?.get('token') || null;

  const isInterviewReport = pathname?.includes('interview-results');
  const isMonitorReport = pathname?.includes('monitor-results');

  const { data, error, isLoading } = useCustomAssessmentRequest(
    token,
    isInterviewReport,
    isMonitorReport
  );

  const questionNotes = {};
  if (data?.notes?.length) {
    data.notes.forEach((reportQuestionNote: any) => {
      if (Array.isArray(reportQuestionNote.notes) && reportQuestionNote.notes?.length) {
        reportQuestionNote.notes.forEach((reportQuestionNoteDetail: any) => {
          const currentNoteList = questionNotes[reportQuestionNoteDetail.questionId] || [];
          currentNoteList.push({
            ...reportQuestionNoteDetail,
            interviewer: reportQuestionNote.interviewer,
            version: reportQuestionNote.version,
          });
          questionNotes[reportQuestionNoteDetail.questionId] = currentNoteList;
        });
      } else {
        const currentNoteList = questionNotes[reportQuestionNote.questionId] || [];
        currentNoteList.push(reportQuestionNote);
        questionNotes[reportQuestionNote.questionId] = currentNoteList;
      }
    });
  }

  useEffect(() => {
    if (data && data?.assessmentResult?.trial) {
      const reportHeight = interviewContainerRef.current?.offsetHeight;
      const reportWidth = interviewContainerRef.current?.offsetWidth;

      // Create a canvas to use as our watermark image
      const canvasHeight = 300;
      const canvasWidth = 1000;
      const canvas = document.createElement('canvas');
      canvas.height = canvasHeight;
      canvas.width = canvasWidth;
      const context = canvas.getContext('2d');

      // Create the watermark text
      const text = 'THIS IS A TRIAL';
      const fontSize = 100;
      if (context) {
        context.fillStyle = 'rgba(0,0,0,0.4)';
        context.font = fontSize + 'px sans-serif';
        context.rotate((-15 * Math.PI) / 180);
        context.fillText(text, 0, canvasHeight, canvasWidth);
      }

      // Set the created watermark as the background image for repeated use
      const watermarkElement = watermarkRef.current;

      if (watermarkElement) {
        watermarkElement.style.height = `${reportHeight}px`;
        watermarkElement.style.width = `${reportWidth}px`;
        watermarkElement.style.backgroundImage = `url(' ${canvas.toDataURL('image/png')} ')`;
        watermarkElement.style.backgroundRepeat = 'repeat-y';
        watermarkElement.style.backgroundSize = 'contain';
      }
    }
  }, [data]);

  if (
    !token ||
    error ||
    (isInterviewReport && isMonitorReport) ||
    !(isInterviewReport || isMonitorReport)
  ) {
    return <ErrorPage statusCode={HttpStatusCode.NotFound} />;
  }

  const renderInterviewReport = () => {
    const InterviewReportComponent = data?.assessment?.reportType
      ? data?.assessment?.reportType === InterviewReportType.Screener &&
        data?.assessment?.subReportType === InterviewReportType.PHQ
        ? componentMap[InterviewReportType.PHQ]
        : componentMap[data?.assessment?.reportType]
      : null;

    if (!InterviewReportComponent) {
      return (
        <Typography color="red">
          *** Report {data?.assessment?.reportType} is not implement yet!
        </Typography>
      );
    }
    return <InterviewReportComponent {...data} questionNotes={questionNotes} />;
  };

  return (
    <ThemeProvider theme={reportTheme}>
      <CssBaseline />
      <DashboardLayout
        showBreadcrumb={false}
        showCopyright={false}
        showHeader={false}
        background={reportTheme.xPalette.white}
      >
        <Helmet>
          <title>{getPageTitle(t('assessmentReport.title'))}</title>
        </Helmet>
        <Container ref={interviewContainerRef} sx={{ width: '900px' }} disableGutters={true}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Box
                ref={watermarkRef}
                sx={{ position: 'absolute', zIndex: '1', left: '0', right: '0' }}
              ></Box>
              {data?.interviewResultTemplate ? (
                <InterviewResultTemplate template={data.interviewResultTemplate} />
              ) : (
                renderInterviewReport()
              )}
            </>
          )}
        </Container>
      </DashboardLayout>
    </ThemeProvider>
  );
}

const useCustomAssessmentRequest = (
  token: string | null,
  isInterviewReport?: boolean,
  isMonitorReport?: boolean
): { data: any; error: any; isLoading: boolean } => {
  const {
    data: interviewReport,
    error: interviewError,
    isLoading: interviewIsLoading,
  } = useGetInterviewReportByTokenQuery(isInterviewReport && token ? { token } : skipToken);

  const {
    data: monitorReport,
    error: monitorError,
    isLoading: monitorIsLoading,
  } = useGetMonitorReportByTokenQuery(isMonitorReport && token ? { token } : skipToken);

  return {
    data: interviewReport || monitorReport,
    error: interviewError || monitorError,
    isLoading: interviewIsLoading || monitorIsLoading,
  };
};
