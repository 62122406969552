import { ChangeEvent } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';

const StyledDisabledCheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 1,
  width: '17px',
  height: '17px',
  boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.3)',

  'input:disabled ~ &': {
    background: theme.xPalette.disabled,
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    width: '45px',
    height: '45px',
  },
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 27,
  },
}));

const StyledItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '40px',
  color: theme.xPalette.lightGrey,
  backgroundColor: theme.xPalette.white,
  padding: '8px 16px',
  fontWeight: theme.typography.h1.fontWeight,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    minHeight: '70px',
    padding: '8px 24px',
  },
}));

const StyledCheckListAnswerItemBox = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.h1.fontWeight,
  borderBottom: 0,
  width: '10%',
  minWidth: '50px',
  textAlign: 'center',
}));

const StyledQuestionNameHeader = styled(Box)(() => ({
  width: '70%',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginLeft: '16px',
  marginRight: '16px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: '24px',
    marginRight: '24px',
  },
}));

export default function YbocsCheckListQuestion({
  question,
  field,
  error,
  setValue,
  disabled,
  isParentQuestion,
  checkListChangeCallback,
}: InterviewQuestionProps) {
  const theme = useTheme();
  const principal = 'principal';
  const current = 'current';
  const past = 'past';
  const savedValue = field?.value;
  const savedValues = Array.isArray(savedValue)
    ? savedValue
    : (savedValue && savedValue?.split('|')) || [];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let temp = [];
    const checkExistValue = savedValues.includes(value);
    if (!checkExistValue) {
      temp = [...savedValues, value];
      setValue(field?.name!, temp.join('|'));
    } else {
      temp = [...savedValues.filter((item: string) => item !== value)];
      temp = temp.length === 1 && temp[0] === principal ? [] : temp;
      setValue(field?.name!, temp.join('|'));
    }

    if (checkListChangeCallback) {
      // execute parent callback
      const disabled = !(temp.includes(current) || temp.includes(past));
      checkListChangeCallback(field?.name?.split('_')[1], disabled);
    }
  };

  const renderSubItems = () => {
    const items = [];
    for (let i = 0; i < question.possibleAnswers.length; i++) {
      const value = question.possibleAnswers[i];
      const checked = savedValues.includes(value);
      const displayAnswer = question.displayAnswers[i];
      items.push(
        <Box
          key={`${field?.name}_${value}`}
          sx={{
            display: 'flex',
          }}
        >
          <FormControl error={error} onChange={handleChange} variant="standard">
            <FormGroup>
              <FormControlLabel
                sx={{ mx: 0.5 }}
                value={value}
                control={
                  <StyledCheckbox
                    checked={checked && !disabled}
                    disabled={disabled}
                    icon={disabled ? <StyledDisabledCheckboxIcon /> : <CheckBoxOutlineBlankIcon />}
                  />
                }
                label={displayAnswer}
              />
            </FormGroup>
          </FormControl>
        </Box>
      );
    }
    return (
      <>
        <Box
          sx={{
            backgroundColor: theme.xPalette.white,
            pl: 1,
            pb: 1,
            mt: -1,
            [theme.breakpoints.up('sm')]: {
              pl: 2,
              mt: -2,
            },
          }}
        >
          {items}
        </Box>
        <StyledDivider />
      </>
    );
  };

  const renderItems = () => {
    const items = [];
    for (let i = 0; i < question.possibleAnswers.length; i++) {
      const value = question.possibleAnswers[i];
      const checked = savedValues.includes(value);
      const disabledPrincipal =
        value === principal && !savedValues.includes(current) && !savedValues.includes(past);
      items.push(
        <StyledCheckListAnswerItemBox key={`${field?.name}_${value}`}>
          <FormControl error={error} onChange={handleChange} variant="standard">
            <FormGroup>
              <FormControlLabel
                sx={{ mx: 0 }}
                value={value}
                control={
                  <StyledCheckbox
                    checked={checked && !disabled}
                    disabled={disabledPrincipal || disabled}
                    icon={
                      disabledPrincipal || disabled ? (
                        <StyledDisabledCheckboxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )
                    }
                  />
                }
                label=""
              />
            </FormGroup>
          </FormControl>
        </StyledCheckListAnswerItemBox>
      );
    }
    return (
      <>
        <StyledItemWrapper>
          <StyledQuestionNameHeader>
            <Typography
              component="span"
              dangerouslySetInnerHTML={{
                __html: question.name!,
              }}
              sx={{
                color: theme.xPalette.black,
                [theme.breakpoints.up('md')]: {
                  fontSize: '20px',
                },
              }}
            ></Typography>
          </StyledQuestionNameHeader>
          {items}
        </StyledItemWrapper>
        {!isParentQuestion && <StyledDivider />}
      </>
    );
  };

  return question.relatedQuestionIdForAnswerValues ? renderSubItems() : renderItems();
}
