import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AssessmentMode } from './assessmentMode';

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

export const endDateValidation = ({
  assessmentMode,
  interval,
  timezone,
  startDate,
  endDate,
}: {
  assessmentMode: string;
  interval: number;
  timezone?: string;
  startDate?: any;
  endDate: any;
}) => {
  let suggestDate = '';
  if (assessmentMode === AssessmentMode.Email && interval > 0) {
    const currentStartDate = dayjs(startDate).tz(timezone).startOf('day'); // only keep day

    let newDate: any;
    const intervals = [1, 7, 14, 30, 90, 180, 365]; // interval
    if (interval >= 1 && interval <= intervals.length) {
      // Check if have more 30 days
      if (interval === 4) {
        // 30 days
        newDate = currentStartDate.add(1, 'month').startOf('day').valueOf();
      } else if (interval === 5) {
        // 90 days
        newDate = currentStartDate.add(3, 'month').startOf('day').valueOf();
      } else if (interval === 6) {
        // 180 days
        newDate = currentStartDate.add(6, 'month').startOf('day').valueOf();
      } else if (interval === 7) {
        // 365 days
        newDate = currentStartDate.add(1, 'year').startOf('day').valueOf();
      } else {
        // fewer 30 days
        newDate = currentStartDate
          .add(intervals[interval - 1], 'day')
          .startOf('day')
          .valueOf();
      }
    }

    const endDateNewFormat = dayjs(endDate).tz(timezone).startOf('day').valueOf();

    // Compare only day
    if (endDateNewFormat < newDate) {
      const newDay = dayjs(newDate).date();
      const newMonth = dayjs(newDate).month() + 1;
      const newYear = dayjs(newDate).year();

      suggestDate = `${String(newMonth).padStart(2, '0')}/${String(newDay).padStart(
        2,
        '0'
      )}/${newYear}`;
    }
  }
  return suggestDate;
};
