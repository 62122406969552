import { JSXElementConstructor } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AssessmentMode } from '../../../common/utils/assessmentMode';
import { QuestionAnswerType } from '../../../common/utils/questionAnswerTypes';
import { QuestionAnswer, QuestionAnswerProps } from '../../../types/ReportType';
import { InterviewReportType } from '../../interview/interviewUtils';
import ReportNotes from './ReportNotes';
import BinaryAnswer from './questionAnswers/BinaryAnswer';
import FormulaAnswer from './questionAnswers/FormulaAnswer';
import GridAnswer from './questionAnswers/GridAnswer';
import HeightAnswer from './questionAnswers/HeightAnswer';
import InfoNoAnswer from './questionAnswers/InfoNoAnswer';
import InfoNoAnswerNoUI from './questionAnswers/InfoNoAnswerNoUI';
import MonitorFormulaAnswer from './questionAnswers/MonitorFormulaAnswer';
import MultiSelectListAnswer from './questionAnswers/MultiSelectListAnswer';
import NumberAnswer from './questionAnswers/NumberAnswer';
import OptionAnswer from './questionAnswers/OptionAnswer';
import PHQFormulaAnswer from './questionAnswers/PHQFormulaAnswer';
import QuestionnaireAnswer from './questionAnswers/QuestionnaireAnswer';
import SdsScaleAnswer from './questionAnswers/SdsScaleAnswers';
import StsEventAnswers from './questionAnswers/StsEventAnswers';
import StsScaleAnswer from './questionAnswers/StsScaleAnswers';
import TableAnswer from './questionAnswers/TableAnswer';
import TargetSymptomListTextAnswer from './questionAnswers/TargetSymptomListTextAnswer';
import TextAnswer from './questionAnswers/TextAnswer';
import WeightAnswer from './questionAnswers/WeightAnswer';
import YBocsScaleAnswer from './questionAnswers/YBocsScaleAnswer';
import YbocsCheckListAnswer from './questionAnswers/YbocsCheckListAnswer';
import YbocsCheckOtherAnswer from './questionAnswers/YbocsCheckOtherAnswer';
import YbocsExitConditionAnswer from './questionAnswers/YbocsExitConditionAnswer';
import ScoringSection from './scoringModules/ScoringSection';

//TODO: Implement more answer type
const componentMap: { [type: string]: JSXElementConstructor<QuestionAnswerProps> } = {
  [QuestionAnswerType.Binary]: BinaryAnswer,
  [QuestionAnswerType.Height]: HeightAnswer,
  [QuestionAnswerType.Weight]: WeightAnswer,
  [QuestionAnswerType.Text]: TextAnswer,
  [QuestionAnswerType.Options]: OptionAnswer,
  [QuestionAnswerType.Number]: NumberAnswer,
  [QuestionAnswerType.Formula]: FormulaAnswer,
  [QuestionAnswerType.Time]: NumberAnswer,
  [QuestionAnswerType.MultiOptions]: OptionAnswer,
  [QuestionAnswerType.SdsScale]: SdsScaleAnswer,
  [QuestionAnswerType.StsScale]: StsScaleAnswer,
  [QuestionAnswerType.StsEvent]: StsEventAnswers,
  [QuestionAnswerType.Questionnaire]: QuestionnaireAnswer,
  [QuestionAnswerType.MonitorFormula]: MonitorFormulaAnswer,
  [QuestionAnswerType.InfoNoAnswer]: InfoNoAnswer,
  [QuestionAnswerType.InfoNoAnswerNoUI]: InfoNoAnswerNoUI,
  [QuestionAnswerType.YbocsCheckList]: YbocsCheckListAnswer,
  [QuestionAnswerType.YbocsCheckOther]: YbocsCheckOtherAnswer,
  [QuestionAnswerType.YbocsScale]: YBocsScaleAnswer,
  [QuestionAnswerType.YbocsExitCondition]: YbocsExitConditionAnswer,
  [QuestionAnswerType.TargetSymptomListText]: TargetSymptomListTextAnswer,
  [QuestionAnswerType.Grid]: GridAnswer,
  [QuestionAnswerType.TimeDayMonth]: TextAnswer,
  [QuestionAnswerType.Table]: TableAnswer,
  [QuestionAnswerType.PHQFormula]: PHQFormulaAnswer,
  [QuestionAnswerType.MultiSelectList]: MultiSelectListAnswer,
  [QuestionAnswerType.Date]: TextAnswer,
};

//TODO: Update this any type
interface ReportModuleType {
  moduleTitle?: string;
  moduleLetter?: string;
  questionAnswers?: QuestionAnswer[];
  questionScores?: any[];
  questionNotes?: Record<string, any>;
  moduleType?: string;
  subTitle?: string;
  subModuleType?: string;
  assessmentType?: string;
  isHideModuleName?: boolean;
  assessmentName?: string;
}

export default function ReportModulePage({
  questionAnswers,
  questionScores,
  questionNotes,
  moduleType,
  subTitle,
  moduleLetter,
  moduleTitle,
  subModuleType,
  assessmentType,
  isHideModuleName,
  assessmentName,
}: ReportModuleType) {
  const getQuestionNotes = (questionId: number) => {
    if (!questionNotes || !questionId) return null;
    return questionNotes[questionId] ? questionNotes[questionId] : null;
  };

  return (
    <Box sx={{ mt: 5 }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'center',
          flexDirection: 'column',
          rowGap: 4,
        }}
      >
        {moduleType === InterviewReportType.SdsSts && (
          <>
            <Typography
              variant="h2"
              fontSize="30px!important"
              fontWeight={600}
              textAlign={'center'}
            >
              {moduleTitle}
            </Typography>
            <Typography variant="h3" fontSize="24px" fontWeight="bold" textAlign={'center'} mb={1}>
              {subTitle}
            </Typography>
          </>
        )}
        {moduleType === InterviewReportType.MINI && (
          <>
            <Typography
              id={`letter-${moduleLetter}`}
              variant="h2"
              fontWeight={600}
              textAlign={'center'}
            >
              {`${!!moduleLetter ? moduleLetter + '.' : ''} ${moduleTitle}`}
            </Typography>
          </>
        )}
        {moduleType === InterviewReportType.Screener && (
          <>
            <Typography
              id={`letter-${moduleLetter}`}
              variant="h2"
              fontWeight={600}
              textAlign={'center'}
            >
              {`${moduleLetter}. ${moduleTitle}`}
            </Typography>
          </>
        )}
        {moduleType === InterviewReportType.Monitor && !isHideModuleName && (
          <>
            <Typography
              variant="body1"
              width={'100%'}
              fontSize={18}
              fontWeight={600}
              textAlign={'left'}
            >
              {`${moduleTitle}`}
            </Typography>
          </>
        )}
        {(moduleType === InterviewReportType.YBOCS ||
          moduleType === InterviewReportType.CYBOCS) && (
          <>
            <Typography variant="h1" mb={2}>
              {`${moduleTitle}`}
            </Typography>
          </>
        )}
      </Box>

      <Box>
        {questionAnswers?.map((questionAnswer: QuestionAnswer) => {
          let QuestionComponent = componentMap[questionAnswer.questionAnswerType!];
          if (!QuestionComponent || !questionAnswer) {
            return (
              <Typography color="red">
                *** Type {questionAnswer.questionAnswerType} is not implement yet!
              </Typography>
            );
          }

          if (
            moduleType === InterviewReportType.Monitor &&
            questionAnswer.questionAnswerType === QuestionAnswerType.InfoNoAnswer &&
            !(
              subTitle === 'MINI Tracking 7.0.2' ||
              subTitle === 'MINI Tracking KID 7.0.2' ||
              (assessmentType === AssessmentMode.Clinician &&
                (subTitle === 'KID-B. SUICIDALITY (for ages 6 through 8)' ||
                  subTitle === 'KID-B. SUICIDALITY (for ages 9 through 12)' ||
                  subTitle === 'KID-B. SUICIDALITY (for ages 13 through 17)' ||
                  subTitle === 'ADULT-B. SUICIDALITY'))
            )
          ) {
            return null;
          }

          if (
            moduleType === InterviewReportType.Monitor &&
            questionAnswer.questionAnswerType === QuestionAnswerType.InfoNoAnswerNoUI &&
            !(
              subTitle === 'KID-B. SUICIDALITY (for ages 6 through 8)' ||
              subTitle === 'KID-B. SUICIDALITY (for ages 9 through 12)' ||
              subTitle === 'KID-B. SUICIDALITY (for ages 13 through 17)' ||
              subTitle === 'KID-B. SUICIDALITY (for ages 9 through 12)' ||
              subTitle === 'ADULT-B. SUICIDALITY'
            )
          ) {
            return null;
          }

          if (
            questionAnswer.questionAnswerType === QuestionAnswerType.Formula &&
            moduleType === InterviewReportType.Monitor
          ) {
            QuestionComponent = componentMap[QuestionAnswerType.MonitorFormula];
          }

          if (
            questionAnswer.questionAnswerType === QuestionAnswerType.Formula &&
            moduleType === InterviewReportType.Screener &&
            subModuleType === InterviewReportType.PHQ
          ) {
            QuestionComponent = componentMap[QuestionAnswerType.PHQFormula];
          }

          if (
            (questionAnswer.questionAnswerType === QuestionAnswerType.InfoNoAnswer ||
              questionAnswer.questionAnswerType === QuestionAnswerType.YbocsExitCondition) &&
            (moduleType === InterviewReportType.YBOCS || moduleType === InterviewReportType.CYBOCS)
          ) {
            return null;
          }

          return (
            <Box
              key={questionAnswer.questionId}
              sx={{
                pageBreakInside: 'avoid',
              }}
            >
              <QuestionComponent
                questionAnswer={questionAnswer}
                moduleType={moduleType}
                subModuleType={subModuleType}
                assessmentType={assessmentType}
                assessmentName={assessmentName}
                notes={getQuestionNotes(questionAnswer?.questionId || 0)}
              />
            </Box>
          );
        })}
      </Box>

      {/* Notes section component */}
      {(moduleType === InterviewReportType.Screener || moduleType === InterviewReportType.PHQ) &&
        questionNotes &&
        Object.values(questionNotes)[0] && (
          <ReportNotes reportNotes={Object.values(questionNotes)[0]} />
        )}

      {/* Scoring section component */}
      {questionScores?.map((questionScore: any) => (
        <ScoringSection
          key={questionScore.id}
          scoringLineItems={questionScore?.interviewModuleScoringLineItems}
          scoringSectionTitle={questionScore?.title}
        />
      ))}
    </Box>
  );
}
