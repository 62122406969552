import { useState } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import theme from '../../app/theme';

export const enhancedStyles: { [key: string]: SxProps<Theme> } = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.action.active,
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    left: '12px',
    top: '32px',
  },
  textField: {
    '& label': {
      pl: 4,
    },
    '& input': {
      pl: 6,
    },
    '& .Mui-focused': {
      pl: 0,
    },
    '& .MuiFormLabel-filled': {
      pl: 0,
    },
  },
  dropdown: {
    width: '100%',
    '& label': {
      pl: 4,
    },
    '& input': {
      pl: '48px!important',
    },
    '& .Mui-focused': {
      pl: 0,
    },
    '& .MuiFormLabel-filled': {
      pl: 0,
    },
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  labelWrapper: {
    mt: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  }
};

export function ControlledTextField<TControl>({
  control,
  fieldError,
  label,
  name,
  icon,
  inputType,
  forPassword,
  multiline,
  rowNumber,
  isDefaultStyle,
  isSetVariantFilled,
  maxLengthNumber,
}: {
  control: Control<TControl, object>;
  fieldError: FieldError | undefined;
  label: string;
  name: any;
  inputType?: string;
  icon?: JSX.Element;
  forPassword?: boolean;
  multiline?: boolean;
  rowNumber?: number;
  isDefaultStyle?: boolean;
  isSetVariantFilled?: boolean;
  maxLengthNumber?: number;
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const type = forPassword ? (showPassword ? 'text' : 'password') : inputType ?? 'text';

  return (
    <Box sx={enhancedStyles.wrapper}>
      <Box sx={enhancedStyles.icon}>{icon}</Box>
      <Controller
        name={name}
        defaultValue={'' as any}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!fieldError}
            helperText={fieldError?.message}
            margin="normal"
            fullWidth
            label={label}
            variant="outlined"
            type={type}
            sx={(isDefaultStyle && !isSetVariantFilled) ?
              {'&.MuiTextField-root > p:first-of-type':{marginRight: '0px',marginLeft: '0px'}}
              : (isDefaultStyle && isSetVariantFilled) ?
              {backgroundColor: 'rgba(0, 0, 0, 0.04)',
              '&.MuiTextField-root > p:first-of-type': {backgroundColor: 'white',
              marginRight: '0px',
              marginLeft: '0px'
            }}
              : enhancedStyles.textField}
            multiline={multiline}
            rows={rowNumber ? rowNumber : 1}
            inputProps={maxLengthNumber ? {maxLength :maxLengthNumber} : undefined}
            InputProps={
              icon && {
                endAdornment: forPassword && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            }
          />
        )}
      />
    </Box>
  );
}
