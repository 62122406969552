import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { AssessmentMode } from '../../../../common/utils/assessmentMode';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function MonitorFormulaAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const { answerValue } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <Box
      key={questionAnswer.questionId}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 1,
        width: '100%',
        pageBreakInside: 'avoid',
        my: 1,
      }}
    >
      {!(
        (assessmentType === AssessmentMode.Clinician &&
          !questionAnswer.isClinicianOnly &&
          questionAnswer.isPatientOnly) ||
        ((assessmentType === AssessmentMode.Patient ||
          assessmentType === AssessmentMode.Schedule) &&
          questionAnswer.isClinicianOnly &&
          !questionAnswer.isPatientOnly)
      ) && (
        <>
          <Box sx={{ flex: 5, textAlign: 'right' }}>
            <Typography component={'span'} fontWeight={'bold'}>
              {questionAnswer.title || ''}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              sx={{
                width: '10%',
                textAlign: 'right',
                py: 1,
                px: 4,
                border: `1px solid ${theme.xPalette.black}`,
                fontWeight: 'bold',
              }}
            >
              {answerValue || ''}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
