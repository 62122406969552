import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as SiteIconSvg } from '../../../assets/icons/sites.svg';

export default function SiteIcon() {
  return (
    <SvgIcon aria-label="site-icon" fontSize="large" fill="white" viewBox='0 -3 24 24' sx={{width: '30px'}}>
      <SiteIconSvg />
    </SvgIcon>
  );
}
