import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as EditPatientIconSvg } from '../../../assets/icons/edit-patient.svg';

export default function EditPatientIcon() {
  const theme = useTheme();
  return (
    <SvgIcon
      component={EditPatientIconSvg}
      sx={{ '& path': { fill: 'white', stroke: theme.xPalette.shadeGrey } }}
    />
  );
}
