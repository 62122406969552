import { ObjectiveItem } from './ObjectiveType';

export enum GoalProgress {
  NotStarted = 'notStarted',
  Minimal = 'minimal',
  Moderate = 'moderate',
  Good = 'good',
  Excellent = 'excellent',
}

export enum GoalStatus {
  NotStarted = 'notStarted',
  Active = 'active',
  Completed = 'completed',
  Inactive = 'inactive',
  Deferred = 'deferred',
}

export interface GoalItem {
  id: number;
  treatmentPlanId: number;
  startDate: Date | any;
  createdAt: string;
  diagnoses: string;
  description: string;
  status: GoalStatus;
  statusDate: Date | any;
  progress: GoalProgress;
  objectives?: ObjectiveItem[];
}

export interface GoalListRequest {
  treatmentPlanId: number;
}

export interface GoalListReponse {
  data: GoalItem[];
  totalItems: number;
}

export interface GoalAddRequest extends GoalItem {}

export interface GoalAddResponse {
  data: GoalItem;
}

export interface GoalEditRequest extends GoalItem {}

export interface GoalEditResponse {
  data: GoalItem;
}
