import {
  buildAccountUrl,
  buildInterviewUrl,
  QueryCacheDuration,
  rootApi,
  TagIds,
  TagTypes,
} from '.';
import {
  AddOrEditPatientDiagnosisRequest,
  AddOrEditPatientDiagnosisResponse,
  CommonPatientRequest,
  CreatePatientNoteResponse,
  DiagnosesResponse,
  DiagnosisItemsResponse,
  PatientDetailRequest,
  PatientDiagnosisInfo,
  PatientEditRequest,
  PatientInfo,
  PatientNoteRequest,
  PatientNoteResponse,
  ProviderRequest,
  ProviderResponse,
} from '../../types/PatientRequest';
import { CheckShowPopupIntakePacketResponse } from '../../types/PatientType';

const excludeEmptyFields = (obj: any) => {
  const excludedFields = ['birthdate', 'country', 'gender', 'race'];
  const newObj = { ...obj };
  excludedFields.forEach((k) => {
    if (newObj[k] === '') {
      delete newObj[k];
    }
  });
  return newObj;
};

const api = rootApi
  .enhanceEndpoints({ addTagTypes: [TagTypes.Patient, TagTypes.InterviewTypes] })
  .injectEndpoints({
    endpoints: (builder) => ({
      addPatient: builder.mutation<PatientInfo, PatientInfo>({
        query: (patientInfo) => ({
          url: buildAccountUrl('/v1/account/patient'),
          method: 'POST',
          body: excludeEmptyFields(patientInfo),
        }),
        invalidatesTags: (_restul, error) =>
          error ? [] : [{ type: TagTypes.Patient, id: TagIds.List }],
      }),

      editPatient: builder.mutation<void, PatientEditRequest>({
        query: ({ patientId, data }) => ({
          url: buildAccountUrl(`/v1/account/patient`),
          method: 'PUT',
          body: { ...excludeEmptyFields(data), patientId },
        }),
        // TODO (for future): consider adding Optimistic Updates​ to enhance user's experience
        // see https://redux-toolkit.js.org/rtk-query/usage/examples#react-optimistic-updates
        invalidatesTags: (_result, error, arg) =>
          error
            ? []
            : [
                { type: TagTypes.Patient, id: arg.patientId },
                { type: TagTypes.InterviewTypes, id: arg.patientId },
              ],
      }),

      conductInterviewPatientAutoIntakePacket: builder.mutation<any, { patientId: string }>({
        query: (body) => ({
          url: buildInterviewUrl(`/v1/interview/conduct-interview-patient-auto-intake-packet`),
          method: 'POST',
          body,
        }),
      }),

      getPatient: builder.query<PatientInfo, PatientDetailRequest>({
        query: ({ patientId, isWriteLog }) => ({
          url: isWriteLog
            ? buildAccountUrl(`/v1/account/patient?patientId=${patientId}&isWriteLog=${isWriteLog}`)
            : buildAccountUrl(`/v1/account/patient?patientId=${patientId}`),
          method: 'GET',
        }),
        providesTags: (_result, _error, arg) => [{ type: TagTypes.Patient, id: arg.patientId }],
      }),

      getPatientNotes: builder.query<PatientNoteResponse, PatientDetailRequest>({
        query: ({ patientId }) => ({
          url: buildAccountUrl(`/v1/account/patient/patient-notes?patientId=${patientId}`),
          method: 'GET',
        }),
        providesTags: (_result, _error, arg) => [{ type: TagTypes.Patient, id: arg.patientId }],
      }),

      deletePatient: builder.mutation<void, PatientDetailRequest>({
        query: ({ patientId }) => ({
          url: buildAccountUrl(`/v1/account/patient`),
          method: 'DELETE',
          body: { patientId },
        }),
        invalidatesTags: (_result, error, arg) =>
          error
            ? []
            : [
                { type: TagTypes.Patient, id: TagIds.List },
                { type: TagTypes.Patient, id: arg.patientId },
              ],
      }),

      updateStatusPatient: builder.mutation<void, PatientDetailRequest>({
        query: ({ patientId, status }) => ({
          url: buildAccountUrl('/v1/account/patient/update-status'),
          method: 'POST',
          body: { patientId, status },
        }),
        invalidatesTags: (_result, error, arg) =>
          error ? [] : [{ type: TagTypes.Patient, id: arg.patientId }],
      }),

      createPatientNote: builder.mutation<CreatePatientNoteResponse, PatientNoteRequest>({
        query: ({ patientId, title, body }) => ({
          url: buildAccountUrl('/v1/account/patient/create-patient-note'),
          method: 'POST',
          body: { patientId, title, body },
        }),
        invalidatesTags: (_result, error, arg) =>
          error ? [] : [{ type: TagTypes.Patient, id: arg.patientId }],
      }),

      getDiagnoses: builder.query<DiagnosesResponse, CommonPatientRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/patient/diagnoses'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getPatientDiagnoses: builder.query<DiagnosisItemsResponse, CommonPatientRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/patient/patient-diagnoses'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getPatientDiagnosisInfo: builder.query<PatientDiagnosisInfo, CommonPatientRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/patient/patient-diagnosis-info'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      addOrEditPatientDiagnosis: builder.mutation<
        AddOrEditPatientDiagnosisResponse,
        AddOrEditPatientDiagnosisRequest
      >({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/patient/add-or-edit-patient-diagnosis'),
          method: 'POST',
          body,
        }),
      }),

      deletePatientDiagnosis: builder.mutation<
        AddOrEditPatientDiagnosisResponse,
        CommonPatientRequest
      >({
        query: (body) => ({
          url: buildAccountUrl('/v1/account/patient/delete-patient-diagnosis'),
          method: 'POST',
          body,
        }),
      }),

      getProviders: builder.query<ProviderResponse, ProviderRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/patient/get-providers'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getReportInfo: builder.query<any, any>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/patient-report/get-report-info'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getReportInfoForDownload: builder.query<any, any>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/patient-report/get-report-info-for-download'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getManagementReportInfo: builder.query<any, any>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/management-report/get-report-info'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getManagementReportInfoForDownload: builder.query<any, any>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/management-report/get-report-info-for-download'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getReportToken: builder.query<any, any>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/patient-report/get-report-token'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      getManagementReportToken: builder.query<any, any>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/management-report/get-report-token'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      checkShowIntakePacketPopup: builder.query<
        CheckShowPopupIntakePacketResponse,
        { patientId: string }
      >({
        query: (body) => ({
          url: buildInterviewUrl('/v1/interview/check-show-restart-interview-popup'),
          method: 'POST',
          body,
        }),
      }),
    }),
  });

export const {
  useAddPatientMutation,
  useGetPatientQuery,
  useEditPatientMutation,
  useDeletePatientMutation,
  useUpdateStatusPatientMutation,
  useGetPatientNotesQuery,
  useCreatePatientNoteMutation,
  useGetDiagnosesQuery,
  useGetPatientDiagnosesQuery,
  useGetPatientDiagnosisInfoQuery,
  useAddOrEditPatientDiagnosisMutation,
  useDeletePatientDiagnosisMutation,
  useConductInterviewPatientAutoIntakePacketMutation,
  useGetProvidersQuery,
  useGetReportInfoQuery,
  useGetReportInfoForDownloadQuery,
  useGetReportTokenQuery,
  useGetManagementReportInfoForDownloadQuery,
  useGetManagementReportInfoQuery,
  useGetManagementReportTokenQuery,
  useCheckShowIntakePacketPopupQuery,
} = api;
