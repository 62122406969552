import { useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { capitalizeFirstLetter } from '../../../common/utils/stringUtils';
import { MonitorSubQuestionProps } from '../../../types/MonitorTypes';

export const StyledFormLabel = styled(FormControlLabel)(() => ({
  display: 'flex',
  flexDirection: 'row',
  margin: 0,
  width: '100%',
}));

export const StyledFormGroup = styled(FormGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  marginLeft: '8px',
}));

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.xPalette.completed.color,
  },
}));

export default function MultiOptions({
  possibleAnswers,
  onChangeCallBack,
  subQuestionId,
  questionText,
  isRequired,
  error,
  helperText,
}: MonitorSubQuestionProps) {
  const theme = useTheme();
  const [multiOptions, setMultiOptions] = useState<string[]>([]);

  const renderItems = () => {
    const items = [];
    if (possibleAnswers && possibleAnswers.length > 0) {
      for (let i = 0; i < possibleAnswers.length; i++) {
        const value = possibleAnswers[i];
        const text = possibleAnswers[i].trim();
        items.push(
          <StyledFormLabel key={value} value={value} control={<StyledCheckBox />} label={text} />
        );
      }
    }
    return items;
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    const checkExistValue = multiOptions.includes(value);
    let subAnswer = [];

    if (checkExistValue) {
      subAnswer = [...multiOptions.filter((item) => item !== value)];
      setMultiOptions([...multiOptions.filter((item) => item !== value)]);
    } else {
      subAnswer = [...multiOptions, value];
      setMultiOptions([...multiOptions, value]);
    }

    if (onChangeCallBack) {
      onChangeCallBack(subAnswer, subQuestionId, isRequired);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        [theme.breakpoints.down('lg')]: {
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ pt: 1 }}>
        <Typography
          dangerouslySetInnerHTML={{
            __html: capitalizeFirstLetter(questionText!),
          }}
        ></Typography>
      </Box>
      <FormControl error={error} onChange={handleChange} variant="standard">
        <StyledFormGroup>{renderItems()}</StyledFormGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
