import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../../app/hooks';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectGridSelection } from '../interviewSlice';
import GridSections from './gridComponents/GridItem';

const StyledFormControl = styled(FormControl)(({ theme, error }) => ({
  margin: '16px 0px',
  width: '100%',
  alignItems: 'start',
  '&.MuiFormControl-root .MuiFormGroup-root .MuiFormControlLabel-root': {
    borderColor: error ? theme.palette.error.main : '',
  },

  '&.MuiFormControl-root .MuiFormGroup-root': {
    flexWrap: 'wrap-reverse',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    alignItems: 'center',
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

const StyledSectionTitle = styled(Typography)(() => ({
  fontWeight: 'bold',
  margin: '16px 0px',
}));

const StyledFactorTitle = styled(Typography)(({ theme }) => ({
  fontSize: '13px !important',
  textAlign: 'center',
  width: '8%',
  [theme.breakpoints.down('lg')]: {
    fontSize: '10px !important',
  },
}));

const StyledAddFactorButton = styled(Button)(({ theme }) => ({
  padding: '16px 0px',
  justifyContent: 'start',
  fontSize: '16px',
  textTransform: 'none',
  borderColor: theme.xPalette.white,
}));

function GridQuestion({ question, field, error, helperText, setValue }: InterviewQuestionProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [questionData, setQuestionData] = useState<any>();
  const [questionOptions, setQuestionOptions] = useState<any>();
  const isInitialLoad = useRef(true);
  const isDataBound = useRef(false);
  const debounce = useRef(true);
  const gridSelection = useAppSelector(selectGridSelection);
  const [newFactors, setNewFactors] = useState<any>([]);
  const [previousAnswers, setPreviousAnswers] = useState<any>({});
  const factorOptionsTitle = questionData && questionData.options;
  const savedValue = field?.value;

  useEffect(() => {
    if (gridSelection) {
      setPreviousAnswers(gridSelection);
    }
  }, [gridSelection]);

  useEffect(() => {
    if (question.questionText && isInitialLoad) {
      isInitialLoad.current = false;
      const data = JSON.parse(question.questionText?.replace(/\\r\\n/g, '') || '');
      setQuestionData(data);

      // map to index-based values
      const optionArray = [];
      for (let index = 0; index < data.options.length; index++) {
        optionArray.push(index.toString());
      }
      setQuestionOptions(optionArray);
    }
  }, [question.questionText]);

  // Build form value when item is selected
  const fieldName = field?.name;
  useEffect(() => {
    if (fieldName && previousAnswers) {
      setPreviousAnswers(previousAnswers);
      const formValue = Object.keys(previousAnswers).reduce((preValue: any, key: any) => {
        const gridValue = previousAnswers[key].split('|');
        if (gridValue[1]) {
          return {
            ...preValue,
            [key]: { value: gridValue[0], factor: gridValue[1] },
          };
        }
        return {
          ...preValue,
          [key]: {
            value: previousAnswers[key],
          },
        };
      }, {});

      setValue(fieldName, JSON.stringify(formValue));
    }
  }, [previousAnswers, fieldName, setValue]);

  useEffect(() => {
    if (savedValue) {
      if (debounce.current) {
        debounce.current = false;
        const { sections } = JSON.parse(question.questionText?.replace(/\\r\\n/g, '') || '');
        const defaultNumberOfGridQuestions = sections[sections.length - 1].questions;
        const lastNumberOfDefaultGridQuestions =
          defaultNumberOfGridQuestions[defaultNumberOfGridQuestions.length - 1].title;
        const previousGridQuestions = Object.keys(JSON.parse(savedValue.split('|')));

        const savedFactors: any[] = [];
        previousGridQuestions.forEach((item: string) => {
          if (parseInt(item) > parseInt(lastNumberOfDefaultGridQuestions)) {
            newFactors.push({
              title: item,
              text: 'Other:',
              isOther: 'true',
            });
          }
        });

        setNewFactors([...newFactors, ...savedFactors]);
      }
    }
  }, [gridSelection, savedValue, question.questionText, questionData, newFactors]);

  const handleAddNewFactor = () => {
    const { sections } = questionData;
    const addingSection = sections[sections.length - 1].questions;
    const firstNewFactorIndex = (
      parseInt(addingSection[addingSection.length - 1].title) + 1
    ).toString();
    const nextFactorIndex =
      newFactors.length > 0 && (parseInt(newFactors[newFactors.length - 1].title) + 1).toString();

    const newFactor = {
      title: nextFactorIndex || firstNewFactorIndex,
      text: 'Other:',
      isOther: 'true',
    };

    setNewFactors([...newFactors, newFactor]);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {question.name && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.name,
          }}
        ></StyledTypography>
      )}
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <StyledFormControl error={error} variant="standard">
        {questionData &&
          questionData.sections.map((section: any) => {
            const selectedOption = (previousAnswers && previousAnswers[section.title]) || {};
            return (
              <Box key={section.title} sx={{ width: '100%' }}>
                {!matches && (
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: theme.xPalette.background,
                      display: 'flex',
                      my: 2,
                      py: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: '30%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      Factor
                    </Box>
                    <Box
                      sx={{
                        width: '70%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      {factorOptionsTitle.map((factorTitle: string) => {
                        return (
                          <StyledFactorTitle
                            key={factorTitle}
                            dangerouslySetInnerHTML={{
                              __html: factorTitle,
                            }}
                          ></StyledFactorTitle>
                        );
                      })}
                    </Box>
                  </Box>
                )}
                <StyledSectionTitle
                  dangerouslySetInnerHTML={{
                    __html: section.title,
                  }}
                ></StyledSectionTitle>
                {section &&
                  section.questions.map((question: any) => {
                    let selectedValue = '';
                    if (!isDataBound.current) {
                      selectedValue = (selectedOption[question.title] || {}).value || '';
                      isDataBound.current = true;
                    }
                    return (
                      <GridSections
                        key={question.title}
                        selectedValue={selectedValue}
                        title={question.title}
                        text={question.text}
                        questionOptions={questionOptions}
                        isOther={!!question.isOther}
                        questionTitles={factorOptionsTitle}
                        previousAnswers={savedValue}
                      />
                    );
                  })}
              </Box>
            );
          })}

        {newFactors &&
          newFactors.map((newFactor: any) => {
            let selectedValue = '';
            if (!isDataBound.current) {
              selectedValue = (gridSelection[newFactor.title] || {}).value || '';
              isDataBound.current = true;
            }
            return (
              <GridSections
                key={newFactor.title}
                selectedValue={selectedValue}
                title={newFactor.title}
                text={newFactor.text}
                questionOptions={questionOptions}
                isOther={!!newFactor.isOther}
                questionTitles={factorOptionsTitle}
                previousAnswers={savedValue}
              />
            );
          })}
        <StyledAddFactorButton
          fullWidth
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddNewFactor}
        >
          {t('interview.form.grid.add')}
        </StyledAddFactorButton>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </StyledFormControl>
    </Box>
  );
}

export default GridQuestion;
