export enum UserStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'inactive',
}

export const UserTabStatus: { [key: number]: string } = {
  0: 'active',
  1: 'inactive',
};

export const UserCredentials = [
  'Doctor of Osteopathy',
  'LCSW',
  'LMFT',
  'LMHC',
  'LPC',
  'LPCC',
  'Medical Doctor',
  'Nurse Practitioner',
  'PhD',
  `Physician's Assistant`,
  'PsyD',
];
