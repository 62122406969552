import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Radio from '@mui/material/Radio';
import { useTheme } from '@mui/material/styles';
import { StyledFormSelected, StyledRadioCheckIcon, StyledRadioIcon } from '../SdsScaleQuestion';

interface SectionProps {
  possibleAnswersAndText: any;
  controlProps: any;
}

export default function SectionTwo({ possibleAnswersAndText, controlProps }: SectionProps) {
  const theme = useTheme();

  const renderAnswers = () => {
    return possibleAnswersAndText.answers.map((item: string) => {
      return (
        <StyledFormSelected
          key={item}
          value={item}
          labelPlacement="start"
          control={
            <Radio
              checkedIcon={<StyledRadioCheckIcon>{item}</StyledRadioCheckIcon>}
              icon={
                <StyledRadioIcon sx={{ backgroundColor: theme.xPalette.background }}>
                  {item}
                </StyledRadioIcon>
              }
              {...controlProps(item)}
            />
          }
          label={
            <Hidden xlDown xlUp>
              {item}
            </Hidden>
          }
        />
      );
    });
  };

  return (
    <Box
      sx={{
        minWidth: '340px',
        maxWidth: '340px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        mt: 4,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Box sx={{ width: '100%', height: '30px' }}>{possibleAnswersAndText.texts[0]}</Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '68px',
          '&:after': {
            content: '""',
            position: 'absolute',
            width: '40px',
            left: 0,
            bottom: '-0.2px',
            borderBottom: `1px solid ${theme.xPalette.black}`,
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            width: '40px',
            right: 0,
            bottom: '-0.2px',
            borderBottom: `1px solid ${theme.xPalette.black}`,
          },
        }}
      >
        <Box
          sx={{
            width: '34%',
            height: '40px',
            border: `1px solid ${theme.xPalette.black}`,
          }}
        ></Box>
        <Box
          sx={{
            width: '34%',
            height: '40px',
            border: `1px solid ${theme.xPalette.black}`,
            borderLeft: 0,
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          width: '350px',
          height: '140px',
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
        }}
      >
        {renderAnswers()}
      </Box>
    </Box>
  );
}
