import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Control, Controller, UseFormSetError, useController, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useAddOrEditInterviewTypeMutation,
  useGetInterviewTypeInfoQuery,
} from '../../app/services/interview';
import {
  useAddOrEditMonitorTypeMutation,
  useGetMonitorTypeInfoQuery,
} from '../../app/services/monitor';
import { useToast } from '../../app/toast';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import SubmitButton from '../../common/components/SubmitButton';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import checkNullData from '../../common/utils/checkNullData';
import { Status } from '../../common/utils/commonUtils';
import { ReplaceType, editBreadcrumbs } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import {
  UpdatedAssessmentModule,
  EditAssessmentTypeInfoResponseDto,
} from '../../types/AssessmentBuilderType';
import { PageProps } from '../../types/PageProps';
import { PopupContent } from '../../types/PopupType';
import { ResponseError } from '../../types/ResponseError';
import ErrorPage from '../error/ErrorPage';

const StatusNames = {
  active: 'Active',
  inactive: 'Inactive',
  deleted: 'Deleted',
};

type AssessmentType = {
  assessmentTypeName: string;
  modules: number[];
  assessmentTypeStatus: string;
};

const defaultValues: AssessmentType = {
  assessmentTypeName: '',
  modules: [],
  assessmentTypeStatus: Status.ACTIVE,
};

const changePageName = ({
  isInterviewType,
  isMonitorType,
  assessmentId,
  assessmentTypeId,
  t,
}: {
  isInterviewType?: boolean;
  isMonitorType?: boolean;
  assessmentTypeId?: string;
  assessmentId?: string;
  t: TFunction<'translation', string>;
}) => {
  if (isInterviewType && assessmentTypeId && assessmentId) {
    return t('assessmentBuilder.assessmentType.interviewType.edit');
  }

  if (isInterviewType && assessmentId) {
    return t('assessmentBuilder.assessmentType.interviewType.add');
  }

  if (isMonitorType && assessmentTypeId && assessmentId) {
    return t('assessmentBuilder.assessmentType.monitorType.edit');
  }
  return t('assessmentBuilder.assessmentType.monitorType.add');
};

const Checkboxes = ({
  options,
  control,
  name,
  theme,
  setError,
  t,
  handleModuleErrors,
}: {
  options?: UpdatedAssessmentModule[];
  control: Control<AssessmentType, object>;
  name: 'modules';
  theme: Theme;
  setError: UseFormSetError<AssessmentType>;
  t: TFunction;
  handleModuleErrors: (moduleErrors: string[]) => void;
}) => {
  const { field } = useController({
    control,
    name,
  });
  const [value, setValue] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    if (options?.every((module: UpdatedAssessmentModule) => module.isChecked)) {
      setSelectAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (field.value) {
      setValue(field.value);
    }
  }, [field.value]);

  useEffect(() => {
    const errors = [];
    const selectedModules = options?.filter((option: UpdatedAssessmentModule) => value.includes(option.id)) || [];
    const selectedModuleLetters = selectedModules.map((option: UpdatedAssessmentModule) => option.letter);

    for (const module of selectedModules) {
      const dependencies = module?.dependencies?.length ? module.dependencies : [];
      const missingDependencies = dependencies.filter(
        (dependence: string) => !selectedModuleLetters.includes(dependence)
      );

      if (missingDependencies.length > 0) {
        const missingDependenciesString = missingDependencies.join(', ');
        errors.push(
          `Module ${module.letter} requires Module(s) ${missingDependenciesString} for scoring`
        );
      }
    }

    if (options?.length === value?.length) {
      setSelectAll(true);
    }

    setError('modules', { type: 'min', message: errors.join('\n') });
    handleModuleErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value]);

  return (
    <Box>
      {options?.length !== 1 ? (
        <FormControlLabel
          control={<Checkbox />}
          key={'select-all'}
          checked={selectAll}
          value={options?.map((option: UpdatedAssessmentModule) => option.id) || []}
          label={`${t('assessmentBuilder.assessmentType.form.selectAll')}`}
          onChange={(e: any) => {
            const allValues = e?.target?.checked
              ? e?.target?.value.split(',').map((id: string) => Number(id))
              : [];

            field.onChange(allValues);
            setValue(allValues);
            setSelectAll(!selectAll);
          }}
        />
      ) : (
        <></>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {options?.map((option: UpdatedAssessmentModule) => {
          return (
            <FormControlLabel
              control={<Checkbox />}
              key={option?.id}
              checked={value.includes(option?.id)}
              value={option?.id}
              label={
                <>
                  <Typography>
                    {option?.name}{' '}
                    {option?.dependencies?.length ? (
                      <Typography
                        component="span"
                        color={theme.palette.error.main}
                        fontSize="16px!important"
                      >
                        {` Module Dependencies: ${option?.dependencies.join(', ')}`}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </>
              }
              onChange={(e: any) => {
                const valueCopy = [...value];

                // update checkbox value
                const newValue = Number(e.target.value);
                const valueIndex = valueCopy.indexOf(newValue);

                if (newValue && valueIndex === -1) {
                  valueCopy.push(newValue);
                } else if (newValue && valueIndex !== -1) {
                  valueCopy.splice(valueIndex, 1);
                }

                // send data to react hook form
                field.onChange(valueCopy);

                // update local state
                setValue(valueCopy);
                if (selectAll) {
                  setSelectAll(false);
                }
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default function AddOrEditAssessmentType({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { assessmentTypeId, assessmentId } = useParams();
  const isInterviewType = pathname?.includes('interview-types');
  const isMonitorType = pathname?.includes('monitor-types');
  const type = isInterviewType ? 'interview' : 'monitor';
  const isEdit = assessmentTypeId && assessmentId;

  const [moduleErrors, setModuleErrors] = useState<string[]>([]);
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [addOrEditInterviewType] = useAddOrEditInterviewTypeMutation();
  const [addOrEditMonitorType] = useAddOrEditMonitorTypeMutation();

  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);

  const awareChangeStatusPopup = (value: string, changeTo: string) => {
    return {
      title: t('assessmentBuilder.assessmentType.awarePopup.title'),
      description: t('assessmentBuilder.assessmentType.awarePopup.description', {
        value: StatusNames[value],
        changeTo: StatusNames[changeTo],
      }),
      btnOk: t('assessmentBuilder.assessmentType.awarePopup.btnOk'),
    } as PopupContent;
  };

  const replacePath: ReplaceType[] = [{ param: ':assessmentId', value: assessmentId! }];

  const { data, error, isLoading } = useCustomAssessmentTypeInfoRequest({
    assessmentId: Number(assessmentId),
    assessmentTypeId: Number(assessmentTypeId),
    isInterviewType,
    isMonitorType,
  });

  const validationSchema = yup
    .object({
      assessmentTypeName: yup.string(),
      modules: yup.array(yup.number()),
      assessmentTypeStatus: yup.string(),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    getValues,
  } = useForm<AssessmentType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleModuleErrors = (errorMessages: string[]) => {
    setModuleErrors(errorMessages);
  };

  const showAwareChangeStatusPopup = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setContentPopup({
        content: awareChangeStatusPopup(getValues('assessmentTypeStatus'), event?.target?.value),
      })
    );
    dispatch(setIsOpenPopup({ value: true }));
    setValue('assessmentTypeStatus', event.target.value);
  };

  const handleAwareChangeStatusClick = async (successCallback?: () => void) => {
    if (successCallback) {
      successCallback();
    }
    dispatch(setIsOpenPopup({ value: false }));
  };

  const handleManualValidation = (data: AssessmentType) => {
    const { assessmentTypeName, modules } = data;
    let hasError = false;

    if (!assessmentTypeName) {
      setError('assessmentTypeName', {
        type: 'required',
        message: t('assessmentBuilder.error.blankName'),
      });
      hasError = true;
    }

    if (!modules?.length) {
      setError('modules', {
        type: 'min',
        message: t('assessmentBuilder.error.minModule'),
      });
      hasError = true;
    }

    if (moduleErrors.length) {
      setError('modules', {
        type: 'validate',
        message: moduleErrors.join('\n'),
      });
      hasError = true;
    }

    return hasError;
  };

  useEffect(() => {
    if (data) {
      for (const key in data) {
        if (key === 'modules') {
          const modules =
            data?.modules
              ?.filter((module: UpdatedAssessmentModule) => (isEdit ? module.isChecked : true))
              .map((module: UpdatedAssessmentModule) => module.id) || [];
          setValue('modules', modules);
        } else if (key in defaultValues) {
          setValue(key as keyof AssessmentType, checkNullData(data[key]));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentTypeId, assessmentId, data]);

  const onSubmit = async (data: AssessmentType) => {
    const { assessmentTypeName, modules, assessmentTypeStatus } = data;
    const hasErrors = handleManualValidation(data);
    if (!hasErrors) {
      try {
        let res;
        if (isInterviewType) {
          res = await addOrEditInterviewType({
            ...(assessmentTypeId
              ? { interviewTypeId: assessmentTypeId }
              : { interviewId: assessmentId }),
            interviewTypeName: assessmentTypeName,
            modules,
            status: assessmentTypeStatus,
          }).unwrap();
        } else if (isMonitorType) {
          res = await addOrEditMonitorType({
            ...(assessmentTypeId
              ? { trackerTypeId: assessmentTypeId }
              : { trackerId: assessmentId }),
            trackerTypeName: assessmentTypeName,
            modules,
            status: assessmentTypeStatus,
          }).unwrap();
        }

        if (res?.message) {
          toast.publish(res.message, 'success');
          setPageErrors([]);
          if (!isEdit) {
            navigate(
              isInterviewType
                ? `/dashboard/assessment-builder/interview/${assessmentId}/interview-types`
                : `/dashboard/assessment-builder/monitor/${assessmentId}/monitor-types`,
              { replace: true }
            );
          }
        }
      } catch (e) {
        const {
          data: { error },
        } = e as ResponseError;
        setPageErrors(error);
      }
    }
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={editBreadcrumbs(breadcrumbs, ...replacePath)}>
      <Helmet>
        <title>
          {getPageTitle(
            changePageName({ isInterviewType, isMonitorType, assessmentId, assessmentTypeId, t })
          )}
        </title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <PageHeader
            sx={{ mt: 1, mb: 2 }}
            headerText={changePageName({
              isInterviewType,
              isMonitorType,
              assessmentId,
              assessmentTypeId,
              t,
            })}
            backUrl={`/dashboard/assessment-builder/${type}/${assessmentId}/${type}-types/`}
          />
        </Container>
        {isLoading ? (
          <Loading />
        ) : (
          <RoundedContainer
            sx={{
              py: 1,
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  rowGap: 1,
                }}
              >
                <Controller
                  name="assessmentTypeName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors?.assessmentTypeName}
                      helperText={errors?.assessmentTypeName?.message}
                      margin="normal"
                      fullWidth
                      label={t('assessmentBuilder.assessmentType.form.name')}
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  name="assessmentTypeStatus"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={!assessmentTypeId}
                      error={!!errors?.assessmentTypeStatus}
                      helperText={errors?.assessmentTypeStatus?.message}
                      margin="normal"
                      fullWidth
                      select
                      label={t('assessmentBuilder.assessmentType.form.status')}
                      variant="outlined"
                      onChange={showAwareChangeStatusPopup}
                    >
                      {Object.values(Status).map((value: string) => (
                        <MenuItem key={value} value={value}>
                          {StatusNames[value]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <Box sx={{ width: '100%' }}>
                  <Typography fontWeight="bold">
                    {t('assessmentBuilder.assessmentType.form.title', { type: type })}
                  </Typography>
                  <Typography>{data?.assessmentName}</Typography>
                </Box>

                <Box sx={{ width: '100%', mt: 1 }}>
                  <Typography fontWeight="bold" variant="body1">
                    {t('assessmentBuilder.assessmentType.form.modules')}
                  </Typography>
                  {errors?.modules && (
                    <FormHelperText sx={{ color: theme.palette.error.main, whiteSpace: 'pre' }}>
                      {errors.modules && (errors.modules as any).message}
                    </FormHelperText>
                  )}
                  <Checkboxes
                    options={data?.modules}
                    control={control}
                    name="modules"
                    theme={theme}
                    setError={setError}
                    handleModuleErrors={handleModuleErrors}
                    t={t}
                  />
                </Box>

                <Box>
                  <Typography>{t('assessmentBuilder.assessmentType.noteMsg')}</Typography>
                </Box>
              </Box>
              <SubmitButton
                content={t('assessmentBuilder.assessmentType.form.submit')}
                matches={matches}
                pageErrors={pageErrors}
              />
            </Box>
          </RoundedContainer>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={handleAwareChangeStatusClick}
        />
      </Container>
    </DashboardLayout>
  );
}

const useCustomAssessmentTypeInfoRequest = ({
  assessmentId,
  assessmentTypeId,
  isInterviewType,
  isMonitorType,
}: {
  assessmentId?: number;
  assessmentTypeId?: number;
  isInterviewType?: boolean;
  isMonitorType?: boolean;
}): { data?: EditAssessmentTypeInfoResponseDto; error: any; isLoading: boolean } => {
  const {
    data: interviewType,
    error: interviewTypeError,
    isLoading: interviewTypeLoading,
  } = useGetInterviewTypeInfoQuery(
    isInterviewType && assessmentId
      ? { interviewTypeId: assessmentTypeId, interviewId: assessmentId }
      : skipToken
  );

  const {
    data: monitorType,
    error: monitorTypeError,
    isLoading: monitorTypeLoading,
  } = useGetMonitorTypeInfoQuery(
    isMonitorType && assessmentId
      ? { trackerTypeId: assessmentTypeId, trackerId: assessmentId }
      : skipToken
  );

  return {
    data: interviewType || monitorType,
    error: interviewTypeError || monitorTypeError,
    isLoading: interviewTypeLoading || monitorTypeLoading,
  };
};
