import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import useCustomQuestionInfo from './useCustomQuestionInfo';

const borderStyle = `1px solid ${theme.xPalette.black}`;
const borderBottomStyle = `1px solid ${theme.xPalette.shadeGrey}`;

export default function YbocsCheckListAnswer({
  questionAnswer: yBocsQuestionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
}: QuestionAnswerProps) {
  const { answerValue } = useCustomQuestionInfo({
    initialState: yBocsQuestionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  const renderQuestionAnswer = () => {
    const relatedPossibleAnswers = yBocsQuestionAnswer?.possibleAnswers?.slice(1) || [];
    const relatedDisplayAnswers = yBocsQuestionAnswer?.displayAnswers?.slice(1) || [];

    if (!yBocsQuestionAnswer.relatedQuestionIdForAnswerValues) {
      return (
        <Typography
          fontWeight={'bold'}
          fontSize={'16px'}
          dangerouslySetInnerHTML={{
            __html: yBocsQuestionAnswer.title || '',
          }}
        ></Typography>
      );
    }

    if (yBocsQuestionAnswer.relatedQuestionIdForAnswerValues && Array.isArray(answerValue)) {
      return relatedPossibleAnswers.map((possibleAnswer: string, index) => {
        const isCheck = answerValue.some(
          (answer: string) => possibleAnswer.toLowerCase() === answer.toLowerCase()
        );

        return (
          <Box sx={{ display: 'flex', columnGap: 1, alignItems: 'center' }}>
            <Typography fontWeight={'bold'} fontSize={'16px'}>
              {relatedDisplayAnswers[index] || possibleAnswer}
            </Typography>
            <Box
              sx={{
                height: '26px',
                display: 'inline-block',
                position: 'relative',
              }}
            >
              {isCheck ? (
                <CheckBoxIcon fontSize="medium" sx={{ color: theme.palette.secondary.main }} />
              ) : (
                <CheckBoxBlankIcon fontSize="medium" sx={{ color: theme.xPalette.lightGrey }} />
              )}
            </Box>
          </Box>
        );
      });
    }
  };

  return (
    <Box
      key={yBocsQuestionAnswer.questionId}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        pageBreakInside: 'avoid',
        alignItems: 'center',
        columnGap: 1,
        borderTop: borderBottomStyle,
        borderBottom: borderBottomStyle,
        borderRight: borderStyle,
        borderLeft: borderStyle,
        height: '100%',
      }}
    >
      <Box sx={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', px: 1, py: 2 }}>
        <Typography fontWeight={500} fontSize={'16px'}>
          {!yBocsQuestionAnswer.relatedQuestionIdForAnswerValues &&
            (Array.isArray(answerValue) ? answerValue.join(', ') : answerValue)}
        </Typography>
      </Box>

      <Divider sx={{ backgroundColor: theme.xPalette.black }} orientation="vertical" flexItem />

      <Box
        sx={{
          flex: 3,
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 1,
          py: 2,
        }}
      >
        {renderQuestionAnswer()}
      </Box>
    </Box>
  );
}
