import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { rootApi } from '../../app/services';
import { useLogoutMfaMutation, useLogoutMutation } from '../../app/services/auth';
import {
  clearCredentials,
  selectIsAuthenticated,
  selectIsShowMfa,
  selectTokenInfo,
  selectUser,
} from '../../features/auth/authSlice';
import { clearPatientInterviewData } from '../../features/interview/interviewSlice';
import { clearPatientMonitorData } from '../../features/monitor/monitorSlide';
import { clearAssessmentCachedData } from '../../features/patient/patientUtils';
import { logger } from '../utils/logger';
import { COUNTDOWN_SECONDS, IDLE_CHECK_EVENTS, IDLE_CHECK_SECONDS } from '../utils/securityConfig';
import { isSessionCookieExisted, saveSessionCookie } from '../utils/storageUtils';

export default function SessionExpirationModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [logoutTime, setLogoutTime] = useState<number>(0);
  const [inCountdown, setInCountdown] = useState<boolean>(false);
  const [logout] = useLogoutMutation();
  const [logoutMfa] = useLogoutMfaMutation();
  const tokenInfo = useAppSelector(selectTokenInfo);
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const currentUser = useAppSelector(selectUser);
  const isShowMfa = useAppSelector(selectIsShowMfa);

  const forceUserLogout = useCallback(async () => {
    if (isAuthenticated) {
      if (isShowMfa) {
        logoutMfa({
          token: tokenInfo?.accessToken,
          userId: currentUser?.id,
        }).unwrap();
      } else {
        logout().unwrap();
      }

      clearAssessmentCachedData();
      dispatch(clearCredentials());
    } else {
      dispatch(clearPatientInterviewData());
      dispatch(clearPatientMonitorData());
      navigate('/login');
    }
    dispatch(rootApi.util.resetApiState());
  }, [logout, logoutMfa, dispatch, isAuthenticated, navigate, tokenInfo, currentUser, isShowMfa]);

  // Check and log user out if session cookie does not exist
  useEffect(() => {
    const athenaBaseUrl =
      process.env.REACT_APP_ATHENA_BASE_URL || 'https://preview.athenahealth.com';

    if (
      isAuthenticated &&
      !isSessionCookieExisted() &&
      document.location.ancestorOrigins[0] !== athenaBaseUrl
    ) {
      saveSessionCookie();
      forceUserLogout();
    }
  }, [isAuthenticated, forceUserLogout]);

  // Logout countdown logic
  useEffect(() => {
    if (logoutTime <= 0) {
      if (inCountdown) {
        setLogoutTime(0);
        forceUserLogout();
      }
      return;
    }

    const timeoutId = setTimeout(() => {
      setLogoutTime(logoutTime - 1);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [logoutTime, inCountdown, forceUserLogout]);

  const handleClose = (event: SyntheticEvent) => {
    if (['btnOk', 'btnClose'].includes(event.currentTarget.id)) {
      setInCountdown(false);
    }
  };

  const handleOnIdle = (event: Event) => {
    logger.info('Last active', getLastActiveTime());
    setLogoutTime(COUNTDOWN_SECONDS);
    setInCountdown(true);
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: IDLE_CHECK_SECONDS * 1000,
    onIdle: handleOnIdle,
    debounce: 500,
    events: IDLE_CHECK_EVENTS,
  });

  return (
    <Dialog
      open={inCountdown}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{ pt: 6, textAlign: 'center', fontSize: (theme) => theme.typography.h1.fontSize }}
      >
        {t('login.sessionExpirationModal.title')}
        <IconButton
          id="btnClose"
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('login.sessionExpirationModal.loggedOutSoon', { logoutTime })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="btnOk" variant="contained" onClick={handleClose}>
          {t('login.sessionExpirationModal.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
