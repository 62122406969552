import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../../../../app/theme';
import { AnswerTypeConditions, AssessmentMode } from '../../../../common/utils/assessmentMode';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import { InterviewReportType } from '../../../interview/interviewUtils';
import ReportNotes from '../ReportNotes';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function TextAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
  notes,
}: QuestionAnswerProps) {
  const isSdsSts = moduleType === InterviewReportType.SdsSts;
  const isYbocsCyBocs =
    moduleType === InterviewReportType.YBOCS || moduleType === InterviewReportType.CYBOCS;
  const isMonitor = moduleType === InterviewReportType.Monitor;

  const { questionDisplay, promptDisplay, scoreForQuestion, answerValue, questionTitle } =
    useCustomQuestionInfo({
      initialState: questionAnswer,
      moduleType,
      subModuleType,
      assessmentType,
    });

  const answerIsShort = answerValue?.length <= AnswerTypeConditions.TEXT_MAX_LENGTH;

  return (
    <>
      <Box
        key={questionAnswer.questionId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          my: 3,
          width: '100%',
          pageBreakInside: 'avoid',
        }}
      >
        {!isYbocsCyBocs && (
          <>
            <Box sx={{ width: '15%', display: 'flex', columnGap: 1 }}>
              <Typography component={'span'} fontWeight={500}>
                {questionTitle}
              </Typography>
              <Typography component={'span'} fontWeight={500}>
                {(moduleType !== InterviewReportType.Monitor && questionAnswer.subTitle) || ''}
              </Typography>
            </Box>
            <Box sx={{ width: isMonitor ? '85%' : '75%' }}>
              {promptDisplay && (
                <Typography
                  fontWeight="bold"
                  dangerouslySetInnerHTML={{
                    __html: promptDisplay,
                  }}
                ></Typography>
              )}
              <Typography
                fontWeight={500}
                dangerouslySetInnerHTML={{
                  __html: questionDisplay,
                }}
              ></Typography>
              <Typography fontWeight={'bold'} my={1}>
                {!answerIsShort ? answerValue : ''}
              </Typography>
              {notes?.length && (
                <ReportNotes reportNotes={notes} questionId={questionAnswer.questionId} />
              )}
            </Box>
          </>
        )}

        {isSdsSts && (
          <>
            <Typography
              sx={{ width: '10%', textAlign: 'right' }}
              component={'span'}
              fontWeight={'bold'}
            >
              {answerValue || ''}
            </Typography>
            <Typography
              sx={{ width: '5%', textAlign: 'right', fontWeight: 500 }}
              component={'span'}
            ></Typography>
          </>
        )}

        {isYbocsCyBocs && !!answerValue && (
          <>
            <Typography
              fontWeight="bold"
              dangerouslySetInnerHTML={{
                __html: questionAnswer.title || '',
              }}
            ></Typography>
            <Typography
              fontWeight="bold"
              dangerouslySetInnerHTML={{
                __html: answerValue || '',
              }}
            ></Typography>
          </>
        )}

        {isMonitor && (
          <>
            <Typography
              sx={{ width: answerIsShort ? '5%' : '0%', textAlign: 'left' }}
              component={'span'}
              fontWeight={'bold'}
            >
              {answerIsShort ? answerValue : ''}
            </Typography>
          </>
        )}

        {!isSdsSts && !isYbocsCyBocs && !isMonitor && (
          <>
            <Typography
              sx={{ width: '10%', textAlign: 'right' }}
              component={'span'}
              fontWeight={'bold'}
            >
              {answerIsShort ? answerValue : ''}
            </Typography>
            <Typography
              sx={{ width: '5%', textAlign: 'right', fontWeight: 500 }}
              component={'span'}
            >
              {scoreForQuestion}
            </Typography>
          </>
        )}
      </Box>

      {isMonitor &&
        assessmentType === AssessmentMode.Clinician &&
        !!questionAnswer?.clinicianHelpText && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: 1,
              width: '100%',
              pageBreakInside: 'avoid',
            }}
          >
            <Typography sx={{ width: '15%' }}></Typography>
            <Typography
              sx={{ width: '85%' }}
              fontWeight={500}
              textAlign={'left'}
              color={theme.xPalette.lightGrey}
            >
              {questionAnswer.clinicianHelpText}
            </Typography>
          </Box>
        )}
    </>
  );
}
