import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import { buildInterviewUrl } from '../../app/services';
import { useGetManagementReportTokenQuery } from '../../app/services/patient';
import { getPageTitle } from '../../common/utils/pageUtils';
import { useCallback, useState } from 'react';
import { GlobalLoading } from '../../common/components/GlobalLoading';

export default function ManagementReportContentPage() {
  const { t } = useTranslation();
  const { reportNameActive } = useParams();
  const [searchParams] = useSearchParams();
  const reportIdActive = searchParams?.get('reportIdActive') || undefined;
  const [reportLoaded, setReportLoaded] = useState(false);

  const { data: reportTokenRes } = useGetManagementReportTokenQuery(
    reportNameActive && reportIdActive ? { reportNameActive, reportIdActive } : skipToken
  );

  const onLoad = useCallback(() => {
    setReportLoaded(true);
  }, []);

  return (
    <Box>
      <Helmet>
        <title>{getPageTitle(t('managementReport.title'))}</title>
      </Helmet>

      {!reportLoaded && <GlobalLoading appBarHeight={0} loadingText={t('managementReport.generatingText')
      + `${reportNameActive ? reportNameActive?.charAt(0).toUpperCase() + reportNameActive?.slice(1) : ''}`
      +t('managementReport.generatingReport')}/>}
      {reportNameActive && reportTokenRes?.token && (
        <iframe
          onLoad={onLoad}
          title={reportNameActive}
          src={`${buildInterviewUrl(
            '/v1/management-report/download-management-report'
          )}?reportNameActive=${reportNameActive}&reportIdActive=${reportIdActive}&token=${reportTokenRes?.token}`}
          style={{ height: '100vh', width: '100%' }}
        ></iframe>
      )}
    </Box>
  );
}
