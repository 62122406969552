import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Container from '@mui/material/Container';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import ErrorPage from '../error/ErrorPage';
import SsoClientForm from './SsoClientForm';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { useAddIdentityProviderMutation, useEditIdentityProviderMutation, useGetIdentityProviderDetailQuery } from '../../app/services/identityProvider';
import { IdentityProviderEditRequest, IdentityProviderItem } from '../../types/SsoType';
import { Protocol } from '../../common/utils/protocol';

export default function AddOrEditSsoClientPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const params = useParams();
  const identityProviderId: string | undefined = params.identityProviderId;
  const [addIdentityProvider] = useAddIdentityProviderMutation();
  const [editIdentityProvider] = useEditIdentityProviderMutation();
  const { data, error } = useGetIdentityProviderDetailQuery(identityProviderId ? {identityProviderId: parseInt(identityProviderId)} : skipToken);
  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const newBreadcrumb: BreadcrumbType = {
    text: t('ssoClient.manageSsoClient.title'),
    link: navBackUrl || `/dashboard/ssoClients`,
  };

  const processData = (data: IdentityProviderItem, identityProviderId?: number) => {
    if(data.loginMethodId === Protocol.OpenId || data.loginMethodId === Protocol.Oauth2){
      return {
        id: identityProviderId ? identityProviderId : undefined,
        name: data.name,
        description: data.description,
        loginMethodId: data.loginMethodId,
        status: data.status,
        locale: data.locale,
        clientId: data.clientId,
        clientSecret: data.clientSecret,
        baseUrl: data.baseUrl,
        scope: data.scope,
        getProfileUri: data.getProfileUri,
        authorizeUri: data.authorizeUri,
        getTokenUri: data.getTokenUri,
        ehrType: data.ehrType ? data.ehrType : undefined,
      };
    }
    if(data.loginMethodId === Protocol.Saml){
      return {
        id: identityProviderId ? identityProviderId : undefined,
        name: data.name,
        description: data.description,
        loginMethodId: data.loginMethodId,
        status: data.status,
        locale: data.locale,
        logoutUrl: data.logoutUrl,
        postUrl: data.postUrl,
        idpInitiatedUrl: data.idpInitiatedUrl,
        cert: data.cert,
        userAttribute: data.userAttribute,
        subAttribute: data.subAttribute,
        digestAlgorithm: data.digestAlgorithm,
        entityId: data.entityId,
        ehrType: data.ehrType ? data.ehrType : undefined,
      };
    }
  };

  const onFormSubmit = async (
    data: IdentityProviderItem,
    identityProviderId?: number,
    successCallback?: () => void
  ) => {
    if (identityProviderId) {
      const dataProcessed = processData(data, identityProviderId);
      await editIdentityProvider(dataProcessed as IdentityProviderEditRequest).unwrap();
    } else {
      const dataProcessed = processData(data, identityProviderId);
      await addIdentityProvider(dataProcessed as IdentityProviderItem).unwrap();
      navigate(`/dashboard/ssoClients`);
    }
    if (successCallback) {
      successCallback();
    }
    toast.publish(
      (identityProviderId && t('ssoClient.manageSsoClient.updateSuccess')) || t('ssoClient.manageSsoClient.addSuccess'),
      'success'
    );
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>
          {identityProviderId
            ? getPageTitle(t('ssoClient.manageSsoClient.editSsoClient'))
            : getPageTitle(t('ssoClient.manageSsoClient.addSsoClient'))}
        </title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={identityProviderId ? t('ssoClient.manageSsoClient.editSsoClient') : t('ssoClient.manageSsoClient.addSsoClient')}
          backUrl={navBackUrl || `/dashboard/ssoClients`}
        />
      </Container>

      <RoundedContainer>
        <SsoClientForm
          identityProviderId={identityProviderId ? parseInt(identityProviderId) : 0}
          identityProviderDetail={data && data.identityProviderDetail}
          onFormSubmit={onFormSubmit}
        />
      </RoundedContainer>
    </DashboardLayout>
  );
}
