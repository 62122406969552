import { buildAccountUrl, QueryCacheDuration, rootApi, TagTypes } from '.';
import {
  DeleteTemplateParam,
  ListInterviewResultTemplateQuery,
  ListInterviewResultTemplateResponse,
} from '../../types/AccountType';
import {
  TemplateAddResponse,
  TemplateAddRequest,
  TemplateEditResponse,
  TemplateEditRequest,
  TemplateDetail,
} from '../../types/TemplateType';

const api = rootApi
  .enhanceEndpoints({
    addTagTypes: [TagTypes.Site],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getListTemplate: builder.query<
        ListInterviewResultTemplateResponse,
        ListInterviewResultTemplateQuery
      >({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/interview-result-template'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      deleteTemplate: builder.mutation<void, DeleteTemplateParam>({
        query: (params) => ({
          url: buildAccountUrl(`/v1/account/interview-result-template/${params.templateId}`),
          method: 'DELETE',
          params,
        }),
      }),

      getTemplateData: builder.query<{ data: TemplateDetail }, { id: number }>({
        query: (params) => ({
          url: buildAccountUrl(`/v1/account/interview-result-template/${params.id}`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      addTemplate: builder.mutation<TemplateAddResponse, TemplateAddRequest>({
        query: (body) => ({
          url: buildAccountUrl(`/v1/account/interview-result-template`),
          method: 'POST',
          body,
        }),
      }),

      editTemplate: builder.mutation<TemplateEditResponse, TemplateEditRequest>({
        query: (body) => ({
          url: buildAccountUrl(`/v1/account/interview-result-template/${body.id}`),
          method: 'PUT',
          body,
        }),
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetListTemplateQuery,
  useDeleteTemplateMutation,
  useGetTemplateDataQuery,
  useAddTemplateMutation,
  useEditTemplateMutation,
} = api;
