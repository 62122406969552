import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../../app/hooks';
import { useDeleteTreatmentDiagnosisMutation } from '../../../app/services/treatment-plan/treatmentDiagnosis';
import { useToast } from '../../../app/toast';
import DataList from '../../../common/components/DataList';
import LocalPopupModal from '../../../common/components/LocalPopupModal';
import RoundedContainer from '../../../common/components/RoundedContainer';
import DeactivatePatientIcon from '../../../common/components/icons/DeactivatePatientIcon';
import EditUserIcon from '../../../common/components/icons/EditInterviewIcon';
import { sortItemsOnList } from '../../../common/utils/commonUtils';
import { formatServerDateAndTime } from '../../../common/utils/dateTimeUtils';
import { SortDirs } from '../../../common/utils/sortInfo';
import { PatientDiagnosisInfo } from '../../../types/PatientRequest';
import { PopupContent } from '../../../types/PopupType';
import { TreatmentPlanDiagnosisItem } from '../../../types/treatment-plan/TreatmentPlanDiagnosisType';
import ContextMenu from '../../patient/ContextMenu';
import { setTreatmentPlanReloadOption } from '../treatmentPlanSlice';
import TreatmentPlanDiagnosisForm from './TreatmentPlanDiagnosisForm';

interface DiagnosisListProps {
  parentPlanId?: number;
  diagnosisRows: TreatmentPlanDiagnosisItem[] | PatientDiagnosisInfo[];
  treatmentPlanCompleted?: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledSortIconBox = styled(Box)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.primary.main,
  verticalAlign: 'middle',
}));

export default function TreatmentPlanDiagnosisList({
  parentPlanId,
  diagnosisRows,
  treatmentPlanCompleted,
}: DiagnosisListProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [displayedItems, setDisplayedItems] = useState<
    TreatmentPlanDiagnosisItem[] | PatientDiagnosisInfo[]
  >([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const defaultSortOptions = {
    sortField: 'createdAt',
    sortDir: SortDirs.Asc,
  };
  const [sortOptions, setSortOptions] =
    useState<{ sortField: string; sortDir: SortDirs }>(defaultSortOptions);

  // Delete Popup
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState<boolean>(false);
  const [deletingId, setDeletingId] = useState<number>(0);

  // Add/Edit Popup
  const [isOpenAddEditPopup, setIsOpenAddEditPopup] = useState<boolean>(false);
  const [editingData, setEditingData] = useState<TreatmentPlanDiagnosisItem | undefined>(undefined);

  const [deleteDiagnosis] = useDeleteTreatmentDiagnosisMutation();

  const sortHeader = ['timeframe', 'displayName', 'createdAt'];

  const tableHeaders: string[][] = Object.entries(
    t('treatmentPlans.diagnosisList.tableHeader', {
      returnObjects: true,
    })
  );

  useEffect(() => {
    if (diagnosisRows) {
      const sortedItems = [...diagnosisRows];

      const formattedItems = sortedItems.map((diagItem) => ({
        ...diagItem,
        displayName: `${diagItem.diagnosisName ? diagItem.diagnosisName : diagItem.otherDiagnosis}`,
      }));

      sortItemsOnList(formattedItems, sortOptions);

      setDisplayedItems(formattedItems as TreatmentPlanDiagnosisItem[]);
    }
  }, [diagnosisRows, sortOptions]);

  // ***************************** Delete Popup *****************************

  const deleteContentPopup = {
    title: t('treatmentPlans.diagnosisList.delete.title'),
    description: `${t('treatmentPlans.diagnosisList.delete.description')}`,
    btnOk: t('treatmentPlans.diagnosisList.delete.btnOk'),
    btnClose: t('treatmentPlans.diagnosisList.delete.btnClose'),
    toastMessage: t('treatmentPlans.diagnosisList.delete.toastMessage'),
  } as PopupContent;

  const showDeletePopup = async (diagnosisId: number) => {
    setIsOpenDeletePopup(true);
    setDeletingId(diagnosisId);
    handleClose();
  };

  const closeDeletePopup = async () => {
    setIsOpenDeletePopup(false);
  };

  const handleDeleteDiagnosis = async (successCallback?: () => void) => {
    try {
      await deleteDiagnosis({ id: deletingId }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('treatmentPlans.diagnosisList.delete.success'), 'success');
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
    } catch (e) {
      toast.publish(t('treatmentPlans.diagnosisList.delete.fail'), 'error');
    }
    closeDeletePopup();
  };

  // ***************************** Add/Edit Popup *****************************

  const addContentPopup = {
    title: t('treatmentPlans.diagnosisForm.add'),
  } as PopupContent;

  const editContentPopup = {
    title: t('treatmentPlans.diagnosisForm.edit'),
  } as PopupContent;

  const showAddEditPopup = async (diagnosisData?: TreatmentPlanDiagnosisItem) => {
    setIsOpenAddEditPopup(true);
    setEditingData(diagnosisData || undefined);
    handleClose();
  };

  const closeAddEditPopup = async () => {
    setIsOpenAddEditPopup(false);
  };

  const onFormSubmitCallback = async (result: boolean) => {
    if (result) {
      setIsOpenAddEditPopup(false);
      dispatch(setTreatmentPlanReloadOption({ reload: true }));
    }
  };

  // ********************************************************************

  const onHeaderClicked = (header?: string) => {
    if (header && displayedItems?.length) {
      const newSortOptions = {
        sortField: header,
        sortDir: sortOptions.sortDir === SortDirs.Asc ? SortDirs.Desc : SortDirs.Asc,
      };

      setSortOptions(newSortOptions);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderContextMenu = (item: TreatmentPlanDiagnosisItem) => {
    const menuItems = [
      {
        label: t('treatmentPlans.diagnosisList.menuContext.edit'),
        icon: <EditUserIcon />,
        onClick: () => showAddEditPopup(item),
      },
      {
        isDisplayed: !treatmentPlanCompleted,
        label: t('treatmentPlans.diagnosisList.menuContext.delete'),
        icon: <DeactivatePatientIcon />,
        onClick: () => showDeletePopup(item.id),
      },
    ];

    return (
      <ContextMenu
        id={`treatment-diagnosis-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    );
  };

  const getDiagnosisItemId = (item: TreatmentPlanDiagnosisItem | PatientDiagnosisInfo): number => {
    return (
      (item as TreatmentPlanDiagnosisItem).id || (item as PatientDiagnosisInfo).patientDiagnosisId
    );
  };

  const columnWidths = parentPlanId
    ? {
        timeframe: '20%',
        displayName: '50%',
        createdAt: '20%',
        action: '10%',
      }
    : {
        timeframe: '25%',
        displayName: '50%',
        createdAt: '25%',
      };

  const renderDesktopData = () => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map(([headerKey, headerLabel], index) => {
                if (headerKey === 'action' && !parentPlanId) return <></>;

                const isSortable = sortHeader.includes(headerKey);
                const hasSort = sortOptions.sortField === headerKey;
                const sortArrowIcon =
                  sortOptions.sortDir === SortDirs.Asc ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  );
                const sortIcon = hasSort ? (
                  <StyledSortIconBox>{sortArrowIcon}</StyledSortIconBox>
                ) : (
                  <></>
                );
                return (
                  <StyledTableCell
                    align={headerKey === 'displayName' ? 'left' : 'center'}
                    sx={isSortable ? { cursor: 'pointer' } : {}}
                    key={`${headerKey} ${index}`}
                    onClick={isSortable ? () => onHeaderClicked(headerKey) : undefined}
                  >
                    {headerLabel} {sortIcon}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedItems?.map((item: TreatmentPlanDiagnosisItem | PatientDiagnosisInfo) => (
              <TableRow key={getDiagnosisItemId(item)}>
                <TableCell
                  sx={{
                    width: columnWidths.displayName,
                    '&.MuiTableCell-root': { fontSize: '18px' },
                  }}
                >
                  {item.displayName}
                </TableCell>
                <TableCell align="center" sx={{ width: columnWidths.timeframe }}>
                  {(item as TreatmentPlanDiagnosisItem).timeframe ||
                    (item as PatientDiagnosisInfo).timeFrame ||
                    '-'}
                </TableCell>
                <TableCell sx={{ width: columnWidths.createdAt }} align="center">
                  {formatServerDateAndTime(item.createdAt)}
                </TableCell>
                {!!parentPlanId && (
                  <TableCell sx={{ width: columnWidths.action }} align="center">
                    {renderContextMenu(item as TreatmentPlanDiagnosisItem)}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = () => {
    return (
      <DataList
        lineHeight={'auto'}
        data={displayedItems.map((item: TreatmentPlanDiagnosisItem | PatientDiagnosisInfo) => {
          const listItems = [
            [
              <>{t('treatmentPlans.diagnosisList.tableHeader.timeframe')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>
                {(item as TreatmentPlanDiagnosisItem).timeframe ||
                  (item as PatientDiagnosisInfo).timeFrame ||
                  '-'}
              </Box>,
            ],
            [
              <>{t('treatmentPlans.diagnosisList.tableHeader.createdAt')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{formatServerDateAndTime(item.createdAt)}</Box>,
            ],
          ];
          return {
            leftHeader: (
              <Typography variant="body1" fontWeight="bold" fontSize={20}>
                {item.displayName}
              </Typography>
            ),
            rightHeader: !!parentPlanId
              ? renderContextMenu(item as TreatmentPlanDiagnosisItem)
              : undefined,
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: isDesktop ? 'center' : 'normal',
            paddingTop: 1,
            flexWrap: 'wrap',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px!important',
              fontWeight: 'bold',
              paddingBottom: isDesktop ? 0 : 2,
            }}
          >
            {t('treatmentPlans.diagnosisList.title')}
          </Typography>
          {!!parentPlanId && (
            <Button
              onClick={() => {
                showAddEditPopup();
              }}
              fullWidth
              variant="contained"
              disabled={treatmentPlanCompleted}
              sx={{
                width: isDesktop ? '24%' : '100%',
                py: 2,
                float: 'right',
              }}
            >
              {t('treatmentPlans.diagnosisList.add')}
            </Button>
          )}
        </Box>

        {displayedItems.length === 0 ? (
          <RoundedContainer
            sx={{ mt: 3, p: 2, background: theme.xPalette.background, color: 'rgba(0, 0, 0, 0.7)' }}
          >
            <Typography>{t('treatmentPlans.diagnosisList.error.notFound')}</Typography>
          </RoundedContainer>
        ) : (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mt: 2 }}>{isDesktop ? renderDesktopData() : renderMobileData()}</Box>
          </Box>
        )}
      </Box>

      {/* Delete Popup */}
      {!!parentPlanId && (
        <LocalPopupModal
          isOpen={isOpenDeletePopup}
          contentPopup={deleteContentPopup}
          onSuccess={handleDeleteDiagnosis}
          onClose={closeDeletePopup}
        />
      )}

      {/* Add or Edit Popup */}
      {!!parentPlanId && (
        <LocalPopupModal
          isOpen={isOpenAddEditPopup}
          contentPopup={editingData ? editContentPopup : addContentPopup}
          onClose={closeAddEditPopup}
          maxWidth={'md'}
          fullWidth={true}
        >
          <TreatmentPlanDiagnosisForm
            parentPlanId={parentPlanId}
            diagnosesInfoData={editingData}
            onFormSubmitCallback={onFormSubmitCallback}
          />
        </LocalPopupModal>
      )}
    </>
  );
}
