import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { ToastContext } from '../../app/toast';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

export default function Notification() {
  return (
    <ToastContext.Consumer>
      {(value) =>
        value.content && (
          <Snackbar
            autoHideDuration={value.duration}
            open={true}
            onClose={value.clear}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            TransitionComponent={SlideTransition}
          >
            <Alert
              variant="filled"
              severity={value.severity}
              sx={{ width: '100%', position: 'relative', '.MuiAlert-message': { pr: 3 } }}
            >
              {value.content}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={value.clear}
                sx={{ position: 'absolute', right: '8px', top: '8px' }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Alert>
          </Snackbar>
        )
      }
    </ToastContext.Consumer>
  );
}
