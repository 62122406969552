import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useCheckHasClinicalFollowUpsQuery } from '../../app/services/account';
import { useGetPatientQuery } from '../../app/services/patient';
import { useToast } from '../../app/toast';
import {
  clearClinicalFollowUps,
  selectClinicalFollowUpInfo,
} from '../../features/interview/interviewSlice';
import { clearAssessmentCachedData } from '../../features/patient/patientUtils';
import { AssessmentStatus } from '../utils/assessmentStatus';
import PopupModal from './PopupModal';

const LIMIT_REFETCH = 10;

export default function CompletedFollowupModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const clinicalFollowUpInfo = useAppSelector(selectClinicalFollowUpInfo);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [patientInfoMgs, setPatientInfoMgs] = useState<any>({});
  const [followUpInfo, setFollowUpInfo] = useState<any>({});
  const [refetchTime, setRefetchTime] = useState<number>(0);

  const { data: patientInfo } = useGetPatientQuery(
    clinicalFollowUpInfo
      ? {
          patientId: clinicalFollowUpInfo.patientId,
        }
      : skipToken
  );

  const { data: clinicalFollowUp, refetch } = useCheckHasClinicalFollowUpsQuery(
    clinicalFollowUpInfo
      ? {
          patientId: clinicalFollowUpInfo.patientId,
          interviewTestId: clinicalFollowUpInfo.interviewResultId,
        }
      : skipToken
  );

  useEffect(() => {
    const shouldReFetch = setTimeout(() => {
      if (clinicalFollowUpInfo) {
        setRefetchTime(refetchTime + 1);
        refetch();
      }
    }, 3000);

    if (
      patientInfo &&
      clinicalFollowUpInfo &&
      clinicalFollowUp &&
      clinicalFollowUp.interviewStatus === AssessmentStatus.Completed
    ) {
      if (clinicalFollowUp.hasFollowUps) {
        setOpenModal(true);
      } else {
        clearAssessmentCachedData();
        toast.publish(
          t('clinicalFollowup.popUp.noFollowUp', {
            patientInfo:
              patientInfoMgs && followUpInfo
                ? `${patientInfo?.firstName} ${patientInfo?.lastName} (${clinicalFollowUpInfo?.patientId})`
                : '',
          }),
          'success',
          3000
        );
      }
      dispatch(clearClinicalFollowUps());
      clearTimeout(shouldReFetch);
      setRefetchTime(0);
      return;
    }

    if (refetchTime === LIMIT_REFETCH) {
      clearTimeout(shouldReFetch);
      dispatch(clearClinicalFollowUps());
      setRefetchTime(0);
    }

    return () => {
      clearTimeout(shouldReFetch);
    };
  }, [
    clinicalFollowUp,
    clinicalFollowUpInfo,
    dispatch,
    followUpInfo,
    navigate,
    patientInfo,
    patientInfoMgs,
    refetch,
    refetchTime,
    t,
    toast,
  ]);

  useEffect(() => {
    if (patientInfo && clinicalFollowUpInfo) {
      setPatientInfoMgs(patientInfo);
      setFollowUpInfo(clinicalFollowUpInfo);
    }
  }, [clinicalFollowUpInfo, patientInfo]);

  return (
    <PopupModal
      isOpen={openModal}
      contentPopup={{
        title: t('clinicalFollowup.popUp.title'),
        description: t('clinicalFollowup.popUp.description', {
          patientInfo:
            patientInfo || clinicalFollowUpInfo
              ? `${patientInfoMgs?.firstName} ${patientInfoMgs?.lastName} (${followUpInfo?.patientId})`
              : '',
        }),
        btnOk: t('clinicalFollowup.popUp.yesBtn'),
        btnClose: t('clinicalFollowup.popUp.closeBtn'),
      }}
      onClick={async () => {
        clearAssessmentCachedData();
        navigate(`/dashboard/patient/${followUpInfo?.patientId}/follow-up`);
      }}
    />
  );
}
