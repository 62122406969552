import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';

interface CopyrightProps {
  sx?: SxProps;
}

export default function Copyright({ sx }: CopyrightProps) {
  const { t } = useTranslation();
  return (
    <Typography my={2} variant="body2" sx={sx ? sx : {}} color="text.secondary" align="center">
      {t('site.copyright', { year: new Date().getFullYear() })}
    </Typography>
  );
}
