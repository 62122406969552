import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useToast } from '../../app/toast';
import AnonymousLayout from '../../common/layouts/Anonymous';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { getPageTitle } from '../../common/utils/pageUtils';
import { ResetPasswordRequest } from '../../types/AuthRequests';
import { ControlledTextField } from './ControlledTextField';
import { useCheckExpireUrlResetPasswordQuery, useResetPasswordMutation } from '../../app/services/auth';
import { useEffect, useState } from 'react';
import { ResponseError } from '../../types/ResponseError';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Lock from '@mui/icons-material/Lock';
import { clearAssessmentCachedData } from '../patient/patientUtils';
import { clearCredentials } from './authSlice';
import { useAppDispatch } from '../../app/hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Link from '@mui/material/Link';
import Loading from '../../common/components/Loading';

export default function ResetPasswordPage() {
  const toast = useToast();
  const { token, hash } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [resetPassword] = useResetPasswordMutation();
  const [pageErrors, setPageErrors] = useState<string[]>([]);

  const { data: checkExpireUrl, isLoading} = useCheckExpireUrlResetPasswordQuery(
    token && hash ? {
      token: token,
      tokenHash: hash
    } : skipToken
  );
  
  // Form setup
  const validationSchema = yup
    .object({
      newPassword: yup.string().required(t('login.error.blankPassword'))
      .min(10, t('resetPassword.error.minPassword')),
      confirmPassword: yup.string()
      .oneOf([yup.ref('newPassword')], t('resetPassword.error.passwordsDontMatch'))
    })
    .required();

  const {
    control,
    formState: { errors, isSubmitSuccessful},
    reset,
    handleSubmit,
  } = useForm<ResetPasswordRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });
  useEffect(() => {
    if (isSubmitSuccessful && !pageErrors.length) {
      reset();
      clearAssessmentCachedData();
      dispatch(clearCredentials());
      navigate('/login', { replace: true });
    }
  }, [isSubmitSuccessful, pageErrors, reset, navigate, dispatch]);

  const onSubmit = async (data: ResetPasswordRequest) => {
    try{
      data.token = token!;
      data.hash = hash!;
      await resetPassword(data).unwrap();
      setPageErrors([]);
      toast.publish(t('resetPassword.success'), 'success');
     } catch (e) {
      const responseError = e as ResponseError;
      if (responseError.data?.error) {
        setPageErrors(typeof responseError.data.error === 'string' ? [responseError.data.error] : responseError.data.error);
      } else {
        setPageErrors([t('error.unexpectedError')]);
      }
    }
  };

  return (
     <AnonymousLayout>
      <Helmet>
        <title>{getPageTitle(t('resetPassword.title'))} </title>
      </Helmet>
      {isLoading ? (
          <Loading />
        ) : (<>
      {checkExpireUrl?.isExpired ?
       <Container>
       <Box
         sx={{
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
         }}
       >
         <Typography variant="h1">{t('resetPassword.title')}</Typography>
        <Alert severity="error" sx={{ mt: 2, textAlign: 'left' }}>
          {t('resetPassword.expired1')}
          <Link href={`/forgot-password`}>
            {t('forgotPassword.title')}
          </Link>
          {t('resetPassword.expired2')}
        </Alert>
        </Box>
      </Container>
      :  
      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h1">{t('resetPassword.title')}</Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1, width: '100%' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box textAlign="center" sx={{ mt: 1 }}>
                <Alert severity="info" sx={{ mt: 2, textAlign: 'left' }}>
                <AlertTitle sx={{color: "#03a9f4"}}>{t('resetPassword.titleInfo')}</AlertTitle>
                  {t('resetPassword.instructions')}
                </Alert>
            </Box>
            <ControlledTextField
              name="newPassword"
              control={control}
              fieldError={errors.newPassword}
              label={t('resetPassword.newPassword')}
              forPassword={true}
              icon={<Lock />}
            />
            <ControlledTextField
              name="confirmPassword"
              control={control}
              fieldError={errors.confirmPassword}
              label={t('resetPassword.confirmPassword')}
              forPassword={true}
              icon={<Lock />}
            />
            <Box textAlign="center" sx={{ mt: 1 }}>
              {pageErrors.length > 0 &&
                pageErrors.map((error) => (
                  <Alert key={error} severity="error" sx={{ mt: 2, textAlign: 'left' }}>
                    {error}
                  </Alert>
              ))}
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
              >
                {t('resetPassword.submit')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      }
      </>)
    }
   </AnonymousLayout>
  );
}
