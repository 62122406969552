import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Container from '@mui/material/Container';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useGetPatientQuery } from '../../../app/services/patient';
import {
  useAddGoalMutation,
  useEditGoalMutation,
  useGetGoalDataQuery,
} from '../../../app/services/treatment-plan/goal';
import { useToast } from '../../../app/toast';
import PageHeader from '../../../common/components/PageHeader';
import PatientInfoCard from '../../../common/components/PatientInfoCard';
import RoundedContainer from '../../../common/components/RoundedContainer';
import DashboardLayout from '../../../common/layouts/Dashboard';
import { editBreadcrumbs, ReplaceType } from '../../../common/utils/editBreadcrums';
import { HttpStatusCode } from '../../../common/utils/httpStatusCode';
import { getPageTitle } from '../../../common/utils/pageUtils';
import { PageProps } from '../../../types/PageProps';
import { GoalItem } from '../../../types/treatment-plan/GoalType';
import ErrorPage from '../../error/ErrorPage';
import {
  setAddOrEditTreatmentGoalId,
  selectTreatmentPlanReloadOption,
  setTreatmentPlanPatientId,
  setTreatmentPlanReloadOption,
} from '../treatmentPlanSlice';
import GoalForm from './GoalForm';

export default function AddOrEditGoalPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { treatmentPlanGoalId, treatmentPlanId, patientId } = useParams();
  const [goalData, setGoalData] = useState<GoalItem | null>(null);
  const replacePath: ReplaceType[] = [
    { param: ':patientId', value: patientId! },
    { param: ':treatmentPlanId', value: treatmentPlanId! },
  ];

  const reloadGoal = useAppSelector(selectTreatmentPlanReloadOption);
  const [addGoal] = useAddGoalMutation();
  const [editGoal] = useEditGoalMutation();

  if (patientId) {
    dispatch(setTreatmentPlanPatientId({ patientId }));
  }

  const { data, error, refetch } = useGetGoalDataQuery(
    treatmentPlanGoalId ? { id: parseInt(treatmentPlanGoalId) } : skipToken
  );

  const { data: patientInfo } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  if (treatmentPlanGoalId) {
    dispatch(setAddOrEditTreatmentGoalId({ addOrEditGoalId: treatmentPlanGoalId }));
  }

  useEffect(() => {
    if (reloadGoal?.reload) {
      refetch();
      dispatch(setTreatmentPlanReloadOption({ reload: false }));
    }
  }, [reloadGoal, refetch, dispatch]);

  useEffect(() => {
    if (data?.data) {
      setGoalData(data.data);
    }
  }, [data?.data, goalData]);

  const { state } = useLocation();
  let { navBackUrl } = !!state && state;
  navBackUrl =
    navBackUrl || `/dashboard/patient/${patientId}/treatment-plan/edit/${treatmentPlanId}`;

  const onFormSubmit = async (goalData: GoalItem, successCallback?: () => void) => {
    if (treatmentPlanGoalId) {
      await editGoal({ ...goalData, id: parseInt(treatmentPlanGoalId) }).unwrap();
    } else if (treatmentPlanId) {
      const addedGoal = await addGoal({
        ...goalData,
        treatmentPlanId: parseInt(treatmentPlanId),
      }).unwrap();
      if (addedGoal?.data?.id) {
        navigate(
          `/dashboard/patient/${patientId}/treatment-plan/edit/${treatmentPlanId}/goal/edit/${addedGoal.data.id}`
        );
        dispatch(setAddOrEditTreatmentGoalId({ addOrEditGoalId: String(addedGoal.data.id) }));
      }
    }

    if (successCallback) {
      successCallback();
    }

    toast.publish(
      (treatmentPlanGoalId && t('treatmentPlans.goalForm.updateSuccess')) ||
        t('treatmentPlans.goalForm.addSuccess'),
      'success'
    );
  };

  if (!patientId || !treatmentPlanId) {
    return <ErrorPage statusCode={HttpStatusCode.Forbidden} />;
  }

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={editBreadcrumbs(breadcrumbs, ...replacePath)}>
      <Helmet>
        <title>
          {treatmentPlanGoalId
            ? getPageTitle(t('treatmentPlans.goalForm.edit'))
            : getPageTitle(t('treatmentPlans.goalForm.add'))}
        </title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={
            treatmentPlanGoalId
              ? t('treatmentPlans.goalForm.edit')
              : t('treatmentPlans.goalForm.add')
          }
          backUrl={navBackUrl}
        />
        <RoundedContainer sx={{ py: 2 }}>
          <PatientInfoCard data={patientInfo!} />
        </RoundedContainer>
        <GoalForm goalData={goalData || undefined} onFormSubmit={onFormSubmit} />
      </Container>
    </DashboardLayout>
  );
}
