import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';

const StyledText = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    '& fieldset, &:hover fieldset, &.Mui-focused fieldset, &.Mui-disabled fieldset': {
      borderColor: theme.xPalette.white,
    },
  },
}));

export default function YbocsCheckOtherQuestion({
  field,
  error,
  helperText,
  disabled,
}: InterviewQuestionProps) {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.xPalette.white,
          padding: '0 16px',
          mt: -2,
          width: 1,
          [theme.breakpoints.up('sm')]: {
            padding: '0 24px',
          },
        }}
      >
        <StyledText
          {...field}
          helperText={helperText}
          error={error}
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          disabled={disabled}
          value={field?.value || ''}
          sx={{
            my: 2,
            backgroundColor: disabled ? theme.xPalette.disabled : theme.xPalette.noteLightGrey,
            borderRadius: 2,
          }}
        />
      </Box>
    </>
  );
}
