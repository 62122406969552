import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetTreatmentPlanAuditLogQuery } from '../../app/services/treatment-plan/treatmentPlanReport';
import theme from '../../app/theme';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import DataTable from '../../common/components/DataTable';
import RoundedContainer from '../../common/components/RoundedContainer';
import { getEventTypeListByKey } from '../../common/utils/auditLogType';
import { formatDateTimeByPattern } from '../../common/utils/dateTimeUtils';
import { SortDirs } from '../../common/utils/sortInfo';
import { AuditLogItem } from '../../types/AuditLog';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectTreatmentPlanReloadOption, setTreatmentPlanReloadOption } from './treatmentPlanSlice';

const populateAuditLogName = (actionType: string) => {
  const logKeyNames = getEventTypeListByKey();
  const newLogKeyNames = {};

  for (const key in logKeyNames) {
    const newKey = key.split('-')[0];
    newLogKeyNames[newKey] = logKeyNames[key];
  }

  return newLogKeyNames[actionType];
};

export const PatientTreatmentAuditLogTypes = [
  'signedTreatmentSignatureByPatient',
  'refusedTreatmentSignatureByPatient',
  'signedViaEmailTextTreatmentSignatureByPatient',
  'refusedViaEmailTextTreatmentSignatureByPatient',
];

interface Props {
  treatmentPlanId?: number;
}

export default function TreatmentPlanAuditLogs({ treatmentPlanId }: Props) {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useAppDispatch();
  const reloadGoal = useAppSelector(selectTreatmentPlanReloadOption);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState('createdAt');
  const [sortDir, setSortDir] = useState('asc');

  const { data: auditLogs, refetch } = useGetTreatmentPlanAuditLogQuery(
    treatmentPlanId
      ? { page, pageSize: 10, sort: sortName, dir: sortDir, treatmentPlanId }
      : skipToken
  );

  useEffect(() => {
    if (reloadGoal?.reload) {
      refetch();
      dispatch(setTreatmentPlanReloadOption({ reload: false }));
    }
  }, [reloadGoal, refetch, dispatch]);

  const handleTableSorting = (sortKey: string) => {
    const newDir = sortDir === SortDirs.Asc ? SortDirs.Desc : SortDirs.Asc;
    setSortDir(newDir);
    setSortName(sortKey);
  };

  const renderDesktopData = (data?: AuditLogItem[]) => {
    return (
      <DataTable
        sort={{ field: sortName, dir: sortDir }}
        onSorted={handleTableSorting}
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
        }}
        headers={[
          {
            text: t('treatmentPlans.auditLog.header.createdAt'),
            sx: { width: '20%' },
            sortKey: 'createdAt',
          },
          { text: t('treatmentPlans.auditLog.header.user'), sx: { width: '20%' } },
          {
            text: t('treatmentPlans.auditLog.header.actionType'),
            sx: { width: '30%' },
            sortKey: 'actionType',
          },
          {
            text: t('treatmentPlans.auditLog.header.name'),
            sx: { width: '30%' },
            sortKey: 'targetName',
          },
        ]}
        items={
          data?.map((item: AuditLogItem) => [
            {
              component: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>
                    {formatDateTimeByPattern({
                      dateValue: item?.createdAt,
                      pattern: 'MM/DD/YYYY hh:mm a',
                      validPatterns: ['MM/DD/YYYY hh:mm a'],
                    })}
                  </Typography>
                </Box>
              ),
            },
            {
              component: (
                <Typography fontWeight={400} textAlign="left">
                  {PatientTreatmentAuditLogTypes.includes(item?.actionType)
                    ? t('treatmentPlans.auditLog.patient')
                    : item?.actionByLName && item?.actionByFName
                    ? `${item?.actionByLName}, ${item?.actionByFName}`
                    : `${item?.actionByLName || item?.actionByFName}`}
                </Typography>
              ),
            },
            {
              component: (
                <Typography fontWeight={400} textAlign="left">
                  {populateAuditLogName(item?.actionType) || ''}
                </Typography>
              ),
            },
            {
              component: (
                <Typography fontWeight={400} textAlign="left">
                  {item?.targetName || ''}
                </Typography>
              ),
            },
          ]) || []
        }
      />
    );
  };

  const renderPhoneData = (data?: AuditLogItem[]) => {
    return (
      <DataList
        hideDividerBottum={true}
        lineHeight={'auto'}
        customStyle={{ backgroundColor: theme.xPalette.noteLightGrey }}
        data={
          data?.map((item: AuditLogItem) => ({
            leftHeader: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography fontWeight="bold">
                  {formatDateTimeByPattern({
                    dateValue: item?.createdAt,
                    pattern: 'MM/DD/YYYY hh:mm a',
                    validPatterns: ['MM/DD/YYYY hh:mm a'],
                  })}
                </Typography>
              </Box>
            ),
            items: [
              [
                t('treatmentPlans.auditLog.header.user'),
                PatientTreatmentAuditLogTypes.includes(item?.actionType)
                  ? t('treatmentPlans.auditLog.patient')
                  : item?.actionByLName && item?.actionByFName
                  ? `${item?.actionByLName}, ${item?.actionByFName}`
                  : `${item?.actionByLName || item?.actionByFName}`,
              ],
              [
                t('treatmentPlans.auditLog.header.actionType'),
                populateAuditLogName(item?.actionType) || '',
              ],
              [t('treatmentPlans.auditLog.header.name'), item?.targetName || ''],
            ],
          })) || []
        }
      />
    );
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '18px!important',
          fontWeight: 'bold',
          paddingBottom: matches ? 0 : 2,
        }}
      >
        {t('treatmentPlans.auditLog.title')}
      </Typography>
      <Box>
        {!auditLogs?.items?.length ? (
          <RoundedContainer
            sx={{
              mt: 2,
              p: 2,
              background: theme.xPalette.background,
              color: 'rgba(0, 0, 0, 0.7)',
            }}
          >
            <Typography>{t('treatmentPlans.auditLog.error.notFound')}</Typography>
          </RoundedContainer>
        ) : (
          <Box>
            {matches ? renderDesktopData(auditLogs?.items) : renderPhoneData(auditLogs?.items)}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                py: matches ? 4 : 2,
                borderBottomLeftRadius: '15px',
                borderBottomRightRadius: '15px',
                background: theme.xPalette.white,
              }}
            >
              <CustomPagination
                changePage={(e, value) => {
                  setPage(value);
                }}
                currentPage={page}
                pages={auditLogs?.totalPages || 0}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
