import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { DefaultLanguage, languages, LanguageType } from '../../common/utils/languages';
import { monitorModeCode } from '../../common/utils/monitorsMode';
import {
  MonitorDataType,
  MonitorErrorAnswerMessagesType,
  MonitorFormatDto,
  MonitorTypeResponse,
  PatientMonitorData,
} from '../../types/MonitorTypes';
import { MonitorQuestionErrors, MonitorSubQuestionErrors } from '../../types/ResponseError';
import { clearCredentials } from '../auth/authSlice';

export interface MonitorStateType {
  languagesForMonitor: LanguageType[];
  monitorTypesData: MonitorDataType[] | [];
  monitorMode: string;
  patientMonitor?: PatientMonitorData;
  currentPatientId?: string;
  monitor?: MonitorFormatDto;
  monitorTypeId?: number;
  currentMonitorTestId?: number;
  errorAnswerMessages?: MonitorErrorAnswerMessagesType;
  assessmentUrl?: string;
}

const initialState: MonitorStateType = {
  languagesForMonitor: [],
  monitorTypesData: [],
  monitorMode: '',
  patientMonitor: undefined,
  currentPatientId: undefined,
  monitor: undefined,
  monitorTypeId: undefined,
  currentMonitorTestId: undefined,
  errorAnswerMessages: undefined,
  assessmentUrl: '',
};

const monitorSlide = createSlice({
  name: 'monitor',
  initialState,
  reducers: {
    setMonitorTypesData: (
      state,
      { payload: { monitorTypesData } }: PayloadAction<{ monitorTypesData: MonitorDataType[] }>
    ) => {
      const newMonitorTypesData =
        monitorTypesData?.filter((monitorType: MonitorDataType) => monitorType) || [];

      const monitorTypeLanguages = new Set(
        newMonitorTypesData.map((monitorType: MonitorDataType) =>
          monitorType.language !== DefaultLanguage.Universal
            ? monitorType.language
            : DefaultLanguage.English
        )
      );

      const languagesForMonitor: LanguageType[] = [];
      Array.from(monitorTypeLanguages).forEach((monitorTypeLanguage: string) => {
        if (!!languages[monitorTypeLanguage]) {
          languagesForMonitor.push(languages[monitorTypeLanguage]);
        }
      });

      state.languagesForMonitor = languagesForMonitor.sort((a: LanguageType, b: LanguageType) =>
        a.name.localeCompare(b.name)
      );

      state.monitorTypesData = [...newMonitorTypesData].slice().sort((a, b) => a.order - b.order);
    },
    setMonitorMode: (
      state,
      { payload: { monitorMode } }: PayloadAction<{ monitorMode: string }>
    ) => {
      state.monitorMode = monitorMode;
    },
    setPatientMonitorData: (
      state,
      { payload: { data } }: PayloadAction<{ data: PatientMonitorData }>
    ) => {
      state.patientMonitor = data;
    },
    clearPatientMonitorData: (state) => {
      state.patientMonitor = undefined;
    },
    setCurrentMonitor: (
      state,
      {
        payload: { monitorData: monitorResponse },
      }: PayloadAction<{ monitorData: MonitorTypeResponse }>
    ) => {
      state.currentMonitorTestId = monitorResponse.monitorTestId || 0;
      state.monitorTypeId = monitorResponse.monitorId;
      state.currentPatientId = monitorResponse.nViewPatientId || '';
      state.monitor = monitorResponse.monitor || {};
      state.monitorMode = monitorModeCode[monitorResponse.monitorMode];
      state.errorAnswerMessages = {};
      state.assessmentUrl = monitorResponse.assessmentUrl || '';
    },
    clearCurrentMonitor: (state) => {
      // note: don't clear interviewMode to allow showing correct text in complete page
      state.currentMonitorTestId = 0;
      state.monitorTypeId = undefined;
      state.currentPatientId = undefined;
      state.monitor = undefined;
      state.errorAnswerMessages = {};
      state.assessmentUrl = '';
    },
    setErrorFromMonitorResponse: (
      state,
      { payload: { errorQuestions } }: PayloadAction<{ errorQuestions: MonitorQuestionErrors[] }>
    ) => {
      let errorMessages = {};
      errorQuestions?.forEach((error: MonitorQuestionErrors) => {
        let subErrorMessages = {};
        if (error.subQuestions) {
          error.subQuestions.forEach((subError: MonitorSubQuestionErrors) => {
            subErrorMessages = {
              ...subErrorMessages,
              [subError.subQuestionId]: subError.message || '',
            };
          });
        }
        errorMessages = {
          ...errorMessages,
          [error.questionId]: {
            message: error.message || '',
            subErrors: { ...subErrorMessages },
          },
        };
      });
      state.errorAnswerMessages = errorMessages;
    },
  },
  extraReducers: (builder) => {
    // clear monitor data when log out
    builder.addCase(clearCredentials, (state) => ({
      ...state,
      ...initialState,
      // retains below state for patient monitor flow
      monitorMode: state.monitorMode,
      patientMonitor: state.patientMonitor,
    }));
  },
});

export const {
  setMonitorTypesData,
  setMonitorMode,
  setPatientMonitorData,
  clearPatientMonitorData,
  setCurrentMonitor,
  clearCurrentMonitor,
  setErrorFromMonitorResponse,
} = monitorSlide.actions;

export const selectLanguagesForMonitor = (state: RootState) => state.monitor.languagesForMonitor;
export const selectMonitorTypesData = (state: RootState) => state.monitor.monitorTypesData;
export const selectMonitorMode = (state: RootState) => state.monitor.monitorMode;
export const selectPatientMonitorData = (state: RootState) => state.monitor.patientMonitor;
export const selectMonitor = (state: RootState) => state.monitor.monitor;
export const selectErrorAnswerMessages = (state: RootState) => state.monitor.errorAnswerMessages;
export const selectAssessmentUrl = (state: RootState) => state.monitor.assessmentUrl;

export default monitorSlide.reducer;
