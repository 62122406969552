export const SESSION_START_COOKIE_ID = 'sst'; // sst = Session Start Time

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

export const isSessionCookieExisted = () => !!getCookie(SESSION_START_COOKIE_ID);

export const saveSessionCookie = () => {
  document.cookie = `${SESSION_START_COOKIE_ID}=${Date.now()}; path=/`;
};
