import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './app/App';
import { invalidateI18nCache } from './app/i18n';
import store, { persistor } from './app/store';
import theme from './app/theme';
import { ToastProvider } from './app/toast';
import Loading from './common/components/Loading';
import { AppConfig } from './common/utils/commonUtils';
import { logger } from './common/utils/logger';
import reportWebVitals from './reportWebVitals';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';

const appVersion = AppConfig.version;
logger.info(`Build version: ${appVersion}`);
invalidateI18nCache(appVersion);

// Set up mocks
const mockEnabled = '' + process.env.REACT_APP_MOCK_ENABLED === 'true';
if (process.env.NODE_ENV === 'development' || mockEnabled) {
  const { worker } = require('./mocks/browser');
  worker.start();
}
const newRelicEnabled = process.env.REACT_APP_NEW_RELIC_ENABLED === 'true';
logger.info(`New Relic ${newRelicEnabled ? 'Enabled' : 'Disabled'}.`);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <HelmetProvider>
      {(newRelicEnabled && <Helmet>
        <script
          src={process.env.PUBLIC_URL + '/newrelic-browser.js'}
          crossOrigin='anonymous'
          async
        ></script>
      </Helmet>)}
      <React.StrictMode>
        <ToastProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                {/* TODO: use global loading icon */}
                <Suspense fallback={<Loading />}>
                  <App />
                </Suspense>
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </ToastProvider>
      </React.StrictMode>
    </HelmetProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
