import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import Loading from '../../common/components/Loading';
import RoundedContainer from '../../common/components/RoundedContainer';
import FieldNote from './FieldNote';

interface DataList {
  author: string;
  dateAdded: string;
  title: string;
  body: string;
}

interface DataListProps {
  data?: DataList[];
  isLoading: boolean;
}

const StyledBoxNote = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
}));

export default function DataListNote({ data, isLoading }: DataListProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderHeader = (text: string) => {
    return (
      <Typography
        component="h1"
        sx={(theme) => ({
          flexBasis: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          textAlign: 'left',
          fontSize: '20px',
          fontWeight: 700,
          p: '4px 16px',
          mx: 2,
          [theme.breakpoints.up('sm')]: {
            px: 0,
          },
        })}
      >
        {text}
      </Typography>
    );
  };

  return (
    <RoundedContainer sx={{ backgroundColor: theme.xPalette.background, py: 2, mt: 2 }}>
      {isLoading ? (
        <Loading />
      ) : (
        <StyledBoxNote>
          {data?.length ? (
            data.map((row: DataList, index) => (
              <RoundedContainer sx={{ px: 1 }} key={index}>
                {renderHeader(row.title)}
                <FieldNote note={row.body} key={index} />
                <Divider
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: '4px 16px',
                    mx: 2,
                    [theme.breakpoints.up('sm')]: {
                      px: 0,
                    },
                  })}
                />
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: '4px 16px',
                    mx: 2,
                    [theme.breakpoints.up('sm')]: {
                      px: 0,
                    },
                  })}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      textAlign: 'left',
                      color: theme.xPalette.shadeGrey,
                    }}
                  >
                    {row.author}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      textAlign: 'right',
                      color: theme.xPalette.shadeGrey,
                    }}
                  >
                    {row.dateAdded}
                  </Box>
                </Box>
              </RoundedContainer>
            ))
          ) : (
            <Typography component="h4">{t('patientNote.blankPatientNote')}</Typography>
          )}
        </StyledBoxNote>
      )}
    </RoundedContainer>
  );
}
