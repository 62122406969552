import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { QuestionAnswerProps } from '../../../../types/ReportType';
import { InterviewReportType } from '../../../interview/interviewUtils';
import ReportNotes from '../ReportNotes';
import useCustomQuestionInfo from './useCustomQuestionInfo';

export default function NumberAnswer({
  questionAnswer,
  moduleType,
  subModuleType,
  assessmentType,
  notes,
  assessmentName,
}: QuestionAnswerProps) {
  const haveInterviewName = assessmentName && assessmentName.includes('SDS');
  const { questionDisplay, promptDisplay, answerValue, scoreForQuestion } = useCustomQuestionInfo({
    initialState: questionAnswer,
    moduleType,
    subModuleType,
    assessmentType,
  });

  return (
    <>
      <Box
        key={questionAnswer.questionId}
        sx={{
          display: 'flex',
          flexDirection: haveInterviewName ? 'column' : 'row',
          alignItems: 'center',
          columnGap: 1,
          my: 3,
          width: '100%',
          pageBreakInside: 'avoid',
        }}
      >
        <Box sx={{ width: haveInterviewName ? '100%' : '15%', display: 'flex', columnGap: 1 }}>
          <Typography
            component={haveInterviewName ? 'div' : 'span'}
            fontWeight={haveInterviewName ? 'bold' : 500}
            textAlign={haveInterviewName ? 'center' : 'unset'}
            width={haveInterviewName ? '100%' : 'unset'}
          >
            {questionAnswer.title || ''}
          </Typography>
          <Typography component={'span'} fontWeight={500}>
            {questionAnswer.subTitle || ''}
          </Typography>
        </Box>
        <Box sx={{ width: '75%' }}>
          {moduleType !== InterviewReportType.SdsSts && promptDisplay && (
            <Typography
              fontWeight="bold"
              dangerouslySetInnerHTML={{
                __html: promptDisplay,
              }}
            ></Typography>
          )}

          <Typography
            fontWeight={500}
            dangerouslySetInnerHTML={{
              __html: questionDisplay,
            }}
          ></Typography>
          {notes?.length && (
            <ReportNotes reportNotes={notes} questionId={questionAnswer.questionId} />
          )}
        </Box>

        <Typography
          sx={{ width: '10%' }}
          component={'span'}
          fontWeight={'bold'}
          textAlign={'right'}
        >
          {assessmentName !== 'SDS' &&
          !questionAnswer.shouldFillQuestionWAnswerValue &&
          !!answerValue
            ? answerValue
            : ''}
        </Typography>
        <Typography sx={{ width: '5%' }} component={'span'} fontWeight={'bold'} textAlign={'right'}>
          {scoreForQuestion}
        </Typography>
      </Box>
    </>
  );
}
