import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function ExternalRedirectPage() {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('url');

  useEffect(() => {
    window.location.href = redirectUrl || '/';
  }, [redirectUrl]);

  return <></>;
}
