import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { StyledButton, StyledNumberField } from './NumberQuestion';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '8px',

  '&:last-of-type': {
    color: theme.xPalette.lightGrey,
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
  minWidth: '80px',
}));

export const StyledBox = styled(Box)(() => ({
  width: '100%',
  margin: '16px 0',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
}));

enum TimeDayMonthName {
  IncreaseYear = 'increaseYear',
  DecreaseYear = 'decreaseYear',
  IncreaseMonth = 'increaseMonth',
  DecreaseMonth = 'decreaseMonth',
  IncreaseDay = 'increaseDay',
  DecreaseDay = 'decreaseDay',
}

export default function TimeDayMonthQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
}: InterviewQuestionProps) {
  const { t } = useTranslation();
  const [inputYear, setInputYear] = useState<string>('0');
  const [inputMonth, setInputMonth] = useState<string>('0');
  const [inputDay, setInputDay] = useState<string>('0');
  const savedValue = field?.value;
  const shouldRequestInterviewRef = useRef(true);

  const valueSetterMap: { [key: string]: (value: any) => void } = {
    valueYear: setInputYear,
    valueMonth: setInputMonth,
    valueDay: setInputDay,
  };

  const handleIncreaseOrDecrease = (valueName: any) => {
    const value: any = {
      increaseYear: (parseInt(inputYear) + 1).toString(),
      decreaseYear: (parseInt(inputYear) - 1).toString(),
      increaseMonth: (parseInt(inputMonth) + 1).toString(),
      decreaseMonth: (parseInt(inputMonth) - 1).toString(),
      increaseDay: (parseInt(inputDay) + 1).toString(),
      decreaseDay: (parseInt(inputDay) - 1).toString(),
    };
    const setTimeValue = value[valueName];

    if (
      valueName === TimeDayMonthName.IncreaseYear ||
      valueName === TimeDayMonthName.DecreaseYear
    ) {
      setInputYear(setTimeValue);
    }

    if (
      valueName === TimeDayMonthName.IncreaseMonth ||
      valueName === TimeDayMonthName.DecreaseMonth
    ) {
      setInputMonth(setTimeValue);
    }

    if (valueName === TimeDayMonthName.IncreaseDay || valueName === TimeDayMonthName.DecreaseDay) {
      setInputDay(setTimeValue);
    }
  };

  const handleChange = (event: any) => {
    const setter = valueSetterMap[event.target.id];
    if (setter) {
      setter(event.target.value);
    }
  };

  useEffect(() => {
    if (savedValue) {
      // Split "123456" to ["12", "34", "56"]
      const values = savedValue.match(/(..)/g);
      if (shouldRequestInterviewRef.current) {
        if (values.length === 3) {
          shouldRequestInterviewRef.current = false;
          setInputYear('' + (parseInt(values[0]) || 0));
          setInputMonth('' + (parseInt(values[1]) || 0));
          setInputDay('' + (parseInt(values[2]) || 0));
        }
      }
    }
  }, [savedValue]);

  useEffect(() => {
    const timeDayMonthValue = `${inputYear}${inputMonth}${inputDay}`;
    setValue(field?.name!, timeDayMonthValue);
  }, [inputYear, inputMonth, inputDay, setValue, field]);

  return (
    <Box sx={{ my: 2 }}>
      <FormControl error={error} variant="standard">
        {question.questionPrompt && (
          <StyledTitle
            dangerouslySetInnerHTML={{
              __html: question.questionPrompt,
            }}
          ></StyledTitle>
        )}
        <StyledTitle
          dangerouslySetInnerHTML={{
            __html: question.questionText!,
          }}
        ></StyledTitle>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              columnGap: 2,
            }}
          >
            <StyledTypography>{t('interview.form.yearLabel')}</StyledTypography>
            <StyledBox>
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<MinusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeDayMonthName.DecreaseYear)}
              ></StyledButton>
              <StyledNumberField
                id="valueYear"
                variant="outlined"
                error={error}
                onChange={handleChange}
                onBlur={handleChange}
                value={inputYear}
              />
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<PlusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeDayMonthName.IncreaseYear)}
              ></StyledButton>
            </StyledBox>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              columnGap: 2,
            }}
          >
            <StyledTypography>{t('interview.form.monthLabel')}</StyledTypography>
            <StyledBox>
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<MinusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeDayMonthName.DecreaseMonth)}
              ></StyledButton>
              <StyledNumberField
                id="valueMonth"
                variant="outlined"
                error={error}
                onChange={handleChange}
                onBlur={handleChange}
                value={inputMonth}
              />
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<PlusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeDayMonthName.IncreaseMonth)}
              ></StyledButton>
            </StyledBox>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              columnGap: 2,
            }}
          >
            <StyledTypography>{t('interview.form.dayLabel')}</StyledTypography>
            <StyledBox>
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<MinusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeDayMonthName.DecreaseDay)}
              ></StyledButton>
              <StyledNumberField
                id="valueDay"
                variant="outlined"
                error={error}
                onChange={handleChange}
                onBlur={handleChange}
                value={inputDay}
              />
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<PlusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeDayMonthName.IncreaseDay)}
              ></StyledButton>
            </StyledBox>
          </Box>
        </Box>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
