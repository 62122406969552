import { saveAs } from 'file-saver';
import { buildAccountUrl, buildInterviewUrl, buildMonitorUrl } from '../../app/services';
import { DownloadReportRequest } from '../../types/InterviewReportRequest';
import { AssessmentType } from './assessmentType';

export const saveReportDataToFile = (
  data: any,
  type: any = { type: 'application/octet-stream' },
  fileName = 'interviewSummary.pdf'
) => {
  saveAs(new Blob([data], type), fileName);
};

export const saveReportDataToCsvFile = (data: any, fileName = 'usasge_report.csv') => {
  saveAs(new Blob([data], { type: 'text/csv' }), fileName);
};

export const openReport = (
  data: DownloadReportRequest & { fileName: string; token: string },
  w?: Window | null
) => {
  const subUrl = data.latestVersion ? `.pdf?token=` : `?token=`;
  const finalUrl =
    data.type === AssessmentType.Interview
      ? `${buildInterviewUrl(`/v1/interview-report/download-report/`)}` +
        data.fileName +
        subUrl +
        data.token
      : `${buildMonitorUrl(`/v1/monitor-report/download-report/`)}` +
        data.fileName +
        `.pdf?token=` +
        data.token;

  if (navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i)) {
    setTimeout(() => {
      window.location.assign(finalUrl);
    });
  } else if (w) {
    w.location.href = finalUrl;
  } else {
    setTimeout(() => {
      window.location.assign(finalUrl);
    });
  }
};

export const openTreatmentPlanReport = (treatmentPlanId: any) => {
  const finalUrl = `${buildAccountUrl(
    `/v1/account/treatment-plan-report/download?treatmentPlanId=${treatmentPlanId}`
  )}`;
  if (navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i)) {
    setTimeout(() => {
      window.location.assign(finalUrl);
    });
  } else {
    let a = document.createElement('a');
    a.setAttribute('href', finalUrl);
    a.setAttribute('rel', 'noopener noreferrer');
    a.setAttribute('target', '_blank');
    a.setAttribute('style', 'display: none');
    document.body.appendChild(a);
    try {
      a.click();
    } catch (e) {
      setTimeout(() => {
        window.location.assign(finalUrl);
      });
    }
    document.body.removeChild(a);
  }
};

export const replaceFullYear = (text?: string) => {
  return text ? text.replace('%s', new Date().getFullYear().toString()) : '';
};

export const convertCopyRight = (copyRight?: string) => {
  return replaceFullYear(copyRight);
};

export const newlinesToBr = (text: string) => {
  return text ? text.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
};

export const parseLegal = (legal: string) => {
  return legal ? newlinesToBr(replaceFullYear(legal)) : '';
};
