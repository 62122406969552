import { useState, useEffect, useRef } from 'react';
import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectResetQuestion, setResetQuestion } from '../../interview/interviewSlice';
import { setStsEventQuestion } from '../interviewSlice';

export const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: theme.xPalette.lightGrey,
  '&:hover': {
    borderColor: theme.xPalette.lightGrey,
  },
  '&:last-of-type': {
    borderRadius: '0 10px 10px 0',
    backgroundColor: theme.xPalette.noteLightGrey,
  },
  '&:first-of-type': {
    borderRadius: '10px 0 0 10px',
  },
  '&.MuiButton-root .MuiButton-startIcon': {
    color: theme.xPalette.lightGrey,
    margin: 0,
  },
}));

export const StyledNumberField = styled(TextField)(({ theme }) => ({
  width: '30%',
  '& .MuiOutlinedInput-root': {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '& fieldset': {
      borderRadius: 0,
      borderColor: theme.xPalette.lightGrey,
    },
    '&:hover fieldset': {
      borderColor: theme.xPalette.lightGrey,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.xPalette.lightGrey,
    },
  },

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledBox = styled(Box)(() => ({
  width: '100%',
  margin: '16px 0',
  display: 'flex',
  alignItems: 'stretch',
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

const checkType = (input: any) => (typeof input === 'string' ? parseInt(input) : input);

export default function NumberQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  stsQuestionRelative,
}: InterviewQuestionProps) {
  const [inputNumber, setInputNumber] = useState<number>(0);
  const savedValue = field?.value;
  const shouldRequestInterviewRef = useRef(true);
  const isStsEventRelativeQuestion =
    stsQuestionRelative && stsQuestionRelative.includes(question.name || question.title);
  const dispatch = useAppDispatch();
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);

  const setNumberValue = (value: number) => {
    setInputNumber(value);
    if (isStsEventRelativeQuestion) {
      dispatch(setStsEventQuestion({ questionNumber: question.name! || question.title!, value }));
    }
  };

  const onValueChanged = (event: any) => {
    const valueNumber = event.target.value;
    setNumberValue(valueNumber);
  };

  const onIncreaseValue = () => {
    const value = (checkType(inputNumber) || 0) + 1;
    setNumberValue(value);
    dispatch(setResetQuestion({ reset: false }));
  };

  const onDecreaseValue = () => {
    const value = (checkType(inputNumber) || 0) - 1;
    setNumberValue(value);
    dispatch(setResetQuestion({ reset: false }));
  };

  useEffect(() => {
    if (savedValue !== '' && savedValue !== '0') {
      if (shouldRequestInterviewRef.current) {
        shouldRequestInterviewRef.current = false;
        setInputNumber(parseInt(savedValue) || 0);
      }
    }
  }, [dispatch, savedValue]);

  useEffect(() => {
    setValue(field?.name!, inputNumber);
    if (isResetQuestion) {
      setInputNumber(0);
    }
  }, [field?.name, inputNumber, setValue, isResetQuestion]);

  return (
    <Box>
      <FormControl sx={{ width: '100%' }} error={error} variant="standard">
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.title ? `${question.title!}:` : '',
          }}
        ></StyledTypography>
        {isStsEventRelativeQuestion && (
          <StyledTypography
            dangerouslySetInnerHTML={{
              __html: question.questionPrompt!,
            }}
          ></StyledTypography>
        )}
        <Typography
          dangerouslySetInnerHTML={{
            __html: question.questionText!,
          }}
        ></Typography>
        <StyledBox>
          <StyledButton
            type="button"
            variant="outlined"
            startIcon={<MinusIcon fontSize="medium" />}
            onClick={onDecreaseValue}
          ></StyledButton>
          <StyledNumberField
            {...field}
            error={error}
            value={inputNumber}
            variant="outlined"
            onChange={onValueChanged}
            onBlur={onValueChanged}
          />
          <StyledButton
            type="button"
            variant="outlined"
            startIcon={<PlusIcon fontSize="medium" />}
            onClick={onIncreaseValue}
          ></StyledButton>
        </StyledBox>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
