import { logger } from "./logger";

export const newRelic = {
  addPageAction: (name: string, ...data: any) => {
    try {
      if (window['newrelic']) {
        window['newrelic'].addPageAction(name, ...data);
      }
    } catch (err) {
      logger.error(err);
    }
  },
};
