import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { clearCredentials } from '../../auth/authSlice';

export interface ReminderState {
  editReminderGroupId: number;
}

const initialState: ReminderState = {
  editReminderGroupId: 0,
};

const reminderSlice = createSlice({
  name: 'reminder',
  initialState,
  reducers: {
    setReminderGroupId: (
      state,
      { payload: { reminderGroupId } }: PayloadAction<{ reminderGroupId: number }>
    ) => {
      state.editReminderGroupId = reminderGroupId;
    },
  },

  extraReducers: (builder) => {
    // clear patient data when log out
    builder.addCase(clearCredentials, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const {
  setReminderGroupId,
} = reminderSlice.actions;

export const selectReminderGroupId = (state: RootState) => state.reminder.editReminderGroupId;

export default reminderSlice.reducer;
