import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useGetRescoreAccountsQuery,
  useGetRescorePatientsQuery,
} from '../../app/services/interview';
import theme from '../../app/theme';
import ConfirmRescorePopup from '../../common/components/ConfirmRescorePopup';
import DataList from '../../common/components/DataList';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import SubmitButton from '../../common/components/SubmitButton';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { RescoreAction } from '../../common/utils/callActionType';
import { getPatientFullName, getPatientName } from '../../common/utils/patientUtils';
import { JobTypes } from '../../common/utils/rescoreType';
import { ConfirmContentPopup } from '../../types/CommonType';
import { PopupContent } from '../../types/PopupType';
import {
  RescoreAccount,
  RescoreInterviewFormInfo,
  RescorePatient,
} from '../../types/RescoreInterviewJob';
import { ResponseError } from '../../types/ResponseError';

interface JobFormProps {
  onFormSubmit: (data: RescoreInterviewFormInfo, successCallback?: () => void) => Promise<void>;
}

const defaultFormValues: RescoreInterviewFormInfo = {
  jobName: '',
  jobType: JobTypes.accounts,
  clinicAccount: '',
  rescoreClinicAccounts: [],
  rescorePatients: [],
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyleFormItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  height: 'auto',
  paddingBottom: '16px',
  marginTop: '10px',
}));

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',
  },
}));

const StyleMultipleAutocomplete = styled(Autocomplete)(() => ({
  '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    width: '100%',
  },
}));

const jobTypes = [
  {
    id: JobTypes.accounts,
    name: 'Clinic Accounts to be Rescored',
  },
  {
    id: JobTypes.patients,
    name: 'Patients to be Rescored',
  },
];

export default function AddJobForm({ onFormSubmit }: JobFormProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const changeAccountContentPopup = {
    title: t('interviews.rescoreJobs.addJobs.form.changeAccountPopup.title'),
    description: t('interviews.rescoreJobs.addJobs.form.changeAccountPopup.description'),
    btnOk: t('interviews.rescoreJobs.addJobs.form.changeAccountPopup.btnOk'),
    btnClose: t('interviews.rescoreJobs.addJobs.form.changeAccountPopup.btnClose'),
  } as PopupContent;
  const changeJobTypeContentPopup = {
    title: t('interviews.rescoreJobs.addJobs.form.changeJobTypePopup.title'),
    description: t('interviews.rescoreJobs.addJobs.form.changeJobTypePopup.description'),
    btnOk: t('interviews.rescoreJobs.addJobs.form.changeJobTypePopup.btnOk'),
    btnClose: t('interviews.rescoreJobs.addJobs.form.changeJobTypePopup.btnClose'),
  } as PopupContent;
  const confirmRescoreContentPopup = {
    title: t('interviews.rescoreJobs.addJobs.form.confirmRescorePopup.title'),
    information: {
      jobName: '',
      jobType: '',
      affectedPatients: [],
      affectedClinicAccounts: [],
      totalInterviewsToBeRescored: 0,
    },
    btnOk: t('interviews.rescoreJobs.addJobs.form.confirmRescorePopup.btnOk'),
    btnClose: t('interviews.rescoreJobs.addJobs.form.confirmRescorePopup.btnClose'),
  } as ConfirmContentPopup;

  const [clinicAccountId, setClinicAccountId] = useState<string>('');
  const [clinicAccountTemp, setClinicAccountTemp] = useState<string>('');
  const [jobType, setJobType] = useState<string>(JobTypes.accounts);
  const [jobTypeTemp, setJobTypeTemp] = useState<string>('');
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [callAction, setCallAction] = useState<string>('');
  const [dataSubmitTemp, setDataSubmitTemp] = useState<RescoreInterviewFormInfo | undefined>(
    undefined
  );
  const [confirmContentPopup, setConfirmContentPopup] = useState<ConfirmContentPopup>(
    confirmRescoreContentPopup
  );
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState<boolean>(false);
  const [patientDataDisplay, setPatientDataDisplay] = useState<RescorePatient[]>([]);
  const [accountDataDisplay, setAccountDataDisplay] = useState<RescoreAccount[]>([]);
  const [multiCheckPatients, setMultiCheckPatients] = useState<RescorePatient[]>([]);
  const [multiCheckAccounts, setMultiCheckAccounts] = useState<RescoreAccount[]>([]);
  const contentPopup = useAppSelector(selectContentPopup);
  const isOpenPopup = useAppSelector(selectIsOpenPopup);

  const validationSchema = yup
    .object()
    .shape({
      jobName: yup.string().required(t('interviews.rescoreJobs.addJobs.error.blankJobName')),
      jobType: yup.string().required(t('interviews.rescoreJobs.addJobs.error.blankJobType')),
      clinicAccount: yup.string().when('jobType', {
        is: (jobType: string) => jobType === JobTypes.patients,
        then: yup.string().required(t('interviews.rescoreJobs.addJobs.error.blankClinicAccount')),
      }),
      rescorePatients: yup.mixed().when('jobType', {
        is: (jobType: string) => jobType === JobTypes.patients,
        then: yup
          .mixed()
          .test(
            'validate-rescorePatient',
            t('interviews.rescoreJobs.addJobs.error.blankPatient'),
            function (rescorePatients) {
              if (Array.isArray(rescorePatients) && rescorePatients.length === 0) {
                return false;
              }
              return true;
            }
          ),
      }),
      rescoreClinicAccounts: yup.mixed().when('jobType', {
        is: (jobType: string) => jobType === JobTypes.accounts,
        then: yup
          .mixed()
          .test(
            'validate-rescoreAccount',
            t('interviews.rescoreJobs.addJobs.error.blankClinicAccounts'),
            function (rescoreClinicAccounts) {
              if (Array.isArray(rescoreClinicAccounts) && rescoreClinicAccounts.length === 0) {
                return false;
              }
              return true;
            }
          ),
      }),
    })
    .required();

  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const patientTableHeaders: string[] = Object.values(
    t('interviews.rescoreJobs.addJobs.managePatientList.tableHeaders', {
      returnObjects: true,
    })
  );
  const accountTableHeaders: string[] = Object.values(
    t('interviews.rescoreJobs.addJobs.manageAccountList.tableHeaders', {
      returnObjects: true,
    })
  );
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" color="secondary" />;
  const { data: rescoreAccountData } = useGetRescoreAccountsQuery();
  const { data: rescorePatientData } = useGetRescorePatientsQuery({ clinicAccountId });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
  } = useForm<RescoreInterviewFormInfo>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (rescoreAccountData) {
      const sortedAccountData = rescoreAccountData?.accounts
        ?.slice()
        .sort((a: RescoreAccount, b: RescoreAccount) =>
          a.name.toLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
        );
      setValue('clinicAccount', sortedAccountData[0].id);
      setClinicAccountId(sortedAccountData[0].id);
      setAccountDataDisplay(sortedAccountData);
    }
  }, [rescoreAccountData, setValue]);

  useEffect(() => {
    if (rescorePatientData) {
      const sortedPatientData = rescorePatientData.patients
        .slice()
        .sort((a: RescorePatient, b: RescorePatient) =>
          getPatientName(a).toLowerCase() > getPatientName(b).toLowerCase() ? 1 : -1
        );
      setPatientDataDisplay(sortedPatientData);
    }
  }, [rescorePatientData, setValue]);

  const getOptionLabelClinicAccount = (option: any) =>
    option.name ? `${option.name}(${option.id})` : '';

  const filterPatientOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getOptionLabelPatient(option),
  });

  const getOptionLabelPatient = (option: any) => getPatientName(option);

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getOptionLabelClinicAccount(option),
  });
  const deleteIcon = (id: string, jobType: string) => {
    const deletePatientRescore = () => {
      const deletedPatientRescore = multiCheckPatients.filter(
        (patient: RescorePatient) => patient.id !== id
      );
      setMultiCheckPatients(deletedPatientRescore);
      setValue('rescorePatients', deletedPatientRescore);
    };
    const deleteAccountRescore = () => {
      const deletedAccountRescore = multiCheckAccounts.filter(
        (account: RescoreAccount) => account.id !== id
      );
      setMultiCheckAccounts(deletedAccountRescore);
      setValue('rescoreClinicAccounts', deletedAccountRescore);
    };
    return (
      <IconButton
        onClick={jobType === JobTypes.patients ? deletePatientRescore : deleteAccountRescore}
      >
        <HighlightOffIcon sx={{ pointerEvents: 'none' }} />
      </IconButton>
    );
  };

  const renderListPatientDesktop = (data: RescorePatient[]) => {
    return (
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ borderRadius: '16px 16px 0 0', maxHeight: 440, margin: 1 }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {patientTableHeaders.map((header, index) => {
                return (
                  <StyledTableCell key={`${header} ${index}`} align={'inherit'}>
                    {header}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: RescorePatient) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell>{item.id}</StyledTableCell>
                  <StyledTableCell>{getPatientFullName(item)}</StyledTableCell>
                  <StyledTableCell>{item.externalId}</StyledTableCell>
                  <StyledTableCell>{item.interviewresults}</StyledTableCell>
                  <StyledTableCell>{deleteIcon(item.id, JobTypes.patients)}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderListPatientMobile = (data: RescorePatient[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: RescorePatient) => {
          const listItems = [
            [
              <>{t('interviews.rescoreJobs.addJobs.managePatientList.tableHeaders.patientName')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{getPatientFullName(item)}</Box>,
            ],
            [
              <>
                {t(
                  'interviews.rescoreJobs.addJobs.managePatientList.tableHeaders.patientExternalId'
                )}
              </>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.externalId}</Box>,
            ],
            [
              <>{t('interviews.rescoreJobs.addJobs.managePatientList.tableHeaders.interviews')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.interviewresults}</Box>,
            ],
          ];
          return {
            leftHeader: (
              <Typography variant="body1" fontWeight="bold" fontSize={20}>
                {`${item.id}`}
              </Typography>
            ),
            rightHeader: deleteIcon(item.id, JobTypes.patients),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  const renderListAccountDesktop = (data: RescoreAccount[]) => {
    return (
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ borderRadius: '16px 16px 0 0', maxHeight: 440, margin: 1 }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {accountTableHeaders.map((header, index) => {
                return (
                  <StyledTableCell key={`${header} ${index}`} align={'inherit'}>
                    {header}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: RescoreAccount) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell>{item.id}</StyledTableCell>
                  <StyledTableCell>{item.name}</StyledTableCell>
                  <StyledTableCell>{item.interviewresults}</StyledTableCell>
                  <StyledTableCell>{item.status}</StyledTableCell>
                  <StyledTableCell>{deleteIcon(item.id, JobTypes.accounts)}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderListAccountMobile = (data: RescoreAccount[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: RescoreAccount) => {
          const listItems = [
            [
              <>{t('interviews.rescoreJobs.addJobs.manageAccountList.tableHeaders.accountName')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.name}</Box>,
            ],
            [
              <>{t('interviews.rescoreJobs.addJobs.manageAccountList.tableHeaders.interviews')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.interviewresults}</Box>,
            ],
            [
              <>{t('interviews.rescoreJobs.addJobs.manageAccountList.tableHeaders.status')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.status}</Box>,
            ],
          ];
          return {
            leftHeader: (
              <Typography variant="body1" fontWeight="bold" fontSize={20}>
                {`${item.id}`}
              </Typography>
            ),
            rightHeader: deleteIcon(item.id, JobTypes.accounts),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  const showChangeAccountContentPopup = async (clinicAccount: string) => {
    dispatch(setContentPopup({ content: changeAccountContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setClinicAccountTemp(clinicAccount);
    setCallAction(RescoreAction.ChangeAccount);
  };

  const showChangeJobTypeContentPopup = async (jobType: string) => {
    dispatch(setContentPopup({ content: changeJobTypeContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setJobTypeTemp(jobType);
    setCallAction(RescoreAction.ChangeJobType);
  };

  const showConfirmRescoreContentPopup = async (data: RescoreInterviewFormInfo) => {
    let affectedPatients;
    let affectedClinicAccounts;
    let totalInterviewsToBeRescored = 0;

    if (
      data.jobType === JobTypes.patients &&
      data.rescorePatients &&
      data.rescorePatients?.length > 0
    ) {
      affectedPatients = data.rescorePatients;
      totalInterviewsToBeRescored = data.rescorePatients.reduce((accumulator, object) => {
        return accumulator + parseInt(object.interviewresults);
      }, 0);
    }

    if (
      data.jobType === JobTypes.accounts &&
      data.rescoreClinicAccounts &&
      data.rescoreClinicAccounts.length > 0
    ) {
      affectedClinicAccounts = data.rescoreClinicAccounts;
      totalInterviewsToBeRescored = data.rescoreClinicAccounts.reduce((accumulator, object) => {
        return accumulator + parseInt(object.interviewresults);
      }, 0);
    }

    const confirmContentPopup = {
      ...confirmRescoreContentPopup,
      information: {
        jobName: data.jobName,
        jobType: data.jobType,
        affectedPatients,
        affectedClinicAccounts,
        totalInterviewsToBeRescored,
      },
    };
    setConfirmContentPopup(confirmContentPopup);
    setIsOpenConfirmPopup(true);
    setDataSubmitTemp(data);
  };

  const onProcessChangeAccount = async (successCallback?: () => void) => {
    setValue('clinicAccount', clinicAccountTemp);
    setClinicAccountId(clinicAccountTemp);

    setMultiCheckPatients([]);
    resetField('rescorePatients');
    if (successCallback) {
      successCallback();
    }
  };

  const onProcessConfirmRescore = async (successCallback?: () => void) => {
    try {
      if (dataSubmitTemp) {
        await onFormSubmit(dataSubmitTemp, () => {
          setPageErrors([]);
        });
      }
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
    setValue('clinicAccount', clinicAccountTemp);
    setClinicAccountId(clinicAccountTemp);

    setMultiCheckPatients([]);
    resetField('rescorePatients');
    if (successCallback) {
      successCallback();
    }
  };

  const onProcessChangeJobType = async (successCallback?: () => void) => {
    setJobType(jobTypeTemp);
    setValue('jobType', jobTypeTemp);

    setMultiCheckPatients([]);
    resetField('rescorePatients');

    setMultiCheckAccounts([]);
    resetField('rescoreClinicAccounts');
    if (successCallback) {
      successCallback();
    }
  };

  const handleJobTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    if (
      (multiCheckPatients && multiCheckPatients.length !== 0) ||
      (multiCheckAccounts && multiCheckAccounts.length !== 0)
    ) {
      showChangeJobTypeContentPopup(selectedValue);
    } else {
      setJobType(selectedValue);
      setValue('jobType', selectedValue);

      setMultiCheckPatients([]);
      resetField('rescorePatients');

      setMultiCheckAccounts([]);
      resetField('rescoreClinicAccounts');
    }
  };

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    if (multiCheckPatients && multiCheckPatients.length !== 0) {
      showChangeAccountContentPopup(selectedValue);
    } else {
      setValue('clinicAccount', selectedValue);
      setClinicAccountId(selectedValue);

      setMultiCheckPatients([]);
      resetField('rescorePatients');
    }
  };

  const handlePatientsChanged = (e: any, values: any) => {
    const finalPatients = handleFilter(values);

    setMultiCheckPatients(finalPatients);
    setValue('rescorePatients', finalPatients);
  };

  const handleAccountsChanged = (e: any, values: any) => {
    const finalAccounts = handleFilter(values);

    setMultiCheckAccounts(finalAccounts);
    setValue('rescoreClinicAccounts', finalAccounts);
  };

  const handleFilter = (values: any) => {
    return values.filter((value: any, index: number, arrCurr: any) => {
      return (
        arrCurr.findIndex((x: any) => x.id === value.id) ===
        arrCurr.map((x: any) => x.id).lastIndexOf(value.id)
      );
    });
  };
  const onSubmit = async (data: RescoreInterviewFormInfo) => {
    showConfirmRescoreContentPopup(data);
  };
  const onClosePopup = () => {
    setIsOpenConfirmPopup(false);
  };

  return (
    <Box
      sx={{
        px: 0,
        pt: 0,
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
          px: 0,
          pt: 0,
        }}
      >
        <RoundedContainer sx={{ marginBottom: 2 }}>
          <StyleFormItem>
            <Controller
              name="jobName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.jobName}
                  helperText={errors.jobName?.message}
                  margin="normal"
                  fullWidth
                  label={t('interviews.rescoreJobs.addJobs.form.jobName')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="jobType"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  margin="normal"
                  select
                  fullWidth
                  label={t('interviews.rescoreJobs.addJobs.form.jobType')}
                  error={!!errors.jobType}
                  helperText={errors.jobType?.message}
                  variant="outlined"
                  onChange={handleJobTypeChange}
                >
                  {jobTypes?.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </StyleTextField>
              )}
            />
            {jobType === JobTypes.patients && (
              <>
                <Controller
                  name="clinicAccount"
                  control={control}
                  render={({ fieldState, formState, field: { value }, ...props }) => (
                    <Autocomplete
                      {...props}
                      sx={{ width: '100%' }}
                      data-testid="mui-component-select-clinic-account"
                      options={accountDataDisplay || []}
                      getOptionLabel={(option: any) =>
                        option.name
                          ? `${option.name}(${option.id})-${option.patients} patients`
                          : ''
                      }
                      onChange={handleAccountChange}
                      value={
                        value ? accountDataDisplay.find((item) => item.id === value) || [] : null
                      }
                      filterOptions={filterAccountOptions}
                      renderOption={(props, option: any) => {
                        return (
                          <li {...props} key={option.id} value={option.id}>
                            {option.name
                              ? `${option.name}(${option.id})-${option.patients} patients`
                              : ''}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.clinicAccount}
                          helperText={errors.clinicAccount?.message}
                          margin="normal"
                          fullWidth
                          label={t('interviews.rescoreJobs.addJobs.form.clinicAccount')}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="rescorePatients"
                  control={control}
                  render={({ fieldState, formState, field: { value }, ...props }) => (
                    <StyleMultipleAutocomplete
                      fullWidth
                      {...props}
                      multiple
                      disableCloseOnSelect
                      options={patientDataDisplay}
                      getOptionLabel={getOptionLabelPatient}
                      renderTags={() => {
                        return (
                          <>
                            {matches
                              ? renderListPatientMobile(multiCheckPatients)
                              : renderListPatientDesktop(multiCheckPatients)}
                          </>
                        );
                      }}
                      value={value || patientDataDisplay}
                      onChange={handlePatientsChanged}
                      filterOptions={filterPatientOptions}
                      disableClearable={true}
                      renderOption={(props, option: any, { selected }) => {
                        return (
                          <li {...props} key={option.id} value={option.id}>
                            <Checkbox
                              key={option.id}
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={
                                selected ||
                                multiCheckPatients.some((patient) => patient.id === option.id)
                              }
                            />
                            {getOptionLabelPatient(option)}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          fullWidth
                          label={t('interviews.rescoreJobs.addJobs.form.patients')}
                          placeholder={t('interviews.rescoreJobs.addJobs.form.searchPatients')}
                          error={!!errors.rescorePatients}
                          helperText={(errors.rescorePatients as any)?.message}
                          onKeyDown={(event: any) => {
                            if (event.key === 'Backspace') {
                              event.stopPropagation();
                            }
                          }}
                        />
                      )}
                    />
                  )}
                />
              </>
            )}
            {jobType === JobTypes.accounts && accountDataDisplay && (
              <Controller
                name="rescoreClinicAccounts"
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <StyleMultipleAutocomplete
                    fullWidth
                    {...props}
                    multiple
                    disableCloseOnSelect
                    options={accountDataDisplay}
                    getOptionLabel={getOptionLabelClinicAccount}
                    renderTags={() => {
                      return matches
                        ? renderListAccountMobile(multiCheckAccounts)
                        : renderListAccountDesktop(multiCheckAccounts);
                    }}
                    value={value || multiCheckAccounts}
                    onChange={handleAccountsChanged}
                    filterOptions={filterAccountOptions}
                    disableClearable={true}
                    renderOption={(props, option: any, { selected }) => {
                      return (
                        <li {...props} key={option.id} value={option.id}>
                          <Checkbox
                            key={option.id}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={
                              selected ||
                              multiCheckAccounts.some((patient) => patient.id === option.id)
                            }
                          />
                          {getOptionLabelClinicAccount(option)}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        label={t('interviews.rescoreJobs.addJobs.form.clinicAccounts')}
                        placeholder={t('interviews.rescoreJobs.addJobs.form.searchClinicAccounts')}
                        error={!!errors.rescoreClinicAccounts}
                        helperText={(errors.rescoreClinicAccounts as any)?.message}
                        onKeyDown={(event: any) => {
                          if (event.key === 'Backspace') {
                            event.stopPropagation();
                          }
                        }}
                      />
                    )}
                  />
                )}
              />
            )}
          </StyleFormItem>
          <SubmitButton
            content={t('interviews.rescoreJobs.addJobs.form.submit')}
            matches={matches}
            pageErrors={pageErrors}
          />
        </RoundedContainer>
      </Box>
      <PopupModal
        isOpen={isOpenPopup}
        contentPopup={contentPopup}
        onClick={
          callAction === RescoreAction.ChangeAccount
            ? onProcessChangeAccount
            : onProcessChangeJobType
        }
      />
      <ConfirmRescorePopup
        isOpen={isOpenConfirmPopup}
        contentPopup={confirmContentPopup}
        onSuccess={onProcessConfirmRescore}
        onClose={onClosePopup}
      />
    </Box>
  );
}
