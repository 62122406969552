import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import EditIdentityProviderIcon from '../../common/components/icons/EditInterviewIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getPageTitle } from '../../common/utils/pageUtils';
import { SortFields, SortDirs } from '../../common/utils/sortInfo';
import { PageProps } from '../../types/PageProps';
import ContextMenu from '../patient/ContextMenu';
import PatientSortMenu from '../patient/PatientSortMenu';
import { useGetIdentityProvidersQuery } from '../../app/services/identityProvider';
import { IdentityProviderItem } from '../../types/SsoType';
import { statusData } from './SsoClientForm';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.xPalette.white,
  flexBasis: '100%',
  minWidth: '200px',
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'unset',
    width: '74%',
    minWidth: 'unset',
  },
}));

const StyledAddWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 0px',
  width: '100%',
  padding: '0px 8px',
  columnGap: '8px',

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    width: 'auto',
    flexBasis: '40%',
    maxWidth: '380px',
  },

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',
  },
}));

const StyledSortIconBox = styled(Box)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.primary.main,
  verticalAlign: 'middle',
}));

const StyledAddclientBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: 0,
  width: '40%',
  minWidth: '150px',
  ml: 1,
  [theme.breakpoints.down('sm')]: {
    width: '96%',
    height: '56px',
    ml: 0,
  },
}));

export default function SsoClientListPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [navBackUrl, setNavBackUrl] = useState<string>('/dashboard/ssoClients');

  const tableHeaders: string[] = Object.values(
    t('ssoClient.manageSsoClient.tableHeader', {
      returnObjects: true,
    })
  );

  const searchTextboxRef = useRef<HTMLInputElement>(null);

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
    search: searchParams.get('search') || '',
    sortField: searchParams.get('sort') || SortFields.Name,
    sortDir: searchParams.get('dir') || SortDirs.Asc,
  };

  const currentPage = searchOptions.page;
  const tableRef = useRef<HTMLDivElement>(null);

  const { data: identityProviderData, isLoading } = useGetIdentityProvidersQuery({
    search: searchOptions.search,
    page: currentPage,
    pageSize: pageSize,
    sort: `${searchOptions.sortField} ${searchOptions.sortDir}`,
  });

  const updateSearchUrl = (updatedData: any) => {
    const params = {
      ...searchOptions,
      ...updatedData,
    };
    const url = generatePath(
      // eslint-disable-next-line no-useless-concat
      '/dashboard/ssoClients/' +
        '?search=:search' +
        '&page=:page' +
        '&sort=:sort' +
        '&dir=:dir',
      params
    );
    navigate(url, { replace: true });
    setNavBackUrl(url);
  };

  const getStatusName = (value: string) => statusData.find(status => status.id === value)?.name;

  useEffect(() => {
    if (identityProviderData) {
      setTotalPages(Math.ceil(identityProviderData.totalItems / pageSize));
    }
  }, [pageSize, identityProviderData]);

  const handlePageChange = (value: number) => {
    updateSearchUrl({ page: value, sort: searchOptions.sortField, dir: searchOptions.sortDir });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    const input = searchTextboxRef.current?.value;
    updateSearchUrl({
      page: 1,
      search: input || '',
      sort: searchOptions.sortField,
      dir: searchOptions.sortDir,
    });
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleMenuSorting = (sortField: string, sortDir: string) => {
    updateSearchUrl({
      sort: sortField,
      dir: sortDir,
    });
  };

  const onHeaderClicked = (header?: string) => {
    if (header) {
      updateSearchUrl({
        sort: header,
        dir:
          header !== searchOptions.sortField || searchOptions.sortDir === SortDirs.Desc
            ? SortDirs.Asc
            : SortDirs.Desc,
      });
    }
  };

  const renderContextMenu = (item: IdentityProviderItem) => {
    return (
      <ContextMenu
        id={`identity-provider-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={[
          {
            isDisplayed: true,
            label: t('ssoClient.manageSsoClient.menuContext.editSsoClient'),
            icon: <EditIdentityProviderIcon />,
            onClick: () => {
              navigate(`/dashboard/ssoClients/edit-sso-client/${item.id}`, { state: { navBackUrl } });
            },
          }
        ]}
      />
    );
  };

  const renderDesktopData = (data: IdentityProviderItem[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders
                .map((header, index) => {
                  const eventHeaders = ['name', 'description', 'protocol', 'ehrtype'];
                  const checkSortEvent =
                    searchOptions.sortDir === SortDirs.Asc &&
                    searchOptions.sortField === header.toLowerCase();
                  const sortIcon = eventHeaders.includes(header.trim().toLowerCase()) ? (
                    <StyledSortIconBox>
                      {checkSortEvent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </StyledSortIconBox>
                  ) : (
                    <></>
                  );
                  return (
                    <StyledTableCell
                      key={`${header} ${index}`}
                      align={`${
                        header === t('ssoClient.manageSsoClient.tableHeader.action')
                          ? 'center'
                          : 'inherit'
                      }`}
                      onClick={
                        eventHeaders.includes(header.toLowerCase())
                          ? () => onHeaderClicked(header.toLowerCase())
                          : undefined
                      }
                    >
                      {header} {sortIcon}
                    </StyledTableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: IdentityProviderItem) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell sx={{width:'25%'}}>
                    <Link
                      sx={{ fontSize: '1em', textAlign: 'left' }}
                      component="button"
                      onClick={() => {
                        navigate(`/dashboard/ssoClients/edit-sso-client/${item.id}`, {
                          state: { navBackUrl },
                        });
                      }}
                    >
                      {`${item.name}`}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>{item.description}</StyledTableCell>
                  <StyledTableCell>{item.loginMethodName}</StyledTableCell>
                  <StyledTableCell>{item.ehrType}</StyledTableCell>
                  <StyledTableCell>{getStatusName(item.status)}</StyledTableCell>
                  <StyledTableCell align="center">{renderContextMenu(item)}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: IdentityProviderItem[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: IdentityProviderItem) => {
          const descriptionItem = [
            <>{t('ssoClient.manageSsoClient.tableHeader.description')}</>,
            <Box sx={{ wordBreak: 'break-word' }}>{item.description}</Box>,
          ];
          const loginMethodItem = [
            <>{t('ssoClient.manageSsoClient.tableHeader.protocol')}</>,
            <Box sx={{ wordBreak: 'break-word' }}>{item.loginMethodName}</Box>,
          ];
          const listItems = [
            [
              <>{t('ssoClient.manageSsoClient.tableHeader.status')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{getStatusName(item.status)}</Box>,
            ],
          ];
          listItems.unshift(loginMethodItem);
          listItems.unshift(descriptionItem);

          return {
            leftHeader:(
              <Link
                sx={{ fontSize: '20px', textAlign: 'left', fontWeight: '600' }}
                component="button"
                onClick={() => {
                  navigate(`/dashboard/ssoClients/edit-sso-client/${item.id}`, {
                    state: { navBackUrl },
                  });
                }}
              >
                {`${item.name}`}
              </Link>
            ),
            rightHeader: renderContextMenu(item),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('ssoClient.manageSsoClient.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={!isDesktop}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 0 : 2 }}
            headerText={t('ssoClient.manageSsoClient.title')}
            backUrl={'/dashboard'}
          />

          <StyledAddWrapper>
            <StyledSearchBox
              inputRef={searchTextboxRef}
              onKeyUp={handleSearchKeyUp}
              defaultValue={searchOptions.search}
              placeholder={t('ssoClient.manageSsoClient.searchClient')}
              endAdornment={
                <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              }
            />
            {!isDesktop && (
              <PatientSortMenu
                sx={{
                  width: '20%',
                  flexShrink: 0,
                  borderRadius: '8px',
                  minWidth: 'unset',
                }}
                sortField={searchOptions.sortField}
                sortDir={searchOptions.sortDir}
                onSorted={handleMenuSorting}
                isSso={true}
              />
            )}
            <StyledAddclientBox
              type="button"
              fullWidth
              variant="contained"
              onClick={() => {
                navigate('/dashboard/ssoClients/add-sso-client', { state: { navBackUrl } });
              }}
            >
              {t('ssoClient.manageSsoClient.addNewClient')}
            </StyledAddclientBox>
          </StyledAddWrapper>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <>
          {identityProviderData && identityProviderData.identityProviders && (
             <>
              <Box ref={tableRef}>
                {identityProviderData?.identityProviders?.length === 0 ? (
                  <RoundedContainer sx={{ mt: 2, p: 2 }}>
                    <Typography>{t('ssoClient.manageSsoClient.error.identityProviderNotFound')}</Typography>
                  </RoundedContainer>
                ) : (
                  <>
                    {isDesktop ? renderDesktopData(identityProviderData.identityProviders)
                    : renderMobileData(identityProviderData.identityProviders)}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        py: 4,
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        background: theme.xPalette.white,
                        mt: 0,
                        [theme.breakpoints.down('sm')]: {
                          mt: 1,
                          py: 2,
                        },
                      }}
                    >
                      <CustomPagination
                        changePage={(e, value) => handlePageChange(value)}
                        currentPage={searchOptions.page}
                        pages={totalPages}
                        pageRef={tableRef}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
}
