import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetInterviewTypesForExportDataQuery } from '../../../app/services/interview';
import { LanguageType, languages as commonLanguages } from '../../../common/utils/languages';
import { ExportDataInterviewType } from '../../../types/InterviewTypes';

interface DefaultValueType {
  defaultLanguage: string;
  defaultInterviewType: number;
}

export default function useFilterInterviewTypeByLanguage({
  initialAccountId,
  initialLanguage,
}: {
  initialAccountId: string;
  initialLanguage: string;
}): {
  isLoading: boolean;
  interviewTypesByLanguage: ExportDataInterviewType[];
  languages: LanguageType[];
  currentLanguage: string;
  setClinicAccountId: Dispatch<SetStateAction<string>>;
  setCurrentLanguage: Dispatch<SetStateAction<string>>;
  defaultValue: DefaultValueType;
} {
  const [clinicAccountId, setClinicAccountId] = useState<string>(initialAccountId);
  const [currentLanguage, setCurrentLanguage] = useState<string>(initialLanguage);
  const [interviewTypesByLanguage, setInterviewTypesByLanguage] = useState<
    ExportDataInterviewType[]
  >([]);
  const [languages, setLanguages] = useState<LanguageType[]>([]);
  const [defaultValue, setDefaultValue] = useState<DefaultValueType>({
    defaultLanguage: 'noLanguage',
    defaultInterviewType: 0,
  });

  const { data, isLoading } = useGetInterviewTypesForExportDataQuery(
    clinicAccountId ? { clinicAccountId } : skipToken
  );

  useEffect(() => {
    if (data) {
      const { interviewTypeLanguages, interviewTypes } = data;
      const defaultPickLanguage = interviewTypeLanguages.includes(currentLanguage)
        ? currentLanguage
        : interviewTypeLanguages[0];

      const typeByLanguages = interviewTypes
        .filter((interviewType: ExportDataInterviewType) => {
          return interviewType?.languages?.split(',').includes(defaultPickLanguage);
        })
        .sort(
          (a: ExportDataInterviewType, b: ExportDataInterviewType) =>
            a?.name?.localeCompare(b?.name || '') || 0
        );

      const fullInfoLanguage = interviewTypeLanguages?.map((code: string) => {
        return commonLanguages[code];
      });

      setInterviewTypesByLanguage(typeByLanguages);
      setLanguages(fullInfoLanguage);

      setDefaultValue({
        defaultLanguage: defaultPickLanguage || 'noLanguage',
        defaultInterviewType: typeByLanguages[0]?.id || 0,
      });
    }
  }, [currentLanguage, data]);

  return {
    isLoading,
    interviewTypesByLanguage,
    languages,
    currentLanguage,
    setClinicAccountId,
    setCurrentLanguage,
    defaultValue,
  };
}
