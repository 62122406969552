import SvgIcon from '@mui/material/SvgIcon';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { ReactComponent as NoteIconSvg } from '../../../assets/icons/note.svg';

interface IconProps {
  sx?: SxProps<Theme>;
}

export default function NoteIcon({ sx }: IconProps) {
  return <SvgIcon component={NoteIconSvg} sx={{ ...sx }} />;
}
