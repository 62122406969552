import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { useGetListStructuredScoredDataCodebookQuery } from '../../app/services/interview';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { StructuredDataCodebook } from '../../types/InterviewTypes';
import { populateLanguage } from '../../common/utils/languages';

export default function StructuredScoredDataCodebook({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const { data: dataCodebook, isLoading } = useGetListStructuredScoredDataCodebookQuery();
  const sortedData = dataCodebook?.data
  ?.slice()
  .sort((a: StructuredDataCodebook, b: StructuredDataCodebook) =>
    a.name > b.name ? 1 : -1
  );

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('exportData.structured.scored.codebook.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Container maxWidth="xl">
          <PageHeader
            sx={{ mt: 1, mb: 2 }}
            headerText={t('exportData.structured.scored.codebook.title')}
            backUrl={'/dashboard/structured-export'}
          />
        </Container>
        {isLoading ? (
          <Loading />
        ) : (
          <RoundedContainer
            sx={{
              py: 1,
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <ul>
                {sortedData?.map((interview: StructuredDataCodebook) => (
                  <li key={interview.id}>
                    <Link
                      href={`/dashboard/structured-export/structured-scored-data-codebook/detail/${interview.id}`}
                    >
                      {`${interview.name} (${populateLanguage(interview.language)})`}
                    </Link>
                  </li>
                ))}
              </ul>
            </Box>
          </RoundedContainer>
        )}
      </Container>
    </DashboardLayout>
  );
}
