import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { SearchPatientRequest, SearchPatientResult } from '../../types/DashboardRequest';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useAppDispatch } from '../../app/hooks';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { patientSearchApi } from '../../app/services/account';
import { debounce } from 'lodash';
import { SxProps, Theme } from '@mui/system';
import { DefaultPageSize } from '../utils/commonUtils';

interface PatientSearchProps {
  clinicaAccountId?: string;
  selectedPatientId?: string; // default id
  viewMore?: boolean;
  onSelect?: (patient: SearchPatientResult | string | null) => void;
  sx?: SxProps<Theme>;
  pageSize?: number;
  searchIfNoInput?: boolean;
  freeSolo?: boolean;
  label?: string;
  searchByFullName?: boolean;
  searchByExternalId?: boolean;
  keepSearchOnBlur?: boolean;
}

export default function PatientSearch(
  { clinicaAccountId, selectedPatientId, viewMore, onSelect, sx, pageSize, searchIfNoInput, freeSolo, label, searchByFullName, searchByExternalId, keepSearchOnBlur }: PatientSearchProps
) {
  // Cover not pass value case, i.e. viewMore = undefined
  pageSize = pageSize || DefaultPageSize;
  viewMore = viewMore !== false ? true : false;
  freeSolo = freeSolo !== false ? true : false;
  searchByExternalId = searchByExternalId === false ? false : true;
  keepSearchOnBlur = keepSearchOnBlur === false ? false : true;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [patients, setPatients] = useState<SearchPatientResult[]>([]);
  const [isSearching, setIsSearching] = useState(true);
  const [isFocusing, setIsFocusing] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<SearchPatientResult | null>(null);

  const patientSearch = useCallback((searchPatientRequest: SearchPatientRequest) => {
    const patientDispatcher = dispatch(patientSearchApi.endpoints.searchPatients.initiate(searchPatientRequest));
    patientDispatcher.then((result) => {
      if (typeof result !== 'undefined') {
        let patientResults: SearchPatientResult[] = [];
        if (result.data && Array.isArray(result.data.patients)) {
          for (let i = 0; i < result.data.patients.length; i++) {
            const patient = result.data.patients[i];

            let optionText = '';
            if (!!patient.externalId && !patient.lastName && !patient.firstName) {
              optionText = `${patient.externalId} (${patient.patientId})`;
            }
            else if (!patient.lastName && !patient.firstName) {
              optionText = ` (${patient.patientId})`;
            } else {
              optionText = `${patient.lastName}, ${patient.firstName} (${patient.patientId})`;
            }

            let option = {
              patientId: patient.patientId,
              name: optionText,
            } as SearchPatientResult;
            patientResults.push(option);
          }

          if (viewMore && result.data.hasMore) {
            patientResults.push({
              patientId: '-1',
              name: 'View more...'
            } as SearchPatientResult);
          }
        }
        setPatients(patientResults);
      }
    })
    .catch(err => {
      console.error(`Error when searching patients ${err.getMessage()}`);
    });
  }, [dispatch, viewMore]);

  useEffect(() => {
    const selectedPatientItem = selectedPatientId ? patients.find((patient) => patient.patientId === selectedPatientId) : null;
    setSelectedPatient(selectedPatientItem || null);

    // if selectedPatientId is not null but this patient is not the list then set null
    if (selectedPatientId && !selectedPatientItem && onSelect) {
      onSelect(null);
    }

    if (!keepSearchOnBlur && !isFocusing && !selectedPatientItem) {
      setIsSearching(false);
      setInputValue('');
    }
  },
  [isFocusing, keepSearchOnBlur, onSelect, patients, selectedPatientId]
);

  const debouncedPatientSearch = useMemo(() => debounce((searchPatientRequest: SearchPatientRequest) => {
    patientSearch(searchPatientRequest);
  }, 200),
  [patientSearch]
  );

  useEffect(() => {
      if (inputValue === '' && !searchIfNoInput) {
        setPatients([]);
        return undefined;
      }
      if (isSearching) {
        debouncedPatientSearch(({ clinicaAccountId, searchTerm: inputValue, searchByFullName, searchByExternalId, pageSize } as SearchPatientRequest));
      }
      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clinicaAccountId, inputValue, debouncedPatientSearch, searchIfNoInput]
  );

  const searchPatient = (selectedPatient: SearchPatientResult | string | null) => {
    if (onSelect) {
      onSelect(selectedPatient);
      return;
    }
    if (typeof selectedPatient !== 'string' && selectedPatient?.patientId !== '-1') {
      navigate(`patient/${selectedPatient?.patientId}`);
    } else {
      navigate(`patients/?search=${inputValue}`);
    }
  }

  return (
    <Autocomplete
      freeSolo={freeSolo}
      id='patientSearch'
      sx={sx || {
        '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
          pr: 1
        },
        width: 1,
      }}
      inputValue={inputValue}
      value={selectedPatient}
      getOptionLabel={(option) => typeof option !== 'undefined' ? typeof option === 'string' ? option : option.name! : '' }
      options={patients}
      filterOptions={(options) => options}
      onFocus={() => {
        setIsFocusing(true);
      }}
      onBlur={() => {
        setIsSearching(false);
        setIsFocusing(false);
        if (!keepSearchOnBlur && !selectedPatient) {
          setInputValue('');
        }
      }}
      onChange={(event, newInputValue) => {
        setIsSearching(false);
        setInputValue((newInputValue as SearchPatientResult)?.name || '');
        searchPatient(newInputValue);
      }}
      onInputChange={(event, newInputValue) => {
        if (event?.type === 'change') {
          setIsSearching(true);
          setInputValue(newInputValue);
        }
      }}
      renderInput={(params) => {
        if (freeSolo) {
          params.InputProps = {
            ...params.InputProps,
             endAdornment: freeSolo && (
                <IconButton type='submit' sx={{ p: '10px' }} aria-label='search'
                onClick={() => {
                  searchPatient(inputValue);
                }}>
                  <SearchIcon />
                </IconButton>
              ),
          };
        }
        return (
          <TextField
            {...params}
            label={label || t('dashboard.searchPatient.title')}
            InputProps={{
              ...params.InputProps,
              type: freeSolo ? 'search' : undefined,
            }}
          />
      )}}
      renderOption={(props, patient) => {
        return (
          <li {...props} key={patient.patientId}>
            <Typography sx={{
              fontStyle: patient.patientId === '-1' ? 'italic' : 'normal',
            }}>
              {patient.name}
            </Typography>
          </li>
        );
      }}
    />
  );
}
