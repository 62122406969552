import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import RoundedContainer from './RoundedContainer';

type DataItem = string | JSX.Element;

interface DataListProps {
  data: {
    leftHeader: JSX.Element;
    rightHeader?: JSX.Element;
    items: any;
  }[];
  lineHeight?: string;
  customStyle?: any;
  hideDividerTop?: boolean;
  hideDividerBottum?: boolean;
}

const StyledTypography = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  padding: '8px 0',
}));

const renderField = (
  name: DataItem,
  value: DataItem,
  alternativeStyle: boolean,
  lineHeight?: string,
  key?: number | string
) => {
  const valueElement = typeof value !== 'string' ? (value as JSX.Element) : undefined;
  return (
    <Box
      key={key}
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '4px 16px',
        m: 2,
        height: lineHeight ? `${lineHeight}` : 'auto',
        bgcolor: alternativeStyle ? theme.xPalette.tableAltLine : 'unset',
        borderRadius: '5px',
        minHeight: '40px',

        [theme.breakpoints.up('sm')]: {
          mx: 0,
        },
      })}
    >
      <Box
        sx={{
          flexBasis: '40%',
          display: 'flex',
          justifyContent: 'flex-start',
          textAlign: 'left',
        }}
      >
        <StyledTypography>{name}</StyledTypography>
      </Box>
      <Box
        sx={{
          flexBasis: '60%',
          display: 'flex',
          justifyContent: 'flex-end',
          textAlign: 'right',
        }}
      >
        {valueElement || <StyledTypography>{value}</StyledTypography>}
      </Box>
    </Box>
  );
};

export default function DataList({
  data,
  lineHeight,
  customStyle,
  hideDividerTop,
  hideDividerBottum,
}: DataListProps) {
  const renderFields = (items: DataItem[][]) => {
    const renderedValue: any = [];
    if (items && items.length > 0) {
      for (let index = 0; index < items.length; index++) {
        const field = items[index];
        renderedValue.push(renderField(field[0], field[1], index % 2 === 0, lineHeight, index));
      }
    }
    return renderedValue;
  };

  let rowIndex = 0;

  return (
    <>
      {data.map((row) => (
        <RoundedContainer
          sx={{
            mt: 2,
            px: 0,
            boxShadow: customStyle?.boxShadow,
            backgroundColor: customStyle?.backgroundColor,
          }}
          key={`row-${rowIndex++}`}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 1,
              height: 'auto',
              backgroundColor: customStyle?.background,
              borderRadius: customStyle?.borderRadius,

              [theme.breakpoints.up('sm')]: {
                px: 0,
              },
            })}
          >
            {row.leftHeader}
            {row.rightHeader}
          </Box>
          {!hideDividerTop && <Divider sx={{ mb: 2 }} />}
          {renderFields(row.items)}
          {!hideDividerBottum && <Divider sx={{ mb: 2 }} />}
        </RoundedContainer>
      ))}
    </>
  );
}
