import { SharpenPageProps } from '../../types/PageProps';

export default function SharpenContentPage({ src, title }: SharpenPageProps) {
  return (
    <>
      <iframe
        src={src}
        title={title}
        width={'100%'}
        style={{
          margin: 0,
          padding: 0,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          border: 'none',
          overflow: 'hidden',
          zIndex: 99999,
        }}
      ></iframe>
    </>
  );
}
