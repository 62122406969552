import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../../app/hooks';
import { useGetProvidersQuery } from '../../../app/services/patient';
import { useAddStrategyMutation, useEditStrategyMutation } from '../../../app/services/treatment-plan/strategy';
import { useToast } from '../../../app/toast';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { ResponseError } from '../../../types/ResponseError';
import { StrategyItem } from '../../../types/treatment-plan/StrategyType';
import { Provider } from '../../../types/UserTypes';
import { selectTreatmentPlanPatientId } from '../treatmentPlanSlice';

interface StrategyFormProps {
  parentObjectiveId: number;
  strategyData?: StrategyItem;
  onFormSubmitCallback: (strategyData: StrategyItem, result: boolean, info?: any) => void;
}

export default function StrategyForm({
  parentObjectiveId,
  strategyData,
  onFormSubmitCallback,
}: StrategyFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const toast = useToast();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [addStrategy] = useAddStrategyMutation();
  const [editStrategy] = useEditStrategyMutation();
  const patientId = useAppSelector(selectTreatmentPlanPatientId);

  const { data: providerData } = useGetProvidersQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const defaultFormValues = {
    description: '',
    providedBy: 0,
    frequency: '',
  };

  const validationSchema = yup
    .object({
      description: yup.string().required(t('treatmentPlans.strategyForm.error.blankName')),
      providedBy: yup.string().required(t('treatmentPlans.strategyForm.error.blankProvidedBy')),
      frequency: yup.string().required(t('treatmentPlans.strategyForm.error.blankFrequency')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<StrategyItem>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (providerData) {
      const sortProviders = [...(providerData.providers || [])].sort(
        (a: Provider, b: Provider) =>
          a.lastName?.localeCompare(b.lastName) || a.firstName?.localeCompare(b.firstName)
      );
      sortProviders.push({ id: 0, firstName: 'Patient', lastName: '', allowTrial: true });
      setProviders(sortProviders);

      if (strategyData?.providedBy === 0) {
        setValue('providedBy', 0);
      } else if (!strategyData?.providedBy) {
        setValue('providedBy', sortProviders[0].id);
      }
    }
  }, [setValue, providerData, strategyData?.providedBy]);

  // initial data load
  useEffect(() => {
    if (strategyData?.id) {
      if (!isDataLoaded) {
        setIsDataLoaded(true);
        Object.keys(strategyData).forEach((attr) => {
          if (attr !== 'id') {
            setValue(attr as any, strategyData[attr]);
          }
        });
      }
    }
  }, [strategyData, isDataLoaded, setValue]);

  const onSubmit = async (strategyFormData: StrategyItem) => {
    setDisableSubmit(true);
    try {
      let submitData = strategyFormData;
      if (strategyData?.id) {
        submitData = { ...strategyFormData, id: strategyData.id };
        await editStrategy(submitData).unwrap();
      } else if (parentObjectiveId) {
        submitData = {
          ...strategyFormData,
          treatmentObjectiveId: parentObjectiveId,
        };
        const addingRes = await addStrategy(submitData).unwrap();
        if (addingRes?.data) {
          submitData = addingRes?.data;
        }
      }

      onFormSubmitCallback(submitData, true);

      setPageErrors([]);

      toast.publish(
        (strategyData?.id && t('treatmentPlans.strategyForm.updateSuccess')) ||
          t('treatmentPlans.strategyForm.addSuccess'),
        'success'
      );
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
      onFormSubmitCallback(strategyFormData, false, error);
    }
    setDisableSubmit(false);
  };

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  const getProviderOptionLabel = (option: any) => {
    if (option.lastName && option.firstName) {
      return `${option.lastName}, ${option.firstName}`;
    }
    return `${option.lastName}${option.firstName}`;
  };

  const filterProviderOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getProviderOptionLabel(option),
  });

  const handleProviderChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('providedBy', selectedValue);
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        px: 0,
        pt: 0,
      }}
    >
      <RoundedContainer sx={{ paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.strategyForm.name.title')}
                variant="outlined"
                error={!!errors.description}
                helperText={errors.description?.message}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Controller
            name="providedBy"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <Autocomplete
                {...props}
                sx={{ width: isMobile ? '100%' : '48%' }}
                data-testid="mui-component-select-provider"
                options={providers || []}
                getOptionLabel={getProviderOptionLabel}
                value={(value || value === 0) ? providers?.find((item) => item.id === value) || null : null}
                filterOptions={filterProviderOptions}
                onChange={handleProviderChange}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.id} value={option.id}>
                      {getProviderOptionLabel(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.providedBy}
                    helperText={errors.providedBy?.message}
                    margin="normal"
                    fullWidth
                    label={t('treatmentPlans.strategyForm.providedBy.title')}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            )}
          />
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label={t('treatmentPlans.strategyForm.frequency.title')}
                variant="outlined"
                error={!!errors.frequency}
                helperText={errors.frequency?.message}
                sx={{ width: isMobile ? '100%' : '48%' }}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            pt: 3,
          }}
        >
          {!isMobile && <Divider sx={{ width: '100%' }} />}

          {pageErrors?.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            ))}
          <Button
            onClick={submitForm}
            disabled={disableSubmit}
            fullWidth
            variant="contained"
            sx={{
              width: isMobile ? '100%' : '35%',
              my: 2,
              py: 2,
              float: 'right',
            }}
          >
            {strategyData?.id
              ? t('treatmentPlans.strategyForm.update')
              : t('treatmentPlans.strategyForm.add')}
          </Button>
        </Box>
      </RoundedContainer>
    </Box>
  );
}
